const LabsIcon = (props) => {
  const {
    stroke = '',
    fill = 'none',
    ...rest
  } = props;

  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill={fill} xmlns="http://www.w3.org/2000/svg" {...rest}>
      <path d="M15.2778 4.13335L8.47181 0.61468C8.32589 0.539323 8.16405 0.5 7.99981 0.5C7.83558 0.5 7.67374 0.539323 7.52781 0.61468L0.721814 4.13335C0.654971 4.16796 0.598928 4.22029 0.559809 4.2846C0.52069 4.34891 0.5 4.42274 0.5 4.49801C0.5 4.57329 0.52069 4.64712 0.559809 4.71143C0.598928 4.77574 0.654971 4.82806 0.721814 4.86268L7.52781 8.38335C7.67374 8.4587 7.83558 8.49803 7.99981 8.49803C8.16405 8.49803 8.32589 8.4587 8.47181 8.38335L15.2778 4.86668C15.3447 4.83206 15.4007 4.77974 15.4398 4.71543C15.4789 4.65112 15.4996 4.57729 15.4996 4.50201C15.4996 4.42674 15.4789 4.35291 15.4398 4.2886C15.4007 4.22429 15.3447 4.17196 15.2778 4.13735V4.13335Z" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" />
      <path d="M3.39981 6.25L0.721814 7.63533C0.654971 7.66995 0.598928 7.72227 0.559809 7.78659C0.52069 7.8509 0.5 7.92472 0.5 8C0.5 8.07528 0.52069 8.1491 0.559809 8.21341C0.598928 8.27773 0.654971 8.33005 0.721814 8.36467L7.52781 11.8853C7.67374 11.9607 7.83558 12 7.99981 12C8.16405 12 8.32589 11.9607 8.47181 11.8853L15.2778 8.36467C15.3447 8.33005 15.4007 8.27773 15.4398 8.21341C15.4789 8.1491 15.4996 8.07528 15.4996 8C15.4996 7.92472 15.4789 7.8509 15.4398 7.78659C15.4007 7.72227 15.3447 7.66995 15.2778 7.63533L12.5998 6.25" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" />
      <path d="M3.39981 9.75L0.721814 11.1333C0.654971 11.168 0.598928 11.2203 0.559809 11.2846C0.52069 11.3489 0.5 11.4227 0.5 11.498C0.5 11.5733 0.52069 11.6471 0.559809 11.7114C0.598928 11.7757 0.654971 11.8281 0.721814 11.8627L7.52781 15.3833C7.67374 15.4587 7.83558 15.498 7.99981 15.498C8.16405 15.498 8.32589 15.4587 8.47181 15.3833L15.2778 11.8667C15.3447 11.832 15.4007 11.7797 15.4398 11.7154C15.4789 11.6511 15.4996 11.5773 15.4996 11.502C15.4996 11.4267 15.4789 11.3529 15.4398 11.2886C15.4007 11.2243 15.3447 11.1719 15.2778 11.1373L12.5998 9.75" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" />
    </svg>

  );
};

export default LabsIcon;
