import { useCallback } from 'react';
import { useRecoilState, useResetRecoilState } from 'recoil';
import { modalAtom } from 'recoil/atoms';

const useModal = () => {
  const [modals, setModals] = useRecoilState(modalAtom);
  const resetModals = useResetRecoilState(modalAtom);
  /**
   * Appends a modal to the modals array
   * @param {Object} newModal - The modal object to add
   */
  const addModal = useCallback((newModal) => {
    setModals((prevModals) => ([
      ...prevModals,
      newModal,
    ]));
  }, [setModals]);

  /**
   * Removes the modal at the specified index
   * @param {number} [index=0] - The index of the modal to remove
   * @returns {boolean} - Whether the removal was successful
   */
  const removeModalAtIndex = useCallback((index = 0) => {
    if (index < 0 || index >= modals?.length) {
      return false;
    }
    setModals((prevModals) => {
      const newModals = [...prevModals];
      newModals.splice(index, 1);
      return newModals;
    });
    return true;
  }, [modals.length, setModals]);

  /**
   * Removes a modal by its key
   * @param {string} modalKey - The key of the modal to remove
   * @returns {boolean} - Whether the removal was successful
   */
  const removeModal = useCallback((modalKey) => {
    const index = modals.findIndex((modal) => modal.key === modalKey);
    if (index === -1) {
      return false;
    }
    return removeModalAtIndex(index);
  }, [modals, removeModalAtIndex]);

  /**
   * Removes a modal by its key
   * @param {string} modalKey - The key of the modal to find
   * @returns {boolean} - Whether the modal was found or not
   */
  const findModal = useCallback((modalKey) => {
    const index = modals.findIndex((modal) => modal.key === modalKey);
    if (index === -1) {
      return false;
    }
    return true;
  }, [modals]);

  return {
    modals,
    setModals,
    resetModals,
    addModal,
    removeModalAtIndex,
    removeModal,
    findModal,
  };
};

export default useModal;
