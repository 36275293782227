const CHALLENGE_CATEGORIES = {
  reversing: 'Reversing',
  crypto: 'Crypto',
  pwn: 'PWN',
  web: 'WEB',
  misc: 'MISC',
  forensics: 'Forensics',
  mobile: 'Mobile',
  hardware: 'Hardware',
};

export default CHALLENGE_CATEGORIES;
