import { FormControl, FormHelperText, Typography } from '@mui/material';
import { RadioGroup } from 'components/molecules';
import { useController } from 'react-hook-form';

const FormRadioGroup = (props) => {
  const { label, radioProps, ...rest } = props;

  const { field, fieldState } = useController(rest);
  const { error } = fieldState;

  const hasErrored = Boolean(error);

  return (
    <FormControl error={hasErrored}>
      <Typography
        variant="bodyStandardRegular"
        sx={{ color: 'text.secondary' }}
      >
        {label}
      </Typography>
      <RadioGroup
        radioProps={{
          'aria-labelledby': rest.name,
          name: rest.name,
        }}
        {...field}
        {...rest}
      />
      {hasErrored && <FormHelperText>{error?.message}</FormHelperText>}
    </FormControl>
  );
};

export default FormRadioGroup;
