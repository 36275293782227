import PropTypes from 'prop-types';

import { useLocale } from 'util/hooks';
import {
  Box,
  Typography,
} from '@mui/material';
import LAB_TYPES from 'assets/constants/labTypes';

const LabName = (props) => {
  const { labDetails } = props;
  const {
    type = '',
    challengesCategory = {},
    nameAr = '',
    nameEn = '',
  } = labDetails;

  const { t, isAr } = useLocale();
  const isTrainingLab = type === LAB_TYPES.training;
  const categoryString = isTrainingLab && (isAr
    ? challengesCategory?.nameAr
    : challengesCategory?.nameEn);

  return (
    <Box sx={{
      display: 'flex',
      flexWrap: 'wrap',
      rowGap: 4,
      columnGap: 20,
    }}
    >
      {isTrainingLab && (
        <Box>
          <Box>
            <Typography
              variant="bodyStandardBold"
              color="text.secondary"
            >
              {t('labs.labCategory')}
            </Typography>
          </Box>
          <Box>
            <Typography
              variant="bodyMediumBold"
              color="text.lighterGray"
            >
              {categoryString}
            </Typography>
          </Box>
        </Box>
      )}
      <Box>
        <Box>
          <Typography
            variant="bodyStandardBold"
            color="text.secondary"
          >
            {t('labs.labNameEn')}
          </Typography>
        </Box>
        <Box>
          <Typography
            variant="bodyMediumBold"
            color="text.lighterGray"
          >
            {nameEn}
          </Typography>
        </Box>
      </Box>
      <Box>
        <Box>
          <Typography
            variant="bodyStandardBold"
            color="text.secondary"
          >
            {t('labs.labNameAr')}
          </Typography>
        </Box>
        <Box>
          <Typography
            variant="bodyMediumBold"
            color="text.lighterGray"
          >
            {nameAr}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

LabName.propTypes = {
  labDetails: PropTypes.oneOfType([PropTypes.object]),
};

LabName.defaultProps = {
  labDetails: {},
};

export default LabName;
