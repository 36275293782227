import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { List } from '@mui/material';
import { useLocale } from 'util/hooks';
import DrawerMenuItem from './DrawerMenuItem';

const DrawerMenu = ({ menu = [] }) => {
  const { pathname } = useLocation();
  const { dir } = useLocale();

  const [activeMenuItem, setActiveMenuItem] = useState({ id: 0 });

  useEffect(() => {
    const findActiveMenuItem = (iterable = [], isChildren = false) => iterable.find((menuItem) => {
      if (menuItem.children) {
        return findActiveMenuItem(menuItem.children, true);
      }

      return isChildren // used to find partial match when a child is active
        ? pathname.startsWith(menuItem.path)
        : menuItem.path === pathname;
    });

    // Find active menu item when the user navigates via url
    const maybeActiveItem = findActiveMenuItem(menu);
    if (maybeActiveItem) {
      setActiveMenuItem(maybeActiveItem);
    }
  }, [pathname, menu]);

  return (
    <List dir={dir} disablePadding>
      {menu?.map((menuItem) => (
        <DrawerMenuItem
          key={menuItem.id}
          menuItem={menuItem}
          onClickItem={() => setActiveMenuItem(menuItem)}
          isActiveGroup={activeMenuItem?.id === menuItem.id}
        />
      ))}
    </List>
  );
};

export default DrawerMenu;
