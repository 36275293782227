const UsersIcon = (props) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M6.25 10C8.14848 10 9.6875 8.46098 9.6875 6.5625C9.6875 4.66402 8.14848 3.125 6.25 3.125C4.35152 3.125 2.8125 4.66402 2.8125 6.5625C2.8125 8.46098 4.35152 10 6.25 10Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M0.625 16.875C0.625 15.3832 1.21763 13.9524 2.27252 12.8975C3.32742 11.8426 4.75816 11.25 6.25 11.25C7.74184 11.25 9.17258 11.8426 10.2275 12.8975C11.2824 13.9524 11.875 15.3832 11.875 16.875" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M14.7734 11.25C16.3267 11.25 17.5859 9.9908 17.5859 8.4375C17.5859 6.8842 16.3267 5.625 14.7734 5.625C13.2201 5.625 11.9609 6.8842 11.9609 8.4375C11.9609 9.9908 13.2201 11.25 14.7734 11.25Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M13.1777 12.5567C13.8736 12.3002 14.6212 12.2156 15.3568 12.3101C16.0924 12.4045 16.7944 12.6753 17.4029 13.0993C18.0114 13.5233 18.5085 14.088 18.8519 14.7454C19.1953 15.4028 19.3748 16.1333 19.3752 16.875" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export default UsersIcon;
