import { useTheme } from '@mui/styles';

const MachineDetailsIcon = (props) => {
  const theme = useTheme();
  const {
    stroke = theme.palette.text.secondary,
    fill = theme.palette.text.secondary,
    ...rest
  } = props;

  return (
    <svg width="48" height="44" viewBox="0 0 48 44" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <rect width="48" height="44" rx="6" fill={fill} fillOpacity="0.1" />
      <path d="M17.125 17.5312C17.057 17.5312 16.9906 17.5514 16.934 17.5892C16.8775 17.627 16.8334 17.6806 16.8074 17.7435C16.7814 17.8063 16.7746 17.8754 16.7879 17.9421C16.8011 18.0087 16.8339 18.07 16.8819 18.1181C16.93 18.1661 16.9913 18.1989 17.0579 18.2121C17.1246 18.2254 17.1937 18.2186 17.2565 18.1926C17.3194 18.1666 17.373 18.1225 17.4108 18.066C17.4486 18.0094 17.4688 17.943 17.4688 17.875C17.4688 17.7838 17.4325 17.6964 17.3681 17.6319C17.3036 17.5675 17.2162 17.5312 17.125 17.5312Z" stroke="#BABBBC" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M17.125 25.7812C17.057 25.7812 16.9906 25.8014 16.934 25.8392C16.8775 25.877 16.8334 25.9306 16.8074 25.9935C16.7814 26.0563 16.7746 26.1254 16.7879 26.1921C16.8011 26.2587 16.8339 26.32 16.8819 26.3681C16.93 26.4161 16.9913 26.4489 17.0579 26.4621C17.1246 26.4754 17.1937 26.4686 17.2565 26.4426C17.3194 26.4166 17.373 26.3725 17.4108 26.316C17.4486 26.2594 17.4688 26.193 17.4688 26.125C17.4688 26.0338 17.4325 25.9464 17.3681 25.8819C17.3036 25.8175 17.2162 25.7812 17.125 25.7812Z" stroke="#BABBBC" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M30.1895 13.75C31.2835 13.75 32.3327 14.1846 33.1063 14.9582C33.8799 15.7318 34.3145 16.781 34.3145 17.875C34.3145 18.969 33.8799 20.0182 33.1063 20.7918C32.3327 21.5654 31.2835 22 30.1895 22H17.8145C16.7204 22 15.6712 21.5654 14.8976 20.7918C14.1241 20.0182 13.6895 18.969 13.6895 17.875C13.6895 16.781 14.1241 15.7318 14.8976 14.9582C15.6712 14.1846 16.7204 13.75 17.8145 13.75H30.1895Z" stroke={stroke} strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M30.1895 22C30.7312 22 31.2676 22.1067 31.768 22.314C32.2685 22.5213 32.7232 22.8251 33.1063 23.2082C33.4893 23.5912 33.7932 24.046 34.0005 24.5464C34.2078 25.0469 34.3145 25.5833 34.3145 26.125C34.3145 26.6667 34.2078 27.2031 34.0005 27.7036C33.7932 28.204 33.4893 28.6588 33.1063 29.0418C32.7232 29.4249 32.2685 29.7287 31.768 29.936C31.2676 30.1433 30.7312 30.25 30.1895 30.25H17.8145C16.7204 30.25 15.6712 29.8154 14.8976 29.0418C14.1241 28.2682 13.6895 27.219 13.6895 26.125C13.6895 25.031 14.1241 23.9818 14.8976 23.2082C15.6712 22.4346 16.7204 22 17.8145 22H30.1895Z" stroke={stroke} strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M22.627 17.875H30.1895" stroke={stroke} strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M22.627 26.125H30.1895" stroke={stroke} strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default MachineDetailsIcon;
