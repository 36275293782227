import { useParams } from 'react-router-dom';
import { adminGetTeamDetails } from 'services';
import { useCustomQuery } from 'reactQuery';
import { transformEventDetails } from 'util/transformers';

const transformTeamDetails = (team) => ({ ...team, event: transformEventDetails(team.event) });

const useAdminTeamDetails = (queryProps = {}) => {
  const { options, ...rest } = queryProps;
  const { teamId } = useParams();

  const query = useCustomQuery({
    queryKey: [`${teamId}-admin-view-team-details`],
    queryFn: () => adminGetTeamDetails(teamId),
    options: {
      select: transformTeamDetails,
      ...options,
    },
    ...rest,
  });

  return query;
};

export default useAdminTeamDetails;
