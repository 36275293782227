import axios from 'axios';
import METHODS from 'services/methods';

const uploadFileToS3 = async (
  url,
  file,
) => {
  const options = {
    method: METHODS.PUT,
    url,
    headers: {
      'Content-Type': file.type,
    },
    data: file,
  };

  await axios(options)
    .then((res) => Promise.resolve(res))
    .catch((error) => Promise.reject(error));
};

export default uploadFileToS3;
