import { Typography } from '@mui/material';
import { CheckCircleOutline } from 'assets/icons';

import { useLocale } from 'util/hooks';

const useTableColumns = (props = {}) => {
  const { plans = [] } = props;

  const { t, isAr } = useLocale();

  const getPlanColumn = (plan) => ({
    field: String(plan.id),
    headerName: isAr ? plan.nameAr : plan.nameEn,
    sortable: false,
    flex: 1,
    minWidth: 120,
    renderCell: ({ row = {} }) => {
      const hasFeature = plan.features.some((feat) => feat.id === row.id);

      if (row.comingSoon && hasFeature) {
        return (
          <Typography
            variant="bodyStandardRegular"
            sx={{ color: 'text.secondary' }}
          >
            {t('common.comingSoon')}
          </Typography>
        );
      }

      if (hasFeature) {
        return (
          <CheckCircleOutline
            width="20"
            height="20"
          />
        );
      }

      return '';
    },
  });

  const columns = [
    {
      field: 'features',
      headerName: t('subscriptions.feature'),
      sortable: false,
      flex: 1,
      minWidth: 170,
      valueGetter: ({ row: { nameAr, nameEn } }) => (isAr ? nameAr : nameEn),
    },
    ...plans.map((plan) => getPlanColumn(plan)),
  ];

  return columns;
};

export default useTableColumns;
