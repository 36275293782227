import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { useLocale } from 'util/hooks';

const PlanHeader = () => {
  const { t } = useLocale();

  return (
    <Box textAlign="center">
      <Typography
        variant="h4"
        color="text.lighterGray"
        align="center"
        sx={{ mb: 3 }}
      >
        {t('subscriptions.multipleSubscription')}
      </Typography>
      <Typography
        variant="bodyLargeRegular"
        color="text.secondary"
      >
        {t('subscriptions.compareTheFeatures')}
      </Typography>
    </Box>
  );
};

export default PlanHeader;
