import END_POINTS from 'services/endPoints';
import api from '../api';

const getAllAnnouncements = (options) => api(
  END_POINTS.announcements,
  options,
)
  .then((res) => Promise.resolve(res))
  .catch((error) => Promise.reject(error));

export default getAllAnnouncements;
