import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { NoContentPlaceholder } from 'components/molecules';

import { ActivityDateCard } from 'components/organisms';
import { useLocale } from 'util/hooks';
import MachineActivityCard from '../../helpers/MachineActivityCard';

const MachineActivity = (props) => {
  const {
    showDates = true,
    machineDetails = {},
  } = props;

  const {
    retiredAt = '',
    publishedAt = '',
    submissions = [],
  } = machineDetails || {};

  const { t } = useLocale();
  const hasSubmissions = submissions?.length;
  const showRetireDate = retiredAt && showDates;
  const showPublishedAt = publishedAt && showDates;

  const activities = submissions?.map((submission) => (
    <MachineActivityCard
      key={submission?.id}
      submission={submission}
    />
  ));

  const renderEmptyActivity = () => {
    const content = {
      desc: [{
        text: t('machines.noMachineActivity'),
        variant: 'bodyStandardRegular',
      }],
    };

    return (
      <Box mt={30}>
        <NoContentPlaceholder content={content} />
      </Box>
    );
  };

  return (
    <Box>
      <Stack
        direction="row"
        flexWrap="wrap"
        rowGap={6}
        columnGap={6}
        my={7}
      >
        {showPublishedAt && (
          <ActivityDateCard
            date={publishedAt}
            type="publish"
          />
        )}
        {showRetireDate && (
          <ActivityDateCard
            date={retiredAt}
            type="retire"
          />
        )}
      </Stack>
      <Box mb={4}>
        <Box>
          <Typography variant="bodyMediumMedium">
            {t('machines.machineHackers')}
          </Typography>
        </Box>
        <Box>
          <Typography
            variant="bodySmallRegular"
            color="text.secondary"
          >
            {t('machines.machineHackersDesc')}
          </Typography>
        </Box>
      </Box>
      {hasSubmissions ? (
        <Stack spacing={5}>
          {activities}
        </Stack>
      ) : renderEmptyActivity()}
    </Box>
  );
};

export default MachineActivity;
