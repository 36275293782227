import { useTheme } from '@mui/styles';

const PromoCodeFilledIcon = (props) => {
  const theme = useTheme();
  const {
    stroke = theme.palette.text.secondary,
    fill = theme.palette.paper.hover,
    ...rest
  } = props;

  return (
    <svg width="48" height="44" viewBox="0 0 48 44" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <rect width="48" height="44" rx="6" fill={fill} />
      <path d="M34.3125 24.75V27.5C34.3125 27.8647 34.1676 28.2144 33.9098 28.4723C33.6519 28.7301 33.3022 28.875 32.9375 28.875H15.0625C14.6978 28.875 14.3481 28.7301 14.0902 28.4723C13.8324 28.2144 13.6875 27.8647 13.6875 27.5V24.75C14.4168 24.75 15.1163 24.4603 15.632 23.9445C16.1478 23.4288 16.4375 22.7293 16.4375 22C16.4375 21.2707 16.1478 20.5712 15.632 20.0555C15.1163 19.5397 14.4168 19.25 13.6875 19.25V16.5C13.6875 16.1353 13.8324 15.7856 14.0902 15.5277C14.3481 15.2699 14.6978 15.125 15.0625 15.125H32.9375C33.3022 15.125 33.6519 15.2699 33.9098 15.5277C34.1676 15.7856 34.3125 16.1353 34.3125 16.5V19.25C33.5832 19.25 32.8837 19.5397 32.368 20.0555C31.8522 20.5712 31.5625 21.2707 31.5625 22C31.5625 22.7293 31.8522 23.4288 32.368 23.9445C32.8837 24.4603 33.5832 24.75 34.3125 24.75Z" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" />
      <path d="M20.5625 20.625C21.3219 20.625 21.9375 20.0094 21.9375 19.25C21.9375 18.4906 21.3219 17.875 20.5625 17.875C19.8031 17.875 19.1875 18.4906 19.1875 19.25C19.1875 20.0094 19.8031 20.625 20.5625 20.625Z" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" />
      <path d="M28.125 26.125C28.8844 26.125 29.5 25.5094 29.5 24.75C29.5 23.9906 28.8844 23.375 28.125 23.375C27.3656 23.375 26.75 23.9906 26.75 24.75C26.75 25.5094 27.3656 26.125 28.125 26.125Z" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" />
      <path d="M27.4375 18.5625L20.5625 25.4375" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};
export default PromoCodeFilledIcon;
