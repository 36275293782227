import React from 'react';
import ReactGA from 'react-ga';

const { REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID } = process.env;

const GoogleAnalytics = () => {
  React.useEffect(() => {
    // Google analytics
    if (REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID) {
      ReactGA.initialize(REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID);
      ReactGA.pageview(window.location.pathname + window.location.search);
    }
  }, []);

  return null;
};

export default GoogleAnalytics;
