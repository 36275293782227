import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

import { Box, Card, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import {
  FormAutocompleteSelect,
  FormSelect,
  FormTextField,
} from 'components/form';
import { moveCompanyMember, updateUserProfile } from 'services';
import {
  useLocale, useFastForm, useSnackbar, usePermission,
} from 'util/hooks';
import { getDirtyObject, REGEX } from 'util/helpers';
import { PATHS, authRoles } from 'routes';
import { getRoleByName } from 'assets/constants/userRoles';
import PERMISSIONS from 'assets/constants/permissions';
import { useCompanies, useUserInfo } from 'reactQuery/queries';

const EditCompanyUserForm = (props) => {
  const { companyName } = props;

  const { t } = useLocale();
  const snack = useSnackbar();
  const navigate = useNavigate();
  const { data: user = {} } = useUserInfo();

  const canViewAllCompanies = usePermission(PERMISSIONS.companyViewAll);

  const { data: { items: companiesList = [] } = {} } = useCompanies({
    options: {
      enabled: canViewAllCompanies,
    },
  });

  const roleOptions = [
    getRoleByName(authRoles.companyManager),
    getRoleByName(authRoles.companyOperator),
  ].map((option) => ({ ...option, label: t(option?.localizationKey) }));

  const defaultValues = {
    email: user?.email || '',
    username: user?.username || '',
    role: user?.role?.id || roleOptions?.[0]?.id,
    company:
      companiesList.find((company) => company.name === companyName) || {},
  };

  const validationSchema = Yup.object({
    email: Yup.string()
      .email(t('email.validation'))
      .matches(REGEX.mail, t('email.validation'))
      .required(t('email.enterEmail')),
    username: Yup.string()
      .matches(REGEX.alphaNumericUnderscore, t('username.validation'))
      .max(35, t('username.maxLength'))
      .min(4, t('username.minLength'))
      .required(t('username.enter')),
    role: Yup.string().required(t('invitation.enterRole')),
    company: Yup.object()
      .nullable()
      .required(t('companies.validation.selectCompany')),
  });

  const {
    control,
    handleSubmit,
    reset,
    formState: { isSubmitting, isDirty, isValid },
  } = useFastForm({
    defaultValues,
    validationSchema,
  });

  const onSubmit = async (values) => {
    const dirtyValues = getDirtyObject(values, defaultValues);

    const hasUsernameChanged = Boolean(dirtyValues?.username);
    const hasCompanyChanged = Boolean(dirtyValues?.company);

    const payload = {
      ...dirtyValues,
      role: { id: Number(dirtyValues.role) },
    };

    try {
      if (hasCompanyChanged) {
        const companyPayload = {
          currentCompany: companyName,
          memberId: user?.id,
          company: { id: dirtyValues.company.id },
        };
        await moveCompanyMember(companyPayload);
      }

      const newUserData = await updateUserProfile(payload, user?.username);
      if (hasUsernameChanged || hasCompanyChanged) {
        const navigationPath = canViewAllCompanies
          ? `/${PATHS.users}/${PATHS.companies}/${companyName}/members`
          : PATHS.root;

        navigate(navigationPath, { replace: true });
      } else {
        reset({ ...newUserData, role: newUserData.role.id });
      }

      snack({
        message: t('common.profileUpdated'),
        severity: 'success',
      });
    } catch (error) {
      snack({
        message: error.errors[0].message || t('common.errorMessage'),
        severity: 'error',
      });
    }
  };

  const personalInformationForm = (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box
        sx={{
          mt: 6,
          display: 'grid',
          columnGap: 12,
          rowGap: 8,
          gridTemplateColumns: {
            xs: 'repeat(1, 1fr)',
            md: 'repeat(2, 1fr)',
            lg: 'repeat(2, 304px)',
            xl: 'repeat(2, 420px)',
          },
        }}
      >
        <Box>
          <FormTextField
            name="email"
            label={t('common.email')}
            sx={{ m: 0 }}
            control={control}
            autoComplete="email"
            disabled={isSubmitting}
            fullWidth
          />
        </Box>
        <Box>
          <FormTextField
            name="username"
            label={t('common.username')}
            sx={{ m: 0 }}
            control={control}
            autoComplete="username"
            disabled={isSubmitting}
            fullWidth
          />
        </Box>
        <Box>
          <FormSelect
            name="role"
            control={control}
            options={roleOptions}
            label={t('common.role')}
            disabled={isSubmitting}
            fullWidth
          />
        </Box>
        {canViewAllCompanies && (
          <Box>
            <FormAutocompleteSelect
              name="company"
              label={t('common.company')}
              control={control}
              optionKey="name"
              autoCompleteProps={{
                options: companiesList,
              }}
              textFieldProps={{
                placeholder: t('events.teams.chooseTeam'),
              }}
              disabled={isSubmitting}
              fullWidth
            />
          </Box>
        )}
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          columnGap: 2,
          mt: 8,
        }}
      >
        <LoadingButton
          type="submit"
          variant="contained"
          size="medium"
          disabled={!isDirty || isSubmitting || !isValid}
          loading={isSubmitting}
        >
          {t('common.saveChanges')}
        </LoadingButton>
      </Box>
    </form>
  );

  return (
    <Card
      id="personal-information-form"
      sx={{
        mt: 9,
        px: { xs: 6, sm: 14 },
        py: 8,
      }}
    >
      <Typography variant="bodyStandardMedium" sx={{ color: 'text.secondary' }}>
        {t('common.personalInfo')}
      </Typography>
      {personalInformationForm}
    </Card>
  );
};

EditCompanyUserForm.propTypes = {
  companyName: PropTypes.string,
};

EditCompanyUserForm.defaultProps = {
  companyName: '',
};

export default EditCompanyUserForm;
