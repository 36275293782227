import React from 'react';
import {
  GoogleRecaptcha,
  Observers,
} from 'components/helpers';
import { Toast } from 'components/render';
import { SocketProvider } from 'context';
import { useLocale } from 'util/hooks';

import useDateDefaults from './useDateDefaults';

const AppHelpers = (props) => {
  const { children } = props;

  const { dir } = useLocale();
  useDateDefaults(); // set date defaults

  React.useEffect(() => {
    document.dir = dir;
  }, [dir]);

  return (
    <SocketProvider>
      <Observers />
      <GoogleRecaptcha />
      <div>
        <Toast />
        {children}
      </div>
    </SocketProvider>
  );
};

export default AppHelpers;
