import { useNavigate } from 'react-router-dom';

import { DataGrid } from 'components/core';
import { useEventTeams } from 'reactQuery/queries';
import { Box, Typography } from '@mui/material';
import { TwoUsersIcon } from 'assets/icons';
import { useLocale } from 'util/hooks';
import useTableColumns from './useTableColumns';

const EventTeamTable = () => {
  const {
    data = {},
    isFetching = true,
    refetch: refetchEvenTeams,
  } = useEventTeams();

  const { t } = useLocale();
  const navigate = useNavigate();
  const columns = useTableColumns({ refetchEvenTeams });

  const {
    items: rows = [],
    meta = {},
  } = data || {};

  const teamCount = (
    <Box sx={{
      gap: 2,
      px: 2,
      py: 2.5,
      borderRadius: 1,
      display: 'flex',
      justifyContent: 'end',
      alignItems: 'center',
      backgroundColor: 'background.default',
    }}
    >
      <TwoUsersIcon width="16" height="16" />
      <Typography variant="bodySmallRegular" color="text.secondary">
        {`${meta?.totalItems?.toLocaleString()} ${t('events.teams.teams')}`}
      </Typography>
    </Box>
  );

  return (
    <DataGrid
      rows={rows}
      columns={columns}
      loading={isFetching}
      pagination={meta}
      onRowClick={({ row }) => {
        const { id: teamId = '' } = row || {};
        navigate(teamId);
      }}
      componentsProps={{
        toolbar: { endElement: teamCount },
      }}
      sx={{
        '& .MuiDataGrid-row:hover': {
          cursor: 'pointer',
        },
      }}
    />
  );
};

export default EventTeamTable;
