import END_POINTS from '../../endPoints';
import METHODS from '../../methods';
import api from '../../api';

const moveTeamMember = (memberId, data) => api(
  END_POINTS.moveTeamMember(memberId),
  {
    method: METHODS.PATCH,
    data,
  },
)
  .then((res) => Promise.resolve(res))
  .catch((error) => Promise.reject(error));

export default moveTeamMember;
