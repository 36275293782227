import END_POINTS from '../endPoints';
import METHODS from '../methods';
import api from '../api';

const connectAccount = (data, options) => api(
  END_POINTS.connectAccount,
  {
    method: METHODS.POST,
    data,
    requireAuth: false,
    ...options,
  },
)
  .then((response) => Promise.resolve(response))
  .catch((error) => Promise.reject(error));

export default connectAccount;
