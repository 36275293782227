export const DIFFICULTIES = [
  {
    id: 1,
    name: 'easy',
    localizationKey: 'difficulties.easy',
  },
  {
    id: 2,
    name: 'medium',
    localizationKey: 'difficulties.medium',
  },
  {
    id: 3,
    name: 'hard',
    localizationKey: 'difficulties.hard',
  },
  {
    id: 4,
    name: 'insane',
    localizationKey: 'difficulties.insane',
  },
];

export const getDifficultyById = (id) => DIFFICULTIES
  .find((difficulty) => difficulty.id === Number(id));
export const getDifficultyByName = (name) => DIFFICULTIES
  .find((difficulty) => difficulty.name === String(name));
export const getDifficultyByKey = (key, value) => DIFFICULTIES
  .find((difficulty) => difficulty[key] === value);
