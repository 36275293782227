import {
  useEffect,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import { useController } from 'react-hook-form';

import {
  Box, FormHelperText, InputLabel,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { generatePresignedUrl, uploadFileToS3 } from 'services';
import { useLocale, useSnackbar } from 'util/hooks';
import { UploadedFile } from 'components/molecules';

const FormSingleImageUpload = (props) => {
  const {
    image,
    label,
    setError, // Need to pass manually, ex, setError={(msg) => setError('files', msg)}
    disabled,
    description,
    ...rest
  } = props;

  const { field, fieldState } = useController(rest);
  const { onChange } = field;
  const { error } = fieldState;

  const hasErrored = Boolean(error);
  const isUploadButtonDisabled = disabled;

  const { t } = useLocale();
  const snack = useSnackbar();

  const [currentImage, setCurrentImage] = useState(image);
  const [isUploadLoading, setIsUploadLoading] = useState(false);

  useEffect(() => () => {
    // Make sure to revoke the data uris to avoid memory leaks
    URL.revokeObjectURL(currentImage?.preview);
  }, [currentImage]);

  const uploadToS3 = async (fileToUpload) => {
    setIsUploadLoading(true);
    try {
      // Step 1 - get url and fileName
      const { uploadUrl, fileName } = await generatePresignedUrl();
      // Step 2 - upload to S3
      await uploadFileToS3(uploadUrl, fileToUpload);

      // Step 3 - update form with fileName
      onChange(fileName);
    } catch (err) {
      snack({
        message: err.message || t('common.errorMessage'),
        severity: 'error',
      });
    }
    setIsUploadLoading(false);
  };

  const onDelete = () => {
    setCurrentImage('');
    onChange('');
  };

  // ========= Dropzone =========
  const onDrop = async (acceptedImage, fileRejections) => {
    if (fileRejections?.[0]) {
      setError({
        type: 'dropzone',
        message: fileRejections?.[0]?.errors?.[0]?.message,
      });
    }
    const newImage = Object.assign(acceptedImage[0], {
      preview: URL.createObjectURL(acceptedImage[0]),
    });
    await uploadToS3(newImage);
    setCurrentImage(newImage?.preview);
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'image/*': ['.jpeg', '.jpg', '.png', '.gif'],
    },
    multiple: false,
    disabled: isUploadButtonDisabled,
    onDrop,
  });

  return (
    <Box maxWidth="md">
      <Box>
        <InputLabel
          htmlFor={rest.id || rest.name}
          sx={(theme) => ({
            ...theme.typography.bodyStandardRegular,
            color: 'text.secondary',
          })}
        >
          {label}
        </InputLabel>
      </Box>
      {currentImage
        ? (
          <Box my={6}>
            <Box sx={{
              mb: 4,
              maxWidth: 600,
              maxHeight: 600,
            }}
            >
              <img
                src={currentImage}
                alt="announcement"
                style={{
                  width: '100%',
                  height: '100%',
                }}
              />
            </Box>
            <UploadedFile
              key={currentImage}
              url={currentImage}
              name={t('common.downloadImage')}
              onDelete={() => onDelete()}
              sx={{
                width: {
                  xs: 1,
                  md: 420,
                  lg: 304,
                  xl: 420,
                },
              }}
            />
          </Box>
        )
        : (
          <Box
            my={2}
          >
            {description
                && (
                <Box
                  sx={(theme) => ({
                    ...theme.typography.bodySmallRegular,
                    color: 'text.secondary',
                    mt: 2,
                    mb: 4,
                  })}
                >
                  {description}
                </Box>
                )}
            <Box sx={{
              display: 'flex',
            }}
            >
              <Box {...getRootProps()}>
                <input
                  {...getInputProps()}
                />
                <LoadingButton
                  size="small"
                  variant="outlined"
                  color="secondary"
                  disabled={isUploadButtonDisabled}
                  loading={isUploadLoading}
                >
                  {t('common.uploadImage')}
                </LoadingButton>
              </Box>
            </Box>
          </Box>
        )}
      <FormHelperText error={hasErrored}>{error?.message}</FormHelperText>
    </Box>
  );
};

FormSingleImageUpload.propTypes = {
  label: PropTypes.string,
  setError: PropTypes.func,
  image: PropTypes.string,
  disabled: PropTypes.bool,
  description: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};

FormSingleImageUpload.defaultProps = {
  label: '',
  setError: () => { },
  image: '',
  disabled: false,
  description: {},
};

export default FormSingleImageUpload;
