import { Box, Typography } from '@mui/material';

const DashboardSectionHeader = (props) => {
  const { title = '' } = props;

  return (
    <Box>
      <Typography variant="bodySmallMedium" color="text.secondary">
        {title}
      </Typography>
    </Box>
  );
};

export default DashboardSectionHeader;
