import {
  Chip, Link, Typography, Box,
} from '@mui/material';
import { GridActionsCellItem } from '@mui/x-data-grid';
import { getStatusByName, STATUS } from 'assets/constants/statuses';
import { getRoleById } from 'assets/constants/userRoles';
import {
  CrossCircleIcon,
  ActiveUserIcon,
  EditInfoIcon,
} from 'assets/icons';
import { useLocale } from 'util/hooks';
import { useLocation, useParams } from 'react-router-dom';
import { PATHS } from 'routes';
import { RoleId } from 'routes/authRoles';
import { UserAwareAvatar } from 'components/molecules';

const useTableColumns = (props = {}) => {
  const { addModal = () => { } } = props;

  const { pathname } = useLocation();
  const { companyName } = useParams();
  const { t, dir } = useLocale();

  const columns = [
    {
      field: 'user',
      headerName: t('common.user'),
      sortable: false,
      flex: 1,
      minWidth: 180,
      renderCell: (params) => {
        const {
          avatar = '',
          username = '',
          role = {},
        } = params?.row || {};

        const isPlayer = role?.id === RoleId.player;

        const user = (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <UserAwareAvatar
              src={avatar}
              alt={username}
              sx={{ mr: 3 }}
              user={params.row}
            />
            <Typography variant="bodySmallMedium" color="text.primary">
              {username}
            </Typography>
          </Box>
        );

        return (
          isPlayer ? <Link to={`/profile/${username}`}>{user}</Link> : user
        );
      },
    },
    {
      field: 'email',
      headerName: t('common.email'),
      flex: 1,
      minWidth: 180,
    },
    {
      field: 'role',
      headerName: t('common.role'),
      flex: 1,
      minWidth: 100,
      valueGetter: (params) => t(getRoleById(params?.row?.role?.id)?.localizationKey),
    },
    {
      field: 'status',
      headerName: t('common.status'),
      flex: 1,
      minWidth: 100,
      renderCell: (params) => (
        <Chip
          size="small"
          color={params.row.status || ''}
          label={t(getStatusByName(params?.row?.status)?.localizationKey) || ''}
        />
      ),
    },
    {
      field: 'actions',
      headerName: t('common.actions'),
      type: 'actions',
      flex: 1,
      minWidth: 50,
      getActions: (params) => {
        const { row: { status } } = params;
        const isActive = status === STATUS.active;
        const isPending = status === STATUS.pending;
        const isSuspended = status === STATUS.suspended;

        const canUpdateInfo = pathname === (`/${PATHS.users}/${PATHS.players}`)
          || pathname === (`/${PATHS.users}/${PATHS.companies}/${companyName}/members`);

        const actions = [];

        if (canUpdateInfo) {
          actions.push(<GridActionsCellItem
            component={Link}
            dir={dir}
            icon={<EditInfoIcon width="20" height="20" />}
            label={t('actions.updateInformation')}
            to={params?.row?.username}
            showInMenu
          />);
        }
        if (isActive) {
          actions.push(<GridActionsCellItem
            dir={dir}
            icon={<CrossCircleIcon width="20" height="20" />}
            label={t('actions.suspendUser')}
            onClick={() => addModal({
              type: 'suspend',
              user: { ...params.row },
            })}
            showInMenu
          />);
        } else if (isSuspended || isPending) {
          actions.push(<GridActionsCellItem
            dir={dir}
            icon={<ActiveUserIcon width="20" height="20" />}
            label={t('actions.activateUser')}
            onClick={() => addModal({
              type: 'activate',
              user: { ...params.row },
            })}
            showInMenu
          />);
        }

        return actions;
      },
    },
  ];

  return columns;
};

export default useTableColumns;
