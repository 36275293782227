import {
  Box,
  Typography,
} from '@mui/material';

import { useLocale } from 'util/hooks';

const CompareHeader = () => {
  const { t } = useLocale();

  return (
    <Box
      maxWidth="md"
      textAlign="center"
    >
      <Typography
        variant="h5"
        color="text.lighterGray"
        align="center"
        sx={{ mb: 3 }}
      >
        {t('subscriptions.plansFeaturesComparisons')}
      </Typography>
    </Box>
  );
};

export default CompareHeader;
