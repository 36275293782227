import * as Yup from 'yup';
import { useNavigate, useLocation } from 'react-router-dom';

import {
  Box, Button,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { FormAutocompleteSelect, FormTextField } from 'components/form';
import { useLocale, useFastForm, useSnackbar } from 'util/hooks';
import { PATHS } from 'routes';
import {
  getDirtyObject,
  REGEX,
} from 'util/helpers';
import {
  createLab, editLab,
} from 'services';

import LAB_TYPES from 'assets/constants/labTypes';
import { useChallengeCategories, useLabDetails } from 'reactQuery/queries';

const LabDetailsForm = (props) => {
  const {
    createMode = false,
    onClickCancel = () => { },
  } = props;

  const { t } = useLocale();
  const snack = useSnackbar();
  const navigate = useNavigate();
  const location = useLocation();

  const {
    data: challengeCategories = [],
  } = useChallengeCategories();

  const {
    data: labDetails = {},
    refetch: refetchLab = () => { },
  } = useLabDetails({
    options: {
      enabled: !createMode, // don't request in create mode
    },
  });

  const { state } = location;
  const isTrainingLab = state?.type === LAB_TYPES.training
    || labDetails?.type === LAB_TYPES.training;

  const onCancel = () => {
    if (createMode) {
      navigate(`/${PATHS.labs}`);
    } else {
      onClickCancel();
    }
  };

  const defaultValues = {
    type: labDetails?.type || null,
    challengesCategory: labDetails?.challengesCategory || null,
    nameEn: labDetails?.nameEn || '',
    nameAr: labDetails?.nameAr || '',
    descriptionEn: labDetails?.descriptionEn || '',
    descriptionAr: labDetails?.descriptionAr || '',
  };

  const validationSchema = Yup.object({
    ...(isTrainingLab && { // only require and validate category with training labs
      challengesCategory: Yup
        .object()
        .nullable()
        .required(t('labs.validation.enterLabCategory')),
    }),
    nameEn: Yup
      .string()
      .trim()
      .matches(REGEX.alphaNumericSpace, t('labs.validation.enterEnglishName'))
      .required(t('labs.validation.enterEnglishName'))
      .min(1, t('labs.validation.nameMinLength'))
      .max(32, t('labs.validation.nameMaxLength')),
    nameAr: Yup
      .string()
      .trim()
      .matches(REGEX.alphaNumericAr, t('labs.validation.enterArabicName'))
      .required(t('labs.validation.enterArabicName'))
      .min(1, t('labs.validation.nameMinLength'))
      .max(32, t('labs.validation.nameMaxLength')),
    descriptionEn: Yup
      .string()
      .matches(REGEX.alphaNumericSpaceSpecialCharacter, t('labs.validation.enterEnglishDescription'))
      .min(1, t('labs.validation.descMinLength'))
      .max(255, t('labs.validation.descMaxLength')),
    descriptionAr: Yup
      .string()
      .matches(REGEX.alphaNumericArSpaceSpecialCharacter, t('labs.validation.enterArabicDescription'))
      .min(1, t('labs.validation.descMinLength'))
      .max(255, t('labs.validation.descMaxLength')),
  });

  const {
    control,
    handleSubmit,
    setError,
    watch,
    formState: {
      errors, isSubmitting, isDirty, isValid,
    },
  } = useFastForm({
    defaultValues,
    validationSchema,
  });

  const onSubmit = async (values) => {
    const dirtyPayload = getDirtyObject(values, defaultValues);
    const payload = {
      ...values,
      type: state?.type,
    };

    try {
      let message = t('labs.updateSuccess');

      if (createMode) {
        const { id } = await createLab(payload);
        message = t('labs.createSuccess');
        navigate(`/${PATHS.labs}/${id}`);
      } else {
        await editLab(dirtyPayload, labDetails?.id);
        onClickCancel();
        refetchLab();
      }

      snack({
        message,
        severity: 'success',
      });
    } catch (error) {
      error.errors?.forEach((err) => {
        setError(err.property, {
          type: 'api',
          message: err.message,
        });
        if (!err.property || !watch(err.property)) {
          snack({
            severity: 'error',
            message: err.message || t('common.somethingWrong'),
          });
        }
      });

      if (!errors) { // Non-form errors
        snack({
          severity: 'error',
          message: error.message || t('common.somethingWrong'),
        });
      }
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box>
        {isTrainingLab && (
          <Box sx={{
            maxWidth: {
              lg: 304,
              xl: 420,
            },
          }}
          >
            <FormAutocompleteSelect
              name="challengesCategory"
              label={t('labs.labCategory')}
              control={control}
              autoCompleteProps={{
                options: challengeCategories,
              }}
              disabled={isSubmitting}
              fullWidth
            />
          </Box>
        )}
        <Box sx={{
          display: 'grid',
          columnGap: 12,
          rowGap: 8,
          gridTemplateColumns: {
            xs: 'repeat(1, 1fr)',
            md: 'repeat(2, 1fr)',
            lg: 'repeat(2, 304px)',
            xl: 'repeat(2, 420px)',
          },
        }}
        >
          <Box>
            <FormTextField
              label={t('labs.labNameEn')}
              name="nameEn"
              control={control}
              disabled={isSubmitting}
              fullWidth
              showCharCount
            />
          </Box>
          <Box>
            <FormTextField
              label={t('labs.labNameAr')}
              name="nameAr"
              control={control}
              disabled={isSubmitting}
              fullWidth
              showCharCount
            />
          </Box>
          <Box>
            <FormTextField
              label={t('labs.labDescriptionEn')}
              name="descriptionEn"
              control={control}
              disabled={isSubmitting}
              fullWidth
              multiline
              minRows={5}
              maxRows={5}
              inputProps={{ maxLength: 255 }}
              showCharCount
            />
          </Box>
          <Box>
            <FormTextField
              label={t('labs.labDescriptionAr')}
              name="descriptionAr"
              control={control}
              disabled={isSubmitting}
              fullWidth
              multiline
              minRows={5}
              maxRows={5}
              inputProps={{ maxLength: 255 }}
              showCharCount
            />
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          mt: 8,
        }}
      >
        <Box sx={{ mx: 2 }}>
          <LoadingButton
            type="submit"
            variant="contained"
            size="medium"
            loading={isSubmitting}
            disabled={!isDirty || isSubmitting || !isValid}
          >
            {t('common.save')}
          </LoadingButton>
        </Box>
        <Box>
          <Button
            onClick={onCancel}
            disabled={isSubmitting}
            sx={{
              color: 'text.raspberry',
            }}
          >
            {t('common.cancel')}
          </Button>
        </Box>
      </Box>
    </form>
  );
};

export default LabDetailsForm;
