import { useTheme } from '@mui/styles';

const OutLineStarIcon = (props) => {
  const { palette } = useTheme();
  const {
    stroke = palette.text.secondary,
    fill = 'none',
    ...rest
  } = props;

  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill={fill} xmlns="http://www.w3.org/2000/svg" {...rest}>
      <g clipPath="url(#clip0_10602_28983)">
        <path
          d="M8.48587 0.800092L10.7165 5.21943L15.0099 5.64476C15.1121 5.65325 15.2097 5.69085 15.2912 5.75311C15.3727 5.81537 15.4347 5.89968 15.4697 5.99606C15.5048 6.09244 15.5115 6.19685 15.4891 6.29693C15.4667 6.39701 15.416 6.48856 15.3432 6.56076L11.8099 10.0628L13.1199 14.8214C13.1467 14.9224 13.1437 15.0289 13.1114 15.1282C13.0791 15.2275 13.0188 15.3154 12.9378 15.3812C12.8567 15.4471 12.7583 15.4881 12.6545 15.4994C12.5507 15.5106 12.4458 15.4917 12.3525 15.4448L7.99987 13.2894L3.6532 15.4421C3.5599 15.489 3.45503 15.508 3.35121 15.4967C3.24739 15.4854 3.14903 15.4444 3.06797 15.3786C2.98691 15.3127 2.9266 15.2248 2.8943 15.1255C2.86199 15.0262 2.85906 14.9197 2.88587 14.8188L4.19587 10.0601L0.659866 6.55809C0.587021 6.4859 0.536388 6.39434 0.513963 6.29426C0.491537 6.19418 0.498259 6.08977 0.53333 5.9934C0.568402 5.89702 0.630356 5.81271 0.711857 5.75045C0.793357 5.68819 0.890992 5.65059 0.9932 5.64209L5.28653 5.21676L7.51387 0.800092C7.55967 0.710626 7.62929 0.63554 7.71504 0.583107C7.80079 0.530674 7.89935 0.50293 7.99987 0.50293C8.10038 0.50293 8.19894 0.530674 8.28469 0.583107C8.37044 0.63554 8.44006 0.710626 8.48587 0.800092V0.800092Z"
          stroke={stroke}
          strokeWidth="1.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_10602_28983">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default OutLineStarIcon;
