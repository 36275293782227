import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import {
  MachineOverview,
  MachineStats,
  MachineTabs,
} from 'components/organisms';

const PlayerViewMachineContainer = (props) => {
  const { machineDetails = {} } = props;
  return (

    <Stack spacing={4} maxWidth="xl">

      <Box>
        <MachineStats machineDetails={machineDetails} />
      </Box>
      <Box>
        <MachineOverview machineDetails={machineDetails} />
      </Box>
      <Box>
        <MachineTabs machineDetails={machineDetails} />
      </Box>
    </Stack>
  );
};

export default PlayerViewMachineContainer;
