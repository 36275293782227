import { LoadingButton } from '@mui/lab';
import { Box } from '@mui/material';
import { useLocale } from 'util/hooks';
import CHALLENGE_FILES_TYPES from 'assets/constants/challengeFilesTypes';
import useDownloadChallengeFiles from '../helpers/useDownloadChallengeFiles';

const ChallengeWriteupFilesCard = (props) => {
  const {
    isContentDisabled = false,
    isWriteUpFilesLocked = false,
  } = props;

  const { t } = useLocale();
  const {
    isLoading = false,
    handleDownload = () => { },
  } = useDownloadChallengeFiles({
    fileType: CHALLENGE_FILES_TYPES.writeUpFiles,
    action: 'DOWNLOAD_WRITEUP_FILES',
  });

  return (
    <Box>
      <LoadingButton
        loading={isLoading}
        variant="outlined"
        color="secondary"
        disabled={isContentDisabled || isWriteUpFilesLocked}
        onClick={handleDownload}
      >
        {t('common.download')}
      </LoadingButton>
    </Box>
  );
};

export default ChallengeWriteupFilesCard;
