import { useEffect } from 'react';

// ==============================|| OnlineWatcher ||============================== //

const OnlineWatcher = () => {
  useEffect(() => {
    const logOnlineStatus = (e) => {
      if (e.type === 'online') {
        // console.log('user is online');
      } else {
        // console.log('user is offline');
      }
    };

    window.addEventListener('online', logOnlineStatus);
    window.addEventListener('offline', logOnlineStatus);

    return () => {
      window.removeEventListener('online', logOnlineStatus);
      window.removeEventListener('offline', logOnlineStatus);
    };
  }, []);

  return null;
};

export default OnlineWatcher;
