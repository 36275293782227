import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const TypographyWithIcon = (props) => {
  const {
    label,
    labelProps,
    icon,
    iconProps,
    sx,
    ...rest
  } = props;

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        ...sx,
      }}
      {...rest}
    >
      <Box sx={{
        width: 19,
        height: 19,
        mx: 3,
        ...iconProps,
      }}
      >
        {icon}
      </Box>
      <Box>
        <Typography
          variant="bodyMediumBold"
          color="text.lighterGray"
          {...labelProps}
        >
          {label}
        </Typography>
      </Box>
    </Box>
  );
};

TypographyWithIcon.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.node]),
  labelProps: PropTypes.oneOfType([PropTypes.object]),
  icon: PropTypes.node,
  iconProps: PropTypes.oneOfType([PropTypes.object]),
};

TypographyWithIcon.defaultProps = {
  label: '',
  labelProps: {},
  icon: null,
  iconProps: {},
};

export default TypographyWithIcon;
