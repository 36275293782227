import { forwardRef } from 'react';
import PropTypes from 'prop-types';

import {
  Box,
  TextField as MUITextField,
  InputLabel,
} from '@mui/material';

const TextField = forwardRef((props, ref) => {
  const {
    label,
    labelProps,
    ...rest
  } = props;

  return (
    <Box sx={{
      minHeight: '103px', // handle helperText height to reduce ui shift
    }}
    >
      <InputLabel
        htmlFor={rest.id || rest.name}
        sx={(theme) => ({
          px: 2,
          color: 'text.secondary',
          ...theme.typography.bodyStandardRegular,
        })}
        {...labelProps}
      >
        {label || ''}
      </InputLabel>
      <MUITextField
        {...rest}
        inputRef={ref}
      />
    </Box>
  );
});

TextField.propTypes = {
  label: PropTypes.node,
  labelProps: PropTypes.oneOfType([PropTypes.object]),
};

TextField.defaultProps = {
  label: null,
  labelProps: {},
};

export default TextField;
