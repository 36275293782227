import { DataGrid } from 'components/core';

import { useAnnouncements } from 'reactQuery/queries';
import useTableColumns from './useTableColumns';

const AnnouncementsTable = () => {
  const {
    data = {},
    isFetching = false,
    refetch,
  } = useAnnouncements();

  const columns = useTableColumns({
    fetchAnnouncements: refetch,
  });

  const {
    items: rows = [],
    meta = {},
  } = data || {};

  return (
    <DataGrid
      rows={rows}
      columns={columns}
      loading={isFetching}
      pagination={meta}
      componentsProps={{
        noRowsOverlay: { type: 'announcement' },
      }}
    />
  );
};

export default AnnouncementsTable;
