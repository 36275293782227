import { useParams } from 'react-router-dom';

import { getLabChallengeDetails } from 'services';
import { useCustomQuery } from 'reactQuery';

const useLabChallengeDetails = (queryProps = {}) => {
  const { labId, challengeId } = useParams();

  const query = useCustomQuery({
    queryKey: [`view-lab-challenge-${challengeId}`],
    queryFn: () => getLabChallengeDetails(labId, challengeId),
    ...queryProps,
    options: {
      enabled: !!labId && !!challengeId,
      ...queryProps?.options,
    },
  });

  return { ...query };
};

export default useLabChallengeDetails;
