import React from 'react';
import { IconButton, Stack, Typography } from '@mui/material';
import { CloseEyeIcon, OpenEyeIcon } from 'assets/icons';

const VisibilityToggleLabel = (props) => {
  const {
    label = '',
    buttonProps = {},
    labelProps = {},
  } = props;

  const [isVisible, setIsVisible] = React.useState(false);

  const toggleVisibility = () => setIsVisible(!isVisible);

  return (
    <Stack direction="row" gap={2} alignItems="center">
      <IconButton
        onClick={toggleVisibility}
        {...buttonProps}
      >
        {isVisible ? <CloseEyeIcon /> : <OpenEyeIcon />}
      </IconButton>
      <Typography {...labelProps}>
        {isVisible ? label : '**********'}
      </Typography>
    </Stack>
  );
};

export default VisibilityToggleLabel;
