import END_POINTS from '../endPoints';
import METHODS from '../methods';
import api from '../api';

const updatePasswordService = (data, options) => api(
  END_POINTS.updatePassword,
  {
    method: METHODS.PUT,
    data,
    ...options,
  },
)
  .then((res) => Promise.resolve(res))
  .catch((error) => Promise.reject(error));

export default updatePasswordService;
