import PropTypes from 'prop-types';

import { useLocale } from 'util/hooks';
import {
  Box,
  Typography,
} from '@mui/material';

const LabDescription = (props) => {
  const { labDetails } = props;
  const {
    descriptionAr = '',
    descriptionEn = '',
  } = labDetails;

  const { t } = useLocale();

  return (
    <Box sx={{
      display: 'flex',
      flexWrap: 'wrap',
      flexDirection: 'column',
    }}
    >
      <Box sx={{ mb: 11 }}>
        <Box>
          <Typography
            variant="bodyStandardBold"
            color="text.secondary"
          >
            {t('labs.labDescriptionEn')}
          </Typography>
        </Box>
        <Box>
          <Typography
            variant="bodyMediumBold"
            color="text.lighterGray"
          >
            {descriptionEn}
          </Typography>
        </Box>
      </Box>
      <Box>
        <Box>
          <Typography
            variant="bodyStandardBold"
            color="text.secondary"
          >
            {t('labs.labDescriptionAr')}
          </Typography>
        </Box>
        <Box>
          <Typography
            variant="bodyMediumBold"
            color="text.lighterGray"
          >
            {descriptionAr}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

LabDescription.propTypes = {
  labDetails: PropTypes.oneOfType([PropTypes.object]),
};

LabDescription.defaultProps = {
  labDetails: {},
};

export default LabDescription;
