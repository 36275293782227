import { forwardRef } from 'react';
import {
  Box,
  TextField,
  Autocomplete,
} from '@mui/material';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ClearIcon from '@mui/icons-material/Clear';

import { useLocale } from 'util/hooks';
import { countries } from 'assets/data';

const CountriesSelect = forwardRef((props, ref) => {
  const {
    name,
    error,
    ...rest
  } = props;

  const { isAr, dir } = useLocale();

  const getOptionLabel = (option) => {
    if (!option) return '';

    return (isAr ? option.nameAr : option.nameEn);
  };

  const isOptionEqualToValue = (option, value) => {
    if (value === '') return true; // Prevent onClear warning

    if (typeof value === 'object') {
      return option.id === value.id;
    }

    const optionString = isAr ? option.nameAr : option.nameEn;
    return optionString === value;
  };

  return (
    <Autocomplete
      ref={ref}
      id={name}
      options={countries}
      fullWidth
      popupIcon={<ExpandMore sx={{ color: 'text.secondary' }} />}
      clearIcon={<ClearIcon sx={{ color: 'text.secondary' }} />}
      getOptionLabel={getOptionLabel}
      isOptionEqualToValue={isOptionEqualToValue}
      renderOption={(optionProps, { code, nameAr, nameEn }) => (
        <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} dir={dir} {...optionProps}>
          <img
            loading="lazy"
            width="20"
            src={code ? `https://flagcdn.com/24x18/${code.toLowerCase()}.png` : null}
            srcSet={code ? `https://flagcdn.com/48x36/${code.toLowerCase()}.png 2x` : null}
            alt={`${code}-flag`}
          />
          {isAr ? nameAr : nameEn}
          {' '}
          (
          {code}
          )
        </Box>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          error={error}
          placeholder={rest.placeholder || ''}
        />
      )}
      {...rest}
    />
  );
});

export default CountriesSelect;
