import axios from 'axios';
import { forOwn } from 'lodash';
import FileSaver from 'file-saver';
import { setRecoil } from 'recoil-nexus';

import { authAtom } from 'recoil/atoms';
import { REGEX } from 'util/helpers';
import { getItem, removeItem, storageKeys } from 'util/localStorage';

const { REACT_APP_BASE_URL, REACT_APP_NAME } = process.env;

const exportTypes = {
  excel: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  pdf: 'pdf',
  zip: 'zip',
};

const fileExtensions = {
  excel: '.csv',
  pdf: '.pdf',
  zip: '.zip',
  ovpn: '.ovpn',
};

const DEFAULT_FILE_NAME = `${REACT_APP_NAME}_export_${new Date()
  .toLocaleDateString(
    'en-US',
    {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
    },
  )}`;

async function downloadApi(options = {}) {
  const {
    path,
    data = options.data || null,
    method = options.method || 'GET',
    requireAuth = options.requireAuth || true,
    // Options
    responseType = options.responseType || 'blob',
    exportType = exportTypes[options.exportType] || exportTypes.pdf,
    fileName = options.fileName || `${DEFAULT_FILE_NAME}${fileExtensions[options.exportType || exportTypes.pdf]}`,
    pagination = options.pagination || null,
    searchOptions = options.searchOptions || null,
    filterOptions = options.filterOptions || null,
    recaptchaToken = options.recaptchaToken || undefined,
  } = options;

  const token = getItem(storageKeys.accessToken) || '';
  const selectedLanguage = getItem(storageKeys.i18nextLng);
  const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const buildUrl = () => {
    let complexUrl = '';

    complexUrl += REACT_APP_BASE_URL + path;

    if (!path.includes('?')
      && (pagination || filterOptions || searchOptions)) {
      complexUrl += '?';
    }

    // Pagination
    if (pagination) {
      if (pagination.page) {
        complexUrl += `& page=${pagination.page} `;
      }

      if (pagination.perPage) {
        complexUrl += `& perPage=${pagination.perPage} `;
      }
    }

    // Filters
    if (filterOptions) {
      const filterArray = [];
      forOwn(filterOptions, (value, key) => (value ? filterArray.push(`& ${key}=${value} `) : null));
      complexUrl += filterArray.join('');
    }

    // Search
    if (searchOptions) {
      const searchArray = [];
      forOwn(searchOptions, (value, key) => (value ? searchArray.push(`& ${key}=${value} `) : null));
      complexUrl += searchArray.join('');
    }

    // Replace ?& with ?
    complexUrl = complexUrl.replace(REGEX.questionAnd, '?');

    return complexUrl;
  };

  const config = {
    method,
    responseType,
    url: buildUrl(),
    // url: path,
    headers: {
      'Accept-Language': selectedLanguage,
      'x-flagyard-tz': tz,
      recaptcha: recaptchaToken,
      ...(requireAuth && { Authorization: `Bearer ${token} ` }),
    },
    data,
  };

  return axios(config)
    .then((response) => {
      const blob = new Blob([response.data], {
        type: exportTypes[exportType],
      });
      FileSaver.saveAs(blob, fileName);
    })
    .catch((error) => {
      const { response = {} } = error;

      if (response?.status === 401) {
        // logout logic
        removeItem(storageKeys.accessToken);
        setRecoil(authAtom, {
          isLoggedIn: false,
          accessToken: '',
          user: {},
        });
      }

      return Promise.reject(response?.data || response || error);
    });
}

export default downloadApi;
