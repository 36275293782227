import PropTypes from 'prop-types';

import { useLocale } from 'util/hooks';
import {
  Box,
  Typography,
} from '@mui/material';

const PlanName = (props) => {
  const { planDetails } = props;
  const {
    nameAr = '',
    nameEn = '',
  } = planDetails;

  const { t } = useLocale();

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: {
        xs: 'column',
        md: 'row',
      },
    }}
    >
      <Box sx={{
        mr: { md: 31 },
        mb: { xs: 4, md: 0 },
      }}
      >
        <Box>
          <Typography
            variant="bodyStandardBold"
            color="text.secondary"
          >
            {t('plans.planNameEn')}
          </Typography>
        </Box>
        <Box>
          <Typography
            variant="bodyMediumBold"
            color="text.lighterGray"
          >
            {nameEn}
          </Typography>
        </Box>
      </Box>
      <Box>
        <Box>
          <Typography
            variant="bodyStandardBold"
            color="text.secondary"
          >
            {t('plans.planNameAr')}
          </Typography>
        </Box>
        <Box dir="rtl">
          <Typography
            variant="bodyMediumBold"
            color="text.lighterGray"
          >
            {nameAr}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

PlanName.propTypes = {
  planDetails: PropTypes.oneOfType([PropTypes.object]),
};

PlanName.defaultProps = {
  planDetails: {},
};

export default PlanName;
