import { useTheme } from '@mui/styles';

const UserIcon = (props) => {
  const theme = useTheme();
  const {
    stroke = theme.palette.text.raspberry,
    fill = 'none',
  } = props;

  return (
    <svg width="22" height="22" viewBox="0 0 20 20" fill={fill} xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_911_540)">
        <path d="M10 9.375C12.4162 9.375 14.375 7.41625 14.375 5C14.375 2.58375 12.4162 0.625 10 0.625C7.58375 0.625 5.625 2.58375 5.625 5C5.625 7.41625 7.58375 9.375 10 9.375Z" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M1.875 19.375C1.875 17.2201 2.73102 15.1535 4.25476 13.6298C5.77849 12.106 7.84512 11.25 10 11.25C12.1549 11.25 14.2215 12.106 15.7452 13.6298C17.269 15.1535 18.125 17.2201 18.125 19.375" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      </g>
      <defs>
        <clipPath id="clip0_911_540">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default UserIcon;
