import * as Yup from 'yup';

import {
  Box,
  Card,
  Tooltip,
  Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

import {
  useAuth,
  useLocale,
  useFastForm,
  useSnackbar,
} from 'util/hooks';
import { downloadVPN } from 'services';
import { useVpnRegions } from 'reactQuery/queries';
import { FormAutocompleteSelect } from 'components/form';

const VpnConfigurationsCard = () => {
  const { t } = useLocale();
  const snack = useSnackbar();
  const { isVerified } = useAuth();

  const {
    data: regions = [],
  } = useVpnRegions();

  const defaultValues = {
    region: regions[0] || null,
  };

  const validationSchema = Yup.object({
    region: Yup
      .object()
      .nullable()
      .required(t('vpn.enterRegion')),
  });

  const onSubmit = async ({ region }) => {
    const { name: regionName } = region;

    try {
      await downloadVPN(regionName, region);
      snack({
        message: t('vpn.vpnDownloaded'),
        severity: 'success',
      });
    } catch (error) {
      snack({
        message: error?.errors?.[0]?.message || error.message || t('common.somethingWrong'),
        severity: 'error',
      });
    }
  };

  const {
    control,
    handleSubmit,
    formState: {
      isValid,
      isSubmitting,
    },
  } = useFastForm({
    defaultValues,
    validationSchema,
  });

  const vpnConfigurations = (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box>
        <Box sx={{
          maxWidth: 420,
        }}
        >
          <FormAutocompleteSelect
            name="region"
            optionKey="name"
            label={t('vpn.region')}
            control={control}
            autoCompleteProps={{
              options: regions,
            }}
          />
        </Box>
        <Tooltip
          title={t('vpn.disabledVPN')}
          placement="bottom-start"
          componentsProps={{
            tooltip: {
              sx: {
                display: !isVerified ? 'block' : 'none',
              },
            },
          }}
        >
          <Box mt={8}>
            <LoadingButton
              type="submit"
              variant="outlined"
              color="secondary"
              loading={isSubmitting}
              disabled={!isValid || !isVerified}
            >
              {t('vpn.downloadVPN')}
            </LoadingButton>
          </Box>
        </Tooltip>
      </Box>
    </form>
  );

  return (
    <Card
      id="vpn-configurations"
      sx={{
        px: { xs: 6, sm: 14 },
        py: 8,
      }}
    >
      <Box mb={6}>
        <Typography
          variant="bodyStandardMedium"
          sx={{ color: 'text.secondary' }}
        >
          {t('vpn.vpnConfigurations')}
        </Typography>
      </Box>
      {vpnConfigurations}
    </Card>
  );
};

export default VpnConfigurationsCard;
