import { Box, Typography } from '@mui/material';
import { AvatarWithBadge } from 'components/molecules';

import { capitalizeFirstLetter } from 'util/helpers';
import { useLocale } from 'util/hooks';

const UserHeader = (props) => {
  const { userDetails = {} } = props;

  const {
    avatar = '',
    username = '',
    isFreePlanUser = true,
    playerLevel = {},
    role = {},
    country: { code = '' } = {},
  } = userDetails || {};

  const { isAr } = useLocale();
  const levelName = isAr ? playerLevel?.nameAr : playerLevel?.nameEn;

  return (
    <Box>
      <Box sx={{
        display: 'flex',
        alignItems: 'center',
      }}
      >
        <Box sx={{ mr: 5 }}>
          <AvatarWithBadge
            avatarProps={{
              src: String(avatar),
              alt: capitalizeFirstLetter(username),
              user: { isFreePlanUser, role },
              sx: {
                width: 71,
                height: 71,
                fontWeight: 'bold',
                fontSize: 33,
              },
            }}
            badgeProps={{
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'right',
              },
              badgeContent: (
                <img
                  alt={`${code}-flag`}
                  src={`https://flagcdn.com/16x12/${code.toLowerCase()}.png`}
                  srcSet={`https://flagcdn.com/32x24/${code.toLowerCase()}.png 2x`}
                  width="20"
                  height="15"
                />
              ),
            }}
          />
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography
            variant="bodyStandardMedium"
            sx={{ color: 'text.primary' }}
          >
            {username}
          </Typography>
          <Typography
            variant="bodySmallRegular"
            sx={{ color: 'text.secondary' }}
          >
            {levelName}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default UserHeader;
