import {
  Box,
  Card,
  Grid,
} from '@mui/material';

import { TypographyWithIcon, NoContentPlaceholder } from 'components/molecules';
import { ChallengePlanIcon, CheckCircleOutline } from 'assets/icons';
import { useLocale, useModal, usePermission } from 'util/hooks';

import PERMISSIONS from 'assets/constants/permissions';
import { STATUS } from 'assets/constants/statuses';

import MODAL_KEYS from 'assets/constants/modalKeys';
import ChallengeSectionWrapper from '../helpers/ChallengeSectionWrapper';

const ChallengePlans = (props) => {
  const { challengeDetails = {} } = props;
  const { addModal } = useModal();

  const {
    status,
    plans = [],
  } = challengeDetails;

  const { t, isAr } = useLocale();

  const isActiveChallenge = status === STATUS.active;
  const isPendingChallenge = status === STATUS.pending;

  const canAssign = usePermission(PERMISSIONS.challengesAssignPlan);

  const showAssignButton = (isActiveChallenge || isPendingChallenge) && canAssign;

  const renderPlans = () => (
    <Grid item sx={{ mt: 1, mx: 16 }}>
      <Grid container direction="row" rowSpacing={5} columnSpacing={10}>
        {plans?.map((plan) => (
          <Grid item key={plan.id} sx={{ minWidth: '300px' }}>
            <TypographyWithIcon
              label={isAr ? plan.nameAr : plan.nameEn}
              icon={(
                <CheckCircleOutline />
              )}
            />
          </Grid>
        ))}
      </Grid>
    </Grid>
  );

  const renderNoPlans = () => {
    const content = {
      desc: [{
        text: t('challenges.notAssigned'),
      }],
    };

    return <NoContentPlaceholder content={content} />;
  };

  return (
    <Box>
      <Card sx={{ p: 14 }}>
        <ChallengeSectionWrapper
          icon={<ChallengePlanIcon />}
          title={t('challenges.challengePlans')}
          showAction={showAssignButton}
          actionLabel={plans?.length ? t('common.edit') : t('challenges.actions.assign')}
          onClickAction={() => {
            addModal({
              key: MODAL_KEYS.challenge,
              props: {
                type: 'setPlan',
                challenges: [challengeDetails],
                challengeDetailsView: true,
              },
            });
          }}
        />
        {plans?.length
          ? renderPlans()
          : renderNoPlans()}
      </Card>
    </Box>
  );
};

export default ChallengePlans;
