import {
  Box,
  Card,
  Typography,
} from '@mui/material';

import { LockedBackdrop } from 'components/molecules';

const ChallengeDetailsCardWrapper = (props) => {
  const {
    isLocked = false,
    icon = null,
    title = null,
    subtitle = null,
    header = null,
    component = null,
    cardProps = {},
  } = props;

  return (
    <Card
      {...cardProps}
      sx={{
        px: 5,
        py: 6,
        width: 1,
        minWidth: { sm: 358 },
        height: 1,
        minHeight: 164,
        position: 'relative', // For backdrop
        ...cardProps?.sx,
      }}
    >
      {isLocked && <LockedBackdrop />}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', sm: 'row' },
            alignItems: { xs: 'start', sm: 'center' },
            justifyContent: 'space-between',
            minHeight: 56, // reduce ui shift of buttons
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Box
              sx={{
                mr: 4,
                width: 48,
                height: 44,
              }}
            >
              {icon}
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Typography
                variant="bodyStandardMedium"
                color="text.lightGray"
              >
                {title}
              </Typography>
              <Typography
                variant="bodySmallRegular"
                color="text.secondary"
              >
                {subtitle}
              </Typography>
            </Box>
          </Box>
          <Box sx={{
            mt: { xs: 4, sm: 0 },
            alignSelf: 'start',
          }}
          >
            {header}
          </Box>
        </Box>
        <Box
          sx={{
            mt: 6,
            ml: { md: 16 },
          }}
        >
          {component}
        </Box>
      </Box>
    </Card>
  );
};

export default ChallengeDetailsCardWrapper;
