import { Box } from '@mui/material';
import PromoCodeHeader from './sections/PromoCodeHeader';
import PromoCodeInfo from './sections/PromoCodeInfo';
import PromoCodeStats from './sections/PromoCodeStats';

const ViewPromoCodeContainer = (props) => {
  const { codeDetails = {} } = props;

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <PromoCodeHeader codeDetails={codeDetails} />
      <PromoCodeStats codeDetails={codeDetails} />
      <PromoCodeInfo codeDetails={codeDetails} />
    </Box>
  );
};

export default ViewPromoCodeContainer;
