import { useTheme } from '@mui/styles';

const CalendarFilledIcon = (props) => {
  const { palette } = useTheme();
  const {
    stroke = palette.text.secondary,
    fill = palette.text.secondary,
    fillOpacity = '0.25',
    ...rest
  } = props;

  return (
    <svg width="46" height="45" viewBox="0 0 46 45" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <rect x="0.0605469" width="45" height="45" rx="7.125" fill={fill} fillOpacity={fillOpacity} />
      <g clipPath="url(#clip0_4788_57462)">
        <path d="M29.5605 16.5H16.5605C16.0083 16.5 15.5605 16.9477 15.5605 17.5V28.5C15.5605 29.0523 16.0083 29.5 16.5605 29.5H29.5605C30.1128 29.5 30.5605 29.0523 30.5605 28.5V17.5C30.5605 16.9477 30.1128 16.5 29.5605 16.5Z" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" />
        <path d="M15.5605 20.5H30.5605" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" />
        <path d="M19.5605 18V14.5" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" />
        <path d="M26.5605 18V14.5" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" />
      </g>
      <defs>
        <clipPath id="clip0_4788_57462">
          <rect width="16" height="16" fill="white" transform="translate(15.0605 14)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CalendarFilledIcon;
