import PropTypes from 'prop-types';
import {
  Box,
  Card,
  Grid,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { InformationCircleIcon } from 'assets/icons';

const InfoCard = (props) => {
  const {
    size,
    icon,
    title,
    cardSx,
    tooltipTitle,
    component,
    endComponent,
  } = props;

  const styles = {
    small: {
      titleVariant: 'bodySmallMedium',
      titleColor: 'text.lightGray',
      card: {
        display: 'flex',
        justifyContent: icon ? 'flex-start' : 'center',
        alignItems: 'center',
        pl: 8,
        pr: 2,
        minWidth: { md: 320 },
        height: 80,
        ...cardSx,
      },
      iconBox: {
        mr: 3,
        width: 45,
        height: 45,
      },
    },
    large: {
      titleVariant: 'bodyStandardBold',
      titleColor: 'text.secondary',
      card: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        p: 10,
        minWidth: 300,
        height: 154,
        ...cardSx,
      },
      iconBox: {
        mr: 4,
      },
    },
  };

  const style = styles[size];
  const tooltip = tooltipTitle && (
    <Box sx={{ ml: 2 }}>
      <Tooltip
        title={tooltipTitle}
        placement="top"
      >
        <Box sx={{ width: 14, height: 14 }}>
          <InformationCircleIcon width="14" height="14" />
        </Box>
      </Tooltip>
    </Box>
  );

  const iconWrapper = (icon ? <Box sx={style.iconBox}>{icon}</Box> : null);

  return (
    <Grid item xs>
      <Card sx={style.card}>
        {iconWrapper}
        <Stack sx={{
          flex: 1,
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          flexWrap: 'wrap',
        }}
        >
          <Stack sx={{ flex: 1 }}>
            <Stack direction="row">
              <Typography
                variant={style.titleVariant}
                color={style.titleColor}
                noWrap
              >
                {title}
              </Typography>
              {tooltip}
            </Stack>
            <Box width={1}>
              {component}
            </Box>
          </Stack>
          {endComponent}
        </Stack>
      </Card>
    </Grid>
  );
};

InfoCard.propTypes = {
  size: PropTypes.string,
  tooltipTitle: PropTypes.string,
};

InfoCard.defaultProps = {
  size: 'large',
  tooltipTitle: '',
};

export default InfoCard;
