import END_POINTS from '../../endPoints';
import METHODS from '../../methods';
import api from '../../api';

const toggleTeamBanning = (teamId) => api(
  END_POINTS.toggleTeamBanning(teamId),
  { method: METHODS.PATCH },
)
  .then((res) => Promise.resolve(res))
  .catch((error) => Promise.reject(error));

export default toggleTeamBanning;
