import { getLeaderBoardPlayers } from 'services';
import { useCustomQuery } from 'reactQuery';

const useLeaderboard = (queryProps = {}) => {
  const { queryKeyOptions = {}, ...rest } = queryProps;

  const query = useCustomQuery({
    queryKey: ['leaderboard', {
      searchOptions: {
        orderBy: 'points',
        searchFields: 'username',
      },
      ...queryKeyOptions,
    }],
    queryFn: getLeaderBoardPlayers,
    ...rest,
  });

  return { ...query };
};

export default useLeaderboard;
