import _ from 'lodash';
import END_POINTS from 'services/endPoints';
import METHODS from 'services/methods';
import api from '../api';

const updatePromoCodeStatus = (id, status) => api(
  END_POINTS.updatePromoCodeStatus(id, _.kebabCase(status)),
  {
    method: METHODS.PATCH,
  },
)
  .then((res) => Promise.resolve(res))
  .catch((error) => Promise.reject(error));

export default updatePromoCodeStatus;
