import { useTheme } from '@mui/styles';

const ActiveUserIcon = (props) => {
  const theme = useTheme();
  const {
    stroke = theme.palette.text.secondary,
    fill = 'none',
    ...rest
  } = props;

  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill={fill} xmlns="http://www.w3.org/2000/svg" stroke={stroke} {...rest}>
      <path d="M0.75 17.25C0.75076 16.1341 1.02804 15.0358 1.55706 14.0532C2.08607 13.0707 2.85033 12.2346 3.78149 11.6196C4.71265 11.0046 5.7817 10.63 6.89305 10.5292C8.00439 10.4284 9.12339 10.6046 10.15 11.042" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M7.5 9C9.77817 9 11.625 7.15317 11.625 4.875C11.625 2.59683 9.77817 0.75 7.5 0.75C5.22183 0.75 3.375 2.59683 3.375 4.875C3.375 7.15317 5.22183 9 7.5 9Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M17.25 23.25C20.5637 23.25 23.25 20.5637 23.25 17.25C23.25 13.9363 20.5637 11.25 17.25 11.25C13.9363 11.25 11.25 13.9363 11.25 17.25C11.25 20.5637 13.9363 23.25 17.25 23.25Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M19.9247 15.505L17.0197 19.379C16.9551 19.4649 16.8728 19.536 16.7784 19.5873C16.684 19.6387 16.5796 19.6692 16.4724 19.6768C16.3652 19.6844 16.2576 19.6689 16.1569 19.6313C16.0562 19.5937 15.9647 19.535 15.8887 19.459L14.3887 17.959" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default ActiveUserIcon;
