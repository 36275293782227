import React, { useState } from 'react';
import arSA from 'date-fns/locale/ar-SA';
import enUS from 'date-fns/locale/en-US';

import {
  format as _format,
  formatDistanceStrict as _formatDistance,
  add as _add,
  isBefore,
  isWithinInterval,
} from 'date-fns';

const defaults = {
  format: 'dd/MM/yyyy',
};

/**
 * Dates and date manipulation without the hassle of external imports
 * https://date-fns.org/v2.29.1/docs/Getting-Started
 */
const useDate = (props = {}) => {
  const {
    initialDate = new Date(),
    clockMode = false, // enables live time mode
    clockPeriod = 1000, // updates time every ...(ms)
  } = props;

  const [now, setNow] = useState(initialDate || new Date());

  // Updates now
  React.useEffect(() => {
    let interval;
    if (clockMode) {
      interval = setInterval(() => {
        setNow(new Date());
      }, clockPeriod);
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [clockMode, clockPeriod]);

  const format = React.useCallback((
    date = now,
    fo = defaults.format,
    options = {},
  ) => _format(new Date(date), fo, options), [now]);

  // Calculates diff in time between date and baseDate, defaults to now
  const diff = React.useCallback((
    date = now,
    baseDate = now,
    options = {},
  ) => _formatDistance(new Date(date), new Date(baseDate), options), [now]);

  // Calculates diff in time between date given and now, now being the base date
  const fromNow = React.useCallback((
    date = now,
    options = {},
  ) => {
    const combinedOptions = {
      addSuffix: true, // show 'in' and 'ago'
      ...options,
    };
    return diff(
      new Date(date),
      now,
      combinedOptions,
    );
  }, [diff, now]);

  /**
   * Add any duration, defaults to now and 0
   * duration = {
      years: 0,
      months: 0,
      weeks: 0,
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
    }
 */
  const add = React.useCallback((
    date = now,
    duration = {},
  ) => _add(date, duration), [now]);

  const getMonthsShort = (isAr = true, customOrder = false, orderArray = []) => {
    const months = [];
    const language = isAr ? arSA : enUS;

    for (let i = 0; i < 12; i += 1) {
      months.push(language.localize.month(customOrder ? orderArray[i] : i, { width: 'abbreviated' }));
    }
    return months;
  };

  const formatDate = (date) => new Date(date)
    .toLocaleDateString(
      'en-US',
      {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
      },
    );

  const formatTime = (date) => (new Date(date)
    .toLocaleTimeString(
      'en-US',
      {
        timeStyle: 'short',
      },
    ));

  return {
    yesterday: add(now, { days: -1 }),
    now,
    tomorrow: add(now, { days: 1 }),
    format,
    diff,
    fromNow,
    add,
    getMonthsShort,
    formatDate,
    formatTime,
    isBefore,
    isWithinInterval,
  };
};

export default useDate;
