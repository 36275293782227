import React from 'react';

import { DataGrid } from 'components/core';
import {
  cancelInvitation,
  resendInvitation,
} from 'services';
import {
  useSnackbar, useLocale, useModal, useAuth,
} from 'util/hooks';

import MODAL_KEYS from 'assets/constants/modalKeys';
import { getRoleByName } from 'assets/constants/userRoles';

import { useInvitations } from 'reactQuery/queries';
import { useParams } from 'react-router-dom';
import useTableColumns from './useTableColumns';

const InvitationTable = (props) => {
  const { roles = [] } = props;

  const { t } = useLocale();
  const snack = useSnackbar();
  const { addModal } = useModal();
  const { companyName: paramsCompanyName } = useParams();
  const { currentUser: { companyMember } } = useAuth();

  const companyName = paramsCompanyName ?? companyMember?.company?.name;

  const filterOptions = React.useMemo(() => ({
    role: roles.map((roleName) => getRoleByName(roleName)?.id),
    companyName: companyName ?? '',
  }), [companyName, roles]);

  const {
    data = {},
    isFetching = true,
    refetch = () => { },
  } = useInvitations({
    queryKeyOptions: { filterOptions },
  });

  const {
    items: rows = [],
    meta = {},
  } = data || {};

  const onResend = async (params) => {
    const {
      id,
      email,
    } = params || {};

    try {
      await resendInvitation(id);
      refetch();
      snack({
        message: `${t('resendInvitation.resendSuccess')} 
          ${email} ${t('resendInvitation.resendSuccessMsg')}`,
        severity: 'success',
      });
    } catch ({ errors }) {
      snack({
        message: errors[0].message,
        severity: 'error',
      });
    }
  };

  const onCancel = async (params) => {
    const {
      id,
      email,
    } = params || {};

    try {
      await cancelInvitation(id);
      refetch();
      snack({
        message: `${t('cancelInvitation.cancelSuccess')} 
          ${email} ${t('cancelInvitation.cancelSuccessMsg')}`,
        severity: 'success',
      });
    } catch ({ errors }) {
      snack({
        message: errors[0].message,
        severity: 'error',
      });
    }
  };

  const onConfirm = (type, params) => {
    switch (type) {
      case 'resend':
        return onResend(params);
      case 'cancel':
        return onCancel(params);
      default:
        return () => { };
    }
  };

  const columns = useTableColumns({
    addModal: (params) => addModal({
      key: MODAL_KEYS.invitation,
      props: {
        onConfirm,
        ...params,
      },
    }),
  });

  return (
    <DataGrid
      rows={rows}
      columns={columns}
      loading={isFetching}
      pagination={meta}
      componentsProps={{ noRowsOverlay: { type: 'invitation' } }}
    />
  );
};

export default InvitationTable;
