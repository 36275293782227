import {
  Box,
  Stack,
} from '@mui/material';

import { useLocale } from 'util/hooks';
import { Tabs } from 'components/molecules';

import EventTeamOverview from './TeamProfile/EventTeamOverview';
import EventTeamStats from './TeamProfile/EventTeamStats';
import EventTeamMembersList from './TeamProfile/EventTeamMembersList';
import EventTeamInfo from './TeamProfile/EventTeamInfo';
import EventTeamActivity from './TeamProfile/EventTeamActivity';

const EventTeamDetails = () => {
  const { t } = useLocale();

  const tabs = [{
    key: 'team-members',
    label: t('common.teamMembers'),
    component: (
      <Box>
        <EventTeamInfo />
        <EventTeamMembersList />
      </Box>
    ),
  },
  {
    key: 'activity',
    label: t('common.activity'),
    component: (
      <Box>
        <EventTeamActivity />
      </Box>
    ),
  }];

  return (
    <Stack sx={{ rowGap: 7, mt: 5 }}>
      <EventTeamOverview />
      <EventTeamStats />
      <Tabs tabs={tabs} />
    </Stack>
  );
};

export default EventTeamDetails;
