import { useNavigate } from 'react-router-dom';

import { DataList } from 'components/core';

import { useLeaderboard } from 'reactQuery/queries';
import useTableColumns from './useTableColumns';

const LeaderBoardTable = () => {
  const navigate = useNavigate();
  const columns = useTableColumns();

  const {
    data = {},
    isFetching = true,
  } = useLeaderboard({
    queryKeyOptions: {
      pagination: {
        limit: 100,
      },
    },
  });

  const {
    items: rows = [],
  } = data || {};

  return (
    <DataList
      rows={rows}
      columns={columns}
      loading={isFetching}
      withSearch
      checkboxSelection={false}
      onRowClick={({ row }) => {
        const { username = '' } = row || {};
        navigate(`/profile/${username}`);
      }}
      sx={{
        '& .MuiDataGrid-columnHeaders': {
          background: 'paper.main',
          border: 'none',
        },
        '& .MuiDataGrid-row': {
          width: '100%',
        },
      }}
    />
  );
};

export default LeaderBoardTable;
