const MODAL_KEYS = {
  badgeEarned: 'badgeEarned',
  correctFlag: 'correctFlag',
  challenge: 'challenge',
  promoCode: 'promoCode',
  dashboardAnnouncement: 'dashboardAnnouncement',
  deletePaymentMethod: 'deletePaymentMethod',
  subscriptionHistory: 'subscriptionHistory',
  createLab: 'createLab',
  invitation: 'invitation',
  updatePlan: 'updatePlan',
  userStatus: 'userStatus',
  machine: 'machine',
  resetMachine: 'resetMachine',
  deleteEvent: 'deleteEvent',
  cancelEventInvitation: 'cancelEventInvitation',
  eventMemberActions: 'eventMemberActions',
  eventMemberMove: 'eventMemberMove',
  eventRegistration: 'eventRegistration',
  eventTeamCreation: 'eventTeamCreation',
  eventTeamJoin: 'eventTeamJoin',
  eventTeamBanning: 'eventTeamBanning',
  eventChallengeVisibility: 'eventChallengeVisibility',
  leaderboardFreeze: 'leaderboardFreeze',
  eventLeaderboardTeam: 'eventLeaderboardTeam',
  planFeaturePriority: 'planFeaturePriority',
  invalidateSubmission: 'invalidateSubmission',
  eventChallengeNewCaptureModal: 'eventChallengeNewCaptureModal',
  eventChallengeNewLeadModal: 'eventChallengeNewLeadModal',
  updateChallengePoints: 'updateChallengePoints',
  updatePointsConfirmation: 'updatePointsConfirmation',
  eventFrozenModeModal: 'eventFrozenModeModal',
};

export default MODAL_KEYS;
