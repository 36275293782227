import authRoles, { authGroups } from './authRoles';

/**
 * Auth keys
 * 1. null - all users permitted
 * 2. [] - public only, user must be logged out
 * 3. ['role1', 'role2', ...] - only specified roles are permitted
 * */

// All roles
const allRoles = [...Object.keys(authRoles)];

const {
  admin,
  technicalAdmin,
  businessAdmin,
  accountManager,
  labManager,
  companyManager,
  companyOperator,
  player,
} = authRoles;

const routePermissions = {
  // ** Public - all users permitted
  mainLayout: null,
  publicLayout: [],
  privateLayout: null,
  landing: [],
  verifyEmail: null,
  accountConnection: null,
  hitbctf: null,
  hitbVisitors: null,

  // ** Public - non-users permitted
  login: [],
  register: [],
  acceptInvitation: [],
  forgotPassword: [],
  resetPassword: [],
  publicProfile: [],
  publicViewEvent: [],

  // ** Protected (login required)
  dashboard: authGroups.internal,
  playerHome: [player],

  profile: allRoles,
  settings: allRoles,
  accountSettings: allRoles,
  leaderBoard: allRoles,

  accountSubscriptions: [player],
  accountTransactions: [player],
  paymentMethods: [player],
  addPaymentCard: [player],

  users: [admin, technicalAdmin, businessAdmin],
  invite: [admin, technicalAdmin, businessAdmin, companyManager],
  businessTeam: [admin, businessAdmin],
  technicalTeam: [admin, technicalAdmin],
  userProfile: [admin, technicalAdmin],
  players: [admin, technicalAdmin],
  instances: [admin, technicalAdmin],

  companies: [admin, businessAdmin, accountManager],
  createCompany: [admin, businessAdmin, accountManager],
  editCompany: [admin, businessAdmin, accountManager],
  companyUsers: [admin, businessAdmin, accountManager],
  companyOwnMembers: [companyManager],
  inviteCompanyMembers: [admin, businessAdmin, accountManager, companyManager],
  editCompanyMember: [admin, businessAdmin, accountManager, companyManager],

  plans: [admin, businessAdmin, accountManager],
  viewPlan: [admin, businessAdmin, accountManager],
  plansList: [admin, businessAdmin, accountManager],
  pendingPlans: [admin, businessAdmin],
  manageFeatures: [admin, businessAdmin],
  createPlan: [admin, businessAdmin, accountManager],
  editPlan: [admin, businessAdmin, accountManager],

  labs: authGroups.internal,
  viewLab: authGroups.internal,
  premiumLabs: authGroups.internal,
  proLabs: authGroups.internal,
  createLab: [admin, technicalAdmin, labManager],
  // Player only
  playerLabs: [player],
  playerViewLab: [player],
  trainingLabs: [player],
  competitiveLabs: [player],

  viewChallenge: authGroups.internal,
  createChallenge: [admin, technicalAdmin, labManager],
  playerViewChallenge: [player],

  promoCode: [admin, businessAdmin, accountManager],
  viewPromoCode: [admin, businessAdmin, accountManager],
  promoCodeList: [admin, businessAdmin, accountManager],
  createPromoCode: [admin, businessAdmin, accountManager],

  subscriptions: [player],
  checkout: [player],

  announcements: [admin, businessAdmin, accountManager],
  createAnnouncement: [admin, businessAdmin, accountManager],
  editAnnouncement: [admin, businessAdmin, accountManager],
  viewAnnouncement: [admin, businessAdmin, accountManager],

  machines: [player],
  manageMachines: [admin, technicalAdmin, labManager],
  createMachine: [admin, technicalAdmin, labManager],
  viewMachine: [admin, technicalAdmin, labManager],
  editMachine: [admin, technicalAdmin, labManager],
  playerViewMachine: [player],

  events: [admin, technicalAdmin, labManager, companyManager],
  companyOperatorEvents: [companyOperator],
  playerEvents: [player],
  viewEvent: [
    admin,
    technicalAdmin,
    labManager,
    companyManager,
    companyOperator,
  ],
  createEvent: [admin, technicalAdmin, labManager],
  createEventChallenge: [admin, technicalAdmin, labManager, companyManager],
  viewEventTeams: [admin, technicalAdmin, labManager, companyManager],
  viewEventSingleTeam: [admin, technicalAdmin, labManager, companyManager, player],
  viewEventLeaderboard: [admin, technicalAdmin, labManager, companyManager],
  viewEventChallenge: [admin, technicalAdmin, labManager, companyManager],
  editEventTeam: [admin, technicalAdmin, labManager, companyManager],
  playerViewEvent: [player],
  captainEditEventTeam: [player],
  playerViewEventChallenge: [player],
  eventVisualization: [admin, technicalAdmin, labManager, player],
};

export default routePermissions;
