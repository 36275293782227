import { useParams } from 'react-router-dom';

import { getEventRecentSolves } from 'services';
import { useCustomQuery } from 'reactQuery';

const useEventRecentSolves = (queryProps = {}) => {
  const {
    queryKeyOptions = {},
    ...rest
  } = queryProps;

  const { eventId } = useParams();

  const query = useCustomQuery({
    queryKey: [`event-recent-solves-${eventId}`, queryKeyOptions],
    queryFn: (options) => getEventRecentSolves(eventId, options),
    ...rest,
  });

  return { ...query };
};

export default useEventRecentSolves;
