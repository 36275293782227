import { useState, useMemo } from 'react';

import {
  Box,
  Drawer as MUIDrawer,
  IconButton,
  Toolbar,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';

import { useAuth } from 'util/hooks';
import { MOBILE_BREAKPOINT } from 'assets/constants/breakpoints';

import AppBar from '../AppBar/AppBar';
import drawerMenuItems from './drawerMenuItems';
import DrawerMenu from './DrawerMenu';

const drawerWidth = 248;

const Drawer = (props) => {
  const { children } = props;

  const { currentUser: { userRole = '' } } = useAuth();
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawerMenu = useMemo(() => (
    <>
      <Toolbar />
      <DrawerMenu menu={drawerMenuItems[userRole]} />
    </>
  ), [userRole]);

  return (
    <Box sx={{ display: 'flex' }}>
      <AppBar
        position="fixed"
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
      >
        <Toolbar sx={{ display: { [MOBILE_BREAKPOINT]: 'none' } }}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { [MOBILE_BREAKPOINT]: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { [MOBILE_BREAKPOINT]: drawerWidth }, flexShrink: { [MOBILE_BREAKPOINT]: 0 } }}
        aria-label="navigation drawer"
      >
        <MUIDrawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: 'block', [MOBILE_BREAKPOINT]: 'none' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: drawerWidth,
            },
          }}
        >
          {drawerMenu}
        </MUIDrawer>
        <MUIDrawer
          variant="permanent"
          sx={{
            display: { xs: 'none', [MOBILE_BREAKPOINT]: 'block' },
            '& .MuiDrawer-paper': {
              marginTop: 12,
              boxSizing: 'border-box',
              width: drawerWidth,
            },
          }}
        >
          {drawerMenu}
        </MUIDrawer>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          width: { [MOBILE_BREAKPOINT]: `calc(100% - ${drawerWidth}px)` },
        }}
      >
        <Toolbar />
        {children}
      </Box>
    </Box>
  );
};
export default Drawer;
