import { useEffect, useMemo } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';

import {
  Box,
  Stack,
  Typography,
} from '@mui/material';

import { useLocale } from 'util/hooks';
import { PaymentCardPurpleIcon } from 'assets/icons';
import { RadioGroup, CardNumberWithEllipses } from 'components/molecules';
import { PaymentCardDetailsForm } from 'components/organisms';

import { checkoutAtom } from 'recoil/atoms';
import { getCardTypeByType } from 'util/cardTypes';
import { formatExpiryDate } from 'util/helpers';
import { usePaymentCards } from 'reactQuery/queries';

const PaymentTitle = () => {
  const { t } = useLocale();

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{
        width: 48,
        height: 44,
        mr: 4,
        p: 0,
      }}
      >
        <PaymentCardPurpleIcon width="48" height="44" />
      </Box>
      <Box>
        <Typography variant="bodyStandardMedium" color="text.lightGray">
          {t('checkout.paymentDetails')}
        </Typography>
      </Box>
    </Box>
  );
};

const PaymentOptions = (props) => {
  const { paymentCards } = props;
  const [checkoutState, setCheckoutState] = useRecoilState(checkoutAtom);
  const {
    paymentOption = {},
  } = checkoutState;

  const { t } = useLocale();

  const paymentOptions = useMemo(() => {
    const options = paymentCards?.map((paymentMethod) => ({
      value: String(paymentMethod.id),
      cardDetails: { ...paymentMethod },
      label: (
        <Stack>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ width: 39, height: 22 }}>
              <img
                alt={`${paymentMethod.cardType}-logo`}
                width="39"
                height="22"
                src={getCardTypeByType(paymentMethod.cardType)?.logo}
              />
            </Box>
            <Box sx={{ ml: 3 }}>
              <Typography variant="bodyStandardMedium">
                <CardNumberWithEllipses
                  ellipseCount={4}
                  lastFourDigits={paymentMethod?.lastFourDigits}
                />
              </Typography>
            </Box>
          </Box>
          <Box>
            <Typography variant="bodySmallRegular" color="text.secondary">
              {`${t('checkout.expires')} ${formatExpiryDate(paymentMethod.expiryDate)}`}
            </Typography>
          </Box>
        </Stack>
      ),
    }));

    const showNewCardOption = paymentCards?.length < 2;

    if (showNewCardOption) {
      options.push({
        value: 'newCard',
        label: t('checkout.newCard'),
      });
    }

    return options;
  }, [paymentCards, t]);

  useEffect(() => {
    const {
      value,
      cardDetails,
    } = paymentOptions[0];

    setCheckoutState((prev) => ({
      ...prev,
      paymentOption: {
        value,
        cardDetails,
      },
    }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentOptions]);

  const handlePaymentOptionChange = (newPaymentValue) => {
    // Find payment option from value
    const {
      value,
      cardDetails,
    } = paymentOptions?.find((option) => option.value === newPaymentValue) || {};

    setCheckoutState((prev) => ({
      ...prev,
      paymentOption: {
        value,
        cardDetails,
      },
    }));
  };

  return (
    <Box>
      <RadioGroup
        options={paymentOptions}
        value={paymentOption?.value}
        onChange={handlePaymentOptionChange}
      />
    </Box>
  );
};

const CheckoutPaymentMethod = () => {
  const checkoutState = useRecoilValue(checkoutAtom);
  const { paymentOption } = checkoutState || {};

  const {
    data: paymentCards = [],
  } = usePaymentCards();

  const isOnlyPaymentMethod = paymentCards?.length < 1;
  const isNewCard = paymentOption?.value === 'newCard';

  return (
    <Stack spacing={10}>
      <PaymentTitle />
      <PaymentOptions paymentCards={paymentCards} />
      {isNewCard && (
        <PaymentCardDetailsForm
          checkoutMode
          isOnlyPaymentMethod={isOnlyPaymentMethod}
        />
      )}
    </Stack>
  );
};

export default CheckoutPaymentMethod;
