const SOCKET_EVENTS = {
  refreshUser: 'refresh-user',
  notifications: 'notifications',
  error: 'error',
  instance: {
    challengeInstance: 'challenge-instance',
    ingress: 'ingress',
    deployment: 'deployment',
    service: 'service',
    instanceStopped: 'instance-stopped',
  },
  onlinePlayers: {
    onlinePlayersCount: 'online-players-count',
  },
  badgeEarned: 'badge-earned',
  events: {
    visualization: 'visualization',
    leaderboardUpdated: 'leaderboard-updated',
    eventFreeze: 'event-freezed',
    eventUnfreeze: 'event-unfreezed',
  },
};

export default SOCKET_EVENTS;
