import { useTheme } from '@mui/styles';

const UserWithHashIcon = (props) => {
  const theme = useTheme();
  const {
    stroke = theme.palette.text.raspberry,
    fill = 'none',
    ...rest
  } = props;

  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill={fill} xmlns="http://www.w3.org/2000/svg" {...rest}>
      <g clipPath="url(#clip0_8885_157858)">
        <path d="M1.68457 13.6829C1.68515 12.8338 1.89614 11.998 2.29868 11.2504C2.70122 10.5027 3.28276 9.8665 3.9913 9.39855C4.69985 8.93061 5.51331 8.64555 6.35896 8.56886C7.20461 8.49216 8.05609 8.62622 8.83726 8.95906" stroke="#8586D7" strokeWidth="1.14139" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M6.82045 7.40165C8.55397 7.40165 9.95926 5.99635 9.95926 4.26283C9.95926 2.52932 8.55397 1.12402 6.82045 1.12402C5.08693 1.12402 3.68164 2.52932 3.68164 4.26283C3.68164 5.99635 5.08693 7.40165 6.82045 7.40165Z" stroke="#8586D7" strokeWidth="1.14139" strokeLinecap="round" strokeLinejoin="round" />
        <g clipPath="url(#clip1_8885_157858)">
          <path d="M8.68652 11.207H15.8045" stroke={stroke} strokeWidth="0.808672" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M7.59082 14.4941H14.7088" stroke={stroke} strokeWidth="0.808672" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M11.6977 8.46973L8.95996 16.6828" stroke={stroke} strokeWidth="0.808672" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M14.4359 8.46973L11.6982 16.6828" stroke={stroke} strokeWidth="0.808672" strokeLinecap="round" strokeLinejoin="round" />
        </g>
      </g>
    </svg>
  );
};
export default UserWithHashIcon;
