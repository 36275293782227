import END_POINTS from 'services/endPoints';
import METHODS from 'services/methods';
import downloadApi from '../downloadApi';

const downloadVPN = (regionName, data) => downloadApi({
  path: END_POINTS.vpn,
  method: METHODS.POST,
  fileName: `flagyard_vpn_${regionName}_config.ovpn`,
  data,
})
  .then((res) => Promise.resolve(res))
  .catch((error) => Promise.reject(error));

export default downloadVPN;
