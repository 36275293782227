import useAuth from './useAuth';

/**
 *
 * @param {String} permissionKey - the permission key to search for
 * @returns true if the current user has permissionKey in their permissions array
 */
const usePermission = (permissionKey = '') => {
  const { currentUser = {} } = useAuth();
  const { role = {} } = currentUser;
  const { permissions = [] } = role;

  const hasPermission = permissions?.find((p) => p.nameKey === permissionKey);

  return !!hasPermission;
};

export default usePermission;
