import {
  Box,
  CircularProgress,
  Typography,
  useMediaQuery,
} from '@mui/material';

import {
  circularProgressClasses,
} from '@mui/material/CircularProgress';

const ProgressWithLabel = (props) => {
  const {
    value = 0,
    label = '',
    size = 136,
    thickness = 4,
    activeProgressProps = {},
    passiveProgressProps = {},
    valueProps = {},
    labelProps = {},
  } = props;

  const mobileView = useMediaQuery(({ breakpoints }) => breakpoints.down('md'));

  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <Box sx={{ position: 'relative' }}>
        <CircularProgress
          variant="determinate"
          sx={{ color: ({ palette }) => palette.text.lightGreen }}
          size={size}
          thickness={thickness}
          value={100}
          {...passiveProgressProps}
        />
        <CircularProgress
          variant="determinate"
          color="success"
          sx={{
            position: 'absolute',
            left: 0,
            [`& .${circularProgressClasses.circle}`]: {
              strokeLinecap: 'round',
            },
          }}
          size={size}
          thickness={thickness}
          value={value}
          {...activeProgressProps}
        />
      </Box>
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography
          variant={mobileView ? 'bodySmallBold' : 'bodyMediumBold'}
          component="div"
          color="text.lighterGray"
          {...valueProps}
        >
          {`${Math.round(value)}%`}
        </Typography>
        <Typography
          variant="bodySmallRegular"
          component="div"
          color="text.secondary"
          {...labelProps}
        >
          {label}
        </Typography>
      </Box>
    </Box>
  );
};

export default ProgressWithLabel;
