import PropTypes from 'prop-types';

import StickyBadge from './StickyBadge';
import NonStickyBadge from './NonStickyBadge';

const AnimatedBadge = (props) => {
  const { sticky, ...rest } = props;

  return sticky
    ? <StickyBadge {...rest} />
    : <NonStickyBadge {...rest} />;
};

AnimatedBadge.propTypes = {
  sticky: PropTypes.bool,
  color: PropTypes.string,
  children: PropTypes.node,
  sx: PropTypes.oneOfType([PropTypes.object]),
};

AnimatedBadge.defaultProps = {
  sticky: false,
  color: 'success.main',
  children: null,
  sx: {},
};

export default AnimatedBadge;
