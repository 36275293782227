import { useMemo } from 'react';
import {
  Box,
  useMediaQuery,
} from '@mui/material';
import { Top3Element } from 'components/molecules';

const gap = 10;
const breakpoint = 'lg';

const Top3 = (props) => {
  const {
    top3 = [],
    handleRowClick,
  } = props;

  const mobileView = useMediaQuery(({ breakpoints }) => breakpoints.down(breakpoint));

  const top3Ordered = useMemo(() => {
    if (top3?.length) {
      const top3Copy = [...top3];
      if (mobileView) {
        return top3Copy;
      }

      top3Copy.splice(1, 0, top3Copy.splice(0, 1)[0]); // move first rank to middle
      return top3Copy;
    }
    return [];
  }, [mobileView, top3]);

  return (
    <Box
      sx={{
        gap,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: { xs: 'column', [breakpoint]: 'row' },
      }}
    >
      {top3Ordered.map((team) => (
        <Top3Element
          key={team.id}
          team={team}
          handleRowClick={handleRowClick}
        />
      ))}
    </Box>
  );
};

export default Top3;
