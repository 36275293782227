const HomeIcon = (props) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#clip0_423_703)">
      <path d="M3.12695 11.62V18.495H8.12695V13.495C8.12695 13.1635 8.25865 12.8455 8.49307 12.6111C8.72749 12.3767 9.04543 12.245 9.37695 12.245H10.627C10.9585 12.245 11.2764 12.3767 11.5108 12.6111C11.7453 12.8455 11.877 13.1635 11.877 13.495V18.495H16.877V11.62" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M0.626953 10.37L9.11779 1.87916C9.23387 1.763 9.3717 1.67085 9.52341 1.60798C9.67512 1.54512 9.83773 1.51276 10.002 1.51276C10.1662 1.51276 10.3288 1.54512 10.4805 1.60798C10.6322 1.67085 10.77 1.763 10.8861 1.87916L19.377 10.37" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </g>
    <defs>
      <clipPath id="clip0_423_703">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default HomeIcon;
