import { useMemo } from 'react';
import { useRoutes } from 'react-router-dom';

import { useAuth } from 'util/hooks';

import routeConfigs from './routeConfigs';
import { getRoutesForRole } from './routeUtils';

const Router = () => {
  const { currentUser: { userRole = '' } } = useAuth();
  const routesForRole = useMemo(() => getRoutesForRole(routeConfigs, userRole), [userRole]);

  const routes = useRoutes(routesForRole);

  return routes;
};

export default Router;
