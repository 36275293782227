import { useTheme } from '@mui/styles';

const ServerCheckIcon = (props) => {
  const { palette } = useTheme();
  const {
    stroke = palette.primaty.contrastText,
    fill = 'none',
    ...rest
  } = props;

  return (
    <svg width="29" height="29" viewBox="0 0 29 29" fill={fill} xmlns="http://www.w3.org/2000/svg" {...rest}>
      <g clipPath="url(#clip0_7344_101607)">
        <path d="M6.625 6.44141C6.53847 6.44141 6.45388 6.46707 6.38194 6.51514C6.30999 6.56321 6.25392 6.63154 6.2208 6.71148C6.18769 6.79143 6.17903 6.87939 6.19591 6.96426C6.21279 7.04912 6.25446 7.12708 6.31564 7.18827C6.37683 7.24945 6.45478 7.29112 6.53965 7.308C6.62452 7.32488 6.71248 7.31622 6.79242 7.2831C6.87237 7.24999 6.94069 7.19391 6.98877 7.12197C7.03684 7.05002 7.0625 6.96544 7.0625 6.87891C7.0625 6.76287 7.01641 6.65159 6.93436 6.56955C6.85231 6.4875 6.74103 6.44141 6.625 6.44141V6.44141Z" stroke={stroke} strokeWidth="1.75" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M6.625 16.9414C6.53847 16.9414 6.45388 16.9671 6.38194 17.0151C6.30999 17.0632 6.25392 17.1315 6.2208 17.2115C6.18769 17.2914 6.17903 17.3794 6.19591 17.4643C6.21279 17.5491 6.25446 17.6271 6.31564 17.6883C6.37683 17.7495 6.45478 17.7911 6.53965 17.808C6.62452 17.8249 6.71248 17.8162 6.79242 17.7831C6.87237 17.75 6.94069 17.6939 6.98877 17.622C7.03684 17.55 7.0625 17.4654 7.0625 17.3789C7.0625 17.2629 7.01641 17.1516 6.93436 17.0695C6.85231 16.9875 6.74103 16.9414 6.625 16.9414V16.9414Z" stroke={stroke} strokeWidth="1.75" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M15.375 12.1289H6.625C5.23261 12.1289 3.89726 11.5758 2.91269 10.5912C1.92812 9.60665 1.375 8.27129 1.375 6.87891C1.375 5.48652 1.92812 4.15116 2.91269 3.1666C3.89726 2.18203 5.23261 1.62891 6.625 1.62891H20.625C21.7565 1.62867 22.8579 1.99403 23.7649 2.67054C24.672 3.34706 25.3362 4.29852 25.6585 5.38317C25.9809 6.46783 25.9442 7.6276 25.5539 8.68971C25.1636 9.75182 24.4406 10.6594 23.4927 11.2772" stroke={stroke} strokeWidth="1.75" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M10.125 22.6289H6.625C5.23261 22.6289 3.89726 22.0758 2.91269 21.0912C1.92812 20.1067 1.375 18.7713 1.375 17.3789C1.375 15.9865 1.92812 14.6512 2.91269 13.6666C3.89726 12.682 5.23261 12.1289 6.625 12.1289" stroke={stroke} strokeWidth="1.75" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M12.75 6.87891H20.625" stroke={stroke} strokeWidth="1.75" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M20.625 27.8789C24.491 27.8789 27.625 24.7449 27.625 20.8789C27.625 17.0129 24.491 13.8789 20.625 13.8789C16.759 13.8789 13.625 17.0129 13.625 20.8789C13.625 24.7449 16.759 27.8789 20.625 27.8789Z" stroke={stroke} strokeWidth="1.75" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M23.7477 18.8428L20.3586 23.3624C20.2832 23.4627 20.1872 23.5456 20.0771 23.6055C19.9669 23.6654 19.8452 23.701 19.7201 23.7099C19.595 23.7187 19.4695 23.7006 19.352 23.6568C19.2345 23.6129 19.1278 23.5444 19.0391 23.4558L17.2891 21.7058" stroke={stroke} strokeWidth="1.75" strokeLinecap="round" strokeLinejoin="round" />
      </g>
      <defs>
        <clipPath id="clip0_7344_101607">
          <rect width="28" height="28" fill="white" transform="translate(0.5 0.753906)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ServerCheckIcon;
