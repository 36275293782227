import { useModal, useSocket } from 'util/hooks';
import MODAL_KEYS from 'assets/constants/modalKeys';
import { useEventDetails } from 'reactQuery/queries';
import SOCKET_EVENTS from 'assets/constants/socketEvents';
import {
  useCallback,
  useEffect,
  useMemo,
} from 'react';

const VisualizationFrozenMode = () => {
  const { addModal, removeModal, findModal } = useModal();

  const {
    data: eventDetails = {},
    refetch: refetchEventDetails,
  } = useEventDetails();

  const { isFrozen = false } = eventDetails;

  const showFrozenModal = useCallback(() => {
    const modalFound = findModal(MODAL_KEYS.eventFrozenModeModal);
    if (!modalFound) {
      addModal({
        key: MODAL_KEYS.eventFrozenModeModal,
        modalProps: {
          disableAutoFocus: true,
        },
      });
    }
  }, [addModal, findModal]);

  const hideFrozenModal = useCallback(() => {
    removeModal(MODAL_KEYS.eventFrozenModeModal);
  }, [removeModal]);

  useEffect(() => {
    const modalFound = findModal(MODAL_KEYS.eventFrozenModeModal);
    if (isFrozen && !modalFound) {
      showFrozenModal();
    } else if (!isFrozen && modalFound) {
      hideFrozenModal();
    }
  }, [isFrozen, findModal, showFrozenModal, hideFrozenModal]);

  const socketEvents = useMemo(() => [
    {
      eventName: SOCKET_EVENTS.events.eventFreeze,
      handler: () => {
        refetchEventDetails();
      },
    },
    {
      eventName: SOCKET_EVENTS.events.eventUnfreeze,
      handler: () => {
        refetchEventDetails();
      },
    },
  ], [refetchEventDetails]);

  useSocket({ events: socketEvents });

  return null;
};

export default VisualizationFrozenMode;
