import { Typography, Box } from '@mui/material';

import { useLocale } from 'util/hooks';

const BadgesHeader = () => {
  const { t } = useLocale();

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      mb: 2,
    }}
    >
      <Typography
        variant="bodyMediumMedium"
        color="text.primary"
      >
        {t('common.badges')}
      </Typography>
      <Typography
        variant="bodySmallRegular"
        color="text.secondary"
      >
        {t('badges.desc')}
      </Typography>
    </Box>
  );
};

export default BadgesHeader;
