import * as Yup from 'yup';

import {
  Box,
  Card,
  Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

import {
  FormAvatar,
  FormCountriesSelect,
  FormTextField,
  FormTimezoneSelect,
} from 'components/form';
import { deleteAvatar, updateMyProfile } from 'services';
import { getDirtyObject, REGEX } from 'util/helpers';
import {
  useAuth, useLocale, useSnackbar, useFastForm, useRecaptcha,
} from 'util/hooks';

const PersonalInformationForm = () => {
  const { t } = useLocale();

  const snack = useSnackbar();
  const { authState, refreshUser } = useAuth();
  const { executeRecaptcha } = useRecaptcha({ action: 'UPDATE_PROFILE' });
  const {
    user: {
      avatar,
      username,
      email,
      country,
      timeZone,
    },
  } = authState;

  const defaultValues = {
    avatar,
    email: email || '',
    username: username || '',
    country,
    timeZone: timeZone || '',
  };

  const validationSchema = Yup.object({
    avatar: Yup.string(),
    email: Yup.string()
      .email(t('email.validation'))
      .matches(REGEX.mail, t('email.validation'))
      .required(t('email.enterEmail')),
    username: Yup.string()
      .matches(REGEX.alphaNumericUnderscore, t('username.validation'))
      .max(35, t('username.maxLength'))
      .min(4, t('username.minLength'))
      .required(t('username.enter')),
    country: Yup.object()
      .nullable(true)
      .required(t('country.enter')),
    timeZone: Yup.string().nullable().required(t('common.timeZoneRequired')),
  });

  const {
    control,
    handleSubmit,
    reset,
    formState: {
      isSubmitting,
      isDirty,
    },
  } = useFastForm({
    defaultValues,
    validationSchema,
  });

  const onSubmit = async (values) => {
    const dirtyPayload = getDirtyObject(values, defaultValues);
    const recaptchaToken = await executeRecaptcha();
    try {
      if (dirtyPayload.avatar === '') {
        // If avatar is deleted
        await deleteAvatar();
      }
      const newProfile = await updateMyProfile(dirtyPayload, { recaptchaToken });
      refreshUser();
      reset(newProfile);
      snack({
        message: t('common.profileUpdated'),
        severity: 'success',
      });
    } catch (error) {
      snack({
        message: error.errors[0].message || t('common.errorMessage'),
        severity: 'error',
      });
    }
  };

  const personalInformationForm = (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormAvatar
        name="avatar"
        control={control}
        avatar={avatar}
        entityName={username}
        disabled={isSubmitting}
      />
      <Box sx={{
        display: 'grid',
        columnGap: 12,
        rowGap: 8,
        gridTemplateColumns: {
          xs: 'repeat(1, 1fr)',
          md: 'repeat(2, 1fr)',
          lg: 'repeat(2, 304px)',
          xl: 'repeat(2, 420px)',
        },
      }}
      >
        <Box>
          <FormTextField
            label={t('common.email')}
            sx={{ m: 0 }}
            name="email"
            control={control}
            autoComplete="email"
            disabled={isSubmitting}
            fullWidth
          />
        </Box>
        <Box>
          <FormTextField
            label={t('common.username')}
            sx={{ m: 0 }}
            name="username"
            control={control}
            autoComplete="username"
            disabled={isSubmitting}
            fullWidth
          />
        </Box>
        <Box>
          <FormCountriesSelect
            label={t('country.label')}
            name="country"
            control={control}
            placeholder={t('country.enter')}
            disabled={isSubmitting}
            fullWidth
          />
        </Box>
        <Box>
          <FormTimezoneSelect
            autoCompleteProps={{
              disabled: isSubmitting,
            }}
            name="timeZone"
            control={control}
            type="text"
            label={t('common.timezone')}
            textFieldProps={{
              placeholder: t('common.timeZoneRequired'),
              fullWidth: true,
            }}
          />
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          mt: 8,
        }}
      >
        <LoadingButton
          type="submit"
          variant="contained"
          size="medium"
          disabled={!isDirty || isSubmitting}
          loading={isSubmitting}
        >
          {t('common.saveChanges')}
        </LoadingButton>
      </Box>
    </form>
  );

  return (
    <Card
      id="personal-information-form"
      sx={{
        px: { xs: 6, sm: 14 },
        py: 8,
      }}
    >
      <Typography
        variant="bodyStandardMedium"
        sx={{ color: 'text.secondary' }}
      >
        {t('common.personalInfo')}
      </Typography>
      {personalInformationForm}
    </Card>
  );
};

export default PersonalInformationForm;
