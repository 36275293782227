import PropTypes from 'prop-types';

import {
  IconButton,
  InputAdornment,
  TextField,
} from '@mui/material';

import ClearIcon from '@mui/icons-material/Clear';
import { SearchIcon } from 'assets/icons';
import { useLocale } from 'util/hooks';

const SearchField = (props) => {
  const {
    value,
    onClear,
    ...rest
  } = props;

  const { t } = useLocale();

  const searchIcon = (
    <InputAdornment position="start">
      <div
        width="16"
        height="16"
      >
        <SearchIcon
          aria-label="search-icon"
          width="16"
          height="16"
        />
      </div>
    </InputAdornment>
  );

  const clearIcon = (
    <IconButton
      title="Clear"
      aria-label="Clear"
      size="small"
      style={{ visibility: value ? 'visible' : 'hidden' }}
      onClick={onClear}
    >
      <ClearIcon sx={{ color: 'text.secondary' }} fontSize="small" />
    </IconButton>
  );

  return (
    <TextField
      value={value}
      variant="standard"
      placeholder={t('common.search')}
      InputProps={{
        startAdornment: searchIcon,
        endAdornment: clearIcon,
      }}
      {...rest}
    />
  );
};

SearchField.propTypes = {
  value: PropTypes.string,
  onClear: PropTypes.func,
};

SearchField.defaultProps = {
  value: '',
  onClear: () => { },
};

export default SearchField;
