import { useEventDetails } from 'reactQuery/queries';
import PlayerEventChallengeList from './challenges/PlayerEventChallengeList';
import EventRegistrationState from './EventRegistrationState';

const PlayerEventChallenges = () => {
  const { data: eventDetails = {} } = useEventDetails();

  const {
    isRegistered,
    eventHasStarted,
  } = eventDetails;

  const showChallengeList = isRegistered && eventHasStarted;

  return (
    showChallengeList
      ? <PlayerEventChallengeList />
      : <EventRegistrationState />
  );
};

export default PlayerEventChallenges;
