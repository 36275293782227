import { Box } from '@mui/material';
import { maintenancePage } from 'assets/images';

const isMaintenanceMode = process.env.REACT_APP_MAINTENANCE_MODE === 'true';

const MaintenancePage = () => (
  <Box
    sx={{
      backgroundImage: `url(${maintenancePage})`,
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
    }}
  />
);

const MaintenanceMode = ({ children }) => {
  if (isMaintenanceMode) {
    return <MaintenancePage />;
  }

  return children;
};

export default MaintenanceMode;
