import Avatar from '@mui/material/Avatar';
import { capitalizeFirstLetter } from 'util/helpers';

const AvatarWithFallback = (props) => {
  const {
    src = '',
    alt = '',
    fallbackText = '',
    sx = {},
    ...rest
  } = props;

  return (
    <Avatar
      src={String(src)}
      alt={alt}
      sx={{
        fontWeight: 'bold',
        ...sx,
      }}
      {...rest}
    >
      {capitalizeFirstLetter(fallbackText || alt)}
    </Avatar>
  );
};

export default AvatarWithFallback;
