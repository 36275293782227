import api from 'services/api';
import END_POINTS from 'services/endPoints';
import METHODS from 'services/methods';

/**
 *
 * @param {machineName} string
 *
 */
const resetMachine = (machineName, options) => api(
  END_POINTS.resetMachine(machineName),
  {
    method: METHODS.POST,
    ...options,
  },
)
  .then((res) => Promise.resolve(res))
  .catch((error) => Promise.reject(error));

export default resetMachine;
