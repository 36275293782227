import { DataGrid } from 'components/core';
import { useCompanies } from 'reactQuery/queries';
import useTableColumns from './useTableColumns';

const CompaniesTable = () => {
  const {
    data = {},
    isFetching = true,
  } = useCompanies();

  const {
    items: rows = [],
    meta = {},
  } = data || {};

  const columns = useTableColumns();

  return (
    <DataGrid
      loading={isFetching}
      rows={rows}
      columns={columns}
      pagination={meta}
      componentsProps={{
        noRowsOverlay: { type: 'user' },
      }}
    />
  );
};

export default CompaniesTable;
