import PERMISSIONS from '../../assets/constants/permissions';

const transformPermissions = (unformattedBackendPermissions) => {
  const findDifferences = (backendPermissions) => {
    const differences = new Set();
    /* check for:
       Keys that exist in one object but not in the other.
       Mismatched key names.
       Mismatched values.
    */

    for (const key in { ...backendPermissions, ...PERMISSIONS }) {
      if (backendPermissions[key] !== PERMISSIONS[key]) {
        differences.add(key);
      }
    }

    return Array.from(differences);
  };

  const formattedBackendPermissions = {};
  unformattedBackendPermissions.forEach(({ nameKey }) => {
    formattedBackendPermissions[nameKey] = nameKey;
  });

  return findDifferences(formattedBackendPermissions);
};

export default transformPermissions;
