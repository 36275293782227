import { useTheme } from '@mui/styles';

const ArrowsSquareIcon = (props) => {
  const theme = useTheme();
  const {
    stroke = theme.palette.text.primary,
    ...rest
  } = props;

  return (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <path d="M6 15.998L1.625 20.373" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M20.375 6.62305V1.62305H15.375" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M1.625 15.373V20.373H6.625" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M20.375 1.62305L16 5.99805" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M16 15.998L20.375 20.373" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M1.625 6.62305V1.62305H6.625" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M20.375 15.373V20.373H15.375" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M1.625 1.62305L6 5.99805" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path fillRule="evenodd" clipRule="evenodd" d="M7.875 8.62305C7.875 8.20883 8.21079 7.87305 8.625 7.87305H13.375C13.7892 7.87305 14.125 8.20883 14.125 8.62305V13.373C14.125 13.7873 13.7892 14.123 13.375 14.123H8.625C8.21079 14.123 7.875 13.7873 7.875 13.373V8.62305Z" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>

  );
};

export default ArrowsSquareIcon;
