import { getCompanies } from 'services';
import { useCustomQuery } from 'reactQuery';

const useCompanies = (queryProps = {}) => {
  const query = useCustomQuery({
    queryKey: ['companies'],
    queryFn: (options) => getCompanies(options),
    ...queryProps,
  });

  return { ...query };
};

export default useCompanies;
