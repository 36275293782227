import { SocketContext } from 'context';
import React, { useContext } from 'react';

const useSocket = (props = {}) => {
  const {
    events = [{
      eventName: '',
      handler: () => { },
    }],
  } = props;

  const socket = useContext(SocketContext);

  React.useEffect(() => {
    if (socket) {
      events?.forEach(({ eventName, handler }) => socket.on(eventName, handler));
      return () => {
        events?.forEach(({ eventName, handler }) => socket.off(eventName, handler));
      };
    }

    return () => { };
  }, [socket, events]);
};

export default useSocket;
