import { DataGrid } from 'components/core';
import useTableColumns from './useTableColumns';

const EventRecentSolvesTable = (props) => {
  const {
    recentSolves = [],
    isFetching = false,
  } = props;

  const columns = useTableColumns();

  return (
    <DataGrid
      rows={recentSolves}
      columns={columns}
      loading={isFetching}
      withSearch={false}
      checkboxSelection={false}
      components={{
        Pagination: null,
      }}
      density="comfortable"
      sx={({ typography }) => ({
        '& .MuiDataGrid-columnHeaders': {
          pl: 3.5,
        },
        '& .MuiDataGrid-columnHeader': {
          color: 'text.primary',
          ...typography.bodyLargeRegular,
        },
        '& .MuiDataGrid-row': {
          pl: 3.5,
        },
      })}
    />
  );
};

export default EventRecentSolvesTable;
