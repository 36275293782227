import { useTheme } from '@mui/styles';

const RemainingUsageIcon = (props) => {
  const theme = useTheme();
  const {
    stroke = theme.palette.text.lightRaspberry,
    fill = 'none',
    ...rest
  } = props;

  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill={fill} xmlns="http://www.w3.org/2000/svg" {...rest}>
      <path d="M11.4654 31.9817L2.50038 23.0183C2.26806 22.7862 2.08377 22.5105 1.95803 22.2071C1.8323 21.9037 1.76758 21.5784 1.76758 21.25C1.76758 20.9216 1.8323 20.5963 1.95803 20.2929C2.08377 19.9895 2.26806 19.7138 2.50038 19.4817L20.0004 1.98167C20.2326 1.74956 20.5083 1.56547 20.8118 1.43993C21.1152 1.31439 21.4404 1.24985 21.7687 1.25H30.732C31.3951 1.25 32.031 1.51339 32.4998 1.98223C32.9687 2.45107 33.232 3.08696 33.232 3.75V12.715C33.2319 13.3776 32.9687 14.013 32.5004 14.4817" stroke={stroke} strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M26.9824 6.875C26.8588 6.875 26.738 6.91166 26.6352 6.98033C26.5324 7.04901 26.4523 7.14662 26.405 7.26082C26.3577 7.37503 26.3453 7.50069 26.3694 7.62193C26.3935 7.74317 26.4531 7.85453 26.5405 7.94194C26.6279 8.02935 26.7393 8.08888 26.8605 8.11299C26.9817 8.13711 27.1074 8.12473 27.2216 8.07742C27.3358 8.03012 27.4334 7.95001 27.5021 7.84723C27.5708 7.74445 27.6074 7.62361 27.6074 7.5C27.6074 7.33424 27.5416 7.17527 27.4244 7.05806C27.3072 6.94085 27.1482 6.875 26.9824 6.875Z" stroke={stroke} strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
      <g clipPath="url(#clip0_3660_42734)">
        <path d="M28.1565 28.7837C30.582 28.7837 32.5483 26.8174 32.5483 24.3918C32.5483 21.9663 30.582 20 28.1565 20C25.7309 20 23.7646 21.9663 23.7646 24.3918C23.7646 26.8174 25.7309 28.7837 28.1565 28.7837Z" stroke={stroke} strokeWidth="1.50577" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M20 38.8203C20 36.6571 20.8593 34.5826 22.3889 33.053C23.9185 31.5234 25.9931 30.6641 28.1562 30.6641C30.3194 30.6641 32.394 31.5234 33.9236 33.053C35.4532 34.5826 36.3125 36.6571 36.3125 38.8203" stroke={stroke} strokeWidth="1.50577" strokeLinecap="round" strokeLinejoin="round" />
      </g>
      <defs>
        <clipPath id="clip0_3660_42734">
          <rect width="18" height="18" fill="white" transform="translate(18 17)" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default RemainingUsageIcon;
