import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import { useState } from 'react';
import { useTheme } from '@mui/styles';
import { useLocale } from 'util/hooks';
import { CopyIcon } from 'assets/icons';

const CopyToClipboardButton = (props) => {
  const {
    buttonLabel = '',
    textToCopy = '',
    disabled = false,
    buttonProps = {},
  } = props;

  const { t } = useLocale();
  const { palette } = useTheme();

  const [openTooltip, setOpenTooltip] = useState(false);
  const handleClose = () => {
    setOpenTooltip(false);
  };
  const handleOpen = () => {
    setOpenTooltip(true);
  };

  const copyToClipboard = (e) => {
    navigator.clipboard.writeText(textToCopy);
    handleOpen();
  };

  return (
    <Tooltip
      title={t('common.copiedToClipboard')}
      placement="top"
      leaveDelay={1500}
      open={openTooltip}
      onClose={handleClose}
    >
      <Button
        size="small"
        color="secondary"
        onClick={copyToClipboard}
        disabled={disabled}
        startIcon={(
          <CopyIcon
            width="16"
            height="16"
            stroke={palette.secondary.main}
          />
        )}
        {...buttonProps}
      >
        <Typography
          variant="bodySmallBold"
          color="secondary"
          sx={{
            maxWidth: 300,
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            display: 'block',
            textOverflow: 'ellipsis',
          }}
        >
          {buttonLabel}
        </Typography>
      </Button>
    </Tooltip>
  );
};

export default CopyToClipboardButton;
