import * as Sentry from '@sentry/react';
import { ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary';

import { ErrorContainer } from 'components/organisms';
import ERRORS_TYPES from 'assets/constants/errorsTypes';

const ErrorBoundary = (props) => {
  const defaultErrorFallback = ({ error, onReset }) => {
    const type = error.type ?? ERRORS_TYPES.crash;

    return (
      <ErrorContainer
        type={type}
        error={error}
        resetErrorBoundary={onReset}
      />
    );
  };

  const defaultOnError = (error) => {
    // https://docs.sentry.io/platforms/javascript/guides/react/usage/
    Sentry.captureException(error);
  };

  const {
    onReset,
    fallbackComponent = defaultErrorFallback,
    onError = defaultOnError,
    children,
  } = props;

  return (
    <ReactErrorBoundary
      FallbackComponent={fallbackComponent}
      onReset={onReset}
      onError={onError}
    >
      {children}
    </ReactErrorBoundary>
  );
};

export default ErrorBoundary;
