import _ from 'lodash';

export const REGEX = {
  alphabet: /^[a-zA-Zء-ي ]+$/,
  alphaNumericAr: /^[\u0600-\u06FF\u0750-\u077F٠١٢٣٤٥٦٧٨٩ ]*$/,
  alphaNumericArSpaceSpecialCharacter: /^[\u0600-\u06FF\u0750-\u077F٠١٢٣٤٥٦٧٨٩!$%^&*()_+|~=`{}[:;<>?,-.@#\] +(\r\n|\r|\n)]*$/,
  alphaNumeric: /^[a-zA-Z0-9]*$/,
  alphaNumericSpace: /^[a-zA-Z0-9 ]*$/,
  alphabetEnSpace: /^[a-zA-Z ]*$/,
  alphaNumericSpecialCharacter: /^[a-zA-Z0-9-*\S-.!$%^&*()_+|~=`{}[:;<>?,.@#\]]*$/,
  alphaNumericSpaceSpecialCharacter: /^[a-zA-Z0-9-!$%^&*()_+|~=`{}[:;<>?,.@#\] +(\r\n|\r|\n)]*$/,
  alphaNumericUnderscore: /^[a-zA-Z0-9_]*$/,
  alphaNumericArEn: /^[a-zA-Z0-9\u0600-\u06FF\u0750-\u077F٠١٢٣٤٥٦٧٨٩ ]*$/,
  alphaNumericArEnComma: /^[a-zA-Z0-9\u0600-\u06FF\u0750-\u077F٠١٢٣٤٥٦٧٨٩, ]*$/,
  alphaNumericArEnCommaDashDot: /^[a-zA-Z0-9\u0600-\u06FF\u0750-\u077F٠١٢٣٤٥٦٧٨٩,-. ]*$/,
  alphaNumericArEnCommaDashDotNewLine: /^[a-zA-Z0-9\u0600-\u06FF\u0750-\u077F٠١٢٣٤٥٦٧٨٩,-.\n ]*$/,
  alphaNumericEnCommaDash: /^[a-zA-Z0-9_-]*$/,
  alphaNumericEnCommaDashDot: /^[a-zA-Z0-9\s.-]*$/,
  notAlphaNumeric: /[^\w]/g,
  saudiMobileNumberWZero: /^(0)(5|0|3|6|4|9|1|8|7)([0-9]{8})/g,
  saudiMobileNumber: /^(5)(5|0|3|6|4|9|1|8|7)([0-9]{7})/g,
  saudiMobileNumberWCountryCode: /^(966)(5)(5|0|3|6|4|9|1|8|7)([0-9]{7})/g,
  nationalIdIqama: /^(1|2)([0-9]{9})/g,
  typeSaudiMobileNumber: /^(5)/,
  typeNationalIdIqama: /^(1|2)/,
  thousandSeparator: /(\d)(?=(\d{3})+(?!\d))/g,
  digits: /^[0-9]*$/,
  numbers: /\d/,
  allNumbers: /^\d+$/,
  expiryDate: /[0-9/]/g,
  lowercase: /[a-z]/,
  uppercase: /[A-Z]/,
  specialCharacter: /[-!$%^&*()_+|~=`{}[:;<>?,.@#\]]/,
  decimalDigits: /^\d{0,15}\.?(\d{1,4})?$/,
  arabicDigits: /[٠١٢٣٤٥٦٧٨٩]/g,
  arabicCharacters: /[\u0600-\u06FF\u0750-\u077F]/,
  whiteSpace: /\s/g,
  noWhiteSpace: /^\s*\S.*$/,
  mail: /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/,
  password: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[-!$%^&*()_+|~=`{}[:;<>?,.@#\]])(?=.{8,})/,
  url: /^(http[s]?:\/\/(www\.)?|ftp:\/\/(www\.)?|www\.){0,1}([0-9A-Za-z-*.@:%_+~#=]+)+((\.[a-zA-Z]{2,3})+)(\/(.)*)?(\?(.)*)?/,
  ip: /^(?!.*\.$)((?!0\d)(1?\d?\d|25[0-5]|2[0-4]\d)(\.|$)){4}$/,
  ipWithPort: /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5]):[0-9]+$/,
  urlOrIpWithPort: /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/,
  iban: /^([A-Z]{2})([0-9]{2})([A-Z0-9]{9,30})$/,
  questionAnd: /\?&/,
  creditCard: /(^4[0-9]{12}(?:[0-9]{3})?$)|(^(?:5[1-5][0-9]{2}|222[1-9]|22[3-9][0-9]|2[3-6][0-9]{2}|27[01][0-9]|2720)[0-9]{12}$)|(3[47][0-9]{13})|(^3(?:0[0-5]|[68][0-9])[0-9]{11}$)|(^6(?:011|5[0-9]{2})[0-9]{12}$)|(^(?:2131|1800|35\d{3})\d{11}$)/,
  noSlash: /^[^/]*$/,
};

export const testRegex = (pattern, text = '') => {
  if (!text || !text.length) {
    return false;
  }
  if (typeof pattern === 'string') {
    const escapedPattern = pattern.replace(/\\/g, '\\\\');
    return new RegExp(escapedPattern).test(text);
  }
  return new RegExp(pattern).test(text);
};

export const convertArNumbersToEn = (number) => (number
  .replace(/[٠١٢٣٤٥٦٧٨٩]/g, (d) => d.charCodeAt(0) - 1632)
  .replace(/[۰۱۲۳۴۵۶۷۸۹]/g, (d) => d.charCodeAt(0) - 1776));

export const numberWithCommas = (number) => {
  if (number || number === 0) {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
  return number;
};

export const numberWithoutCommas = (number) => number && number.toString() && parseFloat(number.toString().replace(/,/g, ''));

export const formatNumber = (numericValue, commaSeparated = 'true') => {
  if (testRegex(REGEX.numbers, numericValue) || testRegex(REGEX.arabicDigits, numericValue)) {
    const convertedNumber = convertArNumbersToEn(numericValue);
    const filteredNumber = numberWithoutCommas(convertedNumber);
    const clearNumber = Number.isNaN(filteredNumber) ? '' : filteredNumber;
    return commaSeparated
      ? numberWithCommas(clearNumber)
      : clearNumber.toString();
  }
  return '';
};

export const numberWithoutSpaces = (number) => number && number.toString().replace(/\s+/g, '');

export const numberWithoutSlash = (number) => number && number.toString().replace(/\//g, '');

export const formatCardNumber = (numericValue) => {
  let convertedNumber = numericValue;

  if (testRegex(REGEX.arabicDigits, numericValue)) {
    convertedNumber = convertArNumbersToEn(numericValue);
  }

  if (testRegex(REGEX.numbers, convertedNumber)) {
    const formattedNumber = convertedNumber.replace(/\s+/g, '').replace(/[^0-9]/gi, '');

    const numbersInBetween = formattedNumber.match(/\d{4,16}/g);
    const matchInBetween = (numbersInBetween && numbersInBetween[0]) || '';
    const parts = [];

    for (let i = 0, len = matchInBetween.length; i < len; i += 4) {
      parts.push(matchInBetween.substring(i, i + 4));
    }

    if (parts.length) {
      return parts.join(' ');
    }

    return convertedNumber;
  }
  return '';
};

export const formatExpiryDate = (numericValue) => {
  let convertedNumber = numericValue;

  if (testRegex(REGEX.arabicDigits, numericValue)) {
    convertedNumber = convertArNumbersToEn(numericValue);
  }

  if (testRegex(REGEX.numbers, convertedNumber)) {
    const formattedNumber = convertedNumber.replace(/\s+/g, '').replace(/[^0-9]/gi, '');
    const numbersInBetween = formattedNumber.match(/\d{2,4}/g);
    const matchInBetween = (numbersInBetween && numbersInBetween[0]) || '';
    const parts = [];

    for (let i = 0, len = matchInBetween.length; i < len; i += 2) {
      parts.push(matchInBetween.substring(i, i + 2));
    }

    if (parts.length) {
      return parts.join('/');
    }
    return convertedNumber;
  }
  return '';
};

export const capitalizeFirstLetter = (string = '') => string && string.charAt(0).toUpperCase() + string.slice(1);

export const delay = (timeout = 1000) => new Promise((resolve) => {
  setTimeout(resolve, timeout);
});

export const isDate = (maybeDate) => (new Date(maybeDate) !== 'Invalid Date') && !isNaN(new Date(maybeDate));

/**
 *
 * @param {object} newValues
 * @param {object} initialValues
 *
 * @returns An object containing the keys and values
 * that has changed in newValues since initialValues
 */
export const getDirtyObject = (newValues = {}, initialValues = {}) => Object
  .entries(newValues)
  .reduce((acc, [key, value]) => {
    // account for dates of different formats
    const hasChanged = isDate(value)
      ? !_.isEqual(new Date(initialValues[key]), new Date(value))
      : !_.isEqual(initialValues[key], value);
    if (hasChanged) {
      acc[key] = value;
    }
    return acc;
  }, {});

/**
 *
 * @param {dirtyFields} object
 * @param {allValues} object
 *
 * @returns An object containing dirty fields only
 */
export const getDirtyFormValues = (dirtyFields, allValues) => {
  // If *any* item in an array was modified, the entire array must be submitted, because there's no
  // way to indicate "placeholders" for unchanged elements. `dirtyFields` is `true` for leaves.
  if (dirtyFields === true || Array.isArray(dirtyFields)) {
    return allValues;
  }

  // Here, we have an object.
  return Object.fromEntries(
    Object.keys(dirtyFields).map((key) => [
      key,
      getDirtyFormValues(dirtyFields[key], allValues[key]),
    ]),
  );
};

/**
 *
 * @param {*} arr
 * @param {*} chuckSize
 * @returns an array containing chunk based on chunkSize
 */
export const chunkArray = (arr = [], chuckSize = 0) => {
  if (!chuckSize) return arr;

  const chunked = [];

  for (let i = 0; i < arr.length; i += chuckSize) {
    chunked.push(arr.slice(i, i + chuckSize));
  }

  return chunked;
};

export const generateRandomBetween = (min, max) => Math
  .floor(Math.random() * (max - min + 1)) + min;

export const calculateDateDiff = (start, end) => {
  if (!start || !end) {
    return null;
  }

  const startDate = new Date(start);
  const endDate = new Date(end);
  // https://stackoverflow.com/questions/13903897/javascript-return-number-of-days-hours-minutes-seconds-between-two-dates#answer-13904621
  let seconds = Math.floor((endDate - (startDate)) / 1000);
  let minutes = Math.floor(seconds / 60);
  let hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);

  hours -= (days * 24);
  minutes = minutes - (days * 24 * 60) - (hours * 60);
  seconds = seconds - (days * 24 * 60 * 60) - (hours * 60 * 60) - (minutes * 60);

  return {
    days,
    hours,
    minutes,
    seconds,
  };
};

export const handleApiErrors = ({
  error = {},
  setError = () => { },
  snack = () => { },
  options = {},
}) => {
  const { isForm = true } = options;

  if (isForm && error.errors) {
    error.errors.forEach(({ property, message }) => {
      setError(property, {
        type: 'api',
        message: capitalizeFirstLetter(message),
      });
    });
  }

  if (error.message) {
    snack({ message: capitalizeFirstLetter(error.message), severity: 'error' });
  }
};
