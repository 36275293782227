import { useMemo, useState } from 'react';
import * as Yup from 'yup';

import {
  Typography,
  Box,
  Alert,
  Card,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { FormPasswordField, FormTextField } from 'components/form';
import { useLocale, useFastForm } from 'util/hooks';
import { connectAccount } from 'services';
import { useParams } from 'react-router-dom';

const AccountConnectionForm = (props) => {
  const { onConnectSuccess = () => {} } = props;

  const [connectError, setConnectError] = useState(false);
  const { t } = useLocale();
  const { token } = useParams();

  const defaultValues = {
    username: '',
    password: '',
  };

  const validationSchema = useMemo(() => (Yup.object({
    username: Yup.string()
      .required(t('login.enterEmail/username')),
    password: Yup.string()
      .required(t('password.enterPassword')),
  })), [t]);

  const onSubmit = async (values) => {
    const loginCredentials = {
      username: values.username,
      password: values.password,
    };

    try {
      const { url } = await connectAccount({ ...loginCredentials, token });
      onConnectSuccess(url);
    } catch (error) {
      setConnectError(error);
    }
  };

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useFastForm({
    defaultValues,
    validationSchema,
  });

  const form = (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box
        sx={{
          display: 'grid',
          rowGap: 8,
          alignItems: 'center',
          py: 10,
          px: {
            sm: 25,
            xs: 5,
          },
        }}
      >
        {connectError
          && (
            <Alert severity="error" sx={{ width: 1, mb: 8 }}>
              {t('login.wrongCredentials')}
            </Alert>
          )}
        <Typography
          sx={{
            marginBottom: 4,
          }}
          variant="h4"
        >
          {t('connectAccount.header')}
        </Typography>
        <Box>
          <FormTextField
            name="username"
            control={control}
            autoComplete="username"
            label={t('login.email/username')}
            fullWidth
            autoFocus
          />
        </Box>
        <Box>
          <FormPasswordField
            name="password"
            control={control}
            showHelper={false}
            autoComplete="current-password"
            label={t('common.password')}
            fullWidth
          />
        </Box>
        <LoadingButton
          sx={{ my: 2 }}
          type="submit"
          variant="contained"
          size="large"
          fullWidth
          loading={isSubmitting}
          disabled={isSubmitting}
        >
          {t('common.connect')}
        </LoadingButton>
      </Box>
    </form>
  );

  return (
    <Card>
      {form}
    </Card>
  );
};

export default AccountConnectionForm;
