import { useTheme } from '@mui/styles';

const ControllerIcon = (props) => {
  const { palette } = useTheme();
  const {
    stroke = palette.primaty.contrastText,
    fill = 'none',
    ...rest
  } = props;

  return (
    <svg width="28" height="29" viewBox="0 0 28 29" fill={fill} xmlns="http://www.w3.org/2000/svg" {...rest}>
      <g clipPath="url(#clip0_7344_101622)">
        <path d="M25.373 13.0039H2.62305C1.65655 13.0039 0.873047 13.7874 0.873047 14.7539V23.5039C0.873047 24.4704 1.65655 25.2539 2.62305 25.2539H25.373C26.3395 25.2539 27.123 24.4704 27.123 23.5039V14.7539C27.123 13.7874 26.3395 13.0039 25.373 13.0039Z" stroke={stroke} strokeWidth="1.77777" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M26.9837 14.0667L23.6237 4.25391H20.1237C20.1237 6.18707 17.382 7.75391 13.9987 7.75391C10.6153 7.75391 7.87367 6.18707 7.87367 4.25391H4.37367L1.01367 14.0667" stroke={stroke} strokeWidth="1.77777" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M3.49805 21.7539H0.873047V16.5039H3.49805C3.73011 16.5039 3.95267 16.5961 4.11677 16.7602C4.28086 16.9243 4.37305 17.1468 4.37305 17.3789V20.8789C4.37305 21.111 4.28086 21.3335 4.11677 21.4976C3.95267 21.6617 3.73011 21.7539 3.49805 21.7539Z" stroke={stroke} strokeWidth="1.77777" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M24.5 21.7539H27.125V16.5039H24.5C24.2679 16.5039 24.0454 16.5961 23.8813 16.7602C23.7172 16.9243 23.625 17.1468 23.625 17.3789V20.8789C23.625 21.111 23.7172 21.3335 23.8813 21.4976C24.0454 21.6617 24.2679 21.7539 24.5 21.7539Z" stroke={stroke} strokeWidth="1.77777" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M9.62695 18.2539H18.377" stroke={stroke} strokeWidth="1.77777" strokeLinecap="round" strokeLinejoin="round" />
      </g>
      <defs>
        <clipPath id="clip0_7344_101622">
          <rect width="28" height="28" fill="white" transform="translate(0 0.753906)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ControllerIcon;
