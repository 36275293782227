import { useTheme } from '@mui/styles';

const DownloadIcon = (props) => {
  const theme = useTheme();
  const {
    stroke = theme.palette.text.raspberry,
    fill = 'none',
    ...rest
  } = props;

  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill={fill} xmlns="http://www.w3.org/2000/svg" stroke={stroke} {...rest}>
      <path d="M10 3.125V13.125" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M6.25 9.375L10 13.125L13.75 9.375" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M19.375 13.125V14.375C19.375 15.038 19.1116 15.6739 18.6428 16.1428C18.1739 16.6116 17.538 16.875 16.875 16.875H3.125C2.46196 16.875 1.82607 16.6116 1.35723 16.1428C0.888392 15.6739 0.625 15.038 0.625 14.375V13.125" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default DownloadIcon;
