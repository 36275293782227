import {
  useEffect,
  useState,
} from 'react';
import { useRecoilValue } from 'recoil';
import { authAtom } from 'recoil/atoms';
import io from 'socket.io-client';

import SocketContext from './socket';

const { REACT_APP_SOCKET_URL } = process.env;

const SocketProvider = ({ socketQuery, children }) => {
  const { accessToken } = useRecoilValue(authAtom);
  const [socket, setSocket] = useState(null);

  useEffect(() => {
    const newSocket = io(
      REACT_APP_SOCKET_URL,
      {
        transports: ['websocket'],
        auth: {
          token: `Bearer ${accessToken}`,
        },
        query: {
          ...socketQuery,
        },
      },
    );

    setSocket(newSocket);

    return () => { socket?.disconnect(); };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken]);

  return (
    <SocketContext.Provider value={socket}>
      {children}
    </SocketContext.Provider>
  );
};

export default SocketProvider;
