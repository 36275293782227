import Box from '@mui/material/Box';
import { useInView } from 'react-intersection-observer';

const AnimateOnScroll = (props) => {
  const {
    sx = {},
    children,
    options = {},
    triggerRenderInView = false,
    ...rest
  } = props;

  const { ref, inView } = useInView({
    rootMargin: '-12% 0px',
    triggerOnce: true,
    ...options,
  });

  const style = {
    opacity: inView ? 1 : 0,
    transform: inView ? 'translateY(0)' : 'translateY(40px)',
    transition: inView ? 'opacity 250ms ease-in-out, transform 250ms ease' : '',
    ...sx,
  };

  const isChildrenInView = inView ? children : null;
  const toRender = triggerRenderInView ? isChildrenInView : children;

  return (
    <Box
      ref={ref}
      sx={style}
      {...rest}
    >
      {toRender}
    </Box>
  );
};

export default AnimateOnScroll;
