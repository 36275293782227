import React from 'react';

import { DataGrid } from 'components/core';
import { downloadInvoice as downloadInvoiceService } from 'services';
import { useSnackbar } from 'util/hooks';

import useLocale from 'util/hooks/useLocale';
import { useTransactions } from 'reactQuery/queries';
import useTableColumns from './useTableColumns';

const TransactionTable = () => {
  const { t } = useLocale();
  const snack = useSnackbar();

  const downloadInvoice = React.useCallback(async (id, invoiceNumber) => {
    try {
      await downloadInvoiceService(id, invoiceNumber);
    } catch (error) {
      snack({
        message: error.message || t('common.errorMessage'),
        severity: 'error',
      });
    }
  }, [snack, t]);

  const columns = useTableColumns({ downloadInvoice });

  const {
    data = {},
    isFetching = true,
  } = useTransactions();

  const {
    items: rows = [],
    meta = {},
  } = data || {};

  return (
    <DataGrid
      rows={rows}
      columns={columns}
      loading={isFetching}
      pagination={meta}
      componentsProps={{
        noRowsOverlay: { type: 'transaction' },
      }}
    />
  );
};

export default TransactionTable;
