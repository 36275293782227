import {
  useState,
  useMemo,
  useEffect,
} from 'react';
import { useNavigate } from 'react-router-dom';

import { Box } from '@mui/material';

import { DataList } from 'components/core';
import { PATHS } from 'routes';

import { STATUS } from 'assets/constants/statuses';

import VISIBILITIES from 'assets/constants/visibilities';
import { useRecoilState } from 'recoil';
import { machinesFilterAtom } from 'recoil/atoms';
import useListColumns from './useListColumns';
import MachineToolbar from './sections/MachineToolbar';
import MachineActions from '../Machines/managers/sections/MachineActions';

const MachinesList = (props) => {
  const {
    status = '',
    machines = [],
  } = props;

  const navigate = useNavigate();

  const {
    isPublishedList,
    isArchivedList,
    isRetiredList,
    checkboxSelection,
    showActions,
  } = useMemo(() => ({
    isPublishedList: status === STATUS.published,
    isDraftList: status === STATUS.draft,
    isRetiredList: status === STATUS.retired,
    isArchivedList: status === STATUS.archived,
    checkboxSelection: !(status === STATUS.archived),
    showActions: !(status === STATUS.archived),
  }), [status]);

  // List state
  const [selectedRows, setSelectedRows] = useState([]);
  const columns = useListColumns({ isArchivedList });

  // Filter state
  const showVisibleFilter = isPublishedList || isRetiredList;

  const [filter, setFilter] = useRecoilState(machinesFilterAtom);

  useEffect(() => {
    const initialFilter = {};
    initialFilter.type = 'visibility';
    initialFilter.value = VISIBILITIES.visible;

    setFilter(initialFilter);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showVisibleFilter]);

  const rows = useMemo(() => {
    if (!showVisibleFilter) {
      return machines;
    }

    switch (filter.value) {
      case VISIBILITIES.visible:
        return machines.filter((machine) => machine.visible);
      case VISIBILITIES.hidden:
        return machines.filter((machine) => !machine.visible);
      default:
        return machines;
    }
  }, [machines, filter.value, showVisibleFilter]);

  return (
    <>
      <MachineToolbar
        rows={rows}
        status={status}
        showFilter={showVisibleFilter}
      />
      <DataList
        rows={rows}
        columns={columns}
        withSearch={false}
        getRowId={(row) => row.name}
        checkboxSelection={checkboxSelection}
        selectionModel={selectedRows.map((r) => r.name)}
        onSelectionModelChange={(newSelectionModel) => {
          const selectedNames = new Set(newSelectionModel);
          const selectedRowData = rows.filter((row) => selectedNames.has(row.name));
          setSelectedRows(selectedRowData);
        }}
        onRowClick={({ row }) => {
          const { name = '' } = row || {};
          navigate(`/${PATHS.machines}/${name}`);
        }}
        componentsProps={{
          noRowsOverlay: { type: 'machines' },
        }}
      />
      {showActions && (
      <Box sx={{
        minHeight: 48,
        mt: 9,
      }}
      >
        <MachineActions
          listView
          machineStatus={status}
          machines={selectedRows}
        />
      </Box>
      )}
    </>
  );
};

export default MachinesList;
