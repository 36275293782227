import { useTheme } from '@mui/styles';

const DiscordIcon = (props) => {
  const theme = useTheme();
  const {
    fill = theme.palette.text.primary,
    ...rest
  } = props;

  return (
    <svg width="24" height="18" viewBox="0 0 24 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <path d="M19.5336 1.66497C18.0629 0.990154 16.4858 0.492979 14.8368 0.208226C14.8068 0.20273 14.7768 0.216464 14.7613 0.243932C14.5585 0.604679 14.3339 1.0753 14.1765 1.44521C12.403 1.17969 10.6385 1.17969 8.90132 1.44521C8.74396 1.06708 8.51113 0.604679 8.30739 0.243932C8.29192 0.217381 8.26193 0.203647 8.2319 0.208226C6.58385 0.492069 5.00676 0.989244 3.53515 1.66497C3.52241 1.67046 3.51149 1.67963 3.50425 1.69152C0.512824 6.16064 -0.306652 10.5199 0.0953557 14.8251C0.0971747 14.8462 0.108998 14.8663 0.12537 14.8792C2.09902 16.3286 4.01085 17.2085 5.88717 17.7917C5.91719 17.8009 5.94901 17.7899 5.96812 17.7652C6.41196 17.1591 6.80761 16.52 7.14684 15.8479C7.16686 15.8085 7.14775 15.7618 7.10683 15.7462C6.47927 15.5082 5.88171 15.2179 5.30689 14.8883C5.26142 14.8618 5.25778 14.7967 5.29961 14.7656C5.42057 14.675 5.54157 14.5806 5.65707 14.4854C5.67797 14.468 5.70709 14.4644 5.73166 14.4754C9.50795 16.1995 13.5962 16.1995 17.328 14.4754C17.3526 14.4635 17.3817 14.4671 17.4035 14.4845C17.519 14.5797 17.64 14.675 17.7619 14.7656C17.8037 14.7967 17.801 14.8618 17.7555 14.8883C17.1807 15.2243 16.5831 15.5082 15.9546 15.7453C15.9137 15.7609 15.8955 15.8085 15.9155 15.8479C16.262 16.519 16.6577 17.1581 17.0933 17.7643C17.1115 17.7899 17.1443 17.8009 17.1743 17.7917C19.0597 17.2085 20.9715 16.3286 22.9452 14.8792C22.9625 14.8663 22.9734 14.8471 22.9752 14.826C23.4563 9.84874 22.1694 5.52522 19.5636 1.69243C19.5572 1.67963 19.5463 1.67046 19.5336 1.66497ZM7.71077 12.2037C6.57384 12.2037 5.63705 11.1599 5.63705 9.87805C5.63705 8.59618 6.55568 7.5524 7.71077 7.5524C8.87493 7.5524 9.80266 8.60534 9.78446 9.87805C9.78446 11.1599 8.86583 12.2037 7.71077 12.2037ZM15.378 12.2037C14.2411 12.2037 13.3043 11.1599 13.3043 9.87805C13.3043 8.59618 14.2229 7.5524 15.378 7.5524C16.5422 7.5524 17.4699 8.60534 17.4517 9.87805C17.4517 11.1599 16.5422 12.2037 15.378 12.2037Z" fill={fill} />
    </svg>

  );
};

export default DiscordIcon;
