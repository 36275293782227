import {
  useContext,
  useEffect,
  useState,
} from 'react';
import { useParams } from 'react-router-dom';

import { Box } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import {
  useLocale,
  useIsEvent,
  useSnackbar,
  useRecaptcha,
} from 'util/hooks';

import { SocketContext } from 'context';
import SOCKET_EVENTS from 'assets/constants/socketEvents';
import { CopyToClipboardButton, OnlineStatusBadge } from 'components/molecules';
import { useEventChallengeDetails, useLabChallengeDetails } from 'reactQuery/queries';

import {
  endLabChallengeInstance,
  endEventChallengeInstance,
  startLabChallengeInstance,
  startEventChallengeInstance,
} from 'services';

const useChallengeInstance = (props) => {
  const {
    isContentDisabled = false,
  } = props;

  const { t } = useLocale();
  const snack = useSnackbar();
  const socket = useContext(SocketContext);
  const { labId, challengeId, eventId } = useParams();
  const { executeRecaptcha } = useRecaptcha({ action: 'TOGGLE_INSTANCE' });

  const isEventChallenge = useIsEvent();

  const {
    data: labChallengeDetails = {},
  } = useLabChallengeDetails({
    options: {
      enabled: !isEventChallenge,
    },
  });

  const {
    data: eventChallengeDetails = {},
  } = useEventChallengeDetails({
    options: {
      enabled: isEventChallenge,
    },
  });

  const challengeDetails = isEventChallenge ? eventChallengeDetails : labChallengeDetails;

  const {
    currentRunningInstanceForUser = {},
  } = challengeDetails || {};

  const {
    isRunning = false,
    instanceAddress = '',
  } = currentRunningInstanceForUser || {};

  const [isLoading, setIsLoading] = useState(false);
  const [instance, setInstance] = useState({
    isRunning,
    instanceAddress,
  });
  const [socketFeedback, setSocketFeedback] = useState({
    type: null,
    message: '',
    isLastMessage: false,
  });

  useEffect(() => {
    setInstance({
      isRunning,
      instanceAddress,
    });
  }, [isRunning, instanceAddress]);

  useEffect(() => {
    const instanceListener = (socketProps = {}) => {
      const {
        type = null,
        data = {},
      } = socketProps;

      const {
        message = '',
        address = '',
      } = data || {};

      if (type === 'error') {
        // console.log('socket error', message);
      }

      if (type === SOCKET_EVENTS.instance.instanceStopped) {
        setInstance({
          isRunning: false,
          instanceAddress: '',
        });
      }

      if (address) {
        setInstance((prev) => ({
          ...prev,
          instanceAddress: address,
          isRunning: true,
        }));
      }

      const isLastMessage = type === SOCKET_EVENTS.instance.ingress
        || type === SOCKET_EVENTS.instance.instanceStopped
        || type === SOCKET_EVENTS.error;
      setSocketFeedback((prev) => ({
        ...prev,
        type,
        message,
        isLastMessage,
      }));
    };

    socket.on(SOCKET_EVENTS.instance.challengeInstance, instanceListener);

    return () => socket.off(SOCKET_EVENTS.instance.challengeInstance, instanceListener);
  }, [socket]);

  const handleInstanceToggle = async () => {
    setIsLoading(true);
    try {
      const recaptchaToken = await executeRecaptcha();

      if (isEventChallenge) {
        instance?.isRunning
          ? await endEventChallengeInstance(eventId, challengeId, { recaptchaToken })
          : await startEventChallengeInstance(eventId, challengeId, { recaptchaToken });
      } else {
        instance?.isRunning
          ? await endLabChallengeInstance(labId, challengeId, { recaptchaToken })
          : await startLabChallengeInstance(labId, challengeId, { recaptchaToken });
      }
    } catch (error) {
      const { errors = [] } = error;
      snack({
        message: errors?.[0]?.message || error.message || t('common.errorMessage'),
        severity: 'error',
      });
    }
    setTimeout(() => {
      setIsLoading(false);
    }, [500]); // allow time for socket messages to show
  };

  const renderButtonText = () => {
    if (socketFeedback.isLastMessage) {
      setTimeout(() => {
        setSocketFeedback((prev) => ({
          ...prev,
          isLastMessage: false,
          message: '',
        }));
      }, [2000]);
    }

    if (socketFeedback?.message) {
      return (
        <Box>
          {socketFeedback?.message}
        </Box>
      );
    }

    return instance?.isRunning
      ? t('challenges.terminateInstance')
      : t('challenges.startInstance');
  };

  const subtitle = instance?.isRunning
    ? t('challenges.terminateYourInstance')
    : t('challenges.startYourInstance');

  const header = (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Box sx={{ alignSelf: { xs: 'start', sm: 'end' }, px: 4 }}>
        <OnlineStatusBadge online={instance?.isRunning} />
      </Box>
      <Box sx={{
        display: 'flex',
        alignItems: 'center',
        minWidth: 172, // reduce ui shift
        minHeight: 32,
      }}
      >
        {instance?.instanceAddress
          && (
            <CopyToClipboardButton
              buttonLabel={t('common.copyInstanceUrl')}
              textToCopy={instance.instanceAddress}
            />
          )}
      </Box>
    </Box>
  );

  const component = (
    <Box>
      <LoadingButton
        loading={isLoading}
        loadingPosition="start"
        startIcon={(
          <Box sx={{
            display: isLoading ? 'block' : 'none',
            width: 10,
            height: 10,
          }}
          />
        )}
        variant="outlined"
        color="secondary"
        onClick={handleInstanceToggle}
        disabled={isContentDisabled || Boolean(socketFeedback?.message)}
      >
        {renderButtonText()}
      </LoadingButton>
    </Box>
  );

  return {
    subtitle,
    header,
    component,
  };
};

export default useChallengeInstance;
