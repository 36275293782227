import {
  Chip,
} from '@mui/material';

import { getStatusByName } from 'assets/constants/statuses';
import { useDate, useLocale } from 'util/hooks';
import { numberWithCommas } from 'util/helpers';
import { DownloadButton } from 'components/molecules';

const useTableColumns = (props) => {
  const { downloadInvoice } = props;

  const { t, isAr } = useLocale();
  const { format } = useDate();

  const columns = [
    {
      field: 'number',
      headerName: t('transactions.transactionId'),
      sortable: false,
      flex: 1,
      minWidth: 150,
    },
    {
      field: 'createdAt',
      headerName: t('common.date'),
      flex: 1,
      minWidth: 150,
      valueGetter: ({ row: { createdAt } }) => format(createdAt, 'PP'),
    },
    {
      field: 'description',
      headerName: t('transactions.description'),
      sortable: false,
      flex: 1,
      minWidth: 250,
      valueGetter: ({ row: { descriptionAr, descriptionEn } }) => (
        isAr
          ? descriptionAr
          : descriptionEn
      ),
    },
    {
      field: 'partialCard',
      headerName: t('paymentMethods.cardNumber'),
      sortable: false,
      flex: 1,
      minWidth: 150,
      valueGetter: ({ row: { partialCard } }) => `**** **** **** ${partialCard}`,
    },
    {
      field: 'totalAmount',
      headerName: t('transactions.totalAmount'),
      sortable: false,
      flex: 1,
      minWidth: 150,
      valueGetter: ({ row: { totalAmount } }) => `${numberWithCommas(totalAmount || 0)} ${t('currency.sar')}`,
    },
    {
      field: 'status',
      headerName: t('common.status'),
      sortable: false,
      flex: 1,
      minWidth: 150,
      renderCell: (params) => (
        <Chip
          size="small"
          color={params.row.status || ''}
          label={t(getStatusByName(params?.row?.status)?.localizationKey) || ''}
        />
      ),
    },
    {
      field: 'download',
      headerName: t('transactions.download'),
      type: 'actions',
      flex: 1,
      minWidth: 150,
      renderCell: (params) => {
        const { row = {} } = params;

        return (
          <DownloadButton
            invoice={row}
            downloadInvoice={downloadInvoice}
          />
        );
      },
    },
  ];

  return columns;
};

export default useTableColumns;
