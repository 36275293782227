import PropTypes from 'prop-types';
import { FormControlLabel, Radio, RadioGroup as MUIRadioGroup } from '@mui/material';
import { forwardRef } from 'react';

const RadioGroup = forwardRef((props, ref) => {
  const {
    options,
    value,
    onChange,
    radioGroupProps,
    radioProps,
    ...rest
  } = props;

  const handleChange = (event) => {
    const { value: newValue } = event.target;
    const maybeStrToBool = (newValue === 'true' || newValue === 'false')
      ? newValue === 'true'
      : newValue;

    onChange(maybeStrToBool);
  };

  return (
    <MUIRadioGroup
      value={String(value) || String(options?.[0]?.value)}
      onChange={handleChange}
      row
      ref={ref}
      {...radioGroupProps}
    >
      {options?.map((option) => (
        <FormControlLabel
          key={option.value}
          control={(
            <Radio
              {...radioProps}
              {...rest}
              sx={{
                color: 'text.darkGray',
                ...radioProps?.sx,
              }}
            />
          )}
          value={String(option.value)}
          label={option.label}
          sx={{
            '.MuiFormControlLabel-label': {
              '&.Mui-disabled': {
                color: 'text.secondary',
              },
            },
          }}
        />
      ))}
    </MUIRadioGroup>
  );
});

RadioGroup.propTypes = {
  options: PropTypes.oneOfType([PropTypes.array]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  onChange: PropTypes.func,
  radioGroupProps: PropTypes.oneOfType([PropTypes.object]),
  radioProps: PropTypes.oneOfType([PropTypes.object]),
};

RadioGroup.defaultProps = {
  options: [],
  value: '',
  onChange: () => { },
  radioGroupProps: {},
  radioProps: {},
};

export default RadioGroup;
