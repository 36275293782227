import END_POINTS from '../endPoints';
import METHODS from '../methods';
import api from '../api';

const deleteAvatar = () => api(
  END_POINTS.deleteAvatar,
  {
    method: METHODS.DELETE,
  },
)
  .then((res) => Promise.resolve(res))
  .catch((error) => Promise.reject(error));

export default deleteAvatar;
