import { Link } from 'components/core';

const components = (theme) => {
  // Theme variables
  const {
    typography,
    palette,
    shape,
    spacing,
  } = theme;

  const {
    appBar,
    drawer,
    secondary,
    primary,
    success,
    error,
    info,
    text,
    paper,
    background,
    action,
  } = palette;

  const {
    bodyStandardRegular,
    bodySmallMedium,
    bodyStandardBold,
    bodyStandardMedium,
    bodySmallBold,
  } = typography;

  const MuiAppBar = {
    styleOverrides: {
      root: {
        ...appBar,
      },
    },
  };

  const MuiDrawer = {
    styleOverrides: {
      paper: {
        ...drawer.paper,
        borderRight: 'none',
        padding: '24px',
      },
    },
  };

  const MuiButtonBase = {
    defaultProps: {
      disableRipple: true,
    },
    styleOverrides: {
      root: {
        boxShadow: 'none',
        '&:hover': {
          boxShadow: 'none',
        },
        '&.MuiTabScrollButton-root': {
          '&.Mui-disabled': {
            opacity: 0.2,
          },
        },
      },
    },
  };

  const MuiButton = {
    variants: [
      {
        props: { size: 'large' },
        style: {
          ...bodyStandardBold,
          padding: '12px 32px',
        },
      },
      {
        props: { size: 'medium' },
        style: {
          ...bodyStandardBold,
          padding: '8px 20px',
        },
      },
      {
        props: { size: 'small' },
        style: {
          ...bodySmallBold,
          padding: '4px 16px',
        },
      },
    ],
    styleOverrides: {
      root: {
        fontWeight: '700',
        boxShadow: 'none',
        textTransform: 'none',
        '&:hover': {
          boxShadow: 'none',
        },
      },
      outlined: {
        borderWidth: '2px',
        '&:hover': {
          borderWidth: '2px',
        },
        '&.Mui-disabled': {
          borderWidth: '2px',
        },
      },
      outlinedPrimary: {
        borderColor: primary.main,
        '&.Mui-disabled': {
          borderColor: action.disabledBackground,
          color: action.disabledBackground,
        },
      },
      outlinedSecondary: {
        borderColor: secondary.main,
        '&.Mui-disabled': {
          borderColor: action.disabled,
        },
      },
      outlinedError: {
        color: text.raspberry,
        borderColor: text.raspberry,
        '&.Mui-disabled': {
          borderColor: action.disabled,
        },
      },
    },
  };

  const MuiListItemButton = {
    styleOverrides: {
      root: {
        borderRadius: '6px',
        maxWidth: '200px',
        overflowWrap: 'break-word',
        '&.Mui-selected': {
          backgroundColor: paper.hover,
          '&:hover': {
            backgroundColor: paper.hover,
          },
          '& .MuiTypography-root': {
            color: text.primary,
          },
          '& .MuiListItemIcon-root': {
            stroke: text.primary,
          },
        },
        '& .MuiListItemIcon-root': {
          stroke: text.secondary,
        },
        '&:hover': {
          backgroundColor: paper.hover,
        },
      },
    },
  };
  const MuiListItemIcon = {
    styleOverrides: {
      root: {
        minWidth: '32.5px',
      },
    },
  };

  const MuiOutlinedInput = {
    styleOverrides: {
      root: {
        borderRadius: shape.borderRadius,
        backgroundColor: background.default,
        fontSize: bodyStandardRegular.fontSize,
        fontWeight: bodyStandardRegular.fontWeight,
        color: primary.contrastText,
        '&.Mui-disabled': {
          backgroundColor: text.darkGray,
          textFillColor: text.primary,
          '.MuiOutlinedInput-notchedOutline': {
            border: 0,
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: text.secondary,
          },
        },
        '&.Mui-focused .MuiOutlinedInput-notchedOutline, &:hover .MuiOutlinedInput-notchedOutline': {
          borderColor: text.secondary,
        },
        '&.Mui-error': {
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: error.main,
          },
        },
      },
      input: {
        '&:-webkit-autofill': {
          WebkitBoxShadow: `0 0 0 1000px  ${secondary.contrastText} inset`,
          WebkitTextFillColor: primary.contrastText,
          transition: '5000s ease-in-out',
        },
      },

    },
  };

  const MuiAlert = {
    variants: [
      {
        props: { severity: 'error' },
        style: {
          color: error.main,
          backgroundColor: error.background,
        },
      },
      {
        props: { severity: 'success' },
        style: {
          color: success.main,
          backgroundColor: success.background,
        },
      },
      {
        props: { severity: 'info' },
        style: {
          color: info.main,
          backgroundColor: info.background,
        },
      },
    ],
  };

  // https://mui.com/guides/routing/#global-theme-link
  const MuiLink = {
    defaultProps: {
      component: Link,
    },
    styleOverrides: {
      root: {
        ...bodyStandardBold,
        textDecoration: 'none',
        color: palette.text.raspberry,
      },
    },
  };

  const MuiChip = {
    styleOverrides: {
      root: {
        borderRadius: shape.borderRadius,
      },
      label: {
        ...bodySmallMedium,
      },
    },
  };

  const MuiTab = {
    styleOverrides: {
      root: {
        ...bodyStandardMedium,
        color: text.secondary,
        padding: 0,
        marginRight: spacing * 11,
        textTransform: 'none',
        '&.Mui-selected': {
          color: text.primary,
        },
      },
    },
  };

  const MuiAvatar = {
    styleOverrides: {
      root: {
        // backgroundColor: text.secondary, // temp, will be replaced by a default avatar
        opacity: 0.8,
        color: primary.contrastText,
      },
    },
  };

  const MuiDataGrid = {
    styleOverrides: {
      root: {
        border: 0,
        '& .MuiDataGrid-virtualScroller': {
          minHeight: 400,
        },
        '& .MuiDataGrid-columnHeaders': {
          background: paper.background,
          color: text.secondary,
          borderRadius: '6px 6px 0px 0px',
          borderBottom: `1px solid ${paper.background}`,
          '& .MuiDataGrid-columnSeparator': {
            visibility: 'hidden', // hide column header separator
          },
        },
        '& .MuiDataGrid-row': {
          '&:hover': {
            backgroundColor: paper.background,
          },
        },
        '& .MuiDataGrid-columnHeader': {
          border: 0,
          outline: 'none',
          '&:focus': {
            border: 0,
            outline: 'none',
          },
          '&:focus-within': {
            border: 0,
            outline: 'none',
          },
        },
        '& .MuiDataGrid-cell': {
          borderBottom: `1px solid ${paper.background}`,
          '&:focus': {
            outline: 'none',
          },
          '&:focus-within': {
            outline: 'none',
          },
        },
        '& .MuiDataGrid-sortIcon': {
          color: primary.main,
        },
        '& .MuiDataGrid-footerContainer': {
          border: 0,
          justifyContent: 'center',
        },
      },
    },
  };

  const MuiAutocomplete = {
    styleOverrides: {
      paper: {
        backgroundColor: background.default,
        marginTop: 6,
        marginBottom: 6,
      },
      listbox: {
        backgroundColor: background.default,
        '& .MuiAutocomplete-option[aria-selected="true"]': {
          backgroundColor: background.default,
        },
        '& .MuiAutocomplete-option:hover': {
          backgroundColor: paper.hover,
        },
      },
    },
  };

  const MuiMenuItem = {
    styleOverrides: {
      root: {
        '&.Mui-selected, &.Mui-selected:focus, &.Mui-selected:hover': {
          backgroundColor: paper.selected,
        },
        '&:hover, &:focus': {
          backgroundColor: paper.hover,
        },
      },
    },
  };

  const MuiCheckbox = {
    styleOverrides: {
      root: {
        color: text.darkGray,
      },
    },
  };

  const MuiCard = {
    styleOverrides: {
      root: {
        boxShadow: 'none',
      },
    },
  };

  const MuiSwitch = {
    styleOverrides: {
      root: {
        width: 42,
        height: 26,
        padding: 0,
        '& .MuiSwitch-switchBase': {
          padding: 0,
          margin: 2,
          color: background.paper,
          transitionDuration: '200ms',
          '&.Mui-checked': {
            transform: 'translateX(16px)',
            color: primary.main,
            '& + .MuiSwitch-track': {
              backgroundColor: background.dark,
              opacity: 1,
              border: 0,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
              opacity: 0.5,
            },
          },
          '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: background.paper,
            border: `6px solid ${primary.main}`,
          },
          '&.Mui-disabled .MuiSwitch-thumb': {
            color: text.darkGray,
          },
          '&.Mui-disabled + .MuiSwitch-track': {
            opacity: 0.3,
          },
        },
        '& .MuiSwitch-thumb': {
          boxSizing: 'border-box',
          width: 22,
          height: 22,
        },
        '& .MuiSwitch-track': {
          borderRadius: 26 / 2,
          backgroundColor: text.darkGray,
          opacity: 1,
        },
      },
    },
  };

  const MuiTooltip = {
    styleOverrides: {
      tooltip: {
        backgroundColor: paper.selected,
      },
    },
  };

  const MuiPickersDay = {
    styleOverrides: {
      root: {
        '&.Mui-disabled': {
          color: text.darkGray,
        },
      },
    },
  };

  const MuiPickersArrowSwitcher = {
    styleOverrides: {
      button: {
        color: text.raspberry,
        '&.Mui-disabled': {
          color: text.darkGray,
        },
      },
    },
  };

  const MuiIconButton = {
    styleOverrides: {
      root: {
        color: text.raspberry,
        '&.Mui-disabled': {
          color: text.darkGray,
        },
      },
    },
  };

  return {
    MuiAppBar,
    MuiDrawer,
    MuiButtonBase,
    MuiButton,
    MuiListItemButton,
    MuiListItemIcon,
    MuiOutlinedInput,
    MuiAlert,
    MuiLink,
    MuiChip,
    MuiTab,
    MuiAvatar,
    MuiDataGrid,
    MuiAutocomplete,
    MuiMenuItem,
    MuiCheckbox,
    MuiCard,
    MuiSwitch,
    MuiTooltip,
    MuiPickersDay,
    MuiPickersArrowSwitcher,
    MuiIconButton,
  };
};

export default components;
