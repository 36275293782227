import { useTheme } from '@mui/styles';

const SearchIcon = (props) => {
  const theme = useTheme();
  const {
    stroke = theme.palette.text.secondary,
    fill = 'none',
    ...rest
  } = props;

  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill={fill} xmlns="http://www.w3.org/2000/svg" stroke={stroke} {...rest}>
      <g clipPath="url(#clip0_1116_705)">
        <path d="M13.3584 18.1531C17.965 16.1954 20.1123 10.8739 18.1545 6.26729C16.1968 1.66069 10.8753 -0.486619 6.26872 1.47114C1.66212 3.4289 -0.485188 8.75036 1.47257 13.357C3.43033 17.9636 8.75179 20.1109 13.3584 18.1531Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M16.2207 16.22L23.2497 23.25" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      </g>
      <defs>
        <clipPath id="clip0_1116_705">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SearchIcon;
