import { Tabs } from 'components/molecules';
import { useLocale } from 'util/hooks';

import MachineDetails from './sections/MachineDetails';
import MachineActivity from './sections/MachineActivity';

const MachineTabs = (props) => {
  const { machineDetails = {} } = props;

  const { t } = useLocale();

  const tabs = [{
    key: 'details',
    label: t('machines.machineDetails'),
    component: <MachineDetails machineDetails={machineDetails} />,
  },
  {
    key: 'activity',
    label: t('common.activity'),
    component: <MachineActivity machineDetails={machineDetails} />,
  }];

  return (
    <Tabs tabs={tabs} />
  );
};

export default MachineTabs;
