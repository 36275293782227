import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

import App from 'App';
// import reportWebVitals from './reportWebVitals';

const {
  REACT_APP_SENTRY_DSN,
  REACT_APP_SENTRY_ENV,
} = process.env;

Sentry.init({
  dsn: REACT_APP_SENTRY_DSN,
  environment: REACT_APP_SENTRY_ENV,
  integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

const printBrand = () => {
  // eslint-disable-next-line no-console
  console.log(
    `%c
███████╗██╗░░░░░░█████╗░░██████╗░██╗░░░██╗░█████╗░██████╗░██████╗░
██╔════╝██║░░░░░██╔══██╗██╔════╝░╚██╗░██╔╝██╔══██╗██╔══██╗██╔══██╗
█████╗░░██║░░░░░███████║██║░░██╗░░╚████╔╝░███████║██████╔╝██║░░██║
██╔══╝░░██║░░░░░██╔══██║██║░░╚██╗░░╚██╔╝░░██╔══██║██╔══██╗██║░░██║
██║░░░░░███████╗██║░░██║╚██████╔╝░░░██║░░░██║░░██║██║░░██║██████╔╝
╚═╝░░░░░╚══════╝╚═╝░░╚═╝░╚═════╝░░░░╚═╝░░░╚═╝░░╚═╝╚═╝░░╚═╝╚═════╝░

👀
`,
    'font-family:monospace;color:#F0004F;font-size:12px;',
  );
};
if (typeof window !== 'undefined') {
  printBrand();
}

const container = document.getElementById('root');
const root = createRoot(container);
const wrappedApp = (
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>
);

root.render(wrappedApp);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
