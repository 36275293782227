import { useTheme } from '@mui/styles';
import { GridActionsCellItem } from '@mui/x-data-grid';
import MODAL_KEYS from 'assets/constants/modalKeys';
import { CheckCircleOutline, CrossCircleIcon, TrashBinIcon } from 'assets/icons';
import { isEmpty } from 'lodash';
import {
  useDate, useLocale, useModal,
} from 'util/hooks';

const useTableColumns = (props) => {
  const {
    refetch = () => { },
  } = props;

  const { t, dir } = useLocale();
  const { palette } = useTheme();
  const { addModal } = useModal();
  const { format } = useDate();

  const columns = [
    {
      field: 'email',
      headerName: t('common.email'),
      flex: 1,
      minWidth: 180,
    },
    {
      field: 'createdAt',
      headerName: t('invitation.invitationDate'),
      flex: 1,
      minWidth: 80,
      valueGetter: ({ row: { createdAt } }) => format(createdAt, 'PP'),
    },
    {
      field: 'user',
      headerName: t('common.hasAccount'),
      flex: 1,
      minWidth: 30,
      maxWidth: 160,
      align: 'center',
      renderCell: ({ row: { user } }) => (
        isEmpty(user) ? (
          <CrossCircleIcon
            width="16"
            height="16"
            stroke={palette.text.red}
          />
        ) : (
          <CheckCircleOutline
            width="16"
            height="16"
            stroke={palette.success.main}
          />
        )),
    },
    {
      field: 'actions',
      headerName: t('common.actions'),
      type: 'actions',
      flex: 1,
      minWidth: 50,
      getActions: (params) => {
        const actions = [
          <GridActionsCellItem
            dir={dir}
            key={params.row.id}
            icon={<TrashBinIcon width="20" height="20" />}
            label={t('cancelInvitation.cancelInvitation')}
            onClick={() => addModal({
              key: MODAL_KEYS.cancelEventInvitation,
              props: {
                invitation: { ...params.row },
                refetch,
              },
            })}
            showInMenu
          />];

        return actions;
      },
    },
  ];

  return columns;
};

export default useTableColumns;
