import {
  Box,
  Typography,
} from '@mui/material';

import { NoContentPlaceholder } from 'components/molecules';

import { PATHS } from 'routes';
import { useLocale } from 'util/hooks';
import { usePaymentCards, useSubscriptions } from 'reactQuery/queries';

import SubscriptionCard from './helpers/SubscriptionCard';

const AccountSubscriptionsContainer = () => {
  const { t } = useLocale();

  const {
    data: paymentCards = [],
  } = usePaymentCards();
  const {
    data: { items: subscriptions = [] } = {},
    isFetched = false,
  } = useSubscriptions();

  const hasPaymentCards = paymentCards?.length;

  if (isFetched && !subscriptions?.length) {
    const content = {
      desc: [{
        text: t('subscriptions.noSubscription'),
        variant: 'bodyMediumBold',
      }],
      cta: {
        text: t('subscriptions.subscribe'),
        to: `/${PATHS.subscriptions}`,
      },
    };

    return (
      <Box sx={{ mt: 30 }}>
        <NoContentPlaceholder content={content} />
      </Box>
    );
  }

  return (
    <>
      <Box>
        <Typography
          variant="bodyMediumBold"
          color="text.lighterGray"
        >
          {t('subscriptions.subscriptions')}
        </Typography>
      </Box>
      <Box maxWidth="sm">
        <Typography
          variant="bodyStandardRegular"
          color="text.secondary"
        >
          {t('subscriptions.subscriptionDesc')}
        </Typography>
      </Box>
      <Box>
        {subscriptions?.map((subscription) => (
          <SubscriptionCard
            key={subscription.id}
            subscription={subscription}
            hasPaymentCards={hasPaymentCards}
          />
        ))}
      </Box>
    </>
  );
};

export default AccountSubscriptionsContainer;
