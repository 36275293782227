import _ from 'lodash';
import {
  Box,
  Stack,
} from '@mui/material';

import { NoContentPlaceholder } from 'components/molecules';
import { PlayerActivityCard } from 'components/organisms';
import { useLocale } from 'util/hooks';

import { useDashboardLatestActivity } from 'reactQuery/queries';
import DashboardSectionHeader from '../helpers/DashboardSectionHeader';

const PlayerLatestActivityWrapper = ({ children, sx }) => {
  const { t } = useLocale();

  return (
    <Box sx={{
      width: 1,
      height: 1,
      display: 'flex',
      flexDirection: 'column',
      ...sx,
    }}
    >
      <Box sx={{ mb: 4 }}>
        <DashboardSectionHeader title={t('dashboard.latestActivity')} />
      </Box>
      {children}
    </Box>
  );
};

const PlayerLatestActivity = () => {
  const { t } = useLocale();
  const {
    data: latestActivity = [],
    isFetched = false,
  } = useDashboardLatestActivity();

  if (isFetched && _.isEmpty(latestActivity)) {
    const content = {
      desc: [{
        text: t('profile.noActivities'),
        variant: 'bodyMediumBold',
      }],
    };

    return (
      <PlayerLatestActivityWrapper>
        <Box sx={{ mt: 30 }}>
          <NoContentPlaceholder content={content} />
        </Box>
      </PlayerLatestActivityWrapper>
    );
  }

  const activityList = latestActivity?.map((activity) => (
    <PlayerActivityCard
      key={activity.id}
      activity={activity}
    />
  ));

  return (
    <PlayerLatestActivityWrapper>
      <Stack spacing={5}>
        {activityList}
      </Stack>
    </PlayerLatestActivityWrapper>
  );
};

export default PlayerLatestActivity;
