import { useTheme } from '@mui/styles';

const FlagIcon = (props) => {
  const theme = useTheme();
  const {
    stroke = theme.palette.text.blue,
    fill = 'none',
    ...rest
  } = props;

  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill={fill} xmlns="http://www.w3.org/2000/svg" stroke={stroke} {...rest}>
      <path d="M0.5625 17.4375V0.5625" strokeWidth="1.125" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M0.5625 13.2806L2.928 12.5531C3.90788 12.2514 4.94086 12.1622 5.95794 12.2916C6.97502 12.4209 7.9528 12.7658 8.826 13.3031C9.67812 13.8283 10.6303 14.1703 11.6218 14.3075C12.6133 14.4446 13.6226 14.3738 14.5853 14.0996L16.8255 13.4591C17.0018 13.4087 17.157 13.3022 17.2674 13.1558C17.3778 13.0094 17.4375 12.831 17.4375 12.6476V3.85232C17.4374 3.72176 17.407 3.59301 17.3487 3.47618C17.2904 3.35935 17.2059 3.25762 17.1017 3.17898C16.9974 3.10034 16.8764 3.04694 16.7481 3.02297C16.6197 2.999 16.4876 3.0051 16.362 3.04082L14.5853 3.54857C13.6229 3.82345 12.6138 3.89499 11.6223 3.75864C10.6307 3.62228 9.67845 3.28102 8.826 2.75657C7.9528 2.21925 6.97502 1.87441 5.95794 1.74508C4.94086 1.61574 3.90788 1.70489 2.928 2.00657L0.5625 2.73332" strokeWidth="1.125" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default FlagIcon;
