import PERMISSIONS from 'assets/constants/permissions';
import { STATUS } from 'assets/constants/statuses';
import useAuth from './useAuth';
import usePermission from './usePermission';

/**
 *
 * @param {*} planDetails
 * @returns all plan related permissions for the given plan and the current user
 * {
    canEdit,
    canDraft,
    canPublish,
    canEditPending,
    canSendForApproval,
    showPublishButton,
    showEditButton,
    showSendForApprovalButton,
    showReturnToDraftButton,
  }
 */
const usePlanPermissions = (planDetails) => {
  const {
    status = '',
    createdBy = '',
  } = planDetails;

  // User
  const { currentUser } = useAuth();
  const isOwnPlan = createdBy === currentUser.username;

  // Status
  const isPublished = status === STATUS.published;
  const isPending = status === STATUS.pending;
  const isDraft = status === STATUS.draft;

  // Permissions
  const canEdit = usePermission(PERMISSIONS.planUpdate);
  const canDraft = usePermission(PERMISSIONS.planDraft);
  const canPublish = usePermission(PERMISSIONS.planPublish);
  const canEditPending = usePermission(PERMISSIONS.planUpdatePending);
  const canSendForApproval = usePermission(PERMISSIONS.planSendForApproval);

  // Actions
  const showPublishButton = !isPublished
    && canPublish;
  const showEditButton = !isPublished
    && (canEditPending
      || (canEdit && isOwnPlan && !isPending)); // for account manager
  const showSendForApprovalButton = isDraft
    && canSendForApproval && isOwnPlan; // for account manager
  const showReturnToDraftButton = isPublished
    && canDraft;

  return {
    canEdit,
    canDraft,
    canPublish,
    canEditPending,
    canSendForApproval,
    showPublishButton,
    showEditButton,
    showSendForApprovalButton,
    showReturnToDraftButton,
  };
};

export default usePlanPermissions;
