import {
  Backdrop,
  Box,
  Stack,
} from '@mui/material';

import { LockIcon } from 'assets/icons';
import UpgradeBackdropAction from './UpgradeBackdropAction';
import VerifyBackdropAction from './VerifyBackdropAction';

/**
 *
 * Required container to have a relative position
 */
const LockedBackdrop = (props) => {
  const { withUpgradeButton = true, withVerificationButton = false } = props;

  let backdropAction;

  if (withUpgradeButton) {
    backdropAction = <UpgradeBackdropAction />;
  }

  if (withVerificationButton) {
    backdropAction = <VerifyBackdropAction />;
  }

  return (
    <Backdrop
      open
      sx={{
        position: 'absolute', // To be limited to container
        zIndex: 1,
        bgcolor: 'background.backdrop',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Stack spacing={3} alignItems="center" justifyContent="center">
          <LockIcon width="56" height="56" />
          {backdropAction}
        </Stack>
      </Box>
    </Backdrop>
  );
};

export default LockedBackdrop;
