import {
  Box,
  Button,
  Chip,
  Typography,
} from '@mui/material';

import {
  useAuth, useLocale, useModal, usePermission,
} from 'util/hooks';
import { getStatusByName, STATUS } from 'assets/constants/statuses';

import MODAL_KEYS from 'assets/constants/modalKeys';
import PERMISSIONS from 'assets/constants/permissions';

const AnnouncementHeader = (props) => {
  const {
    announcementDetails = {},
    refetchAnnouncementDetails = () => {},
  } = props;

  const { addModal } = useModal();
  const { currentUser } = useAuth();
  const { username } = currentUser;

  const {
    title = '',
    status = '',
    createdBy = '',
  } = announcementDetails || {};

  const { t } = useLocale();

  const canPublish = usePermission(PERMISSIONS.announcementPublish);
  const isDraft = status === STATUS.draft;
  const showPublishButton = canPublish && isDraft;
  const canExpire = usePermission(PERMISSIONS.announcementExpire);
  const isActive = status === STATUS.active;
  const showExpireButton = canExpire && isActive;
  const canDeleteAll = usePermission(PERMISSIONS.announcementDeleteOwn);
  const isOwnAnnouncement = createdBy === username;
  const canDeleteThisAnnouncement = isDraft && (canDeleteAll || isOwnAnnouncement);

  return (
    <Box sx={{
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      flexWrap: 'wrap',
      mt: 2,
      mb: 10,
    }}
    >
      <Box sx={{
        display: 'flex',
        alignItems: 'center',
      }}
      >
        <Box sx={{ mr: 4 }}>
          <Typography variant="bodyLargeBold">
            {title}
          </Typography>
        </Box>
        <Box>
          <Chip
            size="small"
            color={status}
            label={t(getStatusByName(status)?.localizationKey)}
          />
        </Box>
      </Box>
      <Box sx={{
        display: 'flex',
        alignItems: 'center',
      }}
      >
        {showPublishButton
          && (
            <Box sx={{ mt: { xs: 2, md: 0 } }}>
              <Button
                size="medium"
                variant="contained"
                onClick={() => addModal({
                  key: MODAL_KEYS.dashboardAnnouncement,
                  props: {
                    type: 'publish',
                    refetch: refetchAnnouncementDetails,
                    ...announcementDetails,
                  },
                })}
              >
                {t('common.publish')}
              </Button>
            </Box>
          )}
        {showExpireButton
          && (
            <Box sx={{ mt: { xs: 2, md: 0 } }}>
              <Button
                size="medium"
                variant="contained"
                onClick={() => addModal({
                  key: MODAL_KEYS.dashboardAnnouncement,
                  props: {
                    type: 'expire',
                    refetch: refetchAnnouncementDetails,
                    ...announcementDetails,
                  },
                })}
              >
                {t('common.expire')}
              </Button>
            </Box>
          )}
        {canDeleteThisAnnouncement
          && (
            <Box sx={{ mt: { xs: 2, md: 0 }, ml: 4 }}>
              <Button
                size="medium"
                variant="outlined"
                onClick={() => addModal({
                  key: MODAL_KEYS.dashboardAnnouncement,
                  props: {
                    type: 'delete',
                    refetch: refetchAnnouncementDetails,
                    ...announcementDetails,
                  },
                })}
              >
                {t('common.delete')}
              </Button>
            </Box>
          )}
      </Box>
    </Box>
  );
};

export default AnnouncementHeader;
