import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import {
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';

import { useLocale } from 'util/hooks';
import { PATHS } from 'routes';

const DrawerMenuItem = (props) => {
  const {
    menuItem = {},
    isActiveGroup = false,
    onClickItem = () => { },
  } = props;

  const {
    id,
    type,
    path,
    title,
  } = menuItem;

  const { pathname } = useLocation();
  const { t } = useLocale();
  const navigate = useNavigate();

  const [isExpanded, setIsExpanded] = useState(false);

  useEffect(() => {
    setIsExpanded(isActiveGroup);
  }, [isActiveGroup]);

  const partialMatch = pathname.startsWith(path); // Is current page
  const exactMatch = pathname === path;
  const isGroup = type === 'group';
  // since dashboard (root) has a path of '/', it'll always partial match
  const isRoot = path === PATHS.root;
  const isSelected = isRoot ? exactMatch : partialMatch;

  const renderExpendable = () => (isExpanded ? <ExpandLess /> : <ExpandMore />);

  const onClick = () => {
    if (exactMatch && !isGroup) {
      return;
    }

    if (isGroup) {
      setIsExpanded(!isExpanded);
    }

    onClickItem(menuItem);

    if (!isGroup) {
      navigate(menuItem.path);
    }
  };

  return (
    <span>
      <ListItemButton
        key={id}
        onClick={onClick}
        selected={isSelected}
        sx={{ mb: 2 }}
        disableRipple
      >
        {menuItem.icon && (
          <ListItemIcon>
            <menuItem.icon width="20" height="20" stroke="inherit" />
          </ListItemIcon>
        )}
        <ListItemText
          primary={t(title)}
          primaryTypographyProps={{
            variant: 'bodyStandardMedium',
            sx: {
              fontWeight: (isSelected)
                ? 700
                : 600,
            },
          }}
        />
        {isGroup && renderExpendable()}
      </ListItemButton>
      {isGroup && (
        <Collapse in={isExpanded} timeout="auto" unmountOnExit>
          <List disablePadding>
            {menuItem.children?.map((child) => (
              <ListItemButton
                key={child.id}
                onClick={() => navigate(child.path)}
                selected={pathname.startsWith(child.path)}
                sx={{ mb: 3 }}
                disableRipple
              >
                <ListItemText
                  primary={t(child.title)}
                  primaryTypographyProps={{ variant: 'bodySmallBold' }}
                />
              </ListItemButton>
            ))}
          </List>
        </Collapse>
      )}
    </span>
  );
};

export default DrawerMenuItem;
