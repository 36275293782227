import { useTheme } from '@mui/styles';

const PeopleWithRecycleIcon = (props) => {
  const { palette } = useTheme();
  const {
    stroke = palette.text.secondary,
    fill = 'none',
    ...rest
  } = props;

  return (
    <svg width="28" height="28" viewBox="0 0 28 28" fill={fill} xmlns="http://www.w3.org/2000/svg" {...rest}>
      <g clipPath="url(#clip0_11050_154620)">
        <path d="M17.5 21.875H13.125V26.25" stroke={stroke} strokeWidth="1.75" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M26.445 22.7302C25.9816 24.0844 25.0843 25.2478 23.8922 26.0399C22.7 26.832 21.2798 27.2086 19.8519 27.1111C18.4239 27.0136 17.068 26.4476 15.9946 25.5009C14.9211 24.5542 14.1901 23.2796 13.915 21.875" stroke={stroke} strokeWidth="1.75" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M22.75 18.375H27.125V14" stroke={stroke} strokeWidth="1.75" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M13.8047 17.5201C14.2681 16.1659 15.1654 15.0024 16.3576 14.2103C17.5497 13.4182 18.9699 13.0417 20.3979 13.1391C21.8258 13.2366 23.1817 13.8026 24.2552 14.7493C25.3286 15.6961 26.0596 16.9706 26.3347 18.3752" stroke={stroke} strokeWidth="1.75" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M12.5778 12.2651C11.2798 11.1198 9.60607 10.4916 7.875 10.5C6.16059 10.4865 4.50086 11.1027 3.21059 12.2317C1.92033 13.3607 1.08926 14.9239 0.875 16.625" stroke={stroke} strokeWidth="1.75" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M7.875 8.75C10.0496 8.75 11.8125 6.98712 11.8125 4.8125C11.8125 2.63788 10.0496 0.875 7.875 0.875C5.70038 0.875 3.9375 2.63788 3.9375 4.8125C3.9375 6.98712 5.70038 8.75 7.875 8.75Z" stroke={stroke} strokeWidth="1.75" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M19.25 7C20.9414 7 22.3125 5.62887 22.3125 3.9375C22.3125 2.24613 20.9414 0.875 19.25 0.875C17.5586 0.875 16.1875 2.24613 16.1875 3.9375C16.1875 5.62887 17.5586 7 19.25 7Z" stroke={stroke} strokeWidth="1.75" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M23.7998 10.5001C23.382 9.77736 22.798 9.16463 22.096 8.71277C21.3941 8.26091 20.5945 7.98295 19.7636 7.90196C18.9328 7.82097 18.0946 7.93929 17.3186 8.24709C16.5426 8.55489 15.8512 9.0433 15.3018 9.6718" stroke={stroke} strokeWidth="1.75" strokeLinecap="round" strokeLinejoin="round" />
      </g>
      <defs>
        <clipPath id="clip0_11050_154620">
          <rect width="28" height="28" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default PeopleWithRecycleIcon;
