import { useTheme } from '@emotion/react';
import { Box, Typography } from '@mui/material';
import {
  BloodDropIcon, FlagIcon, RankIcon, SparklingIcon,
} from 'assets/icons';
import { AvatarWithBadge, ColoredCircle, TypographyWithIcon } from 'components/molecules';
import { capitalizeFirstLetter } from 'util/helpers';
import { useLocale } from 'util/hooks';

const typographyWithIconStyle = {
  iconProps: {
    mx: 0,
    mr: 2,
    width: 16,
    height: 16,
  },
  labelProps: { variant: 'bodyStandardMedium' },
};

const useTableColumns = () => {
  const { t, isAr } = useLocale();
  const { palette } = useTheme();

  return [
    {
      field: 'rank',
      headerName: t('common.rank'),
      sortable: true,
      flex: 0.3,
      minWidth: 100,
      renderHeader: () => <Box sx={{ pl: { xs: 2, sm: 8 } }}>{t('common.rank')}</Box>,
      renderCell: ({ row: { rank } }) => {
        const isTopThree = rank >= 1 && rank <= 3;
        const circleValue = isTopThree
          ? (
            <RankIcon
              width="14"
              height="15"
              fill={palette.topThreePlayersBadges[rank]?.color}
            />
          )
          : rank;

        return (
          <Box sx={{ pl: { xs: 2, sm: 8 } }}>
            <ColoredCircle
              value={circleValue}
              sx={{
                color: !isTopThree && 'text.secondary',
                backgroundColor: isTopThree
                  ? `topThreePlayersBadges.${rank}.background`
                  : 'paper.hover',
              }}
            />
          </Box>
        );
      },
    },
    {
      field: 'player',
      headerName: t('roles.player'),
      sortable: false,
      flex: 1.3,
      minWidth: 200,
      renderCell: ({
        row,
        row: {
          avatar,
          username,
          country: { code },
          playerLevel,
        },
      }) => (
        <Box sx={{
          display: 'flex',
          alignItems: 'center',
        }}
        >
          <Box sx={{ mr: 4 }}>
            <AvatarWithBadge
              avatarProps={{
                src: String(avatar),
                alt: capitalizeFirstLetter(username),
                user: row,
              }}
              badgeProps={{
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'right',
                },
                badgeContent: (
                  <img
                    alt={`${code}-flag`}
                    src={`https://flagcdn.com/16x12/${code.toLowerCase()}.png`}
                    srcSet={`https://flagcdn.com/32x24/${code.toLowerCase()}.png 2x`}
                  />
                ),
              }}
            />
          </Box>
          <Box sx={{
            display: 'flex',
            flexDirection: 'column',
          }}
          >
            <Typography
              variant="bodySmallMedium"
            >
              {username}
            </Typography>
            <Typography
              variant="bodyTinyRegular"
              sx={{ color: 'text.secondary' }}
            >
              {isAr ? playerLevel?.nameAr : playerLevel?.nameEn}
            </Typography>
          </Box>
        </Box>
      ),
    },
    {
      field: 'activePoints',
      headerName: t('challenges.points'),
      sortable: true,
      flex: 1,
      minWidth: 150,
      renderCell: ({ row: { activePoints } }) => (
        <TypographyWithIcon
          label={activePoints ?? 0}
          icon={(
            <SparklingIcon
              width="16"
              height="16"
            />
          )}
          {...typographyWithIconStyle}
        />
      ),
    },
    {
      field: 'challenges',
      headerName: t('common.challenges'),
      sortable: true,
      flex: 1,
      minWidth: 150,
      renderCell: ({ row: { numberOfSolvedChallenges } }) => (
        <TypographyWithIcon
          label={numberOfSolvedChallenges || 0}
          icon={(
            <FlagIcon
              width="16"
              height="16"
            />
          )}
          {...typographyWithIconStyle}
        />
      ),
    },
    {
      field: 'challengeFirstBloods',
      headerName: t('common.firstBloods'),
      sortable: true,
      flex: 1,
      minWidth: 150,
      renderCell: ({ row: { challengeFirstBloods } }) => (
        <TypographyWithIcon
          label={challengeFirstBloods || 0}
          icon={(
            <BloodDropIcon
              width="16"
              height="16"
            />
          )}
          {...typographyWithIconStyle}
        />
      ),
    },
  ];
};

export default useTableColumns;
