import PropTypes from 'prop-types';
import { useController } from 'react-hook-form';

import { TextField } from 'components/molecules';

const DEFAULT_MAX_LENGTH = 32;

const FormTextField = (props) => {
  const { showCharCount, ...rest } = props;
  const { inputProps } = rest;

  const { field, fieldState } = useController(rest);
  const { value } = field;
  const { error } = fieldState;

  const hasErrored = Boolean(error);
  const helperText = (() => {
    if (hasErrored) {
      return error?.message;
    }
    if (showCharCount) {
      return `${value?.length}/${inputProps?.maxLength || DEFAULT_MAX_LENGTH}`;
    }
    if (rest.helperText) {
      return rest.helperText;
    }
    return null;
  })();

  return (
    <TextField
      {...field}
      {...rest}
      error={hasErrored}
      helperText={helperText}
    />
  );
};

FormTextField.propTypes = {
  showCharCount: PropTypes.bool,
};

FormTextField.defaultProps = {
  showCharCount: false,
};

export default FormTextField;
