import { useTheme } from '@mui/styles';

const ChallengesIcon = (props) => {
  const theme = useTheme();
  const {
    stroke = theme.palette.text.secondary,
    fill = theme.palette.transparency.main,
    ...rest
  } = props;

  return (
    <svg width="48" height="44" viewBox="0 0 48 44" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <rect width="48" height="44" rx="6" fill={fill} />
      <g clipPath="url(#clip0_3517_11940)">
        <path d="M28.8125 32.3125C31.8501 32.3125 34.3125 29.8501 34.3125 26.8125C34.3125 23.7749 31.8501 21.3125 28.8125 21.3125C25.7749 21.3125 23.3125 23.7749 23.3125 26.8125C23.3125 29.8501 25.7749 32.3125 28.8125 32.3125Z" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M28.8125 24.0625V29.5625" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M26.0625 26.8125H31.5625" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M20.5625 29.5625H15.0625C14.6978 29.5625 14.3481 29.4176 14.0902 29.1598C13.8324 28.9019 13.6875 28.5522 13.6875 28.1875V13.0625C13.6875 12.6978 13.8324 12.3481 14.0902 12.0902C14.3481 11.8324 14.6978 11.6875 15.0625 11.6875H24.8058C25.1702 11.6876 25.5197 11.8323 25.7774 12.0899L28.4101 14.7226C28.6677 14.9803 28.8124 15.3298 28.8125 15.6943V18.5625" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      </g>
      <defs>
        <clipPath id="clip0_3517_11940">
          <rect width="22" height="22" fill="white" transform="translate(13 11)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ChallengesIcon;
