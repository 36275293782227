import { memo } from 'react';
import PropTypes from 'prop-types';
import { useController } from 'react-hook-form';

import { InputLabel } from '@mui/material';
import { DateTimePicker } from 'components/molecules';

const FormDateTimePicker = (props) => {
  const {
    label,
    helperText,
    labelProps,
    pickerProps,
    ...rest
  } = props;

  const { field, fieldState } = useController(rest);
  const { error } = fieldState;

  const hasErrored = Boolean(error);

  return (
    <>
      <InputLabel
        htmlFor={rest.id || rest.name}
        sx={(theme) => ({
          px: 2,
          color: 'text.secondary',
          ...theme.typography.bodyStandardRegular,
        })}
        {...labelProps}
      >
        {label}
      </InputLabel>
      <DateTimePicker
        pickerProps={{
          ...pickerProps,
          ...field,
          onChange: (e) => {
            field.onChange(e);
            pickerProps?.onChange && pickerProps.onChange(e);
          },
        }}
        inputProps={{
          ...rest,
          error: !!error,
          helperText: (hasErrored && error?.message) || helperText,
        }}
      />
    </>
  );
};

FormDateTimePicker.propTypes = {
  label: PropTypes.string,
  labelProps: PropTypes.oneOfType([PropTypes.object]),
  pickerProps: PropTypes.oneOfType([PropTypes.object]),
  helperText: PropTypes.string,
};

FormDateTimePicker.defaultProps = {
  label: '',
  labelProps: {},
  pickerProps: {},
  helperText: '',
};
export default memo(FormDateTimePicker);
