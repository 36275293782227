import {
  Box, Card,
} from '@mui/material';

import { useAuth, usePermission } from 'util/hooks';
import { useAdminTeamDetails, usePlayerTeamDetails } from 'reactQuery/queries';
import PERMISSIONS from 'assets/constants/permissions';
import TeamMemberInfoCard from '../helpers/TeamMemberInfoCard';

const EventTeamMembersList = () => {
  const { currentUser: { username = '' } } = useAuth();
  const canViewTeamAsManager = usePermission(PERMISSIONS.teamViewOneByName);
  const isTeamMember = usePermission(PERMISSIONS.teamViewOwn);

  const {
    data: adminTeamDetails = {},
  } = useAdminTeamDetails({
    options: {
      enabled: canViewTeamAsManager,
    },
  });

  const {
    data: playerTeamDetails = {},
  } = usePlayerTeamDetails({
    options: {
      enabled: isTeamMember,
    },
  });

  const teamDetails = canViewTeamAsManager
    ? adminTeamDetails
    : playerTeamDetails;

  const {
    captain = {},
    members = [],
  } = teamDetails;

  const isCurrentUserCaptain = username === captain.username;

  return (
    <Box>
      {/*
       The sort might break in strict mode, refer to this thread:
       https://stackoverflow.com/questions/53420055/error-while-sorting-array-of-objects-cannot-assign-to-read-only-property-2-of#comment93715046_53420326
       */}
      {members
        .sort((member) => (member.isCaptain ? -1 : 1)) // captains is first of list
        .map((teamMember) => (
          <Card
            key={teamMember.id}
            sx={{
              py: 4,
              px: 8,
              mt: 6,
              '&:hover': {
                backgroundColor: 'background.default',
                cursor: 'pointer',
              },
            }}
          >
            <TeamMemberInfoCard
              teamMember={{ ...teamMember, isCurrentUserCaptain }}
              currentUserUsername={username}
            />
          </Card>
        ))}
    </Box>
  );
};

export default EventTeamMembersList;
