import { useTheme } from '@mui/styles';

const GameControllerIcon = (props) => {
  const { palette } = useTheme();
  const {
    stroke = palette.primaty.contrastText,
    fill = 'none',
    ...rest
  } = props;

  return (
    <svg width="29" height="29" viewBox="0 0 29 29" fill={fill} xmlns="http://www.w3.org/2000/svg" {...rest}>
      <g clipPath="url(#clip0_7344_101581)">
        <path d="M5.75016 1.62891H22.6668C22.9762 1.62891 23.273 1.75182 23.4918 1.97061C23.7106 2.18941 23.8335 2.48615 23.8335 2.79557V24.3789C23.8335 25.3072 23.4647 26.1974 22.8084 26.8538C22.152 27.5102 21.2618 27.8789 20.3335 27.8789H5.75016C5.44074 27.8789 5.144 27.756 4.9252 27.5372C4.70641 27.3184 4.5835 27.0217 4.5835 26.7122V2.79557C4.5835 2.48615 4.70641 2.18941 4.9252 1.97061C5.144 1.75182 5.44074 1.62891 5.75016 1.62891Z" stroke="white" strokeWidth="1.775" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M19.7502 5.12891H8.66683C8.34466 5.12891 8.0835 5.39007 8.0835 5.71224V13.2956C8.0835 13.6177 8.34466 13.8789 8.66683 13.8789H19.7502C20.0723 13.8789 20.3335 13.6177 20.3335 13.2956V5.71224C20.3335 5.39007 20.0723 5.12891 19.7502 5.12891Z" stroke="white" strokeWidth="1.775" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M9.8335 17.3789V20.8789" stroke={stroke} strokeWidth="1.775" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M11.5835 19.1289H8.0835" stroke={stroke} strokeWidth="1.775" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M19.4634 17.8164C19.5794 17.8164 19.6907 17.8625 19.7727 17.9445C19.8548 18.0266 19.9009 18.1379 19.9009 18.2539" stroke={stroke} strokeWidth="1.775" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M19.021 18.2539C19.021 18.1379 19.0671 18.0266 19.1491 17.9445C19.2312 17.8625 19.3425 17.8164 19.4585 17.8164" stroke={stroke} strokeWidth="1.775" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M19.4585 18.6914C19.3425 18.6914 19.2312 18.6453 19.1491 18.5633C19.0671 18.4812 19.021 18.3699 19.021 18.2539" stroke={stroke} strokeWidth="1.775" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M19.9009 18.2539C19.9009 18.3699 19.8548 18.4812 19.7727 18.5633C19.6907 18.6453 19.5794 18.6914 19.4634 18.6914" stroke={stroke} strokeWidth="1.775" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M15.9585 20.4414C16.0745 20.4414 16.1858 20.4875 16.2679 20.5695C16.3499 20.6516 16.396 20.7629 16.396 20.8789" stroke={stroke} strokeWidth="1.775" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M15.5259 20.8789C15.5259 20.7629 15.572 20.6516 15.654 20.5695C15.7361 20.4875 15.8473 20.4414 15.9634 20.4414" stroke={stroke} strokeWidth="1.775" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M15.9634 21.3164C15.8473 21.3164 15.7361 21.2703 15.654 21.1883C15.572 21.1062 15.5259 20.9949 15.5259 20.8789" stroke={stroke} strokeWidth="1.775" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M16.396 20.8789C16.396 20.9949 16.3499 21.1062 16.2679 21.1883C16.1858 21.2703 16.0745 21.3164 15.9585 21.3164" stroke={stroke} strokeWidth="1.775" strokeLinecap="round" strokeLinejoin="round" />
      </g>
      <defs>
        <clipPath id="clip0_7344_101581">
          <rect width="28" height="28" fill="white" transform="translate(0.5 0.753906)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default GameControllerIcon;
