import END_POINTS from 'services/endPoints';
import METHODS from 'services/methods';
import api from '../api';

const getPlanPrice = (planId, data) => api(
  END_POINTS.planPrice(planId),
  {
    method: METHODS.PUT,
    data,
  },
)
  .then((res) => Promise.resolve(res))
  .catch((error) => Promise.reject(error));

export default getPlanPrice;
