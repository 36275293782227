import { useState } from 'react';
import {
  Alert,
  Card,
  Typography,
  Box,
} from '@mui/material';
import * as Yup from 'yup';
import { useLocale, useFastForm, useRecaptcha } from 'util/hooks';
import { FormPasswordField } from 'components/form';
import { useParams } from 'react-router-dom';
import { resetPasswordService } from 'services';
import { REGEX } from 'util/helpers';
import { LoadingButton } from '@mui/lab';

const ResetPasswordForm = (props) => {
  const {
    onSubmitSuccess = () => { },
  } = props;

  const [resetError, setResetError] = useState(false);
  const { executeRecaptcha } = useRecaptcha({ action: 'RESET_PASSWORD' });
  const { token } = useParams();
  const { t } = useLocale();

  const onSubmit = async (values) => {
    const valuesToSubmit = { ...values, token };
    try {
      const recaptchaToken = await executeRecaptcha();
      await resetPasswordService(valuesToSubmit, { recaptchaToken });
      onSubmitSuccess();
    } catch (error) {
      setResetError(error);
    }
  };

  const defaultValues = {
    password: '',
  };

  const validationSchema = Yup.object({
    password: Yup
      .string()
      .matches(REGEX.password, t('password.invalidPassword'))
      .required(t('password.enterPassword')),
  });

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useFastForm({
    defaultValues,
    validationSchema,
  });

  const form = (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        py: 10,
        px: {
          sm: 25,
          xs: 5,
        },
      }}
      >
        {resetError && (
          <Alert
            sx={{ width: 1, mb: 5 }}
            severity="error"
          >
            {t('login.wrongCredentials')}
          </Alert>
        )}
        <Box>
          <Typography variant="h5">{t('password.resetPassword')}</Typography>
          <Typography variant="bodyStandardRegular" sx={{ mt: 1 }}>{t('password.resetPasswordDescription')}</Typography>
        </Box>
        <Box sx={{ mt: 6, mb: 9 }}>
          <FormPasswordField
            name="password"
            control={control}
            autoComplete="new-password"
            label={t('common.password')}
            fullWidth
          />
        </Box>
        <LoadingButton
          variant="contained"
          type="submit"
          fullWidth
          disabled={isSubmitting}
          loading={isSubmitting}
        >
          {t('common.reset')}
        </LoadingButton>
      </Box>
    </form>
  );

  return (
    <Card>
      {form}
    </Card>
  );
};

export default ResetPasswordForm;
