import React from 'react';
import { useController } from 'react-hook-form';
import { Typography } from '@mui/material';

import { Checkbox } from 'components/molecules';

const FormCheckboxArray = ({ options, ...rest }) => {
  const { field } = useController(rest);
  const { name, value, onChange } = field;

  const handleChange = React.useCallback((e, option) => {
    const valueCopy = [...value];
    if (e.target.checked) {
      valueCopy.push(option); // append to array
    } else {
      const idx = valueCopy
        .findIndex((formOption) => formOption.id === option.id);
      valueCopy.splice(idx, 1); // remove from array
    }
    onChange(valueCopy); // update form field with new array
  }, [onChange, value]);

  return options?.map((option) => {
    const checked = value
      .some((formOption) => formOption.id === option.id);

    return (
      <div key={`${name}-checkbox-option-${option.id}`}>
        <Checkbox
          key={option.id}
          checkboxProps={{
            checked,
            onChange: (e) => handleChange(e, option),
          }}
          labelProps={{
            label: (
              <div>
                <Typography variant="bodyStandardRegular">
                  {option.label}
                </Typography>
              </div>
            ),
          }}
        />
      </div>
    );
  });
};

export default FormCheckboxArray;
