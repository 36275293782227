import { useParams } from 'react-router-dom';
import { getPlayerEventHistory } from 'services';
import { useCustomQuery } from 'reactQuery';

const usePlayerEventHistory = () => {
  const { username } = useParams();

  const query = useCustomQuery({
    queryKey: [`player-event-history-${username}`],
    queryFn: () => getPlayerEventHistory(username),
    options: { enabled: !!username },
  });

  return { ...query };
};

export default usePlayerEventHistory;
