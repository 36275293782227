import { useTheme } from '@mui/styles';

const SearchTeamIcon = (props) => {
  const { palette } = useTheme();
  const {
    stroke = palette.primaty.contrastText,
    fill = 'none',
    ...rest
  } = props;

  return (
    <svg width="28" height="29" viewBox="0 0 28 29" fill={fill} xmlns="http://www.w3.org/2000/svg" {...rest}>
      <g clipPath="url(#clip0_7344_101661)">
        <path d="M0.710938 27.8789C0.710938 25.7903 1.54062 23.7873 3.01747 22.3104C4.49432 20.8336 6.49736 20.0039 8.58594 20.0039C10.6745 20.0039 12.6776 20.8336 14.1544 22.3104C15.6313 23.7873 16.4609 25.7903 16.4609 27.8789" stroke={stroke} strokeWidth="1.5625" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M4.44189 10.998C5.18726 11.7708 6.08074 12.3855 7.06897 12.8052C8.05719 13.225 9.11989 13.4412 10.1936 13.441" stroke={stroke} strokeWidth="1.5625" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M12.3549 16.4339C11.8483 17.072 11.188 17.571 10.4358 17.8842C9.6837 18.1973 8.86437 18.3144 8.05462 18.2245C7.24486 18.1345 6.4712 17.8405 5.80613 17.3699C5.14106 16.8993 4.60636 16.2675 4.25212 15.5338C3.89789 14.8001 3.73572 13.9885 3.78082 13.175C3.82591 12.3615 4.0768 11.5728 4.50996 10.8828C4.94313 10.1927 5.54439 9.62396 6.2574 9.22975C6.97041 8.83554 7.77184 8.6288 8.58657 8.62891C8.8208 8.62902 9.05472 8.64579 9.28657 8.67907" stroke={stroke} strokeWidth="1.5625" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M24.0014 18.4176C23.5562 19.0164 22.95 19.4764 22.2535 19.744C21.557 20.0116 20.7988 20.0758 20.0672 19.929C19.3356 19.7823 18.6608 19.4308 18.1213 18.9153C17.5818 18.3999 17.1999 17.7417 17.02 17.0176" stroke={stroke} strokeWidth="1.5625" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M19.9611 27.8789H26.9611C26.9611 26.2545 26.3158 24.6965 25.1672 23.5479C24.0185 22.3992 22.4606 21.7539 20.8361 21.7539C19.9833 21.7528 19.1401 21.9338 18.3628 22.2847" stroke={stroke} strokeWidth="1.5625" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M18.229 13.8955C21.6117 13.8955 24.354 11.1533 24.354 7.77051C24.354 4.38776 21.6117 1.64551 18.229 1.64551C14.8463 1.64551 12.104 4.38776 12.104 7.77051C12.104 11.1533 14.8463 13.8955 18.229 13.8955Z" stroke={stroke} strokeWidth="1.5625" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M26.9773 16.5202L22.5859 12.1289" stroke={stroke} strokeWidth="1.5625" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M8.58594 20.0039V23.5039" stroke={stroke} strokeWidth="1.5625" strokeLinecap="round" strokeLinejoin="round" />
      </g>
      <defs>
        <clipPath id="clip0_7344_101661">
          <rect width="28" height="28" fill="white" transform="translate(0 0.753906)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SearchTeamIcon;
