import { useTheme } from '@mui/styles';

const LeaderBoardIcon = (props) => {
  const theme = useTheme();
  const {
    stroke = '',
    fill = theme.palette.primary.main,
    ...rest
  } = props;

  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" stroke={stroke} {...rest}>
      <g clipPath="url(#clip0_5051_58592)">
        <path d="M5.5 7H1.5C1.23478 7 0.98043 7.10536 0.792893 7.29289C0.605357 7.48043 0.5 7.73478 0.5 8V12H5.5V7Z" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M14.5 8H10.5V12H15.5V9C15.5 8.73478 15.3946 8.48043 15.2071 8.29289C15.0196 8.10536 14.7652 8 14.5 8Z" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M9.5 4H6.5C6.23478 4 5.98043 4.10536 5.79289 4.29289C5.60536 4.48043 5.5 4.73478 5.5 5V12H10.5V5C10.5 4.73478 10.3946 4.48043 10.2071 4.29289C10.0196 4.10536 9.76522 4 9.5 4Z" strokeLinecap="round" strokeLinejoin="round" />
      </g>
      <defs>
        <clipPath id="clip0_5051_58592">
          <rect width="16" height="16" fill={fill} />
        </clipPath>
      </defs>
    </svg>

  );
};

export default LeaderBoardIcon;
