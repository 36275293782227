import PropTypes from 'prop-types';
import { useLocale } from 'util/hooks';
import {
  Box,
  Chip,
  Typography,
} from '@mui/material';

import { getStatusByName } from 'assets/constants/statuses';

const PlanHeader = (props) => {
  const { planDetails = {} } = props;
  const {
    nameAr,
    nameEn,
    status,
    createdBy,
  } = planDetails;

  const { t, isAr } = useLocale();

  return (
    <Box sx={{
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      flexWrap: 'wrap',
      mt: 2,
      mb: 10,
    }}
    >
      <Box sx={{
        display: 'flex',
        alignItems: 'center',
      }}
      >
        <Box sx={{ mr: 4 }}>
          <Typography variant="bodyLargeBold">
            {isAr ? nameAr : nameEn}
          </Typography>
        </Box>
        <Box>
          <Chip
            size="small"
            color={status}
            label={t(getStatusByName(status)?.localizationKey)}
          />
        </Box>
      </Box>
      <Box sx={{ mt: { xs: 2, md: 0 } }}>
        <Typography variant="bodyStandardRegular" color="text.mediumGray">
          {`${t('common.createdBy')} `}
        </Typography>
        <Typography variant="bodyStandardBold">
          {createdBy}
        </Typography>
      </Box>
    </Box>
  );
};

PlanHeader.propTypes = {
  planDetails: PropTypes.oneOfType([PropTypes.object]),
};

PlanHeader.defaultProps = {
  planDetails: {},
};

export default PlanHeader;
