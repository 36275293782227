import END_POINTS from '../endPoints';
import METHODS from '../methods';
import api from '../api';

const editCompany = (name, data) => api(
  END_POINTS.editCompany(name),
  {
    method: METHODS.PATCH,
    data,
  },
)
  .then((res) => Promise.resolve(res))
  .catch((error) => Promise.reject(error));

export default editCompany;
