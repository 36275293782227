import { Snackbar as MUISnackbar, Alert } from '@mui/material';
import PropTypes from 'prop-types';

const Snackbar = (props) => {
  const {
    open,
    message,
    severity,
    onClose,
    autoHideDuration,
    alertProps,
    snackbarProps,
  } = props;

  const combinedAlertProps = {
    severity,
    ...alertProps,
  };
  const combinedSnackbarProps = {
    open,
    onClose,
    autoHideDuration,
    ...snackbarProps,
  };

  return (
    <MUISnackbar {...combinedSnackbarProps}>
      <Alert sx={{ width: 1 }} {...combinedAlertProps}>
        {message}
      </Alert>
    </MUISnackbar>
  );
};

Snackbar.propTypes = {
  open: PropTypes.bool,
  message: PropTypes.string,
  severity: PropTypes.string,
  onClose: PropTypes.func,
  autoHideDuration: PropTypes.number,
  alertProps: PropTypes.oneOfType([PropTypes.object]),
  snackbarProps: PropTypes.oneOfType([PropTypes.object]),
};

Snackbar.defaultProps = {
  open: false,
  message: '',
  severity: 'success',
  onClose: () => { },
  autoHideDuration: 2500,
  alertProps: {},
  snackbarProps: {},
};

export default Snackbar;
