import { useState } from 'react';
import { useLocale, useAuth, usePermission } from 'util/hooks';

import { Box, Card } from '@mui/material';

import { PromoCodeFilledIcon } from 'assets/icons';
import PERMISSIONS from 'assets/constants/permissions';

import PromoCodeDetailsForm from '../../PromoCodeDetailsForm/PromoCodeDetailsForm';
import PromoCodeDetailsWrapper from '../helpers/PromoCodeDetailsWrapper';
import PromoCodeSectionWrapper from '../helpers/PromoCodeSectionWrapper';

const PromoCodeInfo = (props) => {
  const { codeDetails = {} } = props;
  const { t } = useLocale();

  const [viewMode, setViewMode] = useState(true);

  const { currentUser: { username } } = useAuth();

  const canEdit = usePermission(PERMISSIONS.promoCodeUpdateOwn);
  const canEditAll = usePermission(PERMISSIONS.promoCodeUpdate);
  const isOwnCode = codeDetails?.createdBy === username;
  const canEditCode = viewMode
    && (canEditAll || (isOwnCode && canEdit));

  return (
    <Box>
      <Card sx={{ p: 14 }}>
        <PromoCodeSectionWrapper
          Icon={PromoCodeFilledIcon}
          title={t('promoCodes.promoCodeDetails')}
          canEditThisCode={canEditCode}
          onClickEdit={() => {
            setViewMode(false);
          }}
        >
          {viewMode
            && (
              <Box sx={{ mt: 4 }}>
                <PromoCodeDetailsWrapper codeDetails={codeDetails} />
              </Box>
            )}
          {!viewMode
            && (
              <Box sx={{ mt: 4 }}>
                <PromoCodeDetailsForm
                  onClickCancel={() => setViewMode(true)}
                />
              </Box>
            )}
        </PromoCodeSectionWrapper>
      </Card>
    </Box>
  );
};

export default PromoCodeInfo;
