import { useEffect } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { isEmpty } from 'lodash';
import { useNavigate, useParams } from 'react-router-dom';

import {
  Box,
  Button,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import {
  FormTags,
  FormCheckbox,
  FormTextField,
  FormRadioGroup,
  FormFileUpload,
  FormNumberField,
  FormAutocompleteSelect,
} from 'components/form';

import {
  useLocale,
  useSnackbar,
  useIsEvent,
  useFastForm,
} from 'util/hooks';

import { PATHS } from 'routes';
import { REGEX } from 'util/helpers';

import {
  editLabChallenge,
  createLabChallenge,
  editEventChallenge,
  createEventChallenge,
} from 'services';

import FILE_TYPES from 'assets/constants/fileTypes';
import LAB_TYPES from 'assets/constants/labTypes';

import { InformationCircleIcon } from 'assets/icons';
import {
  useLabDetails,
  useLabChallengeDetails,
  useChallengeCategories,
  useEventChallengeDetails,
  useEventDetails,
} from 'reactQuery/queries';

import CHALLENGE_PROTOCOLS from 'assets/constants/challengeProtocols';

const MAX_TAGS = 5;

const ChallengeDetailsForm = (props) => {
  const {
    createMode = false,
    onClickCancel = () => { },
  } = props;

  const { t } = useLocale();
  const snack = useSnackbar();
  const navigate = useNavigate();
  const { eventId, challengeId } = useParams();

  const {
    data: challengeCategories = [],
  } = useChallengeCategories();

  const {
    data: labDetails = {},
  } = useLabDetails();

  const {
    data: labChallengeDetails = {},
    refetch: refetchLabChallenge = () => { },
  } = useLabChallengeDetails();

  const {
    data: eventChallengeDetails = {},
    refetch: refetchEventChallenge = () => { },
  } = useEventChallengeDetails();

  const {
    data: eventDetails = {},
  } = useEventDetails();

  const isEventChallenge = useIsEvent();
  const challengeDetails = isEventChallenge ? eventChallengeDetails : labChallengeDetails;

  const onCancel = () => {
    if (createMode) {
      if (isEventChallenge) {
        navigate(`/${PATHS.events}/${eventId}`);
      } else {
        navigate(`/${PATHS.labs}/${labDetails.id}`);
      }
    } else {
      onClickCancel();
    }
  };

  const isTrainingLab = labDetails?.type === LAB_TYPES.training;

  const challengeFiles = challengeDetails?.files
    ?.filter((f) => f.fileType === FILE_TYPES.challengeFiles);
  const writeUpFiles = challengeDetails?.files
    ?.filter((f) => f.fileType === FILE_TYPES.writeUpFiles);
  const isDynamicPoints = eventDetails?.isDynamicPoints || false;

  const defaultValues = {
    name: challengeDetails?.name || '',
    description: challengeDetails?.description || '',
    points: challengeDetails?.points || 0,
    minPoints: challengeDetails?.minPoints || 100,
    maxPoints: challengeDetails?.maxPoints || 500,
    decay: challengeDetails?.decay || 20,
    correctFlag: challengeDetails?.correctFlag || '',
    author: challengeDetails?.author || '',
    url: challengeDetails?.url || '',
    imageName: challengeDetails?.imageName || '',
    protocol: challengeDetails?.protocol || CHALLENGE_PROTOCOLS.WEB,
    internalPort: challengeDetails?.internalPort || '',
    tags: challengeDetails?.tags || [],
    category: (isTrainingLab
      ? labDetails?.challengesCategory
      : challengeDetails?.category) || '',
    challengeFiles: challengeFiles || [],
    writeUpFiles: writeUpFiles || [],
    isDynamicFlag: challengeDetails?.isDynamicFlag || false,
    isDynamicPoints,

  };
  const validationSchema = Yup.object({
    name: Yup
      .string()
      .trim()
      .matches(REGEX.alphaNumericSpaceSpecialCharacter, t('challenges.validation.enterEnglishName'))
      .required(t('challenges.validation.enterEnglishName'))
      .min(1, t('challenges.validation.nameMinLength'))
      .max(32, t('challenges.validation.nameMaxLength')),
    description: Yup
      .string()
      .nullable()
      .max(1000, t('challenges.validation.descMaxLength')),
    points: Yup
      .number()
      .when('isDynamicPoints', {
        is: false,
        then: () => Yup
          .number()
          .required(t('challenges.validation.enterPoints'))
          .typeError(t('challenges.validation.enterNumbers'))
          .min(1, t('challenges.validation.pointsMinNumber'))
          .max(500, t('challenges.validation.pointsMaxNumber')),
      }),
    minPoints: Yup
      .number()
      .when('isDynamicPoints', {
        is: true,
        then: () => Yup
          .number()
          .required(t('challenges.validation.enterPoints'))
          .typeError(t('challenges.validation.enterNumbers'))
          .min(1, t('challenges.validation.pointsMinNumber'))
          .max(500, t('challenges.validation.pointsMaxNumber'))
          .when('maxPoints', (schemaMaxPoints, schema) => schema.max(schemaMaxPoints, t('challenges.validation.minLess'))),
      }),
    maxPoints: Yup
      .number()
      .when('isDynamicPoints', {
        is: true,
        then: () => Yup
          .number()
          .required(t('challenges.validation.enterPoints'))
          .typeError(t('challenges.validation.enterNumbers'))
          .min(1, t('challenges.validation.pointsMinNumber'))
          .max(500, t('challenges.validation.pointsMaxNumber')),
      }),
    decay: Yup
      .number()
      .when('isDynamicPoints', {
        is: true,
        then: () => Yup
          .number()
          .required(t('challenges.validation.enterDecay'))
          .typeError(t('challenges.validation.enterNumbers'))
          .min(1, t('challenges.validation.decayMinNumber')),
      }),
    correctFlag: Yup
      .string()
      .trim()
      .when('isDynamicFlag', {
        is: false,
        then: () => Yup
          .string()
          .trim()
          .min(1, t('challenges.validation.flagMinLength'))
          .max(250, t('challenges.validation.flagMaxLength'))
          .required(t('challenges.enterFlag')),
      }),
    author: Yup
      .string()
      .trim()
      .matches(REGEX.alphaNumericSpaceSpecialCharacter, t('challenges.validation.enterEnglishAuthor'))
      .required(t('challenges.validation.enterEnglishAuthor'))
      .min(1, t('challenges.validation.authorMinLength'))
      .max(32, t('challenges.validation.authorMaxLength')),
    url: Yup
      .string()
      .test(
        'validUrl',
        t('challenges.validation.url'),
        (v) => v === '' || (REGEX.url.test(v) || REGEX.ip.test(v) || REGEX.ipWithPort.test(v) || REGEX.urlOrIpWithPort.test(v)),
      )
      .nullable(),
    imageName: Yup
      .string()
      .trim()
      .nullable()
      .matches(REGEX.alphaNumericSpecialCharacter, t('challenges.validation.enterEngImageName')),
    tags: Yup
      .array()
      .required(t('challenges.validation.enterTag'))
      .max(MAX_TAGS, t('challenges.validation.tagsMax')),
    protocol: Yup
      .string()
      .nullable(),
    internalPort: Yup
      .string()
      .when('protocol', {
        is: (value) => value === CHALLENGE_PROTOCOLS.TCP,
        then: () => Yup.number().required(t('challenges.validation.enterInternalPort')),
      }),
    category: Yup
      .object()
      .nullable()
      .required(t('challenges.validation.enterCategory')),
    challengeFiles: Yup
      .array()
      .of(Yup
        .object()
        .shape({
          path: Yup.string(),
          fileName: Yup.string(),
        })),
    writeUpFiles: Yup
      .array()
      .of(Yup
        .object()
        .shape({
          path: Yup.string(),
          fileName: Yup.string(),
        })),
    isDynamicFlag: Yup.bool(),
    isDynamicPoints: Yup.bool(),
  });

  const {
    control,
    handleSubmit,
    setError,
    setValue,
    watch,
    formState: {
      errors,
      isSubmitting,
      isDirty,
      isValid,
      dirtyFields,
    },
  } = useFastForm({
    defaultValues,
    validationSchema,
  });

  const onSubmit = async (values) => {
    const dirtyPayload = isDynamicPoints ? {
      protocol: values.protocol, // protocol won't be dirty incase of web challenge
      category: values.category, // category won't be dirty incase of web challenge in training lab
      isDynamicFlag: values.isDynamicFlag,
      isDynamicPoints: values.isDynamicPoints,
      minPoints: values.minPoints,
      maxPoints: values.maxPoints,
      decay: values.decay,
    } : {
      protocol: values.protocol, // protocol won't be dirty incase of web challenge
      category: values.category, // category won't be dirty incase of web challenge in training lab
      isDynamicFlag: values.isDynamicFlag,
      isDynamicPoints: values.isDynamicPoints,
    };
    Object.keys(dirtyFields)?.forEach((dirtyKey) => {
      dirtyPayload[dirtyKey] = values[dirtyKey];
    });

    try {
      let message = t('challenges.updateSuccess');

      if (createMode) {
        if (isEventChallenge) {
          const { id } = await createEventChallenge(dirtyPayload, eventId);
          navigate(`/${PATHS.events}/${eventId}/${PATHS.challenges}/${id}`);
        } else {
          const { id } = await createLabChallenge(
            dirtyPayload,
            labDetails?.id,
          );
          navigate(`/${PATHS.labs}/${labDetails?.id}/${PATHS.challenges}/${id}`);
        }
        message = t('challenges.createSuccess');
      } else {
        if (isEventChallenge) {
          await editEventChallenge(
            dirtyPayload,
            eventId,
            challengeId,
          );

          // Update event challenge
          refetchEventChallenge();
        } else {
          await editLabChallenge(
            dirtyPayload,
            labDetails?.id,
            challengeDetails.id,
          );

          // Update lab challenge
          refetchLabChallenge();
        }

        // Turn off edit mode
        onClickCancel();
      }

      snack({
        message,
        severity: 'success',
      });
    } catch (error) {
      error.errors?.forEach((err) => {
        setError(err.property, {
          type: 'api',
          message: err.message,
        });
        if (!err.property || !watch(err.property)) {
          snack({
            severity: 'error',
            message: err.message || t('common.somethingWrong'),
          });
        }
      });

      if (isEmpty(errors)) { // Non-form errors
        snack({
          severity: 'error',
          message: error.message || t('common.somethingWrong'),
        });
      }
    }
  };

  const isImageNameEmpty = watch('imageName') === '';
  const disableInternalPort = watch('protocol') === CHALLENGE_PROTOCOLS.WEB || isImageNameEmpty;
  const isChallengeFlagDynamic = watch('isDynamicFlag') === true;

  useEffect(() => {
    const handleEmptyImage = () => {
      setValue('internalPort', '');
      setValue('protocol', CHALLENGE_PROTOCOLS.WEB);
    };

    isChallengeFlagDynamic && setValue('correctFlag', '', {
      shouldValidate: true,
      shouldDirty: true,
      shouldTouch: true,
    });

    isImageNameEmpty && handleEmptyImage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isImageNameEmpty, isChallengeFlagDynamic]);

  const dropzoneProps = {
    multiple: false,
    accept: { 'application/zip': ['.zip'] },
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box sx={{
        display: 'grid',
        columnGap: 31,
        gridTemplateColumns: {
          xs: 'repeat(1, 1fr)',
          lg: 'repeat(2, 304px)',
          xl: 'repeat(2, 420px)',
        },
      }}
      >
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          rowGap: 8,
        }}
        >
          <Box>
            <FormTextField
              name="name"
              control={control}
              label={t('challenges.name')}
              inputProps={{ maxLength: 32 }}
              disabled={isSubmitting}
              fullWidth
              showCharCount
            />
          </Box>
          <Box>
            {!isDynamicPoints && (
            <FormNumberField
              name="points"
              control={control}
              label={(
                <Box sx={{
                  display: 'flex',
                  alignItems: 'center',
                }}
                >
                  <Typography>
                    {t('challenges.points')}
                  </Typography>
                  <Tooltip
                    title={t('challenges.pointsDifficultyHint')
                      .split('\n') // support multiline
                      .map((d) => <p key={d}>{d}</p>)}
                  >
                    <Box sx={{
                      display: 'flex',
                      width: 14,
                      height: 14,
                      mx: 1.5,
                    }}
                    >
                      <InformationCircleIcon
                        width="14"
                        height="14"
                      />
                    </Box>
                  </Tooltip>
                </Box>
              )}
              inputProps={{ maxLength: 3 }}
              disabled={isSubmitting}
              fullWidth
            />
            )}

            {isDynamicPoints && (
              <Stack
                display="flex"
                gap={2.5}
              >
                <Typography
                  sx={(theme) => ({
                    px: 2,
                    color: 'text.secondary',
                    ...theme.typography.bodyStandardRegular,
                  })}
                >

                  {t('challenges.dynamicPoints')}
                </Typography>
                <Stack
                  display="flex"
                  direction="row"
                  gap={2}
                >
                  <Box width={100} flex={0.33}>
                    <FormTextField
                      name="maxPoints"
                      control={control}
                      label={t('challenges.maxPoints')}
                      disabled={isSubmitting}
                    />
                  </Box>
                  <Box width={100} flex={0.33}>
                    <FormTextField
                      name="minPoints"
                      control={control}
                      label={t('challenges.minPoints')}
                      disabled={isSubmitting}
                    />
                  </Box>
                  <Box width={100} flex={0.33}>
                    <FormTextField
                      name="decay"
                      control={control}
                      label={t('challenges.decay')}
                      disabled={isSubmitting}
                    />
                  </Box>
                </Stack>
              </Stack>
            )}
          </Box>
          <Box>
            <FormTextField
              name="author"
              control={control}
              label={t('challenges.author')}
              disabled={isSubmitting}
              fullWidth
              showCharCount
            />
          </Box>
          <Box>
            <FormTextField
              name="imageName"
              control={control}
              label={t('challenges.imageName')}
              helperText={t('challenges.imageNameHelper')}
              disabled={isSubmitting}
              fullWidth
            />
          </Box>
          <Box>
            <Tooltip
              title={t('challenges.protocolHint')}
              placement="top-start"
              componentsProps={{
                tooltip: {
                  sx: {
                    display: disableInternalPort ? 'block' : 'none', // only show when field disabled
                  },
                },
              }}
            >
              <Box>
                <FormTextField
                  inputProps={{ inputMode: 'numeric' }}
                  name="internalPort"
                  control={control}
                  label={t('challenges.internalPort')}
                  disabled={isSubmitting || disableInternalPort}
                  fullWidth
                />
              </Box>
            </Tooltip>
          </Box>
          <Box>
            <FormTextField
              name="correctFlag"
              control={control}
              label={t('challenges.correctFlag')}
              inputProps={{ maxLength: 250 }}
              disabled={isSubmitting || isChallengeFlagDynamic}
              fullWidth
              showCharCount
            />
            <Box>
              <FormCheckbox
                name="isDynamicFlag"
                control={control}
                disabled={isSubmitting}
                label={(
                  <Typography variant="bodyStandardRegular">
                    {t('challenges.useDynamicFlag')}
                  </Typography>
                )}
              />
            </Box>
          </Box>
          <Box>
            <FormFileUpload
              dropzoneProps={dropzoneProps}
              name="challengeFiles"
              control={control}
              label={t('challenges.challengeFiles')}
              disabled={isSubmitting}
              fullWidth
            />
          </Box>
          {!isEventChallenge
            && (
              <Box>
                <FormFileUpload
                  dropzoneProps={dropzoneProps}
                  name="writeUpFiles"
                  control={control}
                  label={t('challenges.writeupFiles')}
                  disabled={isSubmitting}
                  fullWidth
                />
              </Box>
            )}
        </Box>
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          rowGap: 8,
        }}
        >
          <Box sx={{
            mt: {
              xs: 7,
              lg: 0,
            },
          }}
          >
            <FormTextField
              name="description"
              control={control}
              label={t('common.description')}
              disabled={isSubmitting}
              fullWidth
              multiline
              minRows={7}
              maxRows={7}
              inputProps={{ maxLength: 1000 }}
              showCharCount
            />
          </Box>
          <Box>
            <FormAutocompleteSelect
              name="category"
              label={t('challenges.category')}
              control={control}
              autoCompleteProps={{
                options: challengeCategories,
              }}
              disabled={isSubmitting || isTrainingLab}
              fullWidth
            />
          </Box>
          <Box>
            <FormTextField
              name="url"
              label={t('challenges.url')}
              helperText="https://google.com or 192.168.0.1"
              control={control}
              disabled={isSubmitting}
              fullWidth
            />
          </Box>
          <Box sx={{
            my: {
              md: 0,
              lg: 4,
            },
          }}
          >
            <Tooltip
              title={t('challenges.protocolHint')}
              placement="top-start"
              componentsProps={{
                tooltip: {
                  sx: {
                    display: isImageNameEmpty ? 'block' : 'none', // only show when field disabled
                  },
                },
              }}
            >
              <Box>
                <FormRadioGroup
                  name="protocol"
                  control={control}
                  label={t('challenges.protocol')}
                  options={[
                    { label: 'Web', value: CHALLENGE_PROTOCOLS.WEB },
                    { label: 'Tcp', value: CHALLENGE_PROTOCOLS.TCP },
                  ]}
                  disabled={isSubmitting || isImageNameEmpty}
                />
              </Box>
            </Tooltip>
          </Box>
          <Box>
            <FormTags
              name="tags"
              control={control}
              label={t('challenges.tags')}
              max={MAX_TAGS}
              setError={(message) => setError('tags', {
                type: 'manual',
                message,
              })}
            />
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          mt: 8,
        }}
      >
        <Box sx={{ mx: 2 }}>
          <LoadingButton
            type="submit"
            variant="contained"
            size="medium"
            loading={isSubmitting}
            disabled={!isDirty || isSubmitting || !isValid}
          >
            {t('common.save')}
          </LoadingButton>
        </Box>
        <Box>
          <Button
            onClick={onCancel}
            disabled={isSubmitting}
            sx={{
              color: 'text.raspberry',
            }}
          >
            {t('common.cancel')}
          </Button>
        </Box>
      </Box>
    </form>
  );
};

ChallengeDetailsForm.propTypes = {
  createMode: PropTypes.bool,
  onClickCancel: PropTypes.func,
};

ChallengeDetailsForm.defaultProps = {
  createMode: false,
  onClickCancel: () => { },
};

export default ChallengeDetailsForm;
