const transformSubmission = (submission = {}) => {
  const {
    id,
    player,
    machine,
    challenge,
    firstBlood,
    submittedAt,
    pointsEarned,
    entityStatus,
    userFirstBlood,
    entityVisibility,
    rootFirstBlood,
    isRootSubmission,
  } = submission;

  const activityType = challenge ? 'challenge' : 'machine';
  const isChallenge = activityType === 'challenge';
  const machineSubmissionType = isRootSubmission ? 'root' : 'user';

  const activityDetails = {
    ...submission[activityType],
    points: isChallenge
      ? challenge.points
      : machine[`${machineSubmissionType}Points`],
  };

  const transformed = {
    id,
    player,
    isChallenge,
    submittedAt,
    pointsEarned,
    entityStatus,
    activityDetails,
    isRootSubmission,
    entityVisibility,
    machineSubmissionType,
    firstBlood: isChallenge
      ? firstBlood
      : (userFirstBlood || rootFirstBlood),
  };

  return transformed;
};

export default transformSubmission;
