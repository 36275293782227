import Box from '@mui/material/Box';
import { SuspenseWrapper } from 'components/render';

import PlanComparisonTable from '../PlanComparisonTable/PlanComparisonTable';
import CompareHeader from './Sections/CompareHeader';
import PlanHeader from './Sections/PlanHeader';
import SubscriptionPlans from './Sections/SubscriptionPlans';

const SubscriptionsContainer = (props) => {
  const { plans = [] } = props;

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    }}
    >
      <Box>
        <PlanHeader />
      </Box>
      <SuspenseWrapper>
        {plans?.length ? (
          <>
            <Box sx={{
              mt: 6,
              mb: 25,
            }}
            >
              <SubscriptionPlans plans={plans} />
            </Box>
            <Box mb={7}>
              <CompareHeader />
            </Box>
            <Box width={1}>
              <PlanComparisonTable plans={plans} />
            </Box>
          </>
        ) : null}
      </SuspenseWrapper>
    </Box>
  );
};

export default SubscriptionsContainer;
