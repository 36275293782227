import {
  Link,
  Typography,
  Breadcrumbs,
} from '@mui/material';
import PropTypes from 'prop-types';

const CustomBreadcrumbs = (props) => {
  const {
    crumbs,
    ...rest
  } = props;

  const breadCrumbs = crumbs?.map((crumb, index) => {
    const { link, text } = crumb;
    const isLastCrumb = (index === crumbs.length - 1);

    if (isLastCrumb) {
      return (
        <Typography
          key={link}
          variant="bodyStandardRegular"
          sx={{ color: 'primary.contrastText' }}
        >
          {text}
        </Typography>
      );
    }
    return (
      <Link
        key={link}
        to={link}
      >
        <Typography
          variant="bodyStandardRegular"
          sx={{ color: 'text.secondary' }}
        >
          {text}
        </Typography>
      </Link>
    );
  });

  return (
    <Breadcrumbs aria-label="breadcrumb" {...rest}>
      {breadCrumbs}
    </Breadcrumbs>

  );
};

CustomBreadcrumbs.propTypes = {
  crumbs: PropTypes.oneOfType([PropTypes.array]),
};

CustomBreadcrumbs.defaultProps = {
  crumbs: [],
};
export default CustomBreadcrumbs;
