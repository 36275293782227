import { useTheme } from '@mui/styles';

const StudyBookIcon = (props) => {
  const { palette } = useTheme();
  const {
    stroke = palette.primaty.contrastText,
    fill = 'none',
    ...rest
  } = props;

  return (
    <svg width="28" height="29" viewBox="0 0 28 29" fill={fill} xmlns="http://www.w3.org/2000/svg" {...rest}>
      <g clipPath="url(#clip0_7344_101634)">
        <path d="M9.53518 22.009C7.26871 21.2588 4.90521 20.8423 2.51885 20.7723C2.07348 20.7478 1.65454 20.5531 1.34862 20.2285C1.04269 19.9039 0.873144 19.4742 0.875015 19.0282V3.43683C0.874437 3.19644 0.923391 2.95851 1.01883 2.73787C1.11426 2.51724 1.25413 2.31863 1.4297 2.15444C1.60528 1.99024 1.81281 1.86398 2.03933 1.78353C2.26586 1.70308 2.50654 1.67016 2.74635 1.68683C10.1243 2.073 14 4.70616 14 6.71983C14 4.72016 18.3342 2.10333 25.2479 1.69383C25.4881 1.67632 25.7294 1.70862 25.9567 1.78869C26.1839 1.86876 26.3921 1.99489 26.5683 2.15917C26.7446 2.32345 26.885 2.52235 26.9808 2.7434C27.0765 2.96446 27.1257 3.20291 27.125 3.44383V18.1438" stroke={stroke} strokeWidth="1.75" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M14 6.7207V11.2555" stroke={stroke} strokeWidth="1.75" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M10.9024 8.39362C8.80166 7.63503 6.61902 7.12598 4.39941 6.87695" stroke={stroke} strokeWidth="1.75" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M22.6528 11.5119C23.0565 11.4466 23.4699 11.3863 23.893 11.3311" stroke={stroke} strokeWidth="1.75" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M10.9024 12.8438C8.79992 12.0911 6.61797 11.5822 4.39941 11.3271" stroke={stroke} strokeWidth="1.75" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M10.9009 17.5606C8.77857 16.8058 6.57668 16.2969 4.33838 16.0439" stroke={stroke} strokeWidth="1.75" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M17.3784 8.40143C19.5018 7.64609 21.7049 7.1372 23.9444 6.88477" stroke={stroke} strokeWidth="1.75" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M26.9966 27.8799L27.1133 26.5709C27.1903 25.7235 26.9559 24.8771 26.4541 24.19C25.9523 23.5029 25.2174 23.0221 24.3868 22.8375L20.1273 21.8914V15.1924C20.1273 14.6122 19.8968 14.0558 19.4866 13.6456C19.0764 13.2354 18.52 13.0049 17.9398 13.0049C17.3596 13.0049 16.8032 13.2354 16.393 13.6456C15.9828 14.0558 15.7523 14.6122 15.7523 15.1924V25.9315L14.0291 24.6389C13.6847 24.3809 13.2588 24.2558 12.8296 24.2864C12.4004 24.3171 11.9966 24.5014 11.6923 24.8057C11.388 25.11 11.2036 25.5138 11.173 25.943C11.1424 26.3722 11.2675 26.7981 11.5255 27.1425L12.0785 27.8799" stroke={stroke} strokeWidth="1.75" strokeLinecap="round" strokeLinejoin="round" />
      </g>
      <defs>
        <clipPath id="clip0_7344_101634">
          <rect width="28" height="28" fill="white" transform="translate(0 0.753906)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default StudyBookIcon;
