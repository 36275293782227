import { Box, Stack } from '@mui/material';

import { useUserProfile } from 'reactQuery/queries';
import PlayerSolutionsDifficultyBreakdown from './sections/PlayerSolutionsDifficultyBreakdown';
import PlayerLevelProgress from './sections/PlayerLevelProgress';
import PlayerStats from './sections/PlayerStats';
import PlayerStatusCharts from './sections/PlayerStatusCharts';

const ProfileOverviewContainer = () => {
  const {
    data: profileDetails = {},
  } = useUserProfile();

  return (
    <Stack spacing={6} py={6}>
      <Box>
        <PlayerStats profileDetails={profileDetails} />
      </Box>
      <Stack
        rowGap={6}
        columnGap={6}
        direction="row"
        justifyContent="space-between"
        flexWrap="wrap"
      >
        <PlayerSolutionsDifficultyBreakdown
          entity="challenge"
          profileDetails={profileDetails}
        />
        <PlayerSolutionsDifficultyBreakdown
          entity="machine"
          profileDetails={profileDetails}
        />
      </Stack>
      <Box>
        <PlayerLevelProgress profileDetails={profileDetails} />
      </Box>
      <Box>
        <PlayerStatusCharts profileDetails={profileDetails} />
      </Box>
    </Stack>
  );
};
export default ProfileOverviewContainer;
