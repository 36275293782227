import { useParams } from 'react-router-dom';
import useCustomQuery from 'reactQuery/useCustomQuery';
import { getUserInfo } from 'services';

const useUserInfo = (options = {}) => {
  const { username } = useParams();
  const query = useCustomQuery({
    queryKey: [`user-${username}`],
    queryFn: () => getUserInfo(username),
    ...options,
  });

  return query;
};

export default useUserInfo;
