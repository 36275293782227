const PromoCodeIcon = (props) => {
  const {
    stroke = '',
    fill = 'none',
    ...rest
  } = props;

  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill={fill} xmlns="http://www.w3.org/2000/svg" stroke={stroke} {...rest}>
      <path d="M15.5 10V12C15.5 12.2652 15.3946 12.5196 15.2071 12.7071C15.0196 12.8946 14.7652 13 14.5 13H1.5C1.23478 13 0.98043 12.8946 0.792893 12.7071C0.605357 12.5196 0.5 12.2652 0.5 12V10C1.03043 10 1.53914 9.78929 1.91421 9.41421C2.28929 9.03914 2.5 8.53043 2.5 8C2.5 7.46957 2.28929 6.96086 1.91421 6.58579C1.53914 6.21071 1.03043 6 0.5 6V4C0.5 3.73478 0.605357 3.48043 0.792893 3.29289C0.98043 3.10536 1.23478 3 1.5 3H14.5C14.7652 3 15.0196 3.10536 15.2071 3.29289C15.3946 3.48043 15.5 3.73478 15.5 4V6C14.9696 6 14.4609 6.21071 14.0858 6.58579C13.7107 6.96086 13.5 7.46957 13.5 8C13.5 8.53043 13.7107 9.03914 14.0858 9.41421C14.4609 9.78929 14.9696 10 15.5 10Z" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" />
      <path d="M5.5 7C6.05228 7 6.5 6.55228 6.5 6C6.5 5.44772 6.05228 5 5.5 5C4.94772 5 4.5 5.44772 4.5 6C4.5 6.55228 4.94772 7 5.5 7Z" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M11 11C11.5523 11 12 10.5523 12 10C12 9.44772 11.5523 9 11 9C10.4477 9 10 9.44772 10 10C10 10.5523 10.4477 11 11 11Z" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M10.5 5.5L5.5 10.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};
export default PromoCodeIcon;
