import { LoadingButton } from '@mui/lab';
import { Box } from '@mui/material';
import { useLocale } from 'util/hooks';
import CHALLENGE_FILES_TYPES from 'assets/constants/challengeFilesTypes';
import useDownloadChallengeFiles from '../helpers/useDownloadChallengeFiles';

const ChallengeFilesCard = (props) => {
  const {
    isContentDisabled = false,
  } = props;

  const { t } = useLocale();
  const {
    isLoading = false,
    handleDownload = () => { },
  } = useDownloadChallengeFiles({
    fileType: CHALLENGE_FILES_TYPES.challengeFiles,
    action: 'DOWNLOAD_CHALLENGE_FILES',
  });

  return (
    <Box>
      <LoadingButton
        loading={isLoading}
        variant="outlined"
        color="secondary"
        disabled={isContentDisabled}
        onClick={handleDownload}
      >
        {t('common.download')}
      </LoadingButton>
    </Box>
  );
};

export default ChallengeFilesCard;
