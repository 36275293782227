import { Tabs } from 'components/molecules';

import { Stack, Typography } from '@mui/material';
import { useEventDetails } from 'reactQuery/queries';
import { useIsEvent, useLocale } from 'util/hooks';

import { EventChallengeActivity } from 'components/organisms';
import ChallengeDetails from './ChallengeDetails';
import LabChallengeActivity from './LabChallengeActivity';

const UnqualifiedTeamState = (props) => {
  const { isEventChallenge } = props;
  const { t } = useLocale();

  const { data: eventDetails = {} } = useEventDetails({
    options:
    { enabled: isEventChallenge },
  });

  const {
    teamMaxCapacity: max,
    teamMinCapacity: min,
    isExactCapacity,
  } = eventDetails;
  return (
    <Stack height={344} alignItems="center" justifyContent="center">
      <Typography variant="bodyMediumMedium">
        {t('events.teams.unqualifiedTitle')}
      </Typography>
      <Typography variant="bodyStandardRegular">
        { isExactCapacity
          ? t('events.teams.unqualifiedDescriptionExact', { count: min })
          : t('events.teams.unqualifiedDescription', { min, max })}
      </Typography>
    </Stack>
  );
};

const ChallengeTabs = (props) => {
  const {
    challengeDetails = {},
    isQualifiedTeam = true,
  } = props;
  const { t } = useLocale();
  const isEventChallenge = useIsEvent();

  const showUnqualifiedState = isEventChallenge && !isQualifiedTeam;
  const challengeDetailsComponent = showUnqualifiedState
    ? <UnqualifiedTeamState isEventChallenge={isEventChallenge} />
    : <ChallengeDetails challengeDetails={challengeDetails} />;

  const challengeActivity = isEventChallenge
    ? <EventChallengeActivity challengeDetails={challengeDetails} />
    : <LabChallengeActivity challengeDetails={challengeDetails} />;

  const tabs = [{
    key: 'details',
    label: t('challenges.challengeDetails'),
    component: challengeDetailsComponent,
  },
  {
    key: 'activity',
    label: t('common.activity'),
    component: challengeActivity,
  }];

  return (
    <Stack gap={7}>
      <Tabs tabs={tabs} />
    </Stack>
  );
};

export default ChallengeTabs;
