import { useEventRecentSolves } from 'reactQuery/queries';
import { EventRecentSolvesTable } from 'components/tables';
import SOCKET_EVENTS from 'assets/constants/socketEvents';
import { useModal, useSocket } from 'util/hooks';
import MODAL_KEYS from 'assets/constants/modalKeys';

const VisualizationLatestCaptures = () => {
  const { addModal } = useModal();
  const {
    data: recentSolves = [],
    isFetching,
    refetch: refetchRecentSolves,
  } = useEventRecentSolves({
    queryKeyOptions: {
      pagination: {
        limit: 5,
      },
    },
  });

  const socketEvents = [
    {
      eventName: SOCKET_EVENTS.events.visualization,
      handler: (params) => {
        refetchRecentSolves();
        if (params.firstBlood) {
          addModal({
            key: MODAL_KEYS.eventChallengeNewCaptureModal,
            modalProps: {
              disableAutoFocus: true,
            },
            props: {
              submission: {
                ...params,
              },
            },
          });
        }
      },
    },
  ];

  useSocket({ events: socketEvents });

  return (
    <EventRecentSolvesTable
      recentSolves={recentSolves}
      isFetching={isFetching}
    />
  );
};

export default VisualizationLatestCaptures;
