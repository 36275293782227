import { useTheme } from '@mui/styles';

const DoubleArrowUp = (props) => {
  const theme = useTheme();
  const {
    stroke = theme.palette.primary.main,
    fill = 'none',
    ...rest
  } = props;

  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill={fill} xmlns="http://www.w3.org/2000/svg" {...rest}>
      <path fillRule="evenodd" clipRule="evenodd" d="M12.2592 8.97259L7.89924 6.19792L3.54057 8.97259C3.0959 9.25526 2.3999 9.02592 2.3999 8.59592V6.98859C2.40759 6.83388 2.45445 6.68366 2.53607 6.55201C2.61769 6.42036 2.73141 6.3116 2.86657 6.23592L7.44657 3.32326C7.5836 3.24206 7.73995 3.19922 7.89924 3.19922C8.05852 3.19922 8.21487 3.24206 8.3519 3.32326L12.9332 6.23592C13.0684 6.3116 13.1821 6.42036 13.2637 6.55201C13.3454 6.68366 13.3922 6.83388 13.3999 6.98859V8.59592C13.3999 9.02592 12.7039 9.25526 12.2592 8.97259Z" stroke={stroke} strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M12.8999 12.5977L7.8999 9.09766L2.8999 12.5977" stroke={stroke} strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default DoubleArrowUp;
