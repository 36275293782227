import {
  Box, Card, Chip, Link, Typography,
} from '@mui/material';
import { getDifficultyByName } from 'assets/constants/difficulties';
import { ArrowRightIcon } from 'assets/icons';
import { ChallengeAvatar, MachineAvatar } from 'components/molecules';
import { PATHS } from 'routes';
import { capitalizeFirstLetter } from 'util/helpers';
import { useLocale } from 'util/hooks';

const LatestChallengesMachinesCard = (props) => {
  const { entity = {} } = props;
  const { t, isRtl } = useLocale();

  const {
    lab = {},
    name = '',
    category = {},
    difficulty = '',
    isMachine = false,
  } = entity || {};

  const {
    id: labId,
    type: labType = '',
  } = lab || {};

  // Link
  const to = isMachine
    ? `/${PATHS.machines}/${name}`
    : `/${PATHS.labs}/${labType}-${PATHS.labs}/${labId}/${PATHS.challenges}/${entity.id}`;

  // Icon
  const iconSize = { width: 36, height: 36 };
  const entityIcon = isMachine
    ? (
      <MachineAvatar
        machineType={category.name}
        sx={{ ...iconSize }}
      />
    )
    : (
      <ChallengeAvatar
        challenge={entity}
        sx={{ ...iconSize }}
      />
    );
  const difficultyChip = (
    <Chip
      size="small"
      color={difficulty || ''}
      label={t(getDifficultyByName(difficulty)?.localizationKey) || ''}
    />
  );
  const cardActionIcon = (
    <ArrowRightIcon
      width="12"
      height="12"
      style={{ transform: isRtl ? 'scaleX(-1)' : 'none' }}
    />
  );

  return (
    <Card sx={{ width: 1 }}>
      <Box
        component={Link}
        to={to}
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          color: 'inherit', // Avoid link color
          width: 1,
          px: { xs: 4, sm: 8 },
          py: 6,
        }}
      >
        <Box sx={{
          display: 'flex',
          alignItems: 'center',
          width: {
            xs: 120,
            sm: 220,
          },
        }}
        >
          <Box sx={{
            ...iconSize,
            mr: 3,
          }}
          >
            {entityIcon}
          </Box>
          <Box>
            <Typography
              variant="bodyStandardMedium"
              sx={{
                lineHeight: 'initial',
                maxWidth: 180,
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                display: 'block',
                textOverflow: 'ellipsis',
              }}
            >
              {name}
            </Typography>
            <Typography
              variant="bodySmallMedium"
              color="text.secondary"
            >
              {capitalizeFirstLetter(category?.name)}
            </Typography>
          </Box>
        </Box>
        <Box sx={{ width: 80 }}>
          {difficultyChip}
        </Box>
        <Box sx={{
          display: 'flex',
          width: 12,
          height: 12,
        }}
        >
          {cardActionIcon}
        </Box>
      </Box>
    </Card>
  );
};

export default LatestChallengesMachinesCard;
