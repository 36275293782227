import { getAllPlans } from 'services';
import { useCustomQuery } from 'reactQuery';

const usePlans = (queryProps = {}) => {
  const { queryKeyOptions = {}, ...rest } = queryProps;

  const query = useCustomQuery({
    queryKey: [
      'plans',
      queryKeyOptions,
    ],
    queryFn: getAllPlans,
    ...rest,
  });

  return { ...query };
};

export default usePlans;
