import { DataGrid } from 'components/core';

import { usePromoCodes } from 'reactQuery/queries';
import useTableColumns from './useTableColumns';

const PromoCodeTable = () => {
  const {
    data = {},
    isFetching = true,
    refetch,
  } = usePromoCodes();

  const columns = useTableColumns({
    fetchPromoCodes: refetch,
  });

  const {
    items: rows = [],
    meta = {},
  } = data || {};

  return (
    <DataGrid
      rows={rows}
      columns={columns}
      loading={isFetching}
      pagination={meta}
      componentsProps={{
        noRowsOverlay: { type: 'promoCode' },
      }}
    />
  );
};

export default PromoCodeTable;
