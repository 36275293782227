import {
  useEffect, useState,
} from 'react';
import {
  Box,
  Card,
  CircularProgress,
  Typography,
} from '@mui/material';

import { useLocale } from 'util/hooks';
import { PlayerLinearLevelProgress } from 'components/organisms';

const PlayerLevelProgress = (props) => {
  const { profileDetails = {} } = props;
  const {
    user = {},
  } = profileDetails || {};
  const {
    playerLevel = {},
    totalPoints = 0,
  } = user || {};

  const { isAr } = useLocale();

  const [levelIcon, setLevelIcon] = useState('');

  useEffect(() => {
    const importLevelIcon = async () => {
      const importedIcon = await import(`assets/images/level-${playerLevel.nameKey}.svg`);
      setLevelIcon(importedIcon.default);
    };

    importLevelIcon();
  }, [playerLevel]);

  const currentLevelName = isAr ? playerLevel.nameAr : playerLevel.nameEn;

  return (
    <Card sx={{
      minWidth: 256,
    }}
    >
      <Box sx={{
        display: 'flex',
        flexDirection: {
          xs: 'column',
          sm: 'row',
        },
        justifyContent: 'space-between',
        alignItems: 'center',
        py: { xs: 8, sm: 13 },
        px: { xs: 10, sm: 15 },
        rowGap: 4,
      }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Box sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            mr: 4,
            width: 100,
            height: 100,
          }}
          >
            <Box sx={{ display: levelIcon ? 'none' : 'block' }}>
              <CircularProgress />
            </Box>
            <Box sx={{
              display: levelIcon ? 'block' : 'none',
              width: 100,
              height: 100,
            }}
            >
              <img
                src={levelIcon}
                alt={`level-${playerLevel.nameKey}-icon`}
                width="100"
                height="100"
              />
            </Box>
          </Box>
          <Typography variant="bodyStandardBold">
            {currentLevelName}
          </Typography>
        </Box>
        <Box sx={{ m: 2 }}>
          <PlayerLinearLevelProgress progressDetails={{ playerLevel, totalPoints }} />
        </Box>
      </Box>
    </Card>
  );
};

export default PlayerLevelProgress;
