import { useTheme } from '@mui/styles';

const KeyboardReturnIcon = (props) => {
  const theme = useTheme();
  const {
    stroke = theme.palette.text.secondary,
    fill = 'none',
    ...rest
  } = props;

  return (
    <svg width="28" height="28" viewBox="0 0 28 28" fill={fill} xmlns="http://www.w3.org/2000/svg" stroke={stroke} {...rest}>
      <path d="M7 15.7496H15.75C17.1424 15.7496 18.4777 15.1965 19.4623 14.2119C20.4469 13.2273 21 11.892 21 10.4996V7.87109" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M11.375 11.3711L7 15.7461L11.375 20.1211" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M22.75 1.74609H5.25C3.317 1.74609 1.75 3.3131 1.75 5.24609V22.7461C1.75 24.6791 3.317 26.2461 5.25 26.2461H22.75C24.683 26.2461 26.25 24.6791 26.25 22.7461V5.24609C26.25 3.3131 24.683 1.74609 22.75 1.74609Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default KeyboardReturnIcon;
