import { Box, Typography, useTheme } from '@mui/material';

import {
  Chart,
  useLineOptions,
} from 'components/chart';
import { useDate, useLocale } from 'util/hooks';

const ActivityBreakdownChart = (props) => {
  const { profileDetails = {} } = props;

  const { palette } = useTheme();
  const { t, isAr } = useLocale();
  const { getMonthsShort } = useDate();

  const {
    sumPerMonth = {},
  } = profileDetails || {};

  const lineLabels = getMonthsShort(isAr, true, sumPerMonth?.map((v) => v.month - 1));
  const lineDataset = sumPerMonth?.map((v) => v.sum);
  const lineOption = useLineOptions();
  lineOption.scales.y.title.text = t('challenges.points');

  const lineData = {
    labels: isAr ? lineLabels.reverse() : lineLabels,
    datasets: [{
      label: t('common.points').toLowerCase(),
      data: isAr ? lineDataset?.reverse() : lineDataset,
      borderColor: palette.primary.main,
      tension: 0.2,
    }],
  };

  return (
    <Box width={1}>
      <Box
        sx={{ mb: 14 }}
      >
        <Typography
          variant="bodyStandardMedium"
          sx={{ mx: 14, color: 'text.lightGray' }}
        >
          {t('profile.activityBreakdown')}
        </Typography>
      </Box>
      <Chart
        data={lineData}
        options={lineOption}
      />
    </Box>
  );
};

export default ActivityBreakdownChart;
