import { Box, Card } from '@mui/material';
import { useLocale } from 'util/hooks';
import { ChallengesIcon } from 'assets/icons';
import MachineSectionWrapper from '../helpers/MachineSectionWrapper';
import MachineDetailsForm from '../../MachineDetailsForm/MachineDetailsForm';

const CreateMachineContainer = () => {
  const { t } = useLocale();

  return (
    <Box mt={8}>
      <Card sx={{
        px: { xs: 8, sm: 14 },
        py: 14,
      }}
      >
        <MachineSectionWrapper
          title={t('machines.newMachine')}
          icon={<ChallengesIcon />}
        >
          <MachineDetailsForm createMode />
        </MachineSectionWrapper>
      </Card>
    </Box>
  );
};

export default CreateMachineContainer;
