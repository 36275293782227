import { useTheme } from '@mui/styles';

const PaymentCardPurpleIcon = (props) => {
  const theme = useTheme();
  const {
    stroke = theme.palette.text.secondary,
    fill = theme.palette.paper.hover,
    ...rest
  } = props;

  return (
    <svg width="48" height="44" viewBox="0 0 48 44" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <rect width="48" height="44" rx="6" fill={fill} />
      <g clipPath="url(#clip0_4590_2454)">
        <path d="M32.9375 14.4375H15.0625C14.3031 14.4375 13.6875 15.0531 13.6875 15.8125V28.1875C13.6875 28.9469 14.3031 29.5625 15.0625 29.5625H32.9375C33.6969 29.5625 34.3125 28.9469 34.3125 28.1875V15.8125C34.3125 15.0531 33.6969 14.4375 32.9375 14.4375Z" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M13.6875 18.5625H34.3125" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M17.8125 22.6875H25.375" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M17.8125 25.4375H22.625" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      </g>
      <defs>
        <clipPath id="clip0_4590_2454">
          <rect width="22" height="22" fill="white" transform="translate(13 11)" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default PaymentCardPurpleIcon;
