const END_POINTS = {
  logout: '/auth/logout',
  register: '/users',
  verifyEmail: '/auth/verify-email',
  sendVerificationEmail: '/auth/send-verification-email',
  resetPassword: '/auth/reset-password',
  forgotPassword: '/auth/forgot-password',
  login: '/auth',
  permissions: '/permissions',
  uploads: '/uploads',
  updateMyProfile: '/users/profile',
  myInfo: '/users/me',
  deleteAvatar: '/users/avatar',
  updatePassword: '/auth/password',
  invitations: '/invitations',
  acceptInvitation: '/invitations/accept',
  validateInvitationToken: '/invitations/validate-token',
  users: '/users',
  userInfo: (username) => `/users/${username}`,
  userProfile: (username) => `/users/profile/${username}`,
  activateUser: (username) => `/users/activate/${username}`,
  suspendUser: (username) => `/users/suspend/${username}`,
  onlinePlayers: '/users/online-players',
  cancelInvitation: (id) => `/invitations/cancel/${id}`,
  resendInvitation: (id) => `/invitations/resend/${id}`,
  plans: '/plans',
  plan: (id) => `/plans/${id}`,
  updatePlanStatus: (id, status) => `/plans/${id}/${status}`,
  planPrice: (id) => `/plans/${id}/prices`,
  features: '/features',
  featuresEditPriority: (id) => `/features/${id}`,
  featuresTogglePromoted: (id) => `/features/${id}/toggle-promoted`,
  featuresToggleComingSoon: (id) => `/features/${id}/toggle-coming-soon`,
  labs: '/labs',
  latestChallenge: (labType) => `/labs/latest-challenge?type=${labType}`,
  lab: (labId) => `/labs/${labId}`,
  createLabChallenge: (labId) => `/labs/${labId}/challenges`,
  eventChallenges: (eventId) => `/events/${eventId}/challenges`,
  eventLeaderboard: (eventId) => `/teams/event/${eventId}/leaderboard`,
  eventRecentSolves: (eventId) => `/teams/event/${eventId}/recent-solves`,
  labChallenge: (labId, challengeId) => `/labs/${labId}/challenges/${challengeId}`,
  eventChallenge: (eventId, challengeId) => `/events/${eventId}/challenges/${challengeId}`,
  eventChallengeSubmissions: (eventId, challengeId) => `/events/${eventId}/challenges/${challengeId}/submissions`,
  eventTeamActivity: (eventId, teamId) => `/teams/event/${eventId}/${teamId}/activities`,
  playerEventHistory: (username) => `/events/${username}/history`,
  challengeCategories: '/challenges-categories',
  updateChallengeStatus: (labId, challengeId, status) => `/labs/${labId}/challenges/${challengeId}/${status}`,
  submitFlag: (labId, challengeId) => `/labs/${labId}/challenges/${challengeId}/flag`,
  submitEventFlag: (eventId, challengeId) => `/events/${eventId}/challenges/${challengeId}/flag`,
  challengeFiles: (labId, challengeId) => `/labs/${labId}/challenges/${challengeId}/challenge-files`,
  eventChallengeFiles: (eventId, challengeId) => `/events/${eventId}/challenges/${challengeId}/challenge-files`,
  writeupFiles: (labId, challengeId) => `/labs/${labId}/challenges/${challengeId}/write-up-files`,
  labChallengeInstance: (labId, challengeId) => `/labs/${labId}/challenges/${challengeId}/instance`,
  eventChallengeInstance: (eventId, challengeId) => `/events/${eventId}/challenges/${challengeId}/instance`,
  promoCodes: '/promo-codes',
  promoCode: (id) => `/promo-codes/${id}`,
  updatePromoCodeStatus: (id, status) => `/promo-codes/${id}/${status}`,
  subscriptions: '/subscriptions',
  userPreferences: '/users/preferences ',
  transactions: '/transactions',
  downloadInvoice: (id) => `/transactions/${id}/pdf`,
  allPaymentCards: '/cards',
  onePaymentCard: (id) => `/cards/${id}`,
  setPaymentCardDefault: (id) => `/cards/${id}/default`,
  tokenizationData: '/payment/tokenization-data',
  purchaseData: '/payment/purchase-data',
  leaderBoard: '/users/leaderboard',
  latestActivity: '/dashboard/players-latest-activity',
  latestChallenges: '/dashboard/latest-challenges',
  latestChallengesMachines: '/dashboard/latest-challenges-machines',
  getAnnouncements: '/dashboard/announcements',
  announcements: '/announcements',
  announcement: (announcementId) => `/announcements/${announcementId}`,
  updateAnnouncementStatus: (announcementId, status) => `/announcements/${announcementId}/${status}`,
  resetMachine: (machineName) => `/machines/reset/${machineName}`,
  machines: '/machines',
  machine: (machineName) => `/machines/${machineName}`,
  updateMachineStatus: (machineName, status) => `/machines/${machineName}/${status}`,
  machineTypes: '/machines/machine-types',
  submitMachineFlag: (machineName) => `/machines/${machineName}/flag`,
  getUserResets: '/machines/resets',
  vpn: '/vpn',
  vpnRegions: '/vpn/regions',
  events: '/events',
  event: (eventId) => `/events/${eventId}`,
  playerTeamDetails: (eventId) => `/teams/event/${eventId}/player`,
  playerLeaderboardTeamDetails: (eventId, teamId) => `/teams/event/${eventId}/leaderboard/${teamId}`,
  adminTeamDetails: (teamId) => `/teams/${teamId}`,
  eventTeams: (eventId) => `/teams/event/${eventId}`,
  updateTeam: (teamId) => `/teams/${teamId}`,
  eventInvitations: '/event-invitations',
  eventInvitation: (id) => `/event-invitations/${id}`,
  removeTeamMember: (memberId) => `/team-member/${memberId}/remove`,
  moveTeamMember: (memberId) => `/team-member/${memberId}/move`,
  withdrawFromTeam: (memberId) => `/team-member/${memberId}/withdraw`,
  setMemberAsCaptain: (memberId) => `/team-member/${memberId}/set-as-captain`,
  toggleTeamBanning: (teamId) => `/teams/${teamId}/toggle-banning`,
  toggleLeaderBoardFreeze: (eventId) => `/events/${eventId}/leaderboard/toggle-freeze`,
  teams: '/teams',
  joinTeam: '/teams/join',
  connectAccount: '/connect',
  deleteSubmission: (eventId, challengeId, submissionId) => `/events/${eventId}/challenges/${challengeId}/submissions/${submissionId}`,
  instances: (type) => `/instances/${type}`,
  instance: (instanceId) => `/instances/${instanceId}`,
  companies: '/companies',
  editCompany: (companyName) => `/companies/${companyName}`,
  companyMembers: (companyName) => `/companies/${companyName}/members`,
  moveCompanyMember: (currentCompany, memberId) => `/companies/${currentCompany}/members/${memberId}/move`,
  exportEventReport: (eventId) => `/events/${eventId}/export`,
};

export default END_POINTS;
