import api from 'services/api';
import END_POINTS from 'services/endPoints';

const getUserResets = (options) => api(
  END_POINTS.getUserResets,
  options,
)
  .then((res) => Promise.resolve(res))
  .catch((error) => Promise.reject(error));

export default getUserResets;
