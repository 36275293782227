import { useLocale, usePermission } from 'util/hooks';
import {
  Box,
  Card,
} from '@mui/material';
import { MachineDetailsIcon } from 'assets/icons';

import PERMISSIONS from 'assets/constants/permissions';
import { useNavigate } from 'react-router-dom';
import { STATUS } from 'assets/constants/statuses';
import MachineSectionWrapper from '../../helpers/MachineSectionWrapper';
import MachineDetailsWrapper from '../../helpers/MachineDetailsWrapper';

const MachineInfo = (props) => {
  const { machineDetails = {} } = props;

  const { t } = useLocale();

  const navigate = useNavigate();

  const isArchived = machineDetails?.status === STATUS.archived;
  const canEdit = usePermission(PERMISSIONS.machineUpdate);
  const canEditMachine = !isArchived && canEdit;
  const onClickEdit = () => navigate(`/machines/${machineDetails?.name}/edit`);

  return (
    <Box>
      <Card sx={{ p: 14 }}>
        <MachineSectionWrapper
          icon={<MachineDetailsIcon />}
          title={t('machines.machineDetails')}
          canEdit={canEditMachine}
          onClickEdit={onClickEdit}
        />
        <Box sx={{ mt: 4 }}>
          <MachineDetailsWrapper machineDetails={machineDetails} />
        </Box>
      </Card>
    </Box>
  );
};

export default MachineInfo;
