import { DataGrid } from 'components/core';

import useTableColumns from './useTableColumns';

const getColumnVisibility = (type) => {
  const isLab = type === 'labs';
  const isEvent = type === 'events';

  const model = {
    // Lab only
    lab: isLab,
    // Event only
    team: isEvent,
    event: isEvent,
  };

  return model;
};

const InstanceTable = (props) => {
  const {
    type,
    query = {},
    rowSelectionModel,
    setRowSelectionModel,
  } = props;

  const columns = useTableColumns();
  const columnVisibilityModel = getColumnVisibility(type);

  const {
    data = {},
    isFetching = false,
  } = query;

  const {
    items: rows = [],
    meta = {},
  } = data || {};

  return (
    <DataGrid
      rows={rows}
      columns={columns}
      loading={isFetching}
      pagination={meta}
      checkboxSelection
      rowSelectionModel={rowSelectionModel}
      onSelectionModelChange={(newRowSelectionModel) => {
        setRowSelectionModel(newRowSelectionModel);
      }}
      columnVisibilityModel={columnVisibilityModel}
    />
  );
};

export default InstanceTable;
