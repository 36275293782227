import PropTypes from 'prop-types';

import { useLocale } from 'util/hooks';
import {
  Box,
  Chip,
  Grid,
  Tooltip,
  Typography,
} from '@mui/material';
import { Tag, TypographyWithIcon, ResetMachineButton } from 'components/molecules';
import { CheckCircleOutline, InformationCircleIcon } from 'assets/icons';
import { useMemo } from 'react';
import { getDifficultyByName } from 'assets/constants/difficulties';

const MachineDetailsWrapper = (props) => {
  const { machineDetails } = props;
  const { t, isAr } = useLocale();

  const {
    name = '',
    type = '',
    templateId = '',
    author = '',
    userFlag = '',
    rootFlag = '',
    userPoints = 0,
    rootPoints = 0,
    tags = [],
    difficulty = '',
    ip = '',
    plans = [],
    createdBy = '',
    publishedAt = '',
  } = machineDetails;

  const items = useMemo(() => [
    {
      key: 'name',
      title: t('common.name'),
      component: name,
    },
    {
      key: 'type',
      title: t('common.type'),
      component: type?.name,
    },
    {
      key: 'templateId',
      title: t('machines.templateId'),
      component: templateId,
    },
    {
      key: 'author',
      title: t('machines.author'),
      component: author,
    },
    {
      key: 'userFlag',
      title: t('machines.userFlag'),
      component: userFlag,
    },
    {
      key: 'rootFlag',
      title: t('machines.rootFlag'),
      component: rootFlag,
    },
    {
      key: 'userPoints',
      title: t('machines.userPoints'),
      component: userPoints,
    },
    {
      key: 'rootPoints',
      title: t('machines.rootPoints'),
      component: rootPoints,
    },
    {
      key: 'tags',
      title: t('common.tags'),
      component: tags?.length > 0 ? (tags?.map((tag) => (
        <Tag
          key={tag}
          label={tag}
          sx={{ mr: 2, mt: 2 }}
        />
      ))) : t('common.none'),
    },
    {
      key: 'difficulty',
      title: (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant="bodyStandardBold" sx={{ mr: 2 }}>
            {t('common.difficulty')}
          </Typography>
          <Tooltip
            title={t('machines.difficultyInfo')}
            placement="top"
          >
            <Box sx={{ display: 'flex', width: 14, height: 14 }}>
              <InformationCircleIcon width="14" height="14" />
            </Box>
          </Tooltip>
        </Box>
      ),
      component: (
        <Chip
          size="small"
          color={difficulty || ''}
          label={t(getDifficultyByName(difficulty)?.localizationKey) || ''}
        />),
    },
    {
      key: 'ip',
      title: t('machines.ip'),
      component: (
        <>
          <Grid item>
            {ip || t('common.none')}
          </Grid>
          <Grid item>
            <ResetMachineButton />
          </Grid>
        </>
      ),
      componentProps: {
        sx: {
          overflowWrap: 'break-word',
          wordWrap: 'break-word',
        },
      },
    },
    {
      key: 'plans',
      title: t('machines.assignedPlans'),
      component: plans?.map((plan) => (
        <Grid item key={plan.id}>
          <TypographyWithIcon
            label={isAr ? plan?.nameAr : plan?.nameEn}
            icon={(
              <CheckCircleOutline />
            )}
            iconProps={{ ml: 0 }}
          />
        </Grid>
      )),
    },
    {
      key: 'createdBy',
      title: t('common.createdBy'),
      component: createdBy,
    },

    {
      key: 'publishedAt',
      title: t('common.publishedAt'),
      component: publishedAt
        ? (new Date(publishedAt)
          .toLocaleString(
            'en-US',
            {
              year: 'numeric',
              month: 'short',
              day: 'numeric',
              hour: '2-digit',
              minute: '2-digit',
            },
          ))
        : t('common.notPublished'),
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
  ], [machineDetails, t, isAr]);

  return (
    <Box>
      <Grid
        container
        sx={{
          display: 'grid',
          rowGap: 14,
          columnGap: 10,
          gridTemplateColumns: {
            xs: 'repeat(1, 1fr)',
            md: 'repeat(1, 420px)',
            lg: 'repeat(2, 420px)',
          },
        }}
      >
        {items?.map(({
          key,
          title,
          component,
          containerProps,
          componentProps,
        }) => (
          <Grid key={key} item {...containerProps}>
            <Typography
              variant="bodyStandardBold"
              color="text.secondary"
            >
              {title}
            </Typography>
            <Box>
              {typeof component === 'string' || typeof component === 'number'
                ? (
                  <Typography
                    variant="bodyMediumMedium"
                    color="text.lighterGray"
                    {...componentProps}
                  >
                    {component}
                  </Typography>
                )
                : component}
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

MachineDetailsWrapper.propTypes = {
  machineDetails: PropTypes.oneOfType([PropTypes.object]),
};

MachineDetailsWrapper.defaultProps = {
  machineDetails: {},
};

export default MachineDetailsWrapper;
