import { Chip } from '@mui/material';
import { useCountDown, useLocale } from 'util/hooks';

const CountdownBadge = (props) => {
  const { countdownFrom, ...rest } = props;

  const { t } = useLocale();
  const countdown = useCountDown(countdownFrom);

  const countDownLabel = countdown?.map((time) => `${time.time}${time.labelShort}`)?.join(' ');
  const label = `${t('common.startingIn')} ${countDownLabel}`;

  return (
    <Chip
      size="small"
      color="active"
      label={label}
      {...rest}
    />
  );
};

export default CountdownBadge;
