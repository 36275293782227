import api from 'services/api';
import END_POINTS from 'services/endPoints';

const getCompanyMembers = (name, options) => api(
  END_POINTS.companyMembers(name),
  options,
)
  .then((res) => Promise.resolve(res))
  .catch((error) => Promise.reject(error));

export default getCompanyMembers;
