import { getMachineTypes } from 'services';
import { useCustomQuery } from 'reactQuery';

const useMachineTypes = (queryProps = {}) => {
  const query = useCustomQuery({
    queryKey: ['machine-types'],
    queryFn: getMachineTypes,
    ...queryProps,
  });

  return { ...query };
};

export default useMachineTypes;
