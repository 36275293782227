import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

import { LockedBackdrop } from 'components/molecules';
import { useAuth } from 'util/hooks';
import { STATUS } from 'assets/constants/statuses';
import ResetMachineCard from '../helpers/ResetMachineCard';
import MachineProgressCard from '../helpers/MachineProgressCard';
import SubmitMachineFlagCard from '../helpers/SubmitMachineFlagCard';

const MachineDetails = (props) => {
  const { machineDetails = {} } = props;

  const { isVerified } = useAuth();

  const { status = '' } = machineDetails || {};
  const isRetired = status === STATUS.retired;

  const showLockedBackdrop = isRetired || !isVerified;

  return (
    <Stack
      spacing={7}
      width={1}
      mt={7}
      sx={{
        position: 'relative',
      }}
    >
      {showLockedBackdrop && (
      <LockedBackdrop
        withVerificationButton={!isVerified}
      />
      )}
      <Box>
        <Stack
          direction="row"
          flexWrap="wrap"
          justifyContent="space-between"
          rowGap={6}
          columnGap={6}
        >
          <Box flex={1}>
            <ResetMachineCard />
          </Box>
          <Box flex={1}>
            <MachineProgressCard />
          </Box>
        </Stack>
      </Box>
      <Box>
        <SubmitMachineFlagCard />
      </Box>
    </Stack>
  );
};
export default MachineDetails;
