import { useLocale } from 'util/hooks';
import { Box, Stack } from '@mui/material';
import { NoContentPlaceholder } from 'components/molecules';
import { PlayerActivityCard } from 'components/organisms';
import { transformSubmission } from 'util/transformers';
import { useUserProfile } from 'reactQuery/queries';
import ProfileActivityHeader from './helpers/ProfileActivityHeader';

const ProfileActivityContainer = () => {
  const {
    data: profileDetails = {},
  } = useUserProfile();

  const { t } = useLocale();

  const {
    submissions = [],
  } = profileDetails || {};

  const activityList = submissions
    .map((submission) => (
      <PlayerActivityCard
        key={submission.id}
        activity={transformSubmission(submission)}
      />
    ));

  const renderNoActivityPlaceholder = () => {
    const content = {
      desc: [{
        text: t('profile.noActivities'),
      }],
    };

    return <NoContentPlaceholder content={content} />;
  };

  return (
    <Box>
      <Box sx={{ py: 6 }}>
        <ProfileActivityHeader />
      </Box>
      <Box>
        {activityList?.length
          ? (
            <Stack spacing={5}>
              {activityList}
            </Stack>
          )
          : renderNoActivityPlaceholder()}
      </Box>
    </Box>
  );
};

export default ProfileActivityContainer;
