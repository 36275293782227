import { useTheme } from '@mui/styles';

const LabsFilledIcon = (props) => {
  const theme = useTheme();
  const {
    stroke = theme.palette.text.secondary,
    fill = theme.palette.text.secondary,
    ...rest
  } = props;

  return (
    <svg width="48" height="44" viewBox="0 0 48 44" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <rect width="48" height="44" rx="6" fill={fill} fillOpacity="0.1" />
      <g clipPath="url(#clip0_6205_8774)">
        <path d="M34.007 16.6834L24.6487 11.8452C24.4481 11.7416 24.2256 11.6875 23.9997 11.6875C23.7739 11.6875 23.5514 11.7416 23.3507 11.8452L13.9925 16.6834C13.9006 16.731 13.8235 16.8029 13.7697 16.8913C13.7159 16.9798 13.6875 17.0813 13.6875 17.1848C13.6875 17.2883 13.7159 17.3898 13.7697 17.4782C13.8235 17.5666 13.9006 17.6386 13.9925 17.6862L23.3507 22.5271C23.5514 22.6307 23.7739 22.6848 23.9997 22.6848C24.2256 22.6848 24.4481 22.6307 24.6487 22.5271L34.007 17.6917C34.0989 17.6441 34.176 17.5721 34.2298 17.4837C34.2835 17.3953 34.312 17.2938 34.312 17.1903C34.312 17.0868 34.2835 16.9853 34.2298 16.8968C34.176 16.8084 34.0989 16.7365 34.007 16.6889V16.6834Z" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M17.6747 19.5938L13.9925 21.4986C13.9006 21.5462 13.8235 21.6181 13.7697 21.7066C13.7159 21.795 13.6875 21.8965 13.6875 22C13.6875 22.1035 13.7159 22.205 13.7697 22.2934C13.8235 22.3819 13.9006 22.4538 13.9925 22.5014L23.3507 27.3423C23.5514 27.4459 23.7739 27.5 23.9997 27.5C24.2256 27.5 24.4481 27.4459 24.6487 27.3423L34.007 22.5014C34.0989 22.4538 34.176 22.3819 34.2298 22.2934C34.2835 22.205 34.312 22.1035 34.312 22C34.312 21.8965 34.2835 21.795 34.2298 21.7066C34.176 21.6181 34.0989 21.5462 34.007 21.4986L30.3247 19.5938" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M17.6747 24.4062L13.9925 26.3083C13.9006 26.3559 13.8235 26.4279 13.7697 26.5163C13.7159 26.6047 13.6875 26.7062 13.6875 26.8097C13.6875 26.9133 13.7159 27.0148 13.7697 27.1032C13.8235 27.1916 13.9006 27.2636 13.9925 27.3112L23.3507 32.1521C23.5514 32.2557 23.7739 32.3098 23.9997 32.3098C24.2256 32.3098 24.4481 32.2557 24.6487 32.1521L34.007 27.3167C34.0989 27.2691 34.176 27.1971 34.2298 27.1087C34.2835 27.0203 34.312 26.9188 34.312 26.8153C34.312 26.7117 34.2835 26.6102 34.2298 26.5218C34.176 26.4334 34.0989 26.3614 34.007 26.3138L30.3247 24.4062" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      </g>
      <defs>
        <clipPath id="clip0_6205_8774">
          <rect width="22" height="22" fill="white" transform="translate(13 11)" />
        </clipPath>
      </defs>
    </svg>

  );
};

export default LabsFilledIcon;
