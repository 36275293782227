import {
  Box,
  Chip,
  Typography,
} from '@mui/material';

import { useLocale } from 'util/hooks';
import { capitalizeFirstLetter } from 'util/helpers';
import { getStatusByName } from 'assets/constants/statuses';

import { VisibilityBadge } from 'components/molecules';
import { ChallengeActions } from 'components/organisms/ChallengeList/Sections';

const ChallengeHeader = (props) => {
  const { challengeDetails = {} } = props;
  const {
    name,
    status,
    visible,
  } = challengeDetails;

  const { t } = useLocale();

  return (
    <Box sx={{
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      flexWrap: 'wrap',
    }}
    >
      <Box sx={{
        display: 'flex',
        alignItems: 'center',
        columnGap: 2,
      }}
      >
        <Box>
          <Typography variant="bodyLargeBold">
            {capitalizeFirstLetter(name)}
          </Typography>
        </Box>
        <Box>
          <Chip
            size="small"
            color={status}
            label={t(getStatusByName(status)?.localizationKey)}
          />
        </Box>
        <Box>
          <VisibilityBadge visible={visible} />
        </Box>
      </Box>
      <Box sx={{ mt: { xs: 2, md: 0 } }}>
        <ChallengeActions />
      </Box>
    </Box>
  );
};

export default ChallengeHeader;
