import { usePlanFeatures } from 'reactQuery/queries';
import { DataGrid } from 'components/core';
import useTableColumns from './useTableColumns';

const FeatureManagementTable = () => {
  const {
    data: rows = [],
    isFetching = true,
    refetch,
  } = usePlanFeatures();

  const columns = useTableColumns({ refetch });

  return (
    <DataGrid
      rows={rows}
      columns={columns}
      loading={isFetching}
      paginationMode="client"
      withSearch={false}
      hideFooterPagination
      hideFooter
    />
  );
};

export default FeatureManagementTable;
