import Box from '@mui/material/Box';

const FlagContainer = (props) => {
  const {
    containerProps = {},
    textProps = {},
    children,
  } = props;

  return (
    <Box
      {...containerProps}
      sx={{
        width: 1,
        height: 1,
        backgroundColor: 'transparency.main',
        clipPath: 'polygon(0 20%, 100% 0, 100% 100%, 0 80%)', // https://developer.mozilla.org/en-US/docs/Web/CSS/basic-shape/polygon
        ...containerProps.sx,
      }}
    >
      <Box
        {...textProps}
        sx={{
          width: 1,
          height: 1,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          wordBreak: 'break-all',
          ...textProps.sx,
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

export default FlagContainer;
