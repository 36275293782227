import { Box } from '@mui/material';
import { SuspenseWrapper } from 'components/render';
import { LeaderBoardTable } from 'components/tables';

import LeaderBoardHeader from './LeaderBoardHeader';

const LeaderBoardContainer = () => (
  <>
    <Box sx={{ mb: 10 }}>
      <LeaderBoardHeader />
    </Box>
    <Box>
      <SuspenseWrapper>
        <LeaderBoardTable />
      </SuspenseWrapper>
    </Box>
  </>
);

export default LeaderBoardContainer;
