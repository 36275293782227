import { LoadingButton } from '@mui/lab';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';

import {
  Box, Button, Card, Link, Typography,
} from '@mui/material';
import PERMISSIONS from 'assets/constants/permissions';
import { EditFileIcon } from 'assets/icons';
import { FormAvatar, FormTextField } from 'components/form';
import { useAdminTeamDetails, usePlayerTeamDetails } from 'reactQuery/queries';
import { PATHS } from 'routes';
import { updateTeam } from 'services';
import { REGEX, getDirtyObject, handleApiErrors } from 'util/helpers';
import {
  useFastForm, useLocale, usePermission, useSnackbar,
} from 'util/hooks';

const EventTeamForm = () => {
  const { t } = useLocale();
  const snack = useSnackbar();
  const navigate = useNavigate();
  const { eventId } = useParams();
  const canEditTeamAsManager = usePermission(PERMISSIONS.teamViewOneByName);

  const {
    data: adminTeamDetails = {},
    refetch: refetchAdminTeamDetails = () => {},
  } = useAdminTeamDetails({
    options: {
      enabled: canEditTeamAsManager,
    },
  });

  const {
    data: playerTeamDetails = {},
    refetch: refetchPlayerTeamDetails = () => {},
  } = usePlayerTeamDetails({
    options: {
      enabled: !canEditTeamAsManager,
    },
  });

  const teamDetails = canEditTeamAsManager
    ? adminTeamDetails
    : playerTeamDetails;
  const refetchTeamDetails = canEditTeamAsManager
    ? refetchAdminTeamDetails
    : refetchPlayerTeamDetails;

  const {
    logo = '', motto = '', id: teamId = '', name = '',
  } = teamDetails;

  const defaultValues = {
    logo: logo ?? '',
    name: name ?? '',
    motto: motto ?? '',
  };

  const validationSchema = Yup.object({
    name: Yup.string()
      .trim()
      .required(t('events.validation.enterTeamName'))
      .matches(REGEX.noWhiteSpace, t('events.validation.enterTeamName'))
      .min(3, t('events.validation.teamNameMinLength'))
      .max(32, t('events.validation.teamNameMaxLength')),
    motto: Yup.string().max(100, t('events.validation.teamMottoMaxLength')),
    logo: Yup.string().nullable(),
  });

  const {
    control,
    handleSubmit,
    setError,
    reset,
    formState: { isSubmitting, isValid, isDirty },
  } = useFastForm({
    defaultValues,
    validationSchema,
  });

  let redirectPath = `/${PATHS.events}/${eventId}`;

  if (canEditTeamAsManager) {
    redirectPath += `/teams/${teamId}`;
  }

  useEffect(() => {
    // Reset form default values based on new api response
    reset({
      logo: logo ?? '',
      motto: motto ?? '',
      name: name ?? '',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamDetails]);

  const onSubmit = async (values) => {
    try {
      const dirtyPayload = getDirtyObject({ ...values }, defaultValues);

      await updateTeam(dirtyPayload, teamId);
      await refetchTeamDetails();

      snack({
        message: t('events.teams.updatedSuccessfully'),
        severity: 'success',
      });

      navigate(redirectPath);
    } catch (error) {
      handleApiErrors({ error, setError, snack });
    }
  };

  const formFields = (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box>
        <FormAvatar
          name="logo"
          control={control}
          avatar={logo}
          entityName={name}
          disabled={isSubmitting}
        />
      </Box>
      <Box
        sx={{
          display: 'grid',
          columnGap: 12,
          rowGap: 8,
          gridTemplateColumns: {
            xs: 'repeat(1, 1fr)',
            md: 'repeat(2, 1fr)',
            lg: 'repeat(2, 304px)',
            xl: 'repeat(2, 420px)',
          },
        }}
      >
        <Box>
          <FormTextField
            name="name"
            control={control}
            label={t('events.teams.name')}
            disabled={isSubmitting}
            fullWidth
          />
        </Box>
        <Box>
          <FormTextField
            name="motto"
            control={control}
            label={t('events.teams.motto')}
            disabled={isSubmitting}
            fullWidth
          />
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          columnGap: 2,
          mt: 8,
        }}
      >
        <LoadingButton
          type="submit"
          variant="contained"
          loading={isSubmitting}
          disabled={!isValid || !isDirty || isSubmitting}
        >
          {t('common.saveChanges')}
        </LoadingButton>
        <Button component={Link} to={redirectPath} disabled={isSubmitting}>
          {t('common.cancel')}
        </Button>
      </Box>
    </form>
  );

  return (
    <Card
      sx={{
        mt: 9,
        px: { xs: 6, sm: 14 },
        py: 8,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'start',
        }}
      >
        <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
          <EditFileIcon width="48" height="44" />
        </Box>
        <Box
          sx={{
            width: 1,
            display: 'flex',
            flexDirection: 'column',
            mx: 5,
          }}
        >
          <Box sx={{ pt: 2.5 }}>
            <Typography
              variant="bodyStandardMedium"
              sx={{ color: 'text.lightGray' }}
            >
              {t('events.teams.edit')}
            </Typography>
          </Box>
          {formFields}
        </Box>
      </Box>
    </Card>
  );
};

export default EventTeamForm;
