import END_POINTS from '../../endPoints';
import METHODS from '../../methods';
import api from '../../api';

const createEventTeam = (data, options) => api(
  END_POINTS.teams,
  {
    method: METHODS.POST,
    data,
    ...options,
  },
)
  .then((res) => Promise.resolve(res))
  .catch((error) => Promise.reject(error));

export default createEventTeam;
