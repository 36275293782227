const typography = {
  // Header
  h1: {
    fontSize: '3.583rem',
    fontWeight: 700,
  },
  h2: {
    fontSize: '2.986rem',
    fontWeight: 700,
  },
  h3: {
    fontSize: '2.488rem',
    fontWeight: 700,
  },
  h4: {
    fontSize: '2.074rem',
    fontWeight: 700,
  },
  h5: {
    fontSize: '1.728rem',
    fontWeight: 700,
  },

  //  Body
  // Bold
  bodyLargeBold: {
    fontSize: '1.44rem',
    fontWeight: 700,
  },
  bodyMediumBold: {
    fontSize: '1.2rem',
    fontWeight: 700,
  },
  bodyStandardBold: {
    fontSize: '1rem',
    fontWeight: 700,
  },
  bodySmallBold: {
    fontSize: '0.833rem',
    fontWeight: 700,
  },
  bodyTinyBold: {
    fontSize: '0.694rem',
    fontWeight: 700,
  },
  // Medium
  bodyLargeMedium: {
    fontSize: '1.44rem',
    fontWeight: 600,
  },
  bodyMediumMedium: {
    fontSize: '1.2rem',
    fontWeight: 600,
  },
  bodyStandardMedium: {
    fontSize: '1rem',
    fontWeight: 600,
  },
  bodySmallMedium: {
    fontSize: '0.833rem',
    fontWeight: 600,
  },
  bodyTinyMedium: {
    fontSize: '0.694rem',
    fontWeight: 600,
  },
  // Regular
  bodyLargeRegular: {
    fontSize: '1.44rem',
    fontWeight: 400,
  },
  bodyMediumRegular: {
    fontSize: '1.2rem',
    fontWeight: 400,
  },
  bodyStandardRegular: {
    fontSize: '1rem',
    fontWeight: 400,
  },
  bodySmallRegular: {
    fontSize: '0.833rem',
    fontWeight: 400,
  },
  bodyTinyRegular: {
    fontSize: '0.694rem',
    fontWeight: 400,
  },
};

export default typography;
