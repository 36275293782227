import { useController } from 'react-hook-form';

import { CardNumberField } from 'components/molecules';
import { formatCardNumber } from 'util/helpers';

const FormCardNumberField = (props) => {
  const { field, fieldState } = useController(props);
  const { onChange } = field;
  const { error } = fieldState;

  const hasErrored = Boolean(error);

  const onFieldChange = ({ target: { value: newValue = '' } }) => {
    onChange(formatCardNumber(newValue));
  };

  return (
    <CardNumberField
      {...field}
      {...props}
      error={hasErrored}
      helperText={error?.message}
      value={formatCardNumber(String(field.value))}
      onChange={onFieldChange}
    />
  );
};

export default FormCardNumberField;
