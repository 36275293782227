import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { Loadable, SuspenseWrapper } from 'components/render';
import { lazy } from 'react';

const AsyncHero = Loadable(lazy(() => import('./sections/Hero')));
const AsyncWhat = Loadable(lazy(() => import('./sections/What')));
const AsyncWhy = Loadable(lazy(() => import('./sections/Why')));
const AsyncWho = Loadable(lazy(() => import('./sections/Who')));
const AsyncJoin = Loadable(lazy(() => import('./sections/Join')));
const AsyncGlobal = Loadable(lazy(() => import('./sections/Global')));
const AsyncChoosePlan = Loadable(lazy(() => import('./sections/ChoosePlan')));
const AsyncFooter = Loadable(lazy(() => import('./sections/Footer')));

const LandingContainer = () => (
  <Stack
    rowGap={{
      xs: 23,
      md: 33,
      lg: 43,
    }}
    justifyContent="center"
    alignItems="center"
  >
    <Box sx={{
      my: {
        xs: 10,
        lg: 35,
      },
    }}
    >
      <AsyncHero />
    </Box>
    <Box>
      <SuspenseWrapper>
        <AsyncWhat />
      </SuspenseWrapper>
    </Box>
    <Box>
      <AsyncWhy />
    </Box>
    <Box width={1}>
      <AsyncWho />
    </Box>
    <Box>
      <AsyncJoin />
    </Box>
    <Box>
      <AsyncGlobal />
    </Box>
    <Box>
      <AsyncChoosePlan />
    </Box>
    <Box width={1}>
      <AsyncFooter />
    </Box>
  </Stack>
);

export default LandingContainer;
