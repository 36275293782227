import {
  Box,
  Grid,
} from '@mui/material';

import { useLocale } from 'util/hooks';

import {
  PlansFilledIcon,
  PaperFilledIcon,
  EmptyPaperFilledIcon,
} from 'assets/icons';

import { CopyToClipboardButton } from 'components/molecules';
import ChallengeDetailsCardWrapper from './helpers/ChallengeDetailsCardWrapper';
import ChallengeFilesCard from './cards/ChallengeFilesCard';
import ChallengeWriteupFilesCard from './cards/ChallengeWriteupFilesCard';

import useChallengeInstance from './helpers/useChallengeInstance';

const ChallengeDetailsCards = (props) => {
  const {
    challengeDetails = {},
    showLockedBackdrop = false,
  } = props;

  const { t } = useLocale();

  const {
    imageName = '',
    url = '',
    hasChallengeFiles = false,
    hasWriteupFiles = false,
    isWriteUpFilesLocked = false,
  } = challengeDetails;

  // Card conditions

  const isContentDisabled = showLockedBackdrop; // Disable all content interaction
  const showInstance = Boolean(imageName);
  const showUrl = Boolean(url);
  const showChallengeFiles = hasChallengeFiles;
  const showWriteupFiles = hasWriteupFiles;
  // // if whole challenge is disabled, prevent more nested components from rendering backdrop
  // if (isContentDisabled) {
  //   isWriteUpFilesLocked = true;
  // }
  const challengeInstance = useChallengeInstance({ isContentDisabled });

  const content = [];

  if (showInstance) {
    const {
      subtitle = null,
      header = null,
      component = null,
    } = challengeInstance;

    content.push({
      key: 'instance',
      title: t('challenges.instance'),
      subtitle,
      icon: <PlansFilledIcon width="48" height="44" />,
      header,
      component,
    });
  }

  if (showUrl) {
    const maskedUrl = 'https://www.*******.com';
    const urlToCopy = isContentDisabled ? maskedUrl : url;
    content.push({
      key: 'url',
      title: t('challenges.url'),
      subtitle: t('challenges.challengeUrl'),
      icon: <PlansFilledIcon width="48" height="44" />,
      component: <CopyToClipboardButton
        textToCopy={urlToCopy}
        buttonLabel={urlToCopy}
        disabled={isContentDisabled}
      />,
    });
  }

  if (showChallengeFiles) {
    content.push({
      key: 'challenge-files',
      title: t('challenges.challengeFiles'),
      subtitle: t('challenges.challengeNeededFiles'),
      icon: <PaperFilledIcon width="48" height="44" />,
      component: <ChallengeFilesCard
        isContentDisabled={isContentDisabled}
      />
      ,
    });
  }

  if (showWriteupFiles) {
    content.push({
      key: 'writeup-files',
      title: t('challenges.writeupFiles'),
      subtitle: t('challenges.challengeWriteupFiles'),
      icon: <EmptyPaperFilledIcon width="48" height="44" />,
      component: <ChallengeWriteupFilesCard
        isWriteUpFilesLocked={!isContentDisabled && isWriteUpFilesLocked}
        isContentDisabled={isContentDisabled}
      />,
      isLocked: !isContentDisabled && isWriteUpFilesLocked,
    });
  }

  const cards = content
    .map(({ key, ...cardContent }) => (
      <Grid
        key={key}
        item
        xs
      >
        <ChallengeDetailsCardWrapper
          {...cardContent}
        />
      </Grid>
    ));

  return (
    <Box>
      <Grid container spacing={6}>
        {cards}
      </Grid>
    </Box>
  );
};

export default ChallengeDetailsCards;
