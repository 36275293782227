import {
  GridOverlay,
} from '@mui/x-data-grid';
import { useLocale } from 'util/hooks';

import { NoContentPlaceholder } from 'components/molecules';

const CustomNoRowsOverlay = (props) => {
  const { type = 'none' } = props;
  const { t } = useLocale();

  const content = {
    none: {
      desc: [
        {
          text: t('common.noData'),
          variant: 'bodyMediumBold',
        },
      ],
    },
    challenges: {
      desc: [
        {
          text: t('challenges.noChallenges'),
          variant: 'bodyMediumBold',
        },
      ],
    },
    machines: {
      desc: [
        {
          text: t('machines.noMachines'),
          variant: 'bodyMediumBold',
        },
      ],
    },
  };

  return (
    <GridOverlay sx={{ pointerEvents: 'auto' }}>
      <NoContentPlaceholder content={content[type]} />
    </GridOverlay>
  );
};

export default CustomNoRowsOverlay;
