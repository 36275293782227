import END_POINTS from 'services/endPoints';
import METHODS from 'services/methods';
import api from '../api';

const deleteAnnouncement = (id) => api(
  END_POINTS.announcement(id),
  {
    method: METHODS.DELETE,
  },
)
  .then((res) => Promise.resolve(res))
  .catch((error) => Promise.reject(error));

export default deleteAnnouncement;
