import { Chip } from '@mui/material';
import { GridActionsCellItem } from '@mui/x-data-grid';
import { getStatusByName, STATUS } from 'assets/constants/statuses';
import { getRoleById } from 'assets/constants/userRoles';
import { CrossCircleIcon, EmailReplyIcon } from 'assets/icons';
import { useLocale } from 'util/hooks';

const useTableColumns = (props) => {
  const { addModal } = props;
  const { t, dir } = useLocale();

  const columns = [
    {
      field: 'email',
      headerName: t('common.email'),
      flex: 1,
      minWidth: 180,
    },
    {
      field: 'role',
      headerName: t('common.role'),
      flex: 1,
      minWidth: 100,
      valueGetter: (params) => t(getRoleById(params?.row?.role?.id)?.localizationKey),
    },
    {
      field: 'invitationStatus',
      headerName: t('invitation.invitationStatus'),
      flex: 1,
      minWidth: 100,
      renderCell: (params) => (
        <Chip
          size="small"
          color={params.row.status || ''}
          label={t(getStatusByName(params?.row?.status)?.localizationKey) || ''}
        />
      ),
    },
    {
      field: 'actions',
      headerName: t('common.actions'),
      type: 'actions',
      flex: 1,
      minWidth: 50,
      getActions: (params) => {
        const { row: { status = '' } = {} } = params || {};
        const isExpired = status === STATUS.expired;

        const actions = [
          <GridActionsCellItem
            dir={dir}
            icon={<EmailReplyIcon width="20" height="20" />}
            label={t('resendInvitation.resendInvitation')}
            onClick={() => addModal({
              type: 'resend',
              invitation: { ...params.row },
            })}
            showInMenu
          />];

        if (!isExpired) {
          actions.push(<GridActionsCellItem
            dir={dir}
            icon={<CrossCircleIcon width="20" height="20" />}
            label={t('cancelInvitation.cancelInvitation')}
            onClick={() => addModal({
              type: 'cancel',
              invitation: { ...params.row },
            })}
            showInMenu
          />);
        }

        return actions;
      },
    },
  ];

  return columns;
};

export default useTableColumns;
