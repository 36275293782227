import { Stack } from '@mui/material';
import { usePlayerEventHistory } from 'reactQuery/queries';

import ProfileEventsHeader from './helpers/ProfileEventsHeader';
import NoEventsPlaceholder from './helpers/NoEventsPlaceholder';
import EventHistoryCard from './helpers/EventHistoryCard';

const ProfileEventsContainer = () => {
  const {
    data: events = [],
    isFetched = false,
  } = usePlayerEventHistory();

  const showNoEventsPlaceholder = isFetched && !events?.length;
  const eventList = events?.map((event) => (
    <EventHistoryCard
      key={event.id}
      event={event}
    />
  ));

  return (
    <Stack sx={{ gap: 4, pt: 6 }}>
      <ProfileEventsHeader />
      {showNoEventsPlaceholder
        ? <NoEventsPlaceholder />
        : (
          <Stack sx={{ gap: 5 }}>
            {eventList}
          </Stack>
        )}
    </Stack>
  );
};
export default ProfileEventsContainer;
