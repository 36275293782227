import { useState } from 'react';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

import {
  Box,
  Card,
  Link,
  Button,
  Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

import {
  FormAvatar,
  FormCountriesSelect,
  FormTextField,
  FormTimezoneSelect,
} from 'components/form';
import { updateUserProfile, deleteAvatar } from 'services';
import { useLocale, useFastForm, useSnackbar } from 'util/hooks';
import { getDirtyObject, REGEX } from 'util/helpers';
import { PATHS } from 'routes';

const EditUserForm = (props) => {
  const {
    user,
  } = props;

  const [userData, setUserData] = useState(user);

  const { t } = useLocale();
  const snack = useSnackbar();
  const navigate = useNavigate();

  const defaultValues = {
    avatar: userData.avatar || '',
    email: userData.email || '',
    username: userData.username || '',
    country: userData.country || '',
    timeZone: userData.timeZone || '',
  };

  const validationSchema = Yup.object({
    avatar: Yup.string(),
    email: Yup.string()
      .email(t('email.validation'))
      .matches(REGEX.mail, t('email.validation'))
      .required(t('email.enterEmail')),
    username: Yup.string()
      .matches(REGEX.alphaNumericUnderscore, t('username.validation'))
      .max(35, t('username.maxLength'))
      .min(4, t('username.minLength'))
      .required(t('username.enter')),
    country: Yup.object()
      .nullable(true)
      .required(t('country.enter')),
    timeZone: Yup.string().nullable().required(t('common.timeZoneRequired')),
  });

  const {
    control,
    handleSubmit,
    reset,
    formState: {
      isSubmitting, isDirty, isValid,
    },
  } = useFastForm({
    defaultValues,
    validationSchema,
  });

  const onSubmit = async (values) => {
    const dirtyPayload = getDirtyObject(values, defaultValues);
    const hasUsernameChanged = Boolean(dirtyPayload?.username);
    try {
      if (dirtyPayload.avatar === '') {
        // If avatar is deleted
        await deleteAvatar();
        dirtyPayload.avatar = null;
      }

      const newUserData = await updateUserProfile(dirtyPayload, userData.username);

      if (hasUsernameChanged) {
        navigate(`/${PATHS.users}/${PATHS.players}`, { replace: true });
      } else {
        setUserData(newUserData);
        reset(newUserData);
      }

      snack({
        message: t('common.profileUpdated'),
        severity: 'success',
      });
    } catch (error) {
      snack({
        message: error.errors[0].message || t('common.errorMessage'),
        severity: 'error',
      });
    }
  };

  const personalInformationForm = (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormAvatar
        name="avatar"
        control={control}
        avatar={defaultValues.avatar}
        entityName={defaultValues.username}
        disabled={isSubmitting}
      />
      <Box sx={{
        display: 'grid',
        columnGap: 12,
        rowGap: 8,
        gridTemplateColumns: {
          xs: 'repeat(1, 1fr)',
          md: 'repeat(2, 1fr)',
          lg: 'repeat(2, 304px)',
          xl: 'repeat(2, 420px)',
        },
      }}
      >
        <Box>
          <FormTextField
            name="email"
            label={t('common.email')}
            sx={{ m: 0 }}
            control={control}
            autoComplete="email"
            disabled={isSubmitting}
            fullWidth
          />
        </Box>
        <Box>
          <FormTextField
            name="username"
            label={t('common.username')}
            sx={{ m: 0 }}
            control={control}
            autoComplete="username"
            disabled={isSubmitting}
            fullWidth
          />
        </Box>
        <Box>
          <FormCountriesSelect
            name="country"
            control={control}
            label={t('country.label')}
            placeholder={t('country.enter')}
            disabled={isSubmitting}
            fullWidth
          />
        </Box>
        <Box>
          <FormTimezoneSelect
            autoCompleteProps={{
              disabled: true,
            }}
            name="timeZone"
            control={control}
            type="text"
            label={t('common.timezone')}
            textFieldProps={{
              placeholder: t('common.timeZoneRequired'),
              fullWidth: true,
            }}
          />
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          columnGap: 2,
          mt: 8,
        }}
      >
        <LoadingButton
          type="submit"
          variant="contained"
          size="medium"
          disabled={!isDirty || isSubmitting || !isValid}
          loading={isSubmitting}
        >
          {t('common.saveChanges')}
        </LoadingButton>
        <Button
          component={Link}
          to={`/${PATHS.users}/${PATHS.players}`}
          disabled={isSubmitting}
        >
          {t('common.cancel')}
        </Button>
      </Box>
    </form>
  );

  return (
    <Card
      id="personal-information-form"
      sx={{
        mt: 9,
        px: { xs: 6, sm: 14 },
        py: 8,
      }}
    >
      <Typography
        variant="bodyStandardMedium"
        sx={{ color: 'text.secondary' }}
      >
        {t('common.personalInfo')}
      </Typography>
      {personalInformationForm}
    </Card>
  );
};

EditUserForm.propTypes = {
  user: PropTypes.oneOfType([PropTypes.object]),
};

EditUserForm.defaultProps = {
  user: {},
};

export default EditUserForm;
