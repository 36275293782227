import { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { useLocale } from 'util/hooks';
import { REGEX } from 'util/helpers';
import { useTheme } from '@mui/styles';

const PasswordHelper = (props) => {
  const {
    password = '',
  } = props;

  const { palette } = useTheme();
  const { t } = useLocale();

  const [validPassword, setValidPassword] = useState({
    isNumeric: false,
    isLowercase: false,
    isUppercase: false,
    isSpecialCharacter: false,
    isLong: false,
  });

  const {
    isNumeric,
    isLowercase,
    isUppercase,
    isSpecialCharacter,
    isLong,
  } = validPassword;

  useEffect(() => {
    const checkPasswordValidity = () => {
      const validState = {
        isNumeric: REGEX.numbers.test(password),
        isLowercase: REGEX.lowercase.test(password),
        isUppercase: REGEX.uppercase.test(password),
        isSpecialCharacter: REGEX.specialCharacter.test(password),
        isLong: password.length >= 8,
      };

      setValidPassword(validState);
    };

    checkPasswordValidity();
  }, [password]);

  const failStyle = { color: palette.error.main };
  const successStyle = { color: palette.success.main };
  const getStyle = (isValid) => (isValid ? successStyle : failStyle);

  return (
    <Box sx={{ mt: 2 }}>
      <Typography variant="bodySmallRegular">
        {t('password.passwordAtLeast')}
        &nbsp;
        <span style={getStyle(isLong)}>
          {t('password.numberOfCharacters')}
          &nbsp;
        </span>
        {t('password.contain')}
        &nbsp;
        <span style={getStyle(isUppercase)}>
          {t('password.uppercase')}
        </span>
        {t('common.comma')}
        &nbsp;
        <span style={getStyle(isLowercase)}>
          {t('password.lowercase')}
        </span>
        {t('common.comma')}
        &nbsp;
        <span style={getStyle(isNumeric)}>
          {t('password.number')}
          &nbsp;
        </span>
        {t('common.and')}
        &nbsp;
        <span style={getStyle(isSpecialCharacter)}>
          {t('password.symbol')}
        </span>
        .
      </Typography>
    </Box>
  );
};

export default PasswordHelper;
