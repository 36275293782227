import { useLocale, useAuth, usePermission } from 'util/hooks';

import { Chip, Link } from '@mui/material';
import { GridActionsCellItem } from '@mui/x-data-grid';

import {
  FileReturnIcon,
  OpenEyeIcon,
  FileCheckIcon,
  FileEditIcon,
} from 'assets/icons';
import { getStatusByName, STATUS } from 'assets/constants/statuses';

import { PATHS } from 'routes';
import { numberWithCommas } from 'util/helpers';
import PERMISSIONS from 'assets/constants/permissions';

const useTableColumns = (props = {}) => {
  const { addModal = () => { } } = props;

  const { currentUser } = useAuth();
  const { t, dir, isAr } = useLocale();

  // Action permissions
  const canEdit = usePermission(PERMISSIONS.planUpdate);
  const canDraft = usePermission(PERMISSIONS.planDraft);
  const canPublish = usePermission(PERMISSIONS.planPublish);
  const canEditPending = usePermission(PERMISSIONS.planUpdatePending);

  const columns = [
    {
      field: 'name',
      headerName: t('common.name'),
      sortable: false,
      flex: 1,
      minWidth: 170,
      valueGetter: ({ row: { nameAr, nameEn } }) => (isAr ? nameAr : nameEn),
    },
    {
      field: 'pricePerMonth',
      headerName: t('plans.pricePerMonth'),
      flex: 1,
      minWidth: 130,
      valueGetter: ({ row: { pricePerMonth } }) => `${t('currency.sar')} ${numberWithCommas(pricePerMonth || 0)}`,
    },
    {
      field: 'pricePerYear',
      headerName: t('plans.pricePerYear'),
      flex: 1,
      minWidth: 130,
      valueGetter: ({ row: { pricePerYear } }) => `${t('currency.sar')} ${numberWithCommas(pricePerYear || 0)}`,
    },
    {
      field: 'createdBy',
      headerName: t('common.createdBy'),
      flex: 1,
      minWidth: 100,
    },
    {
      field: 'status',
      headerName: t('common.status'),
      flex: 1,
      minWidth: 100,
      renderCell: (params) => (
        <Chip
          size="small"
          color={params.row.status || ''}
          label={t(getStatusByName(params?.row?.status)?.localizationKey) || ''}
        />
      ),
    },
    {
      field: 'actions',
      headerName: t('common.actions'),
      type: 'actions',
      flex: 1,
      minWidth: 50,
      getActions: (params) => {
        const { row: { status, id, createdBy } } = params;

        const isPublished = status === STATUS.published;
        const isPending = status === STATUS.pending;
        const isOwnPlan = createdBy === currentUser.username;

        // Actions
        const showPublishButton = !isPublished
          && canPublish;
        const showEditButton = !isPublished
          && (canEditPending
            || (canEdit && isOwnPlan && !isPending)); // for account manager
        const showReturnToDraftButton = isPublished
          && canDraft;

        const actions = [
          <GridActionsCellItem
            component={Link}
            dir={dir}
            icon={<OpenEyeIcon width="20" height="20" />}
            label={t('plans.view')}
            to={`/${PATHS.plans}/${id}`}
            showInMenu
          />,
        ];

        if (showReturnToDraftButton) {
          actions.push(<GridActionsCellItem
            dir={dir}
            icon={<FileReturnIcon width="20" height="20" />}
            label={t('plans.returnToDraft')}
            onClick={() => addModal({ type: 'draft', plan: { ...params.row } })}
            showInMenu
          />);
        }
        if (showPublishButton) {
          actions.push(
            <GridActionsCellItem
              dir={dir}
              icon={<FileCheckIcon width="20" height="20" />}
              label={t('plans.publish')}
              onClick={() => addModal({ type: 'publish', plan: { ...params.row } })}
              showInMenu
            />,
          );
        }
        if (showEditButton) {
          actions.push(
            <GridActionsCellItem
              component={Link}
              dir={dir}
              icon={<FileEditIcon width="20" height="20" />}
              label={t('plans.edit')}
              to={`/${PATHS.plans}/${id}/edit`}
              showInMenu
            />,
          );
        }

        return actions;
      },
    },
  ];

  return columns;
};

export default useTableColumns;
