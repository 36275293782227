import {
  Box,
  LinearProgress,
  linearProgressClasses,
  Tooltip,
  Typography,
} from '@mui/material';

import { useLocale } from 'util/hooks';
import { getPlayerLevelByOrder, PLAYER_LEVELS } from 'assets/constants/playerLevels';

const progressStyle = {
  width: { xs: 19, md: 29 },
  height: { xs: 8, md: 11 },
  borderRadius: 100,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: 'text.darkGray',
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: 'primary.main',
  },
};

const PlayerLinearLevelProgress = (props) => {
  const { progressDetails = {} } = props;
  const {
    playerLevel = {},
    totalPoints = 0,
  } = progressDetails || {};

  const { t, isAr } = useLocale();

  const getPercentageForLevel = (level) => {
    let result;
    const levelMinPoints = PLAYER_LEVELS[level]?.minPoints;
    const levelMaxPoints = PLAYER_LEVELS[level]?.maxPoints;

    if (!totalPoints || totalPoints <= levelMinPoints) {
      result = 0;
    } else if (totalPoints > levelMaxPoints) {
      result = 100;
    } else {
      const diff = levelMaxPoints - levelMinPoints;
      result = 100 * (totalPoints - levelMinPoints) / diff;
    }

    return Math.round(result);
  };

  const levelProgressChart = Object.keys(PLAYER_LEVELS)
    .map((level) => {
      const key = `level-progress-${level}`;
      const levelPoints = getPercentageForLevel(level);

      const levelObj = PLAYER_LEVELS[level];
      const tooltipTitle = `${levelPoints}% ${isAr ? levelObj?.nameAr : levelObj?.nameEn}`;

      return (
        <Box key={key}>
          <Tooltip title={tooltipTitle}>
            <LinearProgress
              variant="determinate"
              value={levelPoints}
              sx={progressStyle}
            />
          </Tooltip>
        </Box>
      );
    });

  const renderLevelProgressTitle = () => {
    const isTopLevel = PLAYER_LEVELS[playerLevel.nameKey]?.isTopLevel;
    const currentLevelPercentage = getPercentageForLevel(playerLevel.nameKey);
    const hasCompletedLevels = isTopLevel && currentLevelPercentage === 100;

    if (hasCompletedLevels) {
      return (
        <Typography variant="bodyStandardBold">
          {`${t('profile.topLevel')} "${isAr ? playerLevel?.nameAr : playerLevel?.nameEn}"`}
        </Typography>
      );
    }

    const towardsNextLevelPercentage = (100 - currentLevelPercentage);
    const nextLevel = getPlayerLevelByOrder(playerLevel.order + 1);
    const nextLevelName = isAr
      ? nextLevel?.nameAr
      : nextLevel?.nameEn;

    return (
      <Typography variant="bodyStandardRegular">
        {`${towardsNextLevelPercentage}% ${t('common.toBe')} `}
        <Typography variant="bodyStandardBold">
          {nextLevelName}
        </Typography>
      </Typography>
    );
  };

  return (
    <>
      <Box sx={{ mb: 2, textAlign: 'center' }}>
        {renderLevelProgressTitle()}
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2 }}>
        {levelProgressChart}
      </Box>
    </>
  );
};

export default PlayerLinearLevelProgress;
