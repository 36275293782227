import { useTheme } from '@mui/styles';

const BloodDropIcon = (props) => {
  const theme = useTheme();
  const {
    stroke = theme.palette.text.red,
    fill = 'none',
    ...rest
  } = props;

  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill={fill} xmlns="http://www.w3.org/2000/svg" {...rest}>
      <path d="M30.834 26.9122C30.834 29.8959 29.6487 32.7574 27.5389 34.8672C25.4292 36.9769 22.5677 38.1622 19.584 38.1622C16.6003 38.1622 13.7388 36.9769 11.629 34.8672C9.51925 32.7574 8.33398 29.8959 8.33398 26.9122C8.33398 18.7955 15.7473 6.55887 18.5373 2.24554C18.6495 2.0697 18.8043 1.92496 18.9872 1.8247C19.1701 1.72443 19.3754 1.67188 19.584 1.67188C19.7926 1.67187 19.9978 1.72443 20.1808 1.8247C20.3637 1.92496 20.5184 2.0697 20.6307 2.24554C23.4207 6.55887 30.834 18.7955 30.834 26.9122Z" stroke={stroke} strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M19.584 33.1691C18.7628 33.1691 17.9497 33.0072 17.1911 32.6928C16.4324 32.3784 15.7432 31.9176 15.1628 31.3367C14.5824 30.7558 14.1221 30.0662 13.8083 29.3074C13.4945 28.5485 13.3333 27.7352 13.334 26.9141" stroke={stroke} strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default BloodDropIcon;
