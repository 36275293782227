import PropTypes from 'prop-types';

import {
  Box,
  Grid,
  Typography,
} from '@mui/material';

import { useIsEvent, useLocale } from 'util/hooks';
import { Tag, UploadedFile } from 'components/molecules';

import FILE_TYPES from 'assets/constants/fileTypes';
import { useEventDetails } from 'reactQuery/queries';

const ChallengeDetailsWrapper = (props) => {
  const { challengeDetails } = props;

  const { t, isAr } = useLocale();

  const isEventChallenge = useIsEvent();

  const {
    data: eventDetails = {},
  } = useEventDetails();

  const {
    isDynamicPoints = false,
  } = eventDetails;

  const {
    name = '',
    description = '',
    author = '',
    createdBy = '',
    points = 0,
    url = '',
    category = '',
    correctFlag = '',
    imageName = '',
    protocol = '',
    internalPort = '',
    isDynamicFlag = false,
    tags = [],
    files = [],
    publishedAt = '',
    decay = 0,
    maxPoints = 0,
    minPoints = 0,
  } = challengeDetails;

  const challengesFiles = files?.filter((f) => f.fileType === FILE_TYPES.challengeFiles);
  const writeUpFiles = isEventChallenge
    ? []
    : files?.filter((f) => f.fileType === FILE_TYPES.writeUpFiles);

  const dynamicPointsDetails = [
    {
      id: 'max-points',
      title: t('common.maximum'),
      value: maxPoints,
    },
    {
      id: 'min-points',
      title: t('common.minimum'),
      value: minPoints,
    },
    {
      id: 'decay',
      title: t('common.decay'),
      value: decay,
    },
  ];

  const renderDynamicPointsDetails = () => (
    <Box gap={3}>
      {dynamicPointsDetails.map(({ id, title, value }) => (
        <Box key={id} display="flex" gap={2}>
          <Typography variant="bodyStandardMedium">
            {title}
          </Typography>
          <Typography variant="bodyStandardMedium">
            {value}
          </Typography>
        </Box>
      ))}
    </Box>
  );

  const renderFileListOrNone = (fileList) => (
    fileList?.length !== 0
      ? (fileList?.map((file) => (
        <UploadedFile
          key={file.fileId}
          url={file.fileId}
          name={file.fileName}
          canDelete={false}
          {...file}
        />
      )))
      : t('common.none'));

  const items = [
    {
      key: 'name',
      title: t('challenges.name'),
      component: name,
    },
    {
      key: 'description',
      title: t('common.description'),
      component: (
        <Box whiteSpace="pre-wrap">
          {description}
        </Box>
      ),
      containerProps: {
        md: 8,
        lg: 8,
      },
    },
    {
      key: 'author',
      title: t('challenges.author'),
      component: author,
    },
    {
      key: 'createdBy',
      title: t('common.createdBy'),
      component: createdBy,
    },
    {
      key: 'points',
      title: `${t('challenges.points')} ${isDynamicPoints ? `(${t('challenges.dynamic')})` : ''}`,
      component: isDynamicPoints
        ? renderDynamicPointsDetails()
        : points,
    },
    {
      key: 'url',
      title: t('challenges.url'),
      component: url || t('common.none'),
      componentProps: {
        sx: {
          overflowWrap: 'break-word',
          wordWrap: 'break-word',
        },
      },
    },
    {
      key: 'correctFlag',
      title: t('challenges.correctFlag'),
      component: correctFlag || t(isDynamicFlag ? 'challenges.dynamic' : 'common.none'),
    },
    {
      key: 'category',
      title: t('challenges.category'),
      component: isAr ? category?.nameAr : category?.nameEn || t('common.none'),
    }, {
      key: 'imageName',
      title: t('challenges.imageName'),
      component: imageName || t('common.none'),
      componentProps: {
        sx: {
          overflowWrap: 'break-word',
          wordWrap: 'break-word',
        },
      },
    },
    {
      key: 'protocol',
      title: t('challenges.protocol'),
      component: protocol || t('common.none'),
    },
    {
      key: 'internalPort',
      title: t('challenges.internalPort'),
      component: internalPort || t('common.none'),
      componentProps: {
        sx: {
          overflowWrap: 'break-word',
          wordWrap: 'break-word',
        },
      },
    },
    {
      key: 'tags',
      title: t('challenges.tags'),
      component: tags?.length ? tags?.map((tag) => (
        <Tag
          key={tag}
          label={tag}
          sx={{ mr: 2, mt: 2 }}
        />
      )) : t('common.none'),
    },
    {
      key: 'challengeFiles',
      title: t('challenges.challengeFiles'),
      component: renderFileListOrNone(challengesFiles),
    },
    {
      key: 'writeUpFiles',
      title: t('challenges.writeupFiles'),
      component: renderFileListOrNone(writeUpFiles),
      hide: isEventChallenge,
    },
    {
      key: 'publishedAt',
      title: t('common.publishedAt'),
      component: publishedAt
        ? (new Date(publishedAt)
          .toLocaleString(
            'en-US',
            {
              year: 'numeric',
              month: 'short',
              day: 'numeric',
              hour: '2-digit',
              minute: '2-digit',
            },
          ))
        : t('common.notPublished'),
    },
  ];

  return (
    <Box>
      <Grid container rowSpacing={15} columnSpacing={5}>
        {items
          ?.filter((item) => !item.hide)
          ?.map(({
            key,
            title,
            component,
            containerProps,
            componentProps,
          }) => (
            <Grid key={key} item sm={12} md={6} lg={4} {...containerProps}>
              <Typography
                variant="bodyStandardBold"
                color="text.secondary"
              >
                {title}
              </Typography>
              <Box>
                {typeof component === 'string' || typeof component === 'number'
                  ? (
                    <Typography
                      variant="bodyMediumMedium"
                      color="text.lighterGray"
                      {...componentProps}
                    >
                      {component}
                    </Typography>
                  )
                  : component}
              </Box>
            </Grid>
          ))}
      </Grid>
    </Box>
  );
};

ChallengeDetailsWrapper.propTypes = {
  challengeDetails: PropTypes.oneOfType([PropTypes.object]),
};

ChallengeDetailsWrapper.defaultProps = {
  challengeDetails: {},
};

export default ChallengeDetailsWrapper;
