import { Stack } from '@mui/material';
import AnnouncementInfo from './sections/AnnouncementInfo';
import AnnouncementHeader from './sections/AnnouncementHeader';

const ViewAnnouncementContainer = (props) => {
  const {
    announcementDetails = {},
    refetchAnnouncementDetails = () => {},
  } = props;

  return (
    <Stack>
      <AnnouncementHeader
        announcementDetails={announcementDetails}
        refetchAnnouncementDetails={refetchAnnouncementDetails}
      />
      <AnnouncementInfo announcementDetails={announcementDetails} />
    </Stack>
  );
};

export default ViewAnnouncementContainer;
