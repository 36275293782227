import { createTheme } from '@mui/material/styles';
import typography from './typography';
import components from './components';
import overrides from './overrides';
import palette from './palette';

const baseTheme = {
  spacing: 4,
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
      xxl: 2000,
      xxxl: 2500,
    },
  },
  shape: {
    borderRadius: 6,
  },
  overrides,
  palette,
  typography: {
    ...typography,
    fontFamily: 'IBM Plex Sans,  sans-serif',
  },
};

baseTheme.components = components(baseTheme);

const theme = createTheme({
  ...baseTheme,
  direction: 'ltr',
});

const rtlTheme = createTheme({
  ...baseTheme,
  direction: 'rtl',
  typography: {
    ...baseTheme.typography,
    fontFamily: 'IBM Plex Sans Arabic, sans-serif',
  },
});

export {
  theme,
  rtlTheme,
};
