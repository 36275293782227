import { memo } from 'react';
import PropTypes from 'prop-types';
import { useController } from 'react-hook-form';

import {
  FormControl,
  FormHelperText,
  Typography,
} from '@mui/material';
import { CountriesSelect } from 'components/molecules';

const FormCountriesSelect = (props) => {
  const {
    label,
    labelProps,
    ...rest
  } = props;

  const { field, fieldState } = useController(rest);
  const { onChange } = field;
  const { error } = fieldState;

  const hasErrored = Boolean(error);

  const onFieldChange = (event, newValue) => {
    onChange(newValue || '');
  };

  return (
    <FormControl
      sx={{
        width: 1,
        minHeight: '103px', // to match text field height
      }}
      error={hasErrored}
    >
      <Typography
        variant="bodyStandardRegular"
        sx={{ px: 2, color: 'text.secondary' }}
        {...labelProps}
      >
        {label}
      </Typography>
      <CountriesSelect
        {...field}
        onChange={onFieldChange}
        error={hasErrored}
        {...rest}
      />
      {hasErrored && <FormHelperText>{error?.message}</FormHelperText>}
    </FormControl>
  );
};

FormCountriesSelect.propTypes = {
  label: PropTypes.string,
  labelProps: PropTypes.oneOfType([PropTypes.object]),
};

FormCountriesSelect.defaultProps = {
  label: '',
  labelProps: {},
};

export default memo(FormCountriesSelect);
