import api from 'services/api';
import END_POINTS from 'services/endPoints';

const getLatestChallengesMachines = (options) => api(
  END_POINTS.latestChallengesMachines,
  options,
)
  .then((res) => Promise.resolve(res))
  .catch((error) => Promise.reject(error));

export default getLatestChallengesMachines;
