import { Chip } from '@mui/material';

const Tag = (props) => {
  const { sx, ...rest } = props;

  const styles = {
    maxWidth: 150,
    borderRadius: 5,
    color: 'text.primary',
    backgroundColor: 'paper.hover',
    '& .MuiChip-deleteIcon': {
      color: 'text.darkGray',
      '&:hover': {
        color: 'text.secondary',
      },
    },
    ...sx,
  };

  return (
    <Chip
      {...rest}
      sx={styles}
    />
  );
};

export default Tag;
