const LEVELS = {
  noob: 'noob',
  amateur: 'amateur',
  hacker: 'hacker',
  elite: 'elite',
  expert: 'expert',
  master: 'master',
  guru: 'guru',
};

export const PLAYER_LEVELS = {
  [LEVELS.noob]: {
    nameKey: LEVELS.noob,
    order: 1,
    minPoints: 0,
    maxPoints: 299,
    nameEn: 'Noob',
    nameAr: 'مبتدئ',
  },
  [LEVELS.amateur]: {
    nameKey: LEVELS.amateur,
    order: 2,
    minPoints: 300,
    maxPoints: 899,
    nameEn: 'Amateur',
    nameAr: 'مختَرِق هاوٍ',
  },
  [LEVELS.hacker]: {
    nameKey: LEVELS.hacker,
    order: 3,
    minPoints: 900,
    maxPoints: 1699,
    nameEn: 'Hacker',
    nameAr: 'مختَرِق مبتدئ',
  },
  [LEVELS.elite]: {
    nameKey: LEVELS.elite,
    order: 4,
    minPoints: 1700,
    maxPoints: 2699,
    nameEn: 'Elite Hacker',
    nameAr: 'مختَرِق ذو خبرة',
  },
  [LEVELS.expert]: {
    nameKey: LEVELS.expert,
    order: 5,
    minPoints: 2700,
    maxPoints: 3899,
    nameEn: 'Expert',
    nameAr: 'متمرّس',
  },
  [LEVELS.master]: {
    nameKey: LEVELS.master,
    order: 6,
    minPoints: 3900,
    maxPoints: 5399,
    nameEn: 'Master',
    nameAr: 'خبير',
  },
  [LEVELS.guru]: {
    nameKey: LEVELS.guru,
    order: 7,
    minPoints: 5400,
    maxPoints: null,
    nameEn: 'Guru',
    nameAr: 'محترف',
    isTopLevel: true,
  },
};

export const getPlayerLevelByOrder = (order) => {
  const found = Object.keys(PLAYER_LEVELS)
    .find((level) => PLAYER_LEVELS[level].order === Number(order));

  return found ? PLAYER_LEVELS[found] : {};
};
export const getPlayerLevelByKey = (key, value) => {
  const found = Object.keys(PLAYER_LEVELS)
    .find((level) => PLAYER_LEVELS[level][key] === value);

  return found ? PLAYER_LEVELS[found] : {};
};
