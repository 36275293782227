import { useNavigate } from 'react-router-dom';

import { DataGrid } from 'components/core';
import { useEvents, useExportEventReport } from 'reactQuery/queries';
import { handleApiErrors } from 'util/helpers';
import { usePermission, useSnackbar } from 'util/hooks';
import PERMISSIONS from 'assets/constants/permissions';
import useTableColumns from './useTableColumns';

const EventTable = () => {
  const {
    data = {},
    isFetching = true,
  } = useEvents();

  const snack = useSnackbar();

  const { mutate } = useExportEventReport({
    onError: (error) => {
      handleApiErrors({ error, snack });
    },
  });

  const canExport = usePermission(PERMISSIONS.eventExportReport);
  const columns = useTableColumns({ canExport, mutate });
  const navigate = useNavigate();

  const {
    items: { all: rows = [] } = {},
    meta = {},
  } = data || {};

  return (
    <DataGrid
      rows={rows}
      columns={columns}
      loading={isFetching}
      pagination={meta}
      getRowId={(row) => row.name}
      onRowClick={({ row }) => {
        const { id: eventId = '' } = row || {};
        navigate(eventId);
      }}
      componentsProps={{
        noRowsOverlay: { type: 'event' },
      }}
      sx={{
        '& .MuiDataGrid-row:hover': {
          cursor: 'pointer',
        },
      }}
    />
  );
};

export default EventTable;
