import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Typography,
} from '@mui/material';

const ChallengeSectionWrapper = (props) => {
  const {
    icon,
    title,
    showAction,
    actionLabel,
    onClickAction,
    children,
    ...rest
  } = props;

  return (
    <Box {...rest}>
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
      }}
      >
        <Box sx={{
          display: 'flex',
          alignItems: {
            xs: 'start',
            md: 'center',
          },
          justifyContent: 'space-between',
          flexDirection: { xs: 'column', md: 'row' },
          mb: 5,
        }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{
              width: 48,
              height: 44,
              mr: 4,
              p: 0,
            }}
            >
              {icon}
            </Box>
            <Box>
              <Typography variant="bodyStandardMedium" color="text.lightGray">
                {title}
              </Typography>
            </Box>
          </Box>
          {showAction && (
            <Box sx={{ mt: { xs: 3, md: 0 } }}>
              <Button
                size="small"
                color="secondary"
                variant="outlined"
                onClick={onClickAction}
              >
                {actionLabel}
              </Button>
            </Box>
          )}
        </Box>
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          flexWrap: 'wrap',
          ml: { md: 16 },
        }}
        >
          {children}
        </Box>
      </Box>
    </Box>
  );
};

ChallengeSectionWrapper.propTypes = {
  icon: PropTypes.node,
  title: PropTypes.string,
  actionLabel: PropTypes.string,
  showAction: PropTypes.bool,
  onClickAction: PropTypes.func,
  children: PropTypes.node,
};

ChallengeSectionWrapper.defaultProps = {
  icon: null,
  title: '',
  actionLabel: '',
  showAction: false,
  onClickAction: () => { },
  children: null,
};

export default ChallengeSectionWrapper;
