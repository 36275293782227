import END_POINTS from 'services/endPoints';
import api from '../api';

const getAllUsers = (options) => api(
  END_POINTS.users,
  options,
)
  .then((res) => Promise.resolve(res))
  .catch((error) => Promise.reject(error));

export default getAllUsers;
