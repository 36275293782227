import { useTheme } from '@mui/styles';

const PlansFilledIcon = (props) => {
  const theme = useTheme();
  const {
    stroke = theme.palette.text.secondary,
    fill = theme.palette.paper.hover,
    ...rest
  } = props;

  return (
    <svg width="48" height="44" viewBox="0 0 48 44" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <rect width="48" height="44" rx="6.66746" fill={fill} />
      <g clipPath="url(#clip0_2137_30119)">
        <path d="M28.8115 32.3125H16.4365C16.0719 32.3125 15.7221 32.1676 15.4643 31.9098C15.2064 31.6519 15.0615 31.3022 15.0615 30.9375V15.8125" stroke={stroke} strokeWidth="1.66687" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M32.0218 11.6875H18.7301C18.2239 11.6875 17.8135 12.0979 17.8135 12.6042V28.6458C17.8135 29.1521 18.2239 29.5625 18.7301 29.5625H32.0218C32.5281 29.5625 32.9385 29.1521 32.9385 28.6458V12.6042C32.9385 12.0979 32.5281 11.6875 32.0218 11.6875Z" stroke={stroke} strokeWidth="1.66687" strokeLinecap="round" strokeLinejoin="round" />
      </g>
      <defs>
        <clipPath id="clip0_2137_30119">
          <rect width="22" height="22" fill="white" transform="translate(13 11)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default PlansFilledIcon;
