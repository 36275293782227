import _ from 'lodash';
import END_POINTS from 'services/endPoints';
import METHODS from 'services/methods';
import api from '../api';

const updateChallengeStatus = (labId, challengeId, status, data) => api(
  END_POINTS.updateChallengeStatus(labId, challengeId, _.kebabCase(status)),
  {
    method: METHODS.PATCH,
    data,
  },
)
  .then((res) => Promise.resolve(res))
  .catch((error) => Promise.reject(error));

export default updateChallengeStatus;
