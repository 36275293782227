import api from 'services/api';
import END_POINTS from 'services/endPoints';
import METHODS from 'services/methods';

const startEventChallengeInstance = (
  eventId,
  challengeId,
  options,
) => api(END_POINTS.eventChallengeInstance(eventId, challengeId), {
  method: METHODS.POST,
  ...options,
})
  .then((res) => Promise.resolve(res))
  .catch((error) => Promise.reject(error));

export default startEventChallengeInstance;
