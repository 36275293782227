import { getLatestActivity } from 'services';
import { useCustomQuery } from 'reactQuery';
import { transformSubmission } from 'util/transformers';

const transformLatestActivityData = (data) => (
  data?.map((transformedActivity) => transformSubmission(transformedActivity))
);

const useDashboardLatestActivity = (queryProps = {}) => {
  const query = useCustomQuery({
    queryKey: ['dashboard-latest-activity'],
    queryFn: getLatestActivity,
    ...queryProps,
    options: {
      select: transformLatestActivityData,
      ...queryProps.options,
    },
  });

  return { ...query };
};

export default useDashboardLatestActivity;
