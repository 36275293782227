import {
  Box,
  Button,
  Card,
  CardMedia,
  Chip,
  Divider,
  Grid,
  Link,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/styles';

import { PATHS } from 'routes';
import {
  CalendarIcon,
  LockCircleIcon,
  UsersIcon,
} from 'assets/icons';
import {
  useDate,
  useLocale,
  useModal,
} from 'util/hooks';
import { CountdownBadge, StatusBadge } from 'components/molecules';
import MODAL_KEYS from 'assets/constants/modalKeys';

const CurrentEventCard = (props) => {
  const {
    eventDetails = {},
    interactive = true, // when true, makes card clickable and shows CTA button
  } = props;

  const {
    id = '',
    name = '',
    image = '',
    eventStartAt = '',
    eventEndAt = '',
    description = '',
    isLive = false,
    isDynamicPoints = false,
    isComingSoon = false,
    isRegistered = false,
    isPrivate = false,
    eventHasEnded,
    eventHasStarted,
    isRegistrationPeriod,
    teamMinCapacity: min,
    teamMaxCapacity: max,
    isExactCapacity,
    registrationHasStarted,
  } = eventDetails;

  const { t } = useLocale();
  const { palette } = useTheme();
  const { addModal } = useModal();
  const { format } = useDate();

  const disableRegistration = !isRegistrationPeriod || isRegistered;
  const startDateString = eventHasStarted ? t('common.started') : t('common.starts');
  const endDateString = eventHasEnded ? t('common.ended') : t('common.ends');
  const getRegistrationString = () => {
    if (!registrationHasStarted) {
      return t('events.openSoon');
    }

    if (isRegistered) {
      return t('events.registered');
    }

    if (!isRegistrationPeriod) {
      return t('events.registrationClosed');
    }

    return t('events.registerNow');
  };

  const handleRegistration = (e) => {
    // Prevent click event from propagation to the card link
    e.stopPropagation();
    e.preventDefault();

    addModal({
      key: MODAL_KEYS.eventRegistration,
      props: { eventDetails },
    });
  };

  return (
    <Card
      key={id}
      sx={{ p: 8 }}
    >
      <Box
        component={interactive ? Link : 'div'}
        to={`/${PATHS.events}/${id}`}
        sx={{
          color: 'text.primary',
          fontWeight: 'normal', // reverses the effect of Link styling
        }}
      >
        <Grid
          container
          spacing={6}
        >
          <Grid
            item
            xs={12}
            sm={6}
            lg={3}
          >
            <CardMedia
              sx={{ height: 200, borderRadius: 1.5 }}
              image={image}
              title={name}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            lg={9}
          >
            <Stack sx={{
              height: 1,
              justifyContent: 'space-between',
            }}
            >
              <Box>
                <Box sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  flexWrap: 'wrap',
                }}
                >
                  <Stack
                    gap={3}
                    direction="row"
                    alignItems="center"
                    flexWrap="wrap"
                  >
                    <Typography variant="bodyMediumMedium">
                      {name}
                    </Typography>
                    {isDynamicPoints && (
                    <Tooltip
                      title={t('common.dynamicPointMessage')}
                      placement="bottom"
                    >
                      <Chip
                        size="small"
                        color="warning"
                        variant="outlined"
                        label={(t('challenges.dynamic'))}
                        sx={{ borderRadius: 2 }}
                      />
                    </Tooltip>
                    )}
                    {isLive && (
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <StatusBadge status="live" />
                        <Typography variant="bodySmallRegular" sx={{ ml: 1 }}>
                          {t('statuses.live')}
                        </Typography>
                      </Box>
                    )}
                    {isComingSoon && (
                      <Box>
                        <CountdownBadge countdownFrom={eventStartAt} />
                      </Box>
                    )}
                  </Stack>
                  {isPrivate && (
                    <Box>
                      <Tooltip title={t('events.onlyInvited')} placement="top">
                        <Chip
                          label={(
                            <Box sx={{
                              display: 'flex',
                              alignItems: 'center',
                            }}
                            >
                              <LockCircleIcon width="15" height="15" />
                              <Typography variant="bodySmallRegular" sx={{ ml: 2 }}>
                                {t('events.privateEvent')}
                              </Typography>
                            </Box>
              )}
                          sx={{
                            borderRadius: 50,
                            backgroundColor: 'transparency.main',
                          }}
                        />
                      </Tooltip>
                    </Box>
                  )}
                </Box>
                <Box sx={{
                  my: 2,
                  gap: 5,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  flexWrap: 'wrap',
                }}
                >
                  <Box sx={{ maxWidth: 560 }}>
                    <Typography
                      variant="bodySmallRegular"
                      color="text.secondary"
                      whiteSpace="pre-wrap"
                      sx={{ overflowWrap: 'anywhere' }}
                    >
                      {description}
                    </Typography>
                  </Box>
                  {interactive && (
                    <Button
                      variant="contained"
                      onClick={handleRegistration}
                      disabled={disableRegistration}
                    >
                      {getRegistrationString()}
                    </Button>
                  )}
                </Box>
              </Box>
              <Box>
                <Divider sx={{ my: 3 }} />
                <Box sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  flexWrap: 'wrap-reverse',
                  gap: 2,
                }}
                >
                  <Stack
                    rowGap={2}
                    columnGap={8}
                    direction="row"
                    alignItems="center"
                    flexWrap="wrap"
                  >
                    <Stack
                      rowGap={2}
                      columnGap={2}
                      direction="row"
                      alignItems="center"
                    >
                      <CalendarIcon
                        width="16"
                        height="16"
                        stroke={palette.text.blue}
                      />
                      <Typography
                        variant="bodySmallRegular"
                        color="text.secondary"
                      >
                        {`${startDateString} ${format(eventStartAt, 'PPp')}`}
                      </Typography>
                    </Stack>
                    <Stack
                      rowGap={2}
                      columnGap={2}
                      direction="row"
                      alignItems="center"
                    >
                      <CalendarIcon
                        width="16"
                        height="16"
                        stroke={palette.text.blue}
                      />
                      <Typography
                        variant="bodySmallRegular"
                        color="text.secondary"
                      >
                        {`${endDateString} ${format(eventEndAt, 'PPp')}`}
                      </Typography>
                    </Stack>
                  </Stack>
                  <Stack
                    direction="row"
                    gap={2}
                    justifyContent="center"
                    alignItems="center"
                  >
                    <UsersIcon width={16} height={16} stroke={palette.colors.mintGreen} />
                    <Typography
                      variant="bodySmallRegular"
                      color="text.secondary"
                    >
                      {isExactCapacity
                        ? t('common.member', { count: min })
                        : t('events.membersRange', { min, max })}
                    </Typography>
                  </Stack>
                </Box>
              </Box>
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </Card>
  );
};

export default CurrentEventCard;
