import { useTheme } from '@mui/styles';

const CardMoneyIcon = (props) => {
  const theme = useTheme();
  const {
    stroke = theme.palette.text.secondary,
    fill = theme.palette.paper.hover,
    ...rest
  } = props;

  return (
    <svg width="48" height="44" viewBox="0 0 48 44" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <rect width="48" height="44" rx="6" fill={fill} />
      <g clipPath="url(#clip0_2887_5878)">
        <path d="M26.0625 15.8125H15.0625C14.6978 15.8125 14.3481 15.9574 14.0902 16.2152C13.8324 16.4731 13.6875 16.8228 13.6875 17.1875V28.1875C13.6875 28.5522 13.8324 28.9019 14.0902 29.1598C14.3481 29.4176 14.6978 29.5625 15.0625 29.5625H26.0625" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M13.6875 19.9375H25.375" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M17.125 24.0625H21.25" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M34.3125 16.3281H30.3938C29.792 16.3281 29.215 16.5672 28.7895 16.9926C28.364 17.4181 28.125 17.9952 28.125 18.5969C28.125 21.9995 34.3125 21.9995 34.3125 25.4031C34.3123 26.0048 34.0732 26.5817 33.6477 27.0071C33.2223 27.4325 32.6454 27.6716 32.0438 27.6719H28.125" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M31.2188 16.3281V14.2656" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M31.2188 27.6719V29.7344" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      </g>
      <defs>
        <clipPath id="clip0_2887_5878">
          <rect width="22" height="22" fill="white" transform="translate(13 11)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CardMoneyIcon;
