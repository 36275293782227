import { useState } from 'react';
import {
  Box,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material';

import {
  useLocale, useModal, useRecaptcha, useSnackbar,
} from 'util/hooks';

import {
  CreditCardIcon,
  TableActionIcon,
  CrossCircleIcon,
} from 'assets/icons';
import { deletePaymentCard, setPaymentCardDefault } from 'services';

import { usePaymentCards } from 'reactQuery/queries';
import MODAL_KEYS from 'assets/constants/modalKeys';

const PaymentMethodCardMenu = (props) => {
  const {
    cardDetails = {},
  } = props;

  const {
    isDefault = false,
  } = cardDetails;

  const { executeRecaptcha } = useRecaptcha({ action: 'DELETE_PAYMENT_CARD' });
  const { t, dir } = useLocale();
  const snack = useSnackbar();

  const { addModal } = useModal();
  const {
    refetch: refetchPaymentCards = () => { },
  } = usePaymentCards();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleMenuClick = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const openDeleteModal = () => {
    // Close menu
    handleMenuClose();

    // Open confirmation modal
    addModal({
      key: MODAL_KEYS.deletePaymentMethod,
      props: {
        handleDelete,
        cardDetails,
      },
    });
  };

  const handleDelete = async () => {
    try {
      const recaptchaToken = await executeRecaptcha();
      // Step 1 - delete card
      await deletePaymentCard(cardDetails.id, { recaptchaToken });

      // Step 2 - re-fetch all cards
      refetchPaymentCards();

      snack({
        message: t('paymentMethods.cardDeletedSuccess'),
        severity: 'success',
      });
    } catch (error) {
      snack({
        message: error.message || t('common.errorMessage'),
        severity: 'error',
      });
    }
    handleMenuClose();
  };

  const handleDefault = async () => {
    try {
      // Step 1 - set card as default
      await setPaymentCardDefault(cardDetails.id);

      // Step 2 - re-fetch all cards
      await refetchPaymentCards();

      snack({
        message: t('paymentMethods.cardSetDefaultSuccess'),
        severity: 'success',
      });
    } catch (error) {
      snack({
        message: error.message || t('common.errorMessage'),
        severity: 'error',
      });
    }
    handleMenuClose();
  };

  const menuItems = [
    {
      id: 'delete',
      label: t('common.delete'),
      icon: <CrossCircleIcon
        width="20"
        height="20"
      />,
      menuItemProps: {
        onClick: openDeleteModal,
      },
    },
  ];

  if (!isDefault) {
    menuItems.push({
      id: 'set-default',
      label: t('paymentMethods.setDefault'),
      icon: <CreditCardIcon
        width="20"
        height="20"
      />,
      menuItemProps: {
        onClick: handleDefault,
      },
    });
  }

  return (
    <Box>
      <IconButton
        aria-label="open card menu"
        onClick={handleMenuClick}
        sx={{ p: 0 }}
      >
        <TableActionIcon />
      </IconButton>

      <Menu
        id="payment-method-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleMenuClose}
      >
        {menuItems
          .map((menuItem) => (
            <Box key={menuItem.id} sx={{ minWidth: 180 }}>
              <MenuItem dir={dir} {...menuItem.menuItemProps}>
                <ListItemIcon
                  sx={{
                    width: '20px',
                    height: '20px',
                  }}
                >
                  {menuItem.icon}
                </ListItemIcon>
                <Typography variant="bodySmallMedium">{menuItem.label}</Typography>
              </MenuItem>
            </Box>
          ))}
      </Menu>
    </Box>
  );
};

export default PaymentMethodCardMenu;
