import React from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useLocale } from 'util/hooks';
import Image from 'mui-image';

import {
  AppBar as MUIAppBar,
  Box,
  Toolbar,
  Typography,
  Container,
  Button,
  Grid,
  CircularProgress,
} from '@mui/material';

import { PATHS } from 'routes';

import { flagyardLogo } from 'assets/images';

const MinimalAppBar = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { t, changeLanguage, isAr } = useLocale();

  const [showPublicActions, setShowPublicActions] = React.useState(true);
  React.useEffect(() => {
    const shouldHideActions = pathname === '/login' || pathname === '/register';
    setShowPublicActions(!shouldHideActions);
  }, [pathname]);

  const renderPublicActions = () => {
    const langMenuItem = {
      label: isAr ? 'English' : 'العربية',
      onClick: () => {
        changeLanguage(isAr ? 'en' : 'ar');
      },
    };

    return (
      <Box>
        <Grid container spacing={2} justifyContent="center" alignItems="center">
          <Grid item>
            <Button
              size="small"
              onClick={langMenuItem.onClick}
            >
              <Typography
                variant="bodySmallMedium"
                sx={{
                  color: 'text.primary',
                  fontFamily: isAr
                    ? 'IBM Plex Sans,  sans-serif'
                    : 'IBM Plex Sans Arabic, sans-serif',
                }}
              >
                {langMenuItem.label}
              </Typography>
            </Button>
          </Grid>
          <Grid item>
            <Button
              size="small"
              variant="outlined"
              color="secondary"
              onClick={() => navigate(`/${PATHS.login}`)}
            >
              {t('common.login')}
            </Button>
          </Grid>
        </Grid>
      </Box>
    );
  };

  return (
    <MUIAppBar
      position="static"
      elevation={0}
      sx={{
        background: 'transparent',
      }}
    >
      <Container
        disableGutters
        maxWidth={false}
      >
        <Toolbar
          disableGutters
          sx={{
            m: 'auto',
            maxWidth: 'xl',
            px: {
              xs: 2,
              sm: 4,
              md: 12,
            },
          }}
        >
          <Typography
            noWrap
            component="div"
            sx={{ flexGrow: 1, display: 'flex' }}
          >
            <Link to={PATHS.root}>
              <Image
                src={flagyardLogo}
                alt="flagyard-logo"
                width={110}
                height={30}
                duration={0}
                showLoading={<CircularProgress size={20} />}
                sx={{ loading: 'lazy' }}
              />
            </Link>
          </Typography>
          {showPublicActions && renderPublicActions()}
        </Toolbar>
      </Container>
    </MUIAppBar>
  );
};

export default MinimalAppBar;
