import { Box, Typography } from '@mui/material';
import { SuspenseWrapper } from 'components/render';
import { useLocale } from 'util/hooks';
import BadgeCard from './BadgeCard';

const BadgesList = (props) => {
  const { badges = {} } = props;

  const { t } = useLocale();

  const renderDomainHeader = (domain = '', domainBadges = []) => {
    const totalNumber = domainBadges?.length || 0;
    const numberOfEarned = domainBadges?.filter((badge) => badge.earned)?.length || 0;
    return (
      <>
        <Typography
          variant="bodySmallMedium"
          color="text.secondary"
        >
          {t(`badges.${domain}`)}
        </Typography>
        <Typography
          variant="bodySmallMedium"
          color="success.main"
        >
          {` ${numberOfEarned}/${totalNumber}`}
        </Typography>
      </>
    );
  };

  return (
    Object.keys(badges)?.map((domain) => (
      <Box key={domain}>
        <Box sx={{ mb: 4 }}>
          {renderDomainHeader(domain, badges[domain])}
        </Box>
        <Box sx={{
          display: 'grid',
          justifyContent: {
            xs: 'center',
            md: 'flex-start',
          },
          gridTemplateColumns: {
            xs: 'repeat(2, 1fr)',
            sm: 'repeat(3, 1fr)',
            lg: 'repeat(4, 1fr)',
            xl: 'repeat(6, 1fr)',
          },
          rowGap: 10,
          mb: 15,
        }}
        >
          <SuspenseWrapper>
            {badges[domain]?.map((badge) => (
              <BadgeCard
                key={badge.id}
                badge={badge}
              />
            ))}
          </SuspenseWrapper>
        </Box>
      </Box>
    ))
  );
};

export default BadgesList;
