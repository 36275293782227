import { useMutation } from '@tanstack/react-query';
import { exportEventReport } from 'services';

const useExportEventReport = (options = {}) => {
  const mutation = useMutation({
    mutationFn: ({ eventId, eventName }) => exportEventReport({ eventId, eventName }),
    ...options,
  });

  return mutation;
};

export default useExportEventReport;
