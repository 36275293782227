import { getInstances } from 'services';
import { useCustomQuery } from 'reactQuery';

const useInstances = (queryProps = {}) => {
  const {
    type = '',
    ...rest
  } = queryProps;

  const query = useCustomQuery({
    queryKey: [`instances-${type}`],
    queryFn: (options) => getInstances(type, options),
    ...rest,
  });

  return query;
};

export default useInstances;
