import CurrentEventCard from './helpers/CurrentEventCard';

const EventSummary = (props) => {
  const {
    eventDetails = {},
  } = props;

  return (
    <CurrentEventCard
      eventDetails={eventDetails}
      interactive={false}
    />
  );
};

export default EventSummary;
