import { useTheme } from '@mui/styles';

const TableActionIcon = (props) => {
  const theme = useTheme();
  const {
    stroke = theme.palette.text.secondary,
    fill = 'none',
    ...rest
  } = props;

  return (
    <svg width="22" height="22" viewBox="0 0 22 22" fill={fill} xmlns="http://www.w3.org/2000/svg" {...rest}>
      <path fillRule="evenodd" clipRule="evenodd" d="M6 9.75C5.30964 9.75 4.75 10.3096 4.75 11C4.75 11.6904 5.30964 12.25 6 12.25C6.69036 12.25 7.25 11.6904 7.25 11C7.25 10.3096 6.69036 9.75 6 9.75Z" stroke={stroke} strokeWidth="1.5" />
      <path fillRule="evenodd" clipRule="evenodd" d="M16 9.75C15.3096 9.75 14.75 10.3096 14.75 11C14.75 11.6904 15.3096 12.25 16 12.25C16.6904 12.25 17.25 11.6904 17.25 11C17.25 10.3096 16.6904 9.75 16 9.75Z" stroke={stroke} strokeWidth="1.5" />
      <path fillRule="evenodd" clipRule="evenodd" d="M11 9.75C10.3096 9.75 9.75 10.3096 9.75 11C9.75 11.6904 10.3096 12.25 11 12.25C11.6904 12.25 12.25 11.6904 12.25 11C12.25 10.3096 11.6904 9.75 11 9.75Z" stroke={stroke} strokeWidth="1.5" />
      <path fillRule="evenodd" clipRule="evenodd" d="M11 20.3779C16.1777 20.3779 20.375 16.1806 20.375 11.0029C20.375 5.82526 16.1777 1.62793 11 1.62793C5.82233 1.62793 1.625 5.82526 1.625 11.0029C1.625 16.1806 5.82233 20.3779 11 20.3779Z" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default TableActionIcon;
