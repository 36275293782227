import { useTheme } from '@mui/styles';

const MoneyFileIcon = (props) => {
  const theme = useTheme();
  const {
    stroke = theme.palette.text.lighterRaspberry,
    fill = 'none',
    ...rest
  } = props;

  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill={fill} xmlns="http://www.w3.org/2000/svg" stroke={stroke} {...rest}>
      <path d="M18.751 14.25H16.718C16.4064 14.2502 16.1047 14.3588 15.8644 14.5572C15.6242 14.7556 15.4605 15.0314 15.4013 15.3373C15.3422 15.6432 15.3914 15.9602 15.5404 16.2338C15.6894 16.5074 15.929 16.7207 16.218 16.837L18.282 17.663C18.5711 17.7793 18.8106 17.9926 18.9597 18.2662C19.1087 18.5398 19.1578 18.8568 19.0987 19.1627C19.0396 19.4686 18.8758 19.7444 18.6356 19.9428C18.3954 20.1412 18.0936 20.2498 17.782 20.25H15.751" strokeWidth="0.9" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M17.25 14.25V13.5" strokeWidth="0.9" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M17.25 21V20.25" strokeWidth="0.9" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M17.25 23.25C20.5637 23.25 23.25 20.5637 23.25 17.25C23.25 13.9363 20.5637 11.25 17.25 11.25C13.9363 11.25 11.25 13.9363 11.25 17.25C11.25 20.5637 13.9363 23.25 17.25 23.25Z" strokeWidth="0.9" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M3.75 6.75H14.25" strokeWidth="0.9" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M3.75 11.25H9.75" strokeWidth="0.9" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M3.75 15.75H7.5" strokeWidth="0.9" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M7.5 20.25H2.25C1.85218 20.25 1.47064 20.092 1.18934 19.8107C0.908035 19.5294 0.75 19.1478 0.75 18.75V2.25C0.75 1.85218 0.908035 1.47064 1.18934 1.18934C1.47064 0.908035 1.85218 0.75 2.25 0.75H12.879C13.2765 0.750085 13.6578 0.907982 13.939 1.189L16.811 4.061C17.092 4.3422 17.2499 4.72345 17.25 5.121V8.25" strokeWidth="0.9" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default MoneyFileIcon;
