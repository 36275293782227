import React from 'react';

// Inspired by: https://stackoverflow.com/questions/56450975/to-fix-cancel-all-subscriptions-and-asynchronous-tasks-in-a-useeffect-cleanup-f#answer-60693711

/**
 * A hook to fetch async data and cancel on unMount
 * @class useAsyncService
 * @borrows useAsyncServiceObject
 * @param {object} _                props
 * @param {async} props.service         Promise like async function
 * @param {bool} props.immediate=false    Invoke the function immediately
 * @param {object} props.immediateParams=null       Function initial parameters
 * @param {object} props.initialData=null      Initial data
 * @returns {useAsyncServiceObject}        Async object
 * @example
 *   const { execute, isLoading, data, error } = useAsyncService({
 *    service: async () => { return 'data' },
 *    immediate: false,
 *    immediateParams: { data: '1' },
 *    initialData: 'Hello'
 *  })
 */
const useAsyncService = (props = {}) => {
  const {
    service = () => { },
    immediate = false,
    immediateParams = null,
    initialData = null,
  } = props;
  const mountedRef = React.useRef(null);
  const [error, setError] = React.useState(null);
  const [data, setData] = React.useState(initialData);
  const [isLoading, setIsLoading] = React.useState(immediate);

  const execute = React.useCallback(async (...params) => {
    setIsLoading(true);

    try {
      const res = await service(...params);

      if (!mountedRef.current) {
        return null;
      }

      setData(res);
      setError(null);
      setIsLoading(false);

      return res;
    } catch (err) {
      if (!mountedRef.current) {
        return null;
      }

      setError(err);
      setIsLoading(false);

      throw err;
    }
  }, [service]);

  React.useEffect(() => {
    mountedRef.current = true;

    if (immediate) {
      execute(immediateParams);
    }

    return () => {
      mountedRef.current = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    execute,
    isLoading,
    data,
    error,
    mountedRef,
  };
};

export default useAsyncService;
