import { NoContentPlaceholder } from 'components/molecules';
import { useLocale } from 'util/hooks';

const NoEventsPlaceholder = () => {
  const { t } = useLocale();

  const content = {
    desc: [{
      text: t('profile.noEvents'),
    }],
  };

  return <NoContentPlaceholder content={content} />;
};

export default NoEventsPlaceholder;
