import { useTheme } from '@mui/styles';

const LockCircleIcon = (props) => {
  const { palette } = useTheme();
  const {
    stroke = palette.text.secondary,
    ...rest
  } = props;

  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <g clipPath="url(#clip0_10501_147371)">
        <path d="M10.5 6.5H5.5C4.94772 6.5 4.5 6.94772 4.5 7.5V11.5C4.5 12.0523 4.94772 12.5 5.5 12.5H10.5C11.0523 12.5 11.5 12.0523 11.5 11.5V7.5C11.5 6.94772 11.0523 6.5 10.5 6.5Z" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" />
        <path d="M8 9.25C7.95055 9.25 7.90222 9.26466 7.86111 9.29213C7.82 9.3196 7.78795 9.35865 7.76903 9.40433C7.75011 9.45001 7.74516 9.50028 7.7548 9.54877C7.76445 9.59727 7.78826 9.64181 7.82322 9.67678C7.85819 9.71174 7.90273 9.73555 7.95123 9.7452C7.99972 9.75484 8.04999 9.74989 8.09567 9.73097C8.14135 9.71205 8.1804 9.68001 8.20787 9.63889C8.23534 9.59778 8.25 9.54945 8.25 9.5C8.25009 9.46715 8.24368 9.4346 8.23115 9.40423C8.21862 9.37385 8.2002 9.34626 8.17697 9.32303C8.15374 9.2998 8.12615 9.28138 8.09577 9.26885C8.0654 9.25632 8.03286 9.24991 8 9.25Z" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" />
        <path d="M5.5 6.5V5C5.5 4.33696 5.76339 3.70107 6.23223 3.23223C6.70107 2.76339 7.33696 2.5 8 2.5C8.66304 2.5 9.29893 2.76339 9.76777 3.23223C10.2366 3.70107 10.5 4.33696 10.5 5V6.5" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" />
        <path d="M8 15.5C12.1421 15.5 15.5 12.1421 15.5 8C15.5 3.85786 12.1421 0.5 8 0.5C3.85786 0.5 0.5 3.85786 0.5 8C0.5 12.1421 3.85786 15.5 8 15.5Z" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" />
      </g>
      <defs>
        <clipPath id="clip0_10501_147371">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default LockCircleIcon;
