import { useTheme } from '@mui/styles';

const AnnouncementsFilledIcon = (props) => {
  const { palette } = useTheme();
  const {
    stroke = palette.text.secondary,
    fill = palette.text.secondary,
    ...rest
  } = props;

  return (
    <svg width="48" height="44" viewBox="0 0 48 44" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <rect width="48" height="44" rx="6" fill={fill} fillOpacity="0.1" />
      <path d="M22.167 30.9375C22.2834 31.3341 22.5252 31.6824 22.8561 31.9301C23.187 32.1778 23.5893 32.3117 24.0026 32.3117C24.416 32.3117 24.8182 32.1778 25.1491 31.9301C25.48 31.6824 25.7218 31.3341 25.8382 30.9375" stroke={stroke} strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M24 13.75V11.6875" stroke={stroke} strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M24 13.75C25.8234 13.75 27.572 14.4743 28.8614 15.7636C30.1507 17.053 30.875 18.8016 30.875 20.625C30.875 27.0838 32.25 28.1875 32.25 28.1875H15.75C15.75 28.1875 17.125 26.4312 17.125 20.625C17.125 18.8016 17.8493 17.053 19.1386 15.7636C20.428 14.4743 22.1766 13.75 24 13.75V13.75Z" stroke={stroke} strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default AnnouncementsFilledIcon;
