import { Box } from '@mui/material';

import { SuspenseWrapper } from 'components/render';

import { useUserProfile } from 'reactQuery/queries';
import BadgesHeader from './helpers/BadgesHeader';
import BadgesList from './helpers/BadgesList';

const ProfileBadgesContainer = () => {
  const {
    data: profileDetails = {},
  } = useUserProfile();

  const { badges = {} } = profileDetails;

  return (
    <Box>
      <Box sx={{ py: 6 }}>
        <BadgesHeader />
      </Box>
      <Box>
        <SuspenseWrapper>
          <BadgesList badges={badges} />
        </SuspenseWrapper>
      </Box>
    </Box>
  );
};
export default ProfileBadgesContainer;
