import React from 'react';
import PropTypes from 'prop-types';

import { useLocale } from 'util/hooks';
import { calculateDateDiff } from 'util/helpers';

const FirstBloodCalculation = (props) => {
  const {
    publishedAt = '',
    firstShownAt = '',
    submittedAt = '',
  } = props;

  const { t } = useLocale();
  const latestDate = publishedAt > firstShownAt
    ? publishedAt
    : firstShownAt;

  const firstBloodTime = React.useMemo(
    () => calculateDateDiff(latestDate, submittedAt),
    [latestDate, submittedAt],
  );

  const renderFirstBloodBreakdown = () => {
    if (firstBloodTime.days < 1) {
      const firstBloodWithoutDays = `${firstBloodTime.hours}${t('time.hour')} 
        ${firstBloodTime.minutes}${t('time.minute')} 
        ${firstBloodTime.seconds}${t('time.second')}`;

      return firstBloodWithoutDays;
    }

    const firstBloodWithDays = `${firstBloodTime.days}${t('time.day')} 
      ${firstBloodTime.hours}${t('time.hour')} 
      ${firstBloodTime.minutes}${t('time.minute')} 
      ${firstBloodTime.seconds}${t('time.second')}`;

    return firstBloodWithDays;
  };

  return firstBloodTime
    ? renderFirstBloodBreakdown()
    : null;
};

FirstBloodCalculation.propTypes = {
  publishedAt: PropTypes.string,
  firstShownAt: PropTypes.string,
  submittedAt: PropTypes.string,
};

FirstBloodCalculation.defaultProps = {
  publishedAt: '',
  firstShownAt: '',
  submittedAt: '',
};

export default FirstBloodCalculation;
