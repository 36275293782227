import { useTheme } from '@mui/styles';

const EditFileIcon = (props) => {
  const { palette } = useTheme();
  const {
    stroke = palette.text.secondary,
    fill = palette.text.secondary,
    ...rest
  } = props;

  return (
    <svg width="48" height="44" viewBox="0 0 48 44" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <rect width="48" height="44" rx="6" fill={fill} fillOpacity="0.1" />
      <g clipPath="url(#clip0_9013_48024)">
        <path d="M33.7442 24.6309L26.75 31.6251L23.3125 32.3126L24 28.8751L30.9942 21.8809C31.1742 21.7006 31.3879 21.5576 31.6232 21.4601C31.8585 21.3625 32.1108 21.3123 32.3655 21.3123C32.6202 21.3123 32.8725 21.3625 33.1078 21.4601C33.3431 21.5576 33.5569 21.7006 33.7368 21.8809L33.7442 21.8882C33.9244 22.0682 34.0675 22.282 34.165 22.5173C34.2626 22.7526 34.3128 23.0048 34.3128 23.2596C34.3128 23.5143 34.2626 23.7666 34.165 24.0019C34.0675 24.2372 33.9244 24.4509 33.7442 24.6309V24.6309Z" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M20.5625 29.5625H15.0625C14.6978 29.5625 14.3481 29.4176 14.0902 29.1598C13.8324 28.9019 13.6875 28.5522 13.6875 28.1875V13.0625C13.6875 12.6978 13.8324 12.3481 14.0902 12.0902C14.3481 11.8324 14.6978 11.6875 15.0625 11.6875H24.8058C25.1702 11.6876 25.5197 11.8323 25.7774 12.0899L28.4101 14.7226C28.6677 14.9803 28.8124 15.3298 28.8125 15.6943V18.5625" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      </g>
      <defs>
        <clipPath id="clip0_9013_48024">
          <rect width="22" height="22" fill="white" transform="translate(13 11)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default EditFileIcon;
