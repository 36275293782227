import { atom } from 'recoil';

const machinesFilterAtom = atom({
  key: 'machinesFilter',
  default: {
    type: '',
    value: '*',
  },
});

export default machinesFilterAtom;
