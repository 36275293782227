const transformEventDetails = (event) => {
  const {
    eventEndAt = '',
    eventStartAt = '',
    registrationEndAt = '',
    registrationStartAt = '',
    teamMaxCapacity = 0,
    teamMinCapacity = 0,
  } = event;

  const now = new Date();

  const eventHasEnded = new Date(eventEndAt) < now;
  const eventHasStarted = new Date(eventStartAt) < now;
  const registrationHasEnded = new Date(registrationEndAt) < now;
  const registrationHasStarted = new Date(registrationStartAt) < now;
  const isRegistrationPeriod = registrationHasStarted && !registrationHasEnded;
  const isExactCapacity = teamMaxCapacity === teamMinCapacity;

  return {
    ...event,
    eventHasEnded,
    eventHasStarted,
    registrationHasEnded,
    registrationHasStarted,
    isRegistrationPeriod,
    isExactCapacity,
  };
};

export default transformEventDetails;
