import {
  Box,
  Card,
  Typography,
  useMediaQuery,
} from '@mui/material';

import { useLocale } from 'util/hooks';

import { AvatarWithFallback, ProgressWithLabel } from 'components/molecules';

const graphBreakpoint = 'lg';

const LabSummary = (props) => {
  const { labDetails = {} } = props;
  const {
    avatar = '',
    nameEn = '',
    nameAr = '',
    descriptionEn = '',
    descriptionAr = '',
    challenges = [],
    userPointsInThisLab = 0,
    totalActivePoints = 0,
  } = labDetails;

  const { t, isAr } = useLocale();

  const completionPercentage = 100 * userPointsInThisLab / totalActivePoints || 0;
  const completionLabel = `${userPointsInThisLab}/${totalActivePoints} ${t('challenges.points')}`;
  const mobileView = useMediaQuery(({ breakpoints }) => breakpoints.down('md'));

  return (
    <Card sx={{ p: 8 }}>
      <Box sx={{
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
      >
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
        }}
        >
          <Box sx={{
            width: 1,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
          >
            <Box sx={{
              display: 'flex',
              alignItems: 'center',
            }}
            >
              <AvatarWithFallback
                src={avatar}
                alt={nameEn}
                sx={{
                  width: { xs: 45, sm: 65 },
                  height: { xs: 45, sm: 65 },
                  fontSize: 30,
                  fontWeight: 'bold',
                  mr: 6,
                  p: 2,
                }}
              />
              <Box>
                <Box>
                  <Typography
                    variant="bodyMediumMedium"
                    color="text.lighterGray"
                    sx={{ mr: 3 }}
                  >
                    {isAr ? nameAr : nameEn}
                  </Typography>
                </Box>
                <Typography
                  variant="bodySmallRegular"
                  color="text.secondary"
                >
                  {`${challenges.length} ${t('common.challenges')}`}
                </Typography>
              </Box>
            </Box>
            <Box sx={{
              display: {
                xs: 'block',
                [graphBreakpoint]: 'none',
              },
            }}
            >
              <ProgressWithLabel
                value={completionPercentage}
                // Hide label to fit
                size={mobileView ? 55 : 68}
              />
            </Box>
          </Box>
          <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            ml: { md: 22 },
          }}
          >
            <Box
              sx={{ mt: 4, mb: 6 }}
              maxWidth="md"
            >
              <Typography
                variant="bodyStandardRegular"
                color="text.lighterGray"
              >
                {isAr ? descriptionAr : descriptionEn}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box sx={{
          display: {
            xs: 'none',
            [graphBreakpoint]: 'block',
          },
        }}
        >
          <ProgressWithLabel
            value={completionPercentage}
            label={completionLabel}
          />
        </Box>
      </Box>
    </Card>
  );
};

export default LabSummary;
