import { Autocomplete, TextField } from '@mui/material';
import PropTypes from 'prop-types';

import ExpandMore from '@mui/icons-material/ExpandMore';
import ClearIcon from '@mui/icons-material/Clear';

const TimezoneSelect = (props) => {
  const {
    autoCompleteProps,
    textFieldProps,
  } = props;

  const timeZones = Intl.supportedValuesOf('timeZone');
  const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const isOptionEqualToValue = (option, value) => {
    if (value === '') return true; // Prevent onClear warning
    return option === value;
  };

  return (
    <Autocomplete
      id={autoCompleteProps.name}
      fullWidth
      popupIcon={<ExpandMore sx={{ color: 'text.secondary' }} />}
      clearIcon={<ClearIcon sx={{ color: 'text.secondary' }} />}
      options={timeZones}
      isOptionEqualToValue={isOptionEqualToValue}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={textFieldProps.placeholder || userTimezone}
          {...textFieldProps}
        />
      )}
      {...autoCompleteProps}
    />
  );
};

TimezoneSelect.propTypes = {
  autoCompleteProps: PropTypes.oneOfType([PropTypes.object]),
  textFieldProps: PropTypes.oneOfType([PropTypes.object]),
};

TimezoneSelect.defaultProps = {
  autoCompleteProps: {},
  textFieldProps: {},
};

export default TimezoneSelect;
