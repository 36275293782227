import { useTheme } from '@mui/styles';

const SubscriptionFilledStarIcon = (props) => {
  const { palette } = useTheme();
  const {
    stroke = palette.text.secondary,
    fill = palette.paper.hover,
    ...rest
  } = props;

  return (
    <svg width="60" height="56" viewBox="0 0 60 56" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <rect width="60" height="56" rx="6" fill={fill} />
      <g clipPath="url(#clip0_4047_48003)">
        <path d="M37.9413 27.573L39.9999 31.9997H43.9866C44.1882 31.9921 44.3873 32.0468 44.5566 32.1566C44.7259 32.2663 44.8572 32.4256 44.9326 32.6127C45.008 32.7998 45.0238 33.0057 44.9779 33.2021C44.9319 33.3986 44.8265 33.5761 44.6759 33.7103L41.2093 37.1277L43.1293 41.545C43.2135 41.7471 43.2323 41.9704 43.183 42.1837C43.1337 42.397 43.0187 42.5894 42.8543 42.734C42.6899 42.8785 42.4843 42.9679 42.2665 42.9895C42.0486 43.011 41.8295 42.9638 41.6399 42.8543L36.9999 40.2437L32.3599 42.8583C32.1704 42.9678 31.9512 43.015 31.7334 42.9935C31.5155 42.9719 31.31 42.8825 31.1455 42.738C30.9811 42.5934 30.8662 42.401 30.8169 42.1877C30.7675 41.9744 30.7863 41.7511 30.8706 41.549L32.7906 37.1317L29.3239 33.7143C29.1712 33.5809 29.0637 33.4033 29.0164 33.2061C28.969 33.0089 28.984 32.8019 29.0594 32.6136C29.1348 32.4253 29.2668 32.2651 29.4372 32.1551C29.6076 32.0452 29.808 31.9908 30.0106 31.9997H34.0106L36.0653 27.569C36.1571 27.4002 36.2929 27.2593 36.4582 27.1613C36.6235 27.0633 36.8123 27.0118 37.0045 27.0122C37.1966 27.0126 37.3852 27.0649 37.5501 27.1637C37.715 27.2624 37.8501 27.4038 37.9413 27.573V27.573Z" stroke={stroke} strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M25 39H17C16.4696 39 15.9609 38.7893 15.5858 38.4142C15.2107 38.0391 15 37.5304 15 37V15C15 14.4696 15.2107 13.9609 15.5858 13.5858C15.9609 13.2107 16.4696 13 17 13H31.172C31.7021 13.0001 32.2104 13.2106 32.5853 13.5853L36.4147 17.4147C36.7894 17.7896 36.9999 18.2979 37 18.828V23" stroke={stroke} strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
      </g>
      <defs>
        <clipPath id="clip0_4047_48003">
          <rect width="32" height="32" fill="white" transform="translate(14 12)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SubscriptionFilledStarIcon;
