import { getPlanFeatures } from 'services';
import { useCustomQuery } from 'reactQuery';

const usePlanFeatures = () => {
  const query = useCustomQuery({
    queryKey: ['plan-features'],
    queryFn: getPlanFeatures,
  });

  return { ...query };
};

export default usePlanFeatures;
