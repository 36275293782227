import {
  Box,
} from '@mui/material';

import { usePaymentCards } from 'reactQuery/queries';
import PaymentMethodsHeader from './Sections/PaymentMethodsHeader';
import PaymentMethodList from './Sections/PaymentMethodList';

const PaymentMethodsContainer = () => {
  const {
    data: paymentCards = [],
  } = usePaymentCards();

  return (
    <Box sx={{ mt: 8 }}>
      <Box mb={10}>
        <PaymentMethodsHeader paymentMethodsCount={paymentCards?.length} />
      </Box>
      <Box>
        <PaymentMethodList paymentCards={paymentCards} />
      </Box>
    </Box>
  );
};

export default PaymentMethodsContainer;
