import { Box, Stack, useMediaQuery } from '@mui/material';

import { useLocale } from 'util/hooks';
import { NoContentPlaceholder } from 'components/molecules';
import {
  ActivityDateCard,
  ChallengeHackersHeader,
  LabChallengeSubmissionCard,
} from 'components/organisms';

const LabChallengeActivity = (props) => {
  const { challengeDetails = {} } = props;

  const { t } = useLocale();
  const mobileView = useMediaQuery(({ breakpoints }) => breakpoints.down('sm'));

  const {
    publishedAt = '',
    retiredAt = '',
    submissions = [], // list of submissions
  } = challengeDetails || {};

  const challengeHackers = submissions
    ?.slice()
    ?.sort((submission) => (submission.firstBlood ? -1 : 1)) // sort by firstBlood
    .map((submission) => (
      <LabChallengeSubmissionCard
        key={submission.id}
        {...submission}
        publishedAt={publishedAt}
        player={submission.player}
      />
    ));

  const renderNoChallengesSolved = () => {
    const content = {
      desc: [{
        text: t('challenges.noChallengesSolved'),
        variant: 'bodStandardRegular',
      }],
    };

    return (
      <Box mt={20}>
        <NoContentPlaceholder content={content} />
      </Box>
    );
  };

  return (
    <Stack gap={6}>
      <Box>
        <Stack direction={mobileView ? 'column' : 'row'} spacing={6}>
          {publishedAt && (
            <ActivityDateCard
              date={publishedAt}
              type="publish"
            />
          )}
          {retiredAt && (
            <ActivityDateCard
              date={retiredAt}
              type="retire"
            />
          )}
        </Stack>
      </Box>
      <Stack>
        <ChallengeHackersHeader />
      </Stack>
      <Box>
        {challengeHackers.length ? (
          <Stack gap={5}>
            {challengeHackers}
          </Stack>
        ) : renderNoChallengesSolved()}
      </Box>
    </Stack>
  );
};

export default LabChallengeActivity;
