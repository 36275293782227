import { useEffect, useState } from 'react';

import { Tabs, Tab, Link } from '@mui/material';
import { Outlet } from 'react-router-dom';
import SuspenseWrapper from 'components/render/SuspenseWrapper/SuspenseWrapper';

const getActiveTab = (tabs, pathname) => (tabs
  .find((tab) => pathname.endsWith(tab.to)) || tabs[0]);

/**
 *
 * @param {[{label: String, to: String}]} tabs
 * @returns Components wrapped in MUITab elements
 * @USAGE
 * const tabs = [{
 *      label: 'Label 1',
 *      to: '/label-1-path,
 *     },
 *    {
 *      label: 'Label 2',
 *      to: '/label-2-path,
 * }];
 */
const UrlTabs = (props) => {
  const {
    tabs = [],
    initialTab = null,
    tabListProps = {},
    tabProps = {},
  } = props;

  const { pathname } = window.location;
  const initialActiveTab = initialTab || getActiveTab(tabs, pathname);
  const [currentTab, setCurrentTab] = useState(initialActiveTab.to);

  const handleChange = (e, newValue) => {
    setCurrentTab(newValue);
  };

  useEffect(() => {
    const newTab = getActiveTab(tabs, pathname);
    setCurrentTab(newTab.to);
  }, [tabs, pathname]);

  return (
    <>
      <Tabs
        value={currentTab}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons="auto"
        allowScrollButtonsMobile
        {...tabListProps}
        sx={{
          mt: 10,
          ...tabListProps?.sx,
        }}
      >
        {tabs?.map(({
          show = true,
          ...tab
        }) => (
          show ? (
            <Tab
              key={`url-tab-${tab.to}`}
              component={Link}
              value={tab.to}
              disableRipple
              {...tab}
              {...tabProps}
            />
          )
            : null
        ))}
      </Tabs>
      <SuspenseWrapper>
        <Outlet />
      </SuspenseWrapper>
    </>
  );
};

export default UrlTabs;
