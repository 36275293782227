import { useParams } from 'react-router-dom';
import { getAnnouncementDetails } from 'services';
import { useCustomQuery } from 'reactQuery';

const useAnnouncementDetails = (queryProps = {}) => {
  const { announcementId } = useParams();

  const query = useCustomQuery({
    queryKey: [`view-announcement-${announcementId}`],
    queryFn: () => getAnnouncementDetails(announcementId),
    ...queryProps,
    options: {
      enabled: !!announcementId,
      ...queryProps?.options,
    },
  });

  return { ...query };
};

export default useAnnouncementDetails;
