import PropTypes from 'prop-types';

import PERMISSIONS from 'assets/constants/permissions';
import { usePermission } from 'util/hooks';
import AvatarWithFallback from '../AvatarWithFallback/AvatarWithFallback';

const UserAwareAvatar = (props) => {
  const {
    user,
    sx,
    ...rest
  } = props;

  const {
    isFreePlanUser = true,
  } = user || {};

  const canSubscribe = usePermission(PERMISSIONS.planSubscribe);
  const showPremiumBorder = canSubscribe && !isFreePlanUser;

  return (
    <AvatarWithFallback
      {...rest}
      sx={{
        border: (theme) => (showPremiumBorder
          ? `2px solid ${theme.palette.primary.main}`
          : null),
        ...sx,
      }}
    />
  );
};

UserAwareAvatar.propTypes = {
  user: PropTypes.oneOfType([PropTypes.object]),
  sx: PropTypes.oneOfType([PropTypes.object]),
};

UserAwareAvatar.defaultProps = {
  user: {},
  sx: {},
};

export default UserAwareAvatar;
