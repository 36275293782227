import { useTheme } from '@mui/styles';

const CodeUsageIcon = (props) => {
  const theme = useTheme();
  const {
    stroke = theme.palette.text.lightRaspberry,
    fill = 'none',
    ...rest
  } = props;

  return (
    <svg width="39" height="39" viewBox="0 0 39 39" fill={fill} xmlns="http://www.w3.org/2000/svg" {...rest}>
      <path d="M7.3125 37.7812C10.678 37.7812 13.4062 35.053 13.4062 31.6875C13.4062 28.322 10.678 25.5938 7.3125 25.5938C3.94701 25.5938 1.21875 28.322 1.21875 31.6875C1.21875 35.053 3.94701 37.7812 7.3125 37.7812Z" stroke={stroke} strokeWidth="2.4375" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M7.3125 13.4062C10.678 13.4062 13.4062 10.678 13.4062 7.3125C13.4062 3.94701 10.678 1.21875 7.3125 1.21875C3.94701 1.21875 1.21875 3.94701 1.21875 7.3125C1.21875 10.678 3.94701 13.4062 7.3125 13.4062Z" stroke={stroke} strokeWidth="2.4375" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M21.1188 22.7916L4.68359 12.8125" stroke={stroke} strokeWidth="2.4375" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M37.7816 6.09375L4.68359 26.1885" stroke={stroke} strokeWidth="2.4375" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M37.7812 28.0312H35.3438" stroke={stroke} strokeWidth="2.4375" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M30.4688 28.0312H28.0312" stroke={stroke} strokeWidth="2.4375" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M23.1562 28.0312H20.7188" stroke={stroke} strokeWidth="2.4375" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default CodeUsageIcon;
