import { memo, useCallback } from 'react';
import { useController } from 'react-hook-form';
import PropTypes from 'prop-types';

import { TextField } from 'components/molecules';
import { formatNumber } from 'util/helpers';

const FormNumberField = (props) => {
  const {
    commaSeparated,
    ...rest
  } = props;

  const { field, fieldState } = useController(props);
  const { onChange } = field;
  const { error } = fieldState;

  const hasErrored = Boolean(error);

  const onFieldChange = useCallback(({ target: { value: newValue = '' } }) => {
    onChange(formatNumber(newValue, commaSeparated));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [commaSeparated]);

  return (
    <TextField
      {...field}
      {...rest}
      error={hasErrored}
      helperText={error?.message}
      value={formatNumber(String(field.value), commaSeparated)}
      onChange={onFieldChange}
    />
  );
};

FormNumberField.propTypes = {
  commaSeparated: PropTypes.bool,
};

FormNumberField.defaultProps = {
  commaSeparated: true,
};

export default memo(FormNumberField);
