import {
  Box,
  Button,
} from '@mui/material';

import {
  useModal,
  useLocale,
} from 'util/hooks';
import { useMachineDetails } from 'reactQuery/queries';

import { STATUS } from 'assets/constants/statuses';
import MODAL_KEYS from 'assets/constants/modalKeys';
import { useRecoilValue } from 'recoil';
import { machinesFilterAtom } from 'recoil/atoms';
import VISIBILITIES from 'assets/constants/visibilities';

const MachineActions = (props) => {
  const {
    listView = false, // List or single action
    machineStatus = '',
    ...rest
  } = props;

  const { t } = useLocale();
  const { addModal } = useModal();

  const {
    data: machineDetails = {},
  } = useMachineDetails();

  const filter = useRecoilValue(machinesFilterAtom);

  const {
    status = listView ? machineStatus : machineDetails?.status,
    machines = listView ? [] : [machineDetails],
  } = rest;

  // Statuses
  const isDraft = status === STATUS.draft;
  const isRetired = status === STATUS.retired;
  const isPublished = status === STATUS.published;
  const visibilityButton = isPublished || isRetired;
  const canArchive = isRetired || isPublished;

  const isVisible = listView ? filter?.value === VISIBILITIES.visible
    : machineDetails?.visible === true;

  const noRowsSelected = !machines?.length;

  // Modal
  const handleActionClick = (type) => {
    addModal({
      key: MODAL_KEYS.machine,
      props: {
        type,
        listView,
        machines,
        machineDetails,
      },
    });
  };

  // Buttons
  const buttonSx = { mx: 2 };
  const buttons = [
    {
      key: 'publishButton',
      show: isDraft,
      variant: 'contained',
      label: listView
        ? t('common.publishSelected')
        : t('common.publish'),
      onClick: () => handleActionClick('publish'),
    },
    {
      key: 'retireButton',
      show: isPublished,
      variant: 'contained',
      label: listView
        ? t('common.retireSelected')
        : t('common.retire'),
      onClick: () => handleActionClick('retire'),
    },
    {
      key: 'visibilityButton',
      show: visibilityButton,
      variant: 'outlined',
      color: 'primary',
      label: t(`common.${isVisible ? 'hide' : 'show'}${listView ? 'Selected' : ''}`),
      onClick: () => handleActionClick(isVisible ? 'hide' : 'show'),
    },
    {
      key: 'archiveButton',
      show: canArchive,
      color: 'primary',
      label: listView
        ? t('common.archiveSelected')
        : t('common.archive'),
      onClick: () => handleActionClick('archive'),
    },
    {
      key: 'deleteButton',
      show: isDraft,
      color: 'primary',
      label: listView
        ? t('common.deleteSelected')
        : t('common.delete'),
      onClick: () => handleActionClick('delete'),
    },
  ];

  const renderActionButton = ({ button = {} }) => (
    <span>
      <Button
        sx={buttonSx}
        disabled={noRowsSelected}
        {...button}
      >
        {button.label}
      </Button>
    </span>
  );

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: { xs: 'column', sm: 'row' },
      justifyContent: 'end',
      alignItems: 'center',
    }}
    >
      {buttons?.map(({
        key,
        show,
        ...button
      }) => (
        show && (
          <Box sx={{ mt: { xs: 4, sm: 0 } }} key={key}>
            {(renderActionButton({ button }))}
          </Box>
        )
      ))}
    </Box>
  );
};

export default MachineActions;
