import { useTheme } from '@mui/styles';

const PauseButtonIcon = (props) => {
  const theme = useTheme();
  const {
    stroke = theme.palette.text.secondary,
    ...rest
  } = props;

  return (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <g clipPath="url(#clip0_11617_165517)">
        <path d="M11.375 9.62256V18.3726" stroke={stroke} strokeWidth="1.8125" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M16.625 9.62256V18.3726" stroke={stroke} strokeWidth="1.8125" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M14 27.1226C21.2487 27.1226 27.125 21.2463 27.125 13.9976C27.125 6.74882 21.2487 0.872559 14 0.872559C6.75126 0.872559 0.875 6.74882 0.875 13.9976C0.875 21.2463 6.75126 27.1226 14 27.1226Z" stroke={stroke} strokeWidth="1.8125" strokeLinecap="round" strokeLinejoin="round" />
      </g>
      <defs>
        <clipPath id="clip0_11617_165517">
          <rect width="28" height="28" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default PauseButtonIcon;
