import { useState } from 'react';
import { useLocale, useSnackbar } from 'util/hooks';

import {
  Alert,
  Box,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { sendVerificationEmail } from 'services';

const PendingEmailVerification = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useLocale();
  const snack = useSnackbar();

  const resendVerificationEmail = async () => {
    setIsLoading(true);
    try {
      const { message } = await sendVerificationEmail();
      snack({
        message,
        severity: 'success',
      });
    } catch (error) {
      snack({
        message: error.message,
        severity: 'error',
      });
    }
    setIsLoading(false);
  };

  return (
    <Box
      sx={{
        mt: 4,
        mx: {
          xs: 2,
          sm: 6,
          md: 12,
        },
      }}
    >
      <Alert
        severity="error"
        action={(
          <LoadingButton
            size="small"
            sx={{
              color: 'secondary.main',
            }}
            onClick={resendVerificationEmail}
            loading={isLoading}
          >
            {t('emailVerification.resendLink')}
          </LoadingButton>
        )}
      >
        {t('emailVerification.verificationIncomplete')}
      </Alert>
    </Box>
  );
};

export default PendingEmailVerification;
