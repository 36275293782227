import {
  Box, Button, Link, Typography,
} from '@mui/material';
import { PATHS } from 'routes';
import { useLocale, usePermission } from 'util/hooks';
import PERMISSIONS from 'assets/constants/permissions';

const ChallengesHeader = () => {
  const { t } = useLocale();

  const canCreateChallenge = usePermission(PERMISSIONS.challengesCreate);

  return (
    <Box sx={{
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: {
        xs: 'column',
        md: 'row',
      },
      mb: 6,
      mt: 15,
    }}
    >
      <Box>
        <Typography variant="bodyLargeBold">
          {t('common.challenges')}
        </Typography>
      </Box>
      {canCreateChallenge && (
        <Box sx={{ mt: { xs: 3, md: 0 } }}>
          <Button
            component={Link}
            variant="contained"
            to={PATHS.createChallenge}
          >
            {t('challenges.createNew')}
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default ChallengesHeader;
