import { useTheme } from '@mui/styles';

const PeopleKeyIcon = (props) => {
  const { palette } = useTheme();
  const {
    stroke = palette.text.orange,
    ...rest
  } = props;

  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <g clipPath="url(#clip0_10476_148494)">
        <path d="M17.9683 17.522C16.114 15.8858 13.723 14.9883 11.25 15.0003C8.80084 14.9811 6.4298 15.8614 4.58656 17.4742C2.74332 19.0871 1.55608 21.3203 1.25 23.7503" stroke={stroke} strokeWidth="2.53125" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M11.25 12.5C14.3566 12.5 16.875 9.9816 16.875 6.875C16.875 3.7684 14.3566 1.25 11.25 1.25C8.1434 1.25 5.625 3.7684 5.625 6.875C5.625 9.9816 8.1434 12.5 11.25 12.5Z" stroke={stroke} strokeWidth="2.53125" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M27.5 10C29.9162 10 31.875 8.04125 31.875 5.625C31.875 3.20875 29.9162 1.25 27.5 1.25C25.0838 1.25 23.125 3.20875 23.125 5.625C23.125 8.04125 25.0838 10 27.5 10Z" stroke={stroke} strokeWidth="2.53125" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M34 14.999C33.4032 13.9665 32.5688 13.0911 31.5661 12.4456C30.5633 11.8001 29.4211 11.403 28.2341 11.2873C27.0472 11.1716 25.8497 11.3407 24.7412 11.7804C23.6326 12.2201 22.6449 12.9178 21.86 13.8157" stroke={stroke} strokeWidth="2.53125" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M25.9582 31.541C26.0455 31.6285 26.1049 31.7399 26.1289 31.8611C26.1528 31.9823 26.1404 32.1079 26.093 32.222C26.0457 32.3361 25.9656 32.4336 25.8628 32.5022C25.7601 32.5708 25.6393 32.6074 25.5157 32.6074C25.3922 32.6074 25.2714 32.5708 25.1687 32.5022C25.0659 32.4336 24.9858 32.3361 24.9385 32.222C24.8911 32.1079 24.8787 31.9823 24.9026 31.8611C24.9266 31.7399 24.986 31.6285 25.0732 31.541C25.1313 31.4828 25.2003 31.4367 25.2762 31.4052C25.3521 31.3736 25.4335 31.3574 25.5157 31.3574C25.598 31.3574 25.6794 31.3736 25.7553 31.4052C25.8312 31.4367 25.9002 31.4828 25.9582 31.541Z" stroke={stroke} strokeWidth="2.53125" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M31.8101 29.518L37.9567 23.3714C38.2084 23.1197 38.4081 22.8209 38.5443 22.492C38.6805 22.1631 38.7506 21.8107 38.7506 21.4547C38.7506 21.0988 38.6805 20.7463 38.5443 20.4174C38.4081 20.0886 38.2084 19.7898 37.9567 19.5381C37.705 19.2864 37.4062 19.0867 37.0774 18.9505C36.7485 18.8143 36.396 18.7441 36.0401 18.7441C35.6841 18.7441 35.3316 18.8143 35.0028 18.9505C34.6739 19.0867 34.3751 19.2864 34.1234 19.5381L27.9834 25.6897C26.5358 25.1227 24.9372 25.0712 23.4562 25.5441C21.9752 26.0169 20.7021 26.9851 19.8509 28.2861C18.9997 29.587 18.6224 31.1413 18.7822 32.6877C18.9421 34.2342 19.6294 35.6784 20.7287 36.7777C21.8281 37.877 23.2723 38.5644 24.8187 38.7242C26.3651 38.8841 27.9194 38.5067 29.2204 37.6555C30.5213 36.8043 31.4896 35.5313 31.9624 34.0502C32.4352 32.5692 32.3838 30.9706 31.8167 29.523L31.8101 29.518Z" stroke={stroke} strokeWidth="2.53125" strokeLinecap="round" strokeLinejoin="round" />
      </g>
      <defs>
        <clipPath id="clip0_10476_148494">
          <rect width="40" height="40" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default PeopleKeyIcon;
