import END_POINTS from 'services/endPoints';
import api from '../api';

const getMachineDetails = (name, options) => api(
  END_POINTS.machine(name),
  options,
)
  .then((res) => Promise.resolve(res))
  .catch((error) => Promise.reject(error));

export default getMachineDetails;
