import END_POINTS from '../endPoints';
import METHODS from '../methods';
import api from '../api';

const verifyEmail = (data) => api(
  END_POINTS.verifyEmail,
  {
    data,
    method: METHODS.POST,
    pagination: null,
    requireAuth: false,
  },
)
  .then((res) => Promise.resolve(res))
  .catch((error) => Promise.reject(error));

export default verifyEmail;
