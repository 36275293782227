import {
  madaLogo,
  mastercardLogo,
  visaLogo,
} from 'assets/images';

export const DEFAULT_CARD_FORMAT = /(\d{1,4})/g;

// https://github.com/medipass/react-payment-inputs/blob/master/src/utils/cardTypes.js
export const CARD_TYPES = [
  {
    // https://github.com/bendrucker/creditcards-types/blob/master/types/mada.js
    displayName: 'Mada',
    type: 'mada',
    format: DEFAULT_CARD_FORMAT,
    gaps: [4, 8, 12],
    lengths: [16],
    startPattern: /^(4(0(0861|1757|6996|7(197|395)|9201)|1(2565|0685|7633|9593)|2(281(7|8|9)|8(331|67(1|2|3)))|3(1361|2328|4107|9954)|4(0(533|647|795)|5564|6(393|404|672))|5(5(036|708)|7865|7997|8456)|6(2220|854(0|1|2|3))|8(301(0|1|2)|4783|609(4|5|6)|931(7|8|9))|93428)|5(0(4300|6968|8160)|13213|2(0058|1076|4(130|514)|9(415|741))|3(0(060|906)|1(095|196)|2013|5(825|989)|6023|7767|9931)|4(3(085|357)|9760)|5(4180|7606|8848)|8(5265|8(8(4(5|6|7|8|9)|5(0|1))|98(2|3))|9(005|206)))|6(0(4906|5141)|36120)|9682(0(1|2|3|4|5|6|7|8|9)|1(0|1)))\d{10}$/,
    code: {
      name: 'CVC',
      length: 3,
    },
    logo: madaLogo,
  },
  {
    displayName: 'Mastercard',
    type: 'mastercard',
    format: DEFAULT_CARD_FORMAT,
    startPattern: /^(5[1-5]|677189)|^(222[1-9]|2[3-6]\d{2}|27[0-1]\d|2720)/,
    gaps: [4, 8, 12],
    lengths: [16],
    code: {
      name: 'CVC',
      length: 3,
    },
    logo: mastercardLogo,
  },
  {
    displayName: 'Visa',
    type: 'visa',
    format: DEFAULT_CARD_FORMAT,
    startPattern: /(\d{1,4})(\d{1,4})?(\d{1,4})?(\d{1,4})?(\d{1,3})?/,
    gaps: [4, 8, 12],
    lengths: [16, 18, 19],
    code: {
      name: 'CVV',
      length: 3,
    },
    logo: visaLogo,
  },
];

export const getCardTypeByValue = (value) => CARD_TYPES
  // .filter((cardType) => cardType.startPattern.test(value))[0];
  .find((cardType) => cardType.startPattern.test(value)) || {};

export const getCardTypeByType = (type) => CARD_TYPES
  .filter((cardType) => cardType.type === type)[0];
