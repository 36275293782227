import { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useController } from 'react-hook-form';

import { InputLabel } from '@mui/material';
import { TimezoneSelect } from 'components/molecules';

const FormTimezoneSelect = (props) => {
  const {
    autoCompleteProps,
    textFieldProps,
    labelProps,
    label,
    ...rest
  } = props;

  const { field, fieldState } = useController(rest);
  const { onChange } = field;
  const { error } = fieldState;

  const hasErrored = Boolean(error);
  const onFieldChange = useCallback((event, newValue) => {
    onChange(newValue || '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <InputLabel
        htmlFor={field.name}
        sx={(theme) => ({
          px: 2,
          color: 'text.secondary',
          ...theme.typography.bodyStandardRegular,
        })}
        {...labelProps}
      >
        {label}
      </InputLabel>
      <TimezoneSelect
        autoCompleteProps={{
          ...field,
          onChange: onFieldChange,
          ...autoCompleteProps,
        }}
        textFieldProps={{
          error: hasErrored,
          helperText: error?.message,
          ...textFieldProps,
        }}
      />
    </>
  );
};

FormTimezoneSelect.propTypes = {
  autoCompleteProps: PropTypes.oneOfType([PropTypes.object]),
  textFieldProps: PropTypes.oneOfType([PropTypes.object]),
  labelProps: PropTypes.oneOfType([PropTypes.object]),
  label: PropTypes.string,
};

FormTimezoneSelect.defaultProps = {
  autoCompleteProps: {},
  textFieldProps: {},
  labelProps: {},
  label: '',
};

export default memo(FormTimezoneSelect);
