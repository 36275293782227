import { useTheme } from '@mui/styles';

const BloodDropWithHashIcon = (props) => {
  const { palette } = useTheme();
  const {
    stroke = palette.text.red,
    fill = 'none',
    ...rest
  } = props;

  return (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <path d="M15.1457 19.1773C13.9853 20.3377 12.4115 20.9896 10.7705 20.9896C9.12948 20.9896 7.55567 20.3377 6.39528 19.1773C5.2349 18.0169 4.58301 16.4431 4.58301 14.8021C4.58301 10.3379 8.66034 3.60777 10.1948 1.23544C10.2566 1.13872 10.3417 1.05912 10.4423 1.00397C10.5429 0.948829 10.6558 0.919922 10.7705 0.919922C10.8852 0.919922 10.9981 0.948829 11.0987 1.00397C11.1994 1.05912 11.2844 1.13872 11.3462 1.23544C11.4698 1.42662 11.61 1.6461 11.7632 1.89042M11.7632 1.89042C12.248 2.66341 12.8634 3.68501 13.4998 4.84542M11.7632 1.89042L13.4998 4.84542M13.4998 4.84542C13.7193 5.24578 13.9413 5.66267 14.1613 6.09157" stroke={stroke} strokeWidth="1.375" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M10.7705 18.243C10.3189 18.243 9.87164 18.154 9.4544 17.9811C9.03716 17.8081 8.65809 17.5547 8.33886 17.2352C8.01962 16.9157 7.76647 16.5364 7.59388 16.1191C7.42129 15.7017 7.33265 15.2544 7.33301 14.8027" stroke={stroke} strokeWidth="1.375" strokeLinecap="round" strokeLinejoin="round" />
      <g clipPath="url(#clip0_9897_139996)">
        <path d="M12.3438 11.2148H19.1793" stroke={stroke} strokeWidth="0.776582" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M11.292 14.373H18.1275" stroke={stroke} strokeWidth="0.776582" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M15.2355 8.58984L12.6064 16.477" stroke={stroke} strokeWidth="0.776582" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M17.8654 8.58984L15.2363 16.477" stroke={stroke} strokeWidth="0.776582" strokeLinecap="round" strokeLinejoin="round" />
      </g>
      <defs>
        <clipPath id="clip0_9897_139996">
          <rect width="9.49947" height="9.49947" fill={fill} transform="translate(11 8)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default BloodDropWithHashIcon;
