import { useTheme } from '@mui/styles';

const FileReturnIcon = (props) => {
  const theme = useTheme();
  const {
    stroke = theme.palette.text.secondary,
    fill = 'none',
    ...rest
  } = props;

  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill={fill} stroke={stroke} xmlns="http://www.w3.org/2000/svg" {...rest}>
      <path d="M19.5 15.75H23.25V11.25" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M23.0616 15.75C22.7239 14.441 21.9539 13.2845 20.8766 12.4678C19.7993 11.6511 18.4778 11.2222 17.1263 11.2505C15.7747 11.2788 14.4723 11.7627 13.4302 12.6238C12.388 13.4849 11.6672 14.6727 11.3846 15.9947C11.1019 17.3167 11.274 18.6954 11.8729 19.9073C12.4718 21.1193 13.4624 22.0935 14.6842 22.672C15.9061 23.2505 17.2874 23.3995 18.6045 23.0947C19.9216 22.79 21.0971 22.0494 21.9406 20.993" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M8.25 20.25H2.25C1.85218 20.25 1.47064 20.092 1.18934 19.8107C0.908035 19.5294 0.75 19.1478 0.75 18.75V2.25C0.75 1.85218 0.908035 1.47064 1.18934 1.18934C1.47064 0.908035 1.85218 0.75 2.25 0.75H12.879C13.2765 0.750085 13.6578 0.907982 13.939 1.189L16.811 4.061C17.092 4.3422 17.2499 4.72345 17.25 5.121V8.25" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default FileReturnIcon;
