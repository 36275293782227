import {
  Box,
  Card,
  Chip,
  Typography,
} from '@mui/material';
import { MachineAvatar, Tag } from 'components/molecules';

import { useLocale } from 'util/hooks';
import { getDifficultyByName } from 'assets/constants/difficulties';
import { capitalizeFirstLetter } from 'util/helpers';

const MachineOverview = (props) => {
  const {
    machineDetails = {},
  } = props;

  const {
    name = '',
    type = '',
    tags = [],
    author = '',
    difficulty = '',
  } = machineDetails;

  const { t } = useLocale();

  const machineType = type?.name?.toLowerCase();

  return (
    <Card sx={{ p: 8 }}>
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
      }}
      >
        <Box sx={{
          display: 'flex',
          alignItems: 'start',
        }}
        >
          <MachineAvatar
            machineType={machineType}
            sx={{
              width: 65,
              height: 65,
              mr: 7,
            }}
          />
          <Box sx={{ width: 1 }}>
            <Box sx={{
              width: 1,
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              flexWrap: 'wrap',
            }}
            >
              <Box sx={{
                display: 'flex',
                alignItems: 'center',
                flexWrap: 'wrap',
                mr: 2,
              }}
              >
                <Typography
                  variant="bodyMediumMedium"
                  color="text.lighterGray"
                  sx={{ mr: 3 }}
                >
                  {capitalizeFirstLetter(name)}
                </Typography>
                <Box>
                  <Chip
                    size="small"
                    color={difficulty || ''}
                    label={t(getDifficultyByName(difficulty)?.localizationKey) || ''}
                  />
                </Box>
              </Box>
              <Box>
                <Typography
                  variant="bodySmallMedium"
                  color="text.secondary"
                >
                  {`${t('common.by')} ${author}`}
                </Typography>
              </Box>
            </Box>
            <Box>
              <Typography
                variant="bodySmallMedium"
                color="text.secondary"
              >
                {capitalizeFirstLetter(type?.name)}
              </Typography>
            </Box>
            <Box sx={{
              display: 'flex',
              flexWrap: 'wrap',
              rowGap: 2,
              mt: 4,
            }}
            >
              {tags?.map((tag) => (
                <Tag
                  key={tag}
                  label={tag}
                  sx={{
                    mr: 4,
                  }}
                />
              ))}
            </Box>
          </Box>
        </Box>
      </Box>
    </Card>
  );
};

export default MachineOverview;
