import {
  Chip,
  Link,
  Stack,
  Typography,
} from '@mui/material';
import { GridActionsCellItem } from '@mui/x-data-grid';
import BAN_STATE from 'assets/constants/banState';
import MODAL_KEYS from 'assets/constants/modalKeys';
import { STATUS, getStatusByName } from 'assets/constants/statuses';
import { PeopleWithBlockIcon, OpenEyeIcon, PeopleWithRecycleIcon } from 'assets/icons';
import { AvatarWithFallback } from 'components/molecules';
import { capitalizeFirstLetter } from 'util/helpers';
import { useLocale, useModal } from 'util/hooks';

const TeamPreview = (props) => {
  const { team = {} } = props;
  const { name = '', motto = '', logo = '' } = team;

  return (
    <Stack direction="row" gap={3}>
      <AvatarWithFallback
        src={logo}
        alt={name}
        sx={{
          width: 36,
          height: 36,
          fontWeight: 'bold',
          fontSize: 23,
        }}
      />
      <Stack justifyContent="center" gap={1}>
        <Typography variant="bodySmallMedium" color="text.primary">{capitalizeFirstLetter(name)}</Typography>
        <Typography variant="bodyTinyMedium" color="text.secondary">{motto}</Typography>
      </Stack>
    </Stack>
  );
};

const useTableColumns = ({ refetchEvenTeams }) => {
  const { t } = useLocale();
  const { addModal } = useModal();

  const handleToggleBan = (row, action) => (
    addModal({
      key: MODAL_KEYS.eventTeamBanning,
      props: {
        teamDetails: row,
        refetch: refetchEvenTeams,
        action,
      },
    })
  );

  const columns = [
    {
      field: 'name',
      headerName: t('events.teams.name'),
      sortable: false,
      flex: 1,
      minWidth: 170,
      renderCell: ({ row: team }) => (
        <TeamPreview team={team} />
      ),
    },
    {
      field: 'membersCount',
      headerName: t('events.teams.membersCount'),
      flex: 1,
      minWidth: 130,
    },
    {
      field: 'status',
      headerName: t('common.status'),
      flex: 1,
      minWidth: 100,
      renderCell: (params) => (
        <Chip
          size="small"
          color={params.row.status || ''}
          label={t(getStatusByName(params?.row?.status)?.localizationKey) || ''}
        />
      ),
    },
    {
      field: 'actions',
      headerName: t('common.actions'),
      type: 'actions',
      flex: 1,
      minWidth: 50,
      getActions: ({ row }) => {
        const { status, id: teamId } = row;
        const isActive = status === STATUS.active;
        const banToggleLabel = isActive ? 'banTeam' : 'unbanTeam';

        const actions = [
          <GridActionsCellItem
            component={Link}
            icon={<OpenEyeIcon width="20" height="20" />}
            label={t('common.view')}
            key="view"
            to={teamId}
            sx={{ minWidth: 180 }}
            showInMenu
          />,
        ];

        const banAction = isActive
          ? BAN_STATE.ban
          : BAN_STATE.unban;

        actions.push(
          <GridActionsCellItem
            key="eventTeamBanning"
            icon={isActive ? <PeopleWithBlockIcon width="20" height="20" />
              : <PeopleWithRecycleIcon width="20" height="20" />}
            label={t(`events.teams.${banToggleLabel}`)}
            onClick={() => handleToggleBan(row, banAction)}
            showInMenu
          />,
        );

        return actions;
      },
    },
  ];

  return columns;
};

export default useTableColumns;
