import { getAllMachines } from 'services';
import { useCustomQuery } from 'reactQuery';

const useMachines = (queryProps = {}) => {
  const query = useCustomQuery({
    queryKey: ['machines'],
    queryFn: getAllMachines,
    ...queryProps,
  });

  return { ...query };
};

export default useMachines;
