import { useTheme } from '@mui/styles';

const HighlightOff = (props) => {
  const theme = useTheme();
  const {
    stroke = theme.palette.text.secondary,
    fill = 'none',
    ...rest
  } = props;

  return (
    <svg width="100%" height="100%" viewBox="0 0 34 34" fill={fill} xmlns="http://www.w3.org/2000/svg" stroke={stroke} {...rest}>
      <path d="M9.53125 24.4667L24.4646 9.53339" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M24.4646 24.4667L9.53125 9.53339" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M17 33C25.8366 33 33 25.8366 33 17C33 8.16344 25.8366 1 17 1C8.16344 1 1 8.16344 1 17C1 25.8366 8.16344 33 17 33Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default HighlightOff;
