import {
  useCallback,
} from 'react';

import { DataGrid } from 'components/core';
import { activateUser, suspendUser } from 'services';
import {
  useLocale, useModal, usePermission, useSnackbar,
} from 'util/hooks';

import MODAL_KEYS from 'assets/constants/modalKeys';
import { useCompanyMembers } from 'reactQuery/queries';
import PERMISSIONS from 'assets/constants/permissions';
import useTableColumns from './useTableColumns';

const CompanyMembersTable = ({ companyName = '' }) => {
  // Modal
  const { addModal } = useModal();

  const { t } = useLocale();
  const snack = useSnackbar();

  const canViewAllCompanies = usePermission(PERMISSIONS.companyViewAll);
  const canViewOwnCompany = usePermission(PERMISSIONS.companyViewOne);
  const canViewCompanyMembers = canViewAllCompanies || canViewOwnCompany;

  const {
    data = { items: [], meta: {} },
    isFetching = true,
    refetch = () => { },
  } = useCompanyMembers({
    companyName,
    options: {
      enabled: canViewCompanyMembers,
    },
  });

  const {
    items: rows = [],
    meta = {},
  } = data || {};

  const onActivate = useCallback(async (params = {}) => {
    const { username } = params;

    try {
      await activateUser(username || '');
      refetch();
      snack({
        message: `${username} ${t('activateUser.activateSuccess')}`,
        severity: 'success',
      });
    } catch ({ errors }) {
      snack({
        message: errors[0].message,
        severity: 'error',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t]);

  const onSuspend = useCallback(async (params = {}) => {
    const { username } = params;

    try {
      await suspendUser(username || '');
      refetch();
      snack({
        message: `${username} ${t('suspendUser.suspendSuccess')}`,
        severity: 'success',
      });
    } catch ({ errors }) {
      snack({
        message: errors[0].message, // Idk why but ok
        severity: 'error',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t]);

  const onConfirm = (type, params) => {
    switch (type) {
      case 'activate':
        return onActivate(params);
      case 'suspend':
        return onSuspend(params);
      default:
        return () => { };
    }
  };

  const columns = useTableColumns({
    addModal: (params) => addModal({
      key: MODAL_KEYS.userStatus,
      props: {
        onConfirm,
        ...params,
      },
    }),
  });

  return (
    <DataGrid
      loading={isFetching}
      rows={rows}
      columns={columns}
      pagination={meta}
      componentsProps={{
        noRowsOverlay: { type: 'user' },
      }}
    />
  );
};

export default CompanyMembersTable;
