import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Divider,
  Grid,
  Link,
  Stack,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/styles';

import { CalendarIcon, LockCircleIcon } from 'assets/icons';
import { useEvents } from 'reactQuery/queries';
import { PATHS } from 'routes';
import { useDate, useLocale } from 'util/hooks';

const PlayerEventList = () => {
  const { t } = useLocale();
  const { palette } = useTheme();
  const { format } = useDate();

  const {
    data: {
      items: {
        past: pastEvents = [],
      } = {},
    } = {},
  } = useEvents();

  const eventList = pastEvents?.map((event) => {
    const {
      id = '',
      name = '',
      image = '',
      eventEndAt = '',
      isPrivate = false,
    } = event;

    return (
      <Grid
        key={id}
        item
        xl={3}
        lg={4}
        sm={6}
        xs={12}
      >
        <Card>
          <Link
            to={`/${PATHS.events}/${id}`}
            sx={{
              color: 'text.primary',
              fontWeight: 'normal', // reverses the effect of Link styling
            }}
          >
            <CardMedia
              sx={{ height: 170 }}
              image={image}
              title={name}
            />
            <CardContent>
              <Box sx={{
                display: 'flex',
                alignItems: 'start',
                justifyContent: 'space-between',
              }}
              >
                <Box height={58} overflow="hidden">
                  <Typography
                    variant="bodyMediumMedium"
                    sx={{
                      display: '-webkit-box',
                      overflow: 'hidden',
                      WebkitBoxOrient: 'vertical',
                      WebkitLineClamp: 2,
                    }}
                  >
                    {name}
                  </Typography>
                </Box>
                <Box width={16} height={16} mt={1}>
                  {isPrivate && <LockCircleIcon />}
                </Box>
              </Box>
              <Divider sx={{ mx: -4, my: 4 }} />
              <Stack
                spacing={2}
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <CalendarIcon
                  width="16"
                  height="16"
                  stroke={palette.text.darkGray}
                />
                <Typography
                  variant="bodySmallRegular"
                  color="text.secondary"
                >
                  {`${t('common.ended')} ${format(eventEndAt, 'PP')}`}
                </Typography>
              </Stack>
            </CardContent>
          </Link>
        </Card>
      </Grid>
    );
  });

  return (
    <Grid
      container
      rowSpacing={6}
      columnSpacing={8}
    >
      {eventList}
    </Grid>
  );
};

export default PlayerEventList;
