import api from 'services/api';
import END_POINTS from 'services/endPoints';

const getAnnouncements = (options) => api(
  END_POINTS.getAnnouncements,
  options,
)
  .then((res) => Promise.resolve(res))
  .catch((error) => Promise.reject(error));

export default getAnnouncements;
