import _ from 'lodash';
import {
  Box, Stack,
} from '@mui/material';

import { NoContentPlaceholder } from 'components/molecules';
import { useLocale } from 'util/hooks';

import { useLatestChallengesMachines } from 'reactQuery/queries';
import LatestChallengesMachinesCard from '../helpers/LatestChallengesMachinesCard';
import DashboardSectionHeader from '../helpers/DashboardSectionHeader';

const PlayerLatestChallengesMachinesWrapper = ({ children, sx }) => {
  const { t } = useLocale();

  return (
    <Box sx={{
      width: 1,
      height: 1,
      display: 'flex',
      flexDirection: 'column',
      // justifyContent: 'center',
      // alignItems: 'center',
      ...sx,
    }}
    >
      <Box sx={{ mb: 2 }}>
        <DashboardSectionHeader title={t('dashboard.latestReleases')} />
      </Box>
      {children}
    </Box>
  );
};

const PlayerLatestChallengesMachines = () => {
  const { t } = useLocale();

  const {
    data: latestChallengesMachines = [],
    isFetched = false,
  } = useLatestChallengesMachines();

  if (isFetched && _.isEmpty(latestChallengesMachines)) {
    const content = {
      desc: [{
        text: t('common.noData'),
      }],
    };

    return (
      <PlayerLatestChallengesMachinesWrapper>
        <NoContentPlaceholder content={content} />
      </PlayerLatestChallengesMachinesWrapper>
    );
  }

  const cards = latestChallengesMachines?.map((entity) => (
    <LatestChallengesMachinesCard
      key={entity?.id}
      entity={entity}
    />
  ));

  return (
    <PlayerLatestChallengesMachinesWrapper>
      <Stack width={1} spacing={2}>
        {cards}
      </Stack>
    </PlayerLatestChallengesMachinesWrapper>
  );
};

export default PlayerLatestChallengesMachines;
