import { Stack, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useLocale } from 'util/hooks';

const ProfileEventsHeader = () => {
  const { t } = useLocale();
  const { username } = useParams();

  return (
    <Stack>
      <Typography
        variant="bodyMediumMedium"
        color="text.lighterGray"
      >
        {t('common.events')}
      </Typography>
      <Typography
        variant="bodySmallRegular"
        color="text.secondary"
      >
        {t('profile.eventHistory', { username })}
      </Typography>
    </Stack>
  );
};

export default ProfileEventsHeader;
