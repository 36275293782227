import { useState } from 'react';
import PropTypes from 'prop-types';
import { useController } from 'react-hook-form';

import {
  Box,
} from '@mui/material';

import { TextField, Tag } from 'components/molecules';
import { useLocale } from 'util/hooks';

const FormTags = (props) => {
  const {
    max,
    setError,
    ...rest
  } = props;

  const { field, fieldState } = useController(rest);
  const { value: tags, onChange } = field;
  const { error } = fieldState;

  const { t } = useLocale();
  const [textFieldValue, setTextFieldValue] = useState('');

  const hasErrored = Boolean(error);
  const disabled = tags.length >= max || rest.disabled;
  const helperText = error?.message || t('challenges.tagsHelper') || rest.helperText;

  const handleTextFieldChange = (e) => {
    const { value: newValue } = e.target;
    setTextFieldValue(newValue);
  };

  const onKeyDown = (e) => {
    if (e.keyCode === 13) {
      // prevent form submit when clicking enter
      e.preventDefault();

      const { value: newValue } = e.target;
      const trimmedValue = newValue.trim();
      const isTagPresent = tags.includes(trimmedValue);

      if (isTagPresent) {
        const errorMessage = `${t('challenges.tag')} "${trimmedValue}" ${t('challenges.alreadyAdded')}`;
        setError(errorMessage);
      } else if (trimmedValue && tags.length < max) {
        tags.push(trimmedValue);
        onChange(tags);
        setTextFieldValue('');
      }
    }
  };

  const handleDeleteTag = (deletedTag) => {
    onChange(tags.filter((tag) => tag !== deletedTag));
  };

  return (
    <Box>
      <TextField
        {...rest}
        value={textFieldValue}
        onChange={handleTextFieldChange}
        onKeyDown={onKeyDown}
        error={hasErrored}
        fullWidth
        disabled={disabled}
        helperText={helperText}
      />
      <Box sx={{ mt: 2 }}>
        {tags.map((tag) => (
          <Tag
            key={tag}
            label={tag}
            onDelete={() => handleDeleteTag(tag)}
            sx={{ mr: 2, mt: 2 }}
          />
        ))}
      </Box>
    </Box>
  );
};

FormTags.propTypes = {
  max: PropTypes.number,
};

FormTags.defaultProps = {
  max: 5,
};

export default FormTags;
