import {
  Link, Stack, Typography,
} from '@mui/material';
import { GridActionsCellItem } from '@mui/x-data-grid';
import {
  EditInfoIcon,
  OpenEyeIcon,
  UsersIcon,
} from 'assets/icons';
import { useLocale } from 'util/hooks';
import { useTheme } from '@mui/styles';

const useTableColumns = () => {
  const { palette } = useTheme();
  const { t, dir } = useLocale();

  const columns = [
    {
      field: 'company',
      headerName: t('common.company'),
      sortable: false,
      flex: 1,
      minWidth: 180,
      renderCell: ({ row: { name = '' } }) => (
        <Typography variant="bodySmallMedium" color="text.primary">
          {name}
        </Typography>
      ),
    },
    {
      field: 'members',
      headerName: t('common.members'),
      flex: 1,
      minWidth: 100,
      renderCell: ({ row: { membersCount = 0 } }) => (
        <Stack direction="row" spacing={2}>
          <UsersIcon width="16" height="16" stroke={palette.colors.mintGreen} />
          <Typography variant="bodySmallMedium" color="text.primary">
            {t('common.member', { count: membersCount })}
          </Typography>
        </Stack>
      ),
    },
    {
      field: 'actions',
      headerName: t('common.actions'),
      type: 'actions',
      flex: 1,
      minWidth: 50,
      getActions: ({ row: { name = '' } }) => ([
        <GridActionsCellItem
          component={Link}
          dir={dir}
          to={`${name}/members`}
          icon={<OpenEyeIcon width="20" height="20" />}
          label={t('actions.viewMembers')}
          showInMenu
        />,
        <GridActionsCellItem
          component={Link}
          dir={dir}
          icon={<EditInfoIcon width="20" height="20" />}
          label={t('companies.edit')}
          to={`${name}/edit`}
          showInMenu
        />,
      ])
      ,
    },
  ];

  return columns;
};

export default useTableColumns;
