import { getEvents } from 'services';
import { useCustomQuery } from 'reactQuery';
import { transformEvents } from 'util/transformers';

const useEvents = (queryProps = {}) => {
  const {
    queryKeyOptions = {},
    options = {},
    ...rest
  } = queryProps;

  const query = useCustomQuery({
    queryKey: ['events', queryKeyOptions],
    queryFn: getEvents,
    options: {
      select: transformEvents,
      ...options,
    },
    ...rest,
  });

  return { ...query };
};

export default useEvents;
