import { Box } from '@mui/material';
import { useLabDetails } from 'reactQuery/queries';

import LabHeader from './sections/LabHeader';
import LabInfo from './sections/LabInfo';

const ViewLabContainer = () => {
  const {
    data: labDetails = {},
  } = useLabDetails();

  return (
    <Box
      sx={{ display: 'flex', flexDirection: 'column' }}
    >
      <LabHeader labDetails={labDetails} />
      <LabInfo labDetails={labDetails} />
    </Box>
  );
};

export default ViewLabContainer;
