import { useCallback } from 'react';

const usePageTitle = () => {
  const setPageTitle = useCallback((title = 'Flagyard') => {
    document.title = title;
  }, []);

  return setPageTitle;
};

export default usePageTitle;
