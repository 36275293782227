import React from 'react';
import Particles from 'react-tsparticles';
import { loadFull } from 'tsparticles';

import bottomConfetti from './bottomConfetti';

const CONFETTI_OPTIONS = {
  bottom: bottomConfetti,
};

const defaultTimeout = 3000;

// tsParticles Repository: https://github.com/matteobruni/tsparticles
// tsParticles Website: https://particles.js.org/
const Confetti = (props) => {
  const {
    source = 'bottom',
    timeout = defaultTimeout,
  } = props;

  const containerRef = React.useRef();

  React.useEffect(() => () => {
    if (containerRef.current) {
      containerRef.current.stop();
    }
    containerRef.current = null;
  }, []);

  const options = CONFETTI_OPTIONS[source];
  const particlesInit = async (main) => {
    await loadFull(main);
  };

  const particlesLoaded = async (container) => {
    const emitters = container.plugins.get('emitters');
    setTimeout(() => {
      if (emitters.array[0]) {
        emitters.array[0].pause(); // turn off confetti and avoid crash
      }
    }, timeout);
  };

  return (
    <Particles
      id={`${source}-confetti`}
      container={containerRef}
      init={particlesInit}
      loaded={particlesLoaded}
      options={options}
    />
  );
};

export default Confetti;
