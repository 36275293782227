import {
  Typography,
} from '@mui/material';
import { useRecoilValue } from 'recoil';
import { checkoutAtom } from 'recoil/atoms';

import { useLocale } from 'util/hooks';

const CheckoutTitle = (props) => {
  const checkoutState = useRecoilValue(checkoutAtom);
  const {
    plan = {},
  } = checkoutState || {};
  const {
    nameAr = '',
    nameEn = '',
  } = plan || {};

  const { t, isAr } = useLocale();
  const planName = isAr ? nameAr : nameEn;

  return (
    <Typography variant="h5">
      {planName
        ? `${t('subscriptions.subscribeTo')} ${planName}`
        : t('subscriptions.subscribe')}
    </Typography>
  );
};

export default CheckoutTitle;
