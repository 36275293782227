import END_POINTS from 'services/endPoints';
import api from '../api';

const getChallengeCategories = () => api(
  END_POINTS.challengeCategories,
)
  .then((res) => Promise.resolve(res))
  .catch((error) => Promise.reject(error));

export default getChallengeCategories;
