import {
  useEffect, useMemo, useState,
} from 'react';

import * as Yup from 'yup';
import { useLocale, useFastForm, useSnackbar } from 'util/hooks';
import { useNavigate } from 'react-router-dom';

import {
  Box,
  Button,
  Card,
  Divider,
  Link,
  Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

import {
  getDirtyObject, numberWithoutCommas, REGEX,
} from 'util/helpers';
import {
  FormCheckbox,
  FormCheckboxArray,
  FormNumberField,
  FormTextField,
} from 'components/form';
import { createPlan, editPlan, getPlanFeatures } from 'services';
import {
  FileStarFilledIcon,
  CardMoneyIcon,
  PlansFilledIcon,
} from 'assets/icons';
import { PATHS } from 'routes';

const PlanDetailsForm = (props) => {
  const {
    createMode = false,
    planDetails = {
      features: [],
    },
  } = props;

  const snack = useSnackbar();
  const navigate = useNavigate();
  const { t, isAr } = useLocale();

  const [features, setFeatures] = useState([]);
  const localizedFeatures = features
    ?.map((feat) => ({
      ...feat,
      label: isAr
        ? feat.nameAr
        : feat.nameEn,
    }));

  useEffect(() => {
    const fetchPlanFeatures = async () => {
      try {
        const planFeatures = await getPlanFeatures();
        setFeatures(planFeatures);
      } catch (error) {
        const { errors: apiErrors } = error;
        snack({
          message: apiErrors?.[0]?.message || error.message,
          severity: 'error',
        });
      }
    };

    fetchPlanFeatures();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const defaultValues = useMemo(() => {
    const {
      nameEn,
      nameAr,
      isFree,
      pricePerMonth,
      pricePerYear,
    } = planDetails;

    return {
      nameEn: nameEn || '',
      nameAr: nameAr || '',
      features: planDetails?.features || [],
      isFree: isFree || false,
      pricePerMonth: pricePerMonth || '',
      pricePerYear: pricePerYear || '',
    };
  }, [planDetails]);

  const validationSchema = useMemo(() => Yup.object({
    nameEn: Yup
      .string()
      .trim()
      .matches(REGEX.alphaNumericSpace, t('plans.validation.enterEnglishName'))
      .required(t('plans.validation.enterEnglishName'))
      .max(64, t('plans.validation.maxLength')),
    nameAr: Yup
      .string()
      .trim()
      .matches(REGEX.alphaNumericAr, t('plans.validation.enterArabicName'))
      .required(t('plans.validation.enterArabicName'))
      .max(64, t('plans.validation.maxLength')),
    features: Yup.array()
      .of(Yup
        .object()
        .shape({ id: Yup.number() }))
      .test({
        name: 'atLeastOneTrue',
        message: t('plans.validation.minFeatures'),
        test: (arr) => arr.length > 0,
      }),
    isFree: Yup.bool(),
    pricePerMonth: Yup
      .number()
      .transform((o, v) => numberWithoutCommas(v))
      .when('isFree', {
        is: false,
        then: Yup
          .number()
          .required(t('plans.validation.enterPricePerMonth'))
          .typeError(t('plans.validation.enterOnlyNumbers'))
          .min(1, t('plans.validation.minPrice')),
      }).typeError(),
    pricePerYear: Yup
      .number()
      .transform((o, v) => numberWithoutCommas(v))
      .when('isFree', {
        is: false,
        then: Yup.number()
          .required(t('plans.validation.enterPricePerYear'))
          .typeError(t('plans.validation.enterOnlyNumbers'))
          .min(1, t('plans.validation.minPrice')),
      }).typeError(),
  }), [t]);

  const {
    control,
    handleSubmit,
    watch,
    setValue,
    setError,
    formState: {
      errors, isSubmitting, isDirty, isValid,
    },
  } = useFastForm({
    defaultValues,
    validationSchema,
  });

  const onSubmit = async (values) => {
    const dirtyPayload = getDirtyObject(values, defaultValues);

    try {
      let message = t('plans.updateSuccess');
      let navigateId = planDetails?.id;

      if (createMode) {
        const { id } = await createPlan(values);
        message = t('plans.createSuccess');
        navigateId = id;
      } else {
        await editPlan(dirtyPayload, planDetails.id);
      }

      snack({
        message,
        severity: 'success',
      });

      navigate(`/${PATHS.plans}/${navigateId}`);
    } catch (error) {
      error.errors?.forEach((err) => {
        setError(err.property, {
          type: 'api',
          message: err.message,
        });
        if (!err.property) {
          snack({
            severity: 'error',
            message: err.message || t('common.somethingWrong'),
          });
        }
      });

      if (!errors) { // Non-form errors
        snack({
          severity: 'error',
          message: error.message || t('common.somethingWrong'),
        });
      }
    }
  };

  const planDetailsForm = (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <PlansFilledIcon width="48" height="44" />
          <Typography variant="bodyStandardMedium" color="text.lightGray" sx={{ mx: 4 }}>
            {t('plans.planName')}
          </Typography>
        </Box>
        <Box sx={{
          display: 'grid',
          columnGap: 12,
          mx: { xs: 0, sm: 14 },
          gridTemplateColumns: {
            xs: 'repeat(1, 1fr)',
            md: 'repeat(2, 1fr)',
            lg: 'repeat(2, 304px)',
            xl: 'repeat(2, 420px)',
          },
        }}
        >
          <Box sx={{ mt: { xs: 4, sm: 0 } }}>
            <FormTextField
              name="nameEn"
              control={control}
              label={t('plans.planNameEn')}
              disabled={isSubmitting}
              inputProps={{ maxLength: 64 }}
              showCharCount
              fullWidth
              sx={{ m: 0 }}
            />
          </Box>
          <Box sx={{ mt: { xs: 4, sm: 0 } }}>
            <FormTextField
              name="nameAr"
              control={control}
              label={t('plans.planNameAr')}
              disabled={isSubmitting}
              inputProps={{ maxLength: 64 }}
              showCharCount
              fullWidth
              sx={{ m: 0 }}
            />
          </Box>
        </Box>
      </Box>
      <Divider sx={{ my: 12 }} />
      <Box>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <FileStarFilledIcon width="48" height="44" />
          <Typography variant="bodyStandardMedium" sx={{ mx: 4, color: 'text.lightGray' }}>
            {t('plans.planFeatures')}
          </Typography>
        </Box>
        <Box sx={{ ml: { sm: 16 }, my: 4 }}>
          <FormCheckboxArray
            name="features"
            control={control}
            options={localizedFeatures}
          />
          {errors?.features && (
            <Typography variant="bodySmallRegular" color="error.main">
              {errors.features?.message}
            </Typography>
          )}
        </Box>
      </Box>
      <Divider sx={{ my: 12 }} />
      <Box>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <CardMoneyIcon width="48" height="44" />
          <Typography variant="bodyStandardMedium" sx={{ mx: 4, color: 'text.lightGray' }}>
            {t('plans.planPrice')}
          </Typography>
        </Box>
        <Box sx={{ ml: { sm: 16 }, my: 4 }}>
          <FormCheckbox
            name="isFree"
            control={control}
            disabled={isSubmitting}
            checkboxProps={{
              checked: watch('isFree'),
              onChange: (e) => {
                const { checked } = e.target;
                const price = checked ? '0' : '';
                setValue('isFree', checked, {
                  shouldValidate: true,
                  shouldTouch: true,
                  shouldDirty: true,
                });
                setValue('pricePerMonth', price, {
                  shouldValidate: true,
                  shouldTouch: true,
                  shouldDirty: true,
                });
                setValue('pricePerYear', price, {
                  shouldValidate: true,
                  shouldTouch: true,
                  shouldDirty: true,
                });
              },
            }}
            label={(
              <div>
                <Typography variant="bodyStandardRegular">
                  {t('plans.freePlan')}
                </Typography>
              </div>
            )}
          />
        </Box>
        <Box sx={{
          display: 'grid',
          columnGap: 12,
          rowGap: 2,
          mx: { sm: 14 },
          gridTemplateColumns: {
            xs: 'repeat(1, 1fr)',
            md: 'repeat(2, 1fr)',
            lg: 'repeat(2, 304px)',
            xl: 'repeat(2, 420px)',
          },
        }}
        >
          <Box>
            <FormNumberField
              name="pricePerMonth"
              control={control}
              inputProps={{ maxLength: 10 }}
              label={t('plans.pricePerMonth')}
              disabled={isSubmitting || watch('isFree')}
              fullWidth
            />
          </Box>
          <Box>
            <FormNumberField
              name="pricePerYear"
              control={control}
              inputProps={{ maxLength: 10 }}
              label={t('plans.pricePerYear')}
              disabled={isSubmitting || watch('isFree')}
              fullWidth
            />
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          mt: 8,
        }}
      >
        <Box sx={{ mx: 2 }}>
          <LoadingButton
            type="submit"
            variant="contained"
            size="medium"
            loading={isSubmitting}
            disabled={!isDirty || isSubmitting || !isValid}
          >
            {t('common.saveAndContinue')}
          </LoadingButton>
        </Box>
        <Box>
          <Button
            component={Link}
            to={`/${PATHS.plans}`}
            disabled={isSubmitting}
            sx={{
              color: 'text.raspberry',
            }}
          >
            {t('common.cancel')}
          </Button>
        </Box>
      </Box>
    </form>
  );

  return (
    <Card
      id="plan-details-form"
      sx={{
        mt: 8,
        px: { xs: 6, sm: 14 },
        py: 8,
      }}
    >
      {planDetailsForm}
    </Card>
  );
};

export default PlanDetailsForm;
