import { useTheme } from '@mui/styles';

const EditInfoIcon = (props) => {
  const theme = useTheme();
  const {
    stroke = theme.palette.text.secondary,
    fill = 'none',
    ...rest
  } = props;

  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill={fill} xmlns="http://www.w3.org/2000/svg" stroke={stroke} {...rest}>
      <g clipPath="url(#clip0_2453_9899)">
        <path d="M15.0867 9.91357L10 15.0002L7.5 15.5002L8 13.0002L13.0867 7.91357C13.2176 7.78247 13.373 7.67846 13.5442 7.60749C13.7153 7.53653 13.8987 7.5 14.084 7.5C14.2693 7.5 14.4527 7.53653 14.6238 7.60749C14.795 7.67846 14.9504 7.78247 15.0813 7.91357L15.0867 7.9189C15.2178 8.0498 15.3218 8.20526 15.3927 8.3764C15.4637 8.54753 15.5002 8.73097 15.5002 8.91624C15.5002 9.1015 15.4637 9.28494 15.3927 9.45608C15.3218 9.62721 15.2178 9.78267 15.0867 9.91357V9.91357Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M5.5 13.5H1.5C1.23478 13.5 0.98043 13.3946 0.792893 13.2071C0.605357 13.0196 0.5 12.7652 0.5 12.5V1.5C0.5 1.23478 0.605357 0.98043 0.792893 0.792893C0.98043 0.605357 1.23478 0.5 1.5 0.5H8.586C8.85103 0.500057 9.1052 0.605321 9.29267 0.792667L11.2073 2.70733C11.3947 2.8948 11.4999 3.14897 11.5 3.414V5.5" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      </g>
      <defs>
        <clipPath id="clip0_2453_9899">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>

  );
};

export default EditInfoIcon;
