import { useTheme } from '@mui/styles';

const PeopleWithBlockIcon = (props) => {
  const { palette } = useTheme();
  const {
    stroke = palette.text.secondary,
    fill = 'none',
    ...rest
  } = props;

  return (
    <svg width="28" height="28" viewBox="0 0 28 28" fill={fill} xmlns="http://www.w3.org/2000/svg" {...rest}>
      <g clipPath="url(#clip0_10769_150570)">
        <path d="M20.125 27.125C23.991 27.125 27.125 23.991 27.125 20.125C27.125 16.259 23.991 13.125 20.125 13.125C16.259 13.125 13.125 16.259 13.125 20.125C13.125 23.991 16.259 27.125 20.125 27.125Z" stroke={stroke} strokeWidth="1.75" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M15.1758 25.0728L25.0738 15.1748" stroke={stroke} strokeWidth="1.75" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M12.579 12.2651C11.2805 11.1199 9.60639 10.4917 7.875 10.5C6.16059 10.4865 4.50086 11.1027 3.21059 12.2317C1.92033 13.3607 1.08926 14.9239 0.875 16.625" stroke={stroke} strokeWidth="1.75" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M7.875 8.75C10.0496 8.75 11.8125 6.98712 11.8125 4.8125C11.8125 2.63788 10.0496 0.875 7.875 0.875C5.70038 0.875 3.9375 2.63788 3.9375 4.8125C3.9375 6.98712 5.70038 8.75 7.875 8.75Z" stroke={stroke} strokeWidth="1.75" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M19.25 7C20.9414 7 22.3125 5.62887 22.3125 3.9375C22.3125 2.24613 20.9414 0.875 19.25 0.875C17.5586 0.875 16.1875 2.24613 16.1875 3.9375C16.1875 5.62887 17.5586 7 19.25 7Z" stroke={stroke} strokeWidth="1.75" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M23.7999 10.5C23.3823 9.77697 22.7982 9.16403 22.0961 8.71202C21.3941 8.26001 20.5943 7.98197 19.7633 7.90098C18.9323 7.81999 18.0939 7.93838 17.3178 8.24632C16.5417 8.55427 15.8502 9.04289 15.3008 9.67163" stroke={stroke} strokeWidth="1.75" strokeLinecap="round" strokeLinejoin="round" />
      </g>
      <defs>
        <clipPath id="clip0_10769_150570">
          <rect width="28" height="28" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default PeopleWithBlockIcon;
