import { useMemo } from 'react';
import { Stack } from '@mui/material';

import { useEvents } from 'reactQuery/queries';
import CurrentEventCard from './helpers/CurrentEventCard';

const PlayerCurrentEvents = () => {
  const {
    data: {
      items: {
        upcoming: upcomingEvents = [],
      } = {},
    } = {},
    isFetched = false,
  } = useEvents();

  const currentEventList = useMemo(() => upcomingEvents?.map((event) => (
    <CurrentEventCard
      key={event.name}
      eventDetails={event}
    />
  )), [upcomingEvents]);

  if (isFetched && !upcomingEvents?.length) {
    return null;
  }

  return (
    <Stack spacing={8}>
      {currentEventList}
    </Stack>
  );
};

export default PlayerCurrentEvents;
