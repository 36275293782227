import React, { useCallback } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import {
  Box,
  Card,
} from '@mui/material';

import { useLocale, useSnackbar } from 'util/hooks';
import { PaymentCardRedIcon } from 'assets/icons';

import { PATHS } from 'routes';
import { MAX_PAYMENT_CARDS } from 'assets/constants/paymentConstants';

import { SuspenseWrapper } from 'components/render';
import { usePaymentCards } from 'reactQuery/queries';
import PaymentSectionWrapper from './helpers/PaymentSectionWrapper';
import PaymentCardDetailsForm from '../PaymentCardDetailsForm/PaymentCardDetailsForm';

const AddPaymentCardContainer = () => {
  const { t } = useLocale();
  const snack = useSnackbar();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();

  const isRedirection = searchParams.has('success'); // coming from payment service

  const {
    data: paymentCards = [],
  } = usePaymentCards();

  const goToPaymentMethods = () => {
    navigate(`/${PATHS.settings}/${PATHS.paymentMethods}`, { replace: true });
  };

  const validateCanAddNewCard = () => {
    if (paymentCards?.length >= MAX_PAYMENT_CARDS) {
      // Show error and navigate to payment methods
      snack({
        severity: 'error',
        message: t('paymentMethods.cantAddMoreCards'),
      });

      goToPaymentMethods();
    }
  };

  const handleAddNewCardResult = useCallback(() => {
    // Handle adding a card successes and failures
    const success = searchParams.get('success');
    const message = searchParams.get('message');

    const isSuccess = success === 'true';

    // Show status message
    const snackMessage = message || (isSuccess
      ? t('paymentMethods.cardAddedSuccess')
      : t('common.errorMessage'));
    message && snack({
      severity: isSuccess ? 'success' : 'error',
      message: snackMessage,
    });

    if (isSuccess) {
      // Navigate to payment methods
      goToPaymentMethods();
    } else {
      // Cleanup url and remove query params
      navigate(pathname, { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, searchParams]);

  React.useEffect(() => {
    if (!isRedirection) {
      validateCanAddNewCard();
    } else {
      handleAddNewCardResult();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, searchParams]);

  return (
    <Box sx={{ width: 1 }}>
      <SuspenseWrapper>
        <Card sx={{
          px: {
            xs: 7,
            sm: 14,
          },
          py: 14,
        }}
        >
          <PaymentSectionWrapper
            icon={<PaymentCardRedIcon />}
            title={t('paymentMethods.cardDetails')}
          >
            <PaymentCardDetailsForm />
          </PaymentSectionWrapper>
        </Card>
      </SuspenseWrapper>
    </Box>
  );
};

export default AddPaymentCardContainer;
