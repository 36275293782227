import { forwardRef } from 'react';
import {
  MenuItem,
  Select as MUISelect,
} from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import { useTheme } from '@mui/styles';

const Select = forwardRef((props, ref) => {
  const {
    options = [],
    optionKey = 'id',
    optionValueKey = 'id',
    optionDisplayValue = 'label',
    optionProps = {},
    ...rest
  } = props;

  const theme = useTheme();

  return (
    <MUISelect
      IconComponent={ExpandMore}
      MenuProps={{
        dir: theme.direction,
        PaperProps: {
          sx: {
            backgroundColor: 'background.default',
            my: 1.5,
          },
        },
      }}
      variant="outlined"
      {...rest}
      ref={ref}
    >
      {options && options.map((option) => (
        <MenuItem key={option[optionKey]} value={option[optionValueKey]} {...optionProps}>
          {option[optionDisplayValue]}
        </MenuItem>
      ))}
    </MUISelect>
  );
});

export default Select;
