import { useLocale } from 'util/hooks';

import {
  Box,
  Card,
} from '@mui/material';

import { AnnouncementDetailsForm } from 'components/organisms';
import AnnouncementSectionWrapper from './helpers/AnnouncementSectionWrapper';

const EditAnnouncementContainer = () => {
  const { t } = useLocale();

  return (
    <Box sx={{ width: 1 }}>
      <Card sx={{
        px: { xs: 6, sm: 14 },
        py: 14,
      }}
      >
        <AnnouncementSectionWrapper
          title={t('announcements.edit')}
        >
          <AnnouncementDetailsForm />
        </AnnouncementSectionWrapper>
      </Card>
    </Box>
  );
};

export default EditAnnouncementContainer;
