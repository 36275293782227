import { useEffect } from 'react';
import { shouldPolyfill } from '@formatjs/intl-enumerator/should-polyfill';

// For reference: https://formatjs.io/docs/polyfills/intl-supportedvaluesof/

const Polyfill = () => {
  useEffect(() => {
    const polyfill = async () => {
      // This platform already supports Intl.supportedValuesOf
      if (shouldPolyfill()) {
        await import('@formatjs/intl-enumerator/polyfill');
      }
      // Alternatively, force the polyfill regardless of support
      await import('@formatjs/intl-enumerator/polyfill-force');
    };

    polyfill();
  }, []);

  return null;
};

export default Polyfill;
