import PropTypes from 'prop-types';
import { Badge } from '@mui/material';

import UserAwareAvatar from '../UserAwareAvatar/UserAwareAvatar';

const AvatarWithBadge = (props) => {
  const {
    badgeProps,
    avatarProps,
  } = props;

  return (
    <Badge
      overlap="circular"
      {...badgeProps}
    >
      <UserAwareAvatar {...avatarProps} />
    </Badge>
  );
};

AvatarWithBadge.propTypes = {
  badgeProps: PropTypes.oneOfType([PropTypes.object]),
  avatarProps: PropTypes.oneOfType([PropTypes.object]),
};

AvatarWithBadge.defaultProps = {
  badgeProps: {},
  avatarProps: {},
};

export default AvatarWithBadge;
