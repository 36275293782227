import { useTheme } from '@mui/styles';

const InformationCircleIcon = (props) => {
  const { palette } = useTheme();
  const {
    stroke = palette.text.lightGray,
    fill = 'white',
    ...rest
  } = props;

  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <g clipPath="url(#clip0_5800_64028)">
        <path d="M8.3125 9.625H7.875C7.64294 9.625 7.42038 9.53281 7.25628 9.36872C7.09219 9.20462 7 8.98206 7 8.75V6.5625C7 6.44647 6.95391 6.33519 6.87186 6.25314C6.78981 6.17109 6.67853 6.125 6.5625 6.125H6.125" stroke={stroke} strokeWidth="1.05" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M6.78125 3.9375C6.73799 3.9375 6.69569 3.95033 6.65972 3.97437C6.62375 3.9984 6.59571 4.03257 6.57915 4.07254C6.56259 4.11251 6.55826 4.15649 6.5667 4.19893C6.57514 4.24136 6.59598 4.28034 6.62657 4.31093C6.65716 4.34152 6.69614 4.36236 6.73857 4.3708C6.78101 4.37924 6.82499 4.37491 6.86496 4.35835C6.90493 4.34179 6.9391 4.31375 6.96313 4.27778C6.98717 4.24181 7 4.19951 7 4.15625C7 4.09823 6.97695 4.04259 6.93593 4.00157C6.89491 3.96055 6.83927 3.9375 6.78125 3.9375Z" stroke={stroke} strokeWidth="1.05" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M7 13.5625C10.6244 13.5625 13.5625 10.6244 13.5625 7C13.5625 3.37563 10.6244 0.4375 7 0.4375C3.37563 0.4375 0.4375 3.37563 0.4375 7C0.4375 10.6244 3.37563 13.5625 7 13.5625Z" stroke={stroke} strokeWidth="1.05" strokeLinecap="round" strokeLinejoin="round" />
      </g>
      <defs>
        <clipPath id="clip0_5800_64028">
          <rect width="14" height="14" fill={fill} />
        </clipPath>
      </defs>
    </svg>
  );
};

export default InformationCircleIcon;
