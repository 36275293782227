import {
  Chip,
  Link,
} from '@mui/material';
import { GridActionsCellItem } from '@mui/x-data-grid';

import { CrossCircleIcon, ExportIcon, OpenEyeIcon } from 'assets/icons';
import {
  useDate,
  useModal,
  useLocale,
} from 'util/hooks';

import MODAL_KEYS from 'assets/constants/modalKeys';
import { STATUS, getStatusByName } from 'assets/constants/statuses';

const staticNow = new Date();

const useTableColumns = ({ canExport = false, mutate = () => {} }) => {
  const { t } = useLocale();
  const { addModal } = useModal();
  const { format, isBefore } = useDate();

  const columns = [
    {
      field: 'name',
      headerName: t('common.name'),
      sortable: false,
      flex: 1,
      minWidth: 100,
    },
    {
      field: 'status',
      headerName: t('common.status'),
      flex: 1,
      renderCell: ({ row }) => {
        const { isLive, isComingSoon } = row;

        let eventState = '';

        if (isLive) {
          eventState = STATUS.live;
        } else if (isComingSoon) {
          eventState = STATUS.comingUp;
        } else {
          eventState = STATUS.ended;
        }

        return (
          <Chip
            size="small"
            color={eventState || ''}
            label={t(getStatusByName(eventState)?.localizationKey) || ''}
          />
        );
      },
    },
    {
      field: 'type',
      headerName: t('common.type'),
      flex: 1,
    },
    {
      field: 'pointsType',
      headerName: t('common.pointsType'),
      flex: 1,
      valueGetter: ({ row: { isDynamicPoints } }) => (isDynamicPoints ? t('common.dynamic') : t('common.static')),
    },
    {
      field: 'startDate',
      headerName: t('common.startDate'),
      flex: 1,
      minWidth: 130,
      valueGetter: ({ row: { eventStartAt } }) => format(eventStartAt, 'PPpp'),
    },
    {
      field: 'endDate',
      headerName: t('common.endDate'),
      flex: 1,
      minWidth: 130,
      valueGetter: ({ row: { eventEndAt } }) => format(eventEndAt, 'PPpp'),
    },

    {
      field: 'actions',
      headerName: t('common.actions'),
      type: 'actions',
      flex: 1,
      minWidth: 50,
      getActions: ({ row }) => {
        const { id = '', name = '', registrationStartAt = '' } = row;
        const registrationStarted = isBefore(new Date(registrationStartAt), staticNow);

        const actions = [
          <GridActionsCellItem
            key="view"
            component={Link}
            icon={<OpenEyeIcon width="20" height="20" />}
            label={t('common.view')}
            to={id}
            sx={{ minWidth: 180 }}
            showInMenu
          />];

        if (canExport) {
          actions.push(<GridActionsCellItem
            key="export"
            icon={(
              <ExportIcon
                width="20"
                height="20"
              />
              )}
            label={t('events.exportReport')}
            onClick={() => mutate({ eventId: id, eventName: name })}
            sx={{ minWidth: 180 }}
            showInMenu
          />);
        }

        if (!registrationStarted) {
          actions.push(<GridActionsCellItem
            key="delete"
            icon={(
              <CrossCircleIcon
                width="20"
                height="20"
              />
              )}
            label={t('common.delete')}
            onClick={() => addModal({
              key: MODAL_KEYS.deleteEvent,
              props: {
                eventDetails: row,
              },
            })}
            sx={{ minWidth: 180 }}
            showInMenu
          />);
        }

        return actions;
      },
    },
  ];

  return columns;
};

export default useTableColumns;
