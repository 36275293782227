import PropTypes from 'prop-types';

import {
  CalendarIcon,
  MoneyBagsIcon,
  MoneyFileIcon,
} from 'assets/icons';
import { useLocale } from 'util/hooks';
import { Box, Grid, Typography } from '@mui/material';
import { InfoCard } from 'components/molecules';

const PlanStats = (props) => {
  const {
    planDetails,
  } = props;

  const {
    createdAt = '',
    totalProfits = '',
    currentSubscribers = '',
  } = planDetails;

  const { t } = useLocale();

  const stats = [
    {
      key: 'plan-total-profit',
      icon: <MoneyBagsIcon width="40" height="40" />,
      title: t('plans.totalProfit'),
      component: (
        <Typography variant="bodyLargeBold">
          {`${totalProfits} ${t('currency.sar')}`}
        </Typography>
      ),
    },
    {
      key: 'plan-active-subscriptions',
      icon: <MoneyFileIcon width="40" height="40" />,
      title: t('plans.activeSubscriptions'),
      component: (
        <Typography variant="bodyLargeBold">
          {currentSubscribers}
        </Typography>
      ),
    },
    {
      key: 'plan-created-at',
      icon: <CalendarIcon width="40" height="40" />,
      title: t('common.createdAt'),
      component: (
        <Typography variant="bodyLargeBold">
          {new Date(createdAt)
            .toLocaleDateString(
              'en-US',
              {
                year: 'numeric',
                month: 'short',
                day: 'numeric',
              },
            )}
        </Typography>
      ),
    }];

  return (
    <Box sx={{ mb: 8 }}>
      <Grid container spacing={10}>
        {stats.map((stat) => <InfoCard {...stat} />)}
      </Grid>
    </Box>
  );
};

PlanStats.propTypes = {
  planDetails: PropTypes.oneOfType([PropTypes.object]),
};

PlanStats.defaultProps = {
  planDetails: {},
};

export default PlanStats;
