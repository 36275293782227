import React, { useState } from 'react';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { useSetRecoilState } from 'recoil';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import {
  Box, Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

import {
  useFastForm, useLocale, useRecaptcha, useSnackbar,
} from 'util/hooks';
import {
  FormCardNumberField,
  FormCheckbox,
  FormExpiryDateField,
  FormNumberField,
  FormTextField,
} from 'components/form';

import { getTokenizationData } from 'services';
import { numberWithoutSpaces, REGEX } from 'util/helpers';
import METHODS from 'services/methods';
import { PAYFORT_COMMANDS } from 'assets/constants/paymentConstants';
import { checkoutAtom } from 'recoil/atoms';
import { getCardTypeByValue } from 'util/cardTypes';
import { PATHS } from 'routes';

const { REACT_APP_PAYFORT_URL } = process.env;

const PaymentCardDetailsForm = (props) => {
  const {
    checkoutMode = false,
    isOnlyPaymentMethod = false,
  } = props;

  const { t } = useLocale();
  const snack = useSnackbar();
  const navigate = useNavigate();
  const formRef = React.useRef(null);
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const { executeRecaptcha } = useRecaptcha({ action: 'ADD_PAYMENT_CARD' });

  const [isLoading, setIsLoading] = useState(false);
  const setCheckoutState = useSetRecoilState(checkoutAtom);
  const showSetDefaultOption = !isOnlyPaymentMethod;

  React.useEffect(() => {
    const isTokenization = searchParams.get('command') === PAYFORT_COMMANDS.TOKENIZATION;

    if (isTokenization && searchParams.has('success')) {
      // Handle adding a card successes and failures
      const success = searchParams.get('success');
      const message = searchParams.get('message');

      const isSuccess = success === 'true';

      // Show status message
      const snackMessage = message || (isSuccess
        ? t('paymentMethods.cardAddedSuccess')
        : t('common.errorMessage'));
      message && snack({
        severity: isSuccess ? 'success' : 'error',
        message: snackMessage,
      });

      if (!checkoutMode && isSuccess) {
        // Navigate to payment methods
        navigate(`/${PATHS.settings}/${PATHS.paymentMethods}`, { replace: true });
      }

      // Cleanup url and remove query params
      navigate(pathname, { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, searchParams]);

  const fetchPayfortData = async (payload) => {
    try {
      const recaptchaToken = await executeRecaptcha();
      const payfortData = await getTokenizationData(payload, { recaptchaToken });
      return payfortData;
    } catch (error) {
      snack({
        message: error.message || t('common.errorMessage'),
        severity: 'error',
      });
      throw error;
    }
  };

  const defaultValues = {
    // card_number: '4005550000000001',
    // card_holder_name: 'Yo yo',
    // expiry_date: '05/25',
    // card_security_code: '123',
    // remember_me: 'YES',
    // isDefault: true,
    card_number: '',
    card_holder_name: '',
    expiry_date: '',
    card_security_code: '',
    remember_me: 'YES',
    isDefault: true,
    access_code: '',
    language: '',
    merchant_identifier: '',
    merchant_reference: '',
    return_url: '',
    service_command: '',
    signature: '',
  };

  const validationSchema = Yup.object({
    card_number: Yup
      .string()
      .transform((v) => numberWithoutSpaces(v))
      .matches(REGEX.digits, t('paymentMethods.validation.enterCardNumber'))
      .required(t('paymentMethods.validation.enterCardNumber'))
      .min(16, t('paymentMethods.validation.enterCardNumber')),
    card_holder_name: Yup
      .string()
      .trim()
      .matches(REGEX.alphabetEnSpace, t('paymentMethods.validation.enterCardHolderName'))
      .required(t('paymentMethods.validation.enterCardHolderName'))
      .min(3, t('paymentMethods.validation.enterCardHolderName')),
    expiry_date: Yup
      .string()
      .matches(REGEX.expiryDate, t('paymentMethods.validation.enterExpiryDate'))
      .required(t('paymentMethods.validation.enterExpiryDate'))
      .min(5, t('paymentMethods.validation.enterExpiryDate')),
    card_security_code: Yup
      .string()
      .matches(REGEX.digits, t('paymentMethods.validation.enterCvv'))
      .required(t('paymentMethods.validation.enterCvv'))
      .min(3, t('paymentMethods.validation.enterCvv')),
    isDefault: Yup.bool(),
  });

  const {
    register,
    control,
    handleSubmit,
    setError,
    watch,
    formState: {
      errors, isSubmitting, isDirty, isValid,
    },
  } = useFastForm({
    defaultValues,
    validationSchema,
  });

  const prepareFormData = (formValues, payfortData) => {
    // formValues
    if (formValues) {
      formRef.current.expiry_date.value = formValues.expiry_date?.split('/')?.reverse()?.join('');
      formRef.current.card_number.value = formValues.card_number?.replace(/\s/g, '');
      if (showSetDefaultOption) {
        // Prevent isDefault from being submitted to payfort
        formRef.current.isDefault.disabled = true;
      }
    }

    // payfortData
    if (payfortData) {
      formRef.current.access_code.value = payfortData.access_code;
      formRef.current.language.value = payfortData.language;
      formRef.current.merchant_identifier.value = payfortData.merchant_identifier;
      formRef.current.merchant_reference.value = payfortData.merchant_reference;
      formRef.current.return_url.value = payfortData.return_url;
      formRef.current.service_command.value = payfortData.service_command;
      formRef.current.signature.value = payfortData.signature;
    }
  };

  const onSubmit = async (values, e) => {
    // Stop default form submission
    e.preventDefault();

    if (checkoutMode) {
      // Disable checkout card and show loader
      setCheckoutState((prev) => ({
        ...prev,
        isCheckoutLoading: true,
      }));
    } else {
      setIsLoading(true);
    }

    const cardType = getCardTypeByValue(values.card_number)?.type;

    // Used to build a custom return url and validate signature
    const returnUrlParams = `?isDefault=${values.isDefault}&cardType=${cardType}`;

    try {
      // Step 1 - fetch payfort data
      const payfortData = await fetchPayfortData({
        return_url_params: returnUrlParams,
        service_command: PAYFORT_COMMANDS.TOKENIZATION,
        frontendReturnUrl: pathname,
      });

      // Step 2 - prepare form data
      prepareFormData(values, payfortData);

      // Step 3 - submit to payfort
      formRef?.current?.submit();
    } catch (error) {
      error.errors?.forEach((err) => {
        setError(err.property, {
          type: 'api',
          message: err.message,
        });
        if (!err.property || !watch(err.property)) {
          snack({
            severity: 'error',
            message: err.message || t('common.somethingWrong'),
          });
        }
      });

      if (!errors) { // Non-form errors
        snack({
          severity: 'error',
          message: error.message || t('common.somethingWrong'),
        });
      }
      setIsLoading(false);
    }
  };

  return (
    <form
      ref={formRef}
      method={METHODS.POST}
      action={REACT_APP_PAYFORT_URL}
      onSubmit={handleSubmit(onSubmit)}
    >
      <input type="hidden" {...register('access_code')} />
      <input type="hidden" {...register('language')} />
      <input type="hidden" {...register('merchant_identifier')} />
      <input type="hidden" {...register('merchant_reference')} />
      <input type="hidden" {...register('return_url')} />
      <input type="hidden" {...register('service_command')} />
      <input type="hidden" {...register('signature')} />
      <input type="hidden" {...register('remember_me')} />
      <Box>
        <Box sx={{
          display: 'grid',
          columnGap: checkoutMode ? 4 : 25,
          rowGap: 4,
          gridTemplateColumns: {
            xs: 'repeat(1, 1fr)',
            lg: 'repeat(2, 1fr)',
            xl: checkoutMode
              ? 'repeat(2, 262px)'
              : 'repeat(2, 420px)',
          },
        }}
        >
          <Box>
            <FormCardNumberField
              label={t('paymentMethods.cardNumber')}
              name="card_number"
              control={control}
              inputProps={{ maxLength: 22 }}
              fullWidth
            />
          </Box>
          <Box>
            <FormTextField
              label={t('paymentMethods.nameOnCard')}
              name="card_holder_name"
              control={control}
              fullWidth
            />
          </Box>
          <Box>
            <FormExpiryDateField
              label={t('paymentMethods.expiryDate')}
              name="expiry_date"
              control={control}
              inputProps={{ maxLength: 5 }}
              placeholder="MM/YY"
              fullWidth
            />
          </Box>
          <Box>
            <FormNumberField
              label="CVV"
              name="card_security_code"
              control={control}
              inputProps={{ maxLength: 3 }}
              fullWidth
            />
          </Box>
        </Box>
        {showSetDefaultOption && (
          <Box>
            <FormCheckbox
              name="isDefault"
              control={control}
              label={(
                <div>
                  <Typography variant="bodyStandardRegular">
                    {t('paymentMethods.setDefault')}
                  </Typography>
                </div>
              )}
            />
          </Box>
        )}
      </Box>
      <Box
        sx={{
          display: 'flex',
          mt: 8,
          justifyContent: checkoutMode
            ? 'flex-start'
            : 'flex-end',
        }}
      >
        <Box sx={{ mx: 2 }}>
          <LoadingButton
            type="submit"
            variant="contained"
            size="medium"
            loading={isSubmitting || isLoading}
            disabled={!isDirty || isSubmitting || isLoading || !isValid}
          >
            {t('paymentMethods.addCard')}
          </LoadingButton>
        </Box>
      </Box>
    </form>
  );
};

PaymentCardDetailsForm.propTypes = {
  checkoutMode: PropTypes.bool,
};

PaymentCardDetailsForm.defaultProps = {
  checkoutMode: false,
};

export default PaymentCardDetailsForm;
