import {
  Box,
  Card,
  Chip,
  Stack,
  Typography,
} from '@mui/material';

import { getDifficultyByName } from 'assets/constants/difficulties';
import { FileCheckedFilledIcon, ServerFilledIcon } from 'assets/icons';
import { useLocale } from 'util/hooks';

const PlayerSolutionsDifficultyBreakdown = (props) => {
  const {
    entity = '',
    profileDetails = {},
  } = props;

  const {
    challengeSubmissionsDifficultyBreakDown = {},
    machineSubmissionsDifficultyBreakDown = {},
  } = profileDetails || {};

  const { t } = useLocale();
  const isMachine = entity === 'machine';

  const dataObject = isMachine
    ? machineSubmissionsDifficultyBreakDown
    : challengeSubmissionsDifficultyBreakDown;

  const entitySubmissionsSum = Object
    .values(dataObject)
    .reduce((a, b) => a + b, 0);

  const icon = isMachine
    ? <ServerFilledIcon width="48" height="44" />
    : <FileCheckedFilledIcon width="48" height="44" />;

  const title = isMachine
    ? t('profile.hackedMachines')
    : t('profile.hackedChallenges');

  const description = `${entitySubmissionsSum || 0} ${isMachine
    ? t('common.machines')
    : t('common.challenges')}`;

  const difficultiesBreakdown = Object
    .keys(dataObject)
    ?.map((difficulty) => {
      const number = dataObject[difficulty];

      return (
        <Box
          key={difficulty}
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Typography
            variant="bodyStandardBold"
            color="text.lightGray"
            sx={{ mr: 2 }}
          >
            {number}
          </Typography>
          <Chip
            size="small"
            color={difficulty || ''}
            label={t(getDifficultyByName(difficulty)?.localizationKey) || ''}
          />
        </Box>
      );
    });

  return (
    <Card
      key={entity}
      sx={{
        px: 8,
        py: 5,
        flex: 1,
        minWidth: 300,
      }}
    >
      <Box sx={{
        display: 'flex',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
        columnGap: 2,
        rowGap: 7,
      }}
      >
        <Box sx={{
          display: 'flex',
          alignItems: 'center',
        }}
        >
          <Box sx={{
            width: 48,
            height: 44,
            mr: 4,
          }}
          >
            {icon}
          </Box>
          <Box>
            <Box>
              <Typography
                variant="bodySmallMedium"
                color="text.lightGray"
              >
                {title}
              </Typography>
            </Box>
            <Box>
              <Typography
                variant="bodyStandardBold"
                color="text.primary"
              >
                {description}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Stack
          direction="row"
          flexWrap="wrap"
          rowGap={2}
          columnGap={{
            xs: 2,
            md: 12,
          }}
        >
          {difficultiesBreakdown}
        </Stack>
      </Box>
    </Card>
  );
};

export default PlayerSolutionsDifficultyBreakdown;
