import { useMemo } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { Button } from '@mui/material';

import { checkoutAtom } from 'recoil/atoms';

import {
  useLocale, useSnackbar,
} from 'util/hooks';
import { PAYFORT_COMMANDS } from 'assets/constants/paymentConstants';
import { getPurchaseData } from 'services';
import { useRecoilState } from 'recoil';

const ConfirmPayment = () => {
  const { t } = useLocale();
  const snack = useSnackbar();
  const { pathname } = useLocation();
  const [, setSearchParams] = useSearchParams();

  const [checkoutState, setCheckoutState] = useRecoilState(checkoutAtom);
  const {
    plan,
    billingOption,
    paymentOption,
    promoCode,
  } = checkoutState || {};

  const isDisabled = useMemo(
    () => (!plan?.id
      || !billingOption
      || !paymentOption?.value
      || paymentOption?.value === 'newCard'),
    [billingOption, paymentOption?.value, plan?.id],
  );

  const fetchPayfortData = async (payload) => {
    try {
      const payfortData = await getPurchaseData(payload);
      return payfortData;
    } catch (error) {
      snack({
        message: error.message || t('common.errorMessage'),
        severity: 'error',
      });
      throw error;
    }
  };

  const handleClickConfirmPayment = async () => {
    setCheckoutState((prev) => ({
      ...prev,
      isCheckoutLoading: true,
    }));

    try {
      const payload = {
        // payfort
        service_command: PAYFORT_COMMANDS.PURCHASE,
        // Frontend
        frontendReturnUrl: pathname,
        ip: '109.202.238.190', // TODO: [PAYMENT] remove temp
        // Plan
        plan,
        promoCode,
        billingOption,
        paymentCard: { id: paymentOption?.value },
      };

      const data = await fetchPayfortData(payload);

      if (data?.recurringPurchase) {
        // Handle manual purchase of a recurring payment card
        const { command, success, message } = data;
        setSearchParams({
          command,
          success,
          message,
        });
      }

      if (data?.['3ds_url']) {
        // 3d secure
        window.location.replace(data['3ds_url']);
      }
    } catch (error) {
      snack({
        severity: 'error',
        message: error.message || t('common.somethingWrong'),
      });

      setCheckoutState((prev) => ({
        ...prev,
        isCheckoutLoading: false,
      }));
    }
  };

  return (
    <Button
      variant="contained"
      onClick={handleClickConfirmPayment}
      disabled={isDisabled}
    >
      {t('checkout.confirmPayment')}
    </Button>
  );
};

export default ConfirmPayment;
