import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import { LoadingButton } from '@mui/lab';
import {
  Box, Button, Stack, Typography,
} from '@mui/material';
import { getDependentsForRole } from 'assets/constants/userRoles';
import { InviteIcon } from 'assets/icons';
import { FormCheckbox, FormSelect, FormTextField } from 'components/form';
import { useInviteUser } from 'reactQuery/queries';
import { handleApiErrors } from 'util/helpers';
import { useFastForm, useLocale, useSnackbar } from 'util/hooks';
import { RoleId } from 'routes/authRoles';

const InviteUserForm = (props) => {
  const { user = {}, inviteCompany = false, companyName = '' } = props;

  const { t } = useLocale();
  const snack = useSnackbar();
  const navigate = useNavigate();

  const roleOptions = useMemo(
    () => (getDependentsForRole(user?.role.id) || [])
      .filter(({ id, invitable }) => {
        const companyMembers = [RoleId.companyManager, RoleId.companyOperator].includes(id);
        return (
          invitable && (companyName ? companyMembers : !companyMembers)
        );
      })
      .map((option) => ({ ...option, label: t(option.localizationKey) })),
    [user?.role.id, t, companyName],
  );

  const { mutate } = useInviteUser({
    onSuccess: (message, { inviteAnother, email }) => {
      inviteAnother ? reset({}) : navigateBack();
      snack({
        message: `${t('invitation.inviteMessage.invitation')} ${email} ${t('invitation.inviteMessage.confirm')}`,
        severity: 'success',
      });
    },
    onError: (error) => {
      handleApiErrors({ error, snack });
    },
  });

  const onSubmit = (values) => {
    const companyPayload = inviteCompany && { company: { name: companyName } };
    const payload = {
      ...values,
      ...companyPayload,
      role: { id: Number(values.role) },
    };
    mutate(payload);
  };

  const navigateBack = () => {
    navigate(-1);
  };

  const defaultValues = {
    email: '',
    role: roleOptions?.[0]?.id || '',
    inviteAnother: false,
  };

  const validationSchema = useMemo(
    () => Yup.object({
      email: Yup.string()
        .email(t('email.validation'))
        .required(t('invitation.enterEmail')),
      role: Yup.string().required(t('invitation.enterRole')),
    }),
    [t],
  );

  const {
    control,
    handleSubmit,
    reset,
    formState: { isSubmitting, isValid, isDirty },
  } = useFastForm({
    defaultValues,
    validationSchema,
  });

  const invitationForm = (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box
        sx={{
          display: 'grid',
          columnGap: 12,
          gridTemplateColumns: {
            xs: 'repeat(1, 1fr)',
            md: 'repeat(2, 1fr)',
            lg: 'repeat(2, 304px)',
            xl: 'repeat(2, 420px)',
          },
        }}
      >
        <Box sx={{ mt: 6 }}>
          <FormTextField
            name="email"
            type="email"
            control={control}
            label={t('common.email')}
            disabled={isSubmitting}
            fullWidth
          />
        </Box>
        <Box sx={{ mt: 6 }}>
          <FormSelect
            name="role"
            control={control}
            options={roleOptions}
            label={t('common.role')}
            disabled={isSubmitting}
            fullWidth
          />
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'row' },
          justifyContent: { xs: 'start', sm: 'end' },
          flexWrap: 'wrap',
          mt: 8,
        }}
      >
        <Box>
          <FormCheckbox
            name="inviteAnother"
            control={control}
            label={t('invitation.inviteAnother')}
          />
        </Box>
        <Stack direction="row" gap={3}>
          <Button onClick={navigateBack} disabled={isSubmitting}>
            {t('common.cancel')}
          </Button>
          <LoadingButton
            type="submit"
            variant="contained"
            loading={isSubmitting}
            disabled={!isValid || !isDirty || isSubmitting}
          >
            {t('invitation.sendInvitation')}
          </LoadingButton>
        </Stack>
      </Box>
    </form>
  );

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: {
          xs: 'column',
          md: 'row',
        },
        alignItems: {
          xs: 'center',
          md: 'start',
        },
      }}
    >
      <Box sx={{ width: 48, height: 44 }}>
        <InviteIcon width="48" height="44" />
      </Box>
      <Box
        sx={{
          width: {
            md: 1,
          },
          display: 'flex',
          flexDirection: 'column',
          mx: 5,
        }}
      >
        <Typography
          variant="bodyStandardMedium"
          sx={{ color: 'text.lightGray' }}
        >
          {t('invitation.inviteMembers')}
        </Typography>
        <Typography variant="bodySmallRegular" sx={{ color: 'text.secondary' }}>
          {t('invitation.inviteHeader')}
        </Typography>
        <Box>{invitationForm}</Box>
      </Box>
    </Box>
  );
};

export default InviteUserForm;
