import END_POINTS from 'services/endPoints';
import api from '../api';

const getAllPromoCodes = (options) => api(
  END_POINTS.promoCodes,
  options,
)
  .then((res) => Promise.resolve(res))
  .catch((error) => Promise.reject(error));

export default getAllPromoCodes;
