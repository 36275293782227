import END_POINTS from '../endPoints';
import METHODS from '../methods';
import api from '../api';

const suspendUser = (username) => api(
  END_POINTS.suspendUser(username),
  {
    method: METHODS.PUT,
  },
)
  .then((res) => Promise.resolve(res))
  .catch((error) => Promise.reject(error));

export default suspendUser;
