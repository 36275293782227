import END_POINTS from '../endPoints';
import METHODS from '../methods';
import api from '../api';

const editEvent = (eventId, data) => api(
  END_POINTS.event(eventId),
  {
    method: METHODS.PUT,
    data,
  },
)
  .then((res) => Promise.resolve(res))
  .catch((error) => Promise.reject(error));

export default editEvent;
