import { getEventTeams } from 'services';
import { useCustomQuery } from 'reactQuery';
import { useParams } from 'react-router-dom';

const useEventTeams = (queryProps = {}) => {
  const { eventId } = useParams();
  const {
    queryKeyOptions = {},
    ...rest
  } = queryProps;

  const query = useCustomQuery({
    queryKey: [`${eventId}-teams`, queryKeyOptions],
    queryFn: (options) => getEventTeams(eventId, options),
    ...rest,
  });

  return { ...query };
};

export default useEventTeams;
