import { useTheme } from '@mui/styles';

const ShieldGlobeIcon = (props) => {
  const { palette } = useTheme();
  const {
    stroke = palette.primaty.contrastText,
    fill = 'none',
    ...rest
  } = props;

  return (
    <svg width="28" height="29" viewBox="0 0 28 29" fill={fill} xmlns="http://www.w3.org/2000/svg" {...rest}>
      <g clipPath="url(#clip0_7344_101649)">
        <path d="M2.62504 5.33085V14.2139C2.62506 17.0216 3.47591 19.7634 5.06542 22.0779C6.65494 24.3924 8.9085 26.1709 11.529 27.179L12.7435 27.6457C13.5523 27.9568 14.4478 27.9568 15.2565 27.6457L16.471 27.179C19.0916 26.1709 21.3451 24.3924 22.9347 22.0779C24.5242 19.7634 25.375 17.0216 25.375 14.2139V5.33085C25.3773 4.99658 25.2829 4.66878 25.1032 4.38689C24.9236 4.10499 24.6663 3.881 24.3624 3.74185C21.0955 2.31594 17.5644 1.59596 14 1.62902C10.4357 1.59596 6.90456 2.31594 3.63771 3.74185C3.33376 3.881 3.07649 4.10499 2.89684 4.38689C2.71719 4.66878 2.6228 4.99658 2.62504 5.33085V5.33085Z" stroke={stroke} strokeWidth="1.8125" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M14 20.8789C17.866 20.8789 21 17.7449 21 13.8789C21 10.0129 17.866 6.87891 14 6.87891C10.134 6.87891 7 10.0129 7 13.8789C7 17.7449 10.134 20.8789 14 20.8789Z" stroke={stroke} strokeWidth="1.8125" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M14 6.87891C17.5 11.5456 17.5 16.2122 14 20.8789" stroke={stroke} strokeWidth="1.8125" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M14 6.87891C10.5 11.5456 10.5 16.2122 14 20.8789" stroke={stroke} strokeWidth="1.8125" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M7 13.8789H21" stroke={stroke} strokeWidth="1.8125" strokeLinecap="round" strokeLinejoin="round" />
      </g>
      <defs>
        <clipPath id="clip0_7344_101649">
          <rect width="28" height="28" fill="white" transform="translate(0 0.753906)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ShieldGlobeIcon;
