import { useTheme } from '@mui/styles';

const CalendarIcon = (props) => {
  const { palette } = useTheme();
  const {
    stroke = palette.text.lighterRaspberry,
    fill = 'none',
    ...rest
  } = props;

  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill={fill} xmlns="http://www.w3.org/2000/svg" stroke={stroke} {...rest}>
      <path d="M21.752 3.75H2.25195C1.42353 3.75 0.751953 4.42157 0.751953 5.25V21.75C0.751953 22.5784 1.42353 23.25 2.25195 23.25H21.752C22.5804 23.25 23.252 22.5784 23.252 21.75V5.25C23.252 4.42157 22.5804 3.75 21.752 3.75Z" strokeWidth="1.1" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M0.751953 9.75H23.252" strokeWidth="1.1" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M6.75195 6V0.75" strokeWidth="1.1" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M17.252 6V0.75" strokeWidth="1.1" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};
export default CalendarIcon;
