import { useTheme } from '@mui/styles';

const TrashBinIcon = (props) => {
  const theme = useTheme();
  const {
    stroke = theme.palette.text.secondary,
    fill = 'none',
    ...rest
  } = props;

  return (
    <svg width="28" height="28" viewBox="0 0 28 28" fill={fill} xmlns="http://www.w3.org/2000/svg" stroke={stroke} {...rest}>
      <path d="M1.75 5.25H26.25" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M16.625 1.75H11.375C10.9109 1.75 10.4658 1.93437 10.1376 2.26256C9.80937 2.59075 9.625 3.03587 9.625 3.5V5.25H18.375V3.5C18.375 3.03587 18.1906 2.59075 17.8624 2.26256C17.5342 1.93437 17.0891 1.75 16.625 1.75Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M11.375 20.125V11.375" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M16.625 20.125V11.375" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M22.0092 24.6447C21.9729 25.0823 21.7734 25.4902 21.4503 25.7876C21.1272 26.085 20.7041 26.25 20.265 26.25H7.73617C7.29705 26.25 6.87397 26.085 6.55088 25.7876C6.22779 25.4902 6.0283 25.0823 5.992 24.6447L4.375 5.25H23.625L22.0092 24.6447Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default TrashBinIcon;
