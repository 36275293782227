import PropTypes from 'prop-types';
import { memo } from 'react';
import { useController } from 'react-hook-form';

import {
  FormControl,
  FormHelperText,
  Typography,
} from '@mui/material';
import { Select } from 'components/molecules';

const FormSelect = (props) => {
  const {
    label,
    labelProps,
    ...rest
  } = props;

  const { field, fieldState } = useController(rest);
  const { onChange } = field;
  const { error } = fieldState;

  const hasErrored = Boolean(error);

  const onFieldChange = ({ target: { value: newValue = '' } }) => {
    onChange(newValue);
  };

  return (
    <FormControl
      sx={{ width: 1 }}
      error={hasErrored}
    >
      <Typography
        variant="bodyStandardRegular"
        sx={{ px: 2, color: 'text.secondary' }}
        {...labelProps}
      >
        {label}
      </Typography>
      <Select
        {...field}
        {...rest}
        onChange={onFieldChange}
        error={hasErrored}
      />
      {hasErrored && <FormHelperText>{error?.message}</FormHelperText>}
    </FormControl>
  );
};

FormSelect.propTypes = {
  label: PropTypes.string,
  labelProps: PropTypes.oneOfType([PropTypes.object]),
};

FormSelect.defaultProps = {
  label: '',
  labelProps: {},
};
export default memo(FormSelect);
