import PropTypes from 'prop-types';
import {
  Box,
  Typography,
} from '@mui/material';

const PlanSectionWrapper = (props) => {
  const {
    Icon,
    title,
    children,
    ...rest
  } = props;

  return (
    <Box {...rest}>
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
      }}
      >
        <Box sx={{
          display: 'flex',
          alignItems: 'center',
          mb: 5,
        }}
        >
          <Box sx={{
            width: 48,
            height: 44,
            mr: 4,
            p: 0,
          }}
          >
            <Icon width="48" height="44" />
          </Box>
          <Box>
            <Typography variant="bodyStandardMedium" color="text.lightGray">
              {title}
            </Typography>
          </Box>
        </Box>
        <Box sx={{
          display: 'flex',
          flexWrap: 'wrap',
          ml: { md: 16 },
        }}
        >
          {children}
        </Box>
      </Box>
    </Box>
  );
};

PlanSectionWrapper.propTypes = {
  Icon: PropTypes.func,
  title: PropTypes.string,
  children: PropTypes.node,
};

PlanSectionWrapper.defaultProps = {
  Icon: () => { },
  title: '',
  children: null,
};

export default PlanSectionWrapper;
