import { useLocale } from 'util/hooks';

import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';

import {
  TypographyWithIcon,
  VisibilityBadge,
} from 'components/molecules';

import {
  ArrowRightIcon, CheckCircleOutline, UserIcon, UserWithHashIcon,
} from 'assets/icons';
import { getStatusByName } from 'assets/constants/statuses';
import { getDifficultyByName } from 'assets/constants/difficulties';
import { capitalizeFirstLetter } from 'util/helpers';
import { useTheme } from '@mui/styles';

const useListColumns = (props = {}) => {
  const {
    isPlayer = false,
    isArchivedList = false,
  } = props;

  const { t, isRtl } = useLocale();
  const { palette } = useTheme();

  const showSelectionHeader = !isPlayer && !isArchivedList;
  const showVisibilityColumn = !isPlayer;
  const showStatusColumn = !isPlayer;
  const showNumberOfResolvesColumn = isPlayer;
  const showIsCompletedColumn = isPlayer;

  const iconSize = {
    width: 18,
    height: 18,
  };

  const columns = [
    {
      field: 'id',
      headerName: '',
      hide: true,
    },
    {
      field: 'name',
      headerName: showSelectionHeader ? t('common.selectAll') : '',
      sortable: false,
      flex: 1,
      minWidth: 150,
      renderCell: ({ row } = {}) => {
        const { name = '', type = '' } = row;

        return (
          <Box>
            <Box>
              <Typography variant="bodyStandardMedium">
                {capitalizeFirstLetter(name)}
              </Typography>
            </Box>
            <Box>
              <Typography variant="bodySmallMedium" color="text.secondary">
                {capitalizeFirstLetter(type?.name)}
              </Typography>
            </Box>
          </Box>
        );
      },
    },
    {
      field: 'difficulty',
      headerName: '',
      sortable: false,
      flex: 1,
      minWidth: 150,
      renderCell: (params) => (
        <Chip
          size="small"
          color={params.row.difficulty || ''}
          label={t(getDifficultyByName(params?.row?.difficulty)?.localizationKey) || ''}
        />
      ),
    },
  ];

  if (showVisibilityColumn) {
    columns.push(
      {
        field: 'visible',
        headerName: '',
        sortable: false,
        flex: 1,
        minWidth: 150,
        renderCell: ({ row: { visible } }) => <VisibilityBadge visible={visible} />,
      },
    );
  }
  if (showStatusColumn) {
    columns.push(
      {
        field: 'status',
        headerName: '',
        sortable: false,
        flex: 1,
        minWidth: 150,
        renderCell: (params) => (
          <Chip
            size="small"
            color={params.row.status || ''}
            label={t(getStatusByName(params?.row?.status)?.localizationKey) || ''}
          />
        ),
      },
    );
  }
  if (showNumberOfResolvesColumn) {
    columns.push(
      {
        field: 'numberOfUserResolves',
        headerName: '',
        sortable: false,
        flex: 1,
        minWidth: 200,
        renderCell: ({ row: { userHacks } }) => (
          <TypographyWithIcon
            label={`${userHacks || 0} ${t('machines.userHacks')}`}
            labelProps={{ variant: 'BodyStandardMedium' }}
            icon={(
              <UserIcon
                stroke={palette.text.lightPurple}
                {...iconSize}
              />
            )}
          />
        ),
      },
      {
        field: 'numberOfRootResolves',
        headerName: '',
        sortable: false,
        flex: 1,
        minWidth: 200,
        renderCell: ({ row: { rootHacks } }) => (
          <TypographyWithIcon
            label={`${rootHacks || 0} ${t('machines.rootHacks')}`}
            labelProps={{ variant: 'BodyStandardMedium' }}
            icon={(
              <UserWithHashIcon
                stroke={palette.text.lightPurple}
                {...iconSize}
              />
            )}
          />
        ),
      },
    );
  }
  if (showIsCompletedColumn) {
    columns.push(
      {
        field: 'isCompletedUserFlag',
        headerName: '',
        sortable: false,
        flex: 1,
        minWidth: 150,
        renderCell: ({ row: { progress } }) => (
          progress.completedUserFlag && (
            <TypographyWithIcon
              label={t('machines.userFlag')}
              labelProps={{ variant: 'BodyStandardMedium' }}
              icon={(
                <CheckCircleOutline
                  stroke={palette.success.main}
                  {...iconSize}
                />
              )}
            />
          )
        ),
      },
      {
        field: 'isCompletedRootFlag',
        headerName: '',
        sortable: false,
        flex: 1,
        minWidth: 150,
        renderCell: ({ row: { progress } }) => (
          progress.completedRootFlag && (
            <TypographyWithIcon
              label={t('machines.rootFlag')}
              labelProps={{ variant: 'BodyStandardMedium' }}
              icon={(
                <CheckCircleOutline
                  stroke={palette.success.main}
                  {...iconSize}
                />
              )}
            />
          )
        ),
      },
    );
  }
  columns.push({
    field: 'actions',
    headerName: '',
    sortable: false,
    minWidth: 50,
    renderCell: () => (
      <ArrowRightIcon style={{ transform: isRtl ? 'scaleX(-1)' : 'none' }} />
    ),
  });
  return columns;
};

export default useListColumns;
