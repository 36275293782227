import { useTheme } from '@mui/styles';

const ExportIcon = (props) => {
  const theme = useTheme();
  const {
    stroke = theme.palette.text.secondary,
    fill = 'none',
    ...rest
  } = props;

  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill={fill} xmlns="http://www.w3.org/2000/svg" {...rest}>
      <path d="M8 10.2727V3" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M10.728 5.72727L8.00071 3L5.27344 5.72727" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M14.8175 10.2734V11.1825C14.8175 11.6647 14.626 12.1272 14.285 12.4682C13.944 12.8092 13.4815 13.0007 12.9993 13.0007H2.99933C2.51712 13.0007 2.05466 12.8092 1.71369 12.4682C1.37271 12.1272 1.18115 11.6647 1.18115 11.1825V10.2734" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default ExportIcon;
