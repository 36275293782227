import { getPlanDetails } from 'services';
import { useCustomQuery } from 'reactQuery';
import { useParams } from 'react-router-dom';

const usePlanDetails = (queryProps = {}) => {
  const { queryKeyOptions = {}, ...rest } = queryProps;
  const { planId } = useParams();

  const query = useCustomQuery({
    queryKey: [`view-plan-${planId}`, queryKeyOptions],
    queryFn: () => getPlanDetails(planId),
    ...rest,
  });

  return { ...query };
};

export default usePlanDetails;
