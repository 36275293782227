import METHODS from 'services/methods';
import END_POINTS from '../endPoints';
import api from '../api';

const terminateInstance = (instanceId, options) => api(
  END_POINTS.instance(instanceId),
  {
    method: METHODS.DELETE,
    ...options,
  },
);

export default terminateInstance;
