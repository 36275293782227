import { useRecoilState } from 'recoil';
import {
  Box,
  Stack,
  Typography,
  Switch,
  Chip,
} from '@mui/material';

import { useLocale } from 'util/hooks';
import { numberWithCommas } from 'util/helpers';
import { checkoutAtom } from 'recoil/atoms';

const BillingOption = (props) => {
  const { t } = useLocale();
  const [checkoutState, setCheckoutState] = useRecoilState(checkoutAtom);
  const {
    plan,
    billingOption,
  } = checkoutState || {};

  const {
    pricePerMonth,
    pricePerYear,
  } = plan || {};

  const isAnnually = billingOption === 'perYear';
  const price = isAnnually ? pricePerYear : pricePerMonth;

  const onChange = (e) => {
    const newBillingOption = e.target.checked ? 'perYear' : 'perMonth';
    setCheckoutState((prev) => ({
      ...prev,
      billingOption: newBillingOption,
    }));
  };

  return (
    <Box sx={{
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      flexWrap: 'wrap',
      rowGap: 4,
    }}
    >
      <Stack
        direction="row"
        spacing={3}
        alignItems="center"
      >
        <Typography>{t('subscriptions.billMonthly')}</Typography>
        <Switch
          id="subscription-period"
          name="subscription-period"
          checked={isAnnually}
          onChange={onChange}
        />
        <Typography>{t('subscriptions.billAnnually')}</Typography>
      </Stack>
      <Box>
        <Chip
          label={(
            <Typography
              color="text.primary"
            >
              {`${numberWithCommas(price)} 
              ${t('currency.sar')} 
              ${t(`subscriptions.${billingOption}`)}`}
            </Typography>
          )}
          sx={{
            borderRadius: 5,
            backgroundColor: 'paper.hover',
          }}
        />
      </Box>
    </Box>
  );
};

export default BillingOption;
