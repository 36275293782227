import { useTheme } from '@mui/styles';

const FlagFilledIcon = (props) => {
  const theme = useTheme();
  const {
    stroke = theme.palette.text.blue,
    fill = theme.palette.text.transparentBlue,
    ...rest
  } = props;

  return (
    <svg width="48" height="44" viewBox="0 0 48 44" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <rect width="48" height="44" rx="6" fill={fill} />
      <path d="M13.6875 32.3125V11.6875" stroke={stroke} strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M13.6875 27.2318L16.5787 26.3426C17.7763 25.9739 19.0388 25.865 20.2819 26.023C21.525 26.1811 22.7201 26.6026 23.7873 27.2593C24.8288 27.9012 25.9925 28.3193 27.2044 28.4869C28.4163 28.6545 29.6498 28.568 30.8264 28.2328L33.5645 27.45C33.78 27.3884 33.9696 27.2583 34.1046 27.0793C34.2396 26.9004 34.3126 26.6823 34.3125 26.4581V15.7084C34.3124 15.5488 34.2752 15.3915 34.204 15.2487C34.1328 15.1059 34.0294 14.9815 33.902 14.8854C33.7746 14.7893 33.6267 14.724 33.4699 14.6947C33.313 14.6654 33.1515 14.6729 32.998 14.7166L30.8264 15.3371C29.6502 15.6731 28.4169 15.7605 27.205 15.5939C25.9931 15.4272 24.8292 15.0101 23.7873 14.3691C22.7201 13.7124 21.525 13.2909 20.2819 13.1329C19.0388 12.9748 17.7763 13.0838 16.5787 13.4525L13.6875 14.3407" stroke={stroke} strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default FlagFilledIcon;
