import { useTheme } from '@mui/styles';

const RouterIcon = (props) => {
  const { palette } = useTheme();
  const {
    stroke = palette.text.primary,
    fill = palette.text.primary,
    ...rest
  } = props;

  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <g clipPath="url(#clip0_13081_1191)">
        <path d="M2.75 14.5007V15.5007" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" />
        <path d="M13.25 14.5007V15.5007" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" />
        <path d="M6.5 10.0007V14.5007" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" />
        <circle cx="9.5" cy="12.0007" r="0.5" fill={fill} />
        <path d="M9.5 12C9.45055 12 9.40222 12.0147 9.36111 12.0421C9.32 12.0696 9.28795 12.1086 9.26903 12.1543C9.25011 12.2 9.24516 12.2503 9.2548 12.2988C9.26445 12.3473 9.28826 12.3918 9.32322 12.4268C9.35819 12.4617 9.40273 12.4855 9.45123 12.4952C9.49972 12.5048 9.54999 12.4999 9.59567 12.481C9.64135 12.462 9.6804 12.43 9.70787 12.3889C9.73534 12.3478 9.75 12.2994 9.75 12.25C9.75 12.1837 9.72366 12.1201 9.67678 12.0732C9.62989 12.0263 9.5663 12 9.5 12Z" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" />
        <circle cx="12.5" cy="12.0007" r="0.5" fill={fill} />
        <path d="M12.5 12C12.4506 12 12.4022 12.0147 12.3611 12.0421C12.32 12.0696 12.288 12.1086 12.269 12.1543C12.2501 12.2 12.2452 12.2503 12.2548 12.2988C12.2645 12.3473 12.2883 12.3918 12.3232 12.4268C12.3582 12.4617 12.4027 12.4855 12.4512 12.4952C12.4997 12.5048 12.55 12.4999 12.5957 12.481C12.6414 12.462 12.6804 12.43 12.7079 12.3889C12.7353 12.3478 12.75 12.2994 12.75 12.25C12.75 12.1837 12.7237 12.1201 12.6768 12.0732C12.6299 12.0263 12.5663 12 12.5 12Z" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" />
        <path d="M11.5 10.0007V4.50068" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" />
        <path d="M10.0859 3.08601C10.461 2.71107 10.9696 2.50044 11.4999 2.50044C12.0303 2.50044 12.5389 2.71107 12.9139 3.08601" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" />
        <path d="M8.67188 1.67201C9.04332 1.3005 9.48431 1.0058 9.96965 0.804741C10.455 0.60368 10.9752 0.500195 11.5005 0.500195C12.0259 0.500195 12.5461 0.60368 13.0314 0.804741C13.5168 1.0058 13.9578 1.3005 14.3292 1.67201" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" />
        <path d="M13.25 14.5007C13.8467 14.5007 14.419 14.2636 14.841 13.8417C15.2629 13.4197 15.5 12.8474 15.5 12.2507C15.5 11.6539 15.2629 11.0816 14.841 10.6597C14.419 10.2377 13.8467 10.0007 13.25 10.0007H2.75C2.15326 10.0007 1.58097 10.2377 1.15901 10.6597C0.737053 11.0816 0.5 11.6539 0.5 12.2507C0.5 12.8474 0.737053 13.4197 1.15901 13.8417C1.58097 14.2636 2.15326 14.5007 2.75 14.5007H13.25Z" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" />
      </g>
      <defs>
        <clipPath id="clip0_13081_1191">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default RouterIcon;
