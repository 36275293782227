import api from 'services/api';
import END_POINTS from 'services/endPoints';

const getVpnRegions = () => api(
  END_POINTS.vpnRegions,
)
  .then((res) => Promise.resolve(res))
  .catch((error) => Promise.reject(error));

export default getVpnRegions;
