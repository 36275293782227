import { useTheme } from '@mui/styles';

const SettingsIcon = (props) => {
  const theme = useTheme();
  const {
    stroke = theme.palette.text.raspberry,
    fill = 'none',
    ...rest
  } = props;

  return (
    <svg width="22" height="22" viewBox="0 0 22 22" fill={fill} xmlns="http://www.w3.org/2000/svg" {...rest}>
      <path id="Shape" fillRule="evenodd" clipRule="evenodd" d="M9.78736 3.03167C10.0966 3.37496 10.537 3.57096 10.999 3.57096C11.4611 3.57096 11.9014 3.37496 12.2107 3.03167L12.999 2.16667C13.462 1.65429 14.1973 1.48864 14.8352 1.75299C15.4732 2.01734 15.8758 2.65451 15.8407 3.34417L15.7815 4.51084C15.7584 4.97107 15.9309 5.41962 16.2565 5.7457C16.5821 6.07178 17.0304 6.24497 17.4907 6.22251L18.6574 6.16334C19.3466 6.12936 19.9827 6.53238 20.2464 7.17006C20.51 7.80774 20.3443 8.54233 19.8324 9.00501L18.964 9.78834C18.6212 10.098 18.4255 10.5384 18.4255 11.0004C18.4255 11.4624 18.6212 11.9028 18.964 12.2125L19.8324 12.9958C20.3447 13.4588 20.5104 14.1941 20.246 14.8321C19.9817 15.47 19.3445 15.8726 18.6549 15.8375L17.4882 15.7783C17.0269 15.7546 16.5772 15.9275 16.2506 16.2541C15.924 16.5807 15.7511 17.0304 15.7749 17.4917L15.834 18.6583C15.8646 19.3444 15.4626 19.9762 14.8282 20.2391C14.1938 20.502 13.4627 20.3398 12.999 19.8333L12.2149 18.9658C11.9053 18.623 11.4651 18.4274 11.0032 18.4274C10.5413 18.4274 10.1011 18.623 9.79152 18.9658L9.00402 19.8333C8.54102 20.3423 7.80864 20.5063 7.17281 20.2434C6.53698 19.9806 6.13419 19.3473 6.16569 18.66L6.22569 17.4933C6.24942 17.0321 6.07654 16.5824 5.74994 16.2558C5.42334 15.9292 4.97363 15.7563 4.51236 15.78L3.34569 15.8392C2.6563 15.8752 2.01887 15.4734 1.75393 14.8359C1.48899 14.1985 1.65388 13.4633 2.16569 13L3.03319 12.2167C3.37604 11.907 3.5717 11.4666 3.5717 11.0046C3.5717 10.5426 3.37604 10.1022 3.03319 9.79251L2.16569 9.00501C1.6556 8.5423 1.49095 7.80914 1.75421 7.17275C2.01747 6.53636 2.65195 6.13379 3.33986 6.16667L4.50652 6.22584C4.96869 6.25014 5.41942 6.07703 5.74651 5.74963C6.0736 5.42222 6.24627 4.97131 6.22152 4.50917L6.16569 3.34167C6.13343 2.65406 6.53595 2.0201 7.17197 1.75681C7.80799 1.49351 8.54083 1.65746 9.00402 2.16667L9.78736 3.03167Z" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path id="Oval" fillRule="evenodd" clipRule="evenodd" d="M11 14.7509C13.0711 14.7509 14.75 13.0719 14.75 11.0009C14.75 8.92979 13.0711 7.25085 11 7.25085C8.92893 7.25085 7.25 8.92979 7.25 11.0009C7.25 13.0719 8.92893 14.7509 11 14.7509Z" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default SettingsIcon;
