import END_POINTS from 'services/endPoints';
import downloadApi from '../downloadApi';

const downloadWriteupFiles = (labId, challengeId) => downloadApi({
  path: END_POINTS.writeupFiles(labId, challengeId),
  exportType: 'zip',
  fileName: `writeup-files-${challengeId}.zip`,
})
  .then((res) => Promise.resolve(res))
  .catch((error) => Promise.reject(error));

export default downloadWriteupFiles;
