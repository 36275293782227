import { useNavigate } from 'react-router-dom';

import { DataList } from 'components/core';
import { PATHS } from 'routes';

import useListColumns from './useListColumns';

const PlayerMachineList = (props) => {
  const {
    machines = [],
  } = props;

  const navigate = useNavigate();
  const columns = useListColumns({ isPlayer: true });

  return (
    <DataList
      rows={machines}
      columns={columns}
      withSearch={false}
      checkboxSelection={false}
      getRowId={(row) => row.name}
      onRowClick={({ row }) => {
        const { name = '' } = row || {};
        navigate(`/${PATHS.machines}/${name}`);
      }}
      componentsProps={{
        noRowsOverlay: { type: 'machines' },
      }}
      sx={{
        '& .MuiDataGrid-row': {
          '& .MuiDataGrid-cell': {
            px: 8,
          },
        },
      }}
      headerHeight={0}
    />
  );
};

export default PlayerMachineList;
