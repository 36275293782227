import { Box, Stack, Typography } from '@mui/material';
import { useCountDown } from 'util/hooks';

const LeaderboardCountDown = (props) => {
  const { date = new Date() } = props;

  const counter = useCountDown(date);

  return (
    <Stack flexDirection="row">
      {counter?.map((time, index) => (
        <Box
          key={time.label}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'end',
          }}
        >
          <Stack
            spacing={2}
            justifyContent="center"
            alignItems="center"
            sx={{
              width: { xs: 62, sm: 72 },
              textAlign: 'center',
            }}
          >
            <Typography
              color="text.secondary"
              variant="bodyStandardMedium"
            >
              {time.label.toUpperCase()}
            </Typography>
            <Typography variant="h1">
              {time.time}
            </Typography>
          </Stack>
          {index !== counter.length - 1
            && (
              <Box sx={{ px: { xs: 1, sm: 2 } }}>
                <Typography variant="h3">
                  :
                </Typography>
              </Box>
            )}
        </Box>
      ))}
    </Stack>
  );
};

export default LeaderboardCountDown;
