import { useController } from 'react-hook-form';

import { TextField } from 'components/molecules';
import { formatExpiryDate } from 'util/helpers';

const FormExpiryDateField = (props) => {
  const {
    ...rest
  } = props;

  const { field, fieldState } = useController(props);
  const { onChange } = field;
  const { error } = fieldState;

  const hasErrored = Boolean(error);

  const onFieldChange = ({ target: { value: newValue = '' } }) => {
    onChange(formatExpiryDate(newValue));
  };

  return (
    <TextField
      {...field}
      {...rest}
      error={hasErrored}
      helperText={error?.message}
      value={formatExpiryDate(String(field.value))}
      onChange={onFieldChange}
    />
  );
};
export default FormExpiryDateField;
