import { InputAdornment } from '@mui/material';
import { getCardTypeByValue } from 'util/cardTypes';
import { forwardRef } from 'react';
import CreditCardOutlinedIcon from '@mui/icons-material/CreditCardOutlined';
import { numberWithoutSpaces } from 'util/helpers';
import TextField from '../TextField/TextField';

const CardNumberField = forwardRef((props, ref) => {
  const { value } = props;

  const renderPaymentIcon = () => {
    const val = numberWithoutSpaces(value);
    const cardType = getCardTypeByValue(val);

    return (
      <InputAdornment position="start">
        {value?.length ? (
          <img
            className={`${cardType}-logo`}
            alt={cardType}
            width="39"
            height="22"
            src={cardType?.logo}
          />
        ) : <CreditCardOutlinedIcon sx={{ color: 'text.secondary' }} />}
      </InputAdornment>
    );
  };

  return (
    <TextField
      {...props}
      InputProps={{
        ref,
        endAdornment: renderPaymentIcon(),
      }}
    />
  );
});

export default CardNumberField;
