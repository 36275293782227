import { useTheme } from '@mui/styles';

const FileCheckedFilledIcon = (props) => {
  const { palette } = useTheme();

  const {
    fill = palette.text.lightPurple,
    stroke = palette.text.lightPurple,
  } = props;

  return (
    <svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="45" height="45" rx="7.125" fill={fill} fillOpacity="0.2" />
      <path d="M30.25 36.25C33.5637 36.25 36.25 33.5637 36.25 30.25C36.25 26.9363 33.5637 24.25 30.25 24.25C26.9363 24.25 24.25 26.9363 24.25 30.25C24.25 33.5637 26.9363 36.25 30.25 36.25Z" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M32.9242 28.5039L30.0192 32.3779C29.9546 32.4637 29.8723 32.5347 29.7779 32.5861C29.6836 32.6375 29.5793 32.668 29.4721 32.6757C29.3649 32.6834 29.2574 32.668 29.1566 32.6306C29.0559 32.5932 28.9644 32.5347 28.8882 32.4589L27.3882 30.9589" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M21.25 33.25H15.25C14.8522 33.25 14.4706 33.092 14.1893 32.8107C13.908 32.5294 13.75 32.1478 13.75 31.75V15.25C13.75 14.8522 13.908 14.4706 14.1893 14.1893C14.4706 13.908 14.8522 13.75 15.25 13.75H25.879C26.2765 13.7501 26.6578 13.908 26.939 14.189L29.811 17.061C30.092 17.3422 30.2499 17.7235 30.25 18.121V21.25" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default FileCheckedFilledIcon;
