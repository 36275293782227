import { useEffect, useState } from 'react';
import { useLocation, Outlet } from 'react-router-dom';
import {
  Box,
  Link,
  Tab,
  Tabs,
} from '@mui/material';

import { PATHS } from 'routes';
import { useAuth, useLocale } from 'util/hooks';
import { getActiveTab } from 'routes/routeUtils';
import { UserHeader } from 'components/organisms';
import { SuspenseWrapper } from 'components/render';
import { useUserProfile } from 'reactQuery/queries';

const ProfileContainer = () => {
  const { t } = useLocale();
  const { isLoggedIn } = useAuth();
  const { pathname } = useLocation();
  const {
    data: profileDetails = {},
  } = useUserProfile();

  const { user = {} } = profileDetails;

  const tabs = [
    {
      label: t('common.overview'),
      to: PATHS.overview,
    },
    {
      label: t('common.badges'),
      to: PATHS.badges,
    },
    {
      label: t('common.events'),
      to: PATHS.events,
    },
    {
      label: t('common.activity'),
      to: PATHS.activity,
    },
  ];

  const [currentTab, setCurrentTab] = useState(() => getActiveTab(tabs, pathname));

  const onTabChange = (event, newValue) => {
    setCurrentTab(newValue);
  };

  useEffect(() => {
    setCurrentTab(getActiveTab(tabs, pathname));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const sx = isLoggedIn // Show page padding when accessed publicly
    ? {
      px: {
        xs: 0,
        xxl: '20%',
      },
    }
    : {
      px: {
        xs: 10,
        sm: 15,
        md: 42,
      },
      py: { xs: 8, md: 14 },
    };

  return (
    <Box sx={sx}>
      <Box sx={{ mb: 6 }}>
        <UserHeader userDetails={user} />
      </Box>
      <Box>
        <Box sx={{ maxWidth: { xs: 300, sm: 1 } }}>
          <Tabs
            value={currentTab}
            onChange={onTabChange}
            variant="scrollable"
            scrollButtons="auto"
            allowScrollButtonsMobile
            sx={{ mt: 10 }}
          >
            {tabs?.map((tab) => (
              <Tab
                key={`tab-${tab.to}`}
                component={Link}
                value={tab.to}
                disableRipple
                {...tab}
              />
            ))}
          </Tabs>
        </Box>
        <SuspenseWrapper>
          <Outlet />
        </SuspenseWrapper>
      </Box>
    </Box>
  );
};

export default ProfileContainer;
