import React from 'react';

import { DataGrid } from 'components/core';
import { updatePlanStatus } from 'services';
import { useLocale, useModal, useSnackbar } from 'util/hooks';

import MODAL_KEYS from 'assets/constants/modalKeys';

import { usePlans } from 'reactQuery/queries';
import useTableColumns from './useTableColumns';

const PlanTable = (props) => {
  const {
    statuses = [],
  } = props;

  // Modal
  const { addModal } = useModal();

  const { t, isAr } = useLocale();
  const snack = useSnackbar();

  const filterOptions = {
    status: statuses,
  };

  const {
    data = {},
    isFetching = true,
    refetch = () => { },
  } = usePlans({
    queryKeyOptions: { filterOptions },
  });

  const {
    items: rows = [],
    meta = {},
  } = data || {};

  const onUpdatePlanStatus = React.useCallback(async (type, params = {}) => {
    const {
      id,
      nameAr,
      nameEn,
    } = params;

    try {
      await updatePlanStatus(id, type);
      refetch();

      snack({
        message: `"${isAr ? nameAr : nameEn}" ${t(`plans.${type}Success`)}`,
        severity: 'success',
      });
    } catch (error) {
      const { errors } = error;
      snack({
        message: errors?.[0]?.message || error.message,
        severity: 'error',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t]);

  const columns = useTableColumns({
    addModal: (params) => addModal({
      key: MODAL_KEYS.updatePlan,
      props: {
        onConfirm: onUpdatePlanStatus,
        ...params,
      },
    }),
  });

  return (
    <DataGrid
      rows={rows}
      columns={columns}
      loading={isFetching}
      pagination={meta}
      componentsProps={{
        noRowsOverlay: { type: 'plan' },
      }}
    />
  );
};

export default PlanTable;
