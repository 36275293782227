import { getAllUsers } from 'services';
import { useCustomQuery } from 'reactQuery';

const useUsers = (queryProps = {}) => {
  const { queryKeyOptions = {}, ...rest } = queryProps;

  const query = useCustomQuery({
    queryKey: ['users', queryKeyOptions],
    queryFn: getAllUsers,
    ...rest,
  });

  return { ...query };
};

export default useUsers;
