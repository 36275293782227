import { Loadable } from 'components/render';
import { lazy } from 'react';
import PATHS from './PATHS';
import routePermissions from './routePermissions';
import { lazyRetry } from './routeUtils';

// Lazy import of all elements
export const NoMatch = Loadable(lazy(() => lazyRetry(() => import('pages/NoMatch/NoMatch'))));
export const MainLayout = Loadable(lazy(() => lazyRetry(() => import('layouts/MainLayout/MainLayout'))));
export const PublicLayout = Loadable(lazy(() => lazyRetry(() => import('layouts/PublicLayout/PublicLayout'))));
export const PrivateLayout = Loadable(lazy(() => lazyRetry(() => import('layouts/PrivateLayout/PrivateLayout'))));
export const Landing = Loadable(lazy(() => lazyRetry(() => import('pages/Landing/Landing'))));
export const Login = Loadable(lazy(() => lazyRetry(() => import('pages/Login/Login'))));
export const Dashboard = Loadable(lazy(() => lazyRetry(() => import('pages/Dashboard/Managers/Dashboard'))));
export const PlayerHome = Loadable(lazy(() => lazyRetry(() => import('pages/Dashboard/Players/PlayerHome'))));
export const Register = Loadable(lazy(() => lazyRetry(() => import('pages/Register/Register'))));
export const EmailVerification = Loadable(lazy(() => lazyRetry(() => import('pages/EmailVerification/EmailVerification'))));
export const Settings = Loadable(lazy(() => lazyRetry(() => import('pages/Settings/Settings'))));
export const Profile = Loadable(lazy(() => lazyRetry(() => import('pages/Profile/Profile'))));
export const Users = Loadable(lazy(() => lazyRetry(() => import('pages/Users/Users'))));
export const BusinessTeam = Loadable(lazy(() => lazyRetry(() => import('pages/Users/BusinessTeam/BusinessTeam'))));
export const TechnicalTeam = Loadable(lazy(() => lazyRetry(() => import('pages/Users/TechnicalTeam/TechnicalTeam'))));
export const Companies = Loadable(lazy(() => lazyRetry(() => import('pages/Users/Companies/Companies'))));
export const CreateCompany = Loadable(lazy(() => lazyRetry(() => import('pages/Users/Companies/CreateCompany'))));
export const EditCompany = Loadable(lazy(() => lazyRetry(() => import('pages/Users/Companies/EditCompany'))));
export const InviteCompanyMembers = Loadable(lazy(() => lazyRetry(() => import('pages/Users/Companies/Members/InviteCompanyMembers'))));
export const CompanyUsers = Loadable(lazy(() => lazyRetry(() => import('pages/Users/Companies/Members/CompanyUsers'))));
export const CompanyOwnMembers = Loadable(lazy(() => lazyRetry(() => import('pages/Users/Companies/Members/CompanyOwnMembers'))));
export const EditCompanyMember = Loadable(lazy(() => lazyRetry(() => import('pages/Users/Companies/Members/EditCompanyMember'))));
export const Players = Loadable(lazy(() => lazyRetry(() => import('pages/Users/Players/Players'))));
export const ForgotPassword = Loadable(lazy(() => lazyRetry(() => import('pages/PasswordRecovery/ForgotPassword'))));
export const ResetPassword = Loadable(lazy(() => lazyRetry(() => import('pages/PasswordRecovery/ResetPassword'))));
export const AccountConnection = Loadable(lazy(() => lazyRetry(() => import('pages/AccountConnection/AccountConnection'))));
export const AccountSettings = Loadable(lazy(() => lazyRetry(() => import('pages/Settings/AccountSettings'))));
export const AccountSubscriptions = Loadable(lazy(() => lazyRetry(() => import('pages/Settings/AccountSubscriptions'))));
export const AccountTransactions = Loadable(lazy(() => lazyRetry(() => import('pages/Settings/AccountTransactions'))));
export const PaymentMethods = Loadable(lazy(() => lazyRetry(() => import('pages/Settings/PaymentMethods'))));
export const AddPaymentCard = Loadable(lazy(() => lazyRetry(() => import('pages/Settings/AddPaymentCard'))));
export const EditUserProfile = Loadable(lazy(() => lazyRetry(() => import('pages/Users/EditUserProfile/EditUserProfile'))));
export const InviteUser = Loadable(lazy(() => lazyRetry(() => import('pages/Users/InviteUser'))));
export const AcceptInvitation = Loadable(lazy(() => lazyRetry(() => import('pages/AcceptInvitation/AcceptInvitation'))));
export const Plans = Loadable(lazy(() => lazyRetry(() => import('pages/Plans/Plans'))));
export const ViewPlan = Loadable(lazy(() => lazyRetry(() => import('pages/Plans/ViewPlan'))));
export const PlansList = Loadable(lazy(() => lazyRetry(() => import('pages/Plans/PlansList'))));
export const PendingPlans = Loadable(lazy(() => lazyRetry(() => import('pages/Plans/PendingPlans'))));
export const FeatureManagement = Loadable(lazy(() => lazyRetry(() => import('pages/Plans/FeatureManagement'))));
export const CreatePlan = Loadable(lazy(() => lazyRetry(() => import('pages/Plans/CreatePlan'))));
export const EditPlan = Loadable(lazy(() => lazyRetry(() => import('pages/Plans/EditPlan'))));
export const Labs = Loadable(lazy(() => lazyRetry(() => import('pages/Labs/Managers/Labs'))));
export const ViewLab = Loadable(lazy(() => lazyRetry(() => import('pages/Labs/Managers/ViewLab'))));
export const PremiumLabs = Loadable(lazy(() => lazyRetry(() => import('pages/Labs/Managers/PremiumLabs'))));
export const ProLabs = Loadable(lazy(() => lazyRetry(() => import('pages/Labs/Managers/ProLabs'))));
export const CreateLab = Loadable(lazy(() => lazyRetry(() => import('pages/Labs/Managers/CreateLab'))));
export const PlayerLabs = Loadable(lazy(() => lazyRetry(() => import('pages/Labs/Players/PlayerLabs'))));
export const TrainingLabs = Loadable(lazy(() => lazyRetry(() => import('pages/Labs/Players/TrainingLabs'))));
export const CompetitiveLabs = Loadable(lazy(() => lazyRetry(() => import('pages/Labs/Players/CompetitiveLabs'))));
export const PlayerViewLab = Loadable(lazy(() => lazyRetry(() => import('pages/Labs/Players/PlayerViewLab'))));
export const ViewChallenge = Loadable(lazy(() => lazyRetry(() => import('pages/Challenges/Managers/ViewChallenge'))));
export const PlayerViewChallenge = Loadable(lazy(() => lazyRetry(() => import('pages/Challenges/Players/PlayerViewChallenge'))));
export const CreateChallenge = Loadable(lazy(() => lazyRetry(() => import('pages/Challenges/Managers/CreateChallenge'))));
export const PromoCode = Loadable(lazy(() => lazyRetry(() => import('pages/PromoCodes/PromoCodes'))));
export const CreatePromoCode = Loadable(lazy(() => lazyRetry(() => import('pages/PromoCodes/CreatePromoCodes'))));
export const ViewPromoCode = Loadable(lazy(() => lazyRetry(() => import('pages/PromoCodes/ViewPromoCodes'))));
export const Subscriptions = Loadable(lazy(() => lazyRetry(() => import('pages/Subscriptions/Subscriptions'))));
export const Checkout = Loadable(lazy(() => lazyRetry(() => import('pages/Subscriptions/Checkout'))));
export const LeaderBoard = Loadable(lazy(() => lazyRetry(() => import('pages/LeaderBoard/LeaderBoard'))));
export const ProfileOverview = Loadable(lazy(() => lazyRetry(() => import('pages/Profile/ProfileOverview'))));
export const ProfileBadges = Loadable(lazy(() => lazyRetry(() => import('pages/Profile/ProfileBadges'))));
export const ProfileEvents = Loadable(lazy(() => lazyRetry(() => import('pages/Profile/ProfileEvents'))));
export const ProfileActivity = Loadable(lazy(() => lazyRetry(() => import('pages/Profile/ProfileActivity'))));
export const Announcements = Loadable(lazy(() => lazyRetry(() => import('pages/Announcements/Announcements'))));
export const CreateAnnouncement = Loadable(lazy(() => lazyRetry(() => import('pages/Announcements/CreateAnnouncement'))));
export const EditAnnouncement = Loadable(lazy(() => lazyRetry(() => import('pages/Announcements/EditAnnouncement'))));
export const ViewAnnouncement = Loadable(lazy(() => lazyRetry(() => import('pages/Announcements/ViewAnnouncement'))));
export const ManageMachines = Loadable(lazy(() => lazyRetry(() => import('pages/Machines/Managers/ManageMachines'))));
export const Machines = Loadable(lazy(() => lazyRetry(() => import('pages/Machines/Players/Machines'))));
export const CreateMachine = Loadable(lazy(() => lazyRetry(() => import('pages/Machines/CreateMachine'))));
export const ViewMachine = Loadable(lazy(() => lazyRetry(() => import('pages/Machines/Managers/ViewMachine'))));
export const EditMachine = Loadable(lazy(() => lazyRetry(() => import('pages/Machines/EditMachine'))));
export const PlayerViewMachine = Loadable(lazy(() => lazyRetry(() => import('pages/Machines/Players/PlayerViewMachine'))));
// Events
export const ViewEventVisualization = Loadable(lazy(() => lazyRetry(() => import('pages/Events/ViewEventVisualization'))));
// Events - Managers
export const Events = Loadable(lazy(() => lazyRetry(() => import('pages/Events/Managers/Events'))));
export const CreateEvent = Loadable(lazy(() => lazyRetry(() => import('pages/Events/Managers/CreateEvent'))));
export const ViewEvent = Loadable(lazy(() => lazyRetry(() => import('pages/Events/Managers/ViewEvent'))));
export const ViewEventDetails = Loadable(lazy(() => lazyRetry(() => import('pages/Events/Managers/ViewEventDetails'))));
export const ViewEventInvitations = Loadable(lazy(() => lazyRetry(() => import('pages/Events/Managers/ViewEventInvitations'))));
export const ViewEventChallenges = Loadable(lazy(() => lazyRetry(() => import('pages/Events/Managers/ViewEventChallenges'))));
export const ViewEventTeams = Loadable(lazy(() => lazyRetry(() => import('pages/Events/Managers/ViewEventTeams'))));
export const ViewEventLeaderboard = Loadable(lazy(() => lazyRetry(() => import('pages/Events/Managers/ViewEventLeaderboard'))));
export const EditEventTeam = Loadable(lazy(() => lazyRetry(() => import('pages/Events/Managers/EditEventTeam'))));
export const EventInvitation = Loadable(lazy(() => lazyRetry(() => import('pages/Events/Managers/EventInvitation'))));
export const ViewEventTeam = Loadable(lazy(() => lazyRetry(() => import('pages/Events/Managers/ViewEventTeam'))));
export const CreateEventChallenge = Loadable(lazy(() => lazyRetry(() => import('pages/Events/Managers/CreateEventChallenge'))));
export const ViewEventChallenge = Loadable(lazy(() => lazyRetry(() => import('pages/Events/Managers/ViewEventChallenge'))));
// Events - Players
export const PlayerEvents = Loadable(lazy(() => lazyRetry(() => import('pages/Events/Players/PlayerEvents'))));
export const PlayerViewEvent = Loadable(lazy(() => lazyRetry(() => import('pages/Events/Players/PlayerViewEvent'))));
export const PublicViewEvent = Loadable(lazy(() => lazyRetry(() => import('pages/Events/Public/PublicViewEvent'))));
export const CaptainEditTeam = Loadable(lazy(() => lazyRetry(() => import('pages/Events/Players/PlayerEditEventTeam'))));
export const PlayerViewEventChallenge = Loadable(lazy(() => lazyRetry(() => import('pages/Events/Players/PlayerViewEventChallenge'))));
// Instances - Admins
export const Instances = Loadable(lazy(() => lazyRetry(() => import('pages/Instances/Instances'))));
// Forms
export const Hitbctf = Loadable(lazy(() => lazyRetry(() => import('pages/Forms/Hitbctf'))));
export const HitbVisitors = Loadable(lazy(() => lazyRetry(() => import('pages/Forms/HitbVisitors'))));

// Declared routes
// Example
// const example = {
//  key: 'example',
//  auth: routePermissions.example
//  path: PATHS.example ,
//  element: <Example titleKey='example'/>,
// }

export const mainLayout = {
  key: 'mainLayout',
  path: PATHS.root,
  auth: routePermissions.mainLayout,
  element: <MainLayout titleKey="mainLayout" />,
};
export const publicLayout = {
  key: 'publicLayout',
  path: PATHS.root,
  auth: routePermissions.publicLayout,
  element: <PublicLayout titleKey="publicLayout" />,
};
export const privateLayout = {
  key: 'privateLayout',
  path: PATHS.root,
  auth: routePermissions.privateLayout,
  element: <PrivateLayout titleKey="privateLayout" />,
};
export const noMatch = {
  key: 'noMatch',
  path: '*',
  element: <NoMatch titleKey="noMatch" />,
};
export const landing = {
  key: 'landing',
  path: PATHS.root,
  auth: routePermissions.landing,
  element: <Landing titleKey="landing" />,
  // index: true,
};
export const login = {
  key: 'login',
  path: PATHS.login,
  auth: routePermissions.login,
  element: <Login titleKey="login" />,
};
export const register = {
  key: 'register',
  path: PATHS.register,
  auth: routePermissions.register,
  element: <Register titleKey="register" />,
};
export const verifyEmail = {
  key: 'verifyEmail',
  path: PATHS.verifyEmail,
  auth: routePermissions.verifyEmail,
  element: <EmailVerification titleKey="verifyEmail" />,
};

// ========================|| Dashboard ||======================== //
export const dashboard = {
  key: 'dashboard',
  path: PATHS.root,
  auth: routePermissions.dashboard,
  element: <Dashboard titleKey="dashboard" />,
};
export const playerHome = {
  key: 'playerHome',
  path: PATHS.root,
  auth: routePermissions.playerHome,
  element: <PlayerHome titleKey="home" />,
};

// ========================|| Settings ||======================== //
export const accountSettings = {
  key: 'accountSettings',
  path: PATHS.accountSettings,
  auth: routePermissions.accountSettings,
  element: <AccountSettings titleKey="accountSettings" />,
  index: true,
};
export const accountSubscriptions = {
  key: 'accountSubscriptions',
  path: PATHS.accountSubscriptions,
  auth: routePermissions.accountSubscriptions,
  element: <AccountSubscriptions titleKey="accountSubscriptions" />,
};
export const accountTransactions = {
  key: 'accountTransactions',
  path: PATHS.accountTransactions,
  auth: routePermissions.accountTransactions,
  element: <AccountTransactions titleKey="accountTransactions" />,
};
// TODO: [PAYMENT] revert comment code when payment is ready
// export const paymentMethods = {
//   key: 'paymentMethods',
//   path: PATHS.paymentMethods,
//   auth: routePermissions.paymentMethods,
//   element: <PaymentMethods titleKey="paymentMethods" />,
// };
export const addPaymentCard = {
  key: 'addPaymentCard',
  path: PATHS.addPaymentCard,
  auth: routePermissions.addPaymentCard,
  element: <AddPaymentCard titleKey="addPaymentCard" />,
};

export const settings = {
  key: 'settings',
  path: PATHS.settings,
  auth: routePermissions.settings,
  element: <Settings titleKey="settings" />,
  children: [
    accountSettings,
    accountSubscriptions,
    accountTransactions,
    // TODO: [PAYMENT] revert comment code when payment is ready
    // paymentMethods,
  ],
};
// ========================|| Profile ||======================== //
export const profileOverview = {
  key: 'profileOverview',
  path: PATHS.overview,
  element: <ProfileOverview titleKey="profileOverview" />,
  index: true,
};
export const profileBadges = {
  key: 'profileBadges',
  path: PATHS.badges,
  element: <ProfileBadges titleKey="profileBadges" />,
};
export const profileEvents = {
  key: 'profileEvents',
  path: PATHS.events,
  element: <ProfileEvents titleKey="profileEvents" />,
};
export const profileActivity = {
  key: 'profileActivity',
  path: PATHS.activity,
  element: <ProfileActivity titleKey="profileActivity" />,
};
export const profile = {
  key: 'profile',
  path: PATHS.profile,
  auth: routePermissions.profile,
  element: <Profile titleKey="profile" />,
  children: [
    profileOverview,
    profileBadges,
    profileEvents,
    profileActivity,
  ],
};
export const publicProfile = {
  key: 'publicProfile',
  path: PATHS.profile,
  auth: routePermissions.publicProfile,
  element: <Profile titleKey="profile" />,
  children: [
    profileOverview,
    profileBadges,
    profileEvents,
    profileActivity,
  ],
};

export const invite = {
  key: 'invite',
  path: PATHS.invite,
  auth: routePermissions.invite,
  element: <InviteUser titleKey="inviteMembers" />,
};

// =========================|| Companies ||========================= //

export const createCompany = {
  key: 'createCompany',
  path: PATHS.createCompany,
  auth: routePermissions.createCompany,
  element: <CreateCompany titleKey="createCompany" />,
};
export const editCompany = {
  key: 'editCompany',
  path: PATHS.editCompany,
  auth: routePermissions.editCompany,
  element: <EditCompany titleKey="editCompany" />,
};
export const editCompanyMember = {
  key: 'editCompanyMember',
  path: PATHS.userProfile,
  auth: routePermissions.editCompanyMember,
  element: <EditCompanyMember titleKey="editUserProfile" />,
};
export const inviteCompanyMembers = {
  key: 'inviteCompanyMembers',
  path: PATHS.invite,
  auth: routePermissions.inviteCompanyMembers,
  element: <InviteCompanyMembers titleKey="inviteMembers" />,
};
export const companyUsers = {
  key: 'companyUsers',
  path: PATHS.companyUsers,
  auth: routePermissions.companyUsers,
  element: <CompanyUsers titleKey="companyMembers" />,
  children: [
    inviteCompanyMembers,
    editCompanyMember,
  ],
};

export const companyOwnMembers = {
  key: 'companyOwnMembers',
  path: PATHS.root,
  auth: routePermissions.companyOwnMembers,
  element: <CompanyOwnMembers titleKey="companyMembers" />,
  children: [
    inviteCompanyMembers,
    editCompanyMember,
  ],
};

// =========================|| Users ||========================= //
export const editUserProfile = {
  key: 'editUserProfile',
  path: PATHS.userProfile,
  // auth: routePermissions.editUserProfile,
  element: <EditUserProfile titleKey="editUserProfile" />,
};
export const businessTeam = {
  key: 'businessTeam',
  path: PATHS.businessTeam,
  auth: routePermissions.businessTeam,
  element: <BusinessTeam titleKey="businessTeam" />,
  children: [
    editUserProfile,
  ],
};
export const technicalTeam = {
  key: 'technicalTeam',
  path: PATHS.technicalTeam,
  auth: routePermissions.technicalTeam,
  element: <TechnicalTeam titleKey="technicalTeam" />,
  children: [
    editUserProfile,
  ],
};
export const companies = {
  key: 'companies',
  path: PATHS.companies,
  auth: routePermissions.companies,
  element: <Companies titleKey="companies" />,
  children: [
    editCompany,
    companyUsers,
  ],
};
export const players = {
  key: 'players',
  path: PATHS.players,
  auth: routePermissions.players,
  element: <Players titleKey="players" />,
  children: [
    editUserProfile,
  ],
};
export const users = {
  key: 'users',
  path: PATHS.users,
  auth: routePermissions.users,
  element: <Users titleKey="users" />,
  children: [
    businessTeam,
    technicalTeam,
    companies,
    players,
    invite,
  ],
};

// =========================|| Auth ||========================= //
export const forgotPassword = {
  key: 'forgotPassword',
  path: PATHS.forgotPassword,
  auth: routePermissions.forgotPassword,
  element: <ForgotPassword titleKey="forgotPassword" />,
};
export const resetPassword = {
  key: 'resetPassword',
  auth: routePermissions.resetPassword,
  path: PATHS.resetPassword,
  element: <ResetPassword titleKey="resetPassword" />,
};
export const accountConnection = {
  key: 'accountConnection',
  auth: routePermissions.accountConnection,
  path: PATHS.accountConnection,
  element: <AccountConnection titleKey="accountConnection" />,
};
// =========================|| Invitations ||========================= //
export const acceptInvitation = {
  key: 'acceptInvitation',
  auth: routePermissions.acceptInvitation,
  path: PATHS.acceptInvitation,
  element: <AcceptInvitation titleKey="acceptInvitation" />,
};
// =========================|| Plans ||========================= //
export const createPlan = {
  key: 'createPlan',
  path: PATHS.createPlan,
  auth: routePermissions.createPlan,
  element: <CreatePlan titleKey="createPlan" />,
};
export const editPlan = {
  key: 'editPlan',
  path: PATHS.editPlan,
  auth: routePermissions.editPlan,
  element: <EditPlan titleKey="editPlan" />,
};

export const viewPlan = {
  key: 'viewPlan',
  path: PATHS.viewPlan,
  auth: routePermissions.viewPlan,
  element: <ViewPlan titleKey="viewPlan" />,
};
export const plansList = {
  key: 'plansList',
  path: PATHS.plansList,
  auth: routePermissions.plansList,
  element: <PlansList titleKey="plansList" />,
  index: true,
};
export const pendingPlans = {
  key: 'pendingPlans',
  path: PATHS.pendingPlans,
  auth: routePermissions.pendingPlans,
  element: <PendingPlans titleKey="pendingPlans" />,
};
export const manageFeatures = {
  key: 'manageFeatures',
  path: PATHS.manageFeatures,
  auth: routePermissions.manageFeatures,
  element: <FeatureManagement titleKey="manageFeatures" />,
};

export const plans = {
  key: 'plans',
  path: PATHS.plans,
  auth: routePermissions.plans,
  element: <Plans titleKey="plans" />,
  children: [
    plansList,
    pendingPlans,
    manageFeatures,
  ],
};

// =========================|| Challenges ||========================= //
export const createChallenge = {
  key: 'createChallenge',
  path: PATHS.createChallenge,
  auth: routePermissions.createChallenge,
  element: <CreateChallenge titleKey="createChallenge" />,
};
export const viewChallenge = {
  key: 'viewChallenge',
  path: PATHS.viewChallenge,
  auth: routePermissions.viewChallenge,
  element: <ViewChallenge titleKey="viewChallenge" />,
};
export const playerViewChallenge = {
  key: 'playerViewChallenge',
  path: PATHS.viewChallenge,
  auth: routePermissions.playerViewChallenge,
  element: <PlayerViewChallenge titleKey="viewChallenge" />,
};

// =========================|| Machines ||========================= //
export const machines = {
  key: 'machines',
  path: PATHS.machines,
  auth: routePermissions.machines,
  element: <Machines titleKey="machines" />,
};
export const manageMachines = {
  key: 'manageMachines',
  path: PATHS.machines,
  auth: routePermissions.manageMachines,
  element: <ManageMachines titleKey="manageMachines" />,
};
export const createMachine = {
  key: 'createMachine',
  path: PATHS.createMachine,
  auth: routePermissions.createMachine,
  element: <CreateMachine titleKey="createMachine" />,
};
export const viewMachine = {
  key: 'viewMachine',
  path: PATHS.viewMachine,
  auth: routePermissions.viewMachine,
  element: <ViewMachine titleKey="viewMachine" />,
};
export const playerViewMachine = {
  key: 'playerViewMachine',
  path: PATHS.viewMachine,
  auth: routePermissions.playerViewMachine,
  element: <PlayerViewMachine titleKey="viewMachine" />,
};
export const editMachine = {
  key: 'editMachine',
  path: PATHS.editMachine,
  auth: routePermissions.editMachine,
  element: <EditMachine titleKey="editMachine" />,
};

// =========================|| Labs ||========================= //
export const createLab = {
  key: 'createLab',
  path: PATHS.createLab,
  auth: routePermissions.createLab,
  element: <CreateLab titleKey="createLab" />,
};
export const viewLab = {
  key: 'viewLab',
  path: PATHS.viewLab,
  auth: routePermissions.viewLab,
  element: <ViewLab titleKey="viewLab" />,
  children: [
    createChallenge,
    viewChallenge,
  ],
};
export const premiumLabs = {
  key: 'premiumLabs',
  path: PATHS.premiumLabs,
  auth: routePermissions.premiumLabs,
  element: <PremiumLabs titleKey="premiumLabs" />,
  index: true,
};
const proLabs = {
  key: 'proLabs',
  path: PATHS.proLabs,
  auth: routePermissions.proLabs,
  element: <ProLabs titleKey="proLabs" />,
};
export const labs = {
  key: 'labs',
  path: PATHS.labs,
  auth: routePermissions.labs,
  element: <Labs titleKey="labs" />,
  children: [
    premiumLabs,
    proLabs,
    viewLab,
  ],
};
// PLAYER LABS
export const playerViewLab = {
  key: 'playerViewLab',
  path: PATHS.viewLab,
  auth: routePermissions.playerViewLab,
  element: <PlayerViewLab titleKey="viewLab" />,
  children: [
    playerViewChallenge,
  ],
};
const trainingLabs = {
  key: 'trainingLabs',
  path: PATHS.trainingLabs,
  auth: routePermissions.trainingLabs,
  element: <TrainingLabs titleKey="trainingLabs" />,
  children: [
    playerViewLab,
  ],
};
const competitiveLabs = {
  key: 'competitiveLabs',
  path: PATHS.competitiveLabs,
  auth: routePermissions.competitiveLabs,
  element: <CompetitiveLabs titleKey="competitiveLabs" />,
  children: [
    playerViewLab,
  ],
};
export const playerLabs = {
  key: 'playerLabs',
  path: PATHS.labs,
  auth: routePermissions.playerLabs,
  element: <PlayerLabs titleKey="labs" />,
  children: [
    trainingLabs,
    competitiveLabs,
  ],
};

// =========================|| Events ||========================= / /
export const createEvent = {
  key: 'createEvent',
  path: PATHS.createEvent,
  auth: routePermissions.createEvent,
  element: <CreateEvent titleKey="createEvent" />,
};
export const eventInvite = {
  key: 'eventInvite',
  path: PATHS.invite,
  auth: routePermissions.eventInvite,
  element: <EventInvitation titleKey="eventInvite" />,
};
export const editEventTeam = {
  key: 'editEventTeam',
  path: PATHS.editEventTeam,
  auth: routePermissions.editEventTeam,
  element: <EditEventTeam titleKey="editEventTeam" />,
};
export const viewEventTeam = {
  key: 'viewEventTeam',
  path: PATHS.viewEventTeam,
  auth: routePermissions.viewEventSingleTeam,
  element: <ViewEventTeam titleKey="viewTeam" />,
};
export const createEventChallenge = {
  key: 'createEventChallenge',
  path: 'create',
  auth: routePermissions.createEventChallenge,
  element: <CreateEventChallenge titleKey="createChallenge" />,
};
export const viewEventChallenge = {
  key: 'viewEventChallenge',
  path: ':challengeId',
  auth: routePermissions.viewEventChallenge,
  element: <ViewEventChallenge titleKey="viewChallenge" />,
};
export const viewEventDetails = {
  key: 'viewEventDetails',
  path: PATHS.details,
  auth: routePermissions.viewEvent,
  element: <ViewEventDetails titleKey="eventDetails" />,
  index: true,
};
export const viewEventInvitations = {
  key: 'viewEventInvitations',
  path: PATHS.invitations,
  auth: routePermissions.viewEvent,
  element: <ViewEventInvitations titleKey="invitations" />,
  children: [
    eventInvite,
  ],
};
export const viewEventChallenges = {
  key: 'viewEventChallenges',
  path: PATHS.challenges,
  auth: routePermissions.viewEvent,
  element: <ViewEventChallenges titleKey="challenges" />,
  children: [
    viewEventChallenge,
    createEventChallenge,
  ],
};
export const viewEventTeams = {
  key: 'viewEventTeams',
  path: PATHS.teams,
  auth: routePermissions.viewEventTeams,
  element: <ViewEventTeams titleKey="teams" />,
  children: [
    editEventTeam,
    viewEventTeam,
  ],
};
export const viewEventLeaderBoard = {
  key: 'viewEventLeaderBoard',
  path: PATHS.leaderBoard,
  auth: routePermissions.viewEventLeaderboard,
  element: <ViewEventLeaderboard titleKey="leaderBoard" />,
};
export const viewEventVisualization = {
  key: 'viewEventVisualization',
  path: PATHS.eventVisualization,
  auth: routePermissions.eventVisualization,
  element: <ViewEventVisualization titleKey="eventVisualization" />,
};
export const viewEvent = {
  key: 'viewEvent',
  path: PATHS.viewEvent,
  auth: routePermissions.viewEvent,
  element: <ViewEvent titleKey="viewEvent" />,
  children: [
    viewEventDetails,
    viewEventInvitations,
    viewEventChallenges,
    viewEventTeams,
    // viewEventLeaderBoard,
  ],
};
export const publicViewEvent = {
  key: 'publicViewEvent',
  path: `/${PATHS.events}/${PATHS.viewEvent}`,
  auth: routePermissions.publicViewEvent,
  element: <PublicViewEvent titleKey="viewEvent" />,
};
export const captainEditEventTeam = {
  key: 'captainEditTeam',
  path: `${PATHS.teams}/${PATHS.editEventTeam}`,
  auth: routePermissions.captainEditEventTeam,
  element: <CaptainEditTeam titleKey="captainEditTeam" />,
};
export const playerViewEventChallenge = {
  key: 'playerViewEventChallenge',
  path: PATHS.viewChallenge,
  auth: routePermissions.playerViewEventChallenge,
  element: <PlayerViewEventChallenge titleKey="viewChallenge" />,
};
export const playerViewEvent = {
  key: 'playerViewEvent',
  path: PATHS.viewEvent,
  auth: routePermissions.playerViewEvent,
  element: <PlayerViewEvent titleKey="viewEvent" />,
  children: [
    captainEditEventTeam,
    playerViewEventChallenge,
    viewEventLeaderBoard,
  ],
};
export const events = {
  key: 'events',
  path: PATHS.events,
  auth: routePermissions.events,
  element: <Events titleKey="events" />,
  children: [
    viewEvent,
  ],
};

export const companyOperatorEvents = {
  key: 'companyOperatorEvents',
  path: PATHS.root,
  auth: routePermissions.companyOperatorEvents,
  element: <Events titleKey="events" />,
  children: [
    viewEvent,
  ],
};

export const playerEvents = {
  key: 'playerEvents',
  path: PATHS.events,
  auth: routePermissions.playerEvents,
  element: <PlayerEvents titleKey="events" />,
  children: [
    playerViewEvent,
  ],
};

// =========================|| PromoCode ||========================= //
export const promoCode = {
  key: 'promoCode',
  path: PATHS.promoCodes,
  auth: routePermissions.promoCode,
  element: <PromoCode titleKey="promoCodes" />,
};
export const createPromoCode = {
  key: 'createPromoCode',
  path: PATHS.createPromoCode,
  auth: routePermissions.createPlan,
  element: <CreatePromoCode titleKey="createPromoCode" />,
};
export const viewPromoCode = {
  key: 'viewPromoCode',
  path: PATHS.viewPromoCode,
  auth: routePermissions.viewPromoCode,
  element: <ViewPromoCode titleKey="viewPromoCode" />,
};

// =========================|| Subscriptions ||========================= //
export const checkout = {
  key: 'checkout',
  path: PATHS.checkout,
  auth: routePermissions.checkout,
  element: <Checkout titleKey="checkout" />,
};
export const subscriptions = {
  key: 'subscriptions',
  path: PATHS.subscriptions,
  auth: routePermissions.subscriptions,
  element: <Subscriptions titleKey="subscriptions" />,
};

export const leaderBoard = {
  key: 'leaderBoard',
  path: PATHS.leaderBoard,
  auth: routePermissions.leaderBoard,
  element: <LeaderBoard titleKey="leaderBoard" />,
};

// =========================|| Announcements ||========================= //
export const announcements = {
  key: 'announcements',
  path: PATHS.announcements,
  auth: routePermissions.announcements,
  element: <Announcements titleKey="announcements" />,
};
export const createAnnouncement = {
  key: 'createAnnouncement',
  path: PATHS.createAnnouncement,
  auth: routePermissions.createAnnouncement,
  element: <CreateAnnouncement titleKey="createAnnouncement" />,
};
export const editAnnouncement = {
  key: 'editAnnouncement',
  path: PATHS.editAnnouncement,
  auth: routePermissions.editAnnouncement,
  element: <EditAnnouncement titleKey="editAnnouncement" />,
};
export const viewAnnouncement = {
  key: 'viewAnnouncement',
  path: PATHS.viewAnnouncement,
  auth: routePermissions.viewAnnouncement,
  element: <ViewAnnouncement titleKey="viewAnnouncement" />,
};

// =========================|| Instances ||========================= //
export const instances = {
  key: 'instances',
  path: PATHS.instances,
  auth: routePermissions.instances,
  element: <Instances titleKey="instances" />,
};

// =========================|| Forms ||========================= //
export const hitbctf = {
  key: 'hitbctf',
  path: PATHS.hitbctf,
  auth: routePermissions.hitbctf,
  element: <Hitbctf titleKey="Hitbctf" />,
};
export const hitbVisitors = {
  key: 'hitbVisitors',
  path: PATHS.hitbVisitors,
  auth: routePermissions.hitbVisitors,
  element: <HitbVisitors titleKey="HitbVisitors" />,
};
