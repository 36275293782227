import { useState } from 'react';
import { LocalizationProvider, DesktopDateTimePicker as MUIDateTimePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import TextField from '@mui/material/TextField';

const DateTimePicker = (props) => {
  const {
    pickerProps = {
      onChange: () => { },
    },
    inputProps = {},
  } = props;

  const [open, setOpen] = useState(false);

  // Handle interactions when disabled
  const { disabled = false } = inputProps;
  const disabledProps = {
    disabled,
    disableOpenPicker: disabled,
  };

  return (
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
    >
      <MUIDateTimePicker
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        {...disabledProps}
        renderInput={(params) => (
          <TextField
            {...params}
            {...inputProps}
            onClick={(e) => setOpen(!open)}
            autoComplete="off"
            onKeyDown={(e) => e.preventDefault()}
            sx={{
              svg: { color: 'secondary.main' },
            }}
          />
        )}
        {...pickerProps}
      />
    </LocalizationProvider>
  );
};

export default DateTimePicker;
