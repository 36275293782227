import { useTheme } from '@mui/styles';

const TagIcon = (props) => {
  const theme = useTheme();
  const {
    stroke = theme.palette.text.raspberry,
    fill = 'none',
    ...rest
  } = props;

  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill={fill} xmlns="http://www.w3.org/2000/svg" stroke={stroke} {...rest}>
      <path d="M0.945312 2.19495V7.40995C0.945454 8.07293 1.20894 8.70871 1.67781 9.17745L11.3695 18.8691C11.4495 18.9492 11.5497 19.006 11.6594 19.0335C11.7691 19.0611 11.8843 19.0584 11.9926 19.0256C12.1009 18.9928 12.1983 18.9313 12.2743 18.8475C12.3504 18.7637 12.4023 18.6609 12.4245 18.5499L13.4453 13.4449L18.5503 12.4241C18.6613 12.4019 18.7641 12.35 18.8479 12.274C18.9316 12.1979 18.9932 12.1005 19.026 11.9922C19.0587 11.8839 19.0615 11.7688 19.0339 11.659C19.0064 11.5493 18.9495 11.4491 18.8695 11.3691L9.17781 1.67745C8.70908 1.20857 8.0733 0.945088 7.41031 0.944946H2.19531C1.86379 0.944946 1.54585 1.07664 1.31143 1.31106C1.07701 1.54548 0.945313 1.86343 0.945312 2.19495V2.19495Z" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M5.32031 6.56995C6.01067 6.56995 6.57031 6.0103 6.57031 5.31995C6.57031 4.62959 6.01067 4.06995 5.32031 4.06995C4.62996 4.06995 4.07031 4.62959 4.07031 5.31995C4.07031 6.0103 4.62996 6.56995 5.32031 6.56995Z" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default TagIcon;
