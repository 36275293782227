import {
  Box,
  Grid,
  Tooltip,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/styles';
import { useLocale } from 'util/hooks';

import { InfoCard } from 'components/molecules';

import {
  BloodDropFilledIcon,
  InformationCircleIcon,
  RankFilledIcon,
  SparklingFilledIcon,
} from 'assets/icons';

const PlayerStats = (props) => {
  const { profileDetails = {} } = props;
  const { t } = useLocale();
  const { palette } = useTheme();

  const {
    user = {},
  } = profileDetails || {};
  const {
    activePoints = 0,
    totalPoints = 0,
    rank = 0,
    firstBloods = 0,
  } = user || {};

  const noRankPlaceholder = (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Typography variant="bodyStandardBold" sx={{ mr: 2 }}>
        {t('dashboard.unranked')}
      </Typography>
      <Tooltip
        title={t('dashboard.unrankedHint')}
        placement="top"
      >
        <Box sx={{ display: 'flex', width: 14, height: 14 }}>
          <InformationCircleIcon width="14" height="14" />
        </Box>
      </Tooltip>
    </Box>
  );

  const stats = [{
    key: 'player-active-points',
    icon: <SparklingFilledIcon width="45" height="45" />,
    title: t('profile.activePoints'),
    tooltipTitle: t('profile.tip.activePoints'),
    component: (
      <Typography variant="bodyStandardBold">
        {`${activePoints} ${t('challenges.points')}`}
      </Typography>
    ),
  },
  {
    key: 'player-total-points',
    icon: <SparklingFilledIcon
      width="45"
      height="45"
      fill={palette.text.lightGreen}
      stroke={palette.success.main}
    />,
    title: t('profile.totalPoints'),
    tooltipTitle: t('profile.tip.totalPoints'),
    component: (
      <Typography variant="bodyStandardBold">
        {`${totalPoints} ${t('challenges.points')}`}
      </Typography>
    ),
  },
  {
    key: 'player-rank',
    icon: <RankFilledIcon width="45" height="45" />,
    title: t('common.rank'),
    component: (
      rank
        ? (
          <Typography variant="bodyStandardBold">
            {`#${rank}`}
          </Typography>
        ) : noRankPlaceholder
    ),
  },
  {
    key: 'player-first-bloods',
    icon: <BloodDropFilledIcon width="45" height="45" />,
    title: t('common.firstBloods'),
    component: (
      <Typography variant="bodyStandardBold">
        {firstBloods}
      </Typography>
    ),
  }];

  return (
    <Box>
      <Grid container spacing={6}>
        {stats.map((stat) => (
          <InfoCard
            {...stat}
            size="small"
            cardSx={{ minWidth: 256 }}
          />
        ))}
      </Grid>
    </Box>
  );
};

export default PlayerStats;
