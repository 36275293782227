import { Box, Stack } from '@mui/material';
import { useLocale } from 'util/hooks';

import { NoContentPlaceholder } from 'components/molecules';

import PaymentMethodCard from '../../PaymentMethodCard/PaymentMethodCard';

const PaymentMethodList = (props) => {
  const {
    paymentCards = [],
  } = props;

  const { t } = useLocale();

  if (!paymentCards?.length) {
    const content = {
      desc: [{
        text: t('paymentMethods.noPaymentAdded'),
        variant: 'bodyMediumBold',
      }],
    };

    return (
      <Box mt={30}>
        <NoContentPlaceholder content={content} />
      </Box>
    );
  }

  return (
    <Box>
      <Stack spacing={6}>
        {paymentCards?.map((paymentMethod) => (
          <PaymentMethodCard
            key={paymentMethod.id}
            cardDetails={paymentMethod}
          />
        ))}
      </Stack>
    </Box>
  );
};

export default PaymentMethodList;
