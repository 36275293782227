import { useTheme } from '@mui/styles';

const TwoUsersIcon = (props) => {
  const theme = useTheme();
  const {
    stroke = theme.palette.text.secondary,
  } = props;

  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_14785_24476)">
        <path d="M5 8C6.51878 8 7.75 6.76878 7.75 5.25C7.75 3.73122 6.51878 2.5 5 2.5C3.48122 2.5 2.25 3.73122 2.25 5.25C2.25 6.76878 3.48122 8 5 8Z" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M0.5 13.5C0.5 12.3065 0.974106 11.1619 1.81802 10.318C2.66193 9.47411 3.80653 9 5 9C6.19347 9 7.33807 9.47411 8.18198 10.318C9.02589 11.1619 9.5 12.3065 9.5 13.5" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M11.8184 9C13.061 9 14.0684 7.99264 14.0684 6.75C14.0684 5.50736 13.061 4.5 11.8184 4.5C10.5757 4.5 9.56836 5.50736 9.56836 6.75C9.56836 7.99264 10.5757 9 11.8184 9Z" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M10.543 10.0457C11.0997 9.84047 11.6977 9.77279 12.2862 9.84837C12.8747 9.92395 13.4363 10.1406 13.9231 10.4798C14.4099 10.819 14.8076 11.2707 15.0823 11.7966C15.357 12.3225 15.5006 12.907 15.501 13.5003" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      </g>
      <defs>
        <clipPath id="clip0_14785_24476">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default TwoUsersIcon;
