import { useTheme } from '@mui/styles';

const EmailReplyIcon = (props) => {
  const theme = useTheme();
  const {
    stroke = theme.palette.text.secondary,
    fill = 'none',
    ...rest
  } = props;

  return (
    <svg width="28" height="28" viewBox="0 0 28 28" fill={fill} xmlns="http://www.w3.org/2000/svg" stroke={stroke} {...rest}>
      <path d="M20.125 27.125C23.991 27.125 27.125 23.991 27.125 20.125C27.125 16.259 23.991 13.125 20.125 13.125C16.259 13.125 13.125 16.259 13.125 20.125C13.125 23.991 16.259 27.125 20.125 27.125Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M23.625 20.125H16.625" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M16.625 20.125L19.25 22.75" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M16.625 20.125L19.25 17.5" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M9.625 18.375H2.625C2.16087 18.375 1.71575 18.1906 1.38756 17.8624C1.05937 17.5342 0.875 17.0891 0.875 16.625V2.625C0.875 2.16087 1.05937 1.71575 1.38756 1.38756C1.71575 1.05937 2.16087 0.875 2.625 0.875H23.625C24.0891 0.875 24.5342 1.05937 24.8624 1.38756C25.1906 1.71575 25.375 2.16087 25.375 2.625V10.5" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M24.9795 1.5166L15.4782 8.8246C14.8035 9.3436 13.9762 9.625 13.125 9.625C12.2738 9.625 11.4465 9.3436 10.7718 8.8246L1.27051 1.5166" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>

  );
};

export default EmailReplyIcon;
