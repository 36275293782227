import { useTheme } from '@mui/styles';

const MoneyBagsIcon = (props) => {
  const theme = useTheme();
  const {
    stroke = theme.palette.text.lighterRaspberry,
    fill = 'none',
    ...rest
  } = props;

  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill={fill} xmlns="http://www.w3.org/2000/svg" stroke={stroke} {...rest}>
      <path d="M14.045 7.98782C16.091 9.39982 18.75 12.7998 18.75 15.8628C18.75 18.9698 15.389 21.4878 12 21.4878C8.611 21.4878 5.25 18.9698 5.25 15.8628C5.25 12.7998 7.909 9.39982 9.955 7.98782L8.4 4.28082C8.33739 4.17151 8.29843 4.05028 8.28562 3.92497C8.27282 3.79966 8.28647 3.67305 8.32568 3.55334C8.36489 3.43364 8.42879 3.32349 8.51326 3.23004C8.59772 3.13658 8.70086 3.0619 8.816 3.01082C10.8827 2.33233 13.1123 2.33233 15.179 3.01082C15.2946 3.06142 15.3982 3.13576 15.4832 3.229C15.5682 3.32224 15.6326 3.43231 15.6723 3.55207C15.712 3.67182 15.7261 3.79859 15.7136 3.92413C15.7012 4.04968 15.6625 4.1712 15.6 4.28082L14.045 7.98782Z" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M9.95508 7.98828H14.0451" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M4.5 20.7383C1.5 20.7383 0.75 17.7383 0.75 15.6243C0.794168 14.3855 1.14418 13.177 1.76883 12.1063C2.39348 11.0357 3.27335 10.1363 4.33 9.48828L3.066 7.66528C2.9881 7.5528 2.94246 7.42114 2.93401 7.28458C2.92556 7.14801 2.95464 7.01174 3.01808 6.89051C3.08153 6.76928 3.17693 6.66772 3.29396 6.59682C3.41098 6.52592 3.54517 6.48839 3.682 6.48828H6" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M10.329 17.3324C10.5455 17.617 10.8274 17.8452 11.1509 17.9976C11.4743 18.1501 11.8297 18.2224 12.187 18.2084C13.326 18.2084 14.25 17.5154 14.25 16.6604C14.25 15.8054 13.326 15.1144 12.187 15.1144C11.048 15.1144 10.125 14.4214 10.125 13.5664C10.125 12.7114 11.049 12.0194 12.187 12.0194C12.5443 12.0049 12.8997 12.0769 13.2232 12.2292C13.5467 12.3816 13.8286 12.6098 14.045 12.8944" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M12.1875 18.207V19.237" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M12.1875 10.9883V12.0193" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M19.5 20.7383C22.5 20.7383 23.25 17.7383 23.25 15.6243C23.2058 14.3855 22.8558 13.177 22.2312 12.1063C21.6065 11.0357 20.7266 10.1363 19.67 9.48828L20.934 7.66528C21.0119 7.5528 21.0575 7.42114 21.066 7.28458C21.0744 7.14801 21.0454 7.01174 20.9819 6.89051C20.9185 6.76928 20.8231 6.66772 20.706 6.59682C20.589 6.52592 20.4548 6.48839 20.318 6.48828H18" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};
export default MoneyBagsIcon;
