import { useTheme } from '@mui/styles';

const PlayButtonIcon = (props) => {
  const theme = useTheme();
  const {
    stroke = theme.palette.text.secondary,
    ...rest
  } = props;

  return (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <g clipPath="url(#clip0_11617_166159)">
        <path d="M14 27.1226C21.2487 27.1226 27.125 21.2463 27.125 13.9976C27.125 6.74882 21.2487 0.872559 14 0.872559C6.75126 0.872559 0.875 6.74882 0.875 13.9976C0.875 21.2463 6.75126 27.1226 14 27.1226Z" stroke={stroke} strokeWidth="1.75" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M10.5 18.2149C10.5003 18.5812 10.6061 18.9398 10.8046 19.2477C11.0032 19.5556 11.2862 19.7999 11.6198 19.9513C11.9534 20.1027 12.3235 20.1549 12.686 20.1017C13.0485 20.0484 13.388 19.892 13.664 19.651L20.125 13.9997L13.664 8.34487C13.3882 8.10339 13.0486 7.94651 12.686 7.89299C12.3233 7.83948 11.9529 7.8916 11.6191 8.04312C11.2853 8.19464 11.0022 8.43914 10.8037 8.74735C10.6053 9.05557 10.4998 9.41445 10.5 9.78104V18.2149Z" stroke={stroke} strokeWidth="1.75" strokeLinecap="round" strokeLinejoin="round" />
      </g>
      <defs>
        <clipPath id="clip0_11617_166159">
          <rect width="28" height="28" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default PlayButtonIcon;
