import { useTheme } from '@mui/styles';

const ProgressFilledIcon = (props) => {
  const { palette } = useTheme();
  const {
    stroke = palette.text.secondary,
    fill = palette.text.secondary,
    ...rest
  } = props;

  return (
    <svg width="49" height="44" viewBox="0 0 49 44" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <rect x="0.5" width="48" height="44" rx="6" fill={fill} fillOpacity="0.1" />
      <g clipPath="url(#clip0_9756_15787)">
        <path d="M24.5 32.3125C30.1954 32.3125 34.8125 27.6954 34.8125 22C34.8125 16.3046 30.1954 11.6875 24.5 11.6875C18.8046 11.6875 14.1875 16.3046 14.1875 22C14.1875 27.6954 18.8046 32.3125 24.5 32.3125Z" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M16.9375 22.6877H19.6875L21.7592 17.5086C21.8113 17.3794 21.9015 17.2692 22.0177 17.1926C22.134 17.1159 22.2708 17.0764 22.41 17.0792C22.5492 17.0821 22.6843 17.1273 22.7972 17.2087C22.9102 17.2901 22.9957 17.404 23.0425 17.5351L26.0602 26.0831C26.104 26.2072 26.1825 26.3161 26.2865 26.3969C26.3904 26.4777 26.5153 26.5269 26.6464 26.5388C26.7775 26.5507 26.9093 26.5247 27.026 26.4639C27.1427 26.4031 27.2396 26.31 27.305 26.1958L29.3125 22.6877H32.0625" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      </g>
    </svg>
  );
};

export default ProgressFilledIcon;
