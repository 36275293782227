import React from 'react';
import { SocketContext } from 'context';
import SOCKET_EVENTS from 'assets/constants/socketEvents';
import MODAL_KEYS from 'assets/constants/modalKeys';
import { useAuth, useModal } from 'util/hooks';

const GlobalSocketEvents = () => {
  const socket = React.useContext(SocketContext);
  const { addModal } = useModal();
  const { refreshUser } = useAuth();

  const events = React.useMemo(() => [
    {
      event: SOCKET_EVENTS.badgeEarned,
      handler: (data) => {
        addModal({
          key: MODAL_KEYS.badgeEarned,
          props: { badgeEarned: data },
        });
      },
    },
    {
      event: SOCKET_EVENTS.refreshUser,
      handler: () => {
        refreshUser();
      },
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
  ], []);

  React.useEffect(() => {
    events.forEach(({
      event = '*',
      handler = () => { },
    }) => {
      socket.on(event, handler);
    });

    return () => {
      events.forEach(({
        event = '*',
        handler = () => { },
      }) => {
        socket.off(event, handler);
      });
    };
  }, [socket, events]);

  return null;
};

export default GlobalSocketEvents;
