import { useTheme } from '@mui/styles';

const UserFilledIcon = (props) => {
  const { palette } = useTheme();

  const {
    stroke = palette.text.lightPurple,
    fill = palette.text.lightPurple,
    ...rest
  } = props;

  return (
    <svg width="46" height="45" viewBox="0 0 46 45" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <rect x="0.5" width="45" height="45" rx="7.125" fill={fill} fillOpacity="0.2" />
      <g clipPath="url(#clip0_4797_55256)">
        <path d="M23.5 22.375C25.9162 22.375 27.875 20.4162 27.875 18C27.875 15.5838 25.9162 13.625 23.5 13.625C21.0838 13.625 19.125 15.5838 19.125 18C19.125 20.4162 21.0838 22.375 23.5 22.375Z" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M15.375 32.375C15.375 30.2201 16.231 28.1535 17.7548 26.6298C19.2785 25.106 21.3451 24.25 23.5 24.25C25.6549 24.25 27.7215 25.106 29.2452 26.6298C30.769 28.1535 31.625 30.2201 31.625 32.375" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      </g>
      <defs>
        <clipPath id="clip0_4797_55256">
          <rect width="20" height="20" fill="white" transform="translate(13.5 13)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default UserFilledIcon;
