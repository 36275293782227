function Exception(message, props) {
  const error = new Error(message);

  const errorWithProps = Object.assign(error, props);
  return errorWithProps;
}

Exception.prototype = Object.create(Error.prototype);

export default Exception;
