import { useMemo } from 'react';
import _ from 'lodash';
import { Box, Typography } from '@mui/material';

import { useLocale } from 'util/hooks';
import { STATUS } from 'assets/constants/statuses';

import ChallengeFilter from '../helpers/ChallengeFilter';

const ChallengeToolbar = (props) => {
  const {
    rows = [],
    status = '',
    showFilter = false,
  } = props;

  const { t } = useLocale();

  const {
    true: solved, // solved challenges for this status & visibility
    false: unsolved,
  } = useMemo(() => _.groupBy(rows, (row) => row.numberOfSolutions > 0), [rows]);

  const isDraftList = status === STATUS.draft;
  const isPendingList = status === STATUS.pending;

  const showNumberOfSolves = !isDraftList && !isPendingList;

  return (
    <Box sx={{
      display: 'flex',
      alignItems: 'center',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      mt: 12,
      mb: showFilter ? 9 : 0,
    }}
    >
      {showFilter && (
        <Box>
          <ChallengeFilter />
        </Box>
      )}
      {showNumberOfSolves
        && (
          <Box>
            <Typography
              variant="bodyStandardRegular"
              color="text.bluishGray"
            >
              {`${solved?.length || 0} 
              ${t('common.solved')} — ${unsolved?.length || 0} 
              ${t('common.unsolved')}`}
            </Typography>
          </Box>
        )}
    </Box>
  );
};

export default ChallengeToolbar;
