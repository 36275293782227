const MachinesIcon = (props) => {
  const {
    stroke = '',
    fill = 'none',
    ...rest
  } = props;

  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill={fill} xmlns="http://www.w3.org/2000/svg" {...rest}>
      <path d="M3 4.75C2.95055 4.75 2.90222 4.76466 2.86111 4.79213C2.82 4.8196 2.78795 4.85865 2.76903 4.90433C2.75011 4.95001 2.74516 5.00028 2.7548 5.04877C2.76445 5.09727 2.78826 5.14181 2.82322 5.17678C2.85819 5.21174 2.90273 5.23555 2.95123 5.2452C2.99972 5.25484 3.04999 5.24989 3.09567 5.23097C3.14135 5.21205 3.1804 5.18 3.20787 5.13889C3.23534 5.09778 3.25 5.04945 3.25 5C3.25 4.9337 3.22366 4.87011 3.17678 4.82322C3.12989 4.77634 3.0663 4.75 3 4.75Z" stroke={stroke} strokeWidth="0.9375" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M3 10.75C2.95055 10.75 2.90222 10.7647 2.86111 10.7921C2.82 10.8196 2.78795 10.8586 2.76903 10.9043C2.75011 10.95 2.74516 11.0003 2.7548 11.0488C2.76445 11.0973 2.78826 11.1418 2.82322 11.1768C2.85819 11.2117 2.90273 11.2355 2.95123 11.2452C2.99972 11.2548 3.04999 11.2499 3.09567 11.231C3.14135 11.212 3.1804 11.18 3.20787 11.1389C3.23534 11.0978 3.25 11.0494 3.25 11C3.25 10.9337 3.22366 10.8701 3.17678 10.8232C3.12989 10.7763 3.0663 10.75 3 10.75Z" stroke={stroke} strokeWidth="0.9375" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M12.502 2C13.2976 2 14.0607 2.31607 14.6233 2.87868C15.1859 3.44129 15.502 4.20435 15.502 5C15.502 5.79565 15.1859 6.55871 14.6233 7.12132C14.0607 7.68393 13.2976 8 12.502 8H3.50195C2.7063 8 1.94324 7.68393 1.38063 7.12132C0.818024 6.55871 0.501953 5.79565 0.501953 5C0.501953 4.20435 0.818024 3.44129 1.38063 2.87868C1.94324 2.31607 2.7063 2 3.50195 2H12.502Z" stroke={stroke} strokeWidth="0.9375" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M12.502 8C12.8959 8 13.286 8.0776 13.65 8.22836C14.014 8.37913 14.3447 8.6001 14.6233 8.87868C14.9019 9.15726 15.1228 9.48797 15.2736 9.85195C15.4244 10.2159 15.502 10.606 15.502 11C15.502 11.394 15.4244 11.7841 15.2736 12.1481C15.1228 12.512 14.9019 12.8427 14.6233 13.1213C14.3447 13.3999 14.014 13.6209 13.65 13.7716C13.286 13.9224 12.8959 14 12.502 14H3.50195C2.7063 14 1.94324 13.6839 1.38063 13.1213C0.818024 12.5587 0.501953 11.7956 0.501953 11C0.501953 10.2044 0.818024 9.44129 1.38063 8.87868C1.94324 8.31607 2.7063 8 3.50195 8H12.502Z" stroke={stroke} strokeWidth="0.9375" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M7.00195 5H12.502" stroke={stroke} strokeWidth="0.9375" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M7.00195 11H12.502" stroke={stroke} strokeWidth="0.9375" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default MachinesIcon;
