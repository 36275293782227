import { RadioGroup } from 'components/molecules';
import { useLocale } from 'util/hooks';
import VISIBILITIES from 'assets/constants/visibilities';
import { useRecoilState } from 'recoil';
import { machinesFilterAtom } from 'recoil/atoms';

const MachineFilter = () => {
  const { t } = useLocale();

  const [filter, setFilter] = useRecoilState(machinesFilterAtom);

  const handleFilterChange = (newFilter) => {
    setFilter((prev) => ({
      ...prev,
      value: newFilter,
    }));
  };

  const options = [{
    value: VISIBILITIES.visible,
    label: t('machines.visibleMachines'),
  },
  {
    value: VISIBILITIES.hidden,
    label: t('machines.hiddenMachines'),
  }];

  return (
    <RadioGroup
      value={filter.value}
      onChange={handleFilterChange}
      options={options}
      radioProps={{
        'aria-labelledby': 'machine-visibility-radio-buttons-group',
        name: 'machine-visibility-radio-buttons-group',
      }}
    />
  );
};

export default MachineFilter;
