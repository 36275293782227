import { Box } from '@mui/material';

const CarouselItem = (props) => {
  const { children, sx, ...rest } = props;

  return (
    <Box
      sx={{
        overflow: 'hidden',
        borderRadius: 1.5,
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: 'primary.main',
        width: 1,
        height: 268,
        ...sx,
      }}
      {...rest}
    >
      {children}
    </Box>
  );
};

export default CarouselItem;
