import { useMutation } from '@tanstack/react-query';
import { inviteUserService } from 'services';

const useInviteUser = (options = {}) => {
  const mutation = useMutation({
    mutationFn: (payload) => inviteUserService(payload),
    ...options,
  });

  return mutation;
};

export default useInviteUser;
