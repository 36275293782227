import { useLocale } from 'util/hooks';
import { useTheme } from '@mui/styles';

import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';

import {
  TypographyWithIcon,
  VisibilityBadge,
} from 'components/molecules';

import {
  ArrowRightIcon,
  CheckCircleOutline,
  SparklingIcon,
  UserIcon,
} from 'assets/icons';
import { getStatusByName } from 'assets/constants/statuses';
import { getDifficultyByName } from 'assets/constants/difficulties';
import { capitalizeFirstLetter } from 'util/helpers';
import { useEventDetails } from 'reactQuery/queries';
import { Tooltip } from '@mui/material';

const useListColumns = (props) => {
  const {
    isPlayer = false,
    isArchivedList = false,
    isDraftList = false,
    isPendingList = false,
    isRetiredList = false,
  } = props;

  const { t, isRtl } = useLocale();
  const { palette } = useTheme();

  const {
    data: eventDetails = {},
  } = useEventDetails();

  const {
    isDynamicPoints = false,
  } = eventDetails;

  const showSelectionHeader = !isPlayer && !isArchivedList;
  const showSolutionsColumn = !isPlayer && !isDraftList && !isPendingList;
  const showVisibilityColumn = !isPlayer;
  const showStatusColumn = !isPlayer;
  const showPointsColumn = isPlayer && !isRetiredList;
  const showNumberOfResolvesColumn = isPlayer;
  const showIsCompletedColumn = isPlayer;

  const iconSize = {
    width: 18,
    height: 18,
  };

  const columns = [
    // Common
    {
      field: 'id',
      headerName: '',
      hide: true,
    },
    {
      field: 'name',
      headerName: showSelectionHeader ? t('common.selectAll') : '',
      sortable: false,
      flex: 1,
      minWidth: 150,
      renderCell: ({ row } = {}) => {
        const { name = '', category = {} } = row;

        return (
          <Box>
            <Box>
              <Typography variant="bodyStandardMedium">
                {name}
              </Typography>
            </Box>
            <Box>
              <Typography variant="bodySmallMedium" color="text.secondary">
                {capitalizeFirstLetter(category?.name)}
              </Typography>
            </Box>
          </Box>
        );
      },
    },
    {
      field: 'difficulty',
      headerName: '',
      sortable: false,
      flex: 1,
      minWidth: 75,
      renderCell: (params) => (
        <Chip
          size="small"
          color={params.row.difficulty || ''}
          label={t(getDifficultyByName(params?.row?.difficulty)?.localizationKey) || ''}
        />
      ),
    },
  ];

  // Internal users only
  if (showSolutionsColumn) {
    columns.push({
      field: 'numberOfSolutions',
      headerName: '',
      sortable: false,
      flex: 1,
      minWidth: 75,
      valueGetter: ({ row: { numberOfSolutions } }) => `${numberOfSolutions || 0} ${t('challenges.hacks')}`,
    });
  }

  if (showVisibilityColumn) {
    columns.push({
      field: 'visible',
      headerName: '',
      sortable: false,
      flex: 1,
      minWidth: 75,
      renderCell: ({ row: { visible } }) => <VisibilityBadge visible={visible} />,
    });
  }

  if (showStatusColumn) {
    columns.push({
      field: 'status',
      headerName: '',
      sortable: false,
      flex: 1,
      minWidth: 75,
      renderCell: (params) => (
        <Chip
          size="small"
          color={params.row.status || ''}
          label={t(getStatusByName(params?.row?.status)?.localizationKey) || ''}
        />
      ),
    });
  }

  if (showPointsColumn) {
    columns.push({
      field: 'points',
      headerName: '',
      sortable: false,
      flex: 1,
      minWidth: 75,
      renderCell: ({ row: { points = 0, currentSubmissionPoints = 0 } }) => (
        <Tooltip
          title={t('common.dynamicPointsToolTip')}
          placement="bottom"
          componentsProps={{
            tooltip: {
              sx: {
                display: isDynamicPoints ? 'block' : 'none',
              },
            },
          }}
        >
          <Box display="flex" gap={2}>
            <TypographyWithIcon
              label={`${isDynamicPoints ? currentSubmissionPoints : points} ${t('challenges.points')}`}
              labelProps={{ variant: 'BodyStandardMedium' }}
              icon={(
                <SparklingIcon
                  {...iconSize}
                />
              )}
            />
          </Box>
        </Tooltip>
      ),
    });
  }

  if (showNumberOfResolvesColumn) {
    columns.push({
      field: 'numberOfResolves',
      headerName: '',
      sortable: false,
      flex: 1,
      minWidth: 75,
      renderCell: ({ row: { numberOfSolutions } }) => (
        <TypographyWithIcon
          label={`${numberOfSolutions || 0} ${t('challenges.hacks')}`}
          labelProps={{ variant: 'BodyStandardMedium' }}
          icon={(
            <UserIcon
              stroke={palette.text.lightPurple}
              {...iconSize}
            />
          )}
        />
      ),
    });
  }

  if (showIsCompletedColumn) {
    columns.push({
      field: 'isCompleted',
      headerName: '',
      sortable: false,
      flex: 1,
      minWidth: 75,
      renderCell: ({ row: { isCompletedByUser } }) => (
        isCompletedByUser && (
          <TypographyWithIcon
            label={t('challenges.completed')}
            labelProps={{ variant: 'BodyStandardMedium' }}
            icon={(
              <CheckCircleOutline
                stroke={palette.success.main}
                {...iconSize}
              />
            )}
          />
        )
      ),
    });
  }

  // Add arrow at the end
  columns.push({
    field: 'actions',
    headerName: '',
    sortable: false,
    minWidth: 50,
    renderCell: () => (
      <ArrowRightIcon style={{ transform: isRtl ? 'scaleX(-1)' : 'none' }} />
    ),
  });

  return columns;
};

export default useListColumns;
