import { Box, Typography } from '@mui/material';
import ONLINE_STATUSES from 'assets/constants/onlineStatuses';

import { useLocale } from 'util/hooks';
import AnimatedBadge from '../AnimatedBadge/AnimatedBadge';
import StatusBadge from '../StatusBadge/StatusBadge';

const OnlineStatusBadge = (props) => {
  const { online = false } = props;

  const { t } = useLocale();

  const onlineStatusString = online
    ? ONLINE_STATUSES.online
    : ONLINE_STATUSES.offline;

  const animatedOnlineIcon = (
    <Box>
      <AnimatedBadge />
    </Box>
  );

  const offlineIcon = <StatusBadge status={ONLINE_STATUSES.offline} />;

  return (
    <Box sx={{
      display: 'flex',
      alignItems: 'center',
    }}
    >
      {online
        ? animatedOnlineIcon
        : offlineIcon}
      <Box sx={{
        ml: 2,
      }}
      >
        <Typography variant="bodySmallMedium" color="text.secondary">
          {t(`onlineStatuses.${onlineStatusString}`)}
        </Typography>
      </Box>
    </Box>
  );
};

export default OnlineStatusBadge;
