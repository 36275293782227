import { getUserResets } from 'services';
import useCustomQuery from 'reactQuery/useCustomQuery';

const useUserResets = (queryProps = {}) => {
  const query = useCustomQuery({
    queryKey: ['user-resets'],
    queryFn: getUserResets,
    ...queryProps,
  });

  return { ...query };
};

export default useUserResets;
