import { useTheme } from '@mui/styles';

const EarthIcon = (props) => {
  const theme = useTheme();
  const {
    stroke = theme.palette.text.darkGray,
    fill = 'none',
    ...rest
  } = props;

  return (
    <svg width="22" height="22" viewBox="0 0 16 16" fill={fill} xmlns="http://www.w3.org/2000/svg" {...rest}>
      <path d="M8 15.5C12.1421 15.5 15.5 12.1421 15.5 8C15.5 3.85786 12.1421 0.5 8 0.5C3.85786 0.5 0.5 3.85786 0.5 8C0.5 12.1421 3.85786 15.5 8 15.5Z" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" />
      <path d="M4.71935 10.5C4.87132 10.5 5.02128 10.4653 5.15785 10.3987C5.29442 10.332 5.41401 10.2351 5.50752 10.1153C5.60103 9.99552 5.666 9.85599 5.69751 9.70732C5.72902 9.55865 5.72622 9.40476 5.68935 9.25733L5.18935 7.25733C5.13524 7.04102 5.01039 6.84901 4.83464 6.71179C4.65889 6.57457 4.44232 6.50002 4.21935 6.5H0.65068C0.374931 7.85458 0.479053 9.25902 0.951539 10.5581C1.42403 11.8572 2.24648 13.0004 3.32801 13.8613L4.00001 10.5H4.71935Z" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" />
      <path d="M13.9905 3.5H11.2811C11.0582 3.50002 10.8416 3.57457 10.6658 3.71179C10.4901 3.84901 10.3652 4.04103 10.3111 4.25733L9.81114 6.25733C9.77426 6.40476 9.77147 6.55865 9.80298 6.70732C9.83448 6.85599 9.89946 6.99552 9.99297 7.11532C10.0865 7.23511 10.2061 7.33202 10.3426 7.39867C10.4792 7.46533 10.6292 7.49998 10.7811 7.5H11.8338L12.3611 10.6667C12.4001 10.9002 12.5207 11.1123 12.7013 11.2652C12.882 11.4182 13.1111 11.5021 13.3478 11.502H14.6311C15.2971 10.2459 15.5905 8.82578 15.477 7.4086C15.3635 5.99143 14.8478 4.63612 13.9905 3.502V3.5Z" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default EarthIcon;
