import { Box } from '@mui/material';

import { LabCard } from 'components/organisms';

const LabList = (props) => {
  const { labs = [] } = props;

  return (
    <Box
      sx={{
        display: 'grid',
        columnGap: 9,
        rowGap: 8,
        gridTemplateColumns: {
          xs: 'repeat(1, 1fr)',
          md: 'repeat(2, 1fr)',
          lg: 'repeat(3, 1fr)',
          xl: 'repeat(4, 1fr)',
        },
      }}
    >
      {labs.map((lab) => (
        <Box key={lab.id}>
          <LabCard lab={lab} />
        </Box>
      ))}
    </Box>
  );
};

export default LabList;
