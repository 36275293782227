import _ from 'lodash';
import {
  Card,
  Box,
  Typography,
  Chip,
  Link,
} from '@mui/material';

import {
  ChallengeAvatar,
  StatusBadge,
} from 'components/molecules';

import { PATHS } from 'routes';
import { useLocale } from 'util/hooks';
import { getDifficultyByName } from 'assets/constants/difficulties';

import ONLINE_STATUSES from 'assets/constants/onlineStatuses';
import { useLatestChallenge } from 'reactQuery/queries';
import { capitalizeFirstLetter } from 'util/helpers';

const LatestChallengeCard = (props) => {
  const { type } = props;
  const { t } = useLocale();

  const {
    data: [latestChallenge = {}],
    isFetched = false,
  } = useLatestChallenge({
    queryKeyOptions: {
      searchOptions: {
        type,
      },
      pagination: {
        limit: 1,
      },
    },
  });

  if (isFetched && _.isEmpty(latestChallenge)) {
    return null;
  }

  return (
    <Card
      sx={{
        minHeight: 137,
        px: { xs: 5, md: 8 },
        py: 9,
      }}
    >
      <Box sx={{
        display: 'flex',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
        alignItems: 'center',
        rowGap: 5,
      }}
      >
        <Box>
          <Box sx={{
            display: 'flex',
            alignItems: 'center',
            ml: 21,
          }}
          >
            <Box
              sx={{
                display: 'flex',
                width: 14,
                height: 14,
                mr: 2,
              }}
            >
              <StatusBadge status={ONLINE_STATUSES.online} />
            </Box>
            <Box>
              <Typography
                variant="bodySmallMedium"
                color="text.secondary"
              >
                {t('common.newRelease')}
              </Typography>
            </Box>
          </Box>
          <Box>
            <Box sx={{
              display: 'flex',
              alignItems: 'center',
            }}
            >
              <ChallengeAvatar
                challenge={latestChallenge}
                sx={{
                  width: 65,
                  height: 65,
                  mr: 6,
                }}
              />
              <Box>
                <Box>
                  <Typography
                    color="primary.contrastText"
                    variant="bodyMediumBold"
                    sx={{ mr: 3, mb: 1 }}
                  >
                    {latestChallenge?.name}
                  </Typography>
                  <Chip
                    color={latestChallenge?.difficulty || ''}
                    label={t(getDifficultyByName(latestChallenge?.difficulty)?.localizationKey) || ''}
                    size="small"
                  />
                </Box>
                <Typography
                  color="text.secondary"
                  variant="bodySmallRegular"
                >
                  {capitalizeFirstLetter(latestChallenge?.category?.name)}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box>
          <Link
            to={`${latestChallenge?.lab?.id}/${PATHS.challenges}/${latestChallenge?.id}`}
            sx={{
              mx: { xs: 0, sm: 2 },
            }}
          >
            <Typography variant="bodySmallBold" color="secondary.main">
              {t('challenges.view')}
            </Typography>
          </Link>
        </Box>
      </Box>
    </Card>
  );
};

export default LatestChallengeCard;
