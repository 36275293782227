import { useLocale } from 'util/hooks';

import {
  Box,
  Card,
} from '@mui/material';

import {
  LabsFilledIcon,
} from 'assets/icons';

import LabSectionWrapper from '../helpers/LabSectionWrapper';
import LabDetailsForm from '../../LabDetailsForm/LabDetailsForm';

const CreateLabContainer = () => {
  const { t } = useLocale();

  return (
    <Box sx={{ width: 1 }}>
      <Card sx={{
        px: { xs: 6, sm: 14 },
        py: 14,
      }}
      >
        <LabSectionWrapper
          Icon={LabsFilledIcon}
          title={t('labs.labDetails')}
          viewMode={false}
        >
          <LabDetailsForm createMode />
        </LabSectionWrapper>
      </Card>
    </Box>
  );
};

export default CreateLabContainer;
