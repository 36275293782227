import { Box, Typography } from '@mui/material';
import { useLocale } from 'util/hooks';

const LeaderBoardHeader = () => {
  const { t } = useLocale();

  return (
    <Box>
      <Typography
        variant="h5"
      >
        {t('pageTitles.leaderBoard')}
      </Typography>
      <Typography
        variant="bodyStandardRegular"
        color="text.secondary"
      >
        {t('leaderBoard.description')}
      </Typography>
    </Box>
  );
};

export default LeaderBoardHeader;
