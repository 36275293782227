import { useTheme } from '@mui/styles';

const ArrowCircleIcon = (props) => {
  const theme = useTheme();
  const {
    stroke = theme.palette.text.darkGray,
    fill = 'none',
    ...rest
  } = props;

  return (
    <svg width="22" height="22" viewBox="0 0 24 24" fill={fill} xmlns="http://www.w3.org/2000/svg" {...rest}>
      <path d="M0.75 12.004H16.5" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M12.75 15.754L16.5 12.004L12.75 8.254" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M3.30664 16.6C4.33869 18.7202 6.05435 20.4313 8.17729 21.4576C10.3002 22.484 12.7068 22.7659 15.0095 22.2579C17.3122 21.7499 19.3768 20.4816 20.8709 18.6574C22.365 16.8331 23.2015 14.559 23.2457 12.2014C23.2899 9.84376 22.5393 7.53989 21.1147 5.66085C19.6901 3.78181 17.6745 2.43699 15.3925 1.84301C13.1105 1.24903 10.695 1.44047 8.53505 2.38649C6.37511 3.3325 4.59648 4.97803 3.48564 7.05801" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default ArrowCircleIcon;
