import { useParams } from 'react-router-dom';

import { getLabDetails } from 'services';
import { useCustomQuery } from 'reactQuery';

const useLabDetails = (queryProps = {}) => {
  const { labId } = useParams();

  const query = useCustomQuery({
    queryKey: [`view-lab-${labId}`],
    queryFn: () => getLabDetails(labId),
    ...queryProps,
    options: {
      enabled: !!labId,
      ...queryProps?.options,
    },
  });

  return { ...query };
};

export default useLabDetails;
