import _ from 'lodash';
import PropTypes from 'prop-types';

import {
  Box,
  Grid,
  Typography,
} from '@mui/material';

import { useLocale } from 'util/hooks';
import { TypographyWithIcon } from 'components/molecules';
import { BellIcon } from 'assets/icons';

const PromoCodeDetailsWrapper = (props) => {
  const { codeDetails = {} } = props;

  const { t } = useLocale();

  const {
    code,
    discountPercentage,
    createdBy,
    startDate,
    endDate,
    duration,
    maxUses,
    isUnlimited,
    sendAnnouncement,
  } = codeDetails;

  const items = [
    {
      key: 'code',
      title: t('promoCodes.code'),
      component: code,
    },
    {
      key: 'discountPercentage',
      title: t('promoCodes.discountPercentage'),
      component: `${_.round(100 * discountPercentage, 2)}${t('common.percentage')}`,
    },
    {
      key: 'createdBy',
      title: t('common.createdBy'),
      component: createdBy,
    },
    {
      key: 'startDate',
      title: t('common.startDate'),
      component: new Date(startDate)
        .toLocaleString(
          'en-US',
          {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
          },
        ),
    },
    {
      key: 'endDate',
      title: t('common.endDate'),
      component: new Date(endDate)
        .toLocaleString(
          'en-US',
          {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
          },
        ),
    },
    {
      key: 'duration',
      title: t('promoCodes.duration'),
      component: `${duration} ${t('common.days')}`,
    },
    {
      key: 'maxUses',
      title: t('promoCodes.maxUses'),
      component: isUnlimited ? t('promoCodes.unlimited') : `${maxUses} ${t('promoCodes.uses')}`,
    },
    {
      key: 'sendAnnouncement',
      title: t('promoCodes.announcement'),
      component: <TypographyWithIcon
        label={sendAnnouncement ? t('common.yes') : t('common.no')}
        icon={<BellIcon />}
        labelProps={{
          variant: 'bodyMediumMedium',
        }}
      />,
    },
  ];

  return (
    <Box>
      <Grid container rowSpacing={15} columnSpacing={5}>
        {items?.map(({
          key,
          title,
          component,
          containerProps,
          componentProps,
        }) => (
          <Grid key={key} item sm={12} md={6} lg={4} {...containerProps}>
            <Typography
              variant="bodyStandardBold"
              color="text.secondary"
            >
              {title}
            </Typography>
            <Box>
              {typeof component === 'string' || typeof component === 'number'
                ? (
                  <Typography
                    variant="bodyMediumMedium"
                    color="text.lighterGray"
                    {...componentProps}
                  >
                    {component}
                  </Typography>
                )
                : component}
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

PromoCodeDetailsWrapper.propTypes = {
  codeDetails: PropTypes.oneOfType([PropTypes.object]),
};

PromoCodeDetailsWrapper.defaultProps = {
  codeDetails: {},
};

export default PromoCodeDetailsWrapper;
