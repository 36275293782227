import { useTheme } from '@mui/styles';

const SparklingFilledIcon = (props) => {
  const theme = useTheme();
  const {
    stroke = theme.palette.colors.yellow,
    fill = theme.palette.colors.yellow,
    ...rest
  } = props;

  return (
    <svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <rect width="45" height="45" rx="7.125" fill={fill} fillOpacity="0.25" />
      <path d="M18.0735 25C18.0663 26.8848 18.9817 27.96 21.0611 28C19.1307 27.9928 18.1715 29.0328 18.0735 31C18.0568 29.144 17.229 28 15.0859 28C17.0036 27.9736 18.0663 27.044 18.0735 25Z" stroke={stroke} strokeWidth="1.53751" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M16.9876 13C16.9804 14.8848 17.8958 15.96 19.9751 16C18.0448 15.9928 17.0856 17.0328 16.9876 19C16.9708 17.144 16.1431 16 14 16C15.9176 15.9736 16.9804 15.044 16.9876 13Z" stroke={stroke} strokeWidth="1.53751" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M26.2628 16.2734C26.2487 19.8931 28.0011 21.9462 32.0011 22.0367C28.2911 22.0226 26.4508 26.6764 26.2628 30.4553C26.227 26.8902 24.6398 22.0313 20.5234 22.0367C24.2063 21.9844 26.2487 20.1996 26.2628 16.2734Z" stroke={stroke} strokeWidth="2.0966" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default SparklingFilledIcon;
