import END_POINTS from 'services/endPoints';
import api from '../api';

const getEventChallengeSubmissions = (
  eventId,
  challengeId,
  options,
) => api(END_POINTS.eventChallengeSubmissions(
  eventId,
  challengeId,
), options)
  .then((res) => Promise.resolve(res))
  .catch((error) => Promise.reject(error));

export default getEventChallengeSubmissions;
