import { useState } from 'react';
import {
  Card, Typography, Button, CircularProgress, Alert, Link, Box,
} from '@mui/material';
import * as Yup from 'yup';
import { useLocale, useFastForm, useRecaptcha } from 'util/hooks';
import { forgotPasswordService } from 'services';
import { PATHS } from 'routes';
import { FormTextField } from 'components/form';

const ForgotPasswordForm = (props) => {
  const {
    onSubmitSuccess = () => { },
  } = props;

  const [forgotError, setForgotError] = useState(false);
  const { executeRecaptcha } = useRecaptcha({ action: 'FORGOT_PASSWORD' });
  const { t } = useLocale();

  const onSubmit = async ({ email }) => {
    setForgotError(false);
    try {
      const recaptchaToken = await executeRecaptcha();
      await forgotPasswordService({ email }, { recaptchaToken });
      onSubmitSuccess(email);
    } catch (error) {
      setForgotError(error);
    }
  };

  const defaultValues = {
    email: '',
  };

  const validationSchema = Yup.object({
    email: Yup.string().email(t('email.validation'))
      .required(t('email.enterEmail')),
  });

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useFastForm({
    defaultValues,
    validationSchema,
  });

  const forgotPasswordForm = (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        py: 10,
        px: {
          sm: 25,
          xs: 5,
        },
      }}
      >
        {forgotError && (
          <Alert
            sx={{ mb: 5 }}
            severity="error"
          >
            {t('login.wrongCredentials')}
          </Alert>
        )}
        <Box>
          <Typography variant="h5">{t('password.forgotPassword')}</Typography>
          <Typography variant="bodyStandardRegular" sx={{ mt: 1 }}>{t('password.forgotPasswordDescription')}</Typography>
        </Box>
        <Box sx={{ mt: 6, mb: 9 }}>
          <FormTextField
            name="email"
            control={control}
            autoComplete="email"
            label={t('common.email')}
            fullWidth
            autoFocus
          />
        </Box>
        <Box>
          <Button
            variant="contained"
            size="large"
            type="submit"
            fullWidth
            disabled={isSubmitting}
          >
            {isSubmitting ? <CircularProgress size={20} /> : t('common.sendRequest')}
          </Button>
        </Box>
        <Box sx={{ textAlign: 'center', mt: 4 }}>
          <Link to={`/${PATHS.login}`}>
            {t('common.backToLogin')}
          </Link>
        </Box>
      </Box>
    </form>
  );

  return (
    <Card>
      {forgotPasswordForm}
    </Card>
  );
};

export default ForgotPasswordForm;
