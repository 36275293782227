import { forwardRef } from 'react';
import {
  Autocomplete,
  TextField,
} from '@mui/material';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ClearIcon from '@mui/icons-material/Clear';

import { useLocale } from 'util/hooks';

const AutocompleteSelect = forwardRef((props, ref) => {
  const { isAr } = useLocale();

  const {
    name,
    error,
    autoCompleteProps = {
      options: [],
      fullWidth: true,
    },
    textFieldProps = {},
    optionKey = isAr
      ? 'nameAr'
      : 'nameEn', // default
    ...rest
  } = props;

  const getOptionLabel = (option) => {
    if (!option) return '';

    return option[optionKey];
  };

  const isOptionEqualToValue = (option, value) => {
    if (value === '') return true; // Prevent onClear warning

    if (typeof value === 'object') {
      return option.id === value.id;
    }

    const optionString = option[optionKey];
    return optionString === value;
  };

  return (
    <Autocomplete
      ref={ref}
      id={name}
      popupIcon={<ExpandMore sx={{ color: 'text.secondary' }} />}
      clearIcon={<ClearIcon sx={{ color: 'text.secondary' }} />}
      getOptionLabel={getOptionLabel}
      isOptionEqualToValue={isOptionEqualToValue}
      renderInput={(params) => (
        <TextField
          {...params}
          {...textFieldProps}
          error={error}
        />
      )}
      {...autoCompleteProps}
      {...rest}
    />
  );
});

export default AutocompleteSelect;
