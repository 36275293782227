import { useMemo } from 'react';
import _ from 'lodash';
import { Box } from '@mui/material';

import { LabelWithBadge, Tabs } from 'components/molecules';
import { useLocale, usePermission } from 'util/hooks';

import { STATUS } from 'assets/constants/statuses';
import PERMISSIONS from 'assets/constants/permissions';
import PlayerMachineList from '../../MachinesList/PlayerMachineList';
import MachinesList from '../../MachinesList/MachinesList';

const Machines = (props) => {
  const { machines = [] } = props;

  const { t } = useLocale();

  const groupedMachines = useMemo(() => _.groupBy(
    machines,
    (machine) => machine.status,
  ), [machines]);

  const viewAsPlayer = usePermission(PERMISSIONS.machineViewAllAsPlayer);

  const showPublishedTab = true;
  const showRetiredTab = true;
  const showDraftTab = usePermission(PERMISSIONS.machineCreate);
  const showArchivedTab = usePermission(PERMISSIONS.machineArchive);
  const publishLabel = usePermission(PERMISSIONS.machinePublish) ? STATUS.active : STATUS.published;

  const tabStatuses = [
    {
      key: STATUS.published,
      label: publishLabel,
      show: showPublishedTab,
    },
    {
      key: STATUS.draft,
      label: STATUS.draft,
      show: showDraftTab,
    },
    {
      key: STATUS.retired,
      label: STATUS.retired,
      show: showRetiredTab,
    },
    {
      key: STATUS.archived,
      label: STATUS.archived,
      show: showArchivedTab,
    },
  ];

  const tabs = tabStatuses
    .filter((tabStatus) => tabStatus.show)
    .map(({ key, label }) => ({
      key,
      label: <LabelWithBadge
        label={t(`statuses.${label}`)}
        badgeContent={groupedMachines[key]?.length || 0}
      />,
      component: viewAsPlayer
        ? (
          <PlayerMachineList
            machines={groupedMachines[key]}
          />
        )
        : (
          <MachinesList
            machines={groupedMachines[key]}
            status={key}
          />
        ),
    }));

  return (
    <Box>
      <Box width={1}>
        <Tabs tabs={tabs} sx={{ maxWidth: { xs: 300, sm: 1 } }} />
      </Box>
    </Box>
  );
};

export default Machines;
