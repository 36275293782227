import { Card } from '@mui/material';
import { CalendarFilledIcon } from 'assets/icons';

import { useLocale } from 'util/hooks';
import EventDetailsFrom from './EventDetailsForm';
import EventSectionWrapper from './helpers/EventSectionWrapper';

const CreateEventCard = () => {
  const { t } = useLocale();

  return (
    <Card sx={{ py: 8, px: { xs: 6, sm: 14 } }}>
      <EventSectionWrapper
        Icon={CalendarFilledIcon}
        title={t('events.eventDetails')}
      >
        <EventDetailsFrom createMode />
      </EventSectionWrapper>
    </Card>
  );
};

export default CreateEventCard;
