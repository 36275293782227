import { useLocation } from 'react-router-dom';
import { Box, Button, Typography } from '@mui/material';

import { useAuth, useLocale } from 'util/hooks';
import { noMatchRobot, forbiddenLock } from 'assets/images';
import ERRORS_TYPES from 'assets/constants/errorsTypes';
import { CenterContainer } from 'components/layout';

const defaultOnReset = () => {
  window.location.replace('/');
};

const redirectToLogin = (location = {}) => {
  const search = `?redirectUrl=${location.pathname}`;
  const to = `/login${search}`;
  window.location.replace(to);
};

const ErrorContainer = (props) => {
  const { forbidden, crash, notFound } = ERRORS_TYPES;
  const {
    type = crash,
    resetErrorBoundary = defaultOnReset,
  } = props;

  const { t } = useLocale();
  const { isLoggedIn } = useAuth();
  const { href } = window.location;
  const { state: locationState, ...location } = useLocation();

  const isNotFound = type === notFound;
  if (isNotFound && !isLoggedIn) {
    return redirectToLogin(location);
  }

  const imageWidth = {
    xs: 273,
    lg: 410,
    xl: 547,
  };
  const imageHeight = {
    xs: 253,
    lg: 380,
    xl: 507,
  };

  const content = {
    403: {
      subTitle: forbidden,
      desc: (
        <Box>
          <Box>
            <Typography
              variant="bodyStandardRegular"
              color="text.secondary"
            >
              {`${t('common.forbiddenPage')}:`}
            </Typography>
          </Box>
          <Box maxWidth={imageWidth}>
            <Typography
              variant="bodyStandardMedium"
              color="text.secondary"
              sx={{
                overflowWrap: 'break-word',
                wordWrap: 'break-word',
              }}
            >
              {locationState?.from || href}
            </Typography>
          </Box>
        </Box>
      ),
      handleClick: resetErrorBoundary,
      image: forbiddenLock,
    },
    404: {
      subTitle: notFound,
      desc: (
        <Box>
          <Box>
            <Typography
              variant="bodyStandardRegular"
              color="text.secondary"
            >
              {`${t('common.couldNotFindPage')}:`}
            </Typography>
          </Box>
          <Box maxWidth={imageWidth}>
            <Typography
              variant="bodyStandardMedium"
              color="text.secondary"
              sx={{
                overflowWrap: 'break-word',
                wordWrap: 'break-word',
              }}
            >
              {locationState?.from || href}
            </Typography>
          </Box>
        </Box>
      ),
      handleClick: resetErrorBoundary,
      image: noMatchRobot,
    },
    400: {
      subTitle: t('common.errorOccurred'),
      desc: (
        <Box maxWidth={imageWidth}>
          <Typography
            variant="bodyStandardRegular"
            color="text.secondary"
            sx={{
              overflowWrap: 'break-word',
              wordWrap: 'break-word',
            }}
          >
            {t('common.errorSorry')}
          </Typography>
        </Box>
      ),
      handleClick: resetErrorBoundary,
      image: noMatchRobot,
    },
  };

  return (
    <CenterContainer>
      <Box sx={{
        width: 1,
        display: 'flex',
        flexDirection: {
          xs: 'column-reverse',
          lg: 'row',
        },
        justifyContent: {
          xs: 'center',
          lg: 'space-between',
        },
        alignItems: 'center',
        rowGap: 10,
        px: {
          xs: 2,
          md: '10%',
          xl: '20%',
        },
      }}
      >
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          rowGap: 5,
        }}
        >
          <Box>
            <Typography
              variant="h3"
              color="text.primary"
            >
              {t('common.whoops')}
            </Typography>
            <Typography
              variant="h1"
              color="text.primary"
            >
              {content[type].subTitle}
            </Typography>
          </Box>
          <Box>
            {content[type].desc}
          </Box>
          <Box>
            <Button
              variant="contained"
              onClick={content[type].handleClick}
            >
              {t('common.backToHome')}
            </Button>
          </Box>
        </Box>
        <Box sx={{
          width: imageWidth,
          height: imageHeight,
        }}
        >
          <img
            src={content[type].image}
            alt="error-icon"
            width="100%"
            height="100%"
          />
        </Box>
      </Box>
    </CenterContainer>
  );
};

export default ErrorContainer;
