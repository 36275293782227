import { useTheme } from '@mui/styles';

const FileStarFilledIcon = (props) => {
  const theme = useTheme();
  const {
    stroke = theme.palette.text.secondary,
    fill = theme.palette.paper.hover,
    ...rest
  } = props;

  return (
    <svg width="48" height="44" viewBox="0 0 48 44" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <rect width="48" height="44" rx="6" fill={fill} />
      <g clipPath="url(#clip0_2887_6212)">
        <path d="M29.4518 21.7069L30.8643 24.7502H33.6143C33.753 24.7448 33.89 24.7823 34.0066 24.8576C34.1231 24.933 34.2135 25.0425 34.2655 25.1712C34.3175 25.2999 34.3284 25.4415 34.2969 25.5766C34.2653 25.7118 34.1928 25.8339 34.0892 25.9263L31.7058 28.2757L33.0277 31.3126C33.0854 31.4517 33.0981 31.6054 33.064 31.7521C33.0299 31.8987 32.9507 32.031 32.8375 32.1304C32.7243 32.2297 32.5828 32.2911 32.4329 32.3059C32.2831 32.3206 32.1323 32.2881 32.0019 32.2128L28.8037 30.4179L25.6073 32.2155C25.4768 32.2908 25.3261 32.3234 25.1762 32.3086C25.0264 32.2938 24.8849 32.2325 24.7717 32.1331C24.6585 32.0338 24.5793 31.9015 24.5452 31.7548C24.5111 31.6081 24.5238 31.4545 24.5815 31.3154L25.9043 28.2784L23.5209 25.929C23.4162 25.8375 23.3425 25.7157 23.3098 25.5805C23.2772 25.4453 23.2872 25.3033 23.3386 25.1741C23.3899 25.0448 23.4801 24.9347 23.5966 24.8588C23.7132 24.7829 23.8504 24.7449 23.9893 24.7502H26.7393L28.1547 21.7041C28.2172 21.5862 28.3107 21.4876 28.4252 21.419C28.5396 21.3503 28.6706 21.3142 28.804 21.3145C28.9375 21.3147 29.0683 21.3514 29.1825 21.4206C29.2966 21.4897 29.3897 21.5887 29.4518 21.7069V21.7069Z" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M16.4375 17.1875H26.0625" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M16.4375 21.3125H23.3125" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M16.4375 25.4375H19.875" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M19.875 29.5625H15.0625C14.6978 29.5625 14.3481 29.4176 14.0902 29.1598C13.8324 28.9019 13.6875 28.5522 13.6875 28.1875V13.0625C13.6875 12.6978 13.8324 12.3481 14.0902 12.0902C14.3481 11.8324 14.6978 11.6875 15.0625 11.6875H24.8058C25.1702 11.6876 25.5197 11.8323 25.7774 12.0899L28.4101 14.7226C28.6677 14.9803 28.8124 15.3298 28.8125 15.6943V17.875" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      </g>
      <defs>
        <clipPath id="clip0_2887_6212">
          <rect width="22" height="22" fill="white" transform="translate(13 11)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default FileStarFilledIcon;
