import { useLocale } from 'util/hooks';

import {
  Box,
  Card,
} from '@mui/material';

import {
  PromoCodeFilledIcon,
} from 'assets/icons';
import { PromoCodeDetailsForm } from 'components/organisms';
import PromoCodeSectionWrapper from './helpers/PromoCodeSectionWrapper';

const CreatePromoCodeContainer = () => {
  const { t } = useLocale();

  return (
    <Box sx={{ width: 1 }}>
      <Card sx={{
        px: { xs: 6, sm: 14 },
        py: 14,
      }}
      >
        <PromoCodeSectionWrapper
          Icon={PromoCodeFilledIcon}
          title={t('promoCodes.newPromoCode')}
          viewMode={false}
        >
          <PromoCodeDetailsForm createMode />
        </PromoCodeSectionWrapper>
      </Card>
    </Box>
  );
};

export default CreatePromoCodeContainer;
