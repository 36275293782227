import PropTypes from 'prop-types';
import { Box } from '@mui/material';

const ColoredCircle = (props) => {
  const { sx, value, ...rest } = props;

  return (
    <Box
      sx={{
        width: 28,
        height: 28,
        borderRadius: 50,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        ...sx,
      }}
      {...rest}
    >
      {value}
    </Box>
  );
};

ColoredCircle.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
  sx: PropTypes.oneOfType([PropTypes.object]),
};

ColoredCircle.defaultProps = {
  value: '',
  sx: {},
};
export default ColoredCircle;
