import { useTheme } from '@mui/styles';

const LockIcon = (props) => {
  const theme = useTheme();
  const {
    stroke = theme.palette.text.secondary,
    fill = 'none',
    ...rest
  } = props;

  return (
    <svg width="57" height="56" viewBox="0 0 57 56" fill={fill} xmlns="http://www.w3.org/2000/svg" stroke={stroke} {...rest}>
      <path d="M44.25 22.75H12.75C10.817 22.75 9.25 24.317 9.25 26.25V50.75C9.25 52.683 10.817 54.25 12.75 54.25H44.25C46.183 54.25 47.75 52.683 47.75 50.75V26.25C47.75 24.317 46.183 22.75 44.25 22.75Z" strokeWidth="3.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M16.25 22.75V14C16.25 10.7511 17.5406 7.63526 19.8379 5.33794C22.1353 3.04062 25.2511 1.75 28.5 1.75C31.7489 1.75 34.8647 3.04062 37.1621 5.33794C39.4594 7.63526 40.75 10.7511 40.75 14V22.75" strokeWidth="3.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M28.5 35V42" strokeWidth="3.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default LockIcon;
