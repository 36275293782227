import { useTheme } from '@mui/styles';

const BloodDropFilledIcon = (props) => {
  const theme = useTheme();
  const {
    stroke = theme.palette.text.red,
    fill = theme.palette.text.red,
    ...rest
  } = props;

  return (
    <svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <rect width="45" height="45" rx="7.125" fill={fill} fillOpacity="0.2" />
      <path d="M28.958 26.8002C28.958 28.4412 28.3061 30.015 27.1457 31.1754C25.9853 32.3358 24.4115 32.9876 22.7705 32.9876C21.1295 32.9876 19.5557 32.3358 18.3953 31.1754C17.2349 30.015 16.583 28.4412 16.583 26.8002C16.583 22.336 20.6603 15.6058 22.1948 13.2335C22.2566 13.1368 22.3417 13.0572 22.4423 13.002C22.5429 12.9469 22.6558 12.918 22.7705 12.918C22.8852 12.918 22.9981 12.9469 23.0987 13.002C23.1994 13.0572 23.2844 13.1368 23.3462 13.2335C24.8807 15.6058 28.958 22.336 28.958 26.8002Z" stroke={stroke} strokeWidth="1.375" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M22.7705 30.241C22.3189 30.241 21.8716 30.152 21.4544 29.9791C21.0372 29.8062 20.6581 29.5527 20.3389 29.2332C20.0196 28.9137 19.7665 28.5345 19.5939 28.1171C19.4213 27.6997 19.3326 27.2524 19.333 26.8008" stroke={stroke} strokeWidth="1.375" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default BloodDropFilledIcon;
