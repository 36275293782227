const basePalette = {
  primary: {
    main: '#F0004F',
    contrastText: '#FFFFFF',
  },
  secondary: {
    main: '#D7E5FF',
    contrastText: '#263993',
  },
  background: {
    default: '#0B0D0F',
    paper: '#131619',
    backdrop: 'rgba(11, 13, 15, 0.5)',
    dark: '#A60037',
  },
  transparency: {
    main: 'rgba(186, 187, 188, 0.1)',
    contrastText: '#FFFFFF',
  },
  colors: {
    lightGreen: 'rgba(82, 227, 133, 0.3)',
    mintGreen: '#A5FAC4',
    flamingoPink: 'rgba(222, 46, 83, 0.3)',
    lightFlamingoPink: '#F58EA4',
    fogGray: 'rgba(196, 196, 196, 0.3)',
    metalGray: '#C4C4C4',
    yellow: '#FFC56E',
    eggplantPurple: '#423D60',
    metallicGold: '#D4AF37',
    silver: '#C0C0C0',
    bronze: '#CD7F32',
    lightTangelo: '#FFA877',
    VeryLightTangelo: 'rgba(255, 168, 119, 0.3)',
    charcoalGray: 'rgba(70, 73, 77, 0.3)',
  },
  text: {
    primary: '#F9F9F9',
    secondary: '#BABBBC',
    darkGray: '#46494D',
    bluishGray: '#A7A6AD',
    mediumGray: '#A2AAB3',
    lightGray: '#CAC9D4',
    lighterGray: '#F6F5FC',
    purple: '#714AE0',
    lightPurple: 'rgba(133, 134, 215, 1)',
    raspberry: '#FF2A70',
    lightRaspberry: '#FC3561',
    lighterRaspberry: '#FC4E74',
    darkRaspberry: '#F0004F',
    lightGreen: 'rgba(174, 252, 202, 0.25)',
    transparentWhite: '#D6D6D666',
    transparentRed: '#F0004F33',
    red: '#FC3535',
    blue: '#4060F8',
    transparentBlue: 'rgba(64, 96, 248, 0.2)',
    lightPink: '#FFD4E2',
    orange: '#FF832A',
    green: '#00D356',
  },
  divider: 'rgba(255,255,255,0.12)',
  action: {
    disabled: 'rgba(255, 255, 255, 0.5)',
    disabledBackground: 'rgba(240, 0, 79, 0.5)',
  },
};

const paperPalette = {
  paper: {
    background: basePalette.background.paper,
    selected: '#2F3237',
    hover: basePalette.transparency.main,
  },
};

const severityPalette = {
  success: {
    main: '#AEFCCA',
    background: '#1A3E20',
  },
  error: {
    main: '#DE6C84',
    background: '#3E141D',
  },
  info: {
    main: basePalette.text.blue,
    background: 'rgba(64, 96, 248, 0.1)',
  },
};

const statusPalette = {
  active: {
    main: basePalette.colors.lightGreen,
    contrastText: basePalette.colors.mintGreen,
  },
  inactive: {
    main: basePalette.colors.flamingoPink,
    contrastText: severityPalette.error.main,
  },
  published: {
    main: basePalette.colors.lightGreen,
    contrastText: basePalette.colors.mintGreen,
  },
  pending: {
    main: basePalette.colors.VeryLightTangelo,
    contrastText: basePalette.colors.lightTangelo,
  },
  suspended: {
    main: basePalette.colors.flamingoPink,
    contrastText: basePalette.colors.lightFlamingoPink,
  },
  expired: {
    main: basePalette.colors.charcoalGray,
    contrastText: basePalette.text.secondary,
  },
  draft: {
    main: basePalette.colors.fogGray,
    contrastText: basePalette.colors.metalGray,
  },
  archived: {
    main: 'rgba(41, 36, 71, 1)',
    contrastText: '#A8A3C7',
  },
  retired: {
    main: 'rgba(90, 233, 253, 0.25)',
    contrastText: '#72D7EE',
  },
  successful: {
    main: basePalette.colors.lightGreen,
    contrastText: basePalette.colors.mintGreen,
  },
  failed: {
    main: basePalette.colors.flamingoPink,
    contrastText: severityPalette.error.main,
  },
  banned: {
    main: basePalette.colors.flamingoPink,
    contrastText: basePalette.colors.lightFlamingoPink,
  },
  live: {
    main: basePalette.colors.lightGreen,
    contrastText: basePalette.colors.mintGreen,
  },
  comingUp: {
    main: basePalette.colors.VeryLightTangelo,
    contrastText: basePalette.colors.lightTangelo,
  },
  ended: {
    main: basePalette.colors.charcoalGray,
    contrastText: basePalette.text.secondary,
  },
  '': { // Prevent crash on undefined statuses
    main: basePalette.colors.fogGray,
    contrastText: basePalette.colors.metalGray,
  },
};

const difficultyPalette = {
  easy: {
    main: 'rgba(110, 255, 246, 0.25)',
    contrastText: '#6EFFF6',
  },
  medium: {
    main: 'rgba(198, 248, 134, 0.25)',
    contrastText: '#C6F886',
  },
  hard: {
    main: 'rgba(253, 212, 109, 0.25)',
    contrastText: '#FDD46D',
  },
  insane: {
    main: 'rgba(255, 115, 110, 0.25)',
    contrastText: '#FF736E',
  },
};

const topThreePlayersBadges = {
  1: {
    color: basePalette.colors.metallicGold,
    background: '#D4AF3740',
  },
  2: {
    color: basePalette.colors.silver,
    background: '#C0C0C040',
  },
  3: {
    color: basePalette.colors.bronze,
    background: '#CD7F3240',
  },
};

const appBarPalette = {
  appBar: {
    backgroundColor: basePalette.background.paper,
    color: basePalette.primary.contrastText,
  },
};

const drawerPalette = {
  drawer: {
    paper: {
      backgroundColor: paperPalette.background,
      color: basePalette.text.secondary,
    },
  },
};

const palette = {
  ...basePalette,
  ...paperPalette,
  ...severityPalette,
  ...statusPalette,
  ...difficultyPalette,
  ...appBarPalette,
  ...drawerPalette,
  topThreePlayersBadges,
};

export default palette;
