import PropTypes from 'prop-types';
import { useLocale } from 'util/hooks';
import {
  Box,
  Card,
  Divider,
  Grid,
} from '@mui/material';

import {
  PlansFilledIcon,
  FileStarFilledIcon,
  CardMoneyIcon,
  CheckCircleOutline,
} from 'assets/icons';
import { chunkArray } from 'util/helpers';
import { TypographyWithIcon } from 'components/molecules';
import PlanSectionWrapper from '../helpers/PlanSectionWrapper';
import PlanName from '../helpers/PlanName';
import PlanPrice from '../helpers/PlanPrice';
import PlanActions from '../helpers/PlanActions';

const PlanInfo = (props) => {
  const {
    planDetails,
    fetchPlanDetails,
  } = props;

  const { t, isAr } = useLocale();

  // Render features in chunks of 4, like newspapers 📰
  const chunkedFeatures = chunkArray(planDetails.features, 4).map((featArr, index) => {
    const key = `feature-array-chuck-${index}`;

    return (
      <Grid item key={key}>
        <Grid container direction="column">
          {featArr.map((feat) => (
            <Grid item key={feat.id}>
              <TypographyWithIcon
                label={isAr ? feat.nameAr : feat.nameEn}
                icon={(
                  <CheckCircleOutline />
                )}
              />
            </Grid>
          ))}
        </Grid>
      </Grid>
    );
  });

  return (
    <Box sx={{ width: 1 }}>
      <Card sx={{ p: 14 }}>
        <PlanSectionWrapper
          Icon={PlansFilledIcon}
          title={t('plans.planName')}
          sx={{ mb: 12 }}
        >
          <PlanName planDetails={planDetails} />
        </PlanSectionWrapper>
        <Divider />
        <PlanSectionWrapper
          Icon={FileStarFilledIcon}
          title={t('plans.planFeatures')}
          sx={{ my: 12 }}
        >
          <Grid container direction="row" spacing={6}>
            {chunkedFeatures}
          </Grid>
        </PlanSectionWrapper>
        <Divider />
        <PlanSectionWrapper
          Icon={CardMoneyIcon}
          title={t('plans.planPrice')}
          sx={{ my: 12 }}
        >
          <PlanPrice planDetails={planDetails} />
        </PlanSectionWrapper>
        <Box>
          <PlanActions
            planDetails={planDetails}
            fetchPlanDetails={fetchPlanDetails}
          />
        </Box>
      </Card>
    </Box>
  );
};

PlanInfo.propTypes = {
  planDetails: PropTypes.oneOfType([PropTypes.object]),
  fetchPlanDetails: PropTypes.func,
};

PlanInfo.defaultProps = {
  planDetails: {},
  fetchPlanDetails: () => { },
};

export default PlanInfo;
