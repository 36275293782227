import {
  mainLayout,
  publicLayout,
  privateLayout,
  landing,
  login,
  register,
  verifyEmail,
  accountConnection,
  resetPassword,
  forgotPassword,
  acceptInvitation,
  dashboard,
  playerHome,
  settings,
  addPaymentCard,
  profile,
  publicProfile,
  users,
  plans,
  createPlan,
  viewPlan,
  editPlan,
  labs,
  playerLabs,
  createLab,
  promoCode,
  createPromoCode,
  viewPromoCode,
  subscriptions,
  checkout,
  leaderBoard,
  noMatch,
  announcements,
  createAnnouncement,
  editAnnouncement,
  viewAnnouncement,
  machines,
  manageMachines,
  createMachine,
  viewMachine,
  editMachine,
  playerViewMachine,
  events,
  playerEvents,
  createEvent,
  publicViewEvent,
  viewEventVisualization,
  instances,
  createCompany,
  companyUsers,
  companyOwnMembers,
  companyOperatorEvents,
  hitbctf,
  hitbVisitors,
} from './routes';

const forms = {
  children: [
    hitbctf,
    hitbVisitors,
  ],
};

const publicRouteConfigs = {
  ...publicLayout,
  children: [
    landing,
    login,
    register,
    verifyEmail,
    resetPassword,
    forgotPassword,
    acceptInvitation,
    publicProfile,
    publicViewEvent,
  ],
};

const privateRouteConfigs = {
  ...privateLayout,
  children: [
    verifyEmail,
    dashboard,
    playerHome,
    settings,
    addPaymentCard,
    profile,
    users,
    plans,
    createPlan,
    viewPlan,
    editPlan,
    labs,
    playerLabs,
    createLab,
    events,
    playerEvents,
    createEvent,
    viewEventVisualization,
    promoCode,
    createPromoCode,
    viewPromoCode,
    subscriptions,
    checkout,
    leaderBoard,
    announcements,
    instances,
    createAnnouncement,
    editAnnouncement,
    viewAnnouncement,
    machines,
    manageMachines,
    createMachine,
    viewMachine,
    editMachine,
    playerViewMachine,
    createCompany,
    companyUsers,
    companyOwnMembers,
    companyOperatorEvents,
    noMatch,
  ],
};

// Route configs
const routeConfigs = [
  {
    ...mainLayout,
    children: [
      forms,
      publicRouteConfigs,
      privateRouteConfigs,
      noMatch,
      accountConnection,
    ],
  },
];

export default routeConfigs;
