import { Stack } from '@mui/material';

import { useSocket } from 'util/hooks';
import { useEventLeaderboard } from 'reactQuery/queries';
import { EventLeaderBoardTable } from 'components/tables';
import SOCKET_EVENTS from 'assets/constants/socketEvents';

import { getItem, setItem } from 'util/localStorage';
import { useEffect } from 'react';
import Top3 from '../leaderBoard/Top3';

const GAP = 10;

const VisualizationLeaderboard = (props) => {
  const { handleNewLead } = props;

  const {
    data: { items = [] } = {},
    isFetching = true,
    refetch: refetchLeaderboard,
  } = useEventLeaderboard({
    queryKeyOptions: {
      pagination: {
        limit: 10,
      },
    },
  });

  const socketEvents = [
    {
      eventName: SOCKET_EVENTS.events.leaderboardUpdated,
      handler: () => {
        refetchLeaderboard();
      },
    },
  ];

  useSocket({ events: socketEvents });

  const currentFirstPlace = items?.find((team) => team.rank === 1);
  useEffect(() => {
    const previousFirstPlace = getItem('previousFirstPlace')
      === 'undefined' ? undefined : JSON.parse(getItem('previousFirstPlace'));

    setItem('previousFirstPlace', currentFirstPlace);
    if (previousFirstPlace?.id !== currentFirstPlace?.id && currentFirstPlace?.rank === 1) {
      handleNewLead(currentFirstPlace);
    }
  }, [currentFirstPlace, handleNewLead]);

  const leaderBoard = [...items]; // used for splice
  const topThreeRange = leaderBoard?.slice(0, 3).filter((team) => team.rank !== null);

  const top3 = leaderBoard?.splice(0, topThreeRange.length) || [];

  return (
    <Stack gap={GAP}>
      <Top3 top3={top3} />
      <EventLeaderBoardTable
        leaderBoard={leaderBoard}
        isFetching={isFetching}
      />
    </Stack>
  );
};

export default VisualizationLeaderboard;
