import { useLocale } from 'util/hooks';
import { useTheme } from '@mui/styles';

const useRadarOptions = (props) => {
  const { overrides = {} } = props || {};

  const { isRtl } = useLocale();
  const { palette, fontFamily } = useTheme();

  return ({
    plugins: {
      legend: {
        display: true,
        rtl: isRtl,
        position: 'bottom',
        labels: {
          padding: 55,
          color: palette.text.lightGray,
          usePointStyle: true,
          pointStyle: 'rectRounded',
          font: {
            family: fontFamily,
            size: 12,
          },
        },
      },
      tooltip: {
        usePointStyle: true,
        filter(tooltipItem) {
          return tooltipItem.raw !== '0';
        },
        callbacks: {
          labelPointStyle() {
            return {
              pointStyle: 'dash',
              rotation: 0,
            };
          },
        },
        backgroundColor: palette.paper.background,
        rtl: isRtl,
      },
    },
    scales: {
      r: {
        pointLabels: {
          color: palette.text.lightGray,
          font: {
            family: fontFamily,
            size: 12,
          },
        },
        min: 0,
        angleLines: {
          color: palette.text.darkGray,
        },
        grid: {
          color: palette.text.darkGray,
        },
        ticks: {
          display: false,
        },
        legend: { position: 'bottom' },
      },
    },
    ...overrides,
  });
};

export default useRadarOptions;
