import { useTheme } from '@mui/styles';

const FlagWithHashIcon = (props) => {
  const { palette } = useTheme();
  const {
    stroke = palette.text.blue,
    fill = 'none',
    ...rest
  } = props;

  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill={fill} xmlns="http://www.w3.org/2000/svg" {...rest}>
      <g clipPath="url(#clip0_9852_140833)">
        <path d="M0.5 15.5V0.5" stroke={stroke} strokeWidth="0.909091" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M0.5 11.8059L2.60267 11.1593C3.47368 10.8911 4.39187 10.8119 5.29594 10.9268C6.20002 11.0418 7.06916 11.3483 7.84533 11.8259C8.60278 12.2928 9.44912 12.5968 10.3305 12.7187C11.2119 12.8406 12.1089 12.7777 12.9647 12.5339L14.956 11.9646C15.1128 11.9198 15.2506 11.8252 15.3488 11.695C15.447 11.5649 15.5 11.4063 15.5 11.2433V3.42526C15.4999 3.30921 15.4729 3.19476 15.4211 3.09091C15.3693 2.98706 15.2941 2.89663 15.2015 2.82674C15.1088 2.75684 15.0012 2.70937 14.8872 2.68806C14.7731 2.66675 14.6556 2.67218 14.544 2.70393M7.84533 2.45126C7.06916 1.97364 6.20002 1.66712 5.29594 1.55216C4.39187 1.43719 3.47368 1.51643 2.60267 1.78459L0.5 2.43059" stroke={stroke} strokeWidth="0.909091" strokeLinecap="round" strokeLinejoin="round" />
        <g clipPath="url(#clip1_9852_140833)">
          <path d="M8.54736 3.70801H13.5715" stroke={stroke} strokeWidth="0.570683" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M7.77441 6.0293H12.7986" stroke={stroke} strokeWidth="0.570683" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M10.6736 1.77832L8.74121 7.57545" stroke={stroke} strokeWidth="0.570683" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M12.6038 1.77832L10.6714 7.57545" stroke={stroke} strokeWidth="0.570683" strokeLinecap="round" strokeLinejoin="round" />
        </g>
      </g>
    </svg>
  );
};

export default FlagWithHashIcon;
