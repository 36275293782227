import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { PlansFilledIcon } from 'assets/icons';
import { CopyToClipboardButton, ResetMachineButton } from 'components/molecules';
import { useMachineDetails } from 'reactQuery/queries';
import { useLocale } from 'util/hooks';

const ResetMachineCard = () => {
  const {
    data: machineDetails = {},
  } = useMachineDetails();

  const {
    ip = '',
  } = machineDetails;

  const { t } = useLocale();

  return (
    <Card
      key="reset-machine-card"
      sx={{
        minWidth: 300,
        minHeight: 161,
        height: 1,
        py: 6,
        px: 5,
      }}
    >
      <Stack>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          flexWrap="wrap"
          rowGap={2}
        >
          <Stack direction="row" spacing={4}>
            <Box
              sx={{
                width: 48,
                height: 44,
              }}
            >
              <PlansFilledIcon width="48" height="44" />
            </Box>
            <Stack>
              <Typography
                variant="bodyStandardMedium"
                color="text.lightGray"
              >
                {t('machines.ip')}
              </Typography>
              <Typography
                variant="bodySmallRegular"
                color="text.secondary"
              >
                {t('machines.machineIp')}
              </Typography>
            </Stack>
          </Stack>
          <ResetMachineButton />
        </Stack>
        <Box sx={{
          mt: 5,
          ml: { md: 16 },
        }}
        >
          <CopyToClipboardButton
            buttonLabel={ip || t('common.none')}
            textToCopy={ip ?? ''}
          />
        </Box>
      </Stack>
    </Card>
  );
};

export default ResetMachineCard;
