import { useTheme } from '@mui/styles';

const FlagsIcon = (props) => {
  const theme = useTheme();
  const {
    stroke = theme.palette.text.lightRaspberry,
    fill = 'none',
    ...rest
  } = props;

  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill={fill} xmlns="http://www.w3.org/2000/svg" {...rest}>
      <g clipPath="url(#clip0_3225_35332)">
        <path d="M27.2022 5.04688L15.0605 34.9569" stroke={stroke} strokeWidth="2.5625" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M26.2622 7.35938L38.3622 12.2727C38.4714 12.3169 38.5659 12.3912 38.6348 12.4868C38.7036 12.5824 38.744 12.6956 38.7513 12.8132C38.7587 12.9308 38.7326 13.0481 38.6761 13.1515C38.6197 13.2549 38.5351 13.3403 38.4322 13.3977L34.3355 15.6944C33.84 15.9717 33.4537 16.4095 33.2401 16.9357C33.0265 17.4619 32.9985 18.0451 33.1605 18.5894L34.4939 23.0977C34.5272 23.2104 34.528 23.3302 34.4963 23.4434C34.4646 23.5566 34.4016 23.6585 34.3146 23.7375C34.2276 23.8165 34.1201 23.8694 34.0044 23.8901C33.8887 23.9107 33.7695 23.8984 33.6605 23.8544L21.5605 18.9427" stroke={stroke} strokeWidth="2.5625" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M12.7988 5.04688L24.9405 34.9569" stroke={stroke} strokeWidth="2.5625" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M13.7384 7.35938L1.6384 12.2727C1.52918 12.3169 1.43469 12.3912 1.36584 12.4868C1.29699 12.5824 1.25657 12.6956 1.24925 12.8132C1.24194 12.9308 1.26801 13.0481 1.32448 13.1515C1.38095 13.2549 1.46551 13.3403 1.5684 13.3977L5.66506 15.6944C6.1606 15.9717 6.54694 16.4095 6.7605 16.9357C6.97407 17.4619 7.00213 18.0451 6.84006 18.5894L5.50673 23.0977C5.47342 23.2104 5.47258 23.3302 5.50428 23.4434C5.53598 23.5566 5.59896 23.6585 5.68597 23.7375C5.77298 23.8165 5.8805 23.8694 5.9962 23.8901C6.1119 23.9107 6.23108 23.8984 6.34006 23.8544L18.4417 18.9427" stroke={stroke} strokeWidth="2.5625" strokeLinecap="round" strokeLinejoin="round" />
      </g>
      <defs>
        <clipPath id="clip0_3225_35332">
          <rect width="40" height="40" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default FlagsIcon;
