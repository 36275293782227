export const STATUS = {
  active: 'active',
  pending: 'pending',
  suspended: 'suspended',
  expired: 'expired',
  draft: 'draft',
  published: 'published',
  retired: 'retired',
  archived: 'archived',
  inactive: 'inactive',
  successful: 'successful',
  failed: 'failed',
  banned: 'banned',
  live: 'live',
  comingUp: 'comingUp',
  ended: 'ended',
};

export const STATUSES = [
  {
    id: 1,
    name: STATUS.active,
    localizationKey: 'statuses.active',
  },
  {
    id: 2,
    name: STATUS.pending,
    localizationKey: 'statuses.pending',
  },
  {
    id: 3,
    name: STATUS.suspended,
    localizationKey: 'statuses.suspended',
  },
  {
    id: 4,
    name: STATUS.expired,
    localizationKey: 'statuses.expired',
  },
  {
    id: 5,
    name: STATUS.draft,
    localizationKey: 'statuses.draft',
  },
  {
    id: 6,
    name: STATUS.published,
    localizationKey: 'statuses.published',
  },
  {
    id: 7,
    name: STATUS.retired,
    localizationKey: 'statuses.retired',
  },
  {
    id: 8,
    name: STATUS.archived,
    localizationKey: 'statuses.archived',
  },
  {
    id: 9,
    name: STATUS.inactive,
    localizationKey: 'statuses.inactive',
  },
  {
    id: 10,
    name: STATUS.successful,
    localizationKey: 'statuses.successful',
  },
  {
    id: 11,
    name: STATUS.failed,
    localizationKey: 'statuses.failed',
  },
  {
    id: 12,
    name: STATUS.banned,
    localizationKey: 'statuses.banned',
  },
  {
    id: 13,
    name: STATUS.live,
    localizationKey: 'statuses.live',
  },
  {
    id: 14,
    name: STATUS.comingUp,
    localizationKey: 'statuses.comingUp',
  },
  {
    id: 15,
    name: STATUS.ended,
    localizationKey: 'statuses.ended',
  },
];

export const getStatusById = (id) => STATUSES.find((status) => status.id === Number(id));
export const getStatusByName = (name) => STATUSES.find((status) => status.name === String(name));
export const getStatusByKey = (key, value) => STATUSES.find((status) => status[key] === value);
