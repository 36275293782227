import { useTheme } from '@mui/styles';

const CreditCardIcon = (props) => {
  const theme = useTheme();
  const {
    stroke = theme.palette.text.secondary,
    fill = 'none',
    ...rest
  } = props;

  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill={fill} xmlns="http://www.w3.org/2000/svg" stroke={stroke} {...rest}>
      <g clipPath="url(#clip0_656_350)">
        <path d="M18.125 3.125H1.875C1.18464 3.125 0.625 3.68464 0.625 4.375V15.625C0.625 16.3154 1.18464 16.875 1.875 16.875H18.125C18.8154 16.875 19.375 16.3154 19.375 15.625V4.375C19.375 3.68464 18.8154 3.125 18.125 3.125Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M0.625 6.875H19.375" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M4.375 10.625H11.25" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M4.375 13.125H8.75" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      </g>
      <defs>
        <clipPath id="clip0_656_350">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CreditCardIcon;
