import { Alert } from '@mui/material';
import { useSystemPermissions } from 'reactQuery/queries';

const PermissionsAlert = () => {
  const { data: permissions = [] } = useSystemPermissions();

  const alertToRender = (
    <Alert
      severity="error"
      sx={{
        px: { xs: 2, md: 64 },
        pt: 16,
      }}
    >
      Backend permissions have been updated, please update the Frontend
      permissions list to have the following keys or values:
      <br />
      (Note: This alert is only shown in the dev mode)
      <br />
      {permissions.map((permission) => (
        <div key={permission}>
          <li>{permission}</li>
        </div>
      ))}
    </Alert>
  );

  return permissions.length > 0 ? alertToRender : null;
};

export default PermissionsAlert;
