import {
  Box, Button, Card, Link, Typography,
} from '@mui/material';

import PERMISSIONS from 'assets/constants/permissions';
import { AvatarWithFallback, StatusBadge } from 'components/molecules';
import {
  useAdminTeamDetails,
  useEventDetails,
  usePlayerTeamDetails,
} from 'reactQuery/queries';
import { useLocale, usePermission } from 'util/hooks';

const EventTeamOverview = () => {
  const { t } = useLocale();

  const { data: eventDetails = {} } = useEventDetails();
  const canViewTeamAsManager = usePermission(PERMISSIONS.teamViewOneByName);
  const isTeamMember = usePermission(PERMISSIONS.teamViewOwn);

  const { data: adminTeamDetails = {} } = useAdminTeamDetails({
    options: {
      enabled: canViewTeamAsManager,
    },
  });

  const { data: playerTeamDetails = {} } = usePlayerTeamDetails({
    options: {
      enabled: isTeamMember,
    },
  });

  const { eventHasEnded } = eventDetails;

  const teamDetails = canViewTeamAsManager
    ? adminTeamDetails
    : playerTeamDetails;

  const {
    logo = null,
    name: teamName = '',
    motto = '',
    status = '',
    isCurrentUserCaptain = false,
    id: teamId,
  } = teamDetails || {};

  const canEdit = canViewTeamAsManager || (isCurrentUserCaptain && !eventHasEnded);
  const editPath = canViewTeamAsManager ? 'edit' : `teams/${teamId}/edit`;

  return (
    <Card sx={{ py: 5, px: 8 }}>
      <Box
        sx={{
          display: 'flex',
          minWidth: 1,
          flexWrap: 'wrap',
          rowGap: 5,
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ mr: 4 }}>
              <AvatarWithFallback
                src={logo}
                alt={`${teamName}-team-avatar`}
                sx={{
                  width: { xs: 45, sm: 60 },
                  height: { xs: 45, sm: 60 },
                  fontWeight: 'bold',
                  fontSize: 23,
                }}
              />
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                wordBreak: 'break-word',
              }}
            >
              <Box
                sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}
              >
                <Typography variant="bodyMediumMedium" color="text.lighterGray">
                  {teamName}
                </Typography>
                <Box sx={{ width: 14, height: 14, mx: 2 }}>
                  <StatusBadge status={status} />
                </Box>
                <Box>
                  <Typography variant="bodySmallMedium" color="text.secondary">
                    {t(`statuses.${status}`)}
                  </Typography>
                </Box>
              </Box>
              <Typography variant="bodyStandardRegular" color="text.secondary">
                {motto}
              </Typography>
            </Box>
            <Box />
          </Box>
        </Box>
        {canEdit && (
          <Box sx={{ ml: { xs: 15, sm: 18 } }}>
            <Button
              component={Link}
              size="small"
              color="secondary"
              variant="outlined"
              to={editPath}
            >
              {t('common.edit')}
            </Button>
          </Box>
        )}
      </Box>
    </Card>
  );
};

export default EventTeamOverview;
