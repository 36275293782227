import END_POINTS from '../endPoints';
import api from '../api';

const getMyInfo = () => api(
  END_POINTS.myInfo,
)
  .then((res) => Promise.resolve(res))
  .catch((error) => Promise.reject(error));

export default getMyInfo;
