import { IconButton } from '@mui/material';

const CirclePagination = (props) => {
  const {
    count,
    page,
    onChange,
  } = props;

  const handleClick = (idx) => onChange(idx);

  return Array(count)
    .fill()
    .map((_, idx) => {
      const key = `circle-pagination-${idx}`;
      const isActive = page === idx;

      return (
        <IconButton
          key={key}
          size="small"
          onClick={() => handleClick(idx)}
          sx={{
            border: 'none',
            bgcolor: isActive
              ? 'text.secondary'
              : 'text.darkGray',
            width: isActive ? 20 : 12,
            height: isActive ? 12 : 12,
            borderRadius: isActive ? 5 : 50,
            mr: 1,
            '&:hover': {
              bgcolor: 'text.secondary',
            },
          }}
        />
      );
    });
};

export default CirclePagination;
