import { Box } from '@mui/material';

const CenterContainer = ({ sx, ...rest }) => (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      minHeight: '100vh',
      ...sx,
    }}
    {...rest}
  />
);

export default CenterContainer;
