import { useParams } from 'react-router-dom';
import { getPromoCodeDetails } from 'services';
import { useCustomQuery } from 'reactQuery';

const usePromoCode = () => {
  const { promoCodeId } = useParams();

  const query = useCustomQuery({
    queryKey: [`view-promo-code-${promoCodeId}`],
    queryFn: () => getPromoCodeDetails(promoCodeId),
    options: { enabled: !!promoCodeId },
  });

  return { ...query };
};

export default usePromoCode;
