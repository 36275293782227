import { getCompanyMembers } from 'services';
import { useCustomQuery } from 'reactQuery';

const useCompanyMembers = (queryProps = {}) => {
  const { companyName, ...rest } = queryProps;

  const query = useCustomQuery({
    queryKey: [`company-members-${companyName}`],
    queryFn: (options) => getCompanyMembers(companyName, options),
    ...rest,
  });

  return { ...query };
};

export default useCompanyMembers;
