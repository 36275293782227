import { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { InputLabel, TextField as MUITextField } from '@mui/material';
import { formatNumber } from 'util/helpers';

const NumberField = forwardRef((props, ref) => {
  const {
    label,
    labelProps,
    commaSeparated,
    value,
    ...rest
  } = props;

  return (
    <>
      <InputLabel
        htmlFor={rest.id || rest.name}
        sx={(theme) => ({
          px: 2,
          color: 'text.secondary',
          ...theme.typography.bodyStandardRegular,
        })}
        {...labelProps}
      >
        {label}
      </InputLabel>
      <MUITextField
        {...rest}
        value={formatNumber(value, commaSeparated)}
        inputRef={ref}
      />
    </>
  );
});

NumberField.propTypes = {
  label: PropTypes.string,
  labelProps: PropTypes.oneOfType([PropTypes.object]),
  commaSeparated: PropTypes.bool,
  value: PropTypes.string,
};

NumberField.defaultProps = {
  label: '',
  labelProps: {},
  commaSeparated: true,
  value: '',
};

export default NumberField;
