import { Chart as ReactChart } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  LineController,
  RadarController,
  LineElement,
  PointElement,
  LinearScale,
  RadialLinearScale,
  Title,
  Filler,
  Tooltip,
  Legend,
  CategoryScale,
} from 'chart.js';

import CHART_TYPES from '../../assets/constants/chartTypes';

ChartJS.register(
  LineController,
  RadarController,
  LineElement,
  PointElement,
  LinearScale,
  RadialLinearScale,
  PointElement,
  LineElement,
  CategoryScale,
  Title,
  Filler,
  Tooltip,
  Legend,
);

const Chart = (props) => {
  const {
    type = CHART_TYPES.line,
    data = {
      labels: [],
      datasets: [],
    },
    ...rest
  } = props;

  return (
    <ReactChart
      type={type}
      data={data}
      {...rest}
    />
  );
};

export default Chart;
