import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Stack,
  Typography,
  Link,
} from '@mui/material';
import { noRowsPlaceholder } from 'assets/images';

const NoContentPlaceholder = (props) => {
  const {
    content,
    stackProps,
    imgProps,
    ctaProps,
  } = props;

  return (
    <Stack
      spacing={4}
      sx={{
        height: 1,
        alignItems: 'center',
        justifyContent: 'center',
      }}
      {...stackProps}
    >
      <Box>
        <img
          src={content?.img || noRowsPlaceholder}
          alt="no-content-placeholder"
          height="53"
          width="173"
          {...imgProps}
        />
      </Box>
      {content.desc?.map(({ text, variant = 'bodyStandardRegular' }) => (
        <Box key={text}>
          <Typography variant={variant}>
            {text}
          </Typography>
        </Box>
      ))}
      {content.cta?.text && (
        <Box>
          <Button
            variant="outlined"
            color="secondary"
            component={Link}
            to={content.cta?.to}
            {...ctaProps}
            sx={{
              zIndex: 1,
              ...ctaProps.sx,
            }}
          >
            {content.cta?.text}
          </Button>
        </Box>
      )}
    </Stack>
  );
};

NoContentPlaceholder.propTypes = {
  content: PropTypes.shape({
    img: PropTypes.string,
    desc: PropTypes.oneOfType([PropTypes.array]),
    cta: PropTypes.oneOfType([PropTypes.object]),
  }),
  stackProps: PropTypes.oneOfType([PropTypes.object]),
  imgProps: PropTypes.oneOfType([PropTypes.object]),
  ctaProps: PropTypes.oneOfType([PropTypes.object]),
};

NoContentPlaceholder.defaultProps = {
  content: {
    img: noRowsPlaceholder,
    desc: [{
      text: 'ops, such empty!',
      variant: 'bodyStandardRegular',
    }],
    cta: {},
  },
  stackProps: {},
  imgProps: {},
  ctaProps: {},
};

export default NoContentPlaceholder;
