import { useTheme } from '@mui/styles';

const AutoGenerateIcon = (props) => {
  const theme = useTheme();
  const {
    stroke = theme.palette.text.raspberry,
    fill = 'none',
    ...rest
  } = props;

  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill={fill} xmlns="http://www.w3.org/2000/svg" stroke={stroke} {...rest}>
      <path d="M5.73269 15.9908L1.25019 11.5092C1.13403 11.3931 1.04188 11.2552 0.979016 11.1035C0.916148 10.9518 0.883789 10.7892 0.883789 10.625C0.883789 10.4608 0.916148 10.2982 0.979016 10.1465C1.04188 9.99475 1.13403 9.85692 1.25019 9.74083L10.0002 0.990833C10.1163 0.874778 10.2542 0.782736 10.4059 0.719966C10.5576 0.657195 10.7202 0.624926 10.8844 0.625H15.366C15.6975 0.625 16.0155 0.756696 16.2499 0.991117C16.4843 1.22554 16.616 1.54348 16.616 1.875V6.3575C16.616 6.68879 16.4844 7.0065 16.2502 7.24083" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M13.4912 3.4375C13.4294 3.4375 13.369 3.45583 13.3176 3.49017C13.2662 3.5245 13.2262 3.57331 13.2025 3.63041C13.1788 3.68751 13.1727 3.75035 13.1847 3.81097C13.1968 3.87158 13.2265 3.92727 13.2702 3.97097C13.3139 4.01467 13.3696 4.04444 13.4302 4.0565C13.4909 4.06855 13.5537 4.06236 13.6108 4.03871C13.6679 4.01506 13.7167 3.97501 13.751 3.92362C13.7854 3.87223 13.8037 3.81181 13.8037 3.75C13.8037 3.66712 13.7708 3.58763 13.7122 3.52903C13.6536 3.47042 13.5741 3.4375 13.4912 3.4375Z" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M12.2412 15.625H9.11621V18.75" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M18.6307 16.2358C18.2997 17.2031 17.6587 18.0341 16.8072 18.5999C15.9557 19.1657 14.9412 19.4347 13.9213 19.3651C12.9013 19.2955 11.9328 18.8912 11.166 18.2149C10.3993 17.5387 9.87717 16.6283 9.68066 15.625" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M15.9912 13.125H19.1162V10" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M9.60156 12.5123C9.93316 11.5456 10.5744 10.7153 11.4259 10.15C12.2773 9.58473 13.2915 9.31613 14.3111 9.38581C15.3308 9.4555 16.299 9.85958 17.0656 10.5354C17.8323 11.2113 18.3546 12.1212 18.5516 13.124" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default AutoGenerateIcon;
