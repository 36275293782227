import END_POINTS from '../endPoints';
import METHODS from '../methods';
import api from '../api';

const togglePlanFeaturePromoted = (id) => api(
  END_POINTS.featuresTogglePromoted(id),
  {
    method: METHODS.PATCH,
  },
)
  .then((res) => Promise.resolve(res))
  .catch((error) => Promise.reject(error));

export default togglePlanFeaturePromoted;
