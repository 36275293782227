import * as Yup from 'yup';
import { useParams } from 'react-router-dom';

import { LoadingButton } from '@mui/lab';
import { Box, Card, Typography } from '@mui/material';

import { FormTextField } from 'components/form';
import { submitEventFlag, submitFlag } from 'services';
import {
  useModal,
  useLocale,
  useIsEvent,
  useFastForm,
  useSnackbar,
  useRecaptcha,
} from 'util/hooks';

import { CheckCircleOutline, FlagFilledIcon } from 'assets/icons';
import {
  useLabDetails,
  useLabChallengeDetails,
  useEventChallengeDetails,
  useEventChallengeSubmissions,
  useEventDetails,
} from 'reactQuery/queries';

import MODAL_KEYS from 'assets/constants/modalKeys';

const FlagSubmissionCard = (props) => {
  const {
    showLockedBackdrop = false,
  } = props;

  const { eventId } = useParams();

  const { t } = useLocale();
  const snack = useSnackbar();
  const { addModal } = useModal();
  const { executeRecaptcha } = useRecaptcha({ action: 'FLAG_SUBMISSION' });

  const {
    data: eventDetails = {},
  } = useEventDetails();

  const {
    data: labDetails = {},
  } = useLabDetails();

  const {
    data: labChallengeDetails = {},
    refetch: refetchLabChallenge = () => { },
  } = useLabChallengeDetails();

  const {
    data: eventChallengeDetails = {},
    refetch: refetchEventChallenge = () => { },
  } = useEventChallengeDetails();

  const {
    refetch: refetchEventChallengeSubmissions = () => { },
  } = useEventChallengeSubmissions({
    queryKeyOptions: {
      pagination: {
        limit: 500,
      },
    },
  });

  const isEventChallenge = useIsEvent();
  const challengeDetails = isEventChallenge ? eventChallengeDetails : labChallengeDetails;
  const refetchChallenge = isEventChallenge
    ? refetchEventChallenge
    : refetchLabChallenge;

  const {
    id: labId,
  } = labDetails || {};

  const { isDynamicPoints = false } = eventDetails;

  const {
    id: challengeId,
    points = 0,
    isCompletedByUser = false,
    nextSubmissionPoints = 0,
  } = challengeDetails || {};

  const handleOpenFlagModal = (firstBlood = false) => {
    addModal({
      key: MODAL_KEYS.correctFlag,
      props: {
        type: 'challenge',
        points,
        firstBlood,
        isDynamicPoints,
        nextSubmissionPoints,
      },
    });
  };

  const playerSubmission = isCompletedByUser;

  const {
    submittedAt = '',
  } = playerSubmission || {};

  const handleError = (error = {}) => {
    const { message, property } = error;

    if (property) {
      setError(property, {
        type: 'api',
        message,
      });
    } else {
      snack({
        severity: 'error',
        message: message || t('common.somethingWrong'),
      });
    }
  };

  const onSubmit = async ({ flag }) => {
    const payload = {
      flag,
    };

    try {
      const entity = isEventChallenge ? eventId : labId;
      const handler = isEventChallenge ? submitEventFlag : submitFlag;
      const recaptchaToken = await executeRecaptcha();
      const { firstBlood } = await handler(entity, challengeId, payload, { recaptchaToken });

      handleOpenFlagModal(firstBlood);

      // Update challenge
      refetchChallenge();
      isEventChallenge && refetchEventChallengeSubmissions();
    } catch (error) {
      if (error.message) {
        handleError(error);
      } else {
        error.errors?.forEach((err) => {
          handleError(err);
        });
      }
    }
  };

  const defaultValues = {
    flag: '',
  };

  const validationSchema = Yup.object({
    flag: Yup
      .string()
      .trim()
      .required(t('challenges.enterFlag'))
      .min(1, t('challenges.validation.flagMinLength'))
      .max(250, t('challenges.validation.flagMaxLength')),
  });

  const {
    control,
    handleSubmit,
    setError,
    formState: { isSubmitting, isDirty },
  } = useFastForm({
    defaultValues,
    validationSchema,
  });

  const form = (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Box sx={{
          mb: 9,
          maxWidth: 304,
        }}
        >
          <FormTextField
            name="flag"
            control={control}
            label={t('common.theFlag')}
            placeholder={t('challenges.enterFlag')}
            disabled={showLockedBackdrop}
            fullWidth
          />
        </Box>
        <Box>
          <LoadingButton
            type="submit"
            variant="contained"
            loading={isSubmitting}
            disabled={showLockedBackdrop || !isDirty || isSubmitting}
          >
            {t('common.submit')}
          </LoadingButton>
        </Box>
      </Box>
    </form>
  );

  const completedPlaceholder = isCompletedByUser && (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        my: 5,
      }}
    >
      <Box sx={{
        mb: 5,
        width: 38,
        height: 38,
      }}
      >
        <CheckCircleOutline
          width="38"
          height="38"
        />
      </Box>
      <Typography
        variant="bodyMediumMedium"
        color="text.lightGray"
      >
        {t('challenges.youHacked')}
      </Typography>
      <Typography
        variant="bodyStandardMedium"
        color="text.secondary"
      >
        {`${t('common.on')} ${new Date(submittedAt)
          .toLocaleString(
            'en-US',
            {
              year: 'numeric',
              month: 'short',
              day: 'numeric',
              hour: '2-digit',
              minute: '2-digit',
            },
          )}`}
      </Typography>
    </Box>
  );

  return (
    <Card
      sx={{
        px: 5,
        py: 6,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Box
            sx={{
              mr: 4,
              width: 48,
              height: 44,
            }}
          >
            <FlagFilledIcon width="48" height="44" />
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Typography
              variant="bodyStandardMedium"
              color="text.lightGray"
            >
              {t('challenges.submitFlag')}
            </Typography>
            <Typography
              variant="bodySmallRegular"
              color="text.secondary"
            >
              {t('challenges.foundFlag')}
            </Typography>
          </Box>
        </Box>
        {isCompletedByUser
          ? completedPlaceholder
          : (
            <Box sx={{
              mt: 5,
              ml: { md: 16 },
            }}
            >
              {form}
            </Box>
          )}
      </Box>
    </Card>
  );
};

export default FlagSubmissionCard;
