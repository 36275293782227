import PropTypes from 'prop-types';

import {
  useLocale,
  useModal,
  usePlanPermissions,
  useSnackbar,
} from 'util/hooks';
import { Box, Button, Link } from '@mui/material';
import { updatePlanStatus } from 'services';
import { useCallback } from 'react';
import { PATHS } from 'routes';
import MODAL_KEYS from 'assets/constants/modalKeys';

const PlanActions = (props) => {
  const {
    planDetails,
    fetchPlanDetails,
  } = props;

  const {
    id = '',
    nameAr = '',
    nameEn = '',
  } = planDetails;

  const snack = useSnackbar();
  const { t, isAr } = useLocale();
  const { addModal } = useModal();

  // Plan permissions
  const {
    showPublishButton,
    showEditButton,
    showSendForApprovalButton,
    showReturnToDraftButton,
  } = usePlanPermissions(planDetails);

  const onUpdatePlanStatus = useCallback(async (type, _) => {
    try {
      await updatePlanStatus(id, type);
      snack({
        message: `"${isAr ? nameAr : nameEn}" ${t(`plans.${type}Success`)}`,
        severity: 'success',
      });
      fetchPlanDetails();
    } catch (error) {
      const { errors } = error;
      snack({
        message: errors?.[0]?.message || error.message,
        severity: 'error',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t]);

  const onUpdatePlan = (type) => {
    addModal({
      key: MODAL_KEYS.updatePlan,
      props: {
        type,
        onConfirm: onUpdatePlanStatus,
        plan: {
          nameAr,
          nameEn,
        },
      },
    });
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'flex-end',
      }}
    >
      {/* Publish */}
      {showPublishButton && (
        <Box sx={{ ml: 2 }}>
          <Button variant="contained" onClick={() => onUpdatePlan('publish')}>
            {t('plans.publish')}
          </Button>
        </Box>
      )}
      {/* Send for approval */}
      {showSendForApprovalButton && (
        <Box sx={{ ml: 2 }}>
          <Button variant="contained" onClick={() => onUpdatePlan('sendForApproval')}>
            {t('plans.sendForApproval')}
          </Button>
        </Box>
      )}
      {/* Edit */}
      {showEditButton && (
        <Box sx={{ ml: 2 }}>
          <Button
            component={Link}
            to={`/${PATHS.plans}/${id}/edit`}
            sx={{
              color: 'text.raspberry',
            }}
          >
            {t('common.edit')}
          </Button>
        </Box>
      )}
      {/* Return to draft */}
      {showReturnToDraftButton && (
        <Box sx={{ ml: 2 }}>
          <Button variant="contained" onClick={() => onUpdatePlan('draft')}>
            {t('plans.returnToDraft')}
          </Button>
        </Box>
      )}
    </Box>
  );
};

PlanActions.propTypes = {
  planDetails: PropTypes.oneOfType([PropTypes.object]),
  fetchPlanDetails: PropTypes.func,

};

PlanActions.defaultProps = {
  planDetails: {},
  fetchPlanDetails: () => { },
};

export default PlanActions;
