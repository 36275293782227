import {
  GridOverlay,
} from '@mui/x-data-grid';
import { useLocale } from 'util/hooks';

import { NoContentPlaceholder } from 'components/molecules';
import { useSearchParams } from 'react-router-dom';

const CustomNoResultsOverlay = () => {
  const [searchParams] = useSearchParams();
  const searchValue = searchParams.get('q') || '';

  const { t } = useLocale();

  const content = {
    desc: [
      {
        text: t('common.noResult'),
        variant: 'bodyMediumBold',
      },
      {
        text: (`${t('common.noResultDescription')}${searchValue}`),
      },
    ],
  };

  return (
    <GridOverlay>
      <NoContentPlaceholder content={content} />
    </GridOverlay>
  );
};

export default CustomNoResultsOverlay;
