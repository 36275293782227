import PropTypes from 'prop-types';

import {
  Box,
  Button,
  Card,
  Chip,
  Tooltip,
  Typography,
} from '@mui/material';

import { useAuth, useLocale, useModal } from 'util/hooks';

import { SubscriptionFilledStarIcon } from 'assets/icons';
import { getStatusByName, STATUS } from 'assets/constants/statuses';
import MODAL_KEYS from 'assets/constants/modalKeys';

const dateStyle = {
  paddingRight: 1.5,
  borderRadius: 5,
  color: 'text.primary',
  backgroundColor: 'paper.hover',
  '& .MuiChip-deleteIcon': {
    color: 'text.darkGray',
  },
};

const SubscriptionCard = (props) => {
  const {
    subscription = {},
    hasPaymentCards = false,
  } = props;

  const { addModal } = useModal();
  const { t, isAr } = useLocale();
  const {
    currentUser: {
      preferences = {},
    } = {},
  } = useAuth();

  const {
    autoRenewalEnabled,
  } = preferences || {};
  const {
    plan = {},
    isActive = false,
    billingOption = 'perMonth',
    expiresAt = '',
  } = subscription || {};
  const {
    isFree = false,
    nameAr = '',
    nameEn = '',
  } = plan || {};

  const isActiveAndPremium = isActive && !isFree;

  const planName = isAr
    ? nameAr
    : nameEn;
  const status = isActive
    ? STATUS.active
    : STATUS.inactive;
  const statusLabel = t(getStatusByName(status)?.localizationKey);
  const activateAutoRenewalDisabled = !hasPaymentCards && !autoRenewalEnabled;

  const handleButtonClick = () => {
    const modalType = autoRenewalEnabled
      ? 'cancel'
      : 'activate';

    addModal({
      key: MODAL_KEYS.subscriptionHistory,
      props: {
        subscription,
        type: modalType,
      },
    });
  };

  const dateLabel = autoRenewalEnabled
    ? t('subscriptions.nextBillingDate')
    : t('subscriptions.activeUntil');

  const expirationDateLabel = `${dateLabel} ${new Date(expiresAt)
    .toLocaleDateString(
      'en-US',
      {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
      },
    )}`;

  const billingOptionString = billingOption === 'perMonth'
    ? t('subscriptions.monthly')
    : t('subscriptions.annually');

  // Card Content
  const subscriptionTitle = (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{
        width: 50,
        height: 46,
        mr: 4,
        p: 0,
      }}
      >
        <SubscriptionFilledStarIcon width="48" height="44" />
      </Box>
      <Box>
        <Typography variant="bodyMediumMedium" color="text.lighterGray">
          {planName}
        </Typography>
        <Box>
          <Typography variant="bodySmallRegular" color="text.secondary">
            {isFree
              ? t('subscriptions.lifetime')
              : billingOptionString}
          </Typography>
        </Box>
      </Box>
    </Box>
  );

  const subscriptionChips = (
    <Box sx={{
      display: 'flex',
      flexDirection: { xs: 'column', sm: 'row' },
      justifyContent: {
        md: 'space-between',
      },
    }}
    >
      <Box>
        <Chip
          size="small"
          label={statusLabel}
          color={status}
        />
      </Box>
      <Box sx={{
        mx: {
          sm: 4,
          xs: 0,
        },
        mt: {
          sm: 0,
          xs: 2,
        },
      }}
      >
        {(isActiveAndPremium) && (
          <Chip
            size="small"
            label={expirationDateLabel}
            sx={dateStyle}
          />
        )}
      </Box>
    </Box>
  );

  const renderSubscriptionCta = () => {
    const ctaLabel = autoRenewalEnabled
      ? t('subscriptions.accountSubscription.cancelAutoRenew')
      : t('subscriptions.accountSubscription.activateAutoRenew');

    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: { xs: 'flex-start', md: 'flex-end' },
        }}
      >
        <Tooltip
          title={t('subscriptions.accountSubscription.enableAutoRenew')}
          placement="top-start"
          componentsProps={{
            tooltip: {
              sx: {
                display: activateAutoRenewalDisabled ? 'block' : 'none',
              },
            },
          }}
        >
          <span>
            <Button
              size="small"
              variant="outlined"
              color="secondary"
              disabled={activateAutoRenewalDisabled}
              onClick={handleButtonClick}
            >
              {ctaLabel}
            </Button>
          </span>
        </Tooltip>
      </Box>
    );
  };

  return (
    <Box key={subscription.id} sx={{ width: 1, mt: 7 }}>
      <Card sx={{ p: { xs: 5, sm: 10 } }}>
        <Box sx={{
          display: 'grid',
          columnGap: 11,
          flexWrap: 'wrap',
          rowGap: 8,
          gridTemplateColumns: {
            xs: 'repeat(1, 1fr)',
            md: 'repeat(3, 1fr)',
          },
          alignItems: {
            xs: 'start',
            md: 'center',
          },
        }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {subscriptionTitle}
          </Box>
          {subscriptionChips}
          {isActiveAndPremium && renderSubscriptionCta()}
        </Box>
      </Card>
    </Box>
  );
};

SubscriptionCard.propTypes = {
  subscription: PropTypes.oneOfType([PropTypes.object]),
};

SubscriptionCard.defaultProps = {
  subscription: {},
};

export default SubscriptionCard;
