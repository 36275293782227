import {
  Button,
  Typography,
} from '@mui/material';

import { useLocale } from 'util/hooks';
import { DownloadIcon } from 'assets/icons';
import palette from 'style/theme/palette';

const DownloadButton = (props) => {
  const { src } = props;

  const { t } = useLocale();

  return (
    <a
      href={src}
      target="_blank"
      style={{ textDecoration: 'none' }}
      rel="noreferrer"
    >
      <Button
        startIcon={<DownloadIcon width="20" height="20" stroke={palette.secondary.main} />}
        color="secondary"
      >
        <Typography
          variant="bodyStandardBold"
        >
          {t('common.download')}
        </Typography>
      </Button>
    </a>
  );
};

export default DownloadButton;
