import { useEffect } from 'react';
import { Box } from '@mui/material';

import { CustomBreadcrumbs } from 'components/molecules';
import { MachineStats } from 'components/organisms';

import { useLocale, usePageTitle } from 'util/hooks';
import { PATHS } from 'routes';

import { STATUS } from 'assets/constants/statuses';
import MachineHeader from './sections/MachineHeader';
import MachineInfo from './sections/MachineInfo';
import MachineActivity from '../players/sections/MachineActivity';

const ViewMachineContainer = (props) => {
  const { t } = useLocale();

  const {
    machineDetails = {},
  } = props;

  const showStatsAndActivity = machineDetails?.status !== STATUS.draft;

  const setPageTitle = usePageTitle();
  useEffect(() => {
    setPageTitle(machineDetails?.name);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [machineDetails?.name]);

  const crumbs = [
    {
      text: t('common.machines'),
      link: `/${PATHS.machines}`,
    },
    {
      text: t('machines.machineDetails'),
      link: '#',
    },
  ];

  return (
    <Box>
      <Box>
        <CustomBreadcrumbs crumbs={crumbs} />
      </Box>
      <Box sx={{ mt: 4, mb: 10 }}>
        <MachineHeader machineDetails={machineDetails} />
      </Box>
      {showStatsAndActivity
        && (
          <Box>
            <MachineStats machineDetails={machineDetails} />
          </Box>
        )}
      <Box sx={{ my: 8 }}>
        <MachineInfo machineDetails={machineDetails} />
      </Box>
      {showStatsAndActivity
        && (
          <Box mt={8}>
            <MachineActivity
              machineDetails={machineDetails}
              showDates={false}
            />
          </Box>
        )}
    </Box>
  );
};

export default ViewMachineContainer;
