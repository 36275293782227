import { useState } from 'react';
import { isEmpty } from 'lodash';

import {
  Box,
  Card,
} from '@mui/material';

import {
  LabsFilledIcon,
} from 'assets/icons';

import { useLocale, useAuth, usePermission } from 'util/hooks';
import PERMISSIONS from 'assets/constants/permissions';
import LabDetailsForm from '../../../LabDetailsForm/LabDetailsForm';
import LabSectionWrapper from '../../helpers/LabSectionWrapper';
import LabName from '../../helpers/LabName';
import LabDescription from '../../helpers/LabDescription';

const LabInfo = (props) => {
  const { labDetails = {} } = props;
  const { currentUser } = useAuth();
  const { username } = currentUser;

  const { t } = useLocale();

  // Edit permission
  // P.S. I don't like this
  const canEdit = usePermission(PERMISSIONS.labUpdate);
  const canEditAll = usePermission(PERMISSIONS.labUpdateOwn);
  const isOwnLab = labDetails?.createdBy === username;
  const canEditThisLab = canEditAll || (canEdit && isOwnLab);

  const [viewMode, setViewMode] = useState(!isEmpty(labDetails));
  const onClickEdit = () => setViewMode(false);
  const onClickCancel = () => setViewMode(true);

  return (
    <Box sx={{ width: 1 }}>
      <Card sx={{ p: 14 }}>
        <LabSectionWrapper
          Icon={LabsFilledIcon}
          title={t('labs.labDetails')}
          viewMode={viewMode}
          canEditThisLab={canEditThisLab}
          onClickEdit={onClickEdit}
        >
          {viewMode
            ? (
              <Box>
                <Box sx={{ mb: 11 }}>
                  <LabName labDetails={labDetails} />
                </Box>
                <Box>
                  <LabDescription labDetails={labDetails} />
                </Box>
              </Box>
            )
            : (canEditThisLab
              && <LabDetailsForm onClickCancel={onClickCancel} />)}
        </LabSectionWrapper>
      </Card>
    </Box>
  );
};

export default LabInfo;
