import { DataGrid } from 'components/core';

import { useLabs } from 'reactQuery/queries';
import useTableColumns from './useTableColumns';

const LabTable = (props) => {
  const {
    types = [],
  } = props;

  const columns = useTableColumns();
  const filterOptions = {
    type: types,
  };

  const {
    data = {},
    isFetching = true,
  } = useLabs({
    queryKeyOptions: { filterOptions },
  });

  const {
    items: rows = [],
    meta = {},
  } = data || {};

  return (
    <DataGrid
      rows={rows}
      columns={columns}
      loading={isFetching}
      pagination={meta}
      componentsProps={{
        noRowsOverlay: { type: 'lab' },
      }}
    />
  );
};

export default LabTable;
