import { getSubscriptions } from 'services';
import { useCustomQuery } from 'reactQuery';

const useSubscriptions = () => {
  const query = useCustomQuery({
    queryKey: ['player-subscriptions'],
    queryFn: getSubscriptions,
  });

  return { ...query };
};

export default useSubscriptions;
