import { useTheme } from '@mui/styles';

const FileEditIcon = (props) => {
  const theme = useTheme();
  const {
    stroke = theme.palette.text.secondary,
    fill = 'none',
    ...rest
  } = props;

  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill={fill} xmlns="http://www.w3.org/2000/svg" stroke={stroke} {...rest}>
      <path d="M22.63 14.87L15 22.5L11.25 23.25L12 19.5L19.63 11.87C19.8263 11.6733 20.0595 11.5173 20.3162 11.4109C20.5729 11.3044 20.8481 11.2496 21.126 11.2496C21.4039 11.2496 21.6791 11.3044 21.9358 11.4109C22.1925 11.5173 22.4257 11.6733 22.622 11.87L22.63 11.878C22.8267 12.0743 22.9827 12.3075 23.0891 12.5642C23.1956 12.8209 23.2504 13.0961 23.2504 13.374C23.2504 13.6519 23.1956 13.9271 23.0891 14.1838C22.9827 14.4405 22.8267 14.6737 22.63 14.87V14.87Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M8.25 20.25H2.25C1.85218 20.25 1.47064 20.092 1.18934 19.8107C0.908035 19.5294 0.75 19.1478 0.75 18.75V2.25C0.75 1.85218 0.908035 1.47064 1.18934 1.18934C1.47064 0.908035 1.85218 0.75 2.25 0.75H12.879C13.2765 0.750085 13.6578 0.907982 13.939 1.189L16.811 4.061C17.092 4.3422 17.2499 4.72345 17.25 5.121V8.25" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default FileEditIcon;
