import {
  Box,
  Card,
  CircularProgress,
  Tooltip,
  Typography,
} from '@mui/material';
import Image from 'mui-image';

import { useLocale } from 'util/hooks';

const BadgeCard = (props) => {
  const {
    badge = {},
  } = props;
  const {
    nameEn = '',
    nameAr = '',
    avatar = '',
    objectiveCompletedEn = '',
    objectiveCompletedAr = '',
    objectiveUncompletedEn = '',
    objectiveUncompletedAr = '',
    earned = false,
  } = badge || {};

  const { isAr } = useLocale();

  const earnedHint = isAr ? objectiveCompletedAr : objectiveCompletedEn;
  const unearnedHint = isAr ? objectiveUncompletedAr : objectiveUncompletedEn;
  const tooltipTitle = earned ? earnedHint : unearnedHint;

  return (
    <Box>
      <Tooltip
        title={tooltipTitle}
        followCursor
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Image
            src={avatar}
            height={126}
            width={126}
            duration={0}
            showLoading={<CircularProgress />}
            style={{ opacity: earned ? 1 : 0.25 }}
          />
          <Card sx={{
            opacity: earned ? 1 : 0.25,
            display: 'flex',
            justifyContent: 'center',
            wordBreak: 'break-all',
            bgcolor: 'background.paper',
            minWidth: { sm: 155 },
            height: 32,
            px: 7,
            py: 2,
            mt: 5,
          }}
          >
            <Typography
              variant="bodySmallMedium"
              color="text.lighterGray"
            >
              {isAr ? nameAr : nameEn}
            </Typography>
          </Card>
        </Box>
      </Tooltip>
    </Box>
  );
};

export default BadgeCard;
