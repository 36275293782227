import { useState } from 'react';

import {
  Box, Button, Link, Tooltip, Typography,
} from '@mui/material';

import { PATHS } from 'routes';
import { useLocale } from 'util/hooks';

import { MAX_PAYMENT_CARDS } from 'assets/constants/paymentConstants';

const PaymentMethodsHeader = (props) => {
  const {
    paymentMethodsCount = 0,
  } = props;

  const { t } = useLocale();

  const disableAddCard = paymentMethodsCount >= MAX_PAYMENT_CARDS;

  // Controlled tooltip
  const [openTooltip, setOpenTooltip] = useState(false);
  const handleClose = () => {
    setOpenTooltip(false);
  };
  const handleOpen = () => {
    if (disableAddCard) {
      setOpenTooltip(true);
    }
  };

  return (
    <Box sx={{
      display: 'flex',
      justifyContent: 'space-between',
      flexWrap: 'wrap',
      rowGap: 5, // Gap applied to wrapped elements
    }}
    >
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        maxWidth: 'sm',
      }}
      >
        <Typography
          variant="bodyMediumBold"
          color="text.lighterGray"
        >
          {t('paymentMethods.managePaymentMethods')}
        </Typography>
        <Typography
          variant="bodyStandardRegular"
          color="text.secondary"
        >
          {t('paymentMethods.modifyMethods')}
        </Typography>
      </Box>
      <Box>
        <Tooltip
          title={t('paymentMethods.cantAddMoreCards')}
          placement="top"
          open={openTooltip}
          onClose={handleClose}
          onOpen={handleOpen}
        >
          {/* To accommodate disabled elements, add a wrapper (span) */}
          <span>
            <Button
              component={Link}
              to={`/${PATHS.addPaymentCard}`}
              variant="contained"
              disabled={disableAddCard}
            >
              {t('paymentMethods.addCard')}
            </Button>
          </span>
        </Tooltip>
      </Box>
    </Box>
  );
};

export default PaymentMethodsHeader;
