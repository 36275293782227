import { useMutation } from '@tanstack/react-query';
import { terminateInstance } from 'services';

const useTerminateInstance = (mutationProps = {}) => {
  const mutation = useMutation({
    mutationFn: terminateInstance,
    ...mutationProps,
  });

  return mutation;
};

export default useTerminateInstance;
