const CHART_TYPES = {
  line: 'line',
  bar: 'bar',
  radar: 'radar',
  doughnut: 'doughnut',
  pie: 'pie',
  polarArea: 'polarArea',
  bubble: 'bubble',
  scatter: 'scatter',
};

export default CHART_TYPES;
