import { Box } from '@mui/material';

import PlanHeader from './sections/PlanHeader';
import PlanStats from './sections/PlanStats';
import PlanDetailsForm from '../PlanDetailsForm/PlanDetailsForm';

const EditPlanContainer = (props) => {
  const {
    planDetails = {},
  } = props;

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }} maxWidth="xl">
      <PlanHeader planDetails={planDetails} />
      <PlanStats planDetails={planDetails} />
      <PlanDetailsForm planDetails={planDetails} />
    </Box>
  );
};

export default EditPlanContainer;
