import { Box, Stack, Typography } from '@mui/material';

import { useLocale } from 'util/hooks';
import { NoContentPlaceholder } from 'components/molecules';

import { useEventChallengeSubmissions } from 'reactQuery/queries';
import EventChallengeSubmissionCard from './EventChallengeSubmissionCard';

const EventChallengeActivity = (props) => {
  const { challengeDetails = {} } = props;
  const {
    publishedAt = '',
    firstShownAt = '',
  } = challengeDetails || {};

  const { t } = useLocale();

  const {
    data: {
      items: eventChallengeSubmissions = [],
    } = {},
  } = useEventChallengeSubmissions({
    queryKeyOptions: {
      pagination: {
        limit: 500,
      },
    },
  });

  const challengeHackers = eventChallengeSubmissions
    ?.sort((submission) => (submission.firstBlood ? -1 : 1)) // sort by firstBlood
    .map((submission) => (
      <EventChallengeSubmissionCard
        key={submission.id}
        publishedAt={publishedAt}
        firstShownAt={firstShownAt}
        {...submission}
      />
    ));

  const renderNoChallengesSolved = () => {
    const content = {
      desc: [{
        text: t('events.teams.noChallengesSolved'),
        variant: 'bodStandardRegular',
      }],
    };

    return (
      <Box mt={20}>
        <NoContentPlaceholder content={content} />
      </Box>
    );
  };

  return (
    <Stack spacing={4}>
      <Stack spacing={1}>
        <Typography
          variant="bodyMediumMedium"
          color="text.lighterGray"
        >
          {t('challenges.challengeHackers')}
        </Typography>
        <Typography
          variant="bodySmallRegular"
          color="text.secondary"
        >
          {t('events.allTeamHackersListed')}
        </Typography>
      </Stack>
      <Box>
        {challengeHackers.length ? (
          <Stack spacing={5}>
            {challengeHackers}
          </Stack>
        ) : renderNoChallengesSolved()}
      </Box>
    </Stack>

  );
};

export default EventChallengeActivity;
