import {
  Box,
  Button,
  Link,
  Stack,
  Typography,
} from '@mui/material';

import PATHS from 'routes/PATHS';
import { useLocale } from 'util/hooks';

const OnlyRegisteredUsers = () => {
  const { t } = useLocale();

  const redirectUrl = `?redirectUrl=${window.location.pathname}`;

  return (
    <Stack sx={{
      gap: 4,
      my: '10%',
      alignItems: 'center',
      justifyContent: 'center',
    }}
    >
      <Stack sx={{
        alignItems: 'center',
        justifyContent: 'center',
      }}
      >
        <Typography variant="bodyMediumMedium" color="text.primary">
          {t('events.onlyRegistered')}
        </Typography>
        <Typography variant="bodyStandardRegular" color="text.secondary">
          {t('events.signUpNowToView')}
        </Typography>
      </Stack>
      <Button
        component={Link}
        to={`/${PATHS.register}${redirectUrl}`}
        variant="contained"
      >
        {t('events.registerNow')}
      </Button>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          flexWrap: 'wrap',
        }}
      >
        <Typography variant="bodyStandardRegular">
          {t('common.haveAccount')}
          &nbsp;
          <Link to={`/${PATHS.login}${redirectUrl}`}>
            {t('common.login')}
          </Link>
        </Typography>
      </Box>
    </Stack>
  );
};

export default OnlyRegisteredUsers;
