import { useRecoilState } from 'recoil';
import { challengesFilterAtom } from 'recoil/atoms';

import { RadioGroup } from 'components/molecules';
import { useLocale } from 'util/hooks';
import VISIBILITIES from 'assets/constants/visibilities';
import ASSIGNMENTS from 'assets/constants/assignments';

const ChallengeFilter = () => {
  const { t } = useLocale();
  const [filter, setFilter] = useRecoilState(challengesFilterAtom);

  const handleFilterChange = (newFilter) => {
    setFilter((prev) => ({
      ...prev,
      value: newFilter,
    }));
  };

  const FILTER_TYPES = {
    visibility: {
      options: [{
        value: VISIBILITIES.visible,
        label: t('challenges.visibleChallenges'),
      },
      {
        value: VISIBILITIES.hidden,
        label: t('challenges.hiddenChallenges'),
      }],
    },
    assignment: {
      options: [{
        value: ASSIGNMENTS.assigned,
        label: t('challenges.assignedChallenges'),
      },
      {
        value: ASSIGNMENTS.unassigned,
        label: t('challenges.unassignedChallenges'),
      }],
    },
  };

  return (
    <RadioGroup
      value={filter.value}
      onChange={handleFilterChange}
      options={FILTER_TYPES[filter.type]?.options}
      radioProps={{
        'aria-labelledby': 'challenge-visibility-radio-buttons-group',
        name: 'challenge-visibility-radio-buttons-group',
      }}
    />
  );
};

export default ChallengeFilter;
