const countries = [
  {
    id: 1,
    nameEn: 'Afghanistan',
    code: 'AF',
    phone: '93',
    nameAr: 'أفغانستان',
    nicCode: '301',
  },
  {
    id: 2,
    nameEn: 'Alland Islands',
    code: 'AX',
    phone: '358',
    nameAr: 'جزر آلاند',
    nicCode: null,
  },
  {
    id: 3,
    nameEn: 'Albania',
    code: 'AL',
    phone: '355',
    nameAr: 'ألبانيا',
    nicCode: '502',
  },
  {
    id: 4,
    nameEn: 'Algeria',
    code: 'DZ',
    phone: '213',
    nameAr: 'الجزائر',
    nicCode: '202',
  },
  {
    id: 5,
    nameEn: 'American Samoa',
    code: 'AS',
    phone: '1-684',
    nameAr: 'ساموا الأمريكية',
    nicCode: null,
  },
  {
    id: 6,
    nameEn: 'Andorra',
    code: 'AD',
    phone: '376',
    nameAr: 'أندورا',
    nicCode: '522',
  },
  {
    id: 7,
    nameEn: 'Angola',
    code: 'AO',
    phone: '244',
    nameAr: 'أنغولا',
    nicCode: '438',
  },
  {
    id: 8,
    nameEn: 'Anguilla',
    code: 'AI',
    phone: '1-264',
    nameAr: 'أنغويلا',
    nicCode: null,
  },
  {
    id: 9,
    nameEn: 'Antarctica',
    code: 'AQ',
    phone: '672',
    nameAr: 'أنتاركتيكا',
    nicCode: null,
  },
  {
    id: 10,
    nameEn: 'Antigua and Barbuda',
    code: 'AG',
    phone: '1-268',
    nameAr: 'أنتيغوا وبربودا',
    nicCode: null,
  },
  {
    id: 11,
    nameEn: 'Argentina',
    code: 'AR',
    phone: '54',
    nameAr: 'الأرجنتين',
    nicCode: '602',
  },
  {
    id: 12,
    nameEn: 'Armenia',
    code: 'AM',
    phone: '374',
    nameAr: 'أرمينيا',
    nicCode: '539',
  },
  {
    id: 13,
    nameEn: 'Aruba',
    code: 'AW',
    phone: '297',
    nameAr: 'آروبا',
    nicCode: null,
  },
  {
    id: 14,
    nameEn: 'Australia',
    code: 'AU',
    phone: '61',
    nameAr: 'أستراليا',
    nicCode: '701',
  },
  {
    id: 15,
    nameEn: 'Austria',
    code: 'AT',
    phone: '43',
    nameAr: 'النمسا',
    nicCode: '523',
  },
  {
    id: 16,
    nameEn: 'Azerbaijan',
    code: 'AZ',
    phone: '994',
    nameAr: 'أذربيجان',
    nicCode: '343',
  },
  {
    id: 17,
    nameEn: 'Bahamas',
    code: 'BS',
    phone: '1-242',
    nameAr: 'الباهاما',
    nicCode: '612',
  },
  {
    id: 18,
    nameEn: 'Bahrain',
    code: 'BH',
    phone: '973',
    nameAr: 'البحرين',
    nicCode: '103',
  },
  {
    id: 19,
    nameEn: 'Bangladesh',
    code: 'BD',
    phone: '880',
    nameAr: 'بنجلاديش',
    nicCode: '305',
  },
  {
    id: 20,
    nameEn: 'Barbados',
    code: 'BB',
    phone: '1-246',
    nameAr: 'بربادوس',
    nicCode: '603',
  },
  {
    id: 21,
    nameEn: 'Belarus',
    code: 'BY',
    phone: '375',
    nameAr: 'بيلاروس',
    nicCode: null,
  },
  {
    id: 22,
    nameEn: 'Belgium',
    code: 'BE',
    phone: '32',
    nameAr: 'بلجيكا',
    nicCode: '509',
  },
  {
    id: 23,
    nameEn: 'Belize',
    code: 'BZ',
    phone: '501',
    nameAr: 'بليز',
    nicCode: null,
  },
  {
    id: 24,
    nameEn: 'Benin',
    code: 'BJ',
    phone: '229',
    nameAr: 'بنين',
    nicCode: '413',
  },
  {
    id: 25,
    nameEn: 'Bermuda',
    code: 'BM',
    phone: '1-441',
    nameAr: 'برمودا',
    nicCode: null,
  },
  {
    id: 26,
    nameEn: 'Bhutan',
    code: 'BT',
    phone: '975',
    nameAr: 'بوتان',
    nicCode: '324',
  },
  {
    id: 27,
    nameEn: 'Bolivia',
    code: 'BO',
    phone: '591',
    nameAr: 'بوليفيا',
    nicCode: '626',
  },
  {
    id: 28,
    nameEn: 'Bosnia and Herzegovina',
    code: 'BA',
    phone: '387',
    nameAr: 'البوسنة والهرسك',
    nicCode: '545',
  },
  {
    id: 29,
    nameEn: 'Botswana',
    code: 'BW',
    phone: '267',
    nameAr: 'بتسوانا',
    nicCode: '403',
  },
  {
    id: 30,
    nameEn: 'Bouvet Island',
    code: 'BV',
    phone: '47',
    nameAr: 'جزيرة بوفيه',
    nicCode: null,
  },
  {
    id: 31,
    nameEn: 'Brazil',
    code: 'BR',
    phone: '55',
    nameAr: 'البرازيل',
    nicCode: '604',
  },
  {
    id: 32,
    nameEn: 'British Indian Ocean Territory',
    code: 'IO',
    phone: '246',
    nameAr: 'الإقليم البريطاني في المحيط الهندي',
    nicCode: null,
  },
  {
    id: 33,
    nameEn: 'British Virgin Islands',
    code: 'VG',
    phone: '1-284',
    nameAr: 'جزر فرجين البريطانية',
    nicCode: null,
  },
  {
    id: 34,
    nameEn: 'Brunei Darussalam',
    code: 'BN',
    phone: '673',
    nameAr: 'بروناي',
    nicCode: '306',
  },
  {
    id: 35,
    nameEn: 'Bulgaria',
    code: 'BG',
    phone: '359',
    nameAr: 'بلغاريا',
    nicCode: '508',
  },
  {
    id: 36,
    nameEn: 'Burkina Faso',
    code: 'BF',
    phone: '226',
    nameAr: 'بوركينا فاسو',
    nicCode: '424',
  },
  {
    id: 37,
    nameEn: 'Burundi',
    code: 'BI',
    phone: '257',
    nameAr: 'بوروندي',
    nicCode: '404',
  },
  {
    id: 38,
    nameEn: 'Cambodia',
    code: 'KH',
    phone: '855',
    nameAr: 'كمبوديا',
    nicCode: '317',
  },
  {
    id: 39,
    nameEn: 'Cameroon',
    code: 'CM',
    phone: '237',
    nameAr: 'الكاميرون',
    nicCode: '425',
  },
  {
    id: 40,
    nameEn: 'Canada',
    code: 'CA',
    phone: '1',
    nameAr: 'كندا',
    nicCode: null,
  },
  {
    id: 41,
    nameEn: 'Cape Verde',
    code: 'CV',
    phone: '238',
    nameAr: 'الرأس الأخضر',
    nicCode: '454',
  },
  {
    id: 42,
    nameEn: 'Cayman Islands',
    code: 'KY',
    phone: '1-345',
    nameAr: 'جزر الكايمن',
    nicCode: null,
  },
  {
    id: 43,
    nameEn: 'Central African Republic',
    code: 'CF',
    phone: '236',
    nameAr: 'جمهورية أفريقيا الوسطى',
    nicCode: '437',
  },
  {
    id: 44,
    nameEn: 'Chad',
    code: 'TD',
    phone: '235',
    nameAr: 'تشاد',
    nicCode: '405',
  },
  {
    id: 45,
    nameEn: 'Chile',
    code: 'CL',
    phone: '56',
    nameAr: 'شيلي',
    nicCode: '627',
  },
  {
    id: 46,
    nameEn: 'China',
    code: 'CN',
    phone: '86',
    nameAr: 'الصين',
    nicCode: '325',
  },
  {
    id: 47,
    nameEn: 'Christmas Island',
    code: 'CX',
    phone: '61',
    nameAr: 'جزيرة كريسماس',
    nicCode: '701',
  },
  {
    id: 48,
    nameEn: 'Cocos (Keeling) Islands',
    code: 'CC',
    phone: '61',
    nameAr: 'جزر كوكوس',
    nicCode: '701',
  },
  {
    id: 49,
    nameEn: 'Colombia',
    code: 'CO',
    phone: '57',
    nameAr: 'كولومبيا',
    nicCode: '611',
  },
  {
    id: 50,
    nameEn: 'Comoros',
    code: 'KM',
    phone: '269',
    nameAr: 'جزر القمر',
    nicCode: '410',
  },
  {
    id: 51,
    nameEn: 'Congo, Democratic Republic of the',
    code: 'CG',
    phone: '243',
    nameAr: 'الكونغو الديموقراطية',
    nicCode: '426',
  },
  {
    id: 52,
    nameEn: 'Congo, Republic of the',
    code: 'CD',
    phone: '242',
    nameAr: 'الكونغو',
    nicCode: '426',
  },
  {
    id: 53,
    nameEn: 'Cook Islands',
    code: 'CK',
    phone: '682',
    nameAr: 'جزر كوك',
    nicCode: null,
  },
  {
    id: 54,
    nameEn: 'Costa Rica',
    code: 'CR',
    phone: '506',
    nameAr: 'كوستاريكا',
    nicCode: '613',
  },
  {
    id: 55,
    nameEn: "Cote d'Ivoire",
    code: 'CI',
    phone: '225',
    nameAr: 'ساحل العاج',
    nicCode: null,
  },
  {
    id: 56,
    nameEn: 'Croatia',
    code: 'HR',
    phone: '385',
    nameAr: 'كرواتيا',
    nicCode: '546',
  },
  {
    id: 57,
    nameEn: 'Cuba',
    code: 'CU',
    phone: '53',
    nameAr: 'كوبا',
    nicCode: '614',
  },
  {
    id: 58,
    nameEn: 'Curacao',
    code: 'CW',
    phone: '599',
    nameAr: 'كوراكاو',
    nicCode: null,
  },
  {
    id: 59,
    nameEn: 'Cyprus',
    code: 'CY',
    phone: '357',
    nameAr: 'قبرص',
    nicCode: '326',
  },
  {
    id: 60,
    nameEn: 'Czech Republic',
    code: 'CZ',
    phone: '420',
    nameAr: 'جمهورية التشيك',
    nicCode: '552',
  },
  {
    id: 61,
    nameEn: 'Denmark',
    code: 'DK',
    phone: '45',
    nameAr: 'الدانمرك',
    nicCode: '512',
  },
  {
    id: 62,
    nameEn: 'Djibouti',
    code: 'DJ',
    phone: '253',
    nameAr: 'جيبوتي',
    nicCode: '203',
  },
  {
    id: 63,
    nameEn: 'Dominica',
    code: 'DM',
    phone: '1-767',
    nameAr: 'دومينيكا',
    nicCode: '615',
  },
  {
    id: 64,
    nameEn: 'Dominican Republic',
    code: 'DO',
    phone: '1-809',
    nameAr: 'جمهورية الدومينيكان',
    nicCode: '615',
  },
  {
    id: 65,
    nameEn: 'Ecuador',
    code: 'EC',
    phone: '593',
    nameAr: 'الإكوادور',
    nicCode: '628',
  },
  {
    id: 66,
    nameEn: 'Egypt',
    code: 'EG',
    phone: '20',
    nameAr: 'مصر',
    nicCode: '207',
  },
  {
    id: 67,
    nameEn: 'El Salvador',
    code: 'SV',
    phone: '503',
    nameAr: 'السلفادور',
    nicCode: '617',
  },
  {
    id: 68,
    nameEn: 'Equatorial Guinea',
    code: 'GQ',
    phone: '240',
    nameAr: 'غينيا الاستوائية',
    nicCode: '422',
  },
  {
    id: 69,
    nameEn: 'Eritrea',
    code: 'ER',
    phone: '291',
    nameAr: 'أريتريا',
    nicCode: '449',
  },
  {
    id: 70,
    nameEn: 'Estonia',
    code: 'EE',
    phone: '372',
    nameAr: 'أستونيا',
    nicCode: '543',
  },
  {
    id: 71,
    nameEn: 'Ethiopia',
    code: 'ET',
    phone: '251',
    nameAr: 'إثيوبيا',
    nicCode: '401',
  },
  {
    id: 72,
    nameEn: 'Falkland Islands (Malvinas)',
    code: 'FK',
    phone: '500',
    nameAr: 'جزر فوكلاند',
    nicCode: null,
  },
  {
    id: 73,
    nameEn: 'Faroe Islands',
    code: 'FO',
    phone: '298',
    nameAr: 'جزر فارو',
    nicCode: '554',
  },
  {
    id: 74,
    nameEn: 'Fiji',
    code: 'FJ',
    phone: '679',
    nameAr: 'فيجي',
    nicCode: null,
  },
  {
    id: 75,
    nameEn: 'Finland',
    code: 'FI',
    phone: '358',
    nameAr: 'فنلندا',
    nicCode: null,
  },
  {
    id: 76,
    nameEn: 'France',
    code: 'FR',
    phone: '33',
    nameAr: 'فرنسا',
    nicCode: '516',
  },
  {
    id: 77,
    nameEn: 'French Guiana',
    code: 'GF',
    phone: '594',
    nameAr: 'غويانا الفرنسية',
    nicCode: null,
  },
  {
    id: 78,
    nameEn: 'French Polynesia',
    code: 'PF',
    phone: '689',
    nameAr: 'بولينيزيا الفرنسية',
    nicCode: null,
  },
  {
    id: 79,
    nameEn: 'French Southern Territories',
    code: 'TF',
    phone: '262',
    nameAr: 'المقاطعات الجنوبية الفرنسية',
    nicCode: null,
  },
  {
    id: 80,
    nameEn: 'Gabon',
    code: 'GA',
    phone: '241',
    nameAr: 'الجابون',
    nicCode: '408',
  },
  {
    id: 81,
    nameEn: 'Gambia',
    code: 'GM',
    phone: '220',
    nameAr: 'غامبيا',
    nicCode: '409',
  },
  {
    id: 82,
    nameEn: 'Georgia',
    code: 'GE',
    phone: '995',
    nameAr: 'جورجيا',
    nicCode: '541',
  },
  {
    id: 83,
    nameEn: 'Germany',
    code: 'DE',
    phone: '49',
    nameAr: 'ألمانيا',
    nicCode: '503',
  },
  {
    id: 84,
    nameEn: 'Ghana',
    code: 'GH',
    phone: '233',
    nameAr: 'غانا',
    nicCode: '421',
  },
  {
    id: 85,
    nameEn: 'Gibraltar',
    code: 'GI',
    phone: '350',
    nameAr: 'جبل طارق',
    nicCode: '534',
  },
  {
    id: 86,
    nameEn: 'Greece',
    code: 'GR',
    phone: '30',
    nameAr: 'اليونان',
    nicCode: '521',
  },
  {
    id: 87,
    nameEn: 'Greenland',
    code: 'GL',
    phone: '299',
    nameAr: 'غرينلاند',
    nicCode: null,
  },
  {
    id: 88,
    nameEn: 'Grenada',
    code: 'GD',
    phone: '1-473',
    nameAr: 'غرينادا',
    nicCode: '618',
  },
  {
    id: 89,
    nameEn: 'Guadeloupe',
    code: 'GP',
    phone: '590',
    nameAr: 'جوادلوب',
    nicCode: null,
  },
  {
    id: 90,
    nameEn: 'Guam',
    code: 'GU',
    phone: '1-671',
    nameAr: 'غوام',
    nicCode: null,
  },
  {
    id: 91,
    nameEn: 'Guatemala',
    code: 'GT',
    phone: '502',
    nameAr: 'غواتيمالا',
    nicCode: '619',
  },
  {
    id: 92,
    nameEn: 'Guernsey',
    code: 'GG',
    phone: '44',
    nameAr: 'غيرنزي',
    nicCode: '506',
  },
  {
    id: 93,
    nameEn: 'Guinea-Bissau',
    code: 'GW',
    phone: '245',
    nameAr: 'غينيا بيساو',
    nicCode: '422',
  },
  {
    id: 94,
    nameEn: 'Guinea',
    code: 'GN',
    phone: '224',
    nameAr: 'غينيا',
    nicCode: '422',
  },
  {
    id: 95,
    nameEn: 'Guyana',
    code: 'GY',
    phone: '592',
    nameAr: 'غيانا',
    nicCode: null,
  },
  {
    id: 96,
    nameEn: 'Haiti',
    code: 'HT',
    phone: '509',
    nameAr: 'هايتي',
    nicCode: '620',
  },
  {
    id: 97,
    nameEn: 'Heard Island and McDonald Islands',
    code: 'HM',
    phone: '672',
    nameAr: 'جزيرة هيرد وجزر ماكدونالد',
    nicCode: null,
  },
  {
    id: 98,
    nameEn: 'Holy See (Vatican City State)',
    code: 'VA',
    phone: '379',
    nameAr: 'الفاتيكان',
    nicCode: '533',
  },
  {
    id: 99,
    nameEn: 'Honduras',
    code: 'HN',
    phone: '504',
    nameAr: 'هندوراس',
    nicCode: '621',
  },
  {
    id: 100,
    nameEn: 'Hong Kong',
    code: 'HK',
    phone: '852',
    nameAr: 'هونغ كونغ الصينية',
    nicCode: '322',
  },
  {
    id: 101,
    nameEn: 'Hungary',
    code: 'HU',
    phone: '36',
    nameAr: 'هنغاريا',
    nicCode: '525',
  },
  {
    id: 102,
    nameEn: 'Iceland',
    code: 'IS',
    phone: '354',
    nameAr: 'أيسلندا',
    nicCode: '526',
  },
  {
    id: 103,
    nameEn: 'India',
    code: 'IN',
    phone: '91',
    nameAr: 'الهند',
    nicCode: '321',
  },
  {
    id: 104,
    nameEn: 'Indonesia',
    code: 'ID',
    phone: '62',
    nameAr: 'أندونيسيا',
    nicCode: '302',
  },
  {
    id: 105,
    nameEn: 'Iran, Islamic Republic of',
    code: 'IR',
    phone: '98',
    nameAr: 'إيران',
    nicCode: '303',
  },
  {
    id: 106,
    nameEn: 'Iraq',
    code: 'IQ',
    phone: '964',
    nameAr: 'العراق',
    nicCode: '105',
  },
  {
    id: 107,
    nameEn: 'Ireland',
    code: 'IE',
    phone: '353',
    nameAr: 'أيرلندا',
    nicCode: '504',
  },
  {
    id: 108,
    nameEn: 'Isle of Man',
    code: 'IM',
    phone: '44',
    nameAr: 'جزيرة مان',
    nicCode: '506',
  },
  {
    id: 110,
    nameEn: 'Italy',
    code: 'IT',
    phone: '39',
    nameAr: 'إيطاليا',
    nicCode: '505',
  },
  {
    id: 111,
    nameEn: 'Jamaica',
    code: 'JM',
    phone: '1-876',
    nameAr: 'جامايكا',
    nicCode: '607',
  },
  {
    id: 112,
    nameEn: 'Japan',
    code: 'JP',
    phone: '81',
    nameAr: 'اليابان',
    nicCode: '323',
  },
  {
    id: 113,
    nameEn: 'Jersey',
    code: 'JE',
    phone: '44',
    nameAr: 'جيرزي',
    nicCode: '506',
  },
  {
    id: 114,
    nameEn: 'Jordan',
    code: 'JO',
    phone: '962',
    nameAr: 'الأردن',
    nicCode: '102',
  },
  {
    id: 115,
    nameEn: 'Kazakhstan',
    code: 'KZ',
    phone: '7',
    nameAr: 'كازاخستان',
    nicCode: '311',
  },
  {
    id: 116,
    nameEn: 'Kenya',
    code: 'KE',
    phone: '254',
    nameAr: 'كينيا',
    nicCode: '427',
  },
  {
    id: 117,
    nameEn: 'Kiribati',
    code: 'KI',
    phone: '686',
    nameAr: 'كيريباتي',
    nicCode: null,
  },
  {
    id: 118,
    nameEn: "Korea, Democratic People's Republic of",
    code: 'KP',
    phone: '850',
    nameAr: 'كوريا الشمالية',
    nicCode: '328',
  },
  {
    id: 119,
    nameEn: 'Korea, Republic of',
    code: 'KR',
    phone: '82',
    nameAr: 'كوريا الجنوبية',
    nicCode: '318',
  },
  {
    id: 120,
    nameEn: 'Kosovo',
    code: 'XK',
    phone: '383',
    nameAr: 'كوسوفو',
    nicCode: null,
  },
  {
    id: 121,
    nameEn: 'Kuwait',
    code: 'KW',
    phone: '965',
    nameAr: 'الكويت',
    nicCode: '109',
  },
  {
    id: 122,
    nameEn: 'Kyrgyzstan',
    code: 'KG',
    phone: '996',
    nameAr: 'قرغيزستان',
    nicCode: '340',
  },
  {
    id: 123,
    nameEn: "Lao People's Democratic Republic",
    code: 'LA',
    phone: '856',
    nameAr: 'لاوس',
    nicCode: '329',
  },
  {
    id: 124,
    nameEn: 'Latvia',
    code: 'LV',
    phone: '371',
    nameAr: 'لاتفيا',
    nicCode: '544',
  },
  {
    id: 125,
    nameEn: 'Lebanon',
    code: 'LB',
    phone: '961',
    nameAr: 'لبنان',
    nicCode: '110',
  },
  {
    id: 126,
    nameEn: 'Lesotho',
    code: 'LS',
    phone: '266',
    nameAr: 'ليسوتو',
    nicCode: '428',
  },
  {
    id: 127,
    nameEn: 'Liberia',
    code: 'LR',
    phone: '231',
    nameAr: 'ليبيريا',
    nicCode: '429',
  },
  {
    id: 128,
    nameEn: 'Libya',
    code: 'LY',
    phone: '218',
    nameAr: 'ليبيا',
    nicCode: '206',
  },
  {
    id: 129,
    nameEn: 'Liechtenstein',
    code: 'LI',
    phone: '423',
    nameAr: 'ليختنشتاين',
    nicCode: '527',
  },
  {
    id: 130,
    nameEn: 'Lithuania',
    code: 'LT',
    phone: '370',
    nameAr: 'ليتوانيا',
    nicCode: '542',
  },
  {
    id: 131,
    nameEn: 'Luxembourg',
    code: 'LU',
    phone: '352',
    nameAr: 'لوكسمبورغ',
    nicCode: '528',
  },
  {
    id: 132,
    nameEn: 'Macao',
    code: 'MO',
    phone: '853',
    nameAr: 'ماكاو',
    nicCode: null,
  },
  {
    id: 133,
    nameEn: 'Macedonia, the Former Yugoslav Republic of',
    code: 'MK',
    phone: '389',
    nameAr: 'مقدونيا',
    nicCode: '549',
  },
  {
    id: 134,
    nameEn: 'Madagascar',
    code: 'MG',
    phone: '261',
    nameAr: 'مدغشقر',
    nicCode: null,
  },
  {
    id: 135,
    nameEn: 'Malawi',
    code: 'MW',
    phone: '265',
    nameAr: 'ملاوي',
    nicCode: '432',
  },
  {
    id: 136,
    nameEn: 'Malaysia',
    code: 'MY',
    phone: '60',
    nameAr: 'ماليزيا',
    nicCode: '319',
  },
  {
    id: 137,
    nameEn: 'Maldives',
    code: 'MV',
    phone: '960',
    nameAr: 'جزر المالديف',
    nicCode: '310',
  },
  {
    id: 138,
    nameEn: 'Mali',
    code: 'ML',
    phone: '223',
    nameAr: 'مالي',
    nicCode: '430',
  },
  {
    id: 139,
    nameEn: 'Malta',
    code: 'MT',
    phone: '356',
    nameAr: 'مالطا',
    nicCode: '529',
  },
  {
    id: 140,
    nameEn: 'Marshall Islands',
    code: 'MH',
    phone: '692',
    nameAr: 'جزر المارشال',
    nicCode: null,
  },
  {
    id: 141,
    nameEn: 'Martinique',
    code: 'MQ',
    phone: '596',
    nameAr: 'مارتينيك',
    nicCode: null,
  },
  {
    id: 142,
    nameEn: 'Mauritania',
    code: 'MR',
    phone: '222',
    nameAr: 'موريتانيا',
    nicCode: '209',
  },
  {
    id: 143,
    nameEn: 'Mauritius',
    code: 'MU',
    phone: '230',
    nameAr: 'موريشيوس',
    nicCode: '433',
  },
  {
    id: 144,
    nameEn: 'Mayotte',
    code: 'YT',
    phone: '262',
    nameAr: 'مايوت',
    nicCode: null,
  },
  {
    id: 145,
    nameEn: 'Mexico',
    code: 'MX',
    phone: '52',
    nameAr: 'المكسيك',
    nicCode: '622',
  },
  {
    id: 146,
    nameEn: 'Micronesia, Federated States of',
    code: 'FM',
    phone: '691',
    nameAr: 'ميكرونيزيا',
    nicCode: null,
  },
  {
    id: 147,
    nameEn: 'Moldova, Republic of',
    code: 'MD',
    phone: '373',
    nameAr: 'مولدافيا',
    nicCode: '540',
  },
  {
    id: 148,
    nameEn: 'Monaco',
    code: 'MC',
    phone: '377',
    nameAr: 'موناكو',
    nicCode: '530',
  },
  {
    id: 149,
    nameEn: 'Mongolia',
    code: 'MN',
    phone: '976',
    nameAr: 'منغوليا',
    nicCode: '330',
  },
  {
    id: 150,
    nameEn: 'Montenegro',
    code: 'ME',
    phone: '382',
    nameAr: 'الجبل الأسود',
    nicCode: '524',
  },
  {
    id: 151,
    nameEn: 'Montserrat',
    code: 'MS',
    phone: '1-664',
    nameAr: 'مونتسرات',
    nicCode: null,
  },
  {
    id: 152,
    nameEn: 'Morocco',
    code: 'MA',
    phone: '212',
    nameAr: 'المغرب',
    nicCode: null,
  },
  {
    id: 153,
    nameEn: 'Mozambique',
    code: 'MZ',
    phone: '258',
    nameAr: 'موزمبيق',
    nicCode: '434',
  },
  {
    id: 154,
    nameEn: 'Myanmar',
    code: 'MM',
    phone: '95',
    nameAr: 'ميانمار',
    nicCode: '307',
  },
  {
    id: 155,
    nameEn: 'Namibia',
    code: 'NA',
    phone: '264',
    nameAr: 'ناميبيا',
    nicCode: '412',
  },
  {
    id: 156,
    nameEn: 'Nauru',
    code: 'NR',
    phone: '674',
    nameAr: 'ناورو',
    nicCode: null,
  },
  {
    id: 157,
    nameEn: 'Nepal',
    code: 'NP',
    phone: '977',
    nameAr: 'نيبال',
    nicCode: '320',
  },
  {
    id: 158,
    nameEn: 'Netherlands',
    code: 'NL',
    phone: '31',
    nameAr: 'هولندا',
    nicCode: '519',
  },
  {
    id: 159,
    nameEn: 'New Caledonia',
    code: 'NC',
    phone: '687',
    nameAr: 'كاليدونيا الجديدة',
    nicCode: null,
  },
  {
    id: 160,
    nameEn: 'New Zealand',
    code: 'NZ',
    phone: '64',
    nameAr: 'نيوزيلاندا',
    nicCode: '702',
  },
  {
    id: 161,
    nameEn: 'Nicaragua',
    code: 'NI',
    phone: '505',
    nameAr: 'نيكاراغوا',
    nicCode: '623',
  },
  {
    id: 162,
    nameEn: 'Niger',
    code: 'NE',
    phone: '227',
    nameAr: 'النيجر',
    nicCode: '436',
  },
  {
    id: 163,
    nameEn: 'Nigeria',
    code: 'NG',
    phone: '234',
    nameAr: 'نيجيريا',
    nicCode: '436',
  },
  {
    id: 164,
    nameEn: 'Niue',
    code: 'NU',
    phone: '683',
    nameAr: 'نيوي',
    nicCode: null,
  },
  {
    id: 165,
    nameEn: 'Norfolk Island',
    code: 'NF',
    phone: '672',
    nameAr: 'جزيرة نورفولك',
    nicCode: null,
  },
  {
    id: 166,
    nameEn: 'Northern Mariana Islands',
    code: 'MP',
    phone: '1-670',
    nameAr: 'جزر ماريانا الشمالية',
    nicCode: null,
  },
  {
    id: 167,
    nameEn: 'Norway',
    code: 'NO',
    phone: '47',
    nameAr: 'النرويج',
    nicCode: null,
  },
  {
    id: 168,
    nameEn: 'Oman',
    code: 'OM',
    phone: '968',
    nameAr: 'عُمان',
    nicCode: '106',
  },
  {
    id: 169,
    nameEn: 'Pakistan',
    code: 'PK',
    phone: '92',
    nameAr: 'باكستان',
    nicCode: '304',
  },
  {
    id: 170,
    nameEn: 'Palau',
    code: 'PW',
    phone: '680',
    nameAr: 'بالاو',
    nicCode: null,
  },
  {
    id: 171,
    nameEn: 'Palestine',
    code: 'PS',
    phone: '970',
    nameAr: 'فلسطين',
    nicCode: '107',
  },
  {
    id: 172,
    nameEn: 'Panama',
    code: 'PA',
    phone: '507',
    nameAr: 'بنما',
    nicCode: '605',
  },
  {
    id: 173,
    nameEn: 'Papua New Guinea',
    code: 'PG',
    phone: '675',
    nameAr: 'بابوا غينيا الجديدة',
    nicCode: null,
  },
  {
    id: 174,
    nameEn: 'Paraguay',
    code: 'PY',
    phone: '595',
    nameAr: 'باراغواي',
    nicCode: '629',
  },
  {
    id: 175,
    nameEn: 'Peru',
    code: 'PE',
    phone: '51',
    nameAr: 'بيرو',
    nicCode: '630',
  },
  {
    id: 176,
    nameEn: 'Philippines',
    code: 'PH',
    phone: '63',
    nameAr: 'الفلبين',
    nicCode: '315',
  },
  {
    id: 177,
    nameEn: 'Pitcairn',
    code: 'PN',
    phone: '870',
    nameAr: 'جزر بيتكيرن',
    nicCode: null,
  },
  {
    id: 178,
    nameEn: 'Poland',
    code: 'PL',
    phone: '48',
    nameAr: 'بولندا',
    nicCode: '510',
  },
  {
    id: 179,
    nameEn: 'Portugal',
    code: 'PT',
    phone: '351',
    nameAr: 'البرتغال',
    nicCode: '507',
  },
  {
    id: 180,
    nameEn: 'Puerto Rico',
    code: 'PR',
    phone: '1',
    nameAr: 'بورتوريكو',
    nicCode: null,
  },
  {
    id: 181,
    nameEn: 'Qatar',
    code: 'QA',
    phone: '974',
    nameAr: 'قطر',
    nicCode: '108',
  },
  {
    id: 182,
    nameEn: 'Reunion',
    code: 'RE',
    phone: '262',
    nameAr: 'لا ريونيون',
    nicCode: null,
  },
  {
    id: 183,
    nameEn: 'Romania',
    code: 'RO',
    phone: '40',
    nameAr: 'رومانيا',
    nicCode: '513',
  },
  {
    id: 184,
    nameEn: 'Russian Federation',
    code: 'RU',
    phone: '7',
    nameAr: 'روسيا',
    nicCode: '311',
  },
  {
    id: 185,
    nameEn: 'Rwanda',
    code: 'RW',
    phone: '250',
    nameAr: 'رواندا',
    nicCode: '414',
  },
  {
    id: 186,
    nameEn: 'Saint Barthelemy',
    code: 'BL',
    phone: '590',
    nameAr: 'سان بارتيلمي',
    nicCode: null,
  },
  {
    id: 187,
    nameEn: 'Saint Helena',
    code: 'SH',
    phone: '290',
    nameAr: 'سانت هيلنا',
    nicCode: null,
  },
  {
    id: 188,
    nameEn: 'Saint Kitts and Nevis',
    code: 'KN',
    phone: '1-869',
    nameAr: 'سانت كيتس ونيفيس',
    nicCode: null,
  },
  {
    id: 189,
    nameEn: 'Saint Lucia',
    code: 'LC',
    phone: '1-758',
    nameAr: 'سانت لوسيا',
    nicCode: '624',
  },
  {
    id: 190,
    nameEn: 'Saint Martin (French part)',
    code: 'MF',
    phone: '590',
    nameAr: 'تجمع سان مارتين',
    nicCode: null,
  },
  {
    id: 191,
    nameEn: 'Saint Pierre and Miquelon',
    code: 'PM',
    phone: '508',
    nameAr: 'سانت بيير وميكولون',
    nicCode: null,
  },
  {
    id: 192,
    nameEn: 'Saint Vincent and the Grenadines',
    code: 'VC',
    phone: '1-784',
    nameAr: 'سانت فنسنت وغرنادين',
    nicCode: '625',
  },
  {
    id: 193,
    nameEn: 'Samoa',
    code: 'WS',
    phone: '685',
    nameAr: 'ساموا',
    nicCode: null,
  },
  {
    id: 194,
    nameEn: 'San Marino',
    code: 'SM',
    phone: '378',
    nameAr: 'سان مارينو',
    nicCode: '532',
  },
  {
    id: 195,
    nameEn: 'Sao Tome and Principe',
    code: 'ST',
    phone: '239',
    nameAr: 'ساو تومي وبرينسيبي',
    nicCode: null,
  },
  {
    id: 196,
    nameEn: 'Saudi Arabia',
    code: 'SA',
    phone: '966',
    nameAr: 'المملكة العربية السعودية',
    nicCode: '113',
  },
  {
    id: 197,
    nameEn: 'Senegal',
    code: 'SN',
    phone: '221',
    nameAr: 'السنغال',
    nicCode: '419',
  },
  {
    id: 198,
    nameEn: 'Serbia',
    code: 'RS',
    phone: '381',
    nameAr: 'صربيا',
    nicCode: '518',
  },
  {
    id: 199,
    nameEn: 'Seychelles',
    code: 'SC',
    phone: '248',
    nameAr: 'سيشل',
    nicCode: '443',
  },
  {
    id: 200,
    nameEn: 'Sierra Leone',
    code: 'SL',
    phone: '232',
    nameAr: 'سيراليون',
    nicCode: '420',
  },
  {
    id: 201,
    nameEn: 'Singapore',
    code: 'SG',
    phone: '65',
    nameAr: 'سنغافورة',
    nicCode: '312',
  },
  {
    id: 202,
    nameEn: 'Sint Maarten (Dutch part)',
    code: 'SX',
    phone: '1-721',
    nameAr: 'سينت مارتن',
    nicCode: null,
  },
  {
    id: 203,
    nameEn: 'Slovakia',
    code: 'SK',
    phone: '421',
    nameAr: 'سلوفاكيا',
    nicCode: '553',
  },
  {
    id: 204,
    nameEn: 'Slovenia',
    code: 'SI',
    phone: '386',
    nameAr: 'سلوفينيا',
    nicCode: '547',
  },
  {
    id: 205,
    nameEn: 'Solomon Islands',
    code: 'SB',
    phone: '677',
    nameAr: 'جزر سليمان',
    nicCode: null,
  },
  {
    id: 206,
    nameEn: 'Somalia',
    code: 'SO',
    phone: '252',
    nameAr: 'الصومال',
    nicCode: '205',
  },
  {
    id: 207,
    nameEn: 'South Africa',
    code: 'ZA',
    phone: '27',
    nameAr: 'جنوب أفريقيا',
    nicCode: '411',
  },
  {
    id: 208,
    nameEn: 'South Georgia and the South Sandwich Islands',
    code: 'GS',
    phone: '500',
    nameAr: 'جورجيا الجنوبية وجزر ساندويتش الجنوبية',
    nicCode: null,
  },
  {
    id: 209,
    nameEn: 'South Sudan',
    code: 'SS',
    phone: '211',
    nameAr: 'جنوب السودان',
    nicCode: '204',
  },
  {
    id: 210,
    nameEn: 'Spain',
    code: 'ES',
    phone: '34',
    nameAr: 'إسبانيا',
    nicCode: '501',
  },
  {
    id: 211,
    nameEn: 'Sri Lanka',
    code: 'LK',
    phone: '94',
    nameAr: 'سريلانكا',
    nicCode: '313',
  },
  {
    id: 212,
    nameEn: 'Sudan',
    code: 'SD',
    phone: '249',
    nameAr: 'السودان',
    nicCode: '204',
  },
  {
    id: 213,
    nameEn: 'Suriname',
    code: 'SR',
    phone: '597',
    nameAr: 'سورينام',
    nicCode: null,
  },
  {
    id: 214,
    nameEn: 'Svalbard and Jan Mayen',
    code: 'SJ',
    phone: '47',
    nameAr: 'سفالبارد وجان مايان',
    nicCode: null,
  },
  {
    id: 215,
    nameEn: 'Swaziland',
    code: 'SZ',
    phone: '268',
    nameAr: 'سوازيلاند',
    nicCode: '444',
  },
  {
    id: 216,
    nameEn: 'Sweden',
    code: 'SE',
    phone: '46',
    nameAr: 'السويد',
    nicCode: '514',
  },
  {
    id: 217,
    nameEn: 'Switzerland',
    code: 'CH',
    phone: '41',
    nameAr: 'سويسرا',
    nicCode: '515',
  },
  {
    id: 218,
    nameEn: 'Syrian Arab Republic',
    code: 'SY',
    phone: '963',
    nameAr: 'سوريا',
    nicCode: '104',
  },
  {
    id: 219,
    nameEn: 'Taiwan, Province of China',
    code: 'TW',
    phone: '886',
    nameAr: 'تايوان',
    nicCode: null,
  },
  {
    id: 220,
    nameEn: 'Tajikistan',
    code: 'TJ',
    phone: '992',
    nameAr: 'طاجكستان',
    nicCode: '339',
  },
  {
    id: 221,
    nameEn: 'Thailand',
    code: 'TH',
    phone: '66',
    nameAr: 'تايلاند',
    nicCode: '308',
  },
  {
    id: 222,
    nameEn: 'Timor-Leste',
    code: 'TL',
    phone: '670',
    nameAr: 'تيمور الشرقية',
    nicCode: '349',
  },
  {
    id: 223,
    nameEn: 'Togo',
    code: 'TG',
    phone: '228',
    nameAr: 'توجو',
    nicCode: '407',
  },
  {
    id: 224,
    nameEn: 'Tokelau',
    code: 'TK',
    phone: '690',
    nameAr: 'توكيلو',
    nicCode: null,
  },
  {
    id: 225,
    nameEn: 'Tonga',
    code: 'TO',
    phone: '676',
    nameAr: 'تونغا',
    nicCode: null,
  },
  {
    id: 226,
    nameEn: 'Trinidad and Tobago',
    code: 'TT',
    phone: '1-868',
    nameAr: 'ترينيداد وتوباغو',
    nicCode: '606',
  },
  {
    id: 227,
    nameEn: 'Tunisia',
    code: 'TN',
    phone: '216',
    nameAr: 'تونس',
    nicCode: '201',
  },
  {
    id: 228,
    nameEn: 'Turkey',
    code: 'TR',
    phone: '90',
    nameAr: 'تركيا',
    nicCode: '309',
  },
  {
    id: 229,
    nameEn: 'Turkmenistan',
    code: 'TM',
    phone: '993',
    nameAr: 'تركمانستان',
    nicCode: '338',
  },
  {
    id: 230,
    nameEn: 'Turks and Caicos Islands',
    code: 'TC',
    phone: '1-649',
    nameAr: 'جزر الترك وجايكوس',
    nicCode: null,
  },
  {
    id: 231,
    nameEn: 'Tuvalu',
    code: 'TV',
    phone: '688',
    nameAr: 'توفالو',
    nicCode: null,
  },
  {
    id: 232,
    nameEn: 'Uganda',
    code: 'UG',
    phone: '256',
    nameAr: 'أوغندا',
    nicCode: '402',
  },
  {
    id: 233,
    nameEn: 'Ukraine',
    code: 'UA',
    phone: '380',
    nameAr: 'أوكرانيا',
    nicCode: '536',
  },
  {
    id: 234,
    nameEn: 'United Arab Emirates',
    code: 'AE',
    phone: '971',
    nameAr: 'الإمارات العربية المتحدة',
    nicCode: '101',
  },
  {
    id: 235,
    nameEn: 'United Kingdom',
    code: 'GB',
    phone: '44',
    nameAr: 'المملكة المتحدة',
    nicCode: '506',
  },
  {
    id: 236,
    nameEn: 'United Republic of Tanzania',
    code: 'TZ',
    phone: '255',
    nameAr: 'تانزانيا',
    nicCode: '406',
  },
  {
    id: 237,
    nameEn: 'United States',
    code: 'US',
    phone: '1',
    nameAr: 'الولايات المتحدة',
    nicCode: null,
  },
  {
    id: 238,
    nameEn: 'Uruguay',
    code: 'UY',
    phone: '598',
    nameAr: 'أورغواي',
    nicCode: null,
  },
  {
    id: 239,
    nameEn: 'US Virgin Islands',
    code: 'VI',
    phone: '1-340',
    nameAr: 'جزر فرجين الأمريكية',
    nicCode: null,
  },
  {
    id: 240,
    nameEn: 'Uzbekistan',
    code: 'UZ',
    phone: '998',
    nameAr: 'أوزبكستان',
    nicCode: '337',
  },
  {
    id: 241,
    nameEn: 'Vanuatu',
    code: 'VU',
    phone: '678',
    nameAr: 'فانواتو',
    nicCode: null,
  },
  {
    id: 242,
    nameEn: 'Venezuela',
    code: 'VE',
    phone: '58',
    nameAr: 'فنزويلا',
    nicCode: '609',
  },
  {
    id: 243,
    nameEn: 'Vietnam',
    code: 'VN',
    phone: '84',
    nameAr: 'فيتنام',
    nicCode: '316',
  },
  {
    id: 244,
    nameEn: 'Wallis and Futuna',
    code: 'WF',
    phone: '681',
    nameAr: 'جزر والس وفوتونا',
    nicCode: null,
  },
  {
    id: 245,
    nameEn: 'Western Sahara',
    code: 'EH',
    phone: '212',
    nameAr: 'الصحراء الغربية',
    nicCode: null,
  },
  {
    id: 246,
    nameEn: 'Yemen',
    code: 'YE',
    phone: '967',
    nameAr: 'اليمن',
    nicCode: '111',
  },
  {
    id: 247,
    nameEn: 'Zambia',
    code: 'ZM',
    phone: '260',
    nameAr: 'زامبيا',
    nicCode: '417',
  },
  {
    id: 248,
    nameEn: 'Zimbabwe',
    code: 'ZW',
    phone: '263',
    nameAr: 'زيمبابوي',
    nicCode: '415',
  },
];

export default countries;
