import END_POINTS from '../endPoints';
import downloadApi from '../downloadApi';

const exportEventReport = ({ eventId, eventName }) => downloadApi({
  path: END_POINTS.exportEventReport(eventId),
  fileName: `${eventName} Report.csv`,
})
  .then((response) => Promise.resolve(response))
  .catch((error) => Promise.reject(error));

export default exportEventReport;
