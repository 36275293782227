import { useState } from 'react';
import { Box, Stack } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { useLocale, useSnackbar } from 'util/hooks';
import { InstanceTable } from 'components/tables';
import { useTerminateInstance } from 'reactQuery/mutations';
import { handleApiErrors } from 'util/helpers';
import { useInstances } from 'reactQuery/queries';

const InstanceList = (props) => {
  const { type } = props;

  const { t } = useLocale();
  const snack = useSnackbar();
  const [rowSelectionModel, setRowSelectionModel] = useState([]);

  const query = useInstances({ type });
  const {
    mutate: terminateInstances,
    isLoading,
  } = useTerminateInstance({
    onSuccess: () => {
      snack({
        message: t('instances.terminateSuccess'),
        severity: 'success',
      });
    },
    onError: (error) => {
      handleApiErrors({ error, snack });
    },
  });

  const handleTerminate = () => {
    rowSelectionModel.forEach((instance) => terminateInstances(instance));
  };

  return (
    <Stack>
      <Box sx={{ textAlign: 'end' }}>
        <LoadingButton
          loading={isLoading}
          variant="contained"
          onClick={handleTerminate}
          disabled={isLoading || !rowSelectionModel?.length}
        >
          {t('actions.terminate')}
        </LoadingButton>
      </Box>
      <InstanceTable
        type={type}
        query={query}
        rowSelectionModel={rowSelectionModel}
        setRowSelectionModel={setRowSelectionModel}
      />
    </Stack>
  );
};

export default InstanceList;
