import { Box } from '@mui/material';
import VISIBILITIES from 'assets/constants/visibilities';

import { useLocale } from 'util/hooks';
import StatusBadge from '../StatusBadge/StatusBadge';

const VisibilityBadge = (props) => {
  const { visible = true } = props;

  const visibilityString = visible ? VISIBILITIES.visible : VISIBILITIES.hidden;

  const { t } = useLocale();

  return (
    <Box sx={{
      display: 'flex',
      alignItems: 'center',
    }}
    >
      <Box sx={{
        display: 'flex',
        width: 14,
        height: 14,
      }}
      >
        <StatusBadge status={visibilityString} />
      </Box>
      <Box sx={{
        ml: 2,
      }}
      >
        {t(`visibilities.${visibilityString}`)}
      </Box>
    </Box>
  );
};

export default VisibilityBadge;
