import END_POINTS from 'services/endPoints';
import api from '../api';

const getEventChallengeDetails = (eventId, challengeId) => api(
  END_POINTS.eventChallenge(eventId, challengeId),
)
  .then((res) => Promise.resolve(res))
  .catch((error) => Promise.reject(error));

export default getEventChallengeDetails;
