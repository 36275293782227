import { getAllAnnouncements } from 'services';
import { useCustomQuery } from 'reactQuery';

const useAnnouncements = (queryProps = {}) => {
  const query = useCustomQuery({
    queryKey: ['announcements'],
    queryFn: getAllAnnouncements,
    ...queryProps,
  });

  return { ...query };
};

export default useAnnouncements;
