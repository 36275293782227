import { Box, Typography } from '@mui/material';
import { useLocale } from 'util/hooks';

const ProfileActivityHeader = (props) => {
  const { t } = useLocale();

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Typography
        variant="bodyMediumMedium"
        color="text.lighterGray"
      >
        {t('profile.accountActivity')}
      </Typography>
      <Typography
        variant="bodySmallRegular"
        color="text.secondary"
      >
        {t('profile.accountActivityDesc')}
      </Typography>
    </Box>
  );
};

export default ProfileActivityHeader;
