import PropTypes from 'prop-types';

import { Box, Button, Typography } from '@mui/material';
import { DownloadIcon } from 'assets/icons';
import { useLocale } from 'util/hooks';

const UploadedFile = (props) => {
  const {
    sx = {},
    url = '',
    name = '',
    canDelete = true,
    onDelete = () => { },
  } = props;

  const { t } = useLocale();

  const styles = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    ...sx,
  };

  return (
    <Box sx={styles}>
      <Box sx={{
        display: 'flex',
        alignItems: 'center',
      }}
      >
        <Box mr={3}>
          <DownloadIcon />
        </Box>
        <Box>
          <a
            href={url}
            target="_blank"
            rel="noreferrer noopener"
            style={{ textDecoration: 'none' }}
          >
            <Typography
              variant="bodyStandardBold"
              color="text.raspberry"
            >
              {name}
            </Typography>
          </a>
        </Box>
      </Box>
      {canDelete
        && (
          <Box>
            <Button
              size="small"
              color="secondary"
              onClick={onDelete}
            >
              {t('common.delete')}
            </Button>
          </Box>
        )}
    </Box>
  );
};

UploadedFile.propTypes = {
  sx: PropTypes.oneOfType([PropTypes.object]),
  url: PropTypes.string,
  name: PropTypes.string,
  canDelete: PropTypes.bool,
  onDelete: PropTypes.func,
};

UploadedFile.defaultProps = {
  sx: {},
  url: '',
  name: '',
  canDelete: true,
  onDelete: () => { },
};

export default UploadedFile;
