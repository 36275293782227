import { useState } from 'react';
import {
  Box,
  Menu,
  MenuItem,
  IconButton,
  Link,
  Typography,
  ListItemIcon,
} from '@mui/material';

import {
  AvatarWithFallback,
  Tag,
  TypographyWithIcon,
} from 'components/molecules';

import {
  TrashBinIcon,
  WithdrawIcon,
  SparklingIcon,
  OutLineStarIcon,
  TableActionIcon,
  ArrowCircleIcon,
} from 'assets/icons';

import MODAL_KEYS from 'assets/constants/modalKeys';
import {
  useLocale,
  useModal,
  usePermission,
} from 'util/hooks';
import MEMBER_ACTIONS from 'assets/constants/memberActions';
import { useEventDetails } from 'reactQuery/queries';
import { useTheme } from '@mui/styles';
import PERMISSIONS from 'assets/constants/permissions';

const iconSize = { width: 16, height: 16 };

const TeamMemberInfoCard = (props) => {
  const {
    teamMember = {},
    currentUserUsername = '',
  } = props;

  const { t } = useLocale();
  const { palette } = useTheme();
  const { addModal } = useModal();
  const [anchorEl, setAnchorEl] = useState(null);
  const canEditTeamAsManager = usePermission(PERMISSIONS.teamViewOneByName);

  const {
    data: eventDetails = {},
  } = useEventDetails();

  const {
    eventHasEnded,
    eventHasStarted,
  } = eventDetails;

  const {
    player,
    isCaptain: isTeamMemberCaptain,
    isCurrentUserCaptain,
    points = 0,
  } = teamMember;

  const {
    avatar = null,
    username,
  } = player;

  const actions = [];
  const open = Boolean(anchorEl);
  const playerIsCurrentUser = currentUserUsername === username;
  const showMenuButton = (
    isCurrentUserCaptain
    || playerIsCurrentUser
    || canEditTeamAsManager
  ) && !eventHasEnded;

  const showCaptainActions = isCurrentUserCaptain && !isTeamMemberCaptain && !eventHasEnded;

  const handleClick = (e) => {
    setAnchorEl(e.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleModal = (type) => {
    const isMove = type === MEMBER_ACTIONS.move;

    addModal({
      key: isMove
        ? MODAL_KEYS.eventMemberMove
        : MODAL_KEYS.eventMemberActions,
      props: {
        type,
        teamMember,
      },
    });
    handleClose();
  };

  if (playerIsCurrentUser && !eventHasStarted) {
    actions.push(
      {
        id: 'withdraw',
        label: t('events.withdraw'),
        icon: <WithdrawIcon {...iconSize} />,
        onClick: () => handleModal(MEMBER_ACTIONS.withdraw),
      },
    );
  }

  if (showCaptainActions || (canEditTeamAsManager && !isTeamMemberCaptain)) {
    actions.push(
      {
        id: 'setCaptain',
        label: t('events.setCaptain'),
        icon: <OutLineStarIcon {...iconSize} />,
        onClick: () => handleModal(MEMBER_ACTIONS.setCaptain),
      },
    );
  }

  if ((showCaptainActions || canEditTeamAsManager) && !eventHasStarted) {
    actions.push(
      {
        id: 'remove',
        label: t('events.removeMember'),
        icon: <TrashBinIcon {...iconSize} />,
        onClick: () => handleModal(MEMBER_ACTIONS.remove),
      },
    );
  }

  if (canEditTeamAsManager && !eventHasStarted) {
    actions.push(
      {
        id: 'move',
        label: t('events.moveMember'),
        icon: <ArrowCircleIcon
          {...iconSize}
          stroke={palette.text.secondary}
        />,
        onClick: () => handleModal(MEMBER_ACTIONS.move),
      },
    );
  }

  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: {
          xs: 'repeat(1, 1fr)',
          sm: 'repeat(2, 1fr)',
        },
        minWidth: 1,
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <Box
        component={Link}
        sx={{
          display: 'flex',
          alignItems: 'center',
          minWidth: 200,
        }}
        to={`/profile/${username}`}
      >
        <AvatarWithFallback
          sx={{ mr: 4 }}
          src={avatar}
          alt={username}
        />
        <Typography sx={{ mr: 2, color: 'text.primary' }} variant="bodyStandardMedium">
          {username}
        </Typography>
        {isTeamMemberCaptain && (
          <Tag
            label={t('events.captain')}
            size="small"
            sx={{ color: 'text.blue', backgroundColor: 'text.transparentBlue' }}
          />
        )}
      </Box>
      <Box sx={{ display: 'flex' }}>
        <Box
          component={Link}
          sx={{ display: 'flex', minWidth: 80, flex: 1 }}
          to={`/profile/${username}`}
        >
          <TypographyWithIcon
            label={points ?? 0}
            icon={(<SparklingIcon />)}
            iconProps={{
              mx: 0,
              mr: 2,
              width: 16,
              height: 16,
            }}
            labelProps={{ variant: 'bodyStandardMedium' }}
          />
        </Box>
        {!!actions.length && showMenuButton && (
          <Box sx={{
            display: 'flex',
            minWidth: 40,
            flex: 1,
            justifyContent: 'center',
          }}
          >
            <IconButton onClick={handleClick} aria-label="actions menu">
              <TableActionIcon />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
            >
              {actions?.map((item) => (
                <Box key={item.id} sx={{ width: 180 }}>
                  <MenuItem disableRipple onClick={item.onClick} disabled={item.disabled}>
                    <ListItemIcon sx={iconSize}>
                      {item.icon}
                    </ListItemIcon>
                    <Typography variant="bodySmallMedium">{item.label}</Typography>
                  </MenuItem>
                </Box>
              ))}
            </Menu>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default TeamMemberInfoCard;
