import END_POINTS from '../endPoints';
import api from '../api';

const getEventDetails = (eventId, options = {}) => api(
  END_POINTS.event(eventId),
  {
    ...options,
  },
)
  .then((res) => Promise.resolve(res))
  .catch((error) => Promise.reject(error));

export default getEventDetails;
