import { Box, Stack } from '@mui/material';
import { LockedBackdrop } from 'components/molecules';

import { STATUS } from 'assets/constants/statuses';
import { useIsEvent, useAuth } from 'util/hooks';
import ChallengeDetailsCards from './ChallengeDetailsCards';
import FlagSubmissionCard from './helpers/FlagSubmissionCard';

const ChallengeDetails = (props) => {
  const { challengeDetails = {} } = props;
  const {
    status = '',
    isDetailsLocked = false,
  } = challengeDetails;

  const { isVerified } = useAuth();

  const isRetired = status === STATUS.retired;
  const showLockedBackdrop = isDetailsLocked || isRetired || !isVerified;
  const isEventChallenge = useIsEvent();

  return (
    <Stack
      gap={6}
      sx={{
        position: 'relative',
      }}
    >
      {showLockedBackdrop && (
      <LockedBackdrop
        withUpgradeButton={!isEventChallenge}
        withVerificationButton={!isVerified}
      />
      )}
      <Box>
        <ChallengeDetailsCards
          showLockedBackdrop={showLockedBackdrop}
          challengeDetails={challengeDetails}
        />
      </Box>
      <Box>
        <FlagSubmissionCard showLockedBackdrop={showLockedBackdrop} />
      </Box>
    </Stack>
  );
};

export default ChallengeDetails;
