import {
  useEffect,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import { useController } from 'react-hook-form';

import {
  Box,
  Button,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { generatePresignedUrl, uploadFileToS3 } from 'services';
import { useLocale, useSnackbar } from 'util/hooks';
import { AvatarWithFallback } from 'components/molecules';

const FormAvatar = (props) => {
  const {
    avatar,
    entityName,
    disabled,
    ...rest
  } = props;

  const { field } = useController(rest);
  const { onChange } = field;

  const { t } = useLocale();
  const snack = useSnackbar();

  const [currentAvatar, setCurrentAvatar] = useState(avatar);
  const [isUploadLoading, setIsUploadLoading] = useState(false);

  useEffect(() => () => {
    // Make sure to revoke the data uris to avoid memory leaks
    URL.revokeObjectURL(currentAvatar?.preview);
  }, [currentAvatar]);

  const uploadToS3 = async (newAvatar) => {
    setIsUploadLoading(true);
    try {
      // Step 1 - get url and fileName
      const { uploadUrl, fileName } = await generatePresignedUrl();
      // Step 2 - upload to S3
      await uploadFileToS3(uploadUrl, newAvatar);
      // Step 3 - update form with fileName
      onChange(fileName);
    } catch (error) {
      snack({
        message: error.message || t('common.errorMessage'),
        severity: 'error',
      });
    }
    setIsUploadLoading(false);
  };

  const deleteAvatar = () => {
    setCurrentAvatar('');
    onChange('');
  };

  // ========= Dropzone =========

  const onDrop = async (acceptedFile) => {
    const newAvatar = Object.assign(acceptedFile[0], {
      preview: URL.createObjectURL(acceptedFile[0]),
    });

    await uploadToS3(newAvatar);
    setCurrentAvatar(newAvatar?.preview);
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      // 'image/*': ['.jpeg', '.jpg', '.png'],
      'image/png': ['.png'],
      'image/jpg': ['.jpg'],
      'image/jpeg': ['.jpeg'],
    },
    multiple: false,
    onDrop,
  });

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        my: 7,
      }}
    >
      <AvatarWithFallback
        src={currentAvatar || entityName}
        alt={entityName}
        sx={{
          width: { xs: 45, sm: 60 },
          height: { xs: 45, sm: 60 },
          fontWeight: 'bold',
          fontSize: 23,
        }}
      />
      <Box sx={{
        display: 'flex',
        m: { xs: 2, sm: 5 },
      }}
      >
        <Box {...getRootProps()}>
          <input
            {...getInputProps()}
          />
          <LoadingButton
            size="small"
            variant="outlined"
            color="secondary"
            disabled={disabled}
            loading={isUploadLoading}
          >
            {t('common.upload')}
          </LoadingButton>
        </Box>
        <Button
          onClick={deleteAvatar}
          color="secondary"
          size="small"
          disabled={disabled || !currentAvatar}
          sx={{
            p: 0,
            ml: { xs: 1, sm: 5 },
          }}
        >
          {t('common.delete')}
        </Button>
      </Box>
    </Box>
  );
};

FormAvatar.propTypes = {
  avatar: PropTypes.string,
  entityName: PropTypes.string,
  disabled: PropTypes.bool,
  setUpdateError: PropTypes.func,
};

FormAvatar.defaultProps = {
  avatar: '',
  entityName: '',
  disabled: false,
  setUpdateError: () => { },
};

export default FormAvatar;
