import END_POINTS from '../../endPoints';
import api from '../../api';

const playerGetLeaderboardTeamDetails = (name, teamId) => api(
  END_POINTS.playerLeaderboardTeamDetails(name, teamId),
)
  .then((res) => Promise.resolve(res))
  .catch((error) => Promise.reject(error));

export default playerGetLeaderboardTeamDetails;
