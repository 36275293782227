import END_POINTS from '../endPoints';
import METHODS from '../methods';
import api from '../api';

const createEventChallenge = (data, eventId) => api(
  END_POINTS.eventChallenges(eventId),
  {
    method: METHODS.POST,
    data,
  },
)
  .then((res) => Promise.resolve(res))
  .catch((error) => Promise.reject(error));

export default createEventChallenge;
