import { Box, Stack } from '@mui/material';

import { SuspenseWrapper } from 'components/render';
import PlayerDashboardStats from './sections/PlayerDashboardStats';
import PlayerAnnouncements from './sections/PlayerAnnouncements';
import PlayerLatestChallengesMachines from './sections/PlayerLatestChallengesMachines';
import PlayerLatestActivity from './sections/PlayerLatestActivity';

const PlayerDashboardContainer = () => (
  <Box>
    <Stack spacing={8}>
      <Box>
        <PlayerDashboardStats />
      </Box>
      <Box sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexWrap: 'wrap',
        columnGap: 14,
        rowGap: 14,
      }}
      >
        <Box sx={{
          flex: 1,
          width: 1,
          height: 302,
        }}
        >
          <SuspenseWrapper>
            <PlayerAnnouncements />
          </SuspenseWrapper>
        </Box>
        <Box sx={{
          flex: 1,
          width: 1,
          height: 302,
        }}
        >
          <SuspenseWrapper>
            <PlayerLatestChallengesMachines />
          </SuspenseWrapper>
        </Box>
      </Box>
      <Box>
        <SuspenseWrapper>
          <PlayerLatestActivity />
        </SuspenseWrapper>
      </Box>
    </Stack>
  </Box>
);

export default PlayerDashboardContainer;
