import { useTheme } from '@mui/styles';

const EmailUnreadIcon = (props) => {
  const theme = useTheme();
  const {
    fill = theme.palette.text.primary,
    ...rest
  } = props;

  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <g clipPath="url(#clip0_7419_116954)">
        <path d="M19.9067 4.86003C19.8788 4.84821 19.8481 4.84498 19.8184 4.85077C19.7887 4.85656 19.7614 4.87111 19.74 4.89253L11.6175 13.0134C11.1878 13.4429 10.6051 13.6841 9.9975 13.6841C9.38994 13.6841 8.80724 13.4429 8.3775 13.0134L0.258335 4.89253C0.237091 4.87091 0.209795 4.85623 0.180043 4.85043C0.150291 4.84463 0.119479 4.84798 0.0916683 4.86003C0.0643406 4.8718 0.0410789 4.89135 0.0247795 4.91625C0.00848017 4.94114 -0.000136756 4.97028 1.6413e-06 5.00003V15C1.6413e-06 15.4421 0.175596 15.866 0.488157 16.1785C0.800718 16.4911 1.22464 16.6667 1.66667 16.6667H18.3333C18.7754 16.6667 19.1993 16.4911 19.5118 16.1785C19.8244 15.866 20 15.4421 20 15V5.00003C20.0001 4.97007 19.9913 4.94074 19.9747 4.91581C19.958 4.89088 19.9344 4.87146 19.9067 4.86003Z" fill={fill} />
        <path d="M9.2622 12.1306C9.45778 12.3257 9.72269 12.4352 9.99887 12.4352C10.275 12.4352 10.54 12.3257 10.7355 12.1306L18.8072 4.05898C18.8618 4.00434 18.9002 3.93557 18.918 3.86037C18.9358 3.78517 18.9324 3.7065 18.908 3.63315C18.8164 3.35898 18.5614 3.33398 18.333 3.33398H1.66637C1.4372 3.33398 1.18054 3.35898 1.09054 3.63315C1.0662 3.7065 1.06274 3.78517 1.08055 3.86037C1.09835 3.93557 1.13672 4.00434 1.19137 4.05898L9.2622 12.1306Z" fill={fill} />
      </g>
      <defs>
        <clipPath id="clip0_7419_116954">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default EmailUnreadIcon;
