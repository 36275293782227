import { useTheme } from '@mui/styles';

const TwitterIcon = (props) => {
  const theme = useTheme();
  const {
    fill = theme.palette.text.primary,
    ...rest
  } = props;

  return (
    <svg width="22" height="18" viewBox="0 0 22 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <path d="M19.4956 4.56925C19.5088 4.76072 19.5088 4.95219 19.5088 5.14542C19.5088 11.0334 15.0265 17.824 6.83029 17.824V17.8204C4.40912 17.824 2.03824 17.1304 0 15.8228C0.352059 15.8651 0.705882 15.8863 1.06059 15.8872C3.06706 15.889 5.01618 15.2157 6.59471 13.976C4.68794 13.9398 3.01588 12.6966 2.43176 10.8816C3.09971 11.0104 3.78794 10.984 4.44353 10.8048C2.36471 10.3848 0.869118 8.55836 0.869118 6.43719C0.869118 6.41778 0.869118 6.39925 0.869118 6.38072C1.48853 6.72572 2.18206 6.91719 2.89147 6.93837C0.933529 5.62984 0.33 3.02513 1.51235 0.98866C3.77471 3.77248 7.11265 5.46484 10.6959 5.64395C10.3368 4.09631 10.8274 2.47454 11.985 1.3866C13.7797 -0.300458 16.6024 -0.213987 18.2894 1.57984C19.2874 1.38307 20.2438 1.0169 21.1191 0.498072C20.7865 1.52954 20.0903 2.40572 19.1603 2.96248C20.0435 2.85837 20.9065 2.6219 21.7191 2.26101C21.1209 3.15748 20.3674 3.93837 19.4956 4.56925Z" fill={fill} />
    </svg>

  );
};

export default TwitterIcon;
