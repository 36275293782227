import END_POINTS from 'services/endPoints';
import api from '../api';

const getPromoCodeDetails = (id) => api(
  END_POINTS.promoCode(id),
)
  .then((res) => Promise.resolve(res))
  .catch((error) => Promise.reject(error));

export default getPromoCodeDetails;
