import {
  Box,
  Button,
  Tooltip,
} from '@mui/material';

import PERMISSIONS from 'assets/constants/permissions';
import {
  useLocale,
  useAuth,
  usePermission,
  useModal,
} from 'util/hooks';
import {
  useLabChallengeDetails,
  useLabDetails,
} from 'reactQuery/queries';

import { STATUS } from 'assets/constants/statuses';
import MODAL_KEYS from 'assets/constants/modalKeys';
import { useRecoilValue } from 'recoil';
import { challengesFilterAtom } from 'recoil/atoms';

import VISIBILITIES from 'assets/constants/visibilities';
import ASSIGNMENTS from 'assets/constants/assignments';

const ChallengeActions = (props) => {
  // Modal
  const { addModal } = useModal();

  const filter = useRecoilValue(challengesFilterAtom);

  const {
    data: labDetails = {},
  } = useLabDetails();
  const {
    data: challengeDetails = {},
  } = useLabChallengeDetails();

  const {
    listView = false, // Entity state (bulk or single action)
    ...rest
  } = props;

  const {
    status = listView ? labDetails?.status : challengeDetails?.status,
    challenges = listView ? [] : [challengeDetails],
  } = rest;

  const { t } = useLocale();
  const {
    currentUser: { username = '' } = {},
  } = useAuth();

  // Tab state
  const isActiveTab = status === STATUS.active;
  const isDraftTab = status === STATUS.draft;
  const isPendingTab = status === STATUS.pending;
  const isRetiredTab = status === STATUS.retired;

  // User permissions
  const canPublish = usePermission(PERMISSIONS.challengesPublish);
  const canAssign = usePermission(PERMISSIONS.challengesAssignPlan);
  const canRetire = usePermission(PERMISSIONS.challengesRetire);
  const canDraft = usePermission(PERMISSIONS.challengesDraft);
  const canArchive = usePermission(PERMISSIONS.challengesArchive);
  const canViewHidden = usePermission(PERMISSIONS.challengesViewHidden);

  // Challenge conditions
  const isOwnChallenge = challenges.every((row) => row.createdBy === username);
  const isActionDisabled = usePermission(PERMISSIONS.challengesEditOwn) && !isOwnChallenge;

  // Filter (radio) state
  const noRowsSelected = !challenges?.length;
  const isVisible = filter?.value === VISIBILITIES.visible;
  const isAssigned = filter?.value === ASSIGNMENTS.assigned;

  // =========================|| Button Permissions ||========================= //
  // Retire: (active) AND admin, technical admin, lab manager(own only)
  const showRetireButton = isActiveTab && canRetire;

  // Publish: (pending) AND admin, business admin
  const showPublishButton = isPendingTab && isAssigned && canPublish;
  // Submit: (draft) AND technical admin, lab manager(own only)
  const showSubmitButton = isDraftTab && canDraft;

  // Assign: (pending) AND admin, business admin, account manager (for unassigned challenges)
  const showAssignButton = (isActiveTab || isPendingTab) && canAssign;
  // Visibility: (active OR retired) AND admin, technical admin, lab manager(own only)
  const showVisibilityButton = (isActiveTab || isRetiredTab)
    && canViewHidden;

  // Archive: (active OR retired) AND admin, technical admin, lab manager(own only)
  const showArchiveButton = (isActiveTab || isRetiredTab)
    && canArchive;

  // =========================|| Handlers ||========================= //
  const handleButtonClick = (type, value) => {
    addModal({
      key: MODAL_KEYS.challenge,
      props: {
        type,
        value,
        challenges,
        listView,
      },
    });
  };

  // =========================|| Buttons ||========================= //
  const buttonSx = { mx: 2 };
  const buttons = [
    {
      key: 'retireButton',
      show: showRetireButton,
      disabled: isActionDisabled,
      variant: 'contained',
      onClick: () => handleButtonClick('retire'),
      label: listView
        ? t('challenges.actions.retireSelected')
        : t('challenges.actions.retire'),
    },
    {
      key: 'publishButton',
      show: showPublishButton,
      variant: 'contained',
      onClick: () => handleButtonClick('publish'),
      label: listView
        ? t('challenges.actions.publishSelected')
        : t('challenges.actions.publish'),
    },
    {
      key: 'submitButton',
      show: showSubmitButton,
      disabled: isActionDisabled,
      variant: 'contained',
      onClick: () => handleButtonClick('submit'),
      label: listView
        ? t('challenges.actions.submitSelected')
        : t('challenges.actions.submit'),
    },
    {
      key: 'assignButton',
      show: listView && showAssignButton,
      variant: 'contained',
      onClick: () => handleButtonClick('setPlan'),
      label: listView
        ? t('challenges.actions.assignSelected')
        : t('challenges.actions.assign'),
    },
    {
      key: 'visibilityButton',
      show: showVisibilityButton,
      disabled: isActionDisabled,
      variant: 'outlined',
      color: 'secondary',
      onClick: () => handleButtonClick(isVisible ? 'hide' : 'show', !isVisible),
      label: t(`challenges.actions.${isVisible ? 'hide' : 'show'}${listView ? 'Selected' : ''}`),
    },
    {
      key: 'archiveButton',
      show: showArchiveButton,
      disabled: isActionDisabled,
      color: 'secondary',
      onClick: () => handleButtonClick('archive'),
      label: listView
        ? t('challenges.actions.archiveSelected')
        : t('challenges.actions.archive'),
    },
  ];

  const renderActionButton = ({ button = {}, disabled = false }) => (
    <span>
      <Button
        sx={buttonSx}
        disabled={noRowsSelected || disabled}
        {...button}
      >
        {button.label}
      </Button>
    </span>
  );

  const renderActionButtonWithTooltip = ({ button, disabled }) => (
    <Tooltip
      title={t('challenges.actions.cantClick')}
      placement="top"
    >
      {renderActionButton({ button, disabled })}
    </Tooltip>
  );

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: { xs: 'column', sm: 'row' },
      justifyContent: 'end',
    }}
    >
      {buttons?.map(({
        key, show, disabled, ...button
      }) => (
        show && (
          <Box sx={{ mt: { xs: 4, sm: 0 } }} key={key}>
            {disabled
              ? (renderActionButtonWithTooltip({ button, disabled }))
              : (renderActionButton({ button, disabled }))}
          </Box>
        )
      ))}
    </Box>
  );
};

export default ChallengeActions;
