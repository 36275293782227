import {
  Box,
  Grid,
  Tooltip,
  Typography,
} from '@mui/material';

import { useAuth, useLocale } from 'util/hooks';

import { InfoCard } from 'components/molecules';

import {
  InformationCircleIcon,
  RankFilledIcon,
  UserFilledIcon,
} from 'assets/icons';
import { PlayerLinearLevelProgress } from 'components/organisms';
import OnlinePlayersCard from '../helpers/OnlinePlayersCard';

const PlayerDashboardStats = () => {
  const { t } = useLocale();
  const { currentUser = {} } = useAuth();
  const {
    rank = 0,
    playerLevel = {},
    totalPoints = 0,
  } = currentUser || {};

  const noRankPlaceholder = (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Typography variant="bodyStandardBold" sx={{ mr: 2 }}>
        {t('dashboard.unranked')}
      </Typography>
      <Tooltip
        title={t('dashboard.unrankedHint')}
        placement="top"
      >
        <Box sx={{ display: 'flex', width: 14, height: 14 }}>
          <InformationCircleIcon width="14" height="14" />
        </Box>
      </Tooltip>
    </Box>
  );

  const stats = [{
    key: 'player-level-progress',
    cardSx: { p: 2 },
    component: (
      <PlayerLinearLevelProgress
        progressDetails={{ playerLevel, totalPoints }}
      />
    ),
  },
  {
    key: 'player-rank',
    icon: <RankFilledIcon width="45" height="45" />,
    title: t('common.rank'),
    component: (
      rank
        ? (
          <Typography variant="bodyStandardBold">
            {`#${rank}`}
          </Typography>
        ) : noRankPlaceholder
    ),
  },
  {
    key: 'online-players',
    icon: <UserFilledIcon width="45" height="45" />,
    title: t('dashboard.onlinePlayers'),
    component: <OnlinePlayersCard />,
  }];

  return (
    <Box>
      <Grid container spacing={6}>
        {stats.map(({ cardSx, ...stat }) => (
          <InfoCard
            size="small"
            cardSx={{
              minWidth: 256,
              ...cardSx,
            }}
            {...stat} // allow for overrides
          />
        ))}
      </Grid>
    </Box>
  );
};

export default PlayerDashboardStats;
