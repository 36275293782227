import { useEffect } from 'react';
import { useRecoilState } from 'recoil';
import _ from 'lodash';

import { Box, Stack, Typography } from '@mui/material';

import { checkoutAtom } from 'recoil/atoms';
import { getPlanPrice } from 'services';
import { numberWithCommas } from 'util/helpers';
import { useAsyncService, useLocale, useSnackbar } from 'util/hooks';
import { TagIcon } from 'assets/icons';

const PriceRow = (props) => {
  const { priceItem } = props;
  const { t } = useLocale();

  const value = _.isNumber(priceItem.value)
    ? numberWithCommas(_.round(priceItem.value, 2)) // round to 2 decimal places
    : priceItem.value;

  const isTotal = priceItem.key === 'total';
  const typographyProps = {
    variant: isTotal
      ? 'bodyStandardBold'
      : 'bodyStandardRegular',
    color: isTotal
      ? 'lighterGray'
      : 'lightGray',
  };

  return (
    <Box
      sx={{
        width: 1,
        display: 'flex',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
      }}
    >
      <Typography
        {...typographyProps}
      >
        {priceItem.label}
      </Typography>
      <Typography
        {...typographyProps}
      >
        {`${value} ${t('currency.sar')}`}
      </Typography>
    </Box>
  );
};

const PriceBreakdown = () => {
  const [checkoutState, setCheckoutState] = useRecoilState(checkoutAtom);
  const snack = useSnackbar();
  const { t } = useLocale();
  const {
    data: planPriceBreakdown,
    execute,
  } = useAsyncService({
    service: getPlanPrice,
  });

  const {
    billingOption,
    promoCode,
    planPrice,
    plan = {},
  } = checkoutState || {};

  const {
    subTotal = 0, // no vat, no discount
    total = 0, // with vat, with discount
    priceWithVat = 0, // with vat, no discount
    vatPercentage = 0,
    vatAmount = 0,
    discountAmount = 0,
  } = planPrice || {};

  const {
    code,
    discountPercentage,
  } = promoCode || {};
  const isPromoCodeApplied = code && discountPercentage;

  useEffect(() => {
    setCheckoutState((prev) => ({
      ...prev,
      isCheckoutLoading: false,
      planPrice: planPriceBreakdown,
    }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [planPriceBreakdown]);

  useEffect(() => {
    const fetchPlanPrice = async () => {
      // Handle switching to monthly while having an active promo code
      if (isPromoCodeApplied && billingOption === 'perMonth') {
        return;
      }

      // Disable checkout card and show loader
      setCheckoutState((prev) => ({
        ...prev,
        isCheckoutLoading: true,
      }));

      try {
        const payload = {
          plan,
          billingOption,
        };

        if (code) {
          payload.promoCode = {
            code,
          };
        }

        await execute(plan?.id, payload);
      } catch (error) {
        const { errors = [] } = error;
        snack({
          message: errors?.[0]?.message || error.message,
          severity: 'error',
        });

        setCheckoutState((prev) => ({
          ...prev,
          isCheckoutLoading: false,
        }));
      }
    };

    fetchPlanPrice();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [billingOption, promoCode, isPromoCodeApplied, plan, code, execute]);

  const breakdown = [
    {
      key: 'subtotal',
      label: t('checkout.subtotal'),
      value: subTotal,
    },
    {
      key: 'vat',
      label: `${t('checkout.vat')} ${100 * vatPercentage}%`,
      value: vatAmount,
    },
    {
      key: 'total',
      label: t('checkout.total'),
      value: total,
    },
  ];

  if (isPromoCodeApplied) {
    const label = (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ mr: 1 }}>
          {t('checkout.discount')}
        </Box>
        <Box sx={{ display: 'flex' }}>
          (&nbsp;
          <Box sx={{ width: 13, height: 13, mr: 1 }}>
            <TagIcon width="13" height="13" />
          </Box>
          <Box>
            {code}
          </Box>
          &nbsp;)
        </Box>
      </Box>
    );

    // Insert discount at index 2
    breakdown.splice(2, 0, {
      key: 'discount',
      label,
      value: ` (${_.round(100 * discountPercentage, 2)}%) -${discountAmount}`,
    });
  }

  const firstPaymentPrice = _.round(total, 2);
  const remainingPaymentsPrice = isPromoCodeApplied
    ? _.round(priceWithVat, 2)
    : firstPaymentPrice;

  return (
    <Box>
      <Stack spacing={5}>
        {breakdown.map((priceItem) => (
          <PriceRow
            key={priceItem.key}
            priceItem={priceItem}
          />
        ))}
      </Stack>
      <Box sx={{ mt: 5 }}>
        <Typography
          variant="bodyStandardRegular"
          color="text.secondary"
        >
          {`${t('checkout.checkoutHint.youWillBeCharged')}
           ${numberWithCommas(firstPaymentPrice)} ${t('currency.sar')} ${t('common.now')}. 
          ${t('checkout.checkoutHint.youWillBeCharged')}
           ${numberWithCommas(remainingPaymentsPrice)} ${t('currency.sar')}
            ${t(`checkout.checkoutHint.${billingOption}`)}
            ${t('checkout.checkoutHint.thereafter')}`}
        </Typography>
      </Box>
    </Box>
  );
};

export default PriceBreakdown;
