import { useEffect, useState } from 'react';
import useLocale from './useLocale';

const useCountDown = (date = new Date()) => {
  const { t } = useLocale();

  const targetDate = (new Date(date)).getTime();

  const [timeDifference, setTimeDifference] = useState(targetDate - (new Date()).getTime());

  const calculateTimeDifference = () => {
    // Divide the difference (in milliseconds) by the number of milliseconds in one day
    const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    // Divide the difference (in milliseconds) by the number of milliseconds in one hour
    const hours = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    // Divide the difference (in milliseconds) by the number of milliseconds in one minute
    const minutes = Math.floor((timeDifference / 1000 / 60) % 60);
    // Divide the difference (in milliseconds) by the number of milliseconds in one second
    const seconds = Math.floor((timeDifference / 1000) % 60);

    if (timeDifference > 0) {
      return ([
        { label: t('common.days'), labelShort: t('time.day'), time: days },
        { label: t('common.hours'), labelShort: t('time.hour'), time: hours },
        { label: t('common.minutes'), labelShort: t('time.minute'), time: minutes },
        { label: t('common.seconds'), labelShort: t('time.second'), time: seconds }]);
    }

    return null;
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeDifference(targetDate - (new Date()).getTime());
    }, 1000);

    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeDifference]);

  return calculateTimeDifference(timeDifference);
};

export default useCountDown;
