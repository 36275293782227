import {
  Badge, Box, Card, Stack, Typography,
} from '@mui/material';
import { BloodDropIcon, FlagIcon, SparklingIcon } from 'assets/icons';
import { leaderboardRank1, leaderboardRank2, leaderboardRank3 } from 'assets/images';
import { AvatarWithFallback, TypographyWithIcon } from 'components/molecules';
import { useXLargeView } from 'util/hooks';

const gap = 10;
const breakpoint = 'lg';

const Top3Element = (props) => {
  const {
    team = {},
    handleRowClick = () => {},
    showStats = true,
    ...rest
  } = props;

  const {
    id,
    name,
    rank,
    logo,
  } = team;

  const top3RankImage = {
    1: leaderboardRank1,
    2: leaderboardRank2,
    3: leaderboardRank3,
  };

  const top3RankBorderColor = {
    1: '#DEB16D',
    2: '#D2D3DA',
    3: '#8A3613',
  };

  const points = [
    { key: 'points', icon: SparklingIcon },
    { key: 'numberOfSolves', icon: FlagIcon },
    { key: 'firstBloods', icon: BloodDropIcon },
  ];

  const isXLargeView = useXLargeView();

  const typographySize = isXLargeView
    ? 'bodyLargeMedium'
    : 'bodyStandardMedium';

  const typographyWithIconStyle = {
    iconProps: {
      mx: 0,
      mr: 2,
      width: 20,
      height: 20,
    },
    labelProps: { variant: typographySize },
  };

  return (
    <Stack
      sx={{
        pt: gap,
        gap: 3,
        alignItems: 'center',
        mt: {
          xs: 0,
          [breakpoint]: (rank === 1 ? -(2 * gap) : 0),
        },
      }}
      {...rest}
    >
      <Badge
        overlap="circular"
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        badgeContent={(
          <img
            src={top3RankImage[rank]}
            alt={`rank-${rank}`}
            width="75"
            height="75"
          />
        )}
        sx={{
          '& .MuiBadge-badge': {
            right: '50%',
            transform: 'translate(50%, 150%)', // Fine-tune the position
          },
        }}
      >
        <AvatarWithFallback
          src={logo}
          alt={name}
          sx={{
            width: 150,
            height: 150,
            border: `6px solid ${top3RankBorderColor[rank]}`,
          }}
        />
      </Badge>
      <Box
        onClick={() => handleRowClick(null, id)}
        sx={{
          mt: 5,
          '&:hover': {
            cursor: 'pointer',
          },
        }}
      >
        <Typography
          variant={typographySize}
          nowrap
          sx={{
            maxWidth: 220,
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            display: 'block',
            textOverflow: 'ellipsis',
          }}
        >
          {name}
        </Typography>
      </Box>
      {showStats && (
      <Card sx={{
        px: 9,
        py: 3,
        gap: 5,
        minWidth: 265,
        display: 'flex',
        justifyContent: 'center',
      }}
      >
        {points.map(({ key, icon: Icon }) => (
          <TypographyWithIcon
            key={key}
            label={team[key] || 0}
            icon={(
              <Icon
                width="20"
                height="20"
              />
      )}
            {...typographyWithIconStyle}
          />
        ))}
      </Card>
      )}
    </Stack>
  );
};
export default Top3Element;
