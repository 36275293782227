import { DataList } from 'components/core';
import { PATHS } from 'routes';

import { useMemo } from 'react';
import { STATUS } from 'assets/constants/statuses';
import useListColumns from './useListColumns';

const PlayerChallengeList = (props) => {
  const { status = '', challenges = [] } = props;

  const isRetiredList = status === STATUS.retired;

  const columns = useListColumns({ isPlayer: true, isRetiredList });
  const rows = useMemo(() => challenges, [challenges]);

  return (
    <DataList
      rows={rows}
      columns={columns}
      withSearch={false}
      checkboxSelection={false}
      useLinkRow
      componentsProps={{
        row: { toPath: PATHS.challenges },
        noRowsOverlay: { type: 'challenges' },
      }}
      headerHeight={0}
      sx={{
        '& .MuiDataGrid-row': {
          pl: 8,
        },
      }}
    />
  );
};

export default PlayerChallengeList;
