import _ from 'lodash';
import {
  Chip, Link,
} from '@mui/material';
import { GridActionsCellItem } from '@mui/x-data-grid';

import { PATHS } from 'routes';
import { getStatusByName, STATUS } from 'assets/constants/statuses';
import { CheckCircleOutline, OpenEyeIcon } from 'assets/icons';
import {
  useDate, useLocale, useModal, usePermission,
} from 'util/hooks';
import PERMISSIONS from 'assets/constants/permissions';
import MODAL_KEYS from 'assets/constants/modalKeys';

const useTableColumns = (props) => {
  const { fetchPromoCodes } = props;

  // Modal
  const { addModal } = useModal();
  const { t, dir } = useLocale();
  const { formatDate } = useDate();

  const canPublish = usePermission(PERMISSIONS.promoCodePublish);

  const columns = [
    {
      field: 'code',
      headerName: t('promoCodes.code'),
      sortable: false,
      flex: 1,
      minWidth: 100,
    },
    {
      field: 'discountPercentage',
      headerName: t('promoCodes.discountPercentage'),
      flex: 1,
      valueGetter: ({ row: { discountPercentage } }) => `${_.round(100 * discountPercentage, 2)}%`,
    },
    {
      field: 'startDate',
      headerName: t('common.startDate'),
      flex: 1,
      minWidth: 130,
      valueGetter: ({ row: { startDate } }) => formatDate(startDate),
    },
    {
      field: 'endDate',
      headerName: t('common.endDate'),
      flex: 1,
      minWidth: 130,
      valueGetter: ({ row: { endDate } }) => formatDate(endDate),
    },
    {
      field: 'status',
      headerName: t('common.status'),
      flex: 1,
      minWidth: 100,
      renderCell: (params) => (
        <Chip
          size="small"
          color={params.row.status || ''}
          label={t(getStatusByName(params?.row?.status)?.localizationKey) || ''}
        />
      ),
    },
    {
      field: 'actions',
      headerName: t('common.actions'),
      type: 'actions',
      flex: 1,
      minWidth: 50,
      getActions: (params) => {
        const { row: { status, id } } = params;

        const isDraft = status === STATUS.draft;
        const showPublishButton = canPublish && isDraft;

        const actions = [
          <GridActionsCellItem
            component={Link}
            dir={dir}
            icon={<OpenEyeIcon width="20" height="20" />}
            label={t('promoCodes.view')}
            to={`/${PATHS.promoCodes}/${id}`}
            sx={{ minWidth: 180 }}
            showInMenu
          />,
        ];

        if (showPublishButton) {
          actions.push(
            <GridActionsCellItem
              dir={dir}
              icon={(
                <CheckCircleOutline
                  width="20"
                  height="20"
                />
              )}
              label={t('promoCodes.publish')}
              onClick={() => addModal({
                key: MODAL_KEYS.promoCode,
                props: {
                  type: 'publish',
                  fetchPromoCodes,
                  ...params?.row,
                },
              })}
              sx={{ minWidth: 180 }}
              showInMenu
            />,
          );
        }

        return actions;
      },
    },
  ];

  return columns;
};

export default useTableColumns;
