import React from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@tanstack/react-query';
import { useSearchParams } from 'react-router-dom';
import Exception from 'util/Exception';
import ERRORS_TYPES from 'assets/constants/errorsTypes';

const defaultPagination = {
  page: 1,
  limit: 15,
};

const defaultOptions = {
  keepPreviousData: true,
  retry: (error) => (!error.statusCode === ERRORS_TYPES.notFound),
  // placeholderData: [],
  // suspense: true,
};

const requestWrapper = async (request, params) => {
  const { queryKey, signal } = params;
  const options = queryKey?.[1] || {};

  const combinedOptions = {
    signal,
    ...options,
  };

  const res = await request(combinedOptions);
  return res;
};

const useCustomQuery = (props) => {
  const {
    queryKey = [''],
    queryFn = () => { },
    options = {},
    // extraOptions = {},
  } = props;

  const [searchParams] = useSearchParams();

  const combinedQueryKey = React.useMemo(() => {
    const isArrayKey = Array.isArray(queryKey);
    const keyId = isArrayKey ? queryKey[0] : queryKey;
    const keyOptions = isArrayKey
      ? queryKey[1] || {}
      : {};

    const pagination = {
      // ...defaultPagination,
      page: searchParams.get('page') || defaultPagination.page || '',
      limit: searchParams.get('limit') || defaultPagination.limit || '',
      ...keyOptions.pagination,
    };
    const searchOptions = {
      term: searchParams.get('q') || '',
      ...keyOptions.searchOptions,
    };
    const filterOptions = {
      ...keyOptions.filterOptions,
    };

    return [
      keyId,
      {
        pagination,
        searchOptions,
        filterOptions,
      }];
  }, [queryKey, searchParams]);

  const combinedQueryFn = React.useCallback(
    (params) => requestWrapper(queryFn, params),
    [queryFn],
  );
  const combinedOptions = React.useMemo(() => ({
    ...defaultOptions,
    ...options,
  }), [options]);

  const query = useQuery(
    combinedQueryKey,
    combinedQueryFn,
    combinedOptions,
  );

  const errorCode = query?.error?.statusCode;
  if ([403, 404].includes(errorCode)) {
    throw new Exception(errorCode, { type: errorCode });
  }

  return { ...query };
};

useCustomQuery.propTypes = {
  queryKey: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  queryFn: PropTypes.func,
  options: PropTypes.oneOfType([PropTypes.object]),
};

useCustomQuery.defaultProps = {
  queryKey: [''],
  queryFn: () => { },
  options: {},
};

export default useCustomQuery;
