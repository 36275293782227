import END_POINTS from '../endPoints';
import METHODS from '../methods';
import api from '../api';

const editMachine = (data, name) => api(
  END_POINTS.machine(name),
  {
    method: METHODS.PUT,
    data,
  },
)
  .then((res) => Promise.resolve(res))
  .catch((error) => Promise.reject(error));

export default editMachine;
