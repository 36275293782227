import { Box, Link } from '@mui/material';
import { DoubleArrowUp } from 'assets/icons';
import { TypographyWithIcon } from 'components/molecules';
import { PATHS } from 'routes';
import { useLocale } from 'util/hooks';

const UpgradeAction = () => {
  const { t } = useLocale();

  return (
    <Box sx={{
      display: 'flex',
      alignItems: 'center',
      ml: {
        md: 34,
        sm: 15,
      },
    }}
    >
      <Link
        to={PATHS.subscriptions}
        style={{ textDecoration: 'none' }}
      >
        <TypographyWithIcon
          label={t('common.goPremium')}
          labelProps={{
            variant: 'bodySmallBold',
            color: 'primary.main',
          }}
          icon={(
            <DoubleArrowUp />
          )}
          iconProps={{
            mr: 2,
          }}
        />
      </Link>
    </Box>
  );
};

export default UpgradeAction;
