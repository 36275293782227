import {
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import { useController } from 'react-hook-form';

import {
  Box, FormHelperText, InputLabel,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { generatePresignedUrl, uploadFileToS3 } from 'services';
import { useLocale, useSnackbar } from 'util/hooks';
import { UploadedFile } from 'components/molecules';

const defaultDropzoneProps = {
  maxFiles: 3,
  multiple: true,
};

const FormFileUpload = (props) => {
  const {
    label,
    buttonLabel,
    setError, // Need to pass manually, ex, setError={(msg) => setError('files', msg)}
    disabled,
    dropzoneProps,
    ...rest
  } = props;

  const combinedDropzoneProps = {
    ...defaultDropzoneProps,
    ...dropzoneProps,
  };

  const { field, fieldState } = useController(rest);
  const { onChange, value: files } = field;
  const { error } = fieldState;

  const hasErrored = Boolean(error);
  const { maxFiles } = combinedDropzoneProps;
  const isUploadButtonDisabled = disabled || files?.length >= maxFiles;

  const { t } = useLocale();
  const snack = useSnackbar();

  const [isUploadLoading, setIsUploadLoading] = useState(false);

  const uploadToS3 = async (filesToUpload) => {
    setIsUploadLoading(true);
    try {
      const uploadedFiles = await Promise.all(
        filesToUpload.map(async (file) => {
          // Step 1 - get url and fileName
          const { uploadUrl, fileName } = await generatePresignedUrl();
          // Step 2 - upload to S3
          await uploadFileToS3(uploadUrl, file);

          // Step 3 - return obj with filename and fileId
          return {
            fileId: fileName, // file hash (e.g., xWkKX)
            fileName: file.name, // actual file name (e.g., coolName.js)
          };
        }),
      );
      // Step 4 - update form
      onChange(files.concat(uploadedFiles));
    } catch (err) {
      snack({
        message: err.message || t('common.errorMessage'),
        severity: 'error',
      });
    }
    setIsUploadLoading(false);
  };

  // ========= Dropzone =========

  const onDrop = async (acceptedFiles, fileRejections) => {
    if (fileRejections?.[0]) {
      setError({
        type: 'manual',
        message: fileRejections?.[0]?.errors?.[0]?.message,
      });
    }
    const distinctFiles = acceptedFiles // Remove duplicates
      .filter((ar) => !files
        ?.find((acceptedFile) => (acceptedFile.name === ar.name)));

    await uploadToS3(distinctFiles);
  };

  const { getRootProps, getInputProps } = useDropzone({
    ...combinedDropzoneProps,
    disabled: isUploadButtonDisabled,
    onDrop,
  });

  const onDelete = (fileToDelete) => {
    onChange(files.filter((f) => f.fileName !== fileToDelete.fileName));
  };

  return (
    <Box maxWidth="md">
      <Box>
        <InputLabel
          htmlFor={rest.id || rest.fileName}
          error={hasErrored}
          sx={(theme) => ({
            ...theme.typography.bodyStandardRegular,
            color: 'text.secondary',
          })}
        >
          {label}
        </InputLabel>
      </Box>
      <Box my={6}>
        {files
          ?.map((file) => (
            <UploadedFile
              key={file.fileId || file.fileName || file.id}
              url={file.fileId}
              name={file.fileName}
              onDelete={() => onDelete(file)}
              {...file}
            />
          ))}
        <FormHelperText>{error?.message}</FormHelperText>
      </Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Box sx={{
          display: 'flex',
        }}
        >
          <Box {...getRootProps()}>
            <input
              {...getInputProps()}
            />
            <LoadingButton
              size="small"
              variant="outlined"
              color="secondary"
              disabled={isUploadButtonDisabled}
              loading={isUploadLoading}
            >
              {buttonLabel || t('common.upload')}
            </LoadingButton>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

FormFileUpload.propTypes = {
  label: PropTypes.string,
  buttonLabel: PropTypes.string,
  setError: PropTypes.func,
  files: PropTypes.oneOfType([PropTypes.array]),
  disabled: PropTypes.bool,
  dropzoneProps: PropTypes.oneOfType([PropTypes.object]),
};

FormFileUpload.defaultProps = {
  label: '',
  buttonLabel: '',
  setError: () => { },
  files: [],
  disabled: false,
  dropzoneProps: defaultDropzoneProps,
};

export default FormFileUpload;
