const {
  REACT_APP_RECAPTCHA_KEY,
} = process.env;

const useRecaptcha = (props = {}) => {
  const {
    action = '',
  } = props;

  const executeRecaptcha = async () => {
    if (window.grecaptcha) {
      return new Promise((resolve, reject) => {
        window.grecaptcha
          .ready(() => window.grecaptcha
            .execute(REACT_APP_RECAPTCHA_KEY, { action })
            .then((token) => resolve(token))
            .catch((error) => reject(error)));
      });
    }
    return undefined;
  };

  return { executeRecaptcha };
};

export default useRecaptcha;
