import { useEffect } from 'react';
import * as Yup from 'yup';
import { useRecoilState } from 'recoil';

import { LoadingButton } from '@mui/lab';
import { useTheme } from '@mui/styles';
import { Box } from '@mui/material';

import { FormTextField } from 'components/form';
import { useFastForm, useLocale, useSnackbar } from 'util/hooks';
import { checkoutAtom } from 'recoil/atoms';
import { getPlanPrice } from 'services';
import { TrashBinIcon } from 'assets/icons';
import { REGEX } from 'util/helpers';

const PromoCode = () => {
  const [checkoutState, setCheckoutState] = useRecoilState(checkoutAtom);
  const {
    plan = {},
    billingOption,
    promoCode = {},
  } = checkoutState;
  const isPromoCodeApplied = Boolean(promoCode?.code);

  const snack = useSnackbar();
  const { t } = useLocale();
  const theme = useTheme();

  const defaultValues = {
    promoCode: promoCode?.code || '',
  };

  const validationSchema = Yup.object({
    promoCode: Yup
      .string()
      .matches(REGEX.alphaNumericSpaceSpecialCharacter, t('checkout.enterValidPromoCode')),
  });

  const {
    control,
    handleSubmit,
    setError,
    setValue,
    watch,
    formState: { isSubmitting, isDirty },
  } = useFastForm({
    defaultValues,
    validationSchema,
  });

  const handleApplyCode = async (values) => {
    try {
      const payload = {
        plan,
        billingOption,
      };

      if (values.promoCode) {
        payload.promoCode = {
          code: values.promoCode,
        };
      }

      // Step 1 - validate promoCode from api and set errors if any
      const {
        promoCode: planPricePromoCode,
        ...planPrice
      } = await getPlanPrice(plan?.id, payload);

      // Step 2 - update checkoutState with promo code
      setCheckoutState((prev) => ({
        ...prev,
        promoCode: planPricePromoCode,
        ...planPrice,
      }));
    } catch (error) {
      error.errors?.forEach((err) => {
        setError(err.property, {
          type: 'api',
          message: err.message,
        });

        if (!err.property || !watch(err.property)) {
          snack({
            severity: 'error',
            message: err.message || t('common.somethingWrong'),
          });
        }
      });
    }
  };

  const handleDeleteCode = async () => {
    // Clear input
    setValue('promoCode', '');

    // Update plan price
    await handleApplyCode({ promoCode: null });
  };

  useEffect(() => {
    if (isPromoCodeApplied && billingOption === 'perMonth') {
      handleDeleteCode();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPromoCodeApplied, billingOption]);

  const buttonText = isPromoCodeApplied
    ? t('common.remove')
    : t('checkout.applyCode');
  const buttonStartIcon = isPromoCodeApplied
    ? (
      <TrashBinIcon
        width="20"
        height="20"
        stroke={isDirty
          ? theme.palette.text.raspberry
          : theme.palette.text.secondary}
      />
    ) : null;

  return (
    <form onSubmit={handleSubmit(isPromoCodeApplied ? handleDeleteCode : handleApplyCode)}>
      <Box sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexWrap: 'wrap',
        columnGap: 3,
      }}
      >
        <Box sx={{
          width: { sm: 320 },
        }}
        >
          <FormTextField
            name="promoCode"
            control={control}
            label={t('promoCodes.promoCode')}
            disabled={isPromoCodeApplied || isSubmitting}
            fullWidth
          />
        </Box>
        <Box
          sx={{
            flexGrow: 1,
          }}
        >
          <LoadingButton
            type="submit"
            sx={{
              color: 'text.raspberry',
            }}
            loading={isSubmitting}
            startIcon={buttonStartIcon}
            disabled={isSubmitting}
          >
            {buttonText}
          </LoadingButton>
        </Box>
      </Box>
    </form>
  );
};

export default PromoCode;
