import transformEventDetails from './transformEventDetails';

const transformEvents = (data = {}) => {
  const { items: events = [] } = data;

  const now = new Date();

  const past = [];
  const upcoming = [];

  events.forEach((event) => {
    const transformedEvent = transformEventDetails(event);

    // Past event
    if (new Date(transformedEvent.eventEndAt).toISOString() < new Date(now).toISOString()) {
      past.push(transformedEvent);
    } else {
      upcoming.push(transformedEvent);
    }
  });

  return {
    ...data,
    items: {
      all: data.items,
      past,
      upcoming,
    },
  };
};

export default transformEvents;
