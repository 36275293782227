import { getAnnouncements } from 'services';
import { useCustomQuery } from 'reactQuery';

const useDashboardAnnouncements = () => {
  const query = useCustomQuery({
    queryKey: ['dashboard-announcements'],
    queryFn: getAnnouncements,
  });

  return { ...query };
};

export default useDashboardAnnouncements;
