import { useTheme } from '@mui/styles';

const TeamIcon = (props) => {
  const { palette } = useTheme();
  const {
    stroke = palette.text.primary,
    rectStroke = palette.text.secondary,
    fill = 'none',
    ...rest
  } = props;

  return (
    <svg width="45" height="45" viewBox="0 0 45 45" fill={fill} xmlns="http://www.w3.org/2000/svg" {...rest}>
      <rect width="45" height="45" rx="7.125" fill={rectStroke} fillOpacity="0.2" />
      <g clipPath="url(#clip0_10499_146779)">
        <path d="M18.875 22C20.9633 22 22.6562 20.3071 22.6562 18.2188C22.6562 16.1304 20.9633 14.4375 18.875 14.4375C16.7867 14.4375 15.0938 16.1304 15.0938 18.2188C15.0938 20.3071 16.7867 22 18.875 22Z" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M12.6875 29.5625C12.6875 27.9215 13.3394 26.3477 14.4998 25.1873C15.6602 24.0269 17.234 23.375 18.875 23.375C20.516 23.375 22.0898 24.0269 23.2502 25.1873C24.4106 26.3477 25.0625 27.9215 25.0625 29.5625" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M28.2495 23.375C29.9581 23.375 31.3433 21.9899 31.3433 20.2812C31.3433 18.5726 29.9581 17.1875 28.2495 17.1875C26.5409 17.1875 25.1558 18.5726 25.1558 20.2812C25.1558 21.9899 26.5409 23.375 28.2495 23.375Z" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M26.4951 24.8125C27.2606 24.5304 28.0829 24.4373 28.8921 24.5413C29.7013 24.6452 30.4734 24.943 31.1428 25.4094C31.8121 25.8758 32.3589 26.497 32.7367 27.2201C33.1144 27.9432 33.3119 28.7469 33.3124 29.5627" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      </g>
      <defs>
        <clipPath id="clip0_10499_146779">
          <rect width="22" height="22" fill="white" transform="translate(12 11)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default TeamIcon;
