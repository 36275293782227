import { getAllPaymentCards } from 'services';
import { useCustomQuery } from 'reactQuery';

const usePaymentCards = () => {
  const query = useCustomQuery({
    queryKey: ['payment-cards'],
    queryFn: getAllPaymentCards,
  });

  return { ...query };
};

export default usePaymentCards;
