import END_POINTS from '../endPoints';
import METHODS from '../methods';
import api from '../api';

const editLabChallenge = (data, labId, challengeId) => api(
  END_POINTS.labChallenge(labId, challengeId),
  {
    method: METHODS.PUT,
    data,
  },
)
  .then((res) => Promise.resolve(res))
  .catch((error) => Promise.reject(error));

export default editLabChallenge;
