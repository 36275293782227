import api from 'services/api';
import END_POINTS from 'services/endPoints';
import METHODS from 'services/methods';

const deletePaymentCard = (id, options) => api(
  END_POINTS.onePaymentCard(id),
  {
    method: METHODS.DELETE,
    ...options,
  },
)
  .then((res) => Promise.resolve(res))
  .catch((error) => Promise.reject(error));

export default deletePaymentCard;
