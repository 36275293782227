import * as Yup from 'yup';

import { useNavigate } from 'react-router-dom';

import {
  Box, Button,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

import {
  FormSingleImageUpload,
  FormTextField,
  FormDateTimePicker,
} from 'components/form';
import { PATHS } from 'routes';
import {
  REGEX,
  getDirtyObject,
} from 'util/helpers';
import { createAnnouncement, editAnnouncement } from 'services';
import { useAnnouncementDetails } from 'reactQuery/queries';
import {
  useLocale, useFastForm, useSnackbar, useDate,
} from 'util/hooks';
import { useEffect, useMemo } from 'react';

const AnnouncementDetailsForm = (props) => {
  const {
    createMode = false,
  } = props;

  const {
    data: announcementDetails = {},
    refetch: refetchAnnouncementDetails = () => { },
  } = useAnnouncementDetails();

  const { t } = useLocale();
  const snack = useSnackbar();
  const navigate = useNavigate();
  const { now, yesterday } = useDate();

  const { id } = announcementDetails;

  const imageDescriptionListItems = [
    t('announcements.imageDescription1'),
    t('announcements.imageDescription2'),
    t('announcements.imageDescription3'),
  ];

  const imageDescriptionList = imageDescriptionListItems.map((item) => (
    <li key={item}>{item}</li>
  ));

  const defaultValues = useMemo(() => {
    const {
      title,
      image,
      startAt,
      endAt,
    } = announcementDetails;

    return {
      title: title || '',
      image: image || '',
      startAt: startAt || now,
      endAt: endAt || now,
    };
  }, [announcementDetails, now]);

  const validationSchema = Yup.object({
    title: Yup
      .string()
      .trim()
      .matches(REGEX.alphaNumericSpaceSpecialCharacter, t('announcements.validation.enterEnglishTitle'))
      .required(t('announcements.validation.enterTitle'))
      .max(100, t('announcements.validation.titleMaxLength')),
    image: Yup
      .string()
      .nullable(true)
      .required(t('announcements.validation.uploadImage')),
    startAt: Yup
      .date()
      .nullable()
      .min(yesterday, t('announcements.validation.startDate'))
      .required(t('announcements.validation.enterStartDateAndTime')),
    endAt: Yup
      .date()
      .nullable()
      .when('startAt', (startDate, schema) => schema.min(startDate, t('announcements.validation.afterDate')))
      .required(t('announcements.validation.enterEndDateAndTime')),
  });

  const {
    control,
    handleSubmit,
    setError,
    watch,
    getValues,
    trigger,
    formState: {
      errors, isSubmitting, isDirty, isValid,
    },
  } = useFastForm({
    defaultValues,
    validationSchema,
  });

  const startAt = watch('startAt');
  const endAt = watch('endAt');

  useEffect(() => {
    // Effect to re-trigger endDate validation when start date is updated
    if (startAt && endAt) {
      trigger('endAt');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startAt]);

  const onCancel = () => {
    if (createMode) {
      navigate(`/${PATHS.announcements}`);
    } else {
      navigate(`/${PATHS.announcements}/${id}`);
    }
  };

  const onSubmit = async (values) => {
    const dirtyPayload = getDirtyObject({ ...values }, defaultValues);

    try {
      let message = t('announcements.updateSuccess');

      if (createMode) {
        const { id: announcementId } = await createAnnouncement({ ...values });
        message = t('announcements.createSuccess');
        navigate(`/${PATHS.announcements}/${announcementId}`);
      } else {
        await editAnnouncement(dirtyPayload, id);
        refetchAnnouncementDetails();
        // Turn off edit mode
        navigate(`/${PATHS.announcements}/${id}`);
      }
      snack({
        message,
        severity: 'success',
      });
    } catch (error) {
      error.errors?.forEach((err) => {
        setError(err.property, {
          type: 'api',
          message: err.message,
        });
        if (!err.property || !watch(err.property)) {
          snack({
            severity: 'error',
            message: err.message || t('common.somethingWrong'),
          });
        }
      });
      if (!errors) { // Non-form errors
        snack({
          severity: 'error',
          message: error.message || t('common.somethingWrong'),
        });
      }
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box>
        <Box sx={{
          display: 'grid',
          rowGap: 11,
        }}
        >
          <Box>
            <FormTextField
              name="title"
              control={control}
              label={t('common.title')}
              disabled={isSubmitting}
              sx={{
                width: {
                  xs: 1,
                  md: 420,
                  lg: 304,
                  xl: 420,
                },
              }}
            />
          </Box>
          <Box sx={{
            display: 'grid',
            rowGap: 11,
            columnGap: 31,
            gridTemplateColumns: {
              xs: 'repeat(1, 1fr)',
              md: 'repeat(1, 420px)',
              lg: 'repeat(2, 304px)',
              xl: 'repeat(2, 420px)',
            },
          }}
          >
            <Box>
              <FormDateTimePicker
                name="startAt"
                control={control}
                label={t('announcements.startDateAndTime')}
                disabled={isSubmitting}
                fullWidth
                pickerProps={{
                  disablePast: true,
                  minDateTime: new Date(),
                }}
              />
            </Box>
            <Box>
              <FormDateTimePicker
                name="endAt"
                control={control}
                label={t('announcements.endDateAndTime')}
                disabled={isSubmitting}
                fullWidth
                pickerProps={{
                  disablePast: true,
                  minDateTime: getValues('startAt'),
                }}
              />
            </Box>
          </Box>
          <Box
            mt={5}
          >
            <FormSingleImageUpload
              name="image"
              image={announcementDetails?.image}
              control={control}
              label={t('common.image')}
              disabled={isSubmitting}
              description={imageDescriptionList}
              fullWidth
            />
          </Box>
        </Box>
      </Box>

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          mt: 8,
        }}
      >
        <Box sx={{ mx: 2 }}>
          <LoadingButton
            type="submit"
            variant="contained"
            size="medium"
            loading={isSubmitting}
            disabled={!isDirty || isSubmitting || !isValid}
          >
            {t('common.save')}
          </LoadingButton>
        </Box>
        <Box>
          <Button
            onClick={onCancel}
            disabled={isSubmitting}
            sx={{
              color: 'text.raspberry',
            }}
          >
            {t('common.cancel')}
          </Button>
        </Box>
      </Box>
    </form>
  );
};

export default AnnouncementDetailsForm;
