import { DataGrid } from 'components/core';

import { usePlanFeatures } from 'reactQuery/queries';
import useTableColumns from './useTableColumns';

const PlanComparisonTable = (props) => {
  const {
    plans = [],
  } = props;

  const columns = useTableColumns({ plans });

  const {
    data: rows = [],
    isFetching = true,
  } = usePlanFeatures();

  return (
    <DataGrid
      rows={rows}
      columns={columns}
      loading={isFetching}
      paginationMode="client"
      withSearch={false}
      hideFooterPagination
      hideFooter
    />
  );
};

export default PlanComparisonTable;
