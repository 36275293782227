import { Box, Typography } from '@mui/material';

import { useLocale } from 'util/hooks';

const ChallengeHackersHeader = () => {
  const { t } = useLocale();

  return (
    <>
      <Box>
        <Typography
          variant="bodyMediumMedium"
          color="text.lighterGray"
        >
          {t('challenges.challengeHackers')}
        </Typography>
      </Box>
      <Box>
        <Typography
          variant="bodySmallRegular"
          color="text.secondary"
        >
          {`${t('challenges.allHackersListed')}:`}
        </Typography>
      </Box>
    </>
  );
};

export default ChallengeHackersHeader;
