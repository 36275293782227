import {
  Box,
  Button,
  Chip,
  Typography,
} from '@mui/material';

import { useLocale, useModal, usePermission } from 'util/hooks';
import { getStatusByName, STATUS } from 'assets/constants/statuses';

import MODAL_KEYS from 'assets/constants/modalKeys';
import PERMISSIONS from 'assets/constants/permissions';

const PromoCodeHeader = (props) => {
  const { codeDetails = {} } = props;

  // Modal
  const { addModal } = useModal();

  const {
    code = '',
    status = '',
  } = codeDetails;

  const { t } = useLocale();

  const canPublish = usePermission(PERMISSIONS.promoCodePublish);
  const isDraft = status === STATUS.draft;
  const showPublishButton = canPublish && isDraft;

  return (
    <Box sx={{
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      flexWrap: 'wrap',
      mt: 2,
      mb: 10,
    }}
    >
      <Box sx={{
        display: 'flex',
        alignItems: 'center',
      }}
      >
        <Box sx={{ mr: 4 }}>
          <Typography variant="bodyLargeBold">
            {code}
          </Typography>
        </Box>
        <Box>
          <Chip
            size="small"
            color={status}
            label={t(getStatusByName(status)?.localizationKey)}
          />
        </Box>
      </Box>
      {showPublishButton
        && (
          <Box sx={{ mt: { xs: 2, md: 0 } }}>
            <Button
              size="medium"
              variant="contained"
              onClick={() => addModal({
                key: MODAL_KEYS.promoCode,
                props: {
                  type: 'publish',
                  promoCodeDetailsView: true,
                  ...codeDetails,
                },
              })}
            >
              {t('challenges.actions.publish')}
            </Button>
          </Box>
        )}
    </Box>
  );
};

export default PromoCodeHeader;
