import {
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { useLocale, useSnackbar } from 'util/hooks';
import { sendVerificationEmail } from 'services';
import { LoadingButton } from '@mui/lab';

const VerifyBackdropAction = () => {
  const { t } = useLocale();
  const [isLoading, setIsLoading] = useState(false);
  const snack = useSnackbar();

  const resendVerificationEmail = async () => {
    setIsLoading(true);
    try {
      const { message } = await sendVerificationEmail();
      snack({
        message,
        severity: 'success',
      });
    } catch (error) {
      snack({
        message: error.message,
        severity: 'error',
      });
    }
    setIsLoading(false);
  };

  return (
    <>
      <Typography variant="bodyStandardBold" mt={1}>
        {t('emailVerification.activateToUnlock')}
      </Typography>
      <LoadingButton
        size="small"
        variant="contained"
        onClick={resendVerificationEmail}
        sx={{ mt: 4 }}
        loading={isLoading}
      >
        {t('emailVerification.resendLink')}
      </LoadingButton>
    </>
  );
};

export default VerifyBackdropAction;
