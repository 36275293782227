import {
  Box,
  Typography,
  useMediaQuery,
} from '@mui/material';

import { useLocale } from 'util/hooks';

import { numberWithCommas } from 'util/helpers';

const PlanPrice = (props) => {
  const { plan, billingOption, priceColor } = props;
  const {
    isFree,
    pricePerMonth,
    pricePerYear,
  } = plan;

  const { t } = useLocale();
  const isMonthly = billingOption === 'perMonth';
  const price = isMonthly ? pricePerMonth : pricePerYear;

  const mobileView = useMediaQuery(({ breakpoints }) => breakpoints.down('md'));

  return (
    <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
      <Box sx={{
        display: 'flex',
        alignItems: 'baseline',
        mr: 3,
        color: priceColor,
      }}
      >
        <Typography variant={mobileView ? 'h6' : 'h2'}>
          {numberWithCommas(price)}
        </Typography>
        <Typography variant={mobileView ? 'bodySmallMedium' : 'bodyLargeMedium'}>
          {t('currency.sar')}
        </Typography>
      </Box>
      <Box>
        <Typography variant={mobileView ? 'bodyTinyRegular' : 'bodySmallRegular'}>
          {isFree ? t('subscriptions.lifetime') : t(`subscriptions.${billingOption}`)}
        </Typography>
      </Box>
    </Box>
  );
};

export default PlanPrice;
