const DashboardIcon = (props) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#clip0_1012_414)">
      <path d="M4.375 8.12917C6.44607 8.12917 8.125 6.45023 8.125 4.37917C8.125 2.3081 6.44607 0.629166 4.375 0.629166C2.30393 0.629166 0.625 2.3081 0.625 4.37917C0.625 6.45023 2.30393 8.12917 4.375 8.12917Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M4.375 19.3792C6.44607 19.3792 8.125 17.7002 8.125 15.6292C8.125 13.5581 6.44607 11.8792 4.375 11.8792C2.30393 11.8792 0.625 13.5581 0.625 15.6292C0.625 17.7002 2.30393 19.3792 4.375 19.3792Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M15.625 8.12917C17.6961 8.12917 19.375 6.45023 19.375 4.37917C19.375 2.3081 17.6961 0.629166 15.625 0.629166C13.5539 0.629166 11.875 2.3081 11.875 4.37917C11.875 6.45023 13.5539 8.12917 15.625 8.12917Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M15.625 19.3792C17.6961 19.3792 19.375 17.7002 19.375 15.6292C19.375 13.5581 17.6961 11.8792 15.625 11.8792C13.5539 11.8792 11.875 13.5581 11.875 15.6292C11.875 17.7002 13.5539 19.3792 15.625 19.3792Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </g>
    <defs>
      <clipPath id="clip0_1012_414">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>

);

export default DashboardIcon;
