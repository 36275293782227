import { useTheme } from '@mui/styles';

const UserWithHashFilledIcon = (props) => {
  const { palette } = useTheme();

  const {
    stroke = palette.text.lightPurple,
    fill = palette.text.lightPurple,
    ...rest
  } = props;

  return (
    <svg width="46" height="45" viewBox="0 0 46 45" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <rect x="0.5" width="45" height="45" rx="7.125" fill={fill} fillOpacity="0.2" />
      <g clipPath="url(#clip0_9666_139930)">
        <path d="M23.5 22.375C25.9162 22.375 27.875 20.4162 27.875 18C27.875 15.5838 25.9162 13.625 23.5 13.625C21.0838 13.625 19.125 15.5838 19.125 18C19.125 20.4162 21.0838 22.375 23.5 22.375Z" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M15.375 32.375C15.375 30.2201 16.231 28.1535 17.7548 26.6298C19.2785 25.106 21.3451 24.25 23.5 24.25" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <g clipPath="url(#clip1_9666_139930)">
          <path d="M25.915 26.3857H33.1114" stroke={stroke} strokeWidth="0.81758" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M24.8071 29.708H32.0035" stroke={stroke} strokeWidth="0.81758" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M28.9597 23.6182L26.1919 31.9217" stroke={stroke} strokeWidth="0.81758" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M31.7273 23.6182L28.9595 31.9217" stroke={stroke} strokeWidth="0.81758" strokeLinecap="round" strokeLinejoin="round" />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_9666_139930">
          <rect width="20" height="20" fill="white" transform="translate(13.5 13)" />
        </clipPath>
        <clipPath id="clip1_9666_139930">
          <rect width="10.001" height="10.001" fill="white" transform="translate(24.5 23)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default UserWithHashFilledIcon;
