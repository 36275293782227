import { useParams } from 'react-router-dom';

import { getEventChallenges } from 'services';
import { useCustomQuery } from 'reactQuery';

const useEventChallenges = (queryProps = {}) => {
  const { eventId } = useParams();

  const query = useCustomQuery({
    queryKey: [`event-challenges-${eventId}`],
    queryFn: () => getEventChallenges(eventId),
    ...queryProps,
  });

  return { ...query };
};

export default useEventChallenges;
