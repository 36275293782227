import { useSetRecoilState } from 'recoil';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Card,
  Button,
  Typography,
  Stack,
} from '@mui/material';
import { useTheme } from '@mui/styles';

import { useAuth, useLocale } from 'util/hooks';
import { checkoutAtom } from 'recoil/atoms';
import { TypographyWithIcon } from 'components/molecules';
import { CheckCircleOutline } from 'assets/icons';
import { PATHS } from 'routes';
import PlanPrice from './PlanPrice';

const PlanCard = (props) => {
  const {
    plan = [],
    billingOption = 'perYear',
  } = props;

  const {
    isCurrent = false,
    isFree = false,
    nameAr = '',
    nameEn = '',
    promotedFeatures = [],
  } = plan;

  // Recoil
  const setCheckoutState = useSetRecoilState(checkoutAtom);

  const navigate = useNavigate();
  const { palette } = useTheme();
  const { t, isAr } = useLocale();
  const { isLoggedIn } = useAuth();

  const generateStylesForPlan = () => {
    if (isFree) {
      return {
        bgcolor: null,
        defaultColor: 'text.primary',
        priceColor: 'text.primary',
        planNameColor: 'text.primary',
        buttonProps: {
          color: 'secondary',
          variant: 'outlined',
        },
        iconStroke: palette.text.raspberry,
      };
    }

    return {
      bgcolor: 'paper.hover',
      defaultColor: 'text.primary',
      priceColor: 'text.primary',
      planNameColor: 'text.primary',
      buttonProps: {
        variant: 'contained',
      },
      iconStroke: palette.text.raspberry,
    };
  };

  const styles = generateStylesForPlan();

  const showSubscribeButton = isLoggedIn && (isCurrent || !isFree);

  const handleSubscribeClick = () => {
    setCheckoutState((prev) => ({
      ...prev,
      type: 'subscription',
      billingOption,
      plan,
    }));

    navigate(`/${PATHS.checkout}`);
  };

  const renderPlanCta = () => {
    /* TODO: Temp code is ON for launch */
    const tempMode = true;

    if (tempMode) {
      return (
        <Button
          fullWidth
          disabled
          {...styles.buttonProps}
        >
          {isFree
            ? t('subscriptions.currentPlan')
            : t('common.comingSoon')}
        </Button>
      );
    }
    // End of Temp code

    if (showSubscribeButton) {
      const subscribeButtonText = isCurrent
        ? t('subscriptions.currentPlan')
        : t('subscriptions.subscribe');
      return (
        <Button
          fullWidth
          disabled={isFree || isCurrent}
          onClick={handleSubscribeClick}
          {...styles.buttonProps}
        >
          {subscribeButtonText}
        </Button>
      );
    }

    if (!isLoggedIn) {
      return (
        <Button
          fullWidth
          onClick={() => navigate(`/${PATHS.register}`)}
          {...styles.buttonProps}
        >
          {t('landing.joinUsCTA')}
        </Button>
      );
    }
    return null;
  };

  return (
    <Card
      sx={{
        px: { xs: 5, sm: 10 },
        py: 6,
        minWidth: { sm: 324 },
        bgcolor: styles.bgcolor,
        color: styles.defaultColor,
      }}
    >
      <Stack spacing={4}>
        <Box>
          <PlanPrice
            billingOption={billingOption}
            plan={plan}
            priceColor={styles.priceColor}
          />
        </Box>
        <Box>
          <Typography
            variant="bodyLargeMedium"
            color={styles.planNameColor}
          >
            {isAr ? nameAr : nameEn}
          </Typography>
        </Box>
        <Box>
          {renderPlanCta()}
        </Box>
        <Box sx={{ pt: 6 }}>
          {promotedFeatures?.map((feat) => (
            <TypographyWithIcon
              key={feat.id}
              label={isAr ? feat.nameAr : feat.nameEn}
              labelProps={{
                variant: 'bodyStandardMedium',
                color: styles.defaultColor,
              }}
              icon={<CheckCircleOutline stroke={styles.iconStroke} width="19" height="19" />}
              sx={{ mb: 3 }}
            />
          ))}
        </Box>
      </Stack>
    </Card>
  );
};

export default PlanCard;
