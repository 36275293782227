import { useTheme } from '@mui/styles';

const ChallengeDetailIcon = (props) => {
  const theme = useTheme();
  const {
    stroke = theme.palette.text.secondary,
    fill = theme.palette.paper.hover,
    ...rest
  } = props;

  return (
    <svg width="48" height="44" viewBox="0 0 48 44" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <rect width="48" height="44" rx="6" fill={fill} />
      <path d="M15.4062 14.7296V13.4078C15.4062 13.0572 15.5455 12.721 15.7934 12.4731C16.0413 12.2252 16.3775 12.0859 16.7281 12.0859H18.0508" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M15.4062 29.2734V30.5953C15.4062 30.9458 15.5455 31.2821 15.7934 31.5299C16.0413 31.7778 16.3775 31.9171 16.7281 31.9171H18.0508" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M32.5938 29.2734V30.5953C32.5938 30.9458 32.4545 31.2821 32.2066 31.5299C31.9588 31.7778 31.6225 31.9171 31.272 31.9171H29.9492" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M25.3223 31.9141H27.3059" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M20.6953 31.9141H22.679" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M25.3223 12.0859H27.3059" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M20.6953 12.0859H22.679" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M15.4062 20.0188V18.0352" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M15.4062 25.968V23.9844" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M32.5938 20.0188V18.0352" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M32.5938 25.968V23.9844" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M29.9492 14.0664L31.272 15.3882" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default ChallengeDetailIcon;
