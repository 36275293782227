import PropTypes from 'prop-types';

import { Box } from '@mui/material';

import { NoContentPlaceholder } from 'components/molecules';

import { useLocale } from 'util/hooks';

import LAB_TYPES from 'assets/constants/labTypes';

import { SuspenseWrapper } from 'components/render';
import { useLabs } from 'reactQuery/queries';
import LatestChallengeCard from './sections/LatestChallengeCard';
import PlayerLabHeader from './sections/PlayerLabHeader';
import LabList from './sections/LabList';

const PlayerLabsContainer = (props) => {
  const { type } = props;

  const { t } = useLocale();

  const filterOptions = {
    type,
  };

  const {
    data = {},
    isFetched = false,
  } = useLabs({
    queryKeyOptions: { filterOptions },
  });

  const { items: labs = [] } = data || {};

  if (isFetched && !labs.length) {
    const content = {
      desc: [{
        text: t('labs.noLabs'),
        variant: 'bodyMediumBold',
      }],
    };

    return (
      <Box>
        <Box sx={{ mb: 5 }}>
          <PlayerLabHeader type={type} />
        </Box>
        <Box sx={{ mt: 30 }}>
          <NoContentPlaceholder content={content} />
        </Box>
      </Box>
    );
  }

  return (
    <Box>
      <Box sx={{ mb: 5 }}>
        <PlayerLabHeader type={type} />
      </Box>
      <Box sx={{ my: 5 }}>
        <SuspenseWrapper>
          <LatestChallengeCard type={type} />
        </SuspenseWrapper>
      </Box>
      <Box>
        <LabList labs={labs} />
      </Box>
    </Box>
  );
};

PlayerLabsContainer.propTypes = {
  type: PropTypes.string,
};

PlayerLabsContainer.defaultProps = {
  type: LAB_TYPES.training,
};

export default PlayerLabsContainer;
