import { useState } from 'react';
import { Tabs as MUITabs, Tab } from '@mui/material';

import SuspenseWrapper from 'components/render/SuspenseWrapper/SuspenseWrapper';
import TabPanel from './TabPanel';

/**
 *
 * @param {[{label: String, component: Node}]} tabs
 * @returns Components wrapped in MUITab elements
 * @USAGE
 * const tabs = [{
 *      label: 'Label 1',
 *      component: <Component1 />,
 *     },
 *    {
 *      label: 'Label 2',
 *      component: <Component2 />,
 * }];
 */
const Tabs = (props) => {
  const {
    tabs = [],
    ...rest
  } = props;

  const [currentTab, setCurrentTab] = useState(tabs[0]?.key || 0);

  const handleChange = (event, newValue) => {
    setCurrentTab(newValue);
  };

  return (
    <>
      <MUITabs
        value={currentTab}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons="auto"
        allowScrollButtonsMobile
        {...rest}
      >
        {tabs.map((tab, i) => (
          <Tab
            key={`tab-${tab.key}`}
            label={tab.label}
            value={tab.key || i}
            disableRipple
          />
        ))}
      </MUITabs>
      <SuspenseWrapper>
        {tabs.map((tab, i) => (
          <TabPanel
            key={`tab-panel-${tab.key}`}
            currentTab={currentTab}
            value={tab.key || i}
          >
            {tab.component}
          </TabPanel>
        ))}
      </SuspenseWrapper>
    </>
  );
};

export default Tabs;
