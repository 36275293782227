import api from 'services/api';
import END_POINTS from 'services/endPoints';
import METHODS from 'services/methods';

const moveCompanyMember = ({ currentCompany, memberId, company }) => api(
  END_POINTS.moveCompanyMember(currentCompany, memberId),
  {
    method: METHODS.PATCH,
    data: { company },
  },
)
  .then((res) => Promise.resolve(res))
  .catch((error) => Promise.reject(error));

export default moveCompanyMember;
