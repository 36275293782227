import { useTheme } from '@mui/styles';

const RankIcon = (props) => {
  const { palette } = useTheme();

  const {
    fill = palette.colors.metallicGold,
    ...rest
  } = props;
  return (
    <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <path d="M13.801 1.26045H11.0995V0.185803C11.0995 0.090391 11.0105 0 10.9058 0H3.08377C3.08377 0 3.05759 0 3.04188 0C2.95288 0.0200868 2.88482 0.105456 2.89005 0.185803V1.26045H0.178011C0.0837697 1.26547 0 1.35586 0 1.44625V5.89548C0 7.46225 1.3089 8.71767 2.9267 8.71767H3.57592C4.16754 9.56634 5.089 10.184 6.16754 10.3999V12.2881H4.63351C3.15183 12.2881 1.94764 13.3979 1.85864 14.799C1.8534 14.9044 1.94764 15.0048 2.05759 14.9998C2.10471 14.9998 2.14136 14.9948 2.1466 14.9948H11.9529C12.0576 14.9948 12.1518 14.8944 12.1466 14.7939C12.0576 13.3929 10.8429 12.2881 9.37173 12.2881H7.84293V10.3999C8.91099 10.179 9.83246 9.57136 10.4241 8.71767H11.0785C12.6963 8.71767 14 7.46225 14 5.89548V1.44625C14 1.34582 13.911 1.26045 13.8063 1.26045H13.801ZM8.77487 7.4974L6.99476 6.46795L5.21466 7.4974L5.68586 5.55902L4.11518 4.25338L6.18325 4.08264L6.98953 2.25474L7.79581 4.08264L9.86387 4.25338L8.29319 5.55902L8.7644 7.4974H8.77487Z" fill={fill} />
    </svg>

  );
};

export default RankIcon;
