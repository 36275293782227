import { memo } from 'react';
import { useController } from 'react-hook-form';

import {
  FormControl,
  FormHelperText,
  Typography,
} from '@mui/material';
import { AutocompleteSelect } from 'components/molecules';

const FormAutocompleteSelect = (props) => {
  const {
    label,
    labelProps,
    ...rest
  } = props;

  const { field, fieldState } = useController(rest);
  const { onChange } = field;
  const { error } = fieldState;

  const hasErrored = Boolean(error);

  const onFieldChange = (event, newValue) => {
    onChange(newValue || '');
  };

  return (
    <FormControl
      sx={{
        width: 1,
        minHeight: '103px', // handle helperText height to reduce ui shift
      }}
      error={hasErrored}
    >
      <Typography
        variant="bodyStandardRegular"
        sx={{ px: 2, color: 'text.secondary' }}
        {...labelProps}
      >
        {label}
      </Typography>
      <AutocompleteSelect
        {...field}
        {...rest}
        onChange={onFieldChange}
        error={hasErrored}
      />
      {hasErrored && <FormHelperText>{error?.message}</FormHelperText>}
    </FormControl>
  );
};

FormAutocompleteSelect.defaultProps = {
  label: '',
  labelProps: {},
};
export default memo(FormAutocompleteSelect);
