const AnnouncementsIcon = (props) => {
  const {
    stroke = '',
    fill = 'none',
    ...rest
  } = props;

  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill={fill} xmlns="http://www.w3.org/2000/svg" {...rest}>
      <path d="M6.66602 14.5C6.75069 14.7885 6.92652 15.0417 7.16718 15.2219C7.40784 15.4021 7.70039 15.4994 8.00102 15.4994C8.30164 15.4994 8.59419 15.4021 8.83485 15.2219C9.07551 15.0417 9.25134 14.7885 9.33602 14.5" stroke={stroke} strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M8 2V0.5" stroke={stroke} strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M8 2C9.32608 2 10.5979 2.52678 11.5355 3.46447C12.4732 4.40215 13 5.67392 13 7C13 11.6973 14 12.5 14 12.5H2C2 12.5 3 11.2227 3 7C3 5.67392 3.52678 4.40215 4.46447 3.46447C5.40215 2.52678 6.67392 2 8 2V2Z" stroke={stroke} strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default AnnouncementsIcon;
