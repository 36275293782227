import { Box, Card, Typography } from '@mui/material';
import { useTheme } from '@mui/styles';

import { useLocale } from 'util/hooks';
import { CalendarFilledIcon } from 'assets/icons';

const ActivityDateCard = (props) => {
  const {
    type = null,
    date = null,
  } = props;

  const theme = useTheme();
  const { t } = useLocale();

  const content = {
    publish: {
      label: t('common.publishedAt'),
      color: theme.palette.primary.main,
    },
    retire: {
      label: t('challenges.retireDate'),
      color: theme.palette.text.lightGray,
    },
  };

  return (
    <Card
      sx={{
        display: 'flex',
        alignItems: 'center',
        minWidth: 287,
        px: 8,
        py: 4,
        flex: 1,
      }}
    >
      <Box sx={{
        mr: 3,
        width: 45,
        height: 45,
      }}
      >
        <CalendarFilledIcon
          stroke={content[type]?.color}
          fill={content[type]?.color}
          width="45"
          height="45"
        />
      </Box>
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
      }}
      >
        <Typography
          variant="bodySmallMedium"
          color="text.lightGray"
        >
          {content[type]?.label}
        </Typography>
        <Typography
          variant="bodyStandardBold"
          color="text.lighterGray"
        >
          {new Date(date).toLocaleDateString(
            'en-US',
            {
              year: 'numeric',
              month: 'short',
              day: 'numeric',
              hour: 'numeric',
              minute: 'numeric',
            },
          )}
        </Typography>
      </Box>
    </Card>
  );
};

export default ActivityDateCard;
