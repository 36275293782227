import PropTypes from 'prop-types';
import {
  Button,
  Card,
  CardContent,
  Box,
  Typography,
} from '@mui/material';
import { CheckCircleOutline, HighlightOff } from 'assets/icons';

const MessageBox = (props) => {
  const {
    title,
    description,
    label,
    variant,
    buttonLabel,
    onClickButton,
    ...rest
  } = props;

  const getIconForVariant = () => (
    variant === 'error'
      ? <HighlightOff width="34" height="34" />
      : <CheckCircleOutline width="34" height="34" />
  );

  return (
    <Card {...rest}>
      <Box sx={{
        display: 'flex',
        flexDirection: {
          xs: 'column',
          sm: 'row',
        },
        my: 11,
        mx: {
          xs: 4,
          sm: 13,
        },
      }}
      >
        <Box sx={{
          textAlign: 'center',
          width: '34',
          height: '34',
        }}
        >
          {getIconForVariant()}
        </Box>
        <CardContent sx={{
          my: 0,
          mx: {
            xs: 0,
            sm: 4,
          },
          p: 0,
          textAlign: {
            xs: 'center',
            sm: 'initial',
          },
        }}
        >
          <Typography
            variant="h4"
          >
            {title}
          </Typography>
          <Typography sx={{ mt: 1 }}>
            {description}
          </Typography>
          {label && <Typography sx={{ mt: 1 }}>{label}</Typography>}
          <Button
            sx={{ mt: 8 }}
            size="medium"
            variant="contained"
            onClick={onClickButton}
          >
            {buttonLabel}
          </Button>
        </CardContent>
      </Box>
    </Card>
  );
};

MessageBox.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  variant: PropTypes.string,
  label: PropTypes.string,
  buttonLabel: PropTypes.string,
  onClickButton: PropTypes.func,
};

MessageBox.defaultProps = {
  title: '',
  description: '',
  variant: 'success',
  label: '',
  buttonLabel: '',
  onClickButton: () => { },

};
export default MessageBox;
