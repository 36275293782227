import { useState, useEffect } from 'react';
import {
  Box,
  Typography,
} from '@mui/material';

import { AnimatedBadge } from 'components/molecules';

import { getOnlinePlayers } from 'services';
import SOCKET_EVENTS from 'assets/constants/socketEvents';
import { useSocket } from 'util/hooks';

const OnlinePlayersCard = () => {
  const [onlinePlayers, setOnlinePlayers] = useState(0);

  useEffect(() => {
    const fetchInitialOnlinePlayers = async () => {
      const initialOnlinePlayers = await getOnlinePlayers();
      setOnlinePlayers(initialOnlinePlayers || 0);
    };

    fetchInitialOnlinePlayers();
  }, []);

  const handleOnlinePlayers = (socketProps) => {
    const {
      data = 0,
    } = socketProps;

    setOnlinePlayers(data);
  };

  const socketEvents = [
    {
      eventName: SOCKET_EVENTS.onlinePlayers.onlinePlayersCount,
      handler: handleOnlinePlayers,
    },
  ];

  useSocket({ events: socketEvents });

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <AnimatedBadge sx={{ mr: 2 }} />
      <Typography variant="bodyStandardBold">
        {onlinePlayers}
      </Typography>
    </Box>
  );
};

export default OnlinePlayersCard;
