import { useTheme } from '@mui/styles';

const RankFilledIcon = (props) => {
  const { palette } = useTheme();
  const {
    stroke = palette.text.purple,
    fill = palette.text.purple,
    ...rest
  } = props;

  return (
    <svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <rect width="45" height="45" rx="7.125" fill={fill} fillOpacity="0.2" />
      <g clipPath="url(#clip0_5833_8950)">
        <path d="M19.5625 20.625H14.0625C13.6978 20.625 13.3481 20.7699 13.0902 21.0277C12.8324 21.2856 12.6875 21.6353 12.6875 22V27.5H19.5625V20.625Z" stroke={stroke} strokeWidth="1.375" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M31.9375 22H26.4375V27.5H33.3125V23.375C33.3125 23.0103 33.1676 22.6606 32.9098 22.4027C32.6519 22.1449 32.3022 22 31.9375 22Z" stroke={stroke} strokeWidth="1.375" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M25.0625 16.5H20.9375C20.5728 16.5 20.2231 16.6449 19.9652 16.9027C19.7074 17.1606 19.5625 17.5103 19.5625 17.875V27.5H26.4375V17.875C26.4375 17.5103 26.2926 17.1606 26.0348 16.9027C25.7769 16.6449 25.4272 16.5 25.0625 16.5Z" stroke={stroke} strokeWidth="1.375" strokeLinecap="round" strokeLinejoin="round" />
      </g>
      <defs>
        <clipPath id="clip0_5833_8950">
          <rect width="22" height="22" fill="white" transform="translate(12 11)" />
        </clipPath>
      </defs>
    </svg>

  );
};

export default RankFilledIcon;
