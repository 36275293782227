export const getRoutesForRole = (routes = [], userRole = null) => routes
  .filter((route) => hasPermission(route.auth, userRole))
  .map((route) => {
    const configuredRoute = { ...route };
    if (route.children) {
      const childrenToRender = route
        .children
        .filter((child) => hasPermission(child.auth, userRole));

      // Find index child, if any
      const indexChild = childrenToRender.find((child) => child.index);
      if (indexChild) {
        // Append index to children (index can't have path)
        childrenToRender.unshift({ ...indexChild, path: undefined });
      }

      // Repeat for all children
      configuredRoute.children = getRoutesForRole(childrenToRender, userRole);
    }
    return configuredRoute;
  });

export const hasPermission = (authArr, userRole) => {
  // If auth array is not defined, allow
  if (!authArr) {
    return true;
  } if (Array.isArray(authArr) && authArr.length === 0) {
    // If auth array is empty (has no permissions), allow if logged-in
    return !userRole;
  }
  // If role is a string, verify user has authorization for this route
  return authArr.includes(userRole);
};

export const isPublicRoute = (routeAuth) => !routeAuth || !routeAuth.length;

export const isPrivateRoute = (routeAuth) => Array.isArray(routeAuth) && routeAuth.length > 0;

/**
 *
 * @param {String} pathname
 * @param {Array} tabs
 * @returns The active tab, the tab matching the current pathname or the first tab (default)
 */
export const getActiveTab = (tabs, pathname) => tabs
  .find((tab) => pathname.endsWith(tab.to))?.to || tabs[0].to;

/**
 *
 * @param {import} componentImport
 * @returns The intended component or an error after a first try
 * Retry loading a chunk to avoid chunk load error for out of date chunks
 */
export const lazyRetry = (componentImport) => new Promise((resolve, reject) => {
  // check if the window has already been refreshed
  const hasRefreshed = JSON.parse(
    window.sessionStorage.getItem('retry-lazy-refreshed') || 'false',
  );
  // try to import the component
  componentImport().then((component) => {
    window.sessionStorage.setItem('retry-lazy-refreshed', 'false');
    return resolve(component);
  }).catch((error) => {
    if (!hasRefreshed) {
      window.sessionStorage.setItem('retry-lazy-refreshed', 'true');
      return window.location.reload();
    }
    return reject(error);
  });
});
