import { useTheme } from '@mui/styles';

const FilledEncryptedPasswordIcon = (props) => {
  const { palette } = useTheme();

  const {
    fill = 'none',
    rectFill = palette.text.secondary,
    stroke = palette.text.primary,
    ...rest
  } = props;
  return (
    <svg width="45" height="45" viewBox="0 0 45 45" fill={fill} xmlns="http://www.w3.org/2000/svg" {...rest}>
      <rect width="45" height="45" rx="7.125" fill={rectFill} fillOpacity="0.2" />
      <g clipPath="url(#clip0_10499_146790)">
        <path d="M31.9375 20.625H26.4375C25.6781 20.625 25.0625 21.2406 25.0625 22V26.125C25.0625 26.8844 25.6781 27.5 26.4375 27.5H31.9375C32.6969 27.5 33.3125 26.8844 33.3125 26.125V22C33.3125 21.2406 32.6969 20.625 31.9375 20.625Z" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M26.4375 20.625V19.25C26.4375 18.5207 26.7272 17.8212 27.243 17.3055C27.7587 16.7897 28.4582 16.5 29.1875 16.5C29.9168 16.5 30.6163 16.7897 31.132 17.3055C31.6478 17.8212 31.9375 18.5207 31.9375 19.25V20.625" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M29.1875 23.7188C29.1195 23.7188 29.0531 23.7389 28.9965 23.7767C28.94 23.8145 28.8959 23.8681 28.8699 23.931C28.8439 23.9938 28.8371 24.0629 28.8504 24.1296C28.8636 24.1962 28.8964 24.2575 28.9444 24.3056C28.9925 24.3536 29.0538 24.3864 29.1204 24.3996C29.1871 24.4129 29.2562 24.4061 29.319 24.3801C29.3819 24.3541 29.4355 24.31 29.4733 24.2535C29.5111 24.1969 29.5313 24.1305 29.5313 24.0625C29.5314 24.0173 29.5226 23.9726 29.5053 23.9308C29.4881 23.8891 29.4628 23.8511 29.4308 23.8192C29.3989 23.7872 29.361 23.7619 29.3192 23.7447C29.2774 23.7274 29.2327 23.7186 29.1875 23.7188Z" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M15.7813 23.7188C15.7133 23.7188 15.6468 23.7389 15.5903 23.7767C15.5337 23.8145 15.4897 23.8681 15.4637 23.931C15.4376 23.9938 15.4308 24.0629 15.4441 24.1296C15.4574 24.1962 15.4901 24.2575 15.5382 24.3056C15.5863 24.3536 15.6475 24.3864 15.7142 24.3996C15.7809 24.4129 15.85 24.4061 15.9128 24.3801C15.9756 24.3541 16.0293 24.31 16.0671 24.2535C16.1048 24.1969 16.125 24.1305 16.125 24.0625C16.1251 24.0173 16.1163 23.9726 16.0991 23.9308C16.0818 23.8891 16.0565 23.8511 16.0246 23.8192C15.9926 23.7872 15.9547 23.7619 15.9129 23.7447C15.8712 23.7274 15.8264 23.7186 15.7813 23.7188Z" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M19.2188 23.7188C19.1508 23.7188 19.0843 23.7389 19.0278 23.7767C18.9712 23.8145 18.9272 23.8681 18.9012 23.931C18.8751 23.9938 18.8683 24.0629 18.8816 24.1296C18.8949 24.1962 18.9276 24.2575 18.9757 24.3056C19.0238 24.3536 19.085 24.3864 19.1517 24.3996C19.2184 24.4129 19.2875 24.4061 19.3503 24.3801C19.4131 24.3541 19.4668 24.31 19.5046 24.2535C19.5423 24.1969 19.5625 24.1305 19.5625 24.0625C19.5626 24.0173 19.5538 23.9726 19.5366 23.9308C19.5193 23.8891 19.494 23.8511 19.4621 23.8192C19.4301 23.7872 19.3922 23.7619 19.3504 23.7447C19.3087 23.7274 19.2639 23.7186 19.2188 23.7188Z" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M22.3125 27.5H14.0625C13.6978 27.5 13.3481 27.3551 13.0902 27.0973C12.8324 26.8394 12.6875 26.4897 12.6875 26.125V20.625C12.6875 20.2603 12.8324 19.9106 13.0902 19.6527C13.3481 19.3949 13.6978 19.25 14.0625 19.25H22.3125" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      </g>
      <defs>
        <clipPath id="clip0_10499_146790">
          <rect width="22" height="22" fill="white" transform="translate(12 11)" />
        </clipPath>
      </defs>
    </svg>

  );
};

export default FilledEncryptedPasswordIcon;
