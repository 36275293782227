import { useCustomQuery } from 'reactQuery';
import { getSystemPermissions } from 'services';
import { transformPermissions } from 'util/transformers/';

const useSystemPermissions = (queryProps = {}) => {
  const { options, ...rest } = queryProps;

  const query = useCustomQuery({
    queryKey: ['system-permissions'],
    queryFn: getSystemPermissions,
    options: {
      select: transformPermissions,
      ...options,
    },
    ...rest,
  });

  return query;
};

export default useSystemPermissions;
