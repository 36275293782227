import { useParams } from 'react-router-dom';

import { getEventLeaderboard } from 'services';
import { useCustomQuery } from 'reactQuery';

const useEventLeaderboard = (queryProps = {}) => {
  const {
    queryKeyOptions = {},
    ...rest
  } = queryProps;

  const { eventId } = useParams();

  const query = useCustomQuery({
    queryKey: [`event-leaderboard-${eventId}`, queryKeyOptions],
    queryFn: (options) => getEventLeaderboard(eventId, options),
    ...rest,
  });

  return { ...query };
};

export default useEventLeaderboard;
