import { useTheme } from '@mui/styles';
import { useLocale } from 'util/hooks';

const useLineOptions = (props) => {
  const { overrides = {} } = props || {};

  const { isRtl } = useLocale();
  const { palette, fontFamily } = useTheme();

  return {
    elements: {
      point: {
        radius: 1,
      },
    },
    plugins: {
      tooltip: {
        usePointStyle: true,
        callbacks: {
          labelPointStyle() {
            return {
              pointStyle: 'dash',
              rotation: 0,
            };
          },
        },
        backgroundColor: palette.paper.background,
        rtl: isRtl,
      },
      legend: {
        display: false,
      },
    },
    datasets: {
      line: {
        hoverBackgroundColor: palette.primary.contrastText,
      },
    },
    scales: {
      x: {
        offset: true,
        ticks: {
          color: palette.text.primary,
          font: {
            family: fontFamily,
            size: 12,
          },
        },
        grid: {
          color: palette.background.default,
          borderColor: palette.text.darkGray,
        },
      },
      y: {
        title: {
          display: true,
          color: palette.text.primary,
          font: {
            family: fontFamily,
            size: 12,
          },
        },
        beginAtZero: true,
        position: isRtl ? 'right' : 'left',
        ticks: {
          color: palette.text.primary,
          font: {
            family: fontFamily,
            size: 12,
          },
        },
        grid: {
          color: palette.background.default,
          borderColor: palette.text.darkGray,
        },
      },
    },
    ...overrides,
  };
};

export default useLineOptions;
