import { atom } from 'recoil';
import { localStorageEffect } from 'recoil/effects';

const checkoutAtom = atom({
  key: 'checkout',
  default: {},
  effects_UNSTABLE: [localStorageEffect('checkout')],
});

export default checkoutAtom;
