import { getChallengeCategories } from 'services';
import { useCustomQuery } from 'reactQuery';

const useChallengeCategories = (queryProps = {}) => {
  const query = useCustomQuery({
    queryKey: ['challenge-categories'],
    queryFn: getChallengeCategories,
    ...queryProps,
  });

  return { ...query };
};

export default useChallengeCategories;
