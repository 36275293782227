import { useTheme } from '@mui/styles';

const ChallengeDifficultyIcon = (props) => {
  const theme = useTheme();
  const {
    stroke = theme.palette.text.lightRaspberry,
    fill = 'none',
    ...rest
  } = props;

  return (
    <svg width="39" height="39" viewBox="0 0 39 39" fill={fill} xmlns="http://www.w3.org/2000/svg" {...rest}>
      <path d="M32.9062 32.9062C32.9062 34.1992 32.3926 35.4392 31.4784 36.3534C30.5642 37.2676 29.3242 37.7812 28.0312 37.7812H8.53125C7.23832 37.7812 5.99834 37.2676 5.0841 36.3534C4.16986 35.4392 3.65625 34.1992 3.65625 32.9062V18.2812C3.65625 16.9883 4.16986 15.7483 5.0841 14.8341C5.99834 13.9199 7.23832 13.4063 8.53125 13.4062H28.0312C29.3242 13.4062 30.5642 13.9199 31.4784 14.8341C32.3926 15.7483 32.9062 16.9883 32.9062 18.2812" stroke={stroke} strokeWidth="2.4375" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M10.9688 13.4062V8.53125C10.9688 6.59185 11.7392 4.73189 13.1105 3.36053C14.4819 1.98917 16.3419 1.21875 18.2812 1.21875C20.2206 1.21875 22.0806 1.98917 23.452 3.36053C24.8233 4.73189 25.5938 6.59185 25.5938 8.53125V13.4062" stroke={stroke} strokeWidth="2.4375" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M25.5938 23.1562H35.3438" stroke={stroke} strokeWidth="2.4375" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M25.5938 28.0312H35.3438" stroke={stroke} strokeWidth="2.4375" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default ChallengeDifficultyIcon;
