import {
  Box,
  Button,
  Stack,
  Typography,
} from '@mui/material';
import MODAL_KEYS from 'assets/constants/modalKeys';
import { useEventDetails } from 'reactQuery/queries';
import { useDate, useLocale, useModal } from 'util/hooks';

const Wrapper = ({ children }) => (
  <Box sx={{
    height: 344,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }}
  >
    <Stack sx={{
      alignItems: 'center',
      justifyContent: 'center',
    }}
    >
      {children}
    </Stack>
  </Box>
);

const EventRegistrationState = () => {
  const { t } = useLocale();
  const { format } = useDate();
  const { addModal } = useModal();

  const {
    data: eventDetails = {},
    refetch: refetchEvent = () => { },
  } = useEventDetails();

  const {
    name = '',
    teamMaxCapacity: max = 0,
    teamMinCapacity: min = 0,
    isRegistered = true,
    eventHasStarted,
    eventHasEnded,
    registrationHasStarted,
    registrationHasEnded,
    registrationStartAt,
    isExactCapacity,
  } = eventDetails;

  const handleRegistration = (e) => {
    addModal({
      key: MODAL_KEYS.eventRegistration,
      props: {
        eventDetails,
        refetchEvent,
        isViewEvent: true,
      },
    });
  };

  const getContent = () => {
    const title = `${t('events.registerIn')} ${name}`;
    const description = isExactCapacity
      ? t('events.teamCapacityExact', { count: min })
      : t('events.teamCapacityRange', { min, max });

    if (isRegistered && !eventHasStarted) {
      return {
        title: t('common.comingSoon'),
      };
    }

    if (eventHasEnded) {
      return {
        title: t('events.notRegistered'),
      };
    }

    if (registrationHasEnded) {
      return {
        title: t('events.registrationClosed'),
        description: t('events.sorryRegistrationClosed'),
      };
    }

    if (!registrationHasStarted) {
      return {
        title: t('events.registrationOpenSoon'),
        description: t('events.registrationWillOpenAt', { date: format(registrationStartAt, 'PPp') }),
      };
    }

    return {
      title,
      description,
      withButton: true,
    };
  };

  const {
    title = '',
    description = '',
    withButton = false,
  } = getContent();

  return (
    <Wrapper>
      <Typography variant="bodyMediumMedium">
        {title}
      </Typography>
      {description && (
        <Typography variant="bodyStandardRegular" color="text.secondary">
          {description}
        </Typography>
      )}
      {withButton && (
        <Button
          variant="contained"
          onClick={handleRegistration}
          sx={{ mt: 5 }}
        >
          {t('events.registerNow')}
        </Button>
      )}
    </Wrapper>
  );
};

export default EventRegistrationState;
