import { Helmet } from 'react-helmet';

import { useLocale } from 'util/hooks';
import SuspenseWrapper from './SuspenseWrapper/SuspenseWrapper';

const Page = ({ title, titleKey, children }) => {
  const { t } = useLocale();
  const resolvedTitle = title || (titleKey ? t(`pageTitles.${titleKey}`) : 'Flagyard');

  return (
    <SuspenseWrapper>
      <Helmet>
        <title>{resolvedTitle}</title>
      </Helmet>
      {children}
    </SuspenseWrapper>
  );
};

export default Page;
