import END_POINTS from 'services/endPoints';
import downloadApi from '../downloadApi';

const downloadInvoice = (id, invoiceNumber) => downloadApi({
  path: END_POINTS.downloadInvoice(id),
  fileName: `Flagyard_invoice_#${invoiceNumber}.pdf`,
})
  .then((res) => Promise.resolve(res))
  .catch((error) => Promise.reject(error));

export default downloadInvoice;
