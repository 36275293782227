import { Badge, Box, Typography } from '@mui/material';

const LabelWithBadge = (props) => {
  const {
    label = '',
    badgeContent = 0,
    labelProps = {},
    badgeProps = {},
  } = props;

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', width: 1 }}>
      <Typography variant="bodyStandardMedium" {...labelProps}>
        {label}
      </Typography>
      <Box sx={{ mx: 6 }}>
        <Badge
          badgeContent={badgeContent}
          color="transparency"
          showZero
          max={99}
          {...badgeProps}
        />
      </Box>
    </Box>
  );
};

export default LabelWithBadge;
