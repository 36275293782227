import { useMemo, useState } from 'react';
import * as Yup from 'yup';

import {
  Typography,
  Box,
  Alert,
  Link,
  Card,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { PATHS } from 'routes';
import { loginService } from 'services';
import { FormPasswordField, FormTextField } from 'components/form';
import { useLocale, useFastForm, useRecaptcha } from 'util/hooks';
import { RecaptchaNotice } from 'components/helpers';
import { useLocation } from 'react-router-dom';

const LoginForm = (props) => {
  const { onLoginSuccess } = props;

  const [loginError, setLoginError] = useState('');
  const { executeRecaptcha } = useRecaptcha({ action: 'LOGIN' });
  const location = useLocation();
  const { t } = useLocale();

  const defaultValues = {
    username: '',
    password: '',
  };

  const validationSchema = useMemo(() => (Yup.object({
    username: Yup.string()
      .required(t('login.enterEmail/username')),
    password: Yup.string()
      .required(t('password.enterPassword')),
  })), [t]);

  const onSubmit = async (values) => {
    const loginCredentials = {
      username: values.username,
      password: values.password,
    };

    try {
      const recaptchaToken = await executeRecaptcha();
      const data = await loginService(loginCredentials, { recaptchaToken });
      onLoginSuccess(data);
    } catch (error) {
      setLoginError(error.message || t('login.wrongCredentials'));
    }
  };

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useFastForm({
    defaultValues,
    validationSchema,
  });

  const form = (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box
        sx={{
          display: 'grid',
          rowGap: 8,
          alignItems: 'center',
          py: 10,
          px: {
            sm: 25,
            xs: 5,
          },
        }}
      >
        {loginError
          && (
            <Alert severity="error" sx={{ width: 1, mb: 8 }}>
              {loginError}
            </Alert>
          )}
        <Typography
          sx={{
            marginBottom: 4,
          }}
          variant="h4"
        >
          {t('login.loginHeader')}
        </Typography>
        <Box>
          <FormTextField
            name="username"
            control={control}
            autoComplete="username"
            label={t('login.email/username')}
            fullWidth
            autoFocus
          />
        </Box>
        <Box>
          <FormPasswordField
            name="password"
            control={control}
            showHelper={false}
            autoComplete="current-password"
            label={t('common.password')}
            fullWidth
          />
        </Box>
        <LoadingButton
          sx={{ my: 2 }}
          type="submit"
          variant="contained"
          size="large"
          fullWidth
          loading={isSubmitting}
          disabled={isSubmitting}
        >
          {t('common.login')}
        </LoadingButton>
        <Box sx={{ textAlign: 'center' }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              flexWrap: 'wrap',
            }}
          >
            <Typography variant="bodyStandardRegular">
              {t('login.notMember')}
              &nbsp;
            </Typography>
            <Link to={{ ...location, pathname: `/${PATHS.register}` }}>
              {t('common.register')}
            </Link>
          </Box>
          <Link
            to={`/${PATHS.forgotPassword}`}
          >
            {t('password.forgotPassword')}
          </Link>
        </Box>
        <Box>
          <RecaptchaNotice />
        </Box>
      </Box>
    </form>
  );

  return (
    <Card>
      {form}
    </Card>
  );
};

export default LoginForm;
