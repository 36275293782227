import END_POINTS from '../../endPoints';
import METHODS from '../../methods';
import api from '../../api';

const removeTeamMember = (memberId) => api(
  END_POINTS.removeTeamMember(memberId),
  {
    method: METHODS.DELETE,
  },
)
  .then((res) => Promise.resolve(res))
  .catch((error) => Promise.reject(error));

export default removeTeamMember;
