import { useTheme } from '@mui/styles';

const WithdrawIcon = (props) => {
  const { palette } = useTheme();
  const {
    stroke = palette.text.primary,
    fill = 'none',
    ...rest
  } = props;

  return (
    <svg width="18" height="16" viewBox="0 0 18 16" fill={fill} xmlns="http://www.w3.org/2000/svg" {...rest}>
      <path d="M1.5 8.00195H12" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M9.5 10.502L12 8.00195L9.5 5.50195" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M3.2041 11.0664C4.62034 13.9758 7.84616 15.5354 11.006 14.8383C14.1659 14.1412 16.4361 11.3692 16.4968 8.13395C16.5575 4.89869 14.3928 2.04347 11.2613 1.22838C8.12984 0.413281 4.84779 1.85076 3.32343 4.70504" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};
export default WithdrawIcon;
