import { getAllTransactions } from 'services';
import { useCustomQuery } from 'reactQuery';

const useTransactions = () => {
  const query = useCustomQuery({
    queryKey: ['transactions'],
    queryFn: getAllTransactions,
  });

  return { ...query };
};

export default useTransactions;
