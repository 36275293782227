import { useTheme } from '@mui/styles';

const BloodDropWithHashFilledIcon = (props) => {
  const { palette } = useTheme();
  const {
    stroke = palette.text.red,
    fill = palette.text.red,
    ...rest
  } = props;

  return (
    <svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <rect width="45" height="45" rx="7.125" fill={fill} fillOpacity="0.2" />
      <path d="M27.146 31.1763C25.9856 32.3367 24.4118 32.9886 22.7708 32.9886C21.1297 32.9886 19.5559 32.3367 18.3955 31.1763C17.2351 30.016 16.5833 28.4422 16.5833 26.8011C16.5833 22.337 20.6606 15.6068 22.1951 13.2345C22.2568 13.1377 22.3419 13.0581 22.4425 13.003C22.5431 12.9479 22.656 12.9189 22.7708 12.9189C22.8855 12.9189 22.9984 12.9479 23.099 13.003C23.1996 13.0581 23.2847 13.1377 23.3464 13.2345C23.4701 13.4256 23.6103 13.6451 23.7635 13.8894M23.7635 13.8894C24.2483 14.6624 24.8637 15.684 25.5 16.8444M23.7635 13.8894L25.5 16.8444M25.5 16.8444C25.7195 17.2448 25.9416 17.6617 26.1616 18.0906" stroke={stroke} strokeWidth="1.375" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M22.7708 30.242C22.3191 30.242 21.8719 30.153 21.4546 29.9801C21.0374 29.8072 20.6583 29.5537 20.3391 29.2342C20.0199 28.9147 19.7667 28.5355 19.5941 28.1181C19.4215 27.7007 19.3329 27.2534 19.3333 26.8018" stroke={stroke} strokeWidth="1.375" strokeLinecap="round" strokeLinejoin="round" />
      <g clipPath="url(#clip0_9674_140258)">
        <path d="M24.3438 23.2139H31.1793" stroke={stroke} strokeWidth="0.776582" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M23.2917 26.3721H30.1273" stroke={stroke} strokeWidth="0.776582" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M27.236 20.5898L24.6069 28.477" stroke={stroke} strokeWidth="0.776582" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M29.8654 20.5898L27.2363 28.477" stroke={stroke} strokeWidth="0.776582" strokeLinecap="round" strokeLinejoin="round" />
      </g>
      <defs>
        <clipPath id="clip0_9674_140258">
          <rect width="9.49947" height="9.49947" fill="white" transform="translate(23 20)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default BloodDropWithHashFilledIcon;
