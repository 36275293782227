import { useTranslation } from 'react-i18next';

const useLocale = () => {
  const translation = useTranslation();
  const { i18n } = translation;
  const { language, changeLanguage } = i18n;

  const dir = i18n.dir();
  const isAr = language.startsWith('ar');
  const isRtl = dir === 'rtl';

  return {
    ...translation,
    dir,
    language,
    isAr,
    isRtl,
    changeLanguage,
  };
};

export default useLocale;
