import { Box } from '@mui/material';

import { useLocale, usePageTitle } from 'util/hooks';
import { usePlanDetails } from 'reactQuery/queries';
import { useEffect } from 'react';
import PlanHeader from './sections/PlanHeader';
import PlanStats from './sections/PlanStats';
import PlanInfo from './sections/PlanInfo';

const ViewPlanContainer = (props) => {
  const { isAr } = useLocale();
  const setPageTitle = usePageTitle();

  const {
    data: planDetails,
    refetch: fetchPlanDetails,
  } = usePlanDetails();

  useEffect(() => {
    const pageTitle = isAr ? planDetails.nameAr : planDetails.nameEn;
    setPageTitle(pageTitle);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAr, planDetails.nameAr, planDetails.nameEn]);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }} maxWidth="xl">
      <PlanHeader planDetails={planDetails} />
      <PlanStats planDetails={planDetails} />
      <PlanInfo planDetails={planDetails} fetchPlanDetails={fetchPlanDetails} />
    </Box>
  );
};

export default ViewPlanContainer;
