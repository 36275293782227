import { useTheme } from '@mui/styles';

const CrossCircleIcon = (props) => {
  const theme = useTheme();
  const {
    stroke = theme.palette.text.secondary,
    fill = 'none',
    ...rest
  } = props;

  return (
    <svg width="22" height="22" viewBox="0 0 22 22" fill={fill} xmlns="http://www.w3.org/2000/svg" stroke={stroke} {...rest}>
      <path d="M6.33203 15.6667L15.6654 6.33337" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M15.6654 15.6667L6.33203 6.33337" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M11 21C16.5228 21 21 16.5228 21 11C21 5.47715 16.5228 1 11 1C5.47715 1 1 5.47715 1 11C1 16.5228 5.47715 21 11 21Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default CrossCircleIcon;
