import { useTheme } from '@mui/styles';

const PeoplePlusIcon = (props) => {
  const { palette } = useTheme();
  const {
    stroke = palette.text.blue,
    ...rest
  } = props;

  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <g clipPath="url(#clip0_10476_148312)">
        <path d="M28.75 38.75C34.2728 38.75 38.75 34.2728 38.75 28.75C38.75 23.2272 34.2728 18.75 28.75 18.75C23.2272 18.75 18.75 23.2272 18.75 28.75C18.75 34.2728 23.2272 38.75 28.75 38.75Z" stroke={stroke} strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M28.75 23.75V33.75" stroke={stroke} strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M23.75 28.75H33.75" stroke={stroke} strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M17.97 17.522C16.1149 15.8859 13.7234 14.9885 11.25 15.0003C8.80084 14.9811 6.4298 15.8614 4.58656 17.4742C2.74332 19.0871 1.55608 21.3203 1.25 23.7503" stroke={stroke} strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M11.25 12.5C14.3566 12.5 16.875 9.9816 16.875 6.875C16.875 3.7684 14.3566 1.25 11.25 1.25C8.1434 1.25 5.625 3.7684 5.625 6.875C5.625 9.9816 8.1434 12.5 11.25 12.5Z" stroke={stroke} strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M27.5 10C29.9162 10 31.875 8.04125 31.875 5.625C31.875 3.20875 29.9162 1.25 27.5 1.25C25.0838 1.25 23.125 3.20875 23.125 5.625C23.125 8.04125 25.0838 10 27.5 10Z" stroke={stroke} strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M33.9998 15.0002C33.4031 13.9673 32.5687 13.0917 31.5658 12.446C30.5628 11.8002 29.4204 11.403 28.2332 11.2873C27.046 11.1716 25.8483 11.3407 24.7396 11.7807C23.6309 12.2206 22.643 12.9186 21.8582 13.8168" stroke={stroke} strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
      </g>
      <defs>
        <clipPath id="clip0_10476_148312">
          <rect width="40" height="40" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default PeoplePlusIcon;
