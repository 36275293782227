import { useTheme } from '@mui/styles';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { useLocale } from 'util/hooks';
import {
  FlagIcon,
  FlagWithHashIcon,
  CheckCircleOutline,
  ProgressFilledIcon,
  OutlinedCircleWithDash,
} from 'assets/icons';

import { useMachineDetails } from 'reactQuery/queries';

const MachineProgress = ({ icon, label, textColor }) => (
  <Box
    sx={{
      display: 'flex',
      alignItems: 'center',
    }}
  >
    <Box sx={{
      display: 'flex',
      alignSelf: 'center',
      width: 16,
      height: 16,
      mr: 3,
    }}
    >
      {icon}
    </Box>
    <Box>
      <Typography
        noWrap
        variant="bodyStandardRegular"
        color={textColor}
      >
        {label}
      </Typography>
    </Box>
  </Box>
);

const MachineProgressCard = () => {
  const { t } = useLocale();
  const { palette } = useTheme();

  const {
    data: machineDetails = {},
  } = useMachineDetails();

  const {
    progress = {},
  } = machineDetails || {};

  const isUserFlagCompleted = progress?.completedUserFlag;
  const isRootFlagCompleted = progress?.completedRootFlag;

  const progressCategories = [
    {
      key: 'user-flag',
      flagIcon: <FlagIcon width="16" height="16" />,
      flagLabel: t('machines.userFlag'),
      isCompleted: isUserFlagCompleted,
    },
    {
      key: 'root-flag',
      flagIcon: <FlagWithHashIcon width="16" height="16" />,
      flagLabel: t('machines.rootFlag'),
      isCompleted: isRootFlagCompleted,
    },
  ];

  const renderCompletionLabel = (isCompleted) => (isCompleted
    ? t('common.completed') : t('common.incomplete'));

  const renderCompletionIcon = (isCompleted) => (isCompleted
    ? (
      <CheckCircleOutline
        stroke={palette.success.main}
      />
    ) : <OutlinedCircleWithDash />
  );

  const userProgress = progressCategories.map(({
    key,
    flagIcon,
    flagLabel,
    isCompleted,
  }) => (
    <Stack
      key={key}
      direction="row"
      justifyContent="space-between"
      flexWrap="wrap"
    >
      <Box sx={{
        display: 'flex',
        flex: 1,
        textAlign: 'start',
      }}
      >
        <MachineProgress
          textColor="text.secondary"
          label={flagLabel}
          icon={flagIcon}
        />
      </Box>
      <Box sx={{
        display: 'flex',
        flex: 1,
        textAlign: 'start',
      }}
      >
        <MachineProgress
          textColor="text.primary"
          label={renderCompletionLabel(isCompleted)}
          icon={renderCompletionIcon(isCompleted)}
        />
      </Box>
    </Stack>
  ));

  return (
    <Card
      key="machine-progress-card"
      sx={{
        minWidth: 300,
        minHeight: 161,
        height: 1,
        py: 6,
        px: 5,
      }}
    >
      <Stack
        direction="row"
        alignItems="center"
        spacing={4}
      >
        <Box
          sx={{
            width: 48,
            height: 44,
          }}
        >
          <ProgressFilledIcon width="48" height="44" />
        </Box>
        <Typography
          variant="bodyStandardMedium"
          color="text.lightGray"
        >
          {t('common.yourProgress')}
        </Typography>
      </Stack>
      <Box mt={5}>
        <Stack ml={{ md: 17 }} spacing={4}>
          {userProgress}
        </Stack>
      </Box>
    </Card>
  );
};

export default MachineProgressCard;
