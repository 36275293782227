import { DataGrid as MUIDataGrid, GridRow } from '@mui/x-data-grid';
import { Box, LinearProgress, Link } from '@mui/material';

import {
  SearchToolbar,
} from '../common';
import {
  CustomNoRowsOverlay,
} from './helpers';

const LinkRow = (props) => {
  const { toPath = '', ...rowProps } = props;

  const to = toPath
    ? `${toPath}/${rowProps.rowId}`
    : rowProps.rowId;

  return (
    <Link
      to={to}
      sx={{
        color: 'inherit',
        fontSize: 'inherit',
        fontWeight: 'inherit',
      }}
    >
      <GridRow {...rowProps} />
    </Link>
  );
};

const DataList = (props) => {
  const {
    rows = [],
    columns = [],
    components = {},
    componentsProps = {},
    withSearch = true,
    checkboxSelection = true,
    useLinkRow = false,
    sx = {},
    ...rest
  } = props;

  const containerHeight = rows.length ? ((rows.length + 2) * 80) : 400;
  const styles = {
    ...sx,
    '& .MuiDataGrid-virtualScroller': {
      minHeight: 0,
      ...sx['& .MuiDataGrid-virtualScroller'],
    },
    '& .MuiDataGrid-columnHeaders': {
      background: 'none',
      border: 'none',
      ...sx['& .MuiDataGrid-columnHeaders'],
    },
    '& .Mui-selected': {
      border: 1,
      borderColor: 'primary.main',
      ...sx['& .Mui-selected'],
    },
    '& .MuiDataGrid-row': {
      width: '99%',
      marginTop: 5,
      borderRadius: 1,
      backgroundColor: 'paper.background',
      '&:hover': {
        backgroundColor: 'background.default',
        cursor: 'pointer',
      },
      ...sx['& .MuiDataGrid-row'],
    },
    '& .MuiDataGrid-cell': {
      border: 'none',
      ...sx['& .MuiDataGrid-cell'],
    },
  };

  return (
    <Box sx={{ height: containerHeight, width: '100%' }}>
      <MUIDataGrid
        // -----Data-----
        rows={rows}
        columns={columns}
        // -----Components-----
        components={{
          Row: useLinkRow ? LinkRow : GridRow,
          LoadingOverlay: LinearProgress,
          NoRowsOverlay: CustomNoRowsOverlay, // No data
          ...(withSearch && { Toolbar: SearchToolbar }),
          ...components,
        }}
        componentsProps={{
          ...componentsProps,
          ...(withSearch && {
            toolbar: {
              ...componentsProps.toolbar,
            },
          }),
        }}
        // -----Pagination-----
        hideFooterPagination
        // -----Styles-----
        sx={styles}
        // autoHeight
        rowHeight={68}
        hideFooter
        // -----UI Options-----
        checkboxSelection={checkboxSelection}
        disableSelectionOnClick
        disableColumnFilter
        disableColumnSelector
        disableColumnMenu
        // eslint-disable-next-line consistent-return
        onCellClick={(params, event) => {
          if (useLinkRow && params?.field === '__check__') {
            // Stop navigation
            return event.stopPropagation();
          }
        }}
        {...rest}
      />
    </Box>
  );
};

export default DataList;
