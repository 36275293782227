import { useState } from 'react';
import {
  Box,
  Menu,
  MenuItem,
  IconButton,
  Typography,
} from '@mui/material';

import MODAL_KEYS from 'assets/constants/modalKeys';
import { TableActionIcon } from 'assets/icons';
import { useLocale, useModal } from 'util/hooks';

const SubmissionActions = (props) => {
  const { modalProps = {} } = props;

  const { t } = useLocale();
  const { addModal } = useModal();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleMenuClick = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const openModal = () => {
    // Close menu
    handleMenuClose();

    // Open  modal
    addModal({
      key: MODAL_KEYS.invalidateSubmission,
      props: {
        modalProps,
      },
    });
  };

  return (
    <>
      <IconButton onClick={handleMenuClick} aria-label="actions list">
        <TableActionIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleMenuClose}
      >
        <Box sx={{ width: 180 }}>
          <MenuItem disableRipple onClick={openModal}>
            <Typography variant="bodySmallMedium">
              {t('events.InvalidateSubmission')}
            </Typography>
          </MenuItem>
        </Box>
      </Menu>
    </>
  );
};

export default SubmissionActions;
