import Box from '@mui/material/Box';

import { useLocale } from 'util/hooks';
import { PlansFilledIcon } from 'assets/icons';

import useChallengeInstance from '../Players/helpers/useChallengeInstance';
import ChallengeDetailsCardWrapper from '../Players/helpers/ChallengeDetailsCardWrapper';

const ChallengeInstance = (props) => {
  const { t } = useLocale();
  const {
    subtitle = null,
    header = null,
    component = null,
  } = useChallengeInstance({ isContentDisabled: false });

  return (
    <Box key="instance">
      <ChallengeDetailsCardWrapper
        icon={<PlansFilledIcon width="48" height="44" />}
        title={t('challenges.instance')}
        header={header}
        subtitle={subtitle}
        component={component}
        cardProps={{
          sx: { p: 14 },
        }}
      />
    </Box>
  );
};

export default ChallengeInstance;
