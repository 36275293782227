import { DataList } from 'components/core';

import useTableColumns from './useTableColumns';

const EventLeaderBoardTable = (props) => {
  const {
    leaderBoard = [],
    isFetching = false,
    handleRowClick = () => { },
  } = props;

  const columns = useTableColumns();

  return (
    <DataList
      rows={leaderBoard}
      columns={columns}
      loading={isFetching}
      withSearch={false}
      headerHeight={0} // Hide column header
      checkboxSelection={false}
      onRowClick={({ row }) => {
        const { id: teamId = '' } = row || {};
        handleRowClick(null, teamId); // placeholder for click event
      }}
      sx={{
        '& .MuiDataGrid-row': {
          width: '100%',
        },
      }}
    />
  );
};

export default EventLeaderBoardTable;
