import { Box } from '@mui/material';

import SkillsSummaryChart from '../helpers/SkillsSummaryChart';
import ActivityBreakdownChart from '../helpers/ActivityBreakdownChart';

const PlayerStatusCharts = (props) => {
  const { profileDetails = {} } = props;

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
        rowGap: 10,
        columnGap: 0.1,
      }}
    >
      <Box sx={{
        width: {
          xs: 1,
          lg: 0.45,
        },
        maxWidth: {
          xl: '500px',
        },
      }}
      >
        <SkillsSummaryChart profileDetails={profileDetails} />
      </Box>
      <Box
        sx={{
          width: {
            xs: 1,
            lg: 0.45,
          },
          maxWidth: {
            xl: '700px',
          },
        }}
      >
        <ActivityBreakdownChart profileDetails={profileDetails} />
      </Box>
    </Box>
  );
};

export default PlayerStatusCharts;
