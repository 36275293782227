import { useTheme } from '@mui/styles';

const ServerFilledIcon = (props) => {
  const { palette } = useTheme();
  const {
    fill = palette.text.lightPurple,
    stroke = palette.text.lightPurple,
    ...rest
  } = props;

  return (
    <svg width="46" height="45" viewBox="0 0 46 45" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <rect x="0.5" width="45" height="45" rx="7.125" fill={fill} fillOpacity="0.2" />
      <g clipPath="url(#clip0_10023_141706)">
        <path d="M17.25 18.9375C17.1882 18.9375 17.1278 18.9558 17.0764 18.9902C17.025 19.0245 16.9849 19.0733 16.9613 19.1304C16.9376 19.1875 16.9314 19.2503 16.9435 19.311C16.9556 19.3716 16.9853 19.4273 17.029 19.471C17.0727 19.5147 17.1284 19.5444 17.189 19.5565C17.2497 19.5686 17.3125 19.5624 17.3696 19.5387C17.4267 19.5151 17.4755 19.475 17.5098 19.4236C17.5442 19.3722 17.5625 19.3118 17.5625 19.25C17.5625 19.1671 17.5296 19.0876 17.471 19.029C17.4124 18.9704 17.3329 18.9375 17.25 18.9375Z" stroke={stroke} strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M17.25 26.4375C17.1882 26.4375 17.1278 26.4558 17.0764 26.4902C17.025 26.5245 16.9849 26.5733 16.9613 26.6304C16.9376 26.6875 16.9314 26.7503 16.9435 26.811C16.9556 26.8716 16.9853 26.9273 17.029 26.971C17.0727 27.0147 17.1284 27.0444 17.189 27.0565C17.2497 27.0686 17.3125 27.0624 17.3696 27.0387C17.4267 27.0151 17.4755 26.975 17.5098 26.9236C17.5442 26.8722 17.5625 26.8118 17.5625 26.75C17.5625 26.6671 17.5296 26.5876 17.471 26.529C17.4124 26.4704 17.3329 26.4375 17.25 26.4375Z" stroke={stroke} strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M29.127 15.5C30.1215 15.5 31.0753 15.8951 31.7786 16.5983C32.4819 17.3016 32.877 18.2554 32.877 19.25C32.877 20.2446 32.4819 21.1984 31.7786 21.9016C31.0753 22.6049 30.1215 23 29.127 23H17.877C16.8824 23 15.9286 22.6049 15.2253 21.9016C14.522 21.1984 14.127 20.2446 14.127 19.25C14.127 18.2554 14.522 17.3016 15.2253 16.5983C15.9286 15.8951 16.8824 15.5 17.877 15.5H29.127Z" stroke={stroke} strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M29.127 23C29.6194 23 30.107 23.097 30.562 23.2855C31.017 23.4739 31.4304 23.7501 31.7786 24.0983C32.1268 24.4466 32.403 24.86 32.5915 25.3149C32.78 25.7699 32.877 26.2575 32.877 26.75C32.877 27.2425 32.78 27.7301 32.5915 28.1851C32.403 28.64 32.1268 29.0534 31.7786 29.4016C31.4304 29.7499 31.017 30.0261 30.562 30.2145C30.107 30.403 29.6194 30.5 29.127 30.5H17.877C16.8824 30.5 15.9286 30.1049 15.2253 29.4016C14.522 28.6984 14.127 27.7446 14.127 26.75C14.127 25.7554 14.522 24.8016 15.2253 24.0983C15.9286 23.3951 16.8824 23 17.877 23H29.127Z" stroke={stroke} strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M22.252 19.25H29.127" stroke={stroke} strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M22.252 26.75H29.127" stroke={stroke} strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
      </g>
      <defs>
        <clipPath id="clip0_10023_141706">
          <rect width="20" height="20" fill="white" transform="translate(13.5 13)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ServerFilledIcon;
