import { Box } from '@mui/material';
import { useTheme } from '@mui/styles';

import ONLINE_STATUSES from 'assets/constants/onlineStatuses';

const KEY_MAPPING = {
  online: ONLINE_STATUSES.online,
  visible: ONLINE_STATUSES.online,
  live: ONLINE_STATUSES.online,
  offline: ONLINE_STATUSES.offline,
  hidden: ONLINE_STATUSES.offline,
  active: ONLINE_STATUSES.online,
  banned: ONLINE_STATUSES.offline,
};

const SIZE_RATIO = 0.57;

const StatusBadge = (props) => {
  const {
    status = ONLINE_STATUSES.online,
    size = 14,
  } = props;

  const theme = useTheme();

  const COLORS = {
    online: theme.palette.success,
    offline: theme.palette.error,
  };

  const styles = COLORS[KEY_MAPPING[status]];

  return (
    <Box sx={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: styles.background,
      borderRadius: 5,
      height: size,
      width: size,
    }}
    >
      <Box sx={{
        backgroundColor: styles.main,
        borderRadius: 5,
        height: SIZE_RATIO * size,
        width: SIZE_RATIO * size,
      }}
      />
    </Box>
  );
};

export default StatusBadge;
