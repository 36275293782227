import ArrowCircleUpOutlinedIcon from '@mui/icons-material/ArrowCircleUpOutlined';
import {
  CheckCircleOutline,
  CrossCircleIcon,
  FileEditIcon,
} from 'assets/icons';
import { useLocale, useModal, useSnackbar } from 'util/hooks';
import { useTheme } from '@mui/styles';
import { GridActionsCellItem } from '@mui/x-data-grid';
import { togglePlanFeatureComingSoon, togglePlanFeaturePromoted } from 'services';
import { Button } from '@mui/material';
import MODAL_KEYS from 'assets/constants/modalKeys';

const useTableColumns = (props) => {
  const { refetch: refetchFeatures } = props;
  const { t, isAr, dir } = useLocale();
  const { palette } = useTheme();
  const { addModal } = useModal();
  const snack = useSnackbar();

  const showSnackError = (error) => snack({
    severity: 'error',
    message: error.message || t('common.somethingWrong'),
  });

  const togglePromoted = async (id) => {
    try {
      await togglePlanFeaturePromoted(id);
      refetchFeatures();
    } catch (error) {
      showSnackError(error);
    }
  };

  const toggleComingSoon = async (id) => {
    try {
      await togglePlanFeatureComingSoon(id);
      refetchFeatures();
    } catch (error) {
      showSnackError(error);
    }
  };

  const handlePriorityUpdate = ({ id, priority }) => {
    addModal({
      key: MODAL_KEYS.planFeaturePriority,
      props: { id, priority, refetch: refetchFeatures },
    });
  };

  const renderMark = (checked = false) => (checked
    ? <CheckCircleOutline stroke={palette.success.main} height={20} width={20} />
    : <CrossCircleIcon stroke={palette.error.main} height={20} width={20} />);

  return [
    {
      field: 'features',
      headerName: t('subscriptions.feature'),
      sortable: false,
      flex: 1,
      minWidth: 170,
      valueGetter: ({ row: { nameAr, nameEn } }) => (isAr ? nameAr : nameEn),
    },
    {
      field: 'comingSoon',
      headerName: t('common.comingSoon'),
      sortable: false,
      flex: 1,
      minWidth: 170,
      renderCell: (params) => {
        const { row: { comingSoon } } = params;
        return renderMark(comingSoon);
      },
    },
    {
      field: 'isPromoted',
      headerName: t('plans.features.promoted'),
      sortable: false,
      flex: 1,
      minWidth: 170,
      renderCell: (params) => {
        const { row: { promoted } } = params;
        return renderMark(promoted);
      },
    },
    {
      field: 'priority',
      headerName: t('plans.features.priority'),
      sortable: false,
      flex: 1,
      minWidth: 170,
      valueGetter: ({ row: { priority } }) => (priority),
    },
    {
      field: 'actions',
      headerName: t('common.actions'),
      flex: 1,
      type: 'actions',
      minWidth: 100,
      getActions: (params) => {
        const { row: { id, priority } } = params;

        const actions = [
          <GridActionsCellItem
            component={Button}
            sx={{ width: 1 }}
            dir={dir}
            icon={<FileEditIcon width="20" height="20" />}
            label={t('plans.features.toggleComingSoon')}
            onClick={() => toggleComingSoon(id)}
            showInMenu
          />,
          <GridActionsCellItem
            component={Button}
            sx={{ width: 1 }}
            dir={dir}
            icon={<FileEditIcon width="20" height="20" />}
            label={t('plans.features.togglePromoted')}
            onClick={() => togglePromoted(id)}
            showInMenu
          />,
          <GridActionsCellItem
            component={Button}
            sx={{ width: 1 }}
            dir={dir}
            icon={<ArrowCircleUpOutlinedIcon sx={{ color: 'text.secondary' }} />}
            label={t('plans.features.editPriority')}
            onClick={() => handlePriorityUpdate({ id, priority })}
            showInMenu
          />,
        ];

        return actions;
      },
    },
  ];
};

export default useTableColumns;
