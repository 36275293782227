import { useTheme } from '@mui/styles';

const CopyIcon = (props) => {
  const { palette } = useTheme();
  const {
    stroke = palette.secondary.main,
    fill = 'none',
    ...rest
  } = props;

  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill={fill} xmlns="http://www.w3.org/2000/svg" {...rest}>
      <g clipPath="url(#clip0_7025_8963)">
        <path d="M11 14.5C11 14.7652 10.8946 15.0196 10.7071 15.2071C10.5196 15.3946 10.2652 15.5 10 15.5H3C2.73478 15.5 2.48043 15.3946 2.29289 15.2071C2.10536 15.0196 2 14.7652 2 14.5V5.5C2 5.23478 2.10536 4.98043 2.29289 4.79289C2.48043 4.60536 2.73478 4.5 3 4.5H8.086C8.35103 4.50006 8.6052 4.60532 8.79267 4.79267L10.7073 6.70733C10.8947 6.8948 10.9999 7.14897 11 7.414V14.5Z" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M5 4.5V1.5C5 1.23478 5.10536 0.98043 5.29289 0.792893C5.48043 0.605357 5.73478 0.5 6 0.5H11.086C11.351 0.500057 11.6052 0.605321 11.7927 0.792667L13.7073 2.70733C13.8947 2.8948 13.9999 3.14897 14 3.414V10.5C14 10.7652 13.8946 11.0196 13.7071 11.2071C13.5196 11.3946 13.2652 11.5 13 11.5H11" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      </g>
      <defs>
        <clipPath id="clip0_7025_8963">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CopyIcon;
