import END_POINTS from 'services/endPoints';
import api from '../api';

const getPlanFeatures = (options) => api(
  END_POINTS.features,
  options,
)
  .then((res) => Promise.resolve(res))
  .catch((error) => Promise.reject(error));

export default getPlanFeatures;
