import {
  Box, Grid, Tooltip, Typography,
} from '@mui/material';
import {
  RankFilledIcon,
  BloodDropFilledIcon,
  SparklingFilledIcon,
  InformationCircleIcon,
} from 'assets/icons';
import { InfoCard } from 'components/molecules';
import {
  useAdminTeamDetails,
  useEventDetails,
  usePlayerTeamDetails,
} from 'reactQuery/queries';
import { useLocale, usePermission } from 'util/hooks';
import { STATUS } from 'assets/constants/statuses';
import PERMISSIONS from 'assets/constants/permissions';

const iconSize = {
  width: '45',
  height: '45',
};

const EventTeamStats = () => {
  const { t } = useLocale();
  const isTeamMember = usePermission(PERMISSIONS.teamViewOwn);
  const canViewTeamAsManager = usePermission(PERMISSIONS.teamViewOneByName);

  const { data: eventDetails = {} } = useEventDetails();

  const { data: adminTeamDetails = {} } = useAdminTeamDetails({
    options: {
      enabled: canViewTeamAsManager,
    },
  });

  const { data: playerTeamDetails = {} } = usePlayerTeamDetails({
    options: {
      enabled: isTeamMember,
    },
  });

  const { isFrozen = false } = eventDetails || {};

  const teamDetails = canViewTeamAsManager
    ? adminTeamDetails
    : playerTeamDetails;

  const {
    points = 0,
    rank = 0,
    status = '',
    firstBloods = 0,
  } = teamDetails || {};

  const isTeamBanned = status === STATUS.banned;

  const frozenRank = (
    <Box sx={{ ml: 2 }}>
      <Tooltip title={t('events.rankFrozen')} placement="top">
        <Box sx={{ display: 'flex', width: 14, height: 14 }}>
          <InformationCircleIcon width="14" height="14" />
        </Box>
      </Tooltip>
    </Box>
  );

  const noRankPlaceholder = (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Typography variant="bodyStandardBold" sx={{ mr: 2 }}>
        {t('dashboard.unranked')}
      </Typography>
      <Tooltip
        title={
          isTeamBanned
            ? t('events.bannedTeamRank')
            : t('dashboard.unrankedHint')
        }
        placement="top"
      >
        <Box>
          <InformationCircleIcon />
        </Box>
      </Tooltip>
    </Box>
  );

  const stats = [
    {
      key: 'team-active-points',
      icon: <SparklingFilledIcon {...iconSize} />,
      title: t('common.thePoints'),
      component: (
        <Typography variant="bodyStandardBold">
          {`${points} ${t('common.points')}`}
        </Typography>
      ),
    },
    {
      key: 'team-rank',
      icon: <RankFilledIcon {...iconSize} />,
      title: t('common.rank'),
      component: rank ? (
        <Box display="flex" alignItems="center">
          <Typography variant="bodyStandardBold">{`#${rank}`}</Typography>
          {isFrozen && frozenRank}
        </Box>
      ) : (
        noRankPlaceholder
      ),
    },
    {
      key: 'team-first-bloods',
      icon: <BloodDropFilledIcon {...iconSize} />,
      title: t('common.firstBloods'),
      component: (
        <Typography variant="bodyStandardBold">{firstBloods}</Typography>
      ),
    },
  ];

  return (
    <Grid container spacing={6}>
      {stats.map((stat) => (
        <InfoCard size="small" {...stat} />
      ))}
    </Grid>
  );
};

export default EventTeamStats;
