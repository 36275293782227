import {
  Box,
  Button,
  Typography,
} from '@mui/material';

import { useLocale, useModal } from 'util/hooks';
import { capitalizeFirstLetter } from 'util/helpers';

import { VisibilityBadge } from 'components/molecules';
import MODAL_KEYS from 'assets/constants/modalKeys';

const EventChallengeHeader = (props) => {
  const { challengeDetails = {} } = props;
  const {
    name = '',
    visible = false,
  } = challengeDetails;

  const { t } = useLocale();
  const { addModal } = useModal();

  const showOrHide = visible ? 'hide' : 'show';

  const handleButtonClick = () => {
    addModal({
      key: MODAL_KEYS.eventChallengeVisibility,
      props: {
        type: showOrHide,
        value: visible,
        challenges: [challengeDetails],
        listView: false,
      },
    });
  };

  return (
    <Box sx={{
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      flexWrap: 'wrap',
    }}
    >
      <Box sx={{
        display: 'flex',
        alignItems: 'center',
        columnGap: 2,
      }}
      >
        <Box>
          <Typography variant="bodyLargeBold">
            {capitalizeFirstLetter(name)}
          </Typography>
        </Box>
        <Box>
          <VisibilityBadge visible={visible} />
        </Box>
      </Box>
      <Button
        variant="contained"
        onClick={handleButtonClick}
      >
        {t(`challenges.actions.${showOrHide}`)}
      </Button>
    </Box>
  );
};

export default EventChallengeHeader;
