import { Box, Typography, useTheme } from '@mui/material';
import CHART_TYPES from 'assets/constants/chartTypes';
import {
  Chart,
  useRadarOptions,
} from 'components/chart';

import { useLocale } from 'util/hooks';

const SkillsSummaryChart = (props) => {
  const { profileDetails = {} } = props;

  const { palette } = useTheme();
  const { t } = useLocale();

  const {
    sumPerType: playerData = {},
    avgPerType: allPlayersData = {},
    user: { username = '' },
  } = profileDetails || {};

  const radarLabels = playerData?.map((challenge) => challenge.challengeType);
  const radarFirstDataset = playerData?.map((challenge) => challenge.sum);
  const radarSecondDataset = allPlayersData?.map((challenge) => challenge.average);
  const radarOption = useRadarOptions();

  const radarData = {
    labels: radarLabels,
    datasets: [
      {
        label: username,
        data: radarFirstDataset,
        backgroundColor: palette.text.transparentWhite,
        borderColor: palette.primary.contrastText,
        borderWidth: 1,
      }, {
        label: t('profile.playersAvg'),
        data: radarSecondDataset,
        backgroundColor: palette.text.transparentRed,
        borderColor: palette.primary.main,
        borderWidth: 1,
      },
    ],
  };

  return (
    <Box width={1}>
      <Box
        sx={{ mb: 7 }}
      >
        <Typography
          variant="bodyStandardMedium"
          sx={{ mx: 9, color: 'text.lightGray' }}
        >
          {t('profile.skillsSummary')}
        </Typography>
      </Box>
      <Chart
        type={CHART_TYPES.radar}
        data={radarData}
        options={radarOption}
      />
    </Box>
  );
};

export default SkillsSummaryChart;
