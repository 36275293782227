import {
  Button,
  Link,
  Typography,
} from '@mui/material';

import { useLocale } from 'util/hooks';
import { PATHS } from 'routes';

const UpgradeBackdropAction = () => {
  const { t } = useLocale();
  return (
    <>
      <Typography variant="bodyStandardBold" mt={1}>
        {t('challenges.detailsLocked')}
      </Typography>
      <Button
        size="small"
        variant="contained"
        component={Link}
        to={`/${PATHS.subscriptions}`}
        sx={{ mt: 4 }}
      >
        {t('common.UpgradeToUnlock')}
      </Button>
    </>
  );
};

export default UpgradeBackdropAction;
