import React, { Children, useEffect, useState } from 'react';
import { PropTypes } from 'prop-types';
import { useSwipeable } from 'react-swipeable';
import { Box, Card, Typography } from '@mui/material';

import { useLocale } from 'util/hooks';

import CirclePagination from './CirclePagination';

const defaultProps = {
  autoSlideTimeout: 3000,
  initialIndex: 0,
  initialPaused: false,
};

const Carousel = (props) => {
  const {
    children = [],
    autoSlideTimeout,
    initialIndex,
    initialPaused,
  } = props;

  const [activeIndex, setActiveIndex] = useState(initialIndex);
  const [paused, setPaused] = useState(initialPaused);

  const { t } = useLocale();

  const updateIndex = (newIndex) => {
    let updatedIndex = newIndex;

    if (updatedIndex < 0) {
      updatedIndex = Children.count(children) - 1;
    } else if (updatedIndex >= Children.count(children)) {
      updatedIndex = 0;
    }

    setActiveIndex(updatedIndex);
  };

  const incrementIndex = () => {
    updateIndex(activeIndex + 1);
  };

  const decrementIndex = () => {
    updateIndex(activeIndex - 1);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (!paused) {
        updateIndex(activeIndex + 1);
      }
    }, autoSlideTimeout);

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  });

  const handlers = useSwipeable({
    onSwipedLeft: () => updateIndex(activeIndex + 1),
    onSwipedRight: () => updateIndex(activeIndex - 1),
  });

  if (!React.Children.count(children)) {
    return (
      <Card sx={{
        width: 1,
        minWidth: { sm: 385 },
        height: 274,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      >
        <Typography
          variant="bodySmallMedium"
          color="text.secondary"
        >
          {t('dashboard.noAnnouncements')}
        </Typography>
      </Card>
    );
  }

  const handleClick = (e) => {
    const clickTarget = e.target;
    const clickTargetWidth = clickTarget.offsetWidth;
    const xCoordInClickTarget = e.clientX - clickTarget.getBoundingClientRect().left;

    if (clickTargetWidth / 2 > xCoordInClickTarget) {
      // Clicked left
      decrementIndex();
    } else {
      // Clicked right
      incrementIndex();
    }
  };

  return (
    <Box
      {...handlers}
      sx={{
        width: 1,
        overflow: 'hidden',
        minWidth: { sm: 385 },
      }}
      onClick={handleClick}
      onMouseEnter={() => setPaused(true)}
      onMouseLeave={() => setPaused(false)}
    >
      <Box
        sx={{
          whiteSpace: 'nowrap',
          transition: 'transform 0.3s',
          transform: `translateX(-${activeIndex * 100}%)`,
        }}
      >
        {children}
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          mt: 3,
        }}
      >
        <CirclePagination
          count={React.Children.count(children)}
          page={activeIndex}
          onChange={(value) => updateIndex(value)}
        />
      </Box>
    </Box>
  );
};

Carousel.propTypes = {
  autoSlideTimeout: PropTypes.number,
  initialIndex: PropTypes.number,
  initialPaused: PropTypes.bool,
};

Carousel.defaultProps = {
  autoSlideTimeout: defaultProps.autoSlideTimeout,
  initialIndex: defaultProps.initialIndex,
  initialPaused: defaultProps.initialPaused,
};

export default Carousel;
