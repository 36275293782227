import { useRecoilState } from 'recoil';
import {
  Box,
  Card,
  Stack,
  Switch,
  Typography,
} from '@mui/material';

import { PlanCard } from 'components/organisms';
import { useLocale } from 'util/hooks';
import { checkoutAtom } from 'recoil/atoms';

const SubscriptionPlans = (props) => {
  const { plans = [] } = props;

  const { t } = useLocale();
  const [checkoutState, setCheckoutState] = useRecoilState(checkoutAtom);
  const {
    billingOption = 'perYear',
  } = checkoutState || {};

  const onChange = (e) => {
    const newBillingOption = e?.target?.checked ? 'perYear' : 'perMonth';
    setCheckoutState((prev) => ({
      ...prev,
      billingOption: newBillingOption,
    }));
  };

  return (
    <Box>
      <Box sx={{
        display: 'flex',
        justifyContent: 'center',
        my: 7.5,
      }}
      >
        <Stack
          direction="row"
          spacing={3}
          alignItems="center"
        >
          <Typography>{t('subscriptions.billMonthly')}</Typography>
          <Switch
            id="subscription-period"
            name="subscription-period"
            checked={billingOption === 'perYear'}
            onChange={onChange}
          />
          <Typography>{t('subscriptions.billAnnually')}</Typography>
        </Stack>
      </Box>
      <Card sx={{
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: {
          xs: 'column',
          lg: 'row',
        },
        px: 10,
        py: 9,
        rowGap: 4,
        columnGap: 4,
      }}
      >
        {plans?.map((plan) => (
          <Box
            key={plan.id}
            sx={{
              width: { sm: 340 },
            }}
          >
            <PlanCard
              billingOption={billingOption}
              plan={plan}
            />
          </Box>
        ))}
      </Card>
    </Box>

  );
};

export default SubscriptionPlans;
