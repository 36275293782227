import { Typography, Box } from '@mui/material';

import { useLocale } from 'util/hooks';
import LAB_TYPES from 'assets/constants/labTypes';

const PlayerLabHeader = (props) => {
  const { type } = props;

  const { t } = useLocale();

  const header = type === LAB_TYPES.training
    ? t('labs.trainingLabs')
    : t('labs.competitiveLabs');

  const description = type === LAB_TYPES.training
    ? t('labs.trainingLabsDescription')
    : t('labs.competitiveLabsDescription');

  return (
    <Box>
      <Typography
        variant="h5"
        color="text.lighterGray"
      >
        {header}
      </Typography>
      <Typography
        variant="bodyStandardRegular"
        color="text.secondary"
      >
        {description}
      </Typography>
    </Box>
  );
};

export default PlayerLabHeader;
