import { useEffect } from 'react';
import { useRecoilValue, useResetRecoilState } from 'recoil';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import {
  Backdrop,
  Box,
  Card,
  Divider,
  LinearProgress,
} from '@mui/material';

import { checkoutAtom } from 'recoil/atoms';

import { PATHS } from 'routes';
import {
  useAuth,
  useLocale,
  useSnackbar,
} from 'util/hooks';
import { PAYFORT_COMMANDS } from 'assets/constants/paymentConstants';

import PromoCode from './sections/PromoCode';
import BillingOption from './sections/BillingOption';
import CheckoutTitle from './sections/CheckoutTitle';
import PriceBreakdown from './sections/PriceBreakdown';
import ConfirmPayment from './sections/ConfirmPayment';
import CheckoutPaymentMethod from './sections/CheckoutPaymentMethod';

const CardLoader = ({ open, sx, ...rest }) => (
  <>
    <LinearProgress />
    <Backdrop
      {...rest}
      open={open}
      sx={{
        position: 'absolute',
        zIndex: 1,
        ...sx,
      }}
    />
  </>
);

const CheckoutContainer = () => {
  const { t } = useLocale();
  const snack = useSnackbar();
  const navigate = useNavigate();
  const { refreshUser } = useAuth();
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();

  const checkoutState = useRecoilValue(checkoutAtom);
  const resetCheckoutState = useResetRecoilState(checkoutAtom);

  const {
    isCheckoutLoading,
  } = checkoutState || {};

  useEffect(() => {
    const isPurchase = searchParams.get('command') === PAYFORT_COMMANDS.PURCHASE;

    if (isPurchase && searchParams.has('success')) {
      // Handle adding a card successes and failures
      const success = searchParams.get('success');
      const message = searchParams.get('message');

      const isSuccess = success === 'true';

      // Show status message
      const snackMessage = message || (isSuccess
        ? t('paymentMethods.cardAddedSuccess')
        : t('common.errorMessage'));
      message && snack({
        severity: isSuccess ? 'success' : 'error',
        message: snackMessage,
      });

      if (isSuccess) {
        // Refresh user and navigate to subscription history
        refreshUser();
        resetCheckoutState();
        navigate(`/${PATHS.settings}/${PATHS.accountSubscriptions}`, { replace: true });
      } else {
        // Cleanup url and remove query params
        navigate(pathname, { replace: true });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, searchParams]);

  return (
    <Card
      sx={{
        maxWidth: 740,
        position: 'relative', // To contain CardLoader
      }}
    >
      {/* Avoid UI shift using minHeight */}
      <Box minHeight={4}>
        {isCheckoutLoading && <CardLoader open />}
      </Box>
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        px: { xs: 10, sm: 25 },
        py: 12,
        rowGap: 8,
      }}
      >
        <Box>
          <CheckoutTitle />
        </Box>
        <Box sx={{
          mb: 14,
        }}
        >
          <BillingOption />
        </Box>
        <Box>
          <CheckoutPaymentMethod />
        </Box>
        <Divider sx={{ mt: 6 }} />
        <Box>
          <PromoCode />
        </Box>
        <Box>
          <PriceBreakdown />
        </Box>
        <Box>
          <ConfirmPayment />
        </Box>
      </Box>
    </Card>
  );
};

export default CheckoutContainer;
