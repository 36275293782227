import PropTypes from 'prop-types';
import { Badge } from '@mui/material';

/**
 * Uses mui's Badge, sticky and works with children like Avatar
 */
const StickyBadge = (props) => {
  const {
    color,
    children,
    sx,
    ...rest
  } = props;

  return (
    <Badge
      overlap="circular"
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      variant="dot"
      {...rest}
      sx={{
        '& .MuiBadge-badge': {
          color,
          backgroundColor: color,
          '&::after': {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            borderRadius: '50%',
            animation: 'ripple 1.2s infinite ease-in-out',
            border: '2px solid currentColor',
            content: '""',
          },
        },
        '@keyframes ripple': {
          '0%': {
            transform: 'scale(.8)',
            opacity: 1,
          },
          '100%': {
            transform: 'scale(2.4)',
            opacity: 0,
          },
        },
        ...sx,
      }}
    >
      {children}
    </Badge>
  );
};

StickyBadge.propTypes = {
  color: PropTypes.string,
  children: PropTypes.node,
  sx: PropTypes.oneOfType([PropTypes.object]),
};

StickyBadge.defaultProps = {
  color: 'success.main',
  children: null,
  sx: {},
};

export default StickyBadge;
