import { useTheme } from '@mui/styles';

const InviteIcon = (props) => {
  const theme = useTheme();
  const {
    stroke = theme.palette.text.secondary,
    fill = theme.palette.paper.hover,
    ...rest
  } = props;

  return (
    <svg width="48" height="44" viewBox="0 0 44 38" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <rect width="44" height="38" rx="6" fill={fill} />
      <g clipPath="url(#clip0_1862_4532)">
        <path d="M26.375 28.375C29.1364 28.375 31.375 26.1364 31.375 23.375C31.375 20.6136 29.1364 18.375 26.375 18.375C23.6136 18.375 21.375 20.6136 21.375 23.375C21.375 26.1364 23.6136 28.375 26.375 28.375Z" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M26.375 20.875V25.875" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M23.875 23.375H28.875" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M12.625 23.3746C12.6256 22.4447 12.8567 21.5295 13.2975 20.7107C13.7384 19.8919 14.3753 19.1951 15.1512 18.6826C15.9272 18.1702 16.8181 17.858 17.7442 17.774C18.6703 17.69 19.6028 17.8368 20.4583 18.2013" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M18.25 16.5C20.1485 16.5 21.6875 14.961 21.6875 13.0625C21.6875 11.164 20.1485 9.625 18.25 9.625C16.3515 9.625 14.8125 11.164 14.8125 13.0625C14.8125 14.961 16.3515 16.5 18.25 16.5Z" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      </g>
      <defs>
        <clipPath id="clip0_1862_4532">
          <rect width="20" height="20" fill="white" transform="translate(12 9)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default InviteIcon;
