import { useLocale } from 'util/hooks';
import {
  GridOverlay,
} from '@mui/x-data-grid';

import { NoContentPlaceholder } from 'components/molecules';
// import { PATHS } from 'routes';

const CustomNoRowsOverlay = (props) => {
  const { type = 'none' } = props;
  const { t } = useLocale();

  const content = {
    none: {
      desc: [
        {
          text: t('common.noData'),
          variant: 'bodyMediumBold',
        },
      ],

    },
    user: {
      desc: [
        {
          text: t('users.noUsers'),
          variant: 'bodyMediumBold',
        },
      ],
    },
    invitation: {
      desc: [
        {
          text: t('users.noUsers'),
          variant: 'bodyMediumBold',
        },
      ],
    },
    plan: {
      desc: [
        {
          text: t('plans.noPlans'),
          variant: 'bodyMediumBold',
        },
      ],
    },
    lab: {
      desc: [
        {
          text: t('labs.noLabs'),
          variant: 'bodyMediumBold',
        },
      ],
    },
    promoCode: {
      desc: [
        {
          text: t('promoCodes.noPromoCodes'),
          variant: 'bodyMediumBold',
        },
      ],
    },
    transaction: {
      desc: [
        {
          text: t('transactions.noTransactions'),
          variant: 'bodyMediumBold',
        },
      ],
    },
    announcement: {
      desc: [
        {
          text: t('announcements.noAnnouncements'),
          variant: 'bodyMediumBold',
        },
      ],
    },
  };

  return (
    <GridOverlay sx={{ pointerEvents: 'auto' }}>
      <NoContentPlaceholder content={content[type]} />
    </GridOverlay>
  );
};

export default CustomNoRowsOverlay;
