import { useParams } from 'react-router-dom';

import { getMachineDetails } from 'services';
import { useCustomQuery } from 'reactQuery';

const useMachineDetails = (queryProps = {}) => {
  const { machineName } = useParams();

  const query = useCustomQuery({
    queryKey: [`view-machine-${machineName}`],
    queryFn: () => getMachineDetails(machineName),
    ...queryProps,
    options: {
      enabled: !!machineName,
      ...queryProps?.options,
    },
  });

  return { ...query };
};

export default useMachineDetails;
