import { Stack, Typography } from '@mui/material';
import { CopyToClipboardButton, UserAwareAvatar, VisibilityToggleLabel } from 'components/molecules';
import {
  useLocale,
} from 'util/hooks';

const useTableColumns = () => {
  const { t, isAr } = useLocale();

  const columns = [
    {
      field: 'player',
      headerName: t('common.player'),
      flex: 1,
      minWidth: 200,
      renderCell: ({ row }) => {
        const { user = {} } = row;
        const {
          username = '',
        } = user;

        return (
          <Stack
            gap={2}
            direction="row"
            alignItems="center"
          >
            <UserAwareAvatar
              user={user}
              src={user?.avatar}
            />
            <Typography variant="bodySmallMedium">
              {username}
            </Typography>
          </Stack>
        );
      },
    },
    {
      field: 'team',
      headerName: t('common.team'),
      flex: 1,
      minWidth: 150,
      renderCell: ({ row }) => {
        const { team = {} } = row;
        const {
          name: teamName = '',
        } = team;

        return (
          <Typography variant="bodySmallMedium">
            {teamName}
          </Typography>
        );
      },
    },
    {
      field: 'challenge',
      headerName: t('common.challenge'),
      flex: 1,
      minWidth: 150,
      renderCell: ({ row }) => {
        const { challenge = {} } = row;
        const {
          name: challengeName = '',
        } = challenge;

        return (
          <Typography variant="bodySmallMedium">
            {challengeName}
          </Typography>
        );
      },
    },
    {
      field: 'lab',
      headerName: t('common.lab'),
      flex: 1,
      minWidth: 150,
      renderCell: ({ row }) => {
        const { challenge = {} } = row;
        const {
          lab = {},
        } = challenge;
        const {
          nameAr = '',
          nameEn = '',
        } = lab;

        const labName = isAr ? nameAr : nameEn;

        return (
          <Typography variant="bodySmallMedium">
            {labName}
          </Typography>
        );
      },
    },
    {
      field: 'event',
      headerName: t('common.event'),
      flex: 1,
      minWidth: 150,
      renderCell: ({ row }) => {
        const { challenge = {} } = row;
        const {
          event = {},
        } = challenge;
        const {
          id: eventId = '',
        } = event;

        return (
          <Typography variant="bodySmallMedium">
            {eventId}
          </Typography>
        );
      },
    },
    {
      field: 'instance',
      headerName: t('challenges.instance'),
      flex: 2,
      minWidth: 300,
      renderCell: ({ row }) => {
        const { instanceAddress = '' } = row;

        return (
          <CopyToClipboardButton
            textToCopy={instanceAddress}
            buttonLabel={instanceAddress}
          />
        );
      },
    },
    {
      field: 'imageName',
      headerName: t('challenges.imageName'),
      flex: 1,
      minWidth: 150,
      renderCell: ({ row }) => {
        const { challenge = {} } = row;
        const {
          imageName = '',
        } = challenge;

        return (
          <Typography variant="bodySmallMedium">
            {imageName}
          </Typography>
        );
      },
    },
    {
      field: 'correctFlag',
      headerName: t('challenges.correctFlag'),
      flex: 2,
      minWidth: 300,
      renderCell: ({ row }) => {
        const { correctFlag = '' } = row;

        return (
          <VisibilityToggleLabel
            label={correctFlag}
            labelProps={{ variant: 'bodySmallMedium' }}
          />
        );
      },
    },
  ];

  return columns;
};

export default useTableColumns;
