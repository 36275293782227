import Image from 'mui-image';
import {
  Box, CircularProgress,
} from '@mui/material';

import { Carousel, CarouselItem } from 'components/layout';

import { useLocale } from 'util/hooks';

import { useDashboardAnnouncements } from 'reactQuery/queries';
import DashboardSectionHeader from '../helpers/DashboardSectionHeader';

const PlayerAnnouncements = () => {
  const { t } = useLocale();

  const {
    data: announcements = [],
  } = useDashboardAnnouncements();

  const carouselItems = announcements?.map((announcement) => (
    <CarouselItem key={announcement.id}>
      <Image
        src={announcement.image}
        duration={0}
        showLoading={<CircularProgress />}
      />
    </CarouselItem>
  ));

  return (
    <Box sx={{
      width: 1,
      maxHeight: 303,
      display: 'flex',
      flexDirection: 'column',
    }}
    >
      <Box sx={{ mb: 2 }}>
        <DashboardSectionHeader title={t('dashboard.announcements')} />
      </Box>
      <Box>
        <Carousel>
          {carouselItems}
        </Carousel>
      </Box>
    </Box>
  );
};

export default PlayerAnnouncements;
