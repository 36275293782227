import {
  Box,
} from '@mui/material';

const CardNumberWithEllipses = (props) => {
  const {
    lastFourDigits = '',
    ellipseCount = 1,
    ellipseProps = {},
    containerProps = {},
  } = props;

  const ellipses = Array(ellipseCount)
    .fill()
    .map((_, i) => {
      const key = `ellipse-${i}`;
      return (
        <Box
          key={key}
          {...ellipseProps}
          sx={{
            backgroundColor: 'text.lighterGray',
            height: 8,
            width: 8,
            borderRadius: 5,
            mr: 1,
            ':nth-of-type(4n)': {
              mr: 2,
            },
            ...ellipseProps.sx,
          }}
        />
      );
    });

  return (
    <Box
      {...containerProps}
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        ...containerProps.sx,
      }}
    >
      {ellipses}
      {lastFourDigits}
    </Box>
  );
};

export default CardNumberWithEllipses;
