import { atom } from 'recoil';

const challengesFilterAtom = atom({
  key: 'challengesFilter',
  default: {
    type: '',
    value: '*',
  },
});

export default challengesFilterAtom;
