import React from 'react';
import PropTypes from 'prop-types';

import CHALLENGE_CATEGORIES from 'assets/constants/challengeCategories';
import AvatarWithFallback from '../AvatarWithFallback/AvatarWithFallback';

const ChallengeAvatar = (props) => {
  const {
    challenge,
    ...rest
  } = props;
  const {
    category = { name: CHALLENGE_CATEGORIES.reversing },
  } = challenge || {};

  const challengeCategoryName = category?.name?.toLowerCase();

  const [src, setSrc] = React.useState('');

  React.useEffect(() => {
    const importLevelIcon = async () => {
      const importedIcon = await import(`assets/images/challenge-mini-${challengeCategoryName}.svg`);
      setSrc(importedIcon.default);
    };
    importLevelIcon();
  }, [challengeCategoryName]);

  const alt = `challenge-${challengeCategoryName}-icon`;

  return (
    <AvatarWithFallback
      src={src}
      alt={alt}
      {...rest}
    />
  );
};

ChallengeAvatar.propTypes = {
  challenge: PropTypes.oneOfType([PropTypes.object]),
};

ChallengeAvatar.defaultProps = {
  challenge: {},
};

export default ChallengeAvatar;
