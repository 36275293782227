import {
  MoneyBagsIcon,
  CodeUsageIcon,
  RemainingUsageIcon,
} from 'assets/icons';
import { useLocale } from 'util/hooks';
import { Box, Grid, Typography } from '@mui/material';
import { InfoCard } from 'components/molecules';
import { numberWithCommas } from 'util/helpers';

const PromoCodeStats = (props) => {
  const { codeDetails = {} } = props;

  const {
    totalProfit = 0,
    codeUsages = 0,
    remainingUsages = 0,
    isUnlimited = false,
  } = codeDetails;

  const { t } = useLocale();

  const stats = [{
    key: 'promoCode-totalProfit',
    icon: <MoneyBagsIcon width="40" height="40" />,
    title: t('promoCodes.totalProfit'),
    component: (
      <Typography variant="bodyLargeBold">
        {`${numberWithCommas(totalProfit || 0)} ${t('currency.sar')}`}
      </Typography>
    ),
  },
  {
    key: 'promoCode-codeUsages',
    icon: <CodeUsageIcon width="40" height="40" />,
    title: t('promoCodes.codeUsage'),
    component: (
      <Typography variant="bodyLargeBold">
        {codeUsages}
      </Typography>
    ),
  },
  {
    key: 'promoCode-remainingUsage',
    icon: <RemainingUsageIcon width="40" height="40" />,
    title: t('promoCodes.remainingUsage'),
    component: (
      <Typography variant="bodyLargeBold">
        {isUnlimited ? t('promoCodes.unlimited') : remainingUsages}
      </Typography>
    ),
  }];

  return (
    <Box sx={{ mb: 8 }}>
      <Grid container spacing={{ xs: 10, xl: 25 }}>
        {stats.map((stat) => <InfoCard {...stat} />)}
      </Grid>
    </Box>
  );
};

export default PromoCodeStats;
