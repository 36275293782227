import { useEventDetails } from 'reactQuery/queries';
import EventRegistrationState from '../EventRegistrationState';
import EventTeamDetails from '../EventTeamDetails';

const EventTeamProfileContainer = () => {
  const {
    data: eventDetails = {},
  } = useEventDetails();

  const { isRegistered = false } = eventDetails;

  if (!isRegistered) {
    return <EventRegistrationState />;
  }

  return <EventTeamDetails />;
};

export default EventTeamProfileContainer;
