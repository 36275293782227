import { getLatestChallengesMachines } from 'services';
import { useCustomQuery } from 'reactQuery';

const transformLatestChallengeData = (data) => data?.map((item) => {
  const isMachine = item.className.toLowerCase() === 'machine';
  return {
    ...item,
    isMachine,
    category: isMachine ? item.type : item.category,
  };
});

const useLatestChallengesMachines = (queryProps = {}) => {
  const query = useCustomQuery({
    queryKey: ['latest-challenges-machines'],
    queryFn: getLatestChallengesMachines,
    ...queryProps,
    options: {
      select: transformLatestChallengeData,
      ...queryProps.options,
    },
  });

  return { ...query };
};

export default useLatestChallengesMachines;
