import './style/app.css';
import 'locales/i18n';

import {
  AppHelpers,
  MUIProvider,
  ReactQueryProvider,
  RecoilProvider,
} from 'components/app';

import { Router } from 'routes';
import { ErrorBoundary } from 'components/helpers';
import MaintenanceMode from 'components/app/MaintenanceMode';

const App = () => (
  <ReactQueryProvider>
    <RecoilProvider>
      <MUIProvider>
        <AppHelpers>
          <ErrorBoundary>
            <MaintenanceMode>
              <Router />
            </MaintenanceMode>
          </ErrorBoundary>
        </AppHelpers>
      </MUIProvider>
    </RecoilProvider>
  </ReactQueryProvider>
);

export default App;
