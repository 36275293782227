import { useTheme } from '@mui/styles';

const OfficialBuildingIcon = (props) => {
  const theme = useTheme();
  const {
    stroke = theme.palette.text.secondary,
    fill = 'none',
    ...rest
  } = props;

  return (
    <svg width="48" height="44" viewBox="0 0 48 44" fill={fill} xmlns="http://www.w3.org/2000/svg" {...rest}>
      <rect width="48" height="44" rx="6" fill={stroke} fillOpacity="0.1" />
      <g clipPath="url(#clip0_13207_69881)">
        <path d="M34 16.75H14C13.7239 16.75 13.5 16.9739 13.5 17.25V19.25C13.5 19.5261 13.7239 19.75 14 19.75H34C34.2761 19.75 34.5 19.5261 34.5 19.25V17.25C34.5 16.9739 34.2761 16.75 34 16.75Z" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M33 16.75C33 13.75 28.5 10.75 24 10.75C19.5 10.75 15 13.75 15 16.75" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M33.75 27.25H14.25C13.8358 27.25 13.5 27.5858 13.5 28V29.5C13.5 29.9142 13.8358 30.25 14.25 30.25H33.75C34.1642 30.25 34.5 29.9142 34.5 29.5V28C34.5 27.5858 34.1642 27.25 33.75 27.25Z" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M13.5 33.25H34.5" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M15 19.75V27.25" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M18 19.75V27.25" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M21 19.75V27.25" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M27 19.75V27.25" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M33 19.75V27.25" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M30 19.75V27.25" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      </g>
      <defs>
        <clipPath id="clip0_13207_69881">
          <rect width="24" height="24" fill="white" transform="translate(12 10)" />
        </clipPath>
      </defs>
    </svg>

  );
};

export default OfficialBuildingIcon;
