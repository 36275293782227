import { useTheme } from '@mui/styles';

const ResetMachineIcon = (props) => {
  const theme = useTheme();
  const {
    fill = theme.palette.common.white,
    ...rest
  } = props;

  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <g clipPath="url(#clip0_9785_1679)">
        <path fillRule="evenodd" clipRule="evenodd" d="M7.71555 2.2137C9.15114 2.02189 10.6069 2.36898 11.8015 3.18791C12.9961 4.00684 13.8448 5.23947 14.1835 6.64765C14.5223 8.05582 14.3271 9.53958 13.6357 10.8122C12.9443 12.0849 11.8057 13.0561 10.4399 13.5382C9.07417 14.0203 7.57819 13.9791 6.24104 13.4226C5.92237 13.2899 5.5565 13.4408 5.42386 13.7594C5.29122 14.0781 5.44203 14.444 5.7607 14.5766C7.38332 15.252 9.19868 15.302 10.856 14.7169C12.5133 14.1319 13.895 12.9533 14.734 11.409C15.5731 9.86462 15.8099 8.06408 15.3989 6.35527C14.9878 4.64647 13.9579 3.15067 12.5083 2.15691C11.0586 1.16315 9.29209 0.741951 7.55001 0.974709C5.80793 1.20747 4.21394 2.07766 3.07604 3.41714C1.93814 4.75662 1.3371 6.47032 1.38904 8.22712C1.42762 9.53167 1.82422 10.7912 2.52339 11.8732H1C0.654822 11.8732 0.375 12.1531 0.375 12.4982C0.375 12.8434 0.654822 13.1232 1 13.1232H4C4.34518 13.1232 4.625 12.8434 4.625 12.4982V9.49824C4.625 9.15307 4.34518 8.87324 4 8.87324C3.65482 8.87324 3.375 9.15307 3.375 9.49824V10.8646C2.92228 10.0512 2.66643 9.13502 2.6385 8.19017C2.59569 6.74245 3.09099 5.33025 4.0287 4.22643C4.9664 3.1226 6.27995 2.40551 7.71555 2.2137Z" fill={fill} />
      </g>
      <defs>
        <clipPath id="clip0_9785_1679">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>

  );
};
export default ResetMachineIcon;
