import END_POINTS from 'services/endPoints';
import api from '../api';

const getAnnouncementDetails = (id) => api(
  END_POINTS.announcement(id),
)
  .then((res) => Promise.resolve(res))
  .catch((error) => Promise.reject(error));

export default getAnnouncementDetails;
