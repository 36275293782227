import {
  Box,
  Grid,
  Link,
  Tooltip,
  Typography,
} from '@mui/material';

import { useIsEvent, useLocale } from 'util/hooks';
import { AvatarWithFallback, FirstBloodCalculation, InfoCard } from 'components/molecules';

import {
  UserFilledIcon,
  BloodDropFilledIcon,
  SparklingFilledIcon,
} from 'assets/icons';

import { STATUS } from 'assets/constants/statuses';
import { useEventChallengeSubmissions } from 'reactQuery/queries';

const PlayerChallengeStats = (props) => {
  const {
    challengeDetails = {},
    eventDetails = {},
  } = props;

  const { isDynamicPoints = false } = eventDetails;

  const {
    points = 0,
    status = '',
    currentSubmissionPoints = 0,
    publishedAt = '',
    firstShownAt = '',
    numberOfSolutions = 0,
    submissions: labChallengeSubmissions = [],
  } = challengeDetails || {};

  const {
    data: {
      items: eventChallengeSubmissions = [],
    } = {},
  } = useEventChallengeSubmissions({
    queryKeyOptions: {
      pagination: {
        limit: 1,
      },
    },
  }); // team submissions

  const { t } = useLocale();
  const isEventChallenge = useIsEvent();
  const submissions = isEventChallenge ? eventChallengeSubmissions : labChallengeSubmissions;
  const challengePoints = isDynamicPoints ? currentSubmissionPoints : points;
  const isRetired = status === STATUS.retired;

  const firstBlood = submissions
    ?.find((submission) => submission.firstBlood);

  const {
    team = {},
    player = {},
    firstBlood: isFirstBlood = false,
  } = firstBlood || {};

  const cardContent = isEventChallenge
    ? {
      name: team?.name,
      avatar: team?.logo,
    }
    : {
      name: player?.username,
      avatar: player?.avatar,
      componentProps: {
        component: Link,
        to: `/profile/${player?.username}`,
      },
    };

  const {
    name = '',
    avatar = '',
    componentProps = {},
  } = cardContent || {};

  const none = (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Typography variant="bodyStandardBold">
        {t('common.none')}
      </Typography>
    </Box>
  );

  const firstBloodUser = firstBlood && (
    <Box sx={{
      display: 'flex',
      alignItems: 'center',
      flexWrap: 'wrap',
    }}
    >
      <Box {...componentProps}>
        <Box sx={{ display: 'flex', alignItems: 'center', mr: 3 }}>
          <Box sx={{ mr: 2, width: 23, height: 23 }}>
            <AvatarWithFallback
              src={avatar}
              alt={name}
              sx={{
                width: 23,
                height: 23,
                fontSize: 15,
                fontWeight: 'bold',

              }}
            />
          </Box>
          <Box>
            <Typography variant="bodyStandardBold" color="text.primary">
              {name}
            </Typography>
          </Box>
        </Box>
      </Box>
      {isFirstBlood && (
        <Box>
          <Tooltip
            title={t('challenges.firstBloodTimeDesc')}
            placement="top"
          >
            <Typography
              variant="bodySmallMedium"
              color="text.red"
            >
              <FirstBloodCalculation
                publishedAt={publishedAt}
                firstShownAt={firstShownAt}
                submittedAt={firstBlood?.submittedAt}
              />
            </Typography>
          </Tooltip>
        </Box>
      )}
    </Box>
  );

  const stats = [{
    key: 'challenge-players-solves',
    icon: <UserFilledIcon width="45" height="45" />,
    title: t('challenges.flagHacks'),
    component: (
      <Typography variant="bodyStandardBold">
        {`${numberOfSolutions} ${t('challenges.hacks')}`}
      </Typography>
    ),
  },
  {
    key: 'challenge-first-blood',
    icon: <BloodDropFilledIcon width="45" height="45" />,
    title: t('challenges.firstBlood'),
    component: firstBlood ? firstBloodUser : none,
  }];

  if (!isRetired) {
    stats.splice(0, 0, {
      key: 'challenge-points',
      icon: <SparklingFilledIcon width="45" height="45" />,
      title: t('challenges.challengePoints'),
      component: (
        <Tooltip
          title={t('common.dynamicPointsToolTip')}
          placement="bottom"
          componentsProps={{
            tooltip: {
              sx: {
                display: isDynamicPoints ? 'block' : 'none',
              },
            },
          }}
        >
          <Box display="flex" gap={2}>
            <Typography variant="bodyStandardBold">
              {`${challengePoints} ${t('challenges.points')}`}
            </Typography>
          </Box>
        </Tooltip>
      ),
    });
  }

  return (
    <Box>
      <Grid container spacing={6}>
        {stats.map((stat) => (
          <InfoCard
            {...stat}
            size="small"
          />
        ))}
      </Grid>
    </Box>
  );
};

export default PlayerChallengeStats;
