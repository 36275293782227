import {
  Box,
  Chip,
  Typography,
} from '@mui/material';

import { useLocale } from 'util/hooks';
import { capitalizeFirstLetter } from 'util/helpers';
import { getStatusByName } from 'assets/constants/statuses';

import { VisibilityBadge } from 'components/molecules';
import MachineActions from './MachineActions';

const MachineHeader = (props) => {
  const { machineDetails = {} } = props;
  const {
    name = '',
    status = '',
    visible = true,
  } = machineDetails;

  const { t } = useLocale();

  const statusLabel = t(getStatusByName(status)?.localizationKey);

  return (
    <Box sx={{
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      flexWrap: 'wrap',
    }}
    >
      <Box sx={{
        display: 'flex',
        alignItems: 'center',
      }}
      >
        <Box sx={{ mr: 4 }}>
          <Typography variant="bodyLargeBold">
            {capitalizeFirstLetter(name)}
          </Typography>
        </Box>
        <Box>
          <Chip
            size="small"
            color={status}
            label={statusLabel}
          />
        </Box>
        <Box sx={{ mx: 2 }}>
          <VisibilityBadge visible={visible} />
        </Box>
      </Box>
      <MachineActions />
    </Box>
  );
};

export default MachineHeader;
