import {
  Box,
  Card,
  Stack,
  CardMedia,
  Typography,
  Grid,
  Link,
  Tooltip,
  AvatarGroup,
} from '@mui/material';
import { useTheme } from '@mui/styles';

import {
  TeamIcon,
  CalendarIcon,
  RankFilledIcon,
  SparklingFilledIcon,
} from 'assets/icons';
import { AvatarWithFallback } from 'components/molecules';
import { PATHS } from 'routes';

import { useDate, useLocale } from 'util/hooks';

const Team = (props) => {
  const { team = {} } = props;

  const { name = '', logo = '' } = team;

  return (
    <Box sx={{
      gap: 3,
      display: 'flex',
      alignItems: 'center',
    }}
    >
      <AvatarWithFallback
        src={logo}
        alt={name}
        sx={{
          width: 45,
          height: 45,
          fontWeight: 'bold',
          fontSize: 23,
        }}
      />
      <Typography variant="bodyStandardMedium" noWrap>
        {name}
      </Typography>
    </Box>
  );
};

const IconText = (props) => {
  const {
    Icon = null,
    title = '',
    children = null,
  } = props;

  return (
    <Box sx={{
      gap: 3,
      display: 'flex',
      alignItems: 'center',
    }}
    >
      <Box sx={{
        width: 45,
        height: 45,
      }}
      >
        <Icon
          width="45"
          height="45"
        />
      </Box>
      <Stack>
        <Typography variant="bodySmallMedium" color="text.lightGray" noWrap>
          {title}
        </Typography>
        <Box>
          {children}
        </Box>
      </Stack>
    </Box>
  );
};

const GridItem = ({ children, ...rest }) => (
  <Grid
    item
    xs
    sx={{ minWidth: { xs: 1, sm: 150 } }}
    {...rest}
  >
    {children}
  </Grid>
);

const gap = 6;
const EventHistoryCard = (props) => {
  const { event = {} } = props;

  const { t } = useLocale();
  const { format } = useDate();
  const { palette } = useTheme();

  const {
    id = '',
    name = '',
    image = '',
    eventStartAt = '',
    eventEndAt = '',
    team = {},
  } = event;

  const {
    rank = null,
    points = 0,
    members = [],
  } = team;

  return (
    <Card sx={{ py: 7, px: 5 }}>
      <Box sx={{
        gap,
        height: 1,
        display: 'flex',
        flexWrap: 'wrap',
      }}
      >
        <Box sx={{ width: { xs: 1, sm: 150 } }}>
          <CardMedia
            image={image}
            title={name}
            sx={{
              aspectRatio: '3/2',
              borderRadius: 1.5,
            }}
          />
        </Box>
        <Stack sx={{
          gap,
          flex: 1,
          justifyContent: 'space-between',
        }}
        >
          <Box sx={{
            gap,
            display: 'flex',
            flexWrap: 'wrap',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
          >
            <Link to={`/${PATHS.events}/${id}`}>
              <Typography
                variant="bodyMediumMedium"
                color="text.lightGray"
              >
                {name}
              </Typography>
            </Link>
            <Stack
              gap={2}
              direction="row"
              alignItems="center"
            >
              <CalendarIcon
                width="16"
                height="16"
                stroke={palette.text.darkGray}
              />
              <Typography
                variant="bodySmallRegular"
                color="text.secondary"
              >
                {`${format(eventStartAt, 'PP')} - ${format(eventEndAt, 'PP')}`}
              </Typography>
            </Stack>
          </Box>
          <Grid container gap={gap}>
            <GridItem lg={4}>
              <Team team={team} />
            </GridItem>
            <GridItem>
              <IconText Icon={TeamIcon} title={t('common.teamMembers')}>
                <AvatarGroup
                  max={4}
                  sx={{
                    justifyContent: 'start',
                    '& .MuiAvatar-root': {
                      width: 21,
                      height: 21,
                      fontSize: 15,
                    },
                  }}
                >
                  {members?.map(({ player: { username, avatar } = {} }) => (
                    <Tooltip key={username} title={username}>
                      <AvatarWithFallback
                        component={Link}
                        to={`/profile/${username}/overview`}
                        src={avatar}
                        alt={username}
                        sx={{
                          width: 21,
                          height: 21,
                        }}
                      />
                    </Tooltip>
                  ))}
                </AvatarGroup>
              </IconText>
            </GridItem>
            <GridItem>
              <IconText Icon={SparklingFilledIcon} title={t('profile.totalPoints')}>
                <Typography variant="bodyStandardBold" noWrap>
                  {`${points} ${t('common.points')}`}
                </Typography>
              </IconText>
            </GridItem>
            <GridItem>
              <IconText Icon={RankFilledIcon} title={t('common.rank')}>
                <Typography variant="bodyStandardBold" noWrap>
                  {rank ? `#${rank}` : t('common.notRanked')}
                </Typography>
              </IconText>
            </GridItem>
          </Grid>
        </Stack>
      </Box>
    </Card>
  );
};

export default EventHistoryCard;
