import { useParams } from 'react-router-dom';

import { getEventChallengeDetails } from 'services';
import { useCustomQuery } from 'reactQuery';

const useEventChallengeDetails = (queryProps = {}) => {
  const { eventId, challengeId } = useParams();

  const query = useCustomQuery({
    queryKey: [`view-event-challenge-${challengeId}`],
    queryFn: () => getEventChallengeDetails(eventId, challengeId),
    ...queryProps,
    options: {
      enabled: !!eventId && !!challengeId,
      ...queryProps?.options,
    },
  });

  return { ...query };
};

export default useEventChallengeDetails;
