import { useTheme } from '@mui/styles';

const ChallengePlanIcon = (props) => {
  const theme = useTheme();
  const {
    stroke = theme.palette.text.secondary,
    fill = theme.palette.paper.hover,
    ...rest
  } = props;

  return (
    <svg width="48" height="44" viewBox="0 0 48 44" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <rect width="48" height="44" rx="6" fill={fill} />
      <g clipPath="url(#clip0_3904_1584)">
        <path d="M29.4518 21.7049L30.8643 24.7482H33.6143C33.753 24.7428 33.89 24.7803 34.0066 24.8557C34.1231 24.931 34.2135 25.0405 34.2655 25.1692C34.3175 25.2979 34.3284 25.4395 34.2969 25.5747C34.2653 25.7098 34.1928 25.832 34.0892 25.9243L31.7058 28.2737L33.0277 31.3107C33.0854 31.4497 33.0981 31.6034 33.064 31.7501C33.0299 31.8968 32.9507 32.0291 32.8375 32.1284C32.7243 32.2278 32.5828 32.2891 32.4329 32.3039C32.2831 32.3187 32.1323 32.2861 32.0019 32.2108L28.8037 30.416L25.6073 32.2136C25.4768 32.2889 25.3261 32.3214 25.1762 32.3067C25.0264 32.2919 24.8849 32.2305 24.7717 32.1312C24.6585 32.0318 24.5793 31.8995 24.5452 31.7529C24.5111 31.6062 24.5238 31.4525 24.5815 31.3134L25.9043 28.2765L23.5209 25.9271C23.4162 25.8355 23.3425 25.7138 23.3098 25.5786C23.2772 25.4434 23.2872 25.3014 23.3386 25.1721C23.3899 25.0429 23.4801 24.9327 23.5966 24.8568C23.7132 24.7809 23.8504 24.743 23.9893 24.7482H26.7393L28.1547 21.7022C28.2172 21.5843 28.3107 21.4857 28.4252 21.417C28.5396 21.3484 28.6706 21.3122 28.804 21.3125C28.9375 21.3128 29.0683 21.3495 29.1825 21.4186C29.2966 21.4878 29.3897 21.5868 29.4518 21.7049V21.7049Z" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M16.4375 17.1875H26.0625" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M16.4375 21.3125H23.3125" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M16.4375 25.4375H19.875" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M19.875 29.5625H15.0625C14.6978 29.5625 14.3481 29.4176 14.0902 29.1598C13.8324 28.9019 13.6875 28.5522 13.6875 28.1875V13.0625C13.6875 12.6978 13.8324 12.3481 14.0902 12.0902C14.3481 11.8324 14.6978 11.6875 15.0625 11.6875H24.8058C25.1702 11.6876 25.5197 11.8323 25.7774 12.0899L28.4101 14.7226C28.6677 14.9803 28.8124 15.3298 28.8125 15.6943V17.875" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      </g>
      <defs>
        <clipPath id="clip0_3904_1584">
          <rect width="22" height="22" fill="white" transform="translate(13 11)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ChallengePlanIcon;
