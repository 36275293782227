import { useTheme } from '@mui/styles';

const ArrowRightIcon = (props) => {
  const theme = useTheme();
  const {
    stroke = theme.palette.archived.contrastText,
    fill = 'none',
    ...rest
  } = props;

  return (
    <svg width="12" height="12" viewBox="0 0 12 12" fill={fill} stroke={stroke} xmlns="http://www.w3.org/2000/svg" {...rest}>
      <g clipPath="url(#clip0_3452_12422)">
        <path d="M3.65625 0.375L9.01575 5.735C9.05058 5.76978 9.07822 5.81109 9.09707 5.85656C9.11592 5.90203 9.12563 5.95078 9.12563 6C9.12563 6.04922 9.11592 6.09797 9.09707 6.14344C9.07822 6.18891 9.05058 6.23022 9.01575 6.265L3.65625 11.625" strokeWidth="0.9" strokeLinecap="round" strokeLinejoin="round" />
      </g>
      <defs>
        <clipPath id="clip0_3452_12422">
          <rect width="12" height="12" fill="white" transform="translate(0 12) rotate(-90)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ArrowRightIcon;
