import {
  Box,
  Card,
  Link,
  Tooltip,
  Typography,
} from '@mui/material';
import { BloodDropIcon, FlagIcon } from 'assets/icons';
import { useDate, useLocale } from 'util/hooks';
import { FirstBloodCalculation, UserAwareAvatar } from 'components/molecules';
import { format } from 'date-fns';

const LabChallengeSubmissionCard = (props) => {
  const {
    player = {},
    submittedAt = '',
    publishedAt = '',
    firstShownAt = '',
    firstBlood = false,
  } = props;

  const {
    avatar = '',
    username = '',
  } = player;

  const { t } = useLocale();
  const { fromNow } = useDate();

  // Icon
  const iconWidth = firstBlood ? 20 : 18;
  const icon = firstBlood
    ? <BloodDropIcon width={iconWidth} height={iconWidth} />
    : <FlagIcon width={iconWidth} height={iconWidth} />;

  // Submission date
  const submittedAtString = fromNow(submittedAt);
  const submittedDateTime = format(new Date(submittedAt), 'PPPp');

  return (
    <Card sx={{
      px: 6,
      py: 4.5,
    }}
    >
      <Box sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexWrap: 'wrap',
      }}
      >
        <Box sx={{
          display: 'flex',
          alignItems: 'center',
        }}
        >
          <Box sx={{
            mr: 5,
            width: iconWidth,
            height: iconWidth,
          }}
          >
            {icon}
          </Box>
          <Box
            component={Link}
            to={`/profile/${username}`}
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Box mr={3}>
              <UserAwareAvatar
                src={avatar}
                alt={username}
                user={player}
                sx={{
                  width: 36,
                  height: 36,
                  fontWeight: 'bold',
                  fontSize: 23,
                }}
              />
            </Box>
            <Typography
              mr={3}
              variant="bodyStandardMedium"
              color="text.primary"
            >
              {username}
            </Typography>
          </Box>
          {firstBlood && (
            <Box>
              <Tooltip
                title={t('challenges.firstBloodTimeDesc')}
                placement="top"
              >
                <Typography
                  variant="bodySmallMedium"
                  color="text.red"
                >
                  <FirstBloodCalculation
                    publishedAt={publishedAt}
                    firstShownAt={firstShownAt}
                    submittedAt={submittedAt}
                  />
                </Typography>
              </Tooltip>
            </Box>
          )}
        </Box>
        <Box>
          <Tooltip title={submittedDateTime} placement="top">
            <Typography
              variant="bodySmallRegular"
              color="text.lightGray"
              noWrap
            >
              {submittedAtString}
            </Typography>
          </Tooltip>
        </Box>
      </Box>
    </Card>
  );
};

export default LabChallengeSubmissionCard;
