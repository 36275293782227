import { Button, Tooltip, Typography } from '@mui/material';
import { useTheme } from '@mui/styles';
import MODAL_KEYS from 'assets/constants/modalKeys';
import ResetMachineIcon from 'assets/icons/ResetMachineIcon';
import { useMachineDetails, useUserResets } from 'reactQuery/queries';
import { useLocale, useModal } from 'util/hooks';

/**
*
* @param {object} props - props to pass to helper - currently unused
* @returns React Component
*
*/
const ResetMachineButton = (props) => {
  const { buttonProps = {} } = props;
  const {
    data: machineDetails = {},
  } = useMachineDetails();
  const {
    refetch: refetchUserResets,
    data: resets = {},
  } = useUserResets({});
  const { palette } = useTheme();
  const { t } = useLocale();
  const { addModal } = useModal();

  const handleResetMachine = async () => {
    addModal({
      key: MODAL_KEYS.resetMachine,
      props: {
        machine: machineDetails,
        refetchUserResets,
        resets: {
          ...resets,
        },
      },
    });
  };
  const isDisabled = resets.hasExceededResets;
  return (
    <Tooltip
      title={t('machines.consumedMaxResets')}
      placement="top-start"
      componentsProps={{
        tooltip: {
          sx: {
            display: isDisabled ? 'block' : 'none',
          },
        },
      }}
    >
      <span>
        <Button
          startIcon={(
            <ResetMachineIcon
              fill={
              isDisabled
                ? palette.action.disabledBackground
                : palette.text.raspberry
              }
            />
)}
          color="secondary"
          onClick={handleResetMachine}
          disabled={isDisabled}
          {...buttonProps}
        >
          <Typography
            variant="bodySmallBold"
            color={isDisabled
              ? 'action.disabledBackground'
              : 'text.raspberry'}
          >
            {t('machines.resetMachine')}
          </Typography>
        </Button>
      </span>

    </Tooltip>
  );
};
export default ResetMachineButton;
