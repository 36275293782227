import { getAllPromoCodes } from 'services';
import { useCustomQuery } from 'reactQuery';

const usePromoCodes = () => {
  const query = useCustomQuery({
    queryKey: ['promo-codes'],
    queryFn: getAllPromoCodes,
  });

  return { ...query };
};

export default usePromoCodes;
