import { useMemo, useState } from 'react';
import {
  Box,
  Typography,
  Link,
  Card,
  Alert,
} from '@mui/material';
import { REGEX } from 'util/helpers';
import * as Yup from 'yup';
import { register } from 'services';
import { PATHS, authRoles } from 'routes';
import {
  FormPasswordField,
  FormCountriesSelect,
  FormTextField,
} from 'components/form';
import { LoadingButton } from '@mui/lab';
import { useLocale, useFastForm, useRecaptcha } from 'util/hooks';
import { RecaptchaNotice } from 'components/helpers';

const RegistrationForm = (props) => {
  const {
    onRegistrationSuccess = () => { },
  } = props;

  const [registrationError, setRegistrationError] = useState(null);
  const { executeRecaptcha } = useRecaptcha({ action: 'REGISTRATION' });
  const { t } = useLocale();

  const onSubmit = async (values) => {
    try {
      const recaptchaToken = await executeRecaptcha();
      const response = await register({
        ...values,
        country: { id: values.country.id },
        type: authRoles.player,
      }, { recaptchaToken });

      onRegistrationSuccess(response);
    } catch (error) {
      const { errors } = error;
      errors?.forEach((err) => {
        setError(err.property, {
          type: 'api',
          message: err.message,
        });
      });

      if (!errors) { // Non-form errors
        setRegistrationError({
          message: error.message || t('common.somethingWrong'),
        });
      }
    }
  };

  const defaultValues = {
    email: '',
    username: '',
    country: '',
    password: '',
    // agreement: false,
  };

  const validationSchema = useMemo(() => Yup.object({
    email: Yup
      .string()
      .email(t('email.validation'))
      .required(t('email.enterEmail'))
      .matches(REGEX.mail, t('email.validation')),
    username: Yup
      .string()
      .matches(REGEX.alphaNumericUnderscore, t('username.validation'))
      .required(t('username.enter'))
      .max(35, t('username.maxLength'))
      .min(6, t('username.minLength')),
    country: Yup.object()
      .nullable(true)
      .required(t('country.enter')),
    password: Yup
      .string()
      .matches(REGEX.password, t('password.invalidPassword'))
      .required(t('password.enterPassword')),
    // agreement: Yup
    //   .bool()
    //   .required(t('agreement.validation'))
    //   .oneOf([true], t('agreement.validation')),
  }), [t]);

  const {
    control,
    handleSubmit,
    // getValues,
    setError,
    formState: { isSubmitting, isDirty, isValid },
  } = useFastForm({
    defaultValues,
    validationSchema,
  });

  return (
    <Card>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box sx={{
          display: 'grid',
          rowGap: 5,
          alignItems: 'center',
          py: 10,
          px: {
            sm: 25,
            xs: 5,
          },
        }}
        >
          <Box sx={{
            width: 1,
            textAlign: 'start',
            marginBottom: 6,
          }}
          >
            {registrationError
              && (
                <Alert severity="error" sx={{ width: 1, mb: 8 }}>
                  {registrationError.message}
                </Alert>
              )}
            <Typography variant="bodyMediumRegular">
              {t('common.createFreeAccount')}
            </Typography>
            <Typography variant="h5">
              {t('common.joinFlagyard')}
            </Typography>
          </Box>
          <Box>
            <FormTextField
              name="email"
              control={control}
              label={t('common.email')}
              disabled={isSubmitting}
              fullWidth
              autoFocus
            />
          </Box>
          <Box>
            <FormTextField
              name="username"
              control={control}
              label={t('common.username')}
              disabled={isSubmitting}
              fullWidth
            />
          </Box>
          <Box>
            <FormCountriesSelect
              name="country"
              control={control}
              label={t('country.label')}
              disabled={isSubmitting}
              fullWidth
            />
          </Box>
          <Box>
            <FormPasswordField
              name="password"
              control={control}
              autoComplete="new-password"
              label={t('common.password')}
              disabled={isSubmitting}
              fullWidth
            />
          </Box>
          {/* TODO: uncomment when terms are ready
          <Box>
            <FormCheckbox
              name="agreement"
              control={control}
              disabled={isSubmitting}
              checkboxProps={{
                checked: getValues('agreement'),
              }}
              label={(
                <div>
                  <Typography variant="bodyStandardRegular">
                    {t('agreement.confirm')}
                    <Link
                      to={`/${PATHS.terms}`}
                    >
                      {t('agreement.terms')}
                    </Link>
                  </Typography>
                </div>
              )}
            />
          </Box> */}
          <LoadingButton
            type="submit"
            variant="contained"
            size="large"
            fullWidth
            loading={isSubmitting}
            disabled={!isDirty || (isSubmitting || !isValid)}
          >
            {t('common.register')}
          </LoadingButton>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              flexWrap: 'wrap',
            }}
          >
            <Typography variant="bodyStandardRegular">
              {t('common.haveAccount')}
              &nbsp;
              <Link
                to={`/${PATHS.login}`}
              >
                {t('common.login')}
              </Link>
            </Typography>
          </Box>
          <Box>
            <RecaptchaNotice />
          </Box>
        </Box>
      </form>
    </Card>
  );
};

export default RegistrationForm;
