import { useTheme } from '@mui/styles';

const BellIcon = (props) => {
  const theme = useTheme();
  const {
    stroke = theme.palette.text.secondary,
    fill = 'none',
    ...rest
  } = props;

  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill={fill} xmlns="http://www.w3.org/2000/svg" {...rest}>
      <path d="M18.752 1.875L1.25195 19.375" stroke={stroke} strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M8.33301 18.125C8.43886 18.4856 8.65864 18.8022 8.95947 19.0274C9.26029 19.2526 9.62597 19.3743 10.0018 19.3743C10.3775 19.3743 10.7432 19.2526 11.044 19.0274C11.3449 18.8022 11.5647 18.4856 11.6705 18.125" stroke={stroke} strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M10.002 2.5V0.625" stroke={stroke} strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M8.75 15.6219H17.5C17.5 15.6219 16.25 14.6219 16.25 8.74687C16.2403 8.49133 16.2144 8.23665 16.1725 7.98438" stroke={stroke} strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M13.51 3.58651C12.5707 2.94894 11.4754 2.57927 10.3419 2.51717C9.2083 2.45507 8.07922 2.7029 7.07587 3.23405C6.07252 3.76519 5.23279 4.55959 4.64684 5.53195C4.06088 6.50432 3.75084 7.61791 3.75 8.75318V13.1282" stroke={stroke} strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};
export default BellIcon;
