import { getInvitations } from 'services';
import { useCustomQuery } from 'reactQuery';

const useInvitations = (queryProps = {}) => {
  const { queryKeyOptions = {}, ...rest } = queryProps;

  const query = useCustomQuery({
    queryKey: ['invitations', queryKeyOptions],
    queryFn: getInvitations,
    ...rest,
  });

  return { ...query };
};

export default useInvitations;
