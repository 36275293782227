import { useTheme } from '@mui/styles';

const SparklingWithHashFilledIcon = (props) => {
  const theme = useTheme();
  const {
    stroke = theme.palette.colors.yellow,
    fill = theme.palette.colors.yellow,
    ...rest
  } = props;

  return (
    <svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <rect width="45" height="45" rx="7.125" fill={fill} fillOpacity="0.25" />
      <g clipPath="url(#clip0_8832_136982)">
        <path d="M13.2832 15.207H21.6249" stroke={stroke} strokeWidth="1.02667" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M12 19.0586H20.3417" stroke={stroke} strokeWidth="1.02667" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M16.8123 12L13.604 21.625" stroke={stroke} strokeWidth="1.02667" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M20.0208 12L16.8125 21.625" stroke={stroke} strokeWidth="1.02667" strokeLinecap="round" strokeLinejoin="round" />
      </g>
      <path d="M18.0735 25C18.0663 26.8848 18.9817 27.96 21.0611 28C19.1307 27.9928 18.1715 29.0328 18.0735 31C18.0568 29.144 17.229 28 15.0859 28C17.0036 27.9736 18.0663 27.044 18.0735 25Z" stroke={stroke} strokeWidth="1.53751" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M26.2628 16.2725C26.2487 19.8921 28.0011 21.9452 32.0011 22.0358C28.2911 22.0216 26.4508 26.6754 26.2628 30.4543C26.227 26.8892 24.6398 22.0303 20.5234 22.0358C24.2063 21.9834 26.2487 20.1987 26.2628 16.2725Z" stroke={stroke} strokeWidth="2.0966" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default SparklingWithHashFilledIcon;
