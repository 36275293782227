import { useTheme } from '@mui/styles';

const PaperFilledIcon = (props) => {
  const theme = useTheme();
  const {
    stroke = theme.palette.text.secondary,
    fill = theme.palette.paper.hover,
  } = props;

  return (
    <svg width="49" height="44" viewBox="0 0 49 44" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect x="0.330078" width="48" height="44" rx="6" fill={fill} />
      <path d="M33.9551 30.9375C33.9551 31.3022 33.8102 31.6519 33.5524 31.9098C33.2945 32.1676 32.9448 32.3125 32.5801 32.3125H16.0801C15.7154 32.3125 15.3657 32.1676 15.1078 31.9098C14.8499 31.6519 14.7051 31.3022 14.7051 30.9375V13.0625C14.7051 12.6978 14.8499 12.3481 15.1078 12.0902C15.3657 11.8324 15.7154 11.6875 16.0801 11.6875H29.8713C30.2302 11.6873 30.575 11.8274 30.832 12.078L33.5398 14.7208C33.6712 14.8488 33.7757 15.0019 33.847 15.171C33.9183 15.3401 33.9551 15.5217 33.9551 15.7053V30.9375Z" stroke={stroke} strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M18.8711 18.5625H29.8711" stroke={stroke} strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M18.8711 22.6875H29.8711" stroke={stroke} strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M18.8711 26.8125H24.3711" stroke={stroke} strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default PaperFilledIcon;
