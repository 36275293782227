import { useMemo } from 'react';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';

import { Box, Stack, Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { FormCheckbox, FormTextField } from 'components/form';
import { createCompany, editCompany } from 'services';
import { useLocale, useFastForm, useSnackbar } from 'util/hooks';
import { REGEX } from 'util/helpers';

const CompanyForm = (props) => {
  const { createMode = true, companyName = '' } = props;
  const { t } = useLocale();
  const snack = useSnackbar();
  const navigate = useNavigate();

  const onSubmit = async (values) => {
    const { inviteAnother, companyName: newCompanyName } = values;

    try {
      createMode
        ? await createCompany({ name: newCompanyName })
        : await editCompany(companyName, { name: newCompanyName });

      if (inviteAnother) {
        reset({});
      } else {
        navigateBack();
      }

      snack({
        message: ` ${newCompanyName} ${t(`companies.${createMode ? 'created' : 'edited'}`)}`,
        severity: 'success',
      });
    } catch (error) {
      snack({
        message: (error.message || t('common.somethingWrong')),
        severity: 'error',
      });
    }
  };

  const navigateBack = () => {
    navigate(-1);
  };

  const defaultValues = {
    companyName,
    inviteAnother: false,
  };

  const validationSchema = useMemo(() => Yup.object({
    companyName: Yup
      .string()
      .matches(REGEX.noSlash, t('common.noSlashes'))
      .required(t('companies.validation.enterName')),
  }), [t]);

  const {
    control,
    handleSubmit,
    reset,
    formState: { isSubmitting, isDirty },
  } = useFastForm({
    defaultValues,
    validationSchema,
  });

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
    }}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box sx={{ mt: 4, width: { xs: 1, md: 420 } }}>
          <FormTextField
            name="companyName"
            control={control}
            label={t('companies.name')}
            disabled={isSubmitting}
            fullWidth
          />
        </Box>
        <Box sx={{
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'row' },
          justifyContent: { xs: 'start', sm: 'end' },
          flexWrap: 'wrap',
          mt: 8,
        }}
        >
          {createMode
            && (
              <Box>
                <FormCheckbox
                  name="inviteAnother"
                  control={control}
                  label={t('common.createAnother')}
                />
              </Box>
            )}
          <Stack
            direction="row"
            gap={3}
          >
            <Button onClick={navigateBack} disabled={isSubmitting}>
              {t('common.cancel')}
            </Button>
            <LoadingButton
              type="submit"
              variant="contained"
              loading={isSubmitting}
              disabled={!isDirty || isSubmitting}
            >
              {createMode ? t('companies.createNew') : t('common.saveChanges')}
            </LoadingButton>
          </Stack>
        </Box>
      </form>
    </Box>
  );
};

export default CompanyForm;
