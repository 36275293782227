import { useEffect } from 'react';
import * as Yup from 'yup';
import _ from 'lodash';

import { useNavigate } from 'react-router-dom';

import {
  Box, Button, Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

import {
  FormCheckbox,
  FormDatePicker,
  FormNumberField,
  FormTextField,
  FormRadioGroup,
} from 'components/form';
import { PATHS } from 'routes';
import {
  REGEX,
  generateRandomBetween,
  getDirtyObject,
} from 'util/helpers';
import { nanoid } from 'nanoid/async';
import { TypographyWithIcon } from 'components/molecules';
import { AutoGenerateIcon } from 'assets/icons';
import { STATUS } from 'assets/constants/statuses';
import { createPromoCode, editPromoCode } from 'services';
import { usePromoCode } from 'reactQuery/queries';
import {
  useLocale, useFastForm, useSnackbar, useDate,
} from 'util/hooks';

const PromoCodeDetailsForm = (props) => {
  const {
    createMode = false,
    onClickCancel = () => { },
  } = props;

  const {
    data: codeDetails = {},
    refetch: refetchCode = () => { },
  } = usePromoCode();

  const { t } = useLocale();
  const snack = useSnackbar();
  const navigate = useNavigate();
  const { now, yesterday } = useDate();

  const isPublished = codeDetails?.status === STATUS.published;
  const isExpired = codeDetails?.status === STATUS.expired;
  const disableField = isPublished || isExpired;

  const defaultValues = {
    code: codeDetails?.code || '',
    discountPercentage: _.round(100 * codeDetails.discountPercentage, 2) || '',
    startDate: codeDetails?.startDate || now,
    endDate: codeDetails?.endDate || now,
    maxUses: codeDetails?.maxUses || 0,
    isUnlimited: codeDetails?.isUnlimited || false,
    sendAnnouncement: Boolean(codeDetails?.sendAnnouncement),
  };

  const validationSchema = Yup.object({
    code: Yup
      .string()
      .trim()
      .matches(REGEX.alphaNumericSpaceSpecialCharacter, t('promoCodes.validation.enterEnglishCode'))
      .required(t('promoCodes.validation.enterCode')),
    discountPercentage: Yup
      .number()
      .required(t('promoCodes.validation.enterDiscountPercentage'))
      .typeError(t('promoCodes.validation.enterNumbers'))
      .min(1, t('promoCodes.validation.minNumber'))
      .max(100, t('promoCodes.validation.maxNumber')),
    startDate: Yup
      .date()
      .nullable()
      .min(yesterday, t('promoCodes.validation.startDate'))
      .required(t('promoCodes.validation.enterStartDate')),
    endDate: Yup
      .date()
      .nullable()
      .when('startDate', (startDate, schema) => schema.min(startDate, t('promoCodes.validation.afterDate')))
      .required(t('promoCodes.validation.enterEndDate')),
    maxUses: Yup
      .number()
      .when('isUnlimited', {
        is: false,
        then: Yup.number()
          .required(t('promoCodes.validation.enterMaxUses'))
          .typeError(t('promoCodes.validation.enterNumbers'))
          .min(1, t('promoCodes.validation.minNumber')),
      }),
    isUnlimited: Yup.bool(),
    sendAnnouncement: Yup
      .bool()
      .required(),
  });

  const {
    control,
    handleSubmit,
    setError,
    watch,
    setValue,
    getValues,
    trigger,
    formState: {
      errors, isSubmitting, isDirty, isValid,
    },
  } = useFastForm({
    defaultValues,
    validationSchema,
  });

  const startDate = watch('startDate');
  const endDate = watch('endDate');

  useEffect(() => {
    // Effect to re-trigger endDate validation when start date is updated
    if (startDate && endDate) {
      trigger('endDate');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate]);

  const onCancel = () => {
    if (createMode) {
      navigate(`/${PATHS.promoCodes}`);
    } else {
      onClickCancel();
    }
  };

  const onSubmit = async (values) => {
    const dirtyPayload = getDirtyObject(values, defaultValues);

    try {
      let message = t('promoCodes.updateSuccess');

      if (createMode) {
        const { id } = await createPromoCode(values);
        message = t('promoCodes.createSuccess');
        navigate(`/${PATHS.promoCodes}/${id}`);
      } else {
        await editPromoCode(dirtyPayload, codeDetails?.id);
        refetchCode();

        // Turn off edit mode
        onClickCancel();
      }

      snack({
        message,
        severity: 'success',
      });
    } catch (error) {
      error.errors?.forEach((err) => {
        setError(err.property, {
          type: 'api',
          message: err.message,
        });
        if (!err.property || !watch(err.property)) {
          snack({
            severity: 'error',
            message: err.message || t('common.somethingWrong'),
          });
        }
      });

      if (!errors) { // Non-form errors
        snack({
          severity: 'error',
          message: error.message || t('common.somethingWrong'),
        });
      }
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box>
        <Box sx={{
          display: 'grid',
          columnGap: 31,
          rowGap: 16,
          gridTemplateColumns: {
            xs: 'repeat(1, 1fr)',
            md: 'repeat(1, 420px)',
            lg: 'repeat(2, 304px)',
            xl: 'repeat(2, 420px)',
          },
        }}
        >
          <Box>
            <FormTextField
              name="code"
              control={control}
              label={t('promoCodes.code')}
              disabled={isSubmitting || disableField}
              fullWidth
            />
            <Button
              size="small"
              type="button" // disable form submission
              onClick={async () => {
                const random = generateRandomBetween(3, 6);
                setValue('code', await nanoid(random));
              }}
              disabled={isSubmitting || disableField}
            >
              <TypographyWithIcon
                label={t('promoCodes.autoGenerateCode')}
                icon={<AutoGenerateIcon />}
                labelProps={{
                  variant: 'bodyStandardBold',
                  color: 'text.raspberry',
                }}
              />
            </Button>
          </Box>
          <Box>
            <FormNumberField
              name="discountPercentage"
              control={control}
              label={t('promoCodes.discountPercentage')}
              disabled={isSubmitting || disableField}
              commaSeparated={false}
              inputProps={{ maxLength: 3 }}
              fullWidth
            />
          </Box>
          <Box>
            <FormDatePicker
              name="startDate"
              control={control}
              label={t('common.startDate')}
              disabled={isSubmitting}
              fullWidth
              pickerProps={{
                disablePast: true,
              }}
            />
          </Box>
          <Box>
            <FormDatePicker
              name="endDate"
              control={control}
              label={t('common.endDate')}
              disabled={isSubmitting}
              fullWidth
              pickerProps={{
                disablePast: true,
                minDate: getValues('startDate'),
              }}
            />
          </Box>
          <Box>
            <FormNumberField
              name="maxUses"
              control={control}
              label={t('promoCodes.maxUses')}
              disabled={isSubmitting || watch('isUnlimited')}
              commaSeparated={false}
              fullWidth
            />
            <FormCheckbox
              name="isUnlimited"
              control={control}
              disabled={isSubmitting}
              label={(
                <div>
                  <Typography variant="bodyStandardRegular">
                    {t('promoCodes.unlimitedNumber')}
                  </Typography>
                </div>
              )}
              checkboxProps={{
                checked: watch('isUnlimited'),
                onChange: (e) => {
                  const { checked } = e.target;
                  setValue('isUnlimited', checked, {
                    shouldValidate: true,
                    shouldTouch: true,
                    shouldDirty: true,
                  });
                  setValue('maxUses', 0, {
                    shouldValidate: true,
                    shouldTouch: true,
                    shouldDirty: true,
                  });
                },
              }}
            />
          </Box>
          <Box>
            <FormRadioGroup
              name="sendAnnouncement"
              control={control}
              label={t('promoCodes.sendAnnouncement')}
              disabled={isSubmitting || disableField}
              options={[
                { label: t('common.yes'), value: true },
                { label: t('common.no'), value: false },
              ]}
            />
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          mt: 8,
        }}
      >
        <Box sx={{ mx: 2 }}>
          <LoadingButton
            type="submit"
            variant="contained"
            size="medium"
            loading={isSubmitting}
            disabled={!isDirty || isSubmitting || !isValid}
          >
            {t('common.save')}
          </LoadingButton>
        </Box>
        <Box>
          <Button
            onClick={onCancel}
            disabled={isSubmitting}
            sx={{
              color: 'text.raspberry',
            }}
          >
            {t('common.cancel')}
          </Button>
        </Box>
      </Box>
    </form>
  );
};

export default PromoCodeDetailsForm;
