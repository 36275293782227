import { useTheme } from '@mui/styles';

const SparklingIcon = (props) => {
  const theme = useTheme();
  const {
    stroke = theme.palette.colors.yellow,
    fill = 'none',
    ...rest
  } = props;

  return (
    <svg width="17" height="16" viewBox="0 0 17 16" fill={fill} xmlns="http://www.w3.org/2000/svg" stroke={stroke} {...rest}>
      <path d="M4.29737 10.418C4.29157 11.9371 5.03243 12.8037 6.71534 12.8359C5.15301 12.8301 4.37668 13.6684 4.29737 15.2539C4.28383 13.758 3.61389 12.8359 1.87939 12.8359C3.43141 12.8147 4.29157 12.0654 4.29737 10.418Z" strokeWidth="0.96719" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M3.41797 0.746094C3.41217 2.26523 4.15304 3.13183 5.83595 3.16407C4.27362 3.15827 3.49728 3.9965 3.41797 5.58204C3.40443 4.08612 2.73449 3.16407 1 3.16407C2.55202 3.14279 3.41217 2.39354 3.41797 0.746094Z" strokeWidth="0.96719" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M10.9205 3.38477C10.9091 6.30216 12.3274 7.95694 15.5648 8.02992C12.5621 8.01848 11.0727 11.7694 10.9205 14.8152C10.8915 11.9418 9.60692 8.02552 6.27539 8.02992C9.25609 7.98771 10.9091 6.54924 10.9205 3.38477Z" strokeWidth="1.3189" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default SparklingIcon;
