import api from 'services/api';
import END_POINTS from 'services/endPoints';

const getAllPaymentCards = () => api(
  END_POINTS.allPaymentCards,
)
  .then((res) => Promise.resolve(res))
  .catch((error) => Promise.reject(error));

export default getAllPaymentCards;
