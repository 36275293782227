import { useTheme } from '@mui/styles';

const SubscriptionFileStarIcon = (props) => {
  const theme = useTheme();
  const {
    stroke = theme.palette.text.raspberry,
    fill = 'none',
    ...rest
  } = props;

  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill={fill} stroke={stroke} xmlns="http://www.w3.org/2000/svg" {...rest}>
      <g clipPath="url(#clip0_1666_882)">
        <path d="M17.9562 11.68L19.5002 15H22.4902C22.6414 14.9943 22.7907 15.0354 22.9177 15.1177C23.0447 15.1999 23.1431 15.3194 23.1997 15.4598C23.2563 15.6001 23.2681 15.7545 23.2337 15.9018C23.1992 16.0492 23.1201 16.1823 23.0072 16.283L20.4072 18.846L21.8472 22.159C21.9104 22.3105 21.9245 22.4781 21.8875 22.638C21.8505 22.798 21.7643 22.9423 21.641 23.0507C21.5177 23.1591 21.3635 23.2261 21.2001 23.2423C21.0367 23.2585 20.8724 23.2231 20.7302 23.141L17.2502 21.183L13.7702 23.144C13.628 23.2261 13.4637 23.2615 13.3003 23.2453C13.1369 23.2291 12.9827 23.1621 12.8594 23.0537C12.7361 22.9453 12.6499 22.801 12.6129 22.641C12.5759 22.4811 12.59 22.3135 12.6532 22.162L14.0932 18.849L11.4932 16.286C11.3787 16.1859 11.298 16.0527 11.2625 15.9048C11.227 15.7569 11.2382 15.6016 11.2948 15.4604C11.3513 15.3192 11.4504 15.1991 11.5782 15.1166C11.706 15.0341 11.8562 14.9934 12.0082 15H15.0082L16.5492 11.677C16.6181 11.5504 16.7199 11.4447 16.8439 11.3712C16.9679 11.2977 17.1094 11.2591 17.2536 11.2594C17.3977 11.2597 17.5391 11.299 17.6628 11.373C17.7865 11.447 17.8878 11.5531 17.9562 11.68V11.68Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M8.25 20.25H2.25C1.85218 20.25 1.47064 20.092 1.18934 19.8107C0.908035 19.5294 0.75 19.1478 0.75 18.75V2.25C0.75 1.85218 0.908035 1.47064 1.18934 1.18934C1.47064 0.908035 1.85218 0.75 2.25 0.75H12.879C13.2765 0.750085 13.6578 0.907982 13.939 1.189L16.811 4.061C17.092 4.3422 17.2499 4.72345 17.25 5.121V8.25" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      </g>
      <defs>
        <clipPath id="clip0_1666_882">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SubscriptionFileStarIcon;
