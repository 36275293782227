import {
  Box,
  Card,
  Chip,
  Typography,
} from '@mui/material';
import { CardNumberWithEllipses } from 'components/molecules';

import { getCardTypeByType } from 'util/cardTypes';
import { useLocale } from 'util/hooks';

import PaymentMethodCardMenu from './PaymentMethodCardMenu';

const PaymentMethodCard = (props) => {
  const { cardDetails = {} } = props;

  const {
    cardType = '',
    lastFourDigits = '',
    expiryDate = '',
    cardHolderName = '',
    isDefault = false,
  } = cardDetails;

  const { t } = useLocale();

  const { logo: cardTypeIcon } = getCardTypeByType(cardType);
  const formattedExpiryDate = `${expiryDate.slice(0, 2)}/${expiryDate.slice(2, 4)}`;

  return (
    <Card
      sx={{
        height: { sm: 182, md: 129 },
        maxHeight: { sm: 182, md: 129 },
        width: { md: 582 },
        maxWidth: 582,
        backgroundColor: 'paper.background',
      }}
    >
      <Box sx={{
        display: 'flex',
        alignItems: 'start',
        flexDirection: { xs: 'column', sm: 'row' },
        p: { xs: 4, sm: 8 },
      }}
      >
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          mr: 17,
        }}
        >
          <Box sx={{ display: 'flex' }}>
            <Box sx={{ maxWidth: 39, maxHeight: 22, mr: 5 }}>
              <img
                alt={`${cardType}-logo`}
                width="39"
                height="22"
                src={cardTypeIcon}
              />
            </Box>
            <Box>
              <Box>
                <Typography variant="bodyStandardMedium">
                  <CardNumberWithEllipses
                    ellipseCount={4}
                    lastFourDigits={lastFourDigits}
                  />
                </Typography>
              </Box>
              <Box sx={{
                display: 'flex',
                justifyContent: 'space-between',
                mt: 7,
              }}
              >
                <Box>
                  <Typography variant="bodySmallMedium">
                    {cardHolderName}
                  </Typography>
                </Box>
                <Box>
                  <Typography variant="bodySmallMedium">
                    {formattedExpiryDate}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box sx={{
          display: 'flex',
          alignItems: 'start',
          mt: { xs: 4, sm: 0 },
        }}
        >
          <Box sx={{
            mr: 19,
            minWidth: 62, // So that all icon buttons are vertically aligned
          }}
          >
            {isDefault
              ? (
                <Chip
                  size="small"
                  label={t('paymentMethods.default')}
                  sx={{
                    borderRadius: 5,
                    color: 'text.primary',
                    backgroundColor: 'paper.hover',
                  }}
                />
              ) : null}
          </Box>
          <Box>
            <PaymentMethodCardMenu cardDetails={cardDetails} />
          </Box>
        </Box>
      </Box>
    </Card>
  );
};

PaymentMethodCard.propTypes = {};

export default PaymentMethodCard;
