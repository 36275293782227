import PropTypes from 'prop-types';
import { Box } from '@mui/material';

/**
 * Animated, stand-alone badge. Doesn't take children.
 * Meant for statuses like online, visibilities and so on.
 */
const NonStickyBadge = (props) => {
  const {
    color,
    children,
    sx,
    ...rest
  } = props;

  return (
    <Box
      {...rest}
      sx={{
        color,
        width: 8,
        height: 8,
        borderRadius: 5,
        backgroundColor: color,
        position: 'relative',
        '&::after': {
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          borderRadius: '50%',
          animation: 'ripple 1.2s infinite ease-in-out',
          border: '2px solid currentColor',
          content: '""',
        },
        '@keyframes ripple': {
          '0%': {
            transform: 'scale(.8)',
            opacity: 1,
          },
          '100%': {
            transform: 'scale(2.4)',
            opacity: 0,
          },
        },
        ...sx,
      }}
    />
  );
};

NonStickyBadge.propTypes = {
  color: PropTypes.string,
  sx: PropTypes.oneOfType([PropTypes.object]),
};

NonStickyBadge.defaultProps = {
  color: 'success.main',
  sx: {},
};

export default NonStickyBadge;
