import { useTheme } from '@mui/styles';

const EmptyPaperFilledIcon = (props) => {
  const theme = useTheme();
  const {
    stroke = theme.palette.text.secondary,
    fill = theme.palette.paper.hover,
    ...rest
  } = props;

  return (
    <svg width="49" height="44" viewBox="0 0 49 44" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <rect x="0.660156" width="48" height="44" rx="6" fill={fill} />
      <path d="M16.0664 14.7277V13.4058C16.0664 13.0552 16.2057 12.719 16.4536 12.4711C16.7015 12.2232 17.0377 12.084 17.3882 12.084H18.711" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M16.0664 29.2715V30.5933C16.0664 30.9439 16.2057 31.2801 16.4536 31.528C16.7015 31.7759 17.0377 31.9151 17.3882 31.9151H18.711" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M33.254 29.2715V30.5933C33.254 30.9439 33.1147 31.2801 32.8668 31.528C32.6189 31.7759 32.2827 31.9151 31.9321 31.9151H30.6094" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M25.9824 31.916H27.9661" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M21.3555 31.916H23.3391" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M25.9824 12.084H27.9661" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M21.3555 12.084H23.3391" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M16.0664 20.0169V18.0332" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M16.0664 25.9661V23.9824" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M33.2539 20.0169V18.0332" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M33.2539 25.9661V23.9824" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M30.6094 14.0664L31.9321 15.3882" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>

  );
};

export default EmptyPaperFilledIcon;
