import { Box } from '@mui/material';

import { CustomBreadcrumbs } from 'components/molecules';
import { Challenges } from 'components/organisms';

import { PATHS } from 'routes';
import { useLocale } from 'util/hooks';

import LabSummary from './sections/LabSummary';

const PlayerViewLabContainer = (props) => {
  const { labDetails = {} } = props;
  const { t, isAr } = useLocale();

  const crumbs = [
    {
      text: t(`labs.${labDetails?.type}Labs`),
      link: `/${PATHS.labs}/${labDetails?.type}-labs`,
    },
    {
      text: isAr ? labDetails?.nameAr : labDetails.nameEn,
      link: '#',
    },
  ];

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <CustomBreadcrumbs crumbs={crumbs} />
      <Box sx={{ mt: 4, mb: 8 }}>
        <LabSummary labDetails={labDetails} />
      </Box>
      <Challenges labDetails={labDetails} />
    </Box>
  );
};

export default PlayerViewLabContainer;
