import { getVpnRegions } from 'services';
import { useCustomQuery } from 'reactQuery';

const useVpnRegions = (queryProps = {}) => {
  const query = useCustomQuery({
    queryKey: ['vpn-regions'],
    queryFn: getVpnRegions,
    ...queryProps,
  });

  return { ...query };
};

export default useVpnRegions;
