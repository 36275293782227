import * as Yup from 'yup';
import { useTheme } from '@mui/styles';
import { LoadingButton } from '@mui/lab';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { submitMachineFlag } from 'services';
import { FormTextField } from 'components/form';
import { useMachineDetails } from 'reactQuery/queries';
import { CheckCircleOutline, FlagFilledIcon } from 'assets/icons';
import {
  useFastForm,
  useLocale,
  useModal,
  useRecaptcha,
  useSnackbar,
} from 'util/hooks';
import MODAL_KEYS from 'assets/constants/modalKeys';

const SubmitMachineFlagCard = () => {
  const { t } = useLocale();
  const snack = useSnackbar();
  const { palette } = useTheme();
  const { addModal } = useModal();
  const { executeRecaptcha } = useRecaptcha({ action: 'MACHINE_FLAG_SUBMISSION' });
  const {
    data: machineDetails = {},
    refetch: refetchMachineDetails = () => { },
  } = useMachineDetails();

  const {
    name: machineName = '',
    progress = {},
  } = machineDetails;

  const {
    completedRootFlag = false,
    completedUserFlag = false,
    completionDate = '',
  } = progress;

  const isCompletedByUser = completedRootFlag && completedUserFlag;

  const handleError = (error = {}) => {
    const { message, property } = error;

    if (property) {
      setError(property, {
        type: 'api',
        message,
      });
    } else {
      snack({
        severity: 'error',
        message: message || t('common.somethingWrong'),
      });
    }
  };

  const handleOpenFlagModal = (submissionResult) => {
    const {
      userFirstBlood,
      rootFirstBlood,
      isRootSubmission,
      machine = {},
    } = submissionResult;

    const {
      rootPoints,
      userPoints,
    } = machine || {};

    addModal({
      key: MODAL_KEYS.correctFlag,
      props: {
        type: 'machine',
        points: isRootSubmission ? rootPoints : userPoints,
        firstBlood: userFirstBlood || rootFirstBlood,
        isRootSubmission,
      },
    });
  };

  const onSubmit = async ({ flag }) => {
    const payload = {
      flag,
    };

    try {
      const recaptchaToken = await executeRecaptcha();
      const submissionResult = await submitMachineFlag(machineName, payload, { recaptchaToken });

      handleOpenFlagModal(submissionResult);

      // Update machine
      refetchMachineDetails();
    } catch (error) {
      if (error.message) {
        handleError(error);
      } else {
        error.errors?.forEach((err) => {
          handleError(err);
        });
      }
    }
  };

  const defaultValues = {
    flag: '',
  };

  const validationSchema = Yup.object({
    flag: Yup
      .string()
      .trim()
      .required(t('challenges.enterFlag'))
      .min(1, t('challenges.validation.flagMinLength'))
      .max(250, t('challenges.validation.flagMaxLength')),
  });

  const {
    control,
    handleSubmit,
    setError,
    formState: { isSubmitting, isDirty },
  } = useFastForm({
    defaultValues,
    validationSchema,
  });

  const form = !isCompletedByUser && (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Box sx={{
          mb: 9,
          maxWidth: 304,
        }}
        >
          <FormTextField
            name="flag"
            control={control}
            label={t('common.theFlag')}
            placeholder={t('challenges.enterFlag')}
            fullWidth
          />
        </Box>
        <Box>
          <LoadingButton
            type="submit"
            variant="contained"
            loading={isSubmitting}
            disabled={!isDirty || isSubmitting}
          >
            {t('common.submit')}
          </LoadingButton>
        </Box>
      </Box>
    </form>
  );

  const completedPlaceholder = isCompletedByUser && (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        my: 5,
      }}
    >
      <Box sx={{
        mb: 5,
        width: 38,
        height: 38,
      }}
      >
        <CheckCircleOutline
          width="38"
          height="38"
          stroke={palette.success.main}
        />
      </Box>
      <Typography
        variant="bodyMediumMedium"
        color="text.lightGray"
      >
        {t('machines.youHacked')}
      </Typography>
      <Typography
        variant="bodyStandardMedium"
        color="text.secondary"
      >
        {`${t('common.on')} ${new Date(completionDate)
          .toLocaleString(
            'en-US',
            {
              year: 'numeric',
              month: 'short',
              day: 'numeric',
              hour: '2-digit',
              minute: '2-digit',
            },
          )}`}
      </Typography>
    </Box>
  );

  return (
    <Card
      key="submit-machine-flag"
      sx={{
        flex: 1,
        minWidth: 300,
        py: 6,
        px: 5,
      }}
    >
      <Stack>
        <Stack direction="row" spacing={4}>
          <Box
            sx={{
              width: 48,
              height: 44,
            }}
          >
            <FlagFilledIcon width="48" height="44" />
          </Box>
          <Stack>
            <Typography
              variant="bodyStandardMedium"
              color="text.lightGray"
            >
              {t('machines.submitFlags')}
            </Typography>
            <Typography
              variant="bodySmallRegular"
              color="text.secondary"
            >
              {t('challenges.foundFlag')}
            </Typography>
          </Stack>
        </Stack>
        <Box sx={{
          mt: 5,
          ml: { md: 16 },
        }}
        >
          {isCompletedByUser
            ? completedPlaceholder
            : form}
        </Box>
      </Stack>
    </Card>
  );
};

export default SubmitMachineFlagCard;
