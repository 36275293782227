import END_POINTS from 'services/endPoints';
import api from '../api';

const getPlanDetails = (planId) => api(
  END_POINTS.plan(planId),
)
  .then((res) => Promise.resolve(res))
  .catch((error) => Promise.reject(error));

export default getPlanDetails;
