import {
  Box,
  Card,
  Chip,
  Typography,
} from '@mui/material';
import { ChallengeAvatar, Tag } from 'components/molecules';

import { useLocale } from 'util/hooks';
import { getDifficultyByName } from 'assets/constants/difficulties';
import { capitalizeFirstLetter } from 'util/helpers';

const ChallengeSummary = (props) => {
  const {
    challengeDetails = {},
    eventDetails = {},
  } = props;

  const {
    name = '',
    description = '',
    author = '',
    difficulty = '',
    tags = [],
    category = {},
  } = challengeDetails;

  const { isDynamicPoints = false } = eventDetails || {};

  const { t } = useLocale();

  return (
    <Card sx={{ p: 8, mb: 8 }}>
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
      }}
      >
        <Box sx={{
          display: 'flex',
          alignItems: 'center',
        }}
        >
          <ChallengeAvatar
            challenge={challengeDetails}
            sx={{
              width: 65,
              height: 65,
              mr: 6,
            }}
          />
          <Box sx={{ width: 1 }}>
            <Box sx={{
              width: 1,
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              flexWrap: 'wrap',
            }}
            >
              <Box sx={{
                display: 'flex',
                alignItems: 'center',
                mr: 2,
              }}
              >
                <Typography
                  variant="bodyMediumMedium"
                  color="text.lighterGray"
                  sx={{ mr: 3 }}
                >
                  {name}
                </Typography>
                {!isDynamicPoints
                && (
                <Box>
                  <Chip
                    size="small"
                    color={difficulty || ''}
                    label={t(getDifficultyByName(difficulty)?.localizationKey) || ''}
                  />
                </Box>
                )}
              </Box>
              <Box>
                <Typography
                  variant="bodySmallMedium"
                  color="text.secondary"
                >
                  {`${t('common.by')} ${author}`}
                </Typography>
              </Box>
            </Box>
            <Box>
              <Typography
                variant="bodySmallMedium"
                color="text.secondary"
              >
                {capitalizeFirstLetter(category?.name)}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap',
        ml: { md: 22 },
      }}
      >
        <Box sx={{ mt: 4, mb: 6 }} maxWidth="md">
          <Typography
            variant="bodyStandardRegular"
            color="text.lighterGray"
            whiteSpace="pre-wrap"
          >
            {description}
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', flexWrap: 'wrap', rowGap: 2 }}>
          {tags?.map((tag) => (
            <Tag
              key={tag}
              label={tag}
              sx={{
                mr: 2,
              }}
            />
          ))}
        </Box>
      </Box>
    </Card>
  );
};

export default ChallengeSummary;
